import React from 'react';

const RepairAppleLinksList = (props) => {
  return (
      <ul className='repair-mb__trouble-list'>
        <li className='repair-mb__trouble-item'>
          <label className='repair-mb__trouble-lable'>{props.number}</label>
          <div className='repair-mb__trouble-wrapper'>
            <h4 className='repair-mb__trouble-subtitle'>{props.subtitle}</h4>
            <p className='repair-mb__trouble-text'>{props.textLi}</p>
          </div>
        </li>
      </ul>
  )
};

export default RepairAppleLinksList;
