import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageFeedbacks.module.css";
import NoteExpertGeneralPageSlider from "./NoteExpertGeneralPageSlider";
import NoteExpertVideo from "./NoteExpertVideo";
import NoteExpertGeneralPageFeedbacksMobile from "./NoteExpertGeneralPageFeedbacksMobile";
import { useMedia } from "../../../utils/useMedia";

const videos = [
  { link: "https://www.youtube.com/embed/CqGfBqQxWv8" },
  { link: "https://www.youtube.com/embed/NJLNghPs4BQ" },
  { link: "https://www.youtube.com/embed/wYpnaKNl6E0" },
  { link: "https://www.youtube.com/embed/3aVqC_1EpC8" },
  { link: "https://www.youtube.com/embed/h04jEGWqj80" },
];

function ChangePage(title) {
  const isBreakpoint = useMedia("(min-width: 600px)");

  if (isBreakpoint) {
    return (
      <Section>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.wrapper}>
            <NoteExpertGeneralPageSlider videos={videos}>
              {videos.map((video, i) => {
                return <NoteExpertVideo key={i} link={video.link} />;
              })}
            </NoteExpertGeneralPageSlider>
            <div className={styles.sale}>
              <h4 className={styles.saleTitle}>СУПЕР СКИДКА</h4>
              <p className={styles.saleText}>
                Снимите ваш видео-отзыв о работе NoteExpert на камеру или
                мобильный телефон, отправьте его нам и получите персональную
                скидку до 25%
              </p>
              <button className={styles.button}>Оставить отзыв</button>
            </div>
          </div>
        </div>
      </Section>
    );
  } else {
    return <NoteExpertGeneralPageFeedbacksMobile videos={videos} />;
  }
}

function NoteExpertGeneralPageFeedbacks({ title }) {
  return ChangePage(title);
}

export default NoteExpertGeneralPageFeedbacks;
