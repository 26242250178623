import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpad4.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Планшет выключается, даже когда индикатор заполнен на треть",
    number: "01",
  },
  {
    id: 2,
    text: "Включить iPad удается только при подключении шнура питания",
    number: "02",
  },
  {
    id: 3,
    text: "Устройство не заряжается и не включается",
    number: "03",
  },
  {
    id: 4,
    text: "Задняя часть планшета сильно перегревается даже при непродолжительной эксплуатации.",
    number: "04",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Когда нужна замена аккумулятора iPad 4",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Планшет заблаговременно «сообщит» своему владельцу о том, что необходимо обновить батарею – он начнет быстрее разряжаться. Если же обладатель устройства не поспешит в сервисный центр, то он столкнется со следующими проблемами:"
          array={listOfTrouble}
          paragraphDown="Несвоевременная замена аккумулятора может стать причиной появления на экране белых пятен. Это связано с тем, что исчерпав свой ресурс, батарея начинает увеличиваться в объемах, и создается дополнительное давление на дисплейный модуль. Выход из строя одного из элементов системы приведет к повреждениям других, а потому стоит без промедления обратиться к специалистам, которые помогут в решении этого вопроса."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сколько времени занимает замена аккумулятора iPad 4",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Изначально наши мастера проводят диагностику устройства, длительность
          которой составляет 10-15 минут. Это очень важный момент, позволяющий
          определить даже скрытые неисправности, чтобы оперативно их устранить.
          Результаты проведенного тестирования будут озвучены владельцу
          планшета, как и спектр предстоящих работ. И лишь после этого мастер
          принимается за работу.
        </p>
        <p className="repair-iphone-display__paragraph">
          Стандартная замена аккумулятора iPad 4 составляет 2-3 часа, но мы
          готовы предложить и срочный ремонт, который займет меньше 60 минут.
        </p>
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModel4 = () => {
  return (
    <Page title="Замена аккумулятора iPad 4 (Айпад 4) в Москве быстро и качественно">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-akkumuljatora/4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если вам потребовалась замена аккумулятора iPad 4, наши мастера создадут все условия для того, чтобы техника работала безотказно. Обращайтесь к нам, и получайте результат!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPad 4"
          titleImg="Замена аккумулятора iPad 4"
          banner={banner}
          title="Замена аккумулятора iPad 4"
          text="У любого аккумулятора есть определенный срок годности, по истечении которого его емкость постепенно сокращается. Соответственно, чем активнее используется устройство, тем чаще владельцам предстоит менять батарею – в среднем, эта процедура проводится каждые 1,5-2 года. Чтобы наслаждаться длительной автономной работой устройства, обратитесь в сервисный центр Мак Профи для замены аккумулятора iPad 4 – наши мастера в течение часа проведут все необходимые работы."
        />
        <Section className="section_group">
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена аккумулятора iPad 4"
          text="Замена аккумулятора"
          price="2950 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Все расценки представлены на сайте – вы можете с ними
                ознакомиться, чтобы оценить насколько выгодно наше предложение.
                В эту цену уже включена стоимость работы мастера, а также
                расходы на приобретение необходимых комплектующих. Ценовая
                политика сервисного центра лояльна и прозрачна, а расценки
                фиксированы – первоначальная сумма не изменится после завершения
                ремонта.
              </p>
              <p className="repair-iphone-display__paragraph">
                Чтобы заменить аккумулятор iPad 4, приезжайте в сервисный центр
                Мак Профи в любое удобное время согласно графику работы,
                представленному на сайте. И вы сможете получить широчайший
                спектр услуг на выгодных условиях.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModel4;
