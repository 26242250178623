import React from "react";
import { NavLink } from "react-router-dom";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import Section from "../Section/Section";
import RepairMacBookLinksMobile from "./RepairMacBookLinksMobile";
import { useMobileScreen } from "../../utils/useMedia";

const links = [
  {
    to: "/remont-apple/remont-macbook/zamena_akkumuljatora_macbook",
    text: "MacBook замена аккумулятора",
  },
  {
    to: "/remont-apple/remont-macbook/zalil_macbook",
    text: "Ремонт MacBook залитых жидкостью",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_remont_tachpad_na_macbook",
    text: "MacBook ремонт и замена тачпад",
  },
  {
    to: "/remont-apple/remont-macbook/sbros_parolya_MacBook",
    text: "Сброс пароля на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_remont_klaviatury_macbook_pro_air",
    text: "MacBook замена и ремонт клавиатуры",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_matrici_MacBook",
    text: "Замена матрицы MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/vosstanovlenie_dannyh_na_MacBook_iMac",
    text: "Восстановление данных на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_operativnoj_pamyati_na_MacBook_Pro",
    text: "Увеличение оперативной памяти на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_ssd_na_macbook_pro_air",
    text: "Замена и установка SSD на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/ustanovka_windows_na_macbook_imac",
    text: "Установка Windows на MacBook",
  },
];

function PageLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairMacBookLinksMobile />;
  }
  return (
    <div className="repaip-mb-container">
      <h2 className="repair-mb__links-title">Подробнее по неисправностям</h2>
      <div className="repair-mb__links-wrapper">
        <ul className="repair-mb__links-list">
          {links.map((link, i) => {
            return (
              <li key={i} className="repair-mb__links-item">
                <img
                  src={IconCheckMark}
                  alt="иконка галочки"
                  className="repair-mb__links-icon"
                />
                <NavLink to={link.to} className="repair-mb__links-link">
                  {link.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className="repair-mb__links-wrapper-images">
          <div className="repaip-mb__links-imagesd"></div>
          <div className="repaip-mb__links-imagemb"></div>
        </div>
      </div>
    </div>
  );
}

const RepairMacBookLinks = () => {
  return <Section className="repair-mb__links-content">{PageLinks()}</Section>;
};

export default RepairMacBookLinks;

// toDo:
// заменить теги a на Link или NavLink
// импортировать картинку через индекс
// h1 ??
