import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIpodModelLinks,
} from "../index";
import banner from "../../images/banners/ipodNano5.jpeg";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Гарантия качества на все виды услуг",
    number: "01",
  },
  {
    id: 2,
    text: "Курьерская доставка на дом и в офис",
    number: "02",
  },
  {
    id: 3,
    text: "Бесплатное проведение диагностики",
    number: "03",
  },
  {
    id: 4,
    text: "Выполнение ремонта в день обращения",
    number: "04",
  },
  {
    id: 5,
    text: "Использование качественных комплектующих.",
    number: "05",
  },
];

const textIpodTouch321 = [
  {
    id: 1,
    title:
      "Самостоятельный ремонт iPod Touch 3G, 2G, 1G или обращение в сервис?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Для восстановления устройства потребуется аккуратно разобрать его,
          снять поврежденные элементы и поставить вместо них новые детали. У
          специалиста это не вызовет затруднений, ведь подобную работу
          приходится проделывать регулярно. Самостоятельно же чинить
          дорогостоящий плеер не стоит – в большинстве случаев владельцам не
          только не удается устранить поломку своими силами, но и неверными
          действиями они создают новые проблемы.
        </p>
        <p className="repair-iphone-display__paragraph">
          Более того, подобный подход еще и нецелесообразен, ведь нужно
          приобрести инструменты и расходные материалы, которые будут
          использованы в процессе ремонта{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          iPod Touch 3G, 2G, 1G. Обращение в сервис Мак Профи с учетом всех
          затрат обойдется на порядок дешевле, и при этом вы получаете
          уверенность в итоговом результате, что подтверждает соответствующая
          гарантия.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Сколько стоит ремонт iPod Touch 3G, 2G, 1G?",
    text: "С расценками на услуги вы можете ознакомиться на сайте. Если возникнут дополнительные вопросы, задайте их менеджерам нашего сервисного центра, которые без труда объяснят механизм формирования цены, а также уточнят стоимость используемых комплектующих. Мы приступаем к работе только диагностики устройства и согласования с владельцем основных этапов ремонта, благодаря чему вы полностью застрахованы от «сюрпризов» – сотрудничая с нами, вы платите честную цену за оказанные услуги.",
  },
  {
    id: 3,
    title: "Ремонт iPod Touch 3G, 2G, 1G на выгодных условиях",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Сервисный центр Мак Профи исправит любые поломки, начиная от замены разбитого стекла или же исчерпавшего свой ресурс аккумулятора и заканчивая сложным ремонтом внутренних элементов. И мы готовы предложить клиентам многочисленные преимущества, среди которых:"
          array={listOfTrouble}
          paragraphDown="Не стоит лишаться любимого плеера, который выполняет целый ряд полезных функций – вне зависимости от того, что послужило причиной возникновения неисправности, мы устраним её в короткие сроки."
        />
      </>
    ),
  },
];

const RepairIpodModelLinkTouch321 = () => {
  return (
    <Page title="Ремонт iPod Touch 3G, 2G, 1G в Москве по низким ценам">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipod/1G-2G-3G"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт iPod Touch 3G, 2G, 1G без задержек: починим Apple Ремонт iPod Touch в день обращения"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPod Touch 3G, 2G, 1G"
          titleImg="Ремонт iPod Touch 3G, 2G, 1G"
          banner={banner}
          title="Ремонт iPod Touch 3G, 2G, 1G"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Нужен ремонт iPod Touch 3G, 2G, 1G в Москве - обращайтесь в Мак
                Профи. Честная цена, гарантии, быстрое восстановление без
                доплаты за срочность.
              </p>
              <p className="repair-iphone-display__paragraph">
                Вся серия iPod Touch 3G, 2G, 1G подкупает своей
                функциональностью, компактными размерами, а также улучшенной
                «начинкой» в сравнении с предшественниками. Вот только, несмотря
                на её надежность, вечной техники все-таки не существует – все
                элементы рассчитаны на определенный период работы. Поэтому если
                ваш айпод нуждается в ремонте, стоит доверить эту задачу
                квалифицированным мастерам – и неполадки перестанут доставлять
                неудобства.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIpodTouch321.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpodModelLinks />
      </main>
    </Page>
  );
};

export default RepairIpodModelLinkTouch321;
