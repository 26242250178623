import React from "react";
import { NavLink } from "react-router-dom";

const PageLinks = () => {
  return (
    <>
      <li className="page-link__li">
        <NavLink className="nav__item" to="/klientam-strahovyh">
          Клиентам страховых
        </NavLink>
      </li>
      <li className="page-link__li">
        <NavLink className="nav__item" to="/dostavka">
          Доставка
        </NavLink>
      </li>
      <li className="page-link__li">
        <NavLink className="nav__item" to="/sotrudnichestvo">
          Сотрудничество
        </NavLink>
      </li>
      <li className="page-link__li">
        <NavLink className="nav__item" to="/kontakty">
          Контакты
        </NavLink>
      </li>
    </>
  );
};

export default PageLinks;
