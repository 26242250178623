import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AdminLayout from "../../admin/AdminLayout";
import AdminUserDetail from "../../admin/AdminUserDetail";
import AdminUserList from "../../admin/AdminUserList";
import { UserProvider } from "../../providers/UserProvider";
import CenterMobileIRepairMobile from "../centerMobile/CenterMobileIRepairMobile/CenterMobileIRepairMobile";
import CenterMobileIRepairFly from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobileFly";
import CenterMobileRepairMobileLenovo from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobileLenovo";
import CenterMobileRepairMobileLG from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobileLG";
import CenterMobileRepairMobileNokia from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobileNokia";
import CenterMobileRepairMobileHTC from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobileHTC";
import CenterMobileRepairMobilePhillips from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobilePhillips";
import CenterMobileRepairMobileSony from "../centerMobile/CenterMobileRepairMobilePages/CenterMobileRepairMobileSony";
import CenterMobileRepairPadAsus from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadAsus";
import CenterMobileRepairPadExplay from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadExplay";
import CenterMobileRepairPadSamsung from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadSamsung";
import CenterMobileRepairPadSony from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadSony";
import CenterMobileRepairPadPrestigio from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadPrestigio";
import CenterMobileRepairPadAcer from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadAcer";
import CenterMobileRepairPadLenovo from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadLenovo";
import CenterMobileRepairPadDigma from "../centerMobile/CenterMobileRepairPadPages/CenterMobileRepairPadDigma";
import NoteExpertGeneralPage from "../noteExpert/NoteExpertGeneralPage/NoteExpertGeneralPage";
import NoteExpertNoteSettingLink from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingLink";
import NoteExpertNoteSettingMatrix from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingMatrix";
import NoteExpertNoteSettingMotherboard from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingMotherboard";
import NoteExpertNoteSettingKeyboard from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingKeyboard";
import NoteExpertNoteSettingClean from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingClean";
import NoteExpertNoteSettingPower from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingPower";
import NoteExpertNoteSettingOverheating from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingOverheating";
import NoteExpertNoteSettingLoud from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingLoud";
import NoteExpertNoteSettingRestart from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingRestart";
import NoteExpertNoteSettingCharge from "../noteExpert/NoteExpertReasonsLinks/NoteExpertNoteSettingCharge";
import NoteExpertGeneralPageServiceLinkUpgrade from "../noteExpert/NoteExpertGeneralPageServiceLinksPages/NoteExpertGeneralPageServiceLinkUpgrade";
import NoteExpertGeneralPageServiceLinkMemory from "../noteExpert/NoteExpertGeneralPageServiceLinksPages/NoteExpertGeneralPageServiceLinkMemory";
import NoteExpertGeneralPageServiceHardDisk from "../noteExpert/NoteExpertGeneralPageServiceLinksPages/NoteExpertGeneralPageServiceLinkHardDisk";
import NoteExpertGeneralPageServiceWindows from "../noteExpert/NoteExpertGeneralPageServiceLinksPages/NoteExpertGeneralPageServiceWindows";
import NoteExpertGeneralPageServicePrograms from "../noteExpert/NoteExpertGeneralPageServiceLinksPages/NoteExpertGeneralPageServicePrograms";
import {
  AboutCompanyPage,
  Admin,
  AdminIndex,
  AdminNotificationList,
  AdminFeedbacksList,
  AdminFeedbackDetail,
  AdminNotificationDetail,
  CooperationPage,
  DeliveryPage,
  Layout,
  MacProfiPage,
  Main,
  RepairMacBookPage,
  RepairMacBookLinkBattery,
  RepairMacBookLinkWater,
  RepairMacBookLinkTouchpad,
  RepairMacBookLinkPassword,
  RepairMacBookLinkKeyboard,
  RepairMacBookLinkMatrix,
  RepairMacBookLinkMemory,
  RepairMacBookLinkData,
  RepairMacBookLinkSSD,
  RepairMacBookLinkWindows,
  PageNotFound,
  User,
  RepairIphonePage,
  RepairIphoneServiceLinkDisplay,
  RepairIphoneServiceLinkWater,
  RepairIphoneServiceLinkPower,
  RepairIphoneServiceLinkNetwork,
  RepairIphoneServiceLinkBattery,
  RepairIphoneServiceLinkCamera,
  RepairIphoneServiceLinkButton,
  RepairIphoneServiceLinkSpeaker,
  RepairIphoneServiceLinkMicrophone,
  RepairIphoneServiceLinkDisplayModels4s,
  RepairIphoneServiceLinkDisplayModels5,
  RepairIphoneServiceLinkDisplayModels5s,
  RepairIphoneServiceLinkDisplayModels5c,
  RepairIphoneServiceLinkDisplayModelsSE,
  RepairIphoneServiceLinkDisplayModels6,
  RepairIphoneServiceLinkDisplayModels6s,
  RepairIphoneServiceLinkDisplayModels6plus,
  RepairIphoneServiceLinkDisplayModels6sPlus,
  RepairIphoneServiceLinkDisplayModels7,
  RepairIphoneServiceLinkDisplayModels7Plus,
  RepairIphoneServiceLinkBatteryModels4s,
  RepairIphoneServiceLinkBatteryModels5,
  RepairIphoneServiceLinkBatteryModels5s,
  RepairIphoneServiceLinkBatteryModels5c,
  RepairIphoneServiceLinkBatteryModelsSE,
  RepairIphoneServiceLinkBatteryModels6,
  RepairIphoneServiceLinkBatteryModels6Plus,
  RepairIphoneServiceLinkBatteryModels6s,
  RepairIphoneServiceLinkBatteryModels6sPlus,
  RepairIphoneServiceLinkBatteryModels7,
  RepairIphoneServiceLinkBatteryModels7Plus,
  RepairIphoneServiceLinkBatteryModels8,
  RepairIphoneServiceLinkBatteryModels8Plus,
  RepairIphoneServiceLinkBatteryModelsX,
  RepairIphoneServiceLinkCameraModels4s,
  RepairIphoneServiceLinkCameraModels5,
  RepairIphoneServiceLinkCameraModels5s,
  RepairIphoneServiceLinkCameraModelsSE,
  RepairIphoneServiceLinkCameraModels5c,
  RepairIphoneServiceLinkCameraModels6,
  RepairIphoneServiceLinkCameraModels6s,
  RepairIphoneServiceLinkCameraModels6Plus,
  RepairIphoneServiceLinkCameraModels6sPlus,
  RepairIphoneServiceLinkCameraModels7,
  RepairIphoneServiceLinkCameraModels7Plus,
  RepairIphoneServiceLinkCameraModels8,
  RepairIphoneServiceLinkCameraModels8Plus,
  RepairIphoneServiceLinkCameraModelsX,
  RepairIphoneServiceLinkSpeakerModels4s,
  RepairIphoneServiceLinkSpeakerModels5,
  RepairIphoneServiceLinkSpeakerModels5s,
  RepairIphoneServiceLinkSpeakerModels5c,
  RepairIphoneServiceLinkSpeakerModelsSE,
  RepairIphoneServiceLinkSpeakerModels6,
  RepairIphoneServiceLinkSpeakerModels6s,
  RepairIphoneServiceLinkSpeakerModels6Plus,
  RepairIphoneServiceLinkSpeakerModels6sPlus,
  RepairIphoneServiceLinkSpeakerModels7,
  RepairIphoneServiceLinkSpeakerModels7Plus,
  RepairIphoneServiceLinkSpeakerModels8,
  RepairIphoneServiceLinkSpeakerModels8Plus,
  RepairIphoneServiceLinkSpeakerModelsX,
  RepairIphoneServiceLinkMicrophoneModels4s,
  RepairIphoneServiceLinkMicrophoneModels5,
  RepairIphoneServiceLinkMicrophoneModels5s,
  RepairIphoneServiceLinkMicrophoneModels5c,
  RepairIphoneServiceLinkMicrophoneModelsSE,
  RepairIphoneServiceLinkMicrophoneModels6,
  RepairIphoneServiceLinkMicrophoneModels6s,
  RepairIphoneServiceLinkMicrophoneModels6Plus,
  RepairIphoneServiceLinkMicrophoneModels6sPlus,
  RepairIphoneServiceLinkMicrophoneModels7,
  RepairIphoneServiceLinkMicrophoneModels7Plus,
  RepairIphoneServiceLinkMicrophoneModels8,
  RepairIphoneServiceLinkMicrophoneModels8Plus,
  RepairIphoneServiceLinkMicrophoneModelsX,
  RepairIphoneModelPage4s,
  RepairIphoneModelPage5,
  RepairIphoneModelPage5s,
  RepairIphoneModelPageSE,
  RepairIphoneModelPage5c,
  RepairIphoneModelPage6,
  RepairIphoneModelPage6s,
  RepairIphoneModelPage6sPlus,
  RepairIphoneModelPage6Plus,
  RepairIphoneModelPage7,
  RepairIphoneModelPage7Plus,
  RepairIphoneModelPage8,
  RepairIphoneModelPage8Plus,
  RepairIphoneModelPageX,
  RepairMacProPage,
  RepairIMacPage,
  RepairIMacLinkMatrixCleaning,
  RepairIMacLinkMotherboard,
  RepairIMacLinkHardDisk,
  RepairIMacLinkDisplay,
  RepairMacMiniPage,
  RepairIpadPage,
  RepairIpadModelPagesIpad2,
  RepairIpadModelPagesIpad3,
  RepairIpadModelPagesIpad4,
  RepairIpadModelPagesIpadAir,
  RepairIpadModelPagesIpadAir2,
  RepairIpadModelPagesIpadMini,
  RepairIpadModelPagesIpadMini2Retina,
  RepairIpadModelPagesIpadMini3,
  RepairIpadModelPagesIpadMini4,
  RepairIpadModelPagesIpadPro9,
  RepairIpadModelPagesIpadPro12,
  RepairIpadServiceLinkCamera,
  RepairIpadServiceLinkBattery,
  RepairIpadServiceLinkDisplay,
  RepairIpadServiceLinkCase,
  RepairIpadServiceLinkSim,
  RepairIpadServiceLinkPower,
  RepairIpadServiceLinkSpeaker,
  RepairIpadServiceLinkNetwork,
  RepairIpadServiceLinkBatteryModelIpadMini,
  RepairIpadServiceLinkBatteryModelIpad2,
  RepairIpadServiceLinkBatteryModelMini4,
  RepairIpadServiceLinkBatteryModel3,
  RepairIpadServiceLinkBatteryModelPro12,
  RepairIpadServiceLinkBatteryModelPro9,
  RepairIpadServiceLinkBatteryModelMini3,
  RepairIpadServiceLinkBatteryModelMini2,
  RepairIpadServiceLinkBatteryModelAir2,
  RepairIpadServiceLinkBatteryModel4,
  RepairIpadServiceLinkBatteryModelAir,
  RepairIpadServiceLinkDisplayModelAir,
  RepairIpadServiceLinkDisplayModelMini,
  RepairIpadServiceLinkDisplayModel2,
  RepairIpadServiceLinkDisplayModel4,
  RepairIpadServiceLinkDisplayModelAir2,
  RepairIpadServiceLinkDisplayModelMini2,
  RepairIpadServiceLinkDisplayModelMini3,
  RepairIpadServiceLinkDisplayModelPro9,
  RepairIpadServiceLinkDisplayModel3,
  RepairIpadServiceLinkDisplayModelMini4,
  RepairIpadServiceLinkDisplayModelPro12,
  RepairIpadServiceLinkCaseModel2,
  RepairIpadServiceLinkCaseModel3,
  RepairIpadServiceLinkCaseModel4,
  RepairIpadServiceLinkCaseModelAir,
  RepairIpadServiceLinkCaseModelAir2,
  RepairIpadServiceLinkCaseModelMini,
  RepairIpadServiceLinkCaseModelMini2,
  RepairIpadServiceLinkCaseModelMini3,
  RepairIpadServiceLinkCaseModelMini4,
  RepairIpadServiceLinkCaseModelPro9,
  RepairIpadServiceLinkCaseModelPro12,
  RepairIwatchPage,
  RepairIwatchModelLink1,
  RepairIwatchModelLink2,
  RepairIwatchModelLinkNike,
  RepairIwatchModelLinkEdition,
  RepairIwatchModelLinkSport,
  RepairIpodModelLinkClassic,
  RepairIpodModelLinkNano7,
  RepairIpodModelLinkNano6,
  RepairIpodModelLinkNano5,
  RepairIpodModelLinkTouch321,
  RepairIpodPage,
  CenterMobileGeneralPage,

  // BurgerMenu,
} from "../index";
import InsuranceClients from "../InsuranceClients/InsuranceClients";

const App = () => {
  const [isDeliveryFormApplication, setIsDeliveryFormApplication] =
    useState(false);
  // const [burgerMenuActive, setBurgerMenuActive] = useState(false)

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  var routes = (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Main />} />
        {/* <Route path="login" element={<User />} /> */}
        <Route path="remont-apple" element={<MacProfiPage />} />
        <Route path="center-mobile" element={<CenterMobileGeneralPage />} />
        <Route path="note-expert" element={<NoteExpertGeneralPage />} />
        <Route
          path="remont-apple/remont-iphone/4s"
          element={<RepairIphoneModelPage4s />}
        />
        <Route
          path="dostavka"
          element={
            <DeliveryPage
              isDeliveryFormApplication={isDeliveryFormApplication}
              setIsDeliveryFormApplication={setIsDeliveryFormApplication}
            />
          }
        />
        <Route path="sotrudnichestvo" element={<CooperationPage />} />
        <Route path="kontakty" element={<AboutCompanyPage />} />
        <Route path="klientam-strahovyh" element={<InsuranceClients />} />
        <Route path="admin-login" element={<Admin />} />
        <Route path="admin-register" element={<Admin />} />
        <Route path="admin-page" element={<AdminLayout />}>
          <Route path="" element={<AdminIndex />} />
          <Route path="users" element={<AdminUserList />} />
          <Route path="users/:id" element={<AdminUserDetail />} />
          <Route path="notifications" element={<AdminNotificationList />} />
          <Route
            path="notifications/:id"
            element={<AdminNotificationDetail />}
          />
          <Route path="otzivy" element={<AdminFeedbacksList />} />
          <Route path="otzivy/:id" element={<AdminFeedbackDetail />} />
        </Route>
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/4s"
          element={<RepairIphoneServiceLinkBatteryModels4s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/5"
          element={<RepairIphoneServiceLinkBatteryModels5 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/5s"
          element={<RepairIphoneServiceLinkBatteryModels5s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/5c"
          element={<RepairIphoneServiceLinkBatteryModels5c />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/se"
          element={<RepairIphoneServiceLinkBatteryModelsSE />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/6"
          element={<RepairIphoneServiceLinkBatteryModels6 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/6-plus"
          element={<RepairIphoneServiceLinkBatteryModels6Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/6s"
          element={<RepairIphoneServiceLinkBatteryModels6s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/6s-plus"
          element={<RepairIphoneServiceLinkBatteryModels6sPlus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/7"
          element={<RepairIphoneServiceLinkBatteryModels7 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/7-plus"
          element={<RepairIphoneServiceLinkBatteryModels7Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/8"
          element={<RepairIphoneServiceLinkBatteryModels8 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/8-plus"
          element={<RepairIphoneServiceLinkBatteryModels8Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora/x"
          element={<RepairIphoneServiceLinkBatteryModelsX />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/4s"
          element={<RepairIphoneServiceLinkCameraModels4s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/5"
          element={<RepairIphoneServiceLinkCameraModels5 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/5s"
          element={<RepairIphoneServiceLinkCameraModels5s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/se"
          element={<RepairIphoneServiceLinkCameraModelsSE />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/5c"
          element={<RepairIphoneServiceLinkCameraModels5c />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/6"
          element={<RepairIphoneServiceLinkCameraModels6 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/6s"
          element={<RepairIphoneServiceLinkCameraModels6s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/6-plus"
          element={<RepairIphoneServiceLinkCameraModels6Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/6s-plus"
          element={<RepairIphoneServiceLinkCameraModels6sPlus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/7"
          element={<RepairIphoneServiceLinkCameraModels7 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/7-plus"
          element={<RepairIphoneServiceLinkCameraModels7Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/8"
          element={<RepairIphoneServiceLinkCameraModels8 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/8-plus"
          element={<RepairIphoneServiceLinkCameraModels8Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery/x"
          element={<RepairIphoneServiceLinkCameraModelsX />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/4s"
          element={<RepairIphoneServiceLinkSpeakerModels4s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/5"
          element={<RepairIphoneServiceLinkSpeakerModels5 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/5s"
          element={<RepairIphoneServiceLinkSpeakerModels5s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/5c"
          element={<RepairIphoneServiceLinkSpeakerModels5c />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/se"
          element={<RepairIphoneServiceLinkSpeakerModelsSE />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/6"
          element={<RepairIphoneServiceLinkSpeakerModels6 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/6s"
          element={<RepairIphoneServiceLinkSpeakerModels6s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/6-plus"
          element={<RepairIphoneServiceLinkSpeakerModels6Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/6s-plus"
          element={<RepairIphoneServiceLinkSpeakerModels6sPlus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/7"
          element={<RepairIphoneServiceLinkSpeakerModels7 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/7-plus"
          element={<RepairIphoneServiceLinkSpeakerModels7Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/8"
          element={<RepairIphoneServiceLinkSpeakerModels8 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/8-plus"
          element={<RepairIphoneServiceLinkSpeakerModels8Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika/X"
          element={<RepairIphoneServiceLinkSpeakerModelsX />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/4s"
          element={<RepairIphoneServiceLinkMicrophoneModels4s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/5"
          element={<RepairIphoneServiceLinkMicrophoneModels5 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/5s"
          element={<RepairIphoneServiceLinkMicrophoneModels5s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/5c"
          element={<RepairIphoneServiceLinkMicrophoneModels5c />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/SE"
          element={<RepairIphoneServiceLinkMicrophoneModelsSE />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/6"
          element={<RepairIphoneServiceLinkMicrophoneModels6 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/6s"
          element={<RepairIphoneServiceLinkMicrophoneModels6s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/6-plus"
          element={<RepairIphoneServiceLinkMicrophoneModels6Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/6s-plus"
          element={<RepairIphoneServiceLinkMicrophoneModels6sPlus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/7"
          element={<RepairIphoneServiceLinkMicrophoneModels7 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/7-plus"
          element={<RepairIphoneServiceLinkMicrophoneModels7Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/8"
          element={<RepairIphoneServiceLinkMicrophoneModels8 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/8-plus"
          element={<RepairIphoneServiceLinkMicrophoneModels8Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona/x"
          element={<RepairIphoneServiceLinkMicrophoneModelsX />}
        />
        <Route
          path="remont-apple/remont-iphone/4s"
          element={<RepairIphoneModelPage4s />}
        />
        <Route
          path="remont-apple/remont-iphone/5"
          element={<RepairIphoneModelPage5 />}
        />
        <Route
          path="remont-apple/remont-iphone/5s"
          element={<RepairIphoneModelPage5s />}
        />
        <Route
          path="remont-apple/remont-iphone/se"
          element={<RepairIphoneModelPageSE />}
        />
        <Route
          path="remont-apple/remont-iphone/5c"
          element={<RepairIphoneModelPage5c />}
        />
        <Route
          path="remont-apple/remont-iphone/6"
          element={<RepairIphoneModelPage6 />}
        />
        <Route
          path="remont-apple/remont-iphone/6s"
          element={<RepairIphoneModelPage6s />}
        />
        <Route
          path="remont-apple/remont-iphone/6s-plus"
          element={<RepairIphoneModelPage6sPlus />}
        />
        <Route
          path="remont-apple/remont-iphone/6-plus"
          element={<RepairIphoneModelPage6Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/7"
          element={<RepairIphoneModelPage7 />}
        />
        <Route
          path="remont-apple/remont-iphone/7-plus"
          element={<RepairIphoneModelPage7Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/8"
          element={<RepairIphoneModelPage8 />}
        />
        <Route
          path="remont-apple/remont-iphone/8-plus"
          element={<RepairIphoneModelPage8Plus />}
        />
        <Route
          path="remont-apple/remont-iphone/x"
          element={<RepairIphoneModelPageX />}
        />
        <Route
          path="remont-apple/remont_mac_pro"
          element={<RepairMacProPage />}
        />
        <Route path="remont-apple/remont-imac" element={<RepairIMacPage />} />
        <Route
          path="remont-apple/remont-imac/chistka_matricy_imac"
          element={<RepairIMacLinkMatrixCleaning />}
        />
        <Route
          path="remont-apple/remont-imac/remont_materinskoj_platy_imac"
          element={<RepairIMacLinkMotherboard />}
        />
        <Route
          path="remont-apple/remont-imac/zamena_zhestkogo_diska_na_imac"
          element={<RepairIMacLinkHardDisk />}
        />
        <Route
          path="remont-apple/remont-imac/zamena_stekla_imac"
          element={<RepairIMacLinkDisplay />}
        />
        <Route
          path="remont-apple/remont_mac_mini"
          element={<RepairMacMiniPage />}
        />
        <Route path="remont-apple/remont-ipad" element={<RepairIpadPage />} />
        <Route
          path="remont-apple/remont-ipad/2"
          element={<RepairIpadModelPagesIpad2 />}
        />
        <Route
          path="remont-apple/remont-ipad/3"
          element={<RepairIpadModelPagesIpad3 />}
        />
        <Route
          path="remont-apple/remont-ipad/4"
          element={<RepairIpadModelPagesIpad4 />}
        />
        <Route
          path="remont-apple/remont-ipad/air"
          element={<RepairIpadModelPagesIpadAir />}
        />
        <Route
          path="remont-apple/remont-ipad/air-2"
          element={<RepairIpadModelPagesIpadAir2 />}
        />
        <Route
          path="remont-apple/remont-ipad/mini"
          element={<RepairIpadModelPagesIpadMini />}
        />
        <Route
          path="remont-apple/remont-ipad/mini-2-retina"
          element={<RepairIpadModelPagesIpadMini2Retina />}
        />
        <Route
          path="remont-apple/remont-ipad/mini-3"
          element={<RepairIpadModelPagesIpadMini3 />}
        />
        <Route
          path="remont-apple/remont-ipad/mini-4"
          element={<RepairIpadModelPagesIpadMini4 />}
        />
        <Route
          path="remont-apple/remont-ipad/pro-9"
          element={<RepairIpadModelPagesIpadPro9 />}
        />
        <Route
          path="remont-apple/remont-ipad/pro-12"
          element={<RepairIpadModelPagesIpadPro12 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-kamery"
          element={<RepairIpadServiceLinkCamera />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora"
          element={<RepairIpadServiceLinkBattery />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla"
          element={<RepairIpadServiceLinkDisplay />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa"
          element={<RepairIpadServiceLinkCase />}
        />
        <Route
          path="remont-apple/remont-ipad/ne-vidit-sim-karty"
          element={<RepairIpadServiceLinkSim />}
        />
        <Route
          path="remont-apple/remont-ipad/ne-vkljuchaetsja"
          element={<RepairIpadServiceLinkPower />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-dinamika"
          element={<RepairIpadServiceLinkSpeaker />}
        />
        <Route
          path="remont-apple/remont-ipad/ne-lovit-set"
          element={<RepairIpadServiceLinkNetwork />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/mini"
          element={<RepairIpadServiceLinkBatteryModelIpadMini />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/2"
          element={<RepairIpadServiceLinkBatteryModelIpad2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/mini-4"
          element={<RepairIpadServiceLinkBatteryModelMini4 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/3"
          element={<RepairIpadServiceLinkBatteryModel3 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/pro-12"
          element={<RepairIpadServiceLinkBatteryModelPro12 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/pro-9"
          element={<RepairIpadServiceLinkBatteryModelPro9 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/mini-3"
          element={<RepairIpadServiceLinkBatteryModelMini3 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/mini-2"
          element={<RepairIpadServiceLinkBatteryModelMini2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/air-2"
          element={<RepairIpadServiceLinkBatteryModelAir2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/4"
          element={<RepairIpadServiceLinkBatteryModel4 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-akkumuljatora/air"
          element={<RepairIpadServiceLinkBatteryModelAir />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/air"
          element={<RepairIpadServiceLinkDisplayModelAir />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/mini"
          element={<RepairIpadServiceLinkDisplayModelMini />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/2"
          element={<RepairIpadServiceLinkDisplayModel2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/4"
          element={<RepairIpadServiceLinkDisplayModel4 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/air-2"
          element={<RepairIpadServiceLinkDisplayModelAir2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/mini-2"
          element={<RepairIpadServiceLinkDisplayModelMini2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/mini-3"
          element={<RepairIpadServiceLinkDisplayModelMini3 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/pro-9"
          element={<RepairIpadServiceLinkDisplayModelPro9 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/3"
          element={<RepairIpadServiceLinkDisplayModel3 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/mini-4"
          element={<RepairIpadServiceLinkDisplayModelMini4 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-stekla/pro-12"
          element={<RepairIpadServiceLinkDisplayModelPro12 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/2"
          element={<RepairIpadServiceLinkCaseModel2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/3"
          element={<RepairIpadServiceLinkCaseModel3 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/4"
          element={<RepairIpadServiceLinkCaseModel4 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/air"
          element={<RepairIpadServiceLinkCaseModelAir />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/air-2"
          element={<RepairIpadServiceLinkCaseModelAir2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/mini"
          element={<RepairIpadServiceLinkCaseModelMini />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/mini-2"
          element={<RepairIpadServiceLinkCaseModelMini2 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/mini-3"
          element={<RepairIpadServiceLinkCaseModelMini3 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/mini-4"
          element={<RepairIpadServiceLinkCaseModelMini4 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/pro-9"
          element={<RepairIpadServiceLinkCaseModelPro9 />}
        />
        <Route
          path="remont-apple/remont-ipad/zamena-korpusa/pro-12"
          element={<RepairIpadServiceLinkCaseModelPro12 />}
        />
        <Route
          path="remont-apple/remont_apple_watch"
          element={<RepairIwatchPage />}
        />
        <Route
          path="remont-apple/remont_apple_watch/series-1"
          element={<RepairIwatchModelLink1 />}
        />
        <Route
          path="remont-apple/remont_apple_watch/series-2"
          element={<RepairIwatchModelLink2 />}
        />
        <Route
          path="remont-apple/remont_apple_watch/nike"
          element={<RepairIwatchModelLinkNike />}
        />
        <Route
          path="remont-apple/remont_apple_watch/edition"
          element={<RepairIwatchModelLinkEdition />}
        />
        <Route
          path="remont-apple/remont_apple_watch/sport"
          element={<RepairIwatchModelLinkSport />}
        />
        <Route path="remont-apple/remont-ipod" element={<RepairIpodPage />} />
        <Route
          path="remont-apple/remont-ipod/classic"
          element={<RepairIpodModelLinkClassic />}
        />
        <Route
          path="remont-apple/remont-ipod/nano-7g"
          element={<RepairIpodModelLinkNano7 />}
        />
        <Route
          path="remont-apple/remont-ipod/nano-6g"
          element={<RepairIpodModelLinkNano6 />}
        />
        <Route
          path="remont-apple/remont-ipod/5g"
          element={<RepairIpodModelLinkNano5 />}
        />
        <Route
          path="remont-apple/remont-ipod/1G-2G-3G"
          element={<RepairIpodModelLinkTouch321 />}
        />
        <Route
          path="remont-apple/remont-macbook"
          element={<RepairMacBookPage />}
        />
        <Route
          path="remont-apple/remont-macbook/zamena_akkumuljatora_macbook"
          element={<RepairMacBookLinkBattery />}
        />
        <Route
          path="remont-apple/remont-macbook/zalil_macbook"
          element={<RepairMacBookLinkWater />}
        />
        <Route
          path="remont-apple/remont-macbook/zamena_remont_tachpad_na_macbook"
          element={<RepairMacBookLinkTouchpad />}
        />
        <Route
          path="remont-apple/remont-macbook/sbros_parolya_MacBook"
          element={<RepairMacBookLinkPassword />}
        />
        <Route
          path="remont-apple/remont-macbook/zamena_remont_klaviatury_macbook_pro_air"
          element={<RepairMacBookLinkKeyboard />}
        />
        <Route
          path="remont-apple/remont-macbook/zamena_matrici_MacBook"
          element={<RepairMacBookLinkMatrix />}
        />
        <Route
          path="remont-apple/remont-macbook/zamena_operativnoj_pamyati_na_MacBook_Pro"
          element={<RepairMacBookLinkMemory />}
        />
        <Route
          path="remont-apple/remont-macbook/vosstanovlenie_dannyh_na_MacBook_iMac"
          element={<RepairMacBookLinkData />}
        />
        <Route
          path="remont-apple/remont-macbook/zamena_ssd_na_macbook_pro_air"
          element={<RepairMacBookLinkSSD />}
        />
        <Route
          path="remont-apple/remont-macbook/ustanovka_windows_na_macbook_imac"
          element={<RepairMacBookLinkWindows />}
        />
        <Route
          path="remont-apple/remont-iphone"
          element={<RepairIphonePage />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja"
          element={<RepairIphoneServiceLinkDisplay />}
        />
        <Route
          path="remont-apple/remont-iphone/zalil_iphone"
          element={<RepairIphoneServiceLinkWater />}
        />
        <Route
          path="remont-apple/remont-iphone/ne-zarjazhaetsja"
          element={<RepairIphoneServiceLinkPower />}
        />
        <Route
          path="remont-apple/remont-iphone/ne-lovit-set"
          element={<RepairIphoneServiceLinkNetwork />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-akkumuljatora"
          element={<RepairIphoneServiceLinkBattery />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-kamery"
          element={<RepairIphoneServiceLinkCamera />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-knopok"
          element={<RepairIphoneServiceLinkButton />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-dinamika"
          element={<RepairIphoneServiceLinkSpeaker />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-mikrofona"
          element={<RepairIphoneServiceLinkMicrophone />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/4s"
          element={<RepairIphoneServiceLinkDisplayModels4s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/5"
          element={<RepairIphoneServiceLinkDisplayModels5 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/5s"
          element={<RepairIphoneServiceLinkDisplayModels5s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/5c"
          element={<RepairIphoneServiceLinkDisplayModels5c />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/se"
          element={<RepairIphoneServiceLinkDisplayModelsSE />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/6"
          element={<RepairIphoneServiceLinkDisplayModels6 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/6s"
          element={<RepairIphoneServiceLinkDisplayModels6s />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/6-plus"
          element={<RepairIphoneServiceLinkDisplayModels6plus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/6s-plus"
          element={<RepairIphoneServiceLinkDisplayModels6sPlus />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/7"
          element={<RepairIphoneServiceLinkDisplayModels7 />}
        />
        <Route
          path="remont-apple/remont-iphone/zamena-displeja/7-plus"
          element={<RepairIphoneServiceLinkDisplayModels7Plus />}
        />
        <Route
          path="center-mobile/remont-smartfonov-samsung"
          element={<CenterMobileIRepairMobile />}
        />
        <Route
          path="center-mobile/remont-smartfonov-fly"
          element={<CenterMobileIRepairFly />}
        />
        <Route
          path="center-mobile/remont-smartfonov-lenovo"
          element={<CenterMobileRepairMobileLenovo />}
        />
        <Route
          path="center-mobile/remont-smartfonov-lg"
          element={<CenterMobileRepairMobileLG />}
        />
        <Route
          path="center-mobile/remont-smartfonov-nokia"
          element={<CenterMobileRepairMobileNokia />}
        />
        <Route
          path="center-mobile/remont-smartfonov-htc"
          element={<CenterMobileRepairMobileHTC />}
        />
        <Route
          path="center-mobile/remont-smartfonov-philips"
          element={<CenterMobileRepairMobilePhillips />}
        />
        <Route
          path="center-mobile/remont-smartfonov-sony"
          element={<CenterMobileRepairMobileSony />}
        />
        <Route
          path="center-mobile/remont-planshetov-asus"
          element={<CenterMobileRepairPadAsus />}
        />
        <Route
          path="center-mobile/remont-planshetov-explay"
          element={<CenterMobileRepairPadExplay />}
        />
        <Route
          path="center-mobile/remont-planshetov-samsung"
          element={<CenterMobileRepairPadSamsung />}
        />
        <Route
          path="center-mobile/remont-planshetov-sony"
          element={<CenterMobileRepairPadSony />}
        />
        <Route
          path="center-mobile/remont-planshetov-prestigio"
          element={<CenterMobileRepairPadPrestigio />}
        />
        <Route
          path="center-mobile/remont-planshetov-acer"
          element={<CenterMobileRepairPadAcer />}
        />
        <Route
          path="center-mobile/remont-planshetov-lenovo"
          element={<CenterMobileRepairPadLenovo />}
        />
        <Route
          path="center-mobile/remont-planshetov-digma"
          element={<CenterMobileRepairPadDigma />}
        />
        <Route
          path="note-expert/nastroyka"
          element={<NoteExpertNoteSettingLink />}
        />
        <Route
          path="note-expert/zamena-matricy"
          element={<NoteExpertNoteSettingMatrix />}
        />
        <Route
          path="note-expert/remont-materinskoy-plati"
          element={<NoteExpertNoteSettingMotherboard />}
        />
        <Route
          path="note-expert/zamena-klaviaturi"
          element={<NoteExpertNoteSettingKeyboard />}
        />
        <Route
          path="note-expert/chistka"
          element={<NoteExpertNoteSettingClean />}
        />
        <Route
          path="note-expert/ne-vkluchaetsya"
          element={<NoteExpertNoteSettingPower />}
        />
        <Route
          path="note-expert/peregrevaetsya"
          element={<NoteExpertNoteSettingOverheating />}
        />
        <Route
          path="note-expert/shumit"
          element={<NoteExpertNoteSettingLoud />}
        />
        <Route
          path="note-expert/perezagruzhaetsya"
          element={<NoteExpertNoteSettingRestart />}
        />
        <Route
          path="note-expert/ne-zaryazhaetsya"
          element={<NoteExpertNoteSettingCharge />}
        />
        <Route
          path="note-expert/upgrade"
          element={<NoteExpertGeneralPageServiceLinkUpgrade />}
        />
        <Route
          path="note-expert/ustanovka-ozu"
          element={<NoteExpertGeneralPageServiceLinkMemory />}
        />
        <Route
          path="note-expert/zamena-hdd"
          element={<NoteExpertGeneralPageServiceHardDisk />}
        />
        <Route
          path="note-expert/ustanovka-windows"
          element={<NoteExpertGeneralPageServiceWindows />}
        />
        <Route
          path="note-expert/ustanovka-programm"
          element={<NoteExpertGeneralPageServicePrograms />}
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );

  // console.log(JSON.stringify([...getRoutes(routes)]));

  return (
    <UserProvider>
      <div className="page">{routes}</div>
    </UserProvider>
  );
};

const getRoutes = (routes) => {
  const paths = new Set();
  /**
   * Walk in list of routes tree
   * @param element {JSX.Element}
   */
  const walkTree = (element) => {
    if (element.props.children && element.props.children.length > 0) {
      element.props.children.forEach((elem) => walkTree(elem));
    } else if (element.props.path && typeof element.props.path === "string") {
      paths.add(element.props.path);
    }
  };
  walkTree(routes);
  return paths;
};

export default App;
