import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera4s.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCamera4s = [
  {
    id: 1,
    title: "Когда нужна замена камеры iPhone 4s?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          От поломок не застрахован никто – даже самая качественная техника
          может выйти из строя. Особенно, если пролить на неё жидкость, уронить
          или же ударить. О том, что необходима замена камеры iPhone 4s
          сигнализируют достаточно явные признаки:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Телефон перезагружается при попытке сделать снимок
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Фотографии получаются некачественными, изображение словно размыто
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Камера не запускается.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Причин возникновения подобной поломки довольно много – в большинстве
          случаев она связана с нарушением правил эксплуатации, но иногда
          неисправность появляется из-за сбоев в работе программного
          обеспечения. Чтобы получить актуальную информацию о состоянии
          устройства, будет проведена комплексная диагностика – наши специалисты
          детально исследуют смартфон и лишь после этого озвучат сроки ремонта.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Этапы замены камеры iPhone 4s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мы настоятельно не рекомендуем менять камеру своими силами – в сервис
          нередко обращаются владельцы смартфонов, которые неправильными
          действиями повредили внутренние части системы. Не стоит рисковать,
          ведь наши мастера выполнят все необходимые действия:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Разберут смартфон
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Снимут основные шлейфы и разъемы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Удалят неисправную камеру и установят новую
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Соберут устройство в обратном порядке
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Продемонстрируют владельцу отменную работу новой камеры.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Такой подход позволяет гарантировать качественное выполнение
          поставленной задачи. Мы не берем предоплату до начала работы – вы
          платите за услуги лишь после того, как мы решим возникшую проблему.
          Более того, если есть дополнительные повреждения, которые не были
          обнаружены владельцем изначально, то мы узнаем о них в процессе
          проведения диагностики и сразу же сообщим. Это позволяет озвучить
          точную стоимость работы и цену необходимых запчастей – цифра останется
          неизменной, ведь мы предлагаем клиентам честный формат сотрудничества.
        </p>
        <p className="repair-iphone-display__paragraph">
          Важно учитывать, что неисправность невозможно починить – потребуется
          лишь полная замена камеры iPhone 4s. Поэтому лучше обратиться в
          специализированный сервис, в котором вы получите качественное
          обслуживание и широкий спектр необходимых услуг – клиентам Мак Профи
          будет оказана необходимая помощь.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels4s = () => {
  return (
    <Page title="Срочная замена камеры iPhone 4s">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/4s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена камеры iPhone 4s в Москве. Новая камера для iPhone 4s в сервисе Oh!MyGadget!."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone 4s"
          titleImg="Замена камеры iPhone 4s"
          banner={banner}
          title="Замена камеры iPhone 4s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Быстрая замена камеры iPhone 4s: качественно, недорого, с
                гарантией. Только качественные комплектующие и приятные цены.
                Диагностика – бесплатно.
              </p>
              <p className="repair-iphone-display__paragraph">
                Во многом смартфоны Apple заслужили популярность благодаря
                отменному качеству изображений, а потому и выход из строя камеры
                является существенной поломкой. Чтобы не лишаться ярких снимков,
                вы можете обратиться в сервисный центр – и в течение 30 минут
                проблема будет решена.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="900 P"
          priceDown="1100 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera4s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels4s;
