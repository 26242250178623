import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery8.png";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfBattery8 = [
  {
    id: 1,
    title: "Что приводит к замене аккумулятора iPhone 8",
    text: "Однако почему же возникают эти проблемы с аккумулятором? Практика показывает, что они вовсе не случайны, и помимо банального износа, к проблемам приводит попадание воды, неаккуратное обращение, не соблюдение правил первых этапов эксплуатации батареи. Стоит понимать, что при внимательном обращении с устройством проблем возникает минимальное количество, и потому определенно стоит приучить себя к аккуратности. Но если проблема уже произошла и необходимо ее устранить – здесь следует отдать должное поиску профессионального исполнителя, который сможет успешно справиться с подобной задачей и обеспечить вам хороший результат.",
  },
  {
    id: 2,
    title: "Замена аккумулятора iPhone 8 в мастерской Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если с техникой произошла беда, если по той или иной причине
          аккумулятор перестал работать исправно – починить технику путем его
          замены не составит особого труда. Наша компания специализируется на
          ремонте данных устройств, и в частности, мы можем произвести быструю
          замену аккумулятора, которая не вынудит вас долго ожидать результата и
          будет выполнена мгновенно и под гарантию. Наши специалисты оказывают
          услуги ремонта широкого профиля, справляются с ситуациями разной
          сложности, и замена аккумулятора не станет для них существенной
          проблемой. Если вы столкнулись с такой необходимостью и нуждаетесь в
          услугах срочного ремонта – в сервисе Мак Профи вам гарантированно
          окажут всю необходимую помощь
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы работаем быстро и грамотно, в профессиональных условиях и с
          предоставлением гарантированного результата. И при этом наши услуги
          обходятся не чрезмерно дорого – мы придерживаемся разумной и доступной
          для клиентов ценовой политики. Именно у нас вы сможете получить все
          необходимое без переплат, а все запчасти и услуги будут предоставлены
          вам под гарантию.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels8 = () => {
  return (
    <Page title="Срочная замена аккумулятора iPhone 8 в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora/8"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если вам потребовалась замена аккумулятора iPhone 8, много времени это не отнимет. Мастерская Oh!MyGadget! готова обеспечить профессиональный подход и скорость замены."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPhone 8"
          titleImg="Замена аккумулятора iPhone 8"
          banner={banner}
          title="Замена аккумулятора iPhone 8"
          text="Жизненный цикл любой батареи таков, что рано или поздно потребуется перезарядка батареи. Как правило, батарея Айфонов создается таким образом, что ее жизненный цикл составляет 1000 процедур зарядки – разрядки, после чего требуется замена аккумулятора iPhone 8. На сегодняшний день устройства снабжаются специальной программой, которая самостоятельно распознает состояние аккумулятора и предупреждает владельца о том, что необходимо произвести замену – это достаточно удобно."
        />
        <RepairApplePrice
          subtitle="Стоимость замены аккумулятора iPhone 8"
          text="Замена аккумулятора"
          price="3000 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Но все же, симптомы проблем с батареей тоже весьма красноречивы,
                и вы наверняка не упустите тот момент, когда необходимо
                выполнить замену. Так, среди проблем с аккумулятором, которые
                могут появиться, стоит отметить следующие:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Его быстрая разрядка – вы замечаете, что аккамулятора
                    перестает хватать и на сутки, несмотря на то, что вы
                    довольно умеренно пользуетесь устройством,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Внезапный сброс заряда – если вы недавно заряжали
                    устройство, и вдруг оно отключается, а после отключения
                    показывает минимальный заряд,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    {" "}
                    Другие проблемы такого же рода, которые просто не могут
                    оставаться незамеченными.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Проблемы с аккумулятором становятся очевидными сразу же, они
                всегда создают существенные препятствия при обращении с
                приборчиком. Их необходимо устранять, так как сам по себе
                телефон в норму не придет, и в дальнейшем проблема будет только
                усугубляться. Решаются проблемы в первую очередь заменой
                элемента, и для этого следует обратиться в мастерскую. Именно в
                профессиональном сервисном центре специалисты смогут аккуратно
                вскрыть приборчик, извлечь старый аккумулятор и поставить новый
                – высокого качества, гарантированно фирменного происхождения. В
                таком случае результат ремонта будет радовать вас на протяжении
                долгого срока.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBattery8.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels8;
