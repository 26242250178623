import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageDecisions.module.css";
import icon1 from "../../../images/icons/note-expert-decisions-icon1.svg";
import icon2 from "../../../images/icons/note-expert-decisions-icon2.svg";
import icon3 from "../../../images/icons/note-expert-decisions-icon3.svg";
import icon4 from "../../../images/icons/note-expert-decisions-icon4.svg";
import icon5 from "../../../images/icons/note-expert-decisions-icon5.svg";
import icon6 from "../../../images/icons/note-expert-decisions-icon6.svg";
import icon7 from "../../../images/icons/note-expert-decisions-icon7.svg";
import icon8 from "../../../images/icons/note-expert-decisions-icon8.svg";
import icon9 from "../../../images/icons/note-expert-decisions-icon9.svg";
import icon10 from "../../../images/icons/note-expert-decisions-icon10.svg";
import icon1mobile from "../../../images/icons/note-expert-decisions-mobile-icon1.svg";
import icon2mobile from "../../../images/icons/note-expert-decisions-mobile-icon2.svg";
import icon3mobile from "../../../images/icons/note-expert-decisions-mobile-icon3.svg";
import icon4mobile from "../../../images/icons/note-expert-decisions-mobile-icon4.svg";
import icon5mobile from "../../../images/icons/note-expert-decisions-mobile-icon5.svg";
import icon6mobile from "../../../images/icons/note-expert-decisions-mobile-icon6.svg";
import icon7mobile from "../../../images/icons/note-expert-decisions-mobile-icon7.svg";
import icon8mobile from "../../../images/icons/note-expert-decisions-mobile-icon8.svg";
import icon9mobile from "../../../images/icons/note-expert-decisions-mobile-icon9.svg";
import icon10mobile from "../../../images/icons/note-expert-decisions-mobile-icon10.svg";
import { Link } from "react-router-dom";
import { useMedia } from "../../../utils/useMedia";
import NoteExpertGeneralPageDecisionsTableScreen from "./NoteExpertGeneralPageDecisionsTableScreen";
import NoteExpertGeneralPageDecisionsMobile from "./NoteExpertGeneralPageDecisionsMobile";
import NoteExpertDecision from "./NoteExpertDecision";

const decisions = [
  {
    icon: icon1,
    text: "Настройка ноутбука от 10 минут",
    paragraph:
      "Настройка ноутбука от 200р: windows, драйвера, wi-fi, bios, дисплей, клавиатура, touchpad, камера, звук и микрофон",
    link: "/note-expert/nastroyka",
  },
  {
    icon: icon2,
    text: "Замена матрицы от 45 минут",
    paragraph:
      "Замена матрицы от 1500р: ремонт инвертора, дешифратора, замена ламп подсветки, свой склад комплектующих",
    link: "/note-expert/zamena-matricy",
  },
  {
    icon: icon3,
    text: "Ремонт материнской платы от 45 минут",
    paragraph:
      "Ремонт мат. платы от 700р: перепайка цепи питания, замена разъемов, восстановление дорожек, перепайка/реболл чипов",
    link: "/note-expert/remont-materinskoy-plati",
  },
  {
    icon: icon4,
    text: "Замена клавиатуры от 20 минут",
    paragraph:
      "Замена клавиатуры от 200р: замена кнопок, чистка и ремонт залитых, установка новых, свой склад комплектующих",
    link: "/note-expert/zamena-klaviaturi",
  },
  {
    icon: icon5,
    text: "Чистка ноутбука от 45 минут",
    paragraph:
      "Чистка ноутбука от 1700р: профилактика, удаление пыли, замена термо-интерфейсов, химическая чистка после залития",
    link: "/note-expert/chistka",
  },
  {
    icon: icon6,
    text: "Не включается - от 20 минут",
    paragraph:
      "Стоимость услуг от 700р: восстановление windows, ремонт/замена материнских плат, HDD, ОЗУ, процессоров и охлаждения",
    link: "/note-expert/ne-vkluchaetsya",
  },
  {
    icon: icon7,
    text: "Перегревается - от 1 часа",
    paragraph:
      "Стоимость услуг от 2000р: чистка, ремонт и замена системы охлаждения, ремонт цепи питания, перепайка чипов",
    link: "/note-expert/peregrevaetsya",
  },
  {
    icon: icon8,
    text: "Сильно шумит - от 1 часа",
    paragraph:
      "Стоимость услуг от 2000р: чистка от пыли, смазка и замена вентиляторов, комплексная профилактика системы охлаждения",
    link: "/note-expert/shumit",
  },
  {
    icon: icon9,
    text: "Перезагружается - от 1 часа",
    paragraph:
      "Стоимость услуг от 2000р: удаление вирусов, замена системы охлаждения, перепайка греющихся элементов",
    link: "/note-expert/perezagruzhaetsya",
  },
  {
    icon: icon10,
    text: "Не заряжается - от 1 часа",
    paragraph:
      "Стоимость услуг от 800р: замена аккумуляторов, перепайка разъема питания, ремонт цепи, восстановление дорожек",
    link: "/note-expert/ne-zaryazhaetsya",
  },
];

const decisionsForMobile = [
  {
    icon: icon1mobile,
    text: "Настройка ноутбука от 10 минут",
    paragraph:
      "Настройка ноутбука от 200р: windows, драйвера, wi-fi, bios, дисплей, клавиатура, touchpad, камера, звук и микрофон",
    link: "/note-expert/nastroyka",
  },
  {
    icon: icon2mobile,
    text: "Замена матрицы от 45 минут",
    paragraph:
      "Замена матрицы от 1500р: ремонт инвертора, дешифратора, замена ламп подсветки, свой склад комплектующих",
    link: "/note-expert/zamena-matricy",
  },
  {
    icon: icon3mobile,
    text: "Ремонт материнской платы от 45 минут",
    paragraph:
      "Ремонт мат. платы от 700р: перепайка цепи питания, замена разъемов, восстановление дорожек, перепайка/реболл чипов",
    link: "/note-expert/remont-materinskoy-plati",
  },
  {
    icon: icon4mobile,
    text: "Замена клавиатуры от 20 минут",
    paragraph:
      "Замена клавиатуры от 200р: замена кнопок, чистка и ремонт залитых, установка новых, свой склад комплектующих",
    link: "/note-expert/zamena-klaviaturi",
  },
  {
    icon: icon5mobile,
    text: "Чистка ноутбука от 45 минут",
    paragraph:
      "Чистка ноутбука от 1700р: профилактика, удаление пыли, замена термо-интерфейсов, химическая чистка после залития",
    link: "/note-expert/chistka",
  },
  {
    icon: icon6mobile,
    text: "Не включается - от 20 минут",
    paragraph:
      "Стоимость услуг от 700р: восстановление windows, ремонт/замена материнских плат, HDD, ОЗУ, процессоров и охлаждения",
    link: "/note-expert/ne-vkluchaetsya",
  },
  {
    icon: icon7mobile,
    text: "Перегревается - от 1 часа",
    paragraph:
      "Стоимость услуг от 2000р: чистка, ремонт и замена системы охлаждения, ремонт цепи питания, перепайка чипов",
    link: "/note-expert/peregrevaetsya",
  },
  {
    icon: icon8mobile,
    text: "Сильно шумит - от 1 часа",
    paragraph:
      "Стоимость услуг от 2000р: чистка от пыли, смазка и замена вентиляторов, комплексная профилактика системы охлаждения",
    link: "/note-expert/shumit",
  },
  {
    icon: icon9mobile,
    text: "Перезагружается - от 1 часа",
    paragraph:
      "Стоимость услуг от 2000р: удаление вирусов, замена системы охлаждения, перепайка греющихся элементов",
    link: "/note-expert/perezagruzhaetsya",
  },
  {
    icon: icon10mobile,
    text: "Не заряжается - от 1 часа",
    paragraph:
      "Стоимость услуг от 800р: замена аккумуляторов, перепайка разъема питания, ремонт цепи, восстановление дорожек",
    link: "/note-expert/ne-zaryazhaetsya",
  },
];

function NoteExpertGeneralPageDecisions() {
  function ChangePage() {
    // const isBreakpoint = useMedia("(min-width: 600px)");
    const isBreakpointTableScreen = useMedia("(min-width: 980px)");
    const isBreakpointMobile = useMedia("(max-width: 600px)");

    if (isBreakpointTableScreen) {
      return (
        <Section className={styles.section}>
          <div className={styles.content}>
            <h2 className={styles.title}>ваши проблемы - наши решения</h2>
            <p className={styles.subtitle}>
              Мы работаем 7 дней в неделю. Если ваш ноутбук отказывается
              работать, мы вернем его в рабочее состояние. В компетенции
              специалистов Note Expert выявление и устранение любых
              неисправностей ноутбуков Acer, Asus, Dell, HP, Lenovo, Samsung,
              Sony, Toshiba и др. – от самых простых до самых серьезных.
            </p>
            <ul className={styles.list}>
              {decisions.map((item, i) => {
                return (
                  <li key={i} className={styles.item}>
                    <div className={styles.wrapperIcon}>
                      <img
                        className={styles.icon}
                        src={item.icon}
                        alt="иконка"
                      />
                    </div>
                    <div className={styles.wrapperText}>
                      <b className={styles.text}>{item.text}</b>
                    </div>
                    <div className={styles.wrapperParagraph}>
                      <p className={styles.paragraph}>{item.paragraph}</p>
                    </div>
                    <Link className={styles.button} to={item.link}>
                      ПОДРОБНЕЕ
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Section>
      );
    } else if (isBreakpointMobile) {
      return (
        <NoteExpertGeneralPageDecisionsMobile
          decisionsForMobile={decisionsForMobile}
        >
          {decisionsForMobile.map((item, i) => {
            return (
              <NoteExpertDecision
                key={i}
                icon={item.icon}
                text={item.text}
                paragraph={item.paragraph}
                link={item.link}
              />
            );
          })}
        </NoteExpertGeneralPageDecisionsMobile>
      );
    }
    return <NoteExpertGeneralPageDecisionsTableScreen decisions={decisions} />;
  }

  return ChangePage();
}

export default NoteExpertGeneralPageDecisions;
