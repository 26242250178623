import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayMini2.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Замену стекла iPad Mini 2 Retina – снимем разбитую поверхность, которую «украшают» трещины и сколы, установив новое стекло.",
    number: "01",
  },
  {
    id: 2,
    text: "Замену тачскрина – в этом не всегда есть необходимость, всё зависит от серьезности и характера повреждений. Но если потребуется заменить не только стекло, но и сенсор, то наши специалисты успешно справятся с поставленной задачей.",
    number: "02",
  },
  {
    id: 3,
    text: "Восстановление корпуса – если не выполнить рихтовку, но деформированная конструкция будет оказывать давление на внутренние части, что приведет к скорому выходу из строя и других элементов конструкции.",
    number: "03",
  },
];

const textOfDisplay = [
  {
    id: 1,
    text: "Бесплатная диагностика",
    number: "01",
  },
  {
    id: 2,
    text: "Курьерская доставка",
    number: "02",
  },
  {
    id: 3,
    text: "Долгосрочная гарантия.",
    number: "03",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title:
      "Полный комплекс услуг: от замены стекла iPad Mini 2 Retina до рихтовки корпуса",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Первоначальный этап любого ремонта – проведение диагностики, после которой специалисты смогут приступить к работе. Благодаря этому будут выявлены любые дефекты, а владелец сможет получить подробную информацию о состоянии устройства. Если экран был разбит в результате падения, то мы готовы предоставить полный комплекс услуг, который включает в себя:"
          array={listOfTrouble}
          paragraphDown="Окончательная стоимость и сроки восстановления будут озвучены только после посещения сервиса. Но вы можете ознакомиться с текущими расценками, представленными на сайте, чтобы оценить выгоды нашего предложения."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Замена стекла iPad Mini 2 Retina: быстро и с гарантией качества",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Наша основная задача: предоставить клиентам качественный сервис на стабильно высоком уровне, выполняя быстрый и профессиональный ремонт техники Apple. Именно у нас, вы сможете получить целый ряд выгод:"
          array={textOfDisplay}
          paragraphDown="Мы используем только качественные комплектующие, а наши мастера – настоящие профессионалы, которые хорошо знакомы с тонкостями восстановления различных типов устройств."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelMini2 = () => {
  return (
    <Page title="Грамотная замена стекла на iPad Mini 2 в кратчайшие сроки!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/mini-2"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена стекла iPad Mini 2 Retina – сервисный центр Oh!MyGadget! готов оказать своим клиентам грамотную профессиональную помощь, предоставить прекрасный результат! "
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена стекла ipad mini 2"
          titleImg="замена стекла ipad mini 2"
          banner={banner}
          title={<>Замена стекла iPad&nbsp;Mini&nbsp;2</>}
          text="Те, кто активно пользуются планшетом iPad Mini 2 Retina, рано или поздно сталкиваются с необходимостью замены стекла на устройстве. Перед разработчиками стояла задача создать легкую и компактную конструкцию, которая в результате оказалась достаточно хрупкой. Поэтому, если вы ударили, раздавили или же уронили свой планшет, обратитесь в сервисный центр Мак Профи – предложим специальные условия и выгодные расценки на все виды услуг."
        />
        <Section className="section_group">
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены стекла iPad Mini 2"
          text="Замена сенсорного стекла (тачскрина)"
          price="3400 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Мы предлагаем выгодные условия, регулярно действуют скидки и
                специальные предложения, благодаря чему вы сможете сэкономить.
                Качественный ремонт должен быть доступен по цене, и обратившись
                к нам, вы сможете в этом убедиться.
              </p>
              <p className="repair-iphone-display__paragraph">
                Чтобы узнать подробнее, позвоните по контактным телефонам, либо
                же сразу приезжайте в сервисный центр. Детально ответим на все
                вопросы, и выполним замену стекла iPad Mini 2 Retina в
                кратчайшие сроки.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelMini2;
