import React, { useEffect, useState } from "react";
import { BlockTitle } from "../../components/index";
import { arrowUp, notificationIcon } from "../../images/index";
import { notificationsApi } from "../../utils/api";

const Notification = ({
  notificationTitle,
  notificationSubtitle,
  notificationText,
  notifications,
}) => {
  const [activeNotification, setActiveNotification] = useState();
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const fetchActiveNotification = async () => {
      try {
        const activeNotification = await notificationsApi.getNotifications({
          active: true,
          limit: 1,
        });
        setActiveNotification(activeNotification.results[0]);
      } catch (error) {
        setIsError(false);
      }
    };

    fetchActiveNotification();
  }, []);

  const sectionClassName = isOpen
    ? "notification"
    : "notification notification-hidden";

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {activeNotification && !isError && (
        <section className={sectionClassName}>
          <div className="notification__container">
            <BlockTitle title={activeNotification.title} />
            <div className="notification__block">
              <div className="notification__wrapper">
                <img
                  alt="иконка блока оповещения"
                  src={notificationIcon}
                  className="notification__icon"
                />
                <aside className="notification__text-block">
                  {activeNotification.subtitle && (
                    <h2 className="notification__subtitle">
                      {activeNotification.subtitle}
                    </h2>
                  )}
                  <p className="notification__paragraph">
                    {activeNotification.text}
                  </p>
                </aside>
              </div>
              <button onClick={handleClick} className="notification__button">
                <img
                  className="notification__button-icon"
                  alt="кнопка закрытия оповещения"
                  src={arrowUp}
                />
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Notification;
