import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone5.jpeg";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfModel5 = [
  {
    id: 1,
    title: "Схема ремонта Айфон 5",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          С начала, мы проводим всестороннюю, и притом бесплатную, диагностику
          вашего смартфона. После этого наш менеджер опишет и разъяснит вам все
          выявленные неисправности и озвучит полную и окончательную сумму
          ремонта. Далее за дело уже берутся наши специалисты. Ремонт вашего
          Айфон 5 вы оплачиваете только после того, как получите на руки
          исправный телефон и сможете удостовериться в его исправности. Как
          видите все очень просто, к тому же ценообразование абсолютно
          прозрачно, и вы всегда можете решить за что вы платите.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады увидеть вас в нашем центре или же ответить на любые
          возникшие вопросы по телефону!
        </p>
      </>
    ),
  },
];

const price5 = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "2450 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "1450 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1300 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "990 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "1200 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1250 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage5 = () => {
  return (
    <Page title="Ремонт iPhone 5 в Москве, стоимость ремонта Айфон 5 в сервисном центре!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/5"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт Айфона 5 в Москве и всей России. Бесплатная курьерская служба по Москве, доставка в сц по всей России, обращайтесь!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 5"
          titleImg="Ремонт iPhone 5"
          banner={banner}
          title="Ремонт iPhone 5"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Профессиональный и быстрый ремонт iPhone 5 в Москве - вот в двух
                словах чем мы занимаемся. Вы на нашем сайте - значит с вашим
                надежным мобильным помощником приключилось несчастье. Мы с
                удовольствием поможем вам за скромное вознаграждение. Не
                убедили? Тогда вот вам 3 отличные причины обратится за ремонтом
                Айфона 5 именно к нам:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Вам не придётся надолго расставаться со своим любимым
                    смартфоном. На починку большинства поломок у нас уходит не
                    более двадцати минут. Не пройдёт и получаса, как ваш Айфон 5
                    опять будет радовать всем разнообразием своих возможностей.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Вам даже не обязательно подъезжать к нам, в наши офисы,
                    которые, кстати, расположены очень удобно - при
                    необходимости, наш курьер подъедет к вам домой, а потом
                    отдаст телефон из ремонта прямиком на руки.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Естественно, на все виды ремонта мы предоставляем гарантию.
                    Мы уверены в качестве нашей работы, поэтому гарантия у нас -
                    до 12 месяцев!
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Каждый день наши специалисты возвращают к жизни десятки Айфонов,
                запчасти, причем только качественные, всегда у нас на складе, и
                мы ещё не сталкивались с болячками телефона, которые были бы нам
                не по зубам! Так что, будь то разбитое стекло, барахлящий
                аккумулятор, сломанная кнопка, или любая другая поломка iPhone 5
                - все это совсем не повод для расстройства. Просто позвоните нам
                и мы решим все проблемы вашего телефона.
              </p>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price5}
            title="Стоимость ремонта iPhone 5"
            text="*Все цены указаны в рублях РФ. Стоимость включает комплектующее и установку."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel5.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage5;
