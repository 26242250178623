import React from "react";
import styles from "./NoteExpertInsidePageAdvantages.module.css";

function NoteExpertAdventage(props) {
  return (
    <div className={styles.item}>
      <div className={styles.circle}>
        <img className={styles.image} src={props.icon} alt="" />
      </div>
      <h4 className={styles.subtitle}>{props.title}</h4>
      <p className={styles.text}>{props.text}</p>
    </div>
  );
}

export default NoteExpertAdventage;
