import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
} from "../index";
import banner from "../../images/banners/mb-password.jpeg";
import { Helmet } from "react-helmet";

const listOfPassword = [
  {
    id: 1,
    number: "01",
    subtitle: "Установили пароль в Mac OS и забыли его.",
    textLi:
      "Проблема встречается повсеместно, ребенок установил случайно пароль, забыли комбинацию клавиш и не можете войти в Mac OS. Решим данную проблему, произведем сброс пароля с сохранением пользовательских данных и установленных вами программ. Стоимость сброса пароля MacBook в данном случае 1500 руб.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Потерялась связка ключей в Mac OS.",
    textLi:
      "Проблема может возникнуть в результате системного сбоя в Mac OS, вы точно знаете свой пароль, но он не подходит? Поможем решить и эту неисправность. Произведем сброс связки ключей и установим новый пароль в Mac OS. Стоимость восстановление связки ключей и сброса пароля MacBook 2300 руб.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Поставили пароль EFI и забыли его.",
    textLi:
      "В данном случае потребуется разблокировка Макбук аппаратным методом. В частности меняется EFI на новый. Стоимость работы определяется стоимостью чипа для вашей модели MacBook и начинается от 5000 руб. Производится аппаратная замена EFI на новый. Точную стоимость вы можете уточнить у оператора по телефону сообщив модель вашего MacBook.",
  },
];

const RepairMacBookLinkPassword = () => {
  return (
    <Page title="Сброс пароля на MacBook Pro, Air, Retina, разблокировка Макбук в Mac OS">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/sbros_parolya_MacBook"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Производим разблокировку и сброс пароля на MacBook Pro, Air, Retina. Делаем разблокировку и сброс пароля в кратчайшие сроки с сохранением пользовательских данных."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="сброс пароля macbook"
          titleImg="сброс пароля macbook"
          banner={banner}
          title="Сброс пароля на MacBook Pro, Air, Retina, разблокировка Макбук в Mac OS"
          text="Забыли пароль в MacBook? Произведем сброс пароля на MacBook Pro, Air, Retina в кратчайшие сроки. В каких случаях требуется разблокировка Макбук:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfPassword.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkPassword;

// toDo:
// импортировать банер из индекса
// перенести списки в константы
