import React, { createContext, useState, useEffect, useContext } from "react";
import { myFetch } from "../utils/api/myFetch";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const value = { user, setUser };

  useEffect(() => {
    let ignore = false;

    const fetchUser = async () => {
      try {
        const data = await myFetch("admin-authenticate");
        if (!ignore) {
          setUser(data);
        }
      } catch (error) {
        if (!ignore) {
          setUser(null);
        }
      }
    };

    fetchUser();
    return () => (ignore = true);
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const value = useContext(UserContext);

  if (value === undefined) {
    throw new Error(`useUser() must be used within <UserProvider />`);
  }

  return value;
};
