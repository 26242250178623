import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpad4.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfText = [
  {
    id: 1,
    text: "Мастер проведет диагностику",
    number: "01",
  },
  {
    id: 2,
    text: "Ознакомит клиента с предстоящим спектром работ и озвучит точную стоимость",
    number: "02",
  },
  {
    id: 3,
    text: "Специалист разберет планшет, установит новый корпус и соберет устройство в обратном порядке",
    number: "03",
  },
  {
    id: 4,
    text: "Владельцу будет продемонстрирована стабильная работа iPad.",
    number: "04",
  },
];

const textOfCase4 = [
  {
    id: 1,
    title: "Почему важно своевременная замена корпуса iPad 4",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Далеко не всегда нужна замена корпуса iPad 4 – в некоторых случаях
          можно обойтись и рихтовкой. Но важно учитывать, что определить
          серьезность проблемы способен исключительно специалист, который
          проведет комплексное тестирование системы. Нередко деформация крышки
          является неединственной проблемой, на которую нужно обратить внимание
          – наши мастера обязательно согласуют ремонт с заказчиком, устранив
          выявленные неполадки еще до того, как они отразятся на работе
          устройства.
        </p>
        <p className="repair-iphone-display__paragraph">
          И это еще один довод в пользу отказа от самостоятельного ремонта. При
          наличии необходимого комплекта инструментов можно разобрать планшет –
          хоть и существует серьезный риск его повредить. Вот только из-за
          отсутствия предварительной диагностики, вскоре могут проявиться
          различные неполадки, а потому в любом случае посещения сервисного
          центра не избежать.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Этапы замены корпуса iPad 4",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чтобы заменить испорченный корпус, который «украшают» сколы, царапины и прочие дефекты, вам необходимо обратиться в наш сервисный центр. Процедура займет минимум времени, и они включает в себя следующие этапы:"
          array={listOfText}
          paragraphDown="Вы платите за предоставленные услуги только после того, как они будут получены в полном объеме. На все работы, а также использованные запчасти, в обязательном порядке будет действовать гарантия качества – у вас не возникнет проблем с планшетом, произошедших по вине наших мастеров."
          paragraphDown2="Ознакомьтесь с расценками на сайте и свяжитесь с нами по указанным контактным телефонам, если возникнут дополнительные вопросы о стоимости и сроках проведения ремонта. Мы позаботимся о том, чтобы замена корпуса iPad 4 была выполнена максимально быстро – в сервисе Мак Профи большинство работ проводятся в присутствии клиента."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModel4 = () => {
  return (
    <Page title="Быстрая замена корпуса iPad 4 в день обращения в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена корпуса iPad 4 в Oh!MyGadget!: квалифицированные мастера и специализированное оборудование. Качественный сервис в Москве по честным ценам."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad 4"
          titleImg="замена корпуса ipad 4"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;4</>}
          text="Если после падения планшета стекло сохранилось в целости, а поврежден только корпус – не спешите радоваться. Деформация конструкции в будущем может привести к нарушениям работы устройства, и чем дольше владелец откладывает посещение сервиса, тем дороже ему это может обойтись. Наши двери открыты ежедневно – обратитесь для проведения бесплатной диагностики и ремонта, чтобы восстановить работоспособность планшета."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="5950 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCase4.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModel4;
