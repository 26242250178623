import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIpodModelLinks,
} from "../index";
import banner from "../../images/banners/ipodClassic.jpeg";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Диагностика – именно с этого этапа начинается любой ремонт. Для клиентов сервиса процедура проводится абсолютно бесплатно.",
    number: "01",
  },
  {
    id: 2,
    text: "Разбор устройства – мастер бережно удалит элементы, которые мешают добраться до неисправной детали.",
    number: "02",
  },
  {
    id: 3,
    text: "Установка новых комплектующих – мы строго следим за тем, чтобы в наличии всегда был достаточный запас запчастей, а потому ремонт удается выполнять в сжатые сроки.",
    number: "03",
  },
  {
    id: 4,
    text: "Сборка гаджета – финальный этап ремонта iPod Classic. Специалист соберет все детали воедино, после чего включит плеер и продемонстрирует его работу.",
    number: "04",
  },
];

const reasons = [
  {
    id: 1,
    text: "Ремонт техники в сжатые сроки, что стало возможно благодаря наличию достаточного количества запчастей в запасе;",
    number: "01",
  },
  {
    id: 2,
    text: "Профессиональный подход, который заключается в проведении предварительной диагностики и быстрой замене неисправных элементов",
    number: "02",
  },
  {
    id: 3,
    text: "Гарантия качества, распространяющаяся не только на используемые детали, но и проведенную работу.",
    number: "03",
  },
];

const textIpodClassic = [
  {
    id: 1,
    title: "Особенности ремонта iPod Classic",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="В телефонном режиме вы сможете получить ответы на все интересующие вопросы, а также вызвать курьера. После того как устройство будет доставлено в сервисный центр, мы предоставим следующие услуги:"
          array={listOfTrouble}
          paragraphDown="Оплата производится только после того, как клиент убедиться в качественном выполнении поставленной задачи. Вы сможете самостоятельно проверить, насколько хорошо техника выполняет основные функции, убедиться в её отменном быстродействии – и лишь после этого заплатить нам за проведенный ремонт."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Качественный ремонт iPod Classic по доступным ценам",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чтобы не заниматься ремонтом самостоятельно, вы можете посетить сервисный центр Мак Профи. Благодаря удобному расположению сервиса возле станции метро Белорусская, вы можете комфортно добраться как на личном, так и общественном транспорте."
          paragraphUp2="Кроме того, нашим посетителям доступны курьерские услуги, бесплатные для столицы – мы самостоятельно заберем устройство у владельца, а после ремонта доставим его обратно. И это далеко не единственное преимущество, которое мы готовы предложить клиентам:"
          array={reasons}
          paragraphDown="Ремонт iPod Classic может потребоваться по разным причинам, начиная от случайного удара и заканчивая ошибками в программном обеспечении. Но эта проблема легко решаема, если обратиться к профессионалам – обычно такая работа занимает полчаса, в некоторых случаях может потребоваться оставить устройство в сервисе на сутки. Обращаясь в Мак Профи, вы получаете максимум при минимальных затратах."
        />
      </>
    ),
  },
];

const RepairIpodModelLinkClassic = () => {
  return (
    <Page title="Быстрый ремонт iPod Classic">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipod/classic"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт iPod Classic: быстрое восстановление классической серии Apple iPod"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPod Classic"
          titleImg="Ремонт iPod Classic"
          banner={banner}
          title="Ремонт iPod Classic"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Сервис Мак Профи в Москве: выполним ремонт iPod Classic на
                выгодных условиях. Гарантия честной цены. Оригинальные
                комплектующие и специализированное оборудование.
              </p>
              <p className="repair-iphone-display__paragraph">
                Вам нужен ремонт iPod Classic? Вы можете воспользоваться этой
                услугой в сервисе Мак Профи – команда опытных инженеров устранит
                любые неполадки. Вне зависимости от серьезности поломки, мы
                сделаем все возможное для быстрого решения проблемы –
                восстановим устройство, используя оригинальные запчасти.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIpodClassic.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpodModelLinks />
      </main>
    </Page>
  );
};

export default RepairIpodModelLinkClassic;
