import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobilePrice.module.css";
import CenterMobileIRepairMobilePriceMobile from "./CenterMobileIRepairMobilePriceMobile";
import { useMedia } from "../../../utils/useMedia";

// function ChangePage(samsungMobilePrice, samsungMobilePrice2) {
//   const isBreakpointMobile = useMedia("(min-width: 390px)");

//   if (isBreakpointMobile) {
//     return (
//       <Section id="pricelist" className={styles.priceSection}>
//         <div className={styles.content}>
//           <h2 className={styles.title}>ВСЕ ЦЕНЫ – ПОЛНЫЙ ПРАЙС ЛИСТ</h2>
//           <div className={styles.tableHeader}>
//             <p className={styles.headerTextService}>УСЛУГИ</p>
//             <div className={styles.wrapper}>
//               <p className={styles.headerText}>ЦЕНА</p>
//               <p className={styles.headerTextTime}>ВРЕМЯ РЕМОНТА</p>
//             </div>
//           </div>
//           <h4 className={styles.subtitle}>РЕМОНТ И ЗАМЕНА ДЕТАЛЕЙ</h4>
//           <ul className={styles.list}>
//             {samsungMobilePrice.map((item) => {
//               return (
//                 <li
//                   key={item.id}
//                   className={styles.item}
//                   style={{
//                     backgroundColor: item.color,
//                     boxShadow: item.boxShadow,
//                   }}
//                 >
//                   <p className={styles.listText}>{item.text}</p>
//                   <div className={styles.listWrapper}>
//                     <p className={styles.listPrice}>{item.price}</p>
//                     <p className={styles.listTime}>{item.time}</p>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//           <h4 className={styles.subtitle}>ПРОФИЛАКТИКА И ЧИСТКА</h4>
//           <ul className={styles.list}>
//             {samsungMobilePrice2.map((item) => {
//               return (
//                 <li
//                   key={item.id}
//                   className={styles.item}
//                   style={{
//                     backgroundColor: item.color,
//                     boxShadow: item.boxShadow,
//                   }}
//                 >
//                   <p className={styles.listText}>{item.text}</p>
//                   <div className={styles.listWrapper}>
//                     <p className={styles.listPrice}>{item.price}</p>
//                     <p className={styles.listTime}>{item.time}</p>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//           <div className={styles.border}></div>
//           <p className={styles.paragraph}>* Все цены указаны в рублях РФ.</p>
//           <button className={styles.button}>Скачать прайс-лист</button>
//         </div>
//       </Section>
//     );
//   }
//   return (
//     <CenterMobileIRepairMobilePriceMobile
//       samsungMobilePrice={samsungMobilePrice}
//       samsungMobilePrice2={samsungMobilePrice2}
//     />
//   );
// }

function CenterMobileIRepairMobilePrice({
  samsungMobilePrice,
  samsungMobilePrice2,
}) {
  return (
    <Section id="pricelist" className={styles.priceSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>ВСЕ ЦЕНЫ – ПОЛНЫЙ ПРАЙС ЛИСТ</h2>
        <div className={styles.tableHeader}>
          <p className={styles.headerTextService}>УСЛУГИ</p>
          <div className={styles.wrapper}>
            <p className={styles.headerText}>ЦЕНА</p>
            <p className={styles.headerTextTime}>ВРЕМЯ РЕМОНТА</p>
          </div>
        </div>
        <h4 className={styles.subtitle}>РЕМОНТ И ЗАМЕНА ДЕТАЛЕЙ</h4>
        <ul className={styles.list}>
          {samsungMobilePrice.map((item) => {
            return (
              <li
                key={item.id}
                className={styles.item}
                style={{
                  backgroundColor: item.color,
                  boxShadow: item.boxShadow,
                }}
              >
                <p className={styles.listText}>{item.text}</p>
                <div className={styles.listWrapper}>
                  <p className={styles.listPrice}>{item.price}</p>
                  <p className={styles.listTime}>{item.time}</p>
                </div>
              </li>
            );
          })}
        </ul>
        <h4 className={styles.subtitle}>ПРОФИЛАКТИКА И ЧИСТКА</h4>
        <ul className={styles.list}>
          {samsungMobilePrice2.map((item) => {
            return (
              <li
                key={item.id}
                className={styles.item}
                style={{
                  backgroundColor: item.color,
                  boxShadow: item.boxShadow,
                }}
              >
                <p className={styles.listText}>{item.text}</p>
                <div className={styles.listWrapper}>
                  <p className={styles.listPrice}>{item.price}</p>
                  <p className={styles.listTime}>{item.time}</p>
                </div>
              </li>
            );
          })}
        </ul>
        <div className={styles.border}></div>
        <p className={styles.paragraph}>* Все цены указаны в рублях РФ.</p>
        <button className={styles.button}>Скачать прайс-лист</button>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobilePrice;
