import React from "react";
import Section from "../../Section/Section";
import iconMobileMini from "../../../images/icons/center-mobile-links-mobile-mini.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

const linksMobile = [
  {
    link: "SAMSUNG",
    to: "remont-smartfonov-samsung",
  },
  {
    link: "FLY",
    to: "remont-smartfonov-fly",
  },
  {
    link: "LENOVO",
    to: "remont-smartfonov-lenovo",
  },
  {
    link: "LG",
    to: "remont-smartfonov-lg",
  },
  {
    link: "NOKIA",
    to: "remont-smartfonov-nokia",
  },
  {
    link: "HTC",
    to: "remont-smartfonov-htc",
  },
  {
    link: "PHILIPS",
    to: "remont-smartfonov-philips",
  },
  {
    link: "SONY",
    to: "remont-smartfonov-sony",
  },
];

const linksPad = [
  {
    link: "ASUS",
    to: "remont-planshetov-asus",
  },
  {
    link: "EXPLAY",
    to: "remont-planshetov-explay",
  },
  {
    link: "SAMSUNG",
    to: "remont-planshetov-samsung",
  },
  {
    link: "SONY",
    to: "remont-planshetov-sony",
  },
  {
    link: "PRESTIGIO",
    to: "remont-planshetov-prestigio",
  },
  {
    link: "ACER",
    to: "remont-planshetov-acer",
  },
  {
    link: "LENOVO",
    to: "remont-planshetov-lenovo",
  },
  {
    link: "DIGMA",
    to: "remont-planshetov-digma",
  },
];

const CenterMobileGeneralPageLinksMobile = () => {
  const [isOpenMobileLinks, setIsOpenMobileLinks] = useState(false);
  const [isOpenPadLinks, setIsOpenPadLinks] = useState(false);

  return (
    <Section>
      <div className="center-mobile__general-page-link-mobile">
        <div className="center-mobile__general-page-link-mobile-image"></div>
        <h2 className="center-mobile__general-page-link-mobile-title">
          Ремонт Смартфонов
          <button
            onClick={() => setIsOpenMobileLinks(!isOpenMobileLinks)}
            className="center-mobile__general-page-link-mobile-button"
          ></button>
        </h2>
        {isOpenMobileLinks ? (
          <ul className="center-mobile__general-page-link-mobile-list">
            {linksMobile.map((link, i) => {
              return (
                <li
                  className="center-mobile__general-page-link-mobile-item"
                  key={i}
                >
                  <Link
                    className="center-mobile__general-page-link-mobile-link"
                    to={link.to}
                  >
                    {link.link}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
        <div className="center-mobile__general-page-link-pad-image"></div>
        <h2 className="center-mobile__general-page-link-mobile-title">
          ремонт планшетов
          <button
            onClick={() => setIsOpenPadLinks(!isOpenPadLinks)}
            className="center-mobile__general-page-link-mobile-button"
          ></button>
        </h2>
        {isOpenPadLinks ? (
          <ul className="center-mobile__general-page-link-mobile-list">
            {linksPad.map((link, i) => {
              return (
                <li
                  className="center-mobile__general-page-link-mobile-item"
                  key={i}
                >
                  <Link
                    className="center-mobile__general-page-link-mobile-link"
                    to={link.to}
                  >
                    {link.link}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </Section>
  );
};

export default CenterMobileGeneralPageLinksMobile;
