import React, { useState } from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileOrderForm.module.css";
import InputMask from "react-input-mask";

function CenterMobileIRepairMobileOrderForm({ title, text }) {
  const [values, setValues] = useState({
    username: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({
    username: false,
    phoneNumber: false,
  });
  // const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  function isValidName(username) {
    return username.trim().length > 1;
  }

  function isValidNumber(phoneNumber) {
    const result =
      phoneNumber !== "" &&
      Array.from(phoneNumber).every((char) => char !== "_");
    return result;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!isValidName(values.username)) {
      setErrors((prevErrors) => ({ ...prevErrors, username: true }));
    }
    if (!isValidNumber(values.phoneNumber)) {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
    }
    if (isValidName(values.username) && isValidNumber(values.phoneNumber)) {
      console.log("форма успешно отправлена");
    }
  }

  const buttonClassName =
    isValidName(values.username) && isValidNumber(values.phoneNumber)
      ? styles.formButtonActive
      : styles.formButton;

  return (
    <Section id="orderForm" style={{ background: "#028FE8" }}>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.subtitle}>{text}</p>
        <form onSubmit={handleSubmit} className={styles.form}>
          <h3 className={styles.formTitle}>ЗАКАЗАТЬ ОБРАТНЫЙ ЗВОНОК</h3>
          <fieldset className={styles.fieldset}>
            <div className={styles.inputWrapper}>
              <ul className={styles.formList}>
                <li className={styles.formItem}>
                  <label className={styles.formLabel} htmlFor="username">
                    ВВЕДИТЕ ВАШЕ ИМЯ
                  </label>
                  <input
                    type="text"
                    placeholder="Валентин"
                    className={styles.formInput}
                    id="username"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isValidName(value) && errors.username) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          username: false,
                        }));
                      }
                      setValues((prevValues) => ({
                        ...prevValues,
                        username: value,
                      }));
                    }}
                  />
                  {errors.username ? (
                    <p className={styles.errorText}>*не указали имя</p>
                  ) : (
                    <p className={styles.hiddenErrorText}></p>
                  )}
                </li>
                <li className={styles.formItem}>
                  <label className={styles.formLabel} htmlFor="phoneNumber">
                    ВВЕДИТЕ КОНТАКТНЫЙ ТЕЛЕФОН
                  </label>
                  <InputMask
                    autoComplete="none"
                    mask="+7 (999) 999-99-99"
                    type="tel"
                    className={styles.formInput}
                    id="phoneNumber"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isValidName(value) && errors.phoneNumber) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phoneNumber: false,
                        }));
                      }
                      setValues((prevValue) => ({
                        ...prevValue,
                        phoneNumber: value,
                      }));
                    }}
                  />
                  {errors.phoneNumber ? (
                    <p className={styles.errorText}>*не указали телефон</p>
                  ) : (
                    <p className={styles.hiddenErrorText}></p>
                  )}
                </li>
              </ul>
              <p className={styles.paragraph}>
                *наш специалист перезвонит вам в течение 5 минут
              </p>
            </div>
            <button className={buttonClassName} type="submit">
              Отправить
            </button>
          </fieldset>
        </form>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobileOrderForm;
