import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { PopupAddNotification, PopupConfirm, Preloader } from "../components";
import useFetch from "../hooks/useFetch";
import { notificationsApi } from "../utils/api";
import Table from "./Table";
import usePopup from "../hooks/usePopup";
import { addNoteIcon, turnOffIcon, trashTableIcon } from "./newIcons/index";

const columns = [
  {
    selector: (row) => row._id,
    id: true,
  },
  {
    name: "Повод",
    selector: (row) => row.title,
    render: (row) => (
      <Link
        to={`/admin-page/notifications/${row._id}`}
        className="admin__data-link"
      >
        {row.title}
      </Link>
    ),
  },
  {
    name: "Текст оповещения",
    selector: (row) => row.text,
  },
  {
    name: "v",
    selecor: (row) => row.active,
    render: (row) => (
      <div className="admin__checkbox">
        <input
          type="radio"
          name="notification"
          onChange={(event) => {
            notificationsApi.editNotification(row._id, {
              active: event.target.checked,
            });
          }}
          defaultChecked={row.active}
          id="checkbox"
          className={["admin__checkbox-image"]}
        />
      </div>
    ),
  },
];

const AdminNotificationList = () => {
  const { isPopupOpen, togglePopup, closePopup } = usePopup();
  const {
    isPopupOpen: isRemovePopupOpen,
    togglePopup: toggleRemovePopup,
    closePopup: closeRemovePopup,
  } = usePopup();

  const tableStateRef = useRef(null);

  const {
    data: notificationsPage,
    loading,
    setLoading,
    setApiFilters: setFilters,
    setData: setNotificationsPage,
  } = useFetch(notificationsApi.getNotifications);

  const handleRemove = async (state) => {
    const { selectedRows } = state;
    const notificationsIds = selectedRows.map((selectedRow) => selectedRow._id);

    await notificationsApi.deleteNotifications(notificationsIds);

    const notifications = await notificationsApi.getNotifications();

    setNotificationsPage(notifications);

    tableStateRef.current = null;
  };

  const handleAddNotification = async (title, text) => {
    setLoading(true);

    await notificationsApi.postNotification(title, text);

    const notifications = await notificationsApi.getNotifications();
    setNotificationsPage(notifications);

    setLoading(false);
  };

  const handlePageChange = (nextPage) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: nextPage }));
  };

  const handleSearchChange = (search) => {
    setFilters((prevFilters) => ({ ...prevFilters, search }));
  };

  const handleHideNotification = async (active) => {
    await notificationsApi.hideNotification({ active: active });

    const notifications = await notificationsApi.getNotifications();
    setNotificationsPage(notifications);
  };

  const actions = [
    { name: "Создать", onClick: togglePopup, icon: addNoteIcon },
    {
      name: "Выключить",
      onClick: handleHideNotification,
      icon: turnOffIcon,
      type: "post",
    },
    {
      name: "Удалить",
      onClick: (state) => {
        tableStateRef.current = state;
        toggleRemovePopup();
      },
      icon: trashTableIcon,
      type: "danger",
      disabled: (state) => !state.isChecked,
    },
  ];

  let errorMessage = "";
  if (
    !loading &&
    notificationsPage.results &&
    notificationsPage.results.length === 0
  ) {
    errorMessage = "Данные по запросу не найдены";
  }

  return (
    <>
      {loading && <Preloader overlay={true} />}
      <section className="info">
        <div className="info__container">
          <Table
            title="Таблица пользователей с доступом в систему"
            columns={columns}
            data={notificationsPage.results}
            totalDataCount={notificationsPage.count}
            actions={actions}
            onPageChange={handlePageChange}
            onSearchChange={handleSearchChange}
            errorMessage={errorMessage}
          />
        </div>
      </section>
      <PopupAddNotification
        isOpenPopup={isPopupOpen}
        onClose={closePopup}
        handleAddNotification={handleAddNotification}
      />
      <PopupConfirm
        isOpenPopup={isRemovePopupOpen}
        onClose={closeRemovePopup}
        handleSubmit={() => handleRemove(tableStateRef.current)}
      />
    </>
  );
};

export default AdminNotificationList;
