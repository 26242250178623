import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairIphoneModelLinksForServiceMobile,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker5s.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const RepairIphoneServiceLinkSpeakerModels5s = () => {
  return (
    <Page title="Замена динамика iPhone 5s в сервисном центре Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-dinamika/5s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Оперативная замена динамика iPhone 5s позволит продолжить эксплуатацию устройства. Мастерская Oh!MyGadget! выполнит работу быстро и качественно."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена динамика iPhone 5s"
          titleImg="Замена динамика iPhone 5s"
          banner={banner}
          title="Замена динамика iPhone 5s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Качество звучания музыки, слышимость абонента при разговоре –
                это важные показатели работоспособности и качества устройства, и
                проблемы в этом отношении создают серьезный дискомфорт. Повинен
                в этом обычно бывает динамик, и о его неисправности можно
                говорить в таких случаях:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Не слышно абонента при разговоре или появляются шумы,
                    дефекты звука,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Не удается прослушать музыку без наушников,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    При низких частотах появляются хрипы.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Эти и другие проблемы наглядно говорят о том, что необходима
                замена динамика iPhone 5s, и такой ремонт действительно помогает
                вернуть звучание в норму, радоваться былой чистоте звука. Однако
                почему же возникают такие поломки, и как их избежать? Они
                довольно распространены, и в мастерские с такими проблемами
                обращаются регулярно.
              </p>
              <p className="repair-iphone-display__paragraph">
                Динамик может выйти из строя из-за того, что попала вода,
                произошло падение или удар, механическое повреждение. Также он
                может сломаться и от простого износа. Чтобы избежать подобных
                проблем, стоит аккуратно обращаться с устройством, а также не
                включать звук на полную мощность, использовать его в комфортном
                режиме. Но что же делать, если поломка все же произошла? В этом
                случае вам стоит обратиться в мастерскую, к грамотным и
                ответственным исполнителям, которые действительно разбираются в
                таких устройствах и смогут оказать вам полноценную
                профессиональную помощь. Не стоит стремиться выполнить ремонт
                своими силами, такой подход часто заканчивается окончательным
                выходом из строя дорогостоящей техники. Ведь для обеспечения
                успеха в ремонте требуются специальные условия и грамотный
                подход, только тогда удается добиться достойного результата.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          title="Быстро поменяем динамик на вашем iPhone 5s"
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1300 P"
          priceDown="1550 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если вы столкнулись с проблемами динамика, и желаете, чтобы ваш
                Айфон был как можно скорее приведен в норму, обращайтесь в нашу
                мастерскую! Мы имеем многолетний опыт работы с такими девайсами,
                а также заслуженную хорошую репутацию и массу положительных
                отзывов. Все услуги у нас оказывают профессиональные
                исполнители, компетентные люди с опытом работы и профильным
                образованием, которые знают о тонкостях современных
                высокотехнологичных устройств буквально все. Мы полностью
                доверяем их работе, и потому даем длительный срок гарантии на
                оказанные услуги.
              </p>
              <p className="repair-iphone-display__paragraph">
                Под рукой у мастера всегда имеются необходимые детали, включая и
                динамики на замену – это позволяет оперативно выполнить ремонт и
                предоставить результат в короткие сроки. Мы используем только
                оригинальные высококачественные запчасти заводского
                производства, и даем на них гарантию. Детали предлагаются по
                доступным ценам, и потому переплачивать не придется. Отдельный
                вклад в качество выполнения работ вкладывает современное
                оснащение каждого рабочего места – у специалистов есть условия и
                все необходимое для выполнения работ оборудование. Все эти
                факторы вместе взятые позволяют нам выполнять работы быстро, но
                без ущерба для качества, а также давать длительные гарантии и
                придерживаться невысоких расценок.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Если ваш Айфон сломался, отнесите его к нам на
                экспресс-диагностику. Ее выполнят бесплатно и немедленно, в
                считанные минуты устанавливая истинную причину неисправности,
                подтверждая или опровергая догадки. После этого можно будет
                узнать стоимость ремонта, и если она вас устроит – немедленно
                приступить к его выполнению, в обычном или в экспресс-режиме. Мы
                работаем официально, через договор и с гарантиями, несем полную
                ответственность за результат труда. Обращаясь к нам, вы
                доверяете ремонт ответственным исполнителям, которые вас не
                подведут и предоставят наилучший результат.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels5s;
