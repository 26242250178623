import React from "react";
import Page from "../../Page";
import styles from "./NoteExpertGeneralPage.module.css";
import CenterMobileGeneralPageBanner from "../../centerMobile/CenterMobileGeneralPageBanner/CenterMobileGeneralPageBanner";
import noteExpertBanner from "../../../images/banners/note-expert-general-banner.png";
import NoteExpertGeneralPageNoteLinks from "../NoteExpertGeneralPageNoteLinks/NoteExpertGeneralPageNoteLinks";
import NoteExpertGeneralPageWork from "../NoteExpertGeneralPageWork/NoteExpertGeneralPageWork";
import NoteExpertGeneralPageReasons from "../NoteExpertGeneralPageReasons/NoteExpertGeneralPageReasons";
import NoteExpertGeneralPageAdvantages from "../NoteExpertGeneralPageAdvantages/NoteExpertGeneralPageAdvantages";
import NoteExpertGeneralPagePrice from "../NoteExpertGeneralPagePrice/NoteExpertGeneralPagePrice";
import NoteExpertGeneralPageDecisions from "../NoteExpertGeneralPageDecisions/NoteExpertGeneralPageDecisions";
import NoteExpertGeneralPageForm from "../NoteExpertGeneralPageForm/NoteExpertGeneralPageForm";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import { Helmet } from "react-helmet";

function NoteExpertGeneralPage() {
  return (
    <Page title="Ремонт ноутбуков в Москве, цены на ремонт в Oh!MyGadget!">
      <main className={styles.content}>
        <Helmet>
          <link rel="canonical" href="https://ohmygadget.ru/note-expert" />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Сервис по ремонту и обслуживанию ноутбуков. Ремонт ноутбуков всех марок любой сложности. Современное оборудование. Квалифицированные мастера. Цены на работы. Гарантии на ремонт. Отзывы. Контакты сервиса."
          />
        </Helmet>
        <CenterMobileGeneralPageBanner
          title="ПРОФЕССИОНАЛЬНЫЙ РЕМОНТ НОУТБУКОВ"
          banner={noteExpertBanner}
        />
        <NoteExpertGeneralPageNoteLinks />
        <NoteExpertGeneralPageReasons />
        <NoteExpertGeneralPageWork />
        <NoteExpertGeneralPagePrice />
        <NoteExpertGeneralPageDecisions />
        <NoteExpertGeneralPageAdvantages />
        <NoteExpertGeneralPageForm />
        <NoteExpertGeneralPageFeedbacks />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertGeneralPage;
