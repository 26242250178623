import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import lenovoBanner from "../../../images/banners/pad-lenovo-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "РЕМОНТ ПЛАНШЕТОВ НА ДОМУ",
    text: "При несложной поломке или неисправности программного характера ремонт планшетов Леново может быть выполнен у клиента на дому. Сделать заказ можно по многоканальному телефону +7 (495) 540-43-19, вызвав специалиста на удобное для вас время.",
  },
  {
    img: icon2,
    title: "ГАРАНТИЙНЫЕ ОБЯЗАТЕЛЬСТВА",
    text: "«Центр Мобайл» предоставляет гарантию на все виды выполненных услуг, в том числе, если была проведена замена стекла на планшете Леново.",
  },
  {
    img: icon3,
    title: "ДОСТАВКА ПЛАНШЕТА КУРЬЕРОМ",
    text: "Если у вас нет времени на поездку в наш сервисный центр, а сложность неисправности не позволяет устранить ее в домашних условиях, можно воспользоваться услугой курьера. Наш сотрудник доставит планшет в офис, оставив владельцу соответствующие документы, а после ремонта привезет его назад.",
  },
  {
    img: icon4,
    title: "БЕСПЛАТНАЯ ДОСТАВКА",
    text: "Воспользоваться услугами курьера бесплатно вы можете в пределах МКАД в рабочие часы. Во сколько обойдется доставка в других районах Москвы и МО, можно узнать, связавшись с нами по телефону или электронной почте info@centre-mobile.ru.",
  },
];

const pads = [
  { name: "Xiaoxin Pad P11 Plus", pricelist: desireHD },
  { name: "Yoga Tab 13", pricelist: desire400 },
  { name: "Tab P11", pricelist: desireHD },
  { name: "Tab M10 Plus", pricelist: desire400 },
  { name: "Ideapad Duet 3 N5030", pricelist: desireHD },
  { name: "Tab M7 TB-7305X", pricelist: desire400 },
  { name: "Tab P10", pricelist: desireHD },
  { name: "Yoga Smart Tab YT-X705X", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadLenovo() {
  return (
    <Page title="Квалифицированный ремонт планшетов Lenovo в Москве, цены Oh!MyGadget!">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-planshetov-lenovo"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт планшетов Lenovo от специалистов сервисного центра Oh!MyGadget! (Москва) – качественно, оперативно, недорого. Комплектующие в наличии, гарантии на работу и запчасти."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={lenovoBanner}
          alt="РЕМОНТ ПЛАНШЕТОВ LENOVO"
          title="РЕМОНТ ПЛАНШЕТОВ LENOVO"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ LENOVO"
          text="Многолетний практический опыт специалистов и современная техническая база компании Oh!MyGadget! позволяют оказывать ремонтно-диагностические услуги по восстановлению работоспособности мобильных устройств на самом высоком уровне. Мы предлагаем не только качественный ремонт планшетов Леново в Москве, но и самую выгодную стоимость во всем регионе."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Lenovo"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Lenovo:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА РЕМОНТА ПЛАНШЕТА LENOVO В «ЦЕНТР МОБАЙЛ»"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ОТРЕМОНТИРОВАТЬ ПЛАНШЕТ ЛЕНОВО ?"
          text="Если не включается планшет Lenovo, разбит экран или устройство «тормозит», позвоните нам по телефону, напишите на e-mail или привезите гаджет в наш офис, удобно расположенный в центре Москвы по адресу Лесная, 1/2, офис 351. Специалисты «Центр Мобайл» справятся с любой неисправностью вашего устройства, восстановив его быстро и недорого."
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadLenovo;
