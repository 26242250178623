import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
} from "../index";
import banner from "../../images/banners/repair-ipad4.jpeg";
import { Helmet } from "react-helmet";

const priceIpad4 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "2600 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "3900 P",
  },
  {
    id: 3,
    option: "Замена корпуса/задней крышки",
    price: "5950 P",
  },
  {
    id: 4,
    option: "Замена аккумулятора",
    price: "2950 P",
  },
  {
    id: 5,
    option: "Замена микрофона",
    price: "2350 P",
  },
  {
    id: 6,
    option: "Замена динамика",
    price: "2300 P",
  },
  {
    id: 7,
    option: "Замена кнопки Home (домой)",
    price: "1900 P",
  },
  {
    id: 8,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1600 P",
  },
  {
    id: 9,
    option: "Замена кнопки включения",
    price: "1600 P",
  },
  {
    id: 10,
    option: "Замена антенны",
    price: "1600 P",
  },
  {
    id: 11,
    option: "Ремонт Wi-Fi модуля",
    price: "4000 P",
  },
  {
    id: 12,
    option: "Замена датчика приближения",
    price: "1600 P",
  },
  {
    id: 13,
    option: "Замена сим-держателя",
    price: "950 P",
  },
  {
    id: 14,
    option: "Замена основной камеры",
    price: "1900 P",
  },
  {
    id: 15,
    option: "Замена передней камеры",
    price: "1900 P",
  },
  {
    id: 16,
    option: "Замена разъема зарядки",
    price: "2435 P",
  },
  {
    id: 17,
    option: "Замена разъема наушников",
    price: "2350 P",
  },
];

const textOfIpad4 = [
  {
    id: 1,
    title: "Наиболее распространённые типы поломок и ремонта iPad 4.",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Как бы не стремились купертиновцы защитить свои планшеты применяя все
          более надёжные сплавы и виды стекла, чаще всего iPad 4 страдает в
          следствии механического повреждений экрана или геометрии корпуса.
          Более половина обращений в наши центры приходится на клиентов, которым
          нужна замена стекла или корпуса Айпэд 4.
        </p>
        <p className="repair-iphone-display__paragraph">
          Вторая по популярности неприятность приключающаяся с iPad 4 - это
          попадание внутрь корпуса влаги. В таком случае может понадобится более
          серьезный ремонт, так как высока возможность замыкания. В первую
          очередь потребуется всесторонняя диагностика, по итогам котором может
          потребоваться замена материнской платы или её элементов.
        </p>
        <p className="repair-iphone-display__paragraph">
          Наконец, еще одна распространённая поломка - это неисправность
          батареи. Особенно часто подобная проблема возникает у пользователей,
          которые не заряжают свои планшеты до конца и часто полностью его
          разряжают. Беда, впрочем, легко лечится - заменой аккумулятора. Быстро
          и безболезненно последняя производится в наших центрах.
        </p>
        <p className="repair-iphone-display__paragraph">
          iPad 4 - удобное, совершенное с точки зрения дизайна, и практичное
          устройство. Он является прекрасным помощником для современного
          мобильного человека. Сёрфинг по безбрежным просторам Интернета,
          просмотр фотографий и фильмов, чтение электронных книг - во всем этом
          нет более надежного помощника, чем Айпэд. Расставаться с ним надолго,
          конечно же, не захочется. Впрочем, если вы обратитесь за помощью к
          нам, вам этого и не придётся делать. Большинство поломок мы устраним
          за 20 минут, и ваш Айпэд ещё долго и надёжно будет радовать вас всем
          богатым спектром своих возможностей и функционала.
        </p>
      </>
    ),
  },
];

const RepairIpadModelPagesIpad4 = () => {
  return (
    <Page title={"Срочный ремонт iPad 4, цены на ремонт Айпад 4!"}>
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Делаем ремонт айпадов 4 по привлекательным ценам. Опытные специалисты, удобное расположение, любые виды неисправностей!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          alt="ремонт ipad 4"
          titleImg="ремонт ipad 4"
          title="Ремонт iPad 4"
          text="Ремонт iPad 4 и других гаджетов от Apple - это наша специализация и работа, приносящая не только дивиденды, но и подлинное удовольствие. Наши опытные сотрудники, вооруженные не только большим опытом, но и всем необходимым современным оборудованием и инструментом, способны вернуть работоспособность вашему Айпэд 4 в кратчайшие сроки, после практически любых поломок.</>"
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpad4}
            title="Цены на ремонт Айпад 4"
            text="* Все цены указаны в рублях. Стоимость включает работу и комплектующее."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpad4.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpad4;
