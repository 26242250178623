import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/batteryse.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfBatterySE = [
  {
    id: 1,
    title: "Когда нужна замена батареи iPhone SE: основные признаки",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Можно выделить несколько основных признаков, сигнализирующих о том,
          что необходима срочная замена аккумулятора iPhone SE. А именно:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Батарея не заряжается или же заряжается слишком медленно
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Смартфон неожиданно выключается, когда индикатор заряда заполнен
              меньше, чем наполовину
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Устройство не включается даже после длительного подключения к
              источнику питания
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Айфон разряжается слишком быстро.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Существует довольно много причин возникновения подобной проблемы – это
          и нарушение правил эксплуатации, и падение, и проникновение влаги.
          Нельзя исключать и завершение периода службы аккумулятора – в среднем
          подобная деталь рассчитана на 1,5-2 года использования или же 400
          перезарядок. Вне зависимости от того, что привело к нарушению работы,
          мы устраним этот дефект.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Можно ли заменить батарею iPhone SE самостоятельно",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Сервисный центр Мак Профи – это ответственный подход и индивидуальная
          работа с каждым клиентом. Мы ответим на все вопросы, подберем
          качественные оригинальные запчасти, устраним повреждения. Диагностика
          абсолютно бесплатна, а потому вы сможете узнать и о сбоях в других
          элементах системы, если таковые имеются.
        </p>
        <p className="repair-iphone-display__paragraph">
          Ремонт своими руками нецелесообразен – любая ошибка может привести к
          поломке смартфона. Не стоит пытаться подобрать аккумулятор увеличенной
          мощности, ведь у любого устройства есть определенные параметры –
          попытка самостоятельно внести какие-либо изменения лишь приведет к
          нарушению работы.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModelsSE = () => {
  return (
    <Page title="Профессиональная замена аккумулятора (батареи) iPhone SE за 15 минут">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora/se"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена аккумулятора iPhone SE будет выполнена быстро и без переплат – сервисный центр Oh!MyGadget! ждет новых клиентов. Профессиональный подход гарантируется."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPhone SE"
          titleImg="Замена аккумулятора iPhone SE"
          banner={banner}
          title="Замена аккумулятора iPhone SE"
          text="Сбои в работе iPhone доставят владельцу массу неудобств, ведь подобная техника заменяет одновременно и телефон, и фотоаппарат, и органайзер. И одной из ключевых проблем становится выход из строя аккумулятора, провоцирующий периодические выключения устройства, быструю разрядку или иные проблемы. Именно поэтому мы рекомендуем вам обратиться в сервисный центр Мак Профи – доверьте ваш смартфон квалифицированным мастерам, которые быстро и без дополнительных затрат восстановят устройство."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBatterySE.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены аккумулятора iPhone SE в сервисе Мак Профи"
          text="Замена аккумулятора"
          price="1550 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Ознакомьтесь с нашими расценками, чтобы в полной мере оценить
                выгоды и преимущества текущего предложения. Окончательная
                стоимость будет озвучена специалистом только после проведения
                диагностики – это дает возможность оценить серьезность поломки,
                а соответственно и установить точную цену работы.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Мы работаем с понедельника по пятницу, неизменно предоставляя
                клиентам отменный сервис и гарантии качества на все виды работ.
                Приезжайте в Мак Профи, чтобы получить необходимые услуги по
                честным ценам.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModelsSE;
