import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  bellIcon,
  commentIcon,
  homeIcon,
  userMultipleIcon,
} from "../../admin/newIcons/index";

const Aside = ({ user, burgerClick }) => {
  return (
    <nav className={!burgerClick ? "aside" : "aside aside_type_hidden"}>
      <ul className="aside__list">
        <li className="aside__item">
          <Link className="aside__link" to="/admin-page">
            <img
              className="aside__icon"
              alt="иконка раздела домой"
              src={homeIcon}
            />
            <p className="aside__subtitle">В начало</p>
          </Link>
        </li>
        {user.role === "admin" && (
          <li className="aside__item">
            <NavLink className="aside__link" to="/admin-page/users">
              <img
                className="aside__icon"
                alt="иконка раздела пользователи"
                src={userMultipleIcon}
              />
              <p className="aside__subtitle">Пользователи</p>
            </NavLink>
          </li>
        )}
        <li className="aside__item">
          <NavLink className="aside__link" to="/admin-page/notifications">
            <img
              className="aside__icon"
              alt="иконка раздела оповещения"
              src={bellIcon}
            />
            <p className="aside__subtitle">Оповещения</p>
          </NavLink>
        </li>
        <li className="aside__item">
          <NavLink className="aside__link" to="/admin-page/otzivy">
            <img
              className="aside__icon"
              alt="иконка раздела отзывы"
              src={commentIcon}
            />
            <p className="aside__subtitle">Отзывы</p>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Aside;
