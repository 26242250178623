import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera5s.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCamera5s = [
  {
    id: 1,
    title: "Замена камеры iPhone 5s: основные причины поломки",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Существует множество причин, по которым камера может перестать
          функционировать в нормальном режиме. Так как техника Apple
          представляет собой отличное сочетание качества и надежности,
          вероятность производственного брака минимальна. Как правило, проблема
          заключается в следующем:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Влага под корпусом. Даже если вы не берете телефон влажными руками
              и не проливали на него жидкость, подобная проблема все равно может
              возникнуть. Если занести смартфон с холодной улицы в тепло, да еще
              и сразу поставить на зарядку – внутри скопиться конденсат, из-за
              которого могут замкнуть внутренние элементы.Влага под корпусом.
              Даже если вы не берете телефон влажными руками и не проливали на
              него жидкость, подобная проблема все равно может возникнуть. Если
              занести смартфон с холодной улицы в тепло, да еще и сразу
              поставить на зарядку – внутри скопиться конденсат, из-за которого
              могут замкнуть внутренние элементы.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Падение. Отсутствие повреждений на корпусе не гарантирует, что
              удар произошел без последствий – подобный «встряска» негативно
              отразится на работе устройства.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Системный сбой. Не стоит использовать незнакомое программное
              обеспечение от неизвестных производителей – программы-вредители
              могут нарушить работу смартфона.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Для того чтобы предоставить клиенту качественный, профессиональный
          ремонт, нам нужно понять причины поломки. Сделать это поможет только
          диагностика, на основании которой мастер определит: смартфон вышел из
          строя, потому что владелец регулярно берет его мокрыми руками, либо же
          повреждениям предшествовало падение.
        </p>
        <p className="repair-iphone-display__paragraph">
          На основании полученной информации рассчитывается смета затрат на
          замену камеры iPhone 5s – сумму и срок мы называем до начала ремонта,
          в обязательном порядке получая подтверждение клиента на проведение
          работ.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Профессиональная замена камеры iPhone 5s с гарантией качества",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мы используем качественные расходные материалы, фирменные инструменты
          и работаем только с оригинальными комплектующими. После замены камеры
          iPhone 5s клиенту будет выдан чек и гарантийный талон.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы уверенны в надежности используемых комплектующих, а потому можем
          предоставить соответствующие гарантии посетителям. У вас не возникнет
          проблем из-за некачественного проведения ремонта, ведь мы дорожим
          репутацией сервиса.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если вам необходима замена камеры iPhone 5s, приезжайте в сервис Мак
          Профи, расположенный возле станции метро Белорусская – предоставляем
          качественное обслуживание по приемлемой цене.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels5s = () => {
  return (
    <Page title="Замена камеры iPhone 5s в Москве по низким ценам">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/5s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена камеры iPhone 5s: низкие цены, качественное обслуживание, быстрый ремонт"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone 5s"
          titleImg="Замена камеры iPhone 5s"
          banner={banner}
          title="Замена камеры iPhone 5s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 5s в Москве. Курьерская доставка по Москве
                и диагностика – бесплатно. Лучший сервис и гарантии качества на
                работу и комплектующие.
              </p>
              <p className="repair-iphone-display__paragraph">
                Значительный процент покупателей iPhone, выбирает этот смартфон
                за его быстродействие и отменную камеру. Именно поэтому, когда с
                последней возникают проблемы, это доставляет серьезные
                неудобства. Чтобы этого не произошло, а устройство по-прежнему
                выполняло необходимые владельцу функции, стоит обратиться в Мак
                Профи – и мы поможем в решении этого вопроса.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="1600 P"
          priceDown="1450 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera5s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels5s;
