import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpad4.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Падение – даже если устройство упало с небольшой высоты, его экран покроет сетка трещин",
    number: "01",
  },
  {
    id: 2,
    text: "Сдавливание – забыв планшет в кресле или на диване, можно легко сесть на хрупкое устройство, после чего не удастся обойтись без посещения сервисного центра",
    number: "02",
  },
  {
    id: 3,
    text: "Удар – механическое воздействие может стать причиной повреждения не только стекла, но и тачскрина.",
    number: "03",
  },
];

const listForText = [
  {
    id: 1,
    text: "Чтобы снять стекло нужно растопить клей – для этого потребуется воздействие высоких температур. Но в домашних условиях отсутствует соответствующее оборудование.",
    number: "01",
  },
  {
    id: 2,
    text: "Меняя экран, легко повредить тачскрин – достаточно всего одного неаккуратного движения или же случайно дрогнувшей руки, чтобы стоимость ремонта возросла в несколько раз.",
    number: "02",
  },
  {
    id: 3,
    text: "Проведя замену стекла, нужно собрать устройство – важно аккуратно удалить остатки клея, битое стекло и различный мусор, после чего проклеить поверхность.",
    number: "03",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title: <>В каких случаях нужна замена стекла iPad&nbsp;4</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Несмотря на то, что техника Apple отличается отменным качеством и рассчитана на долгосрочную работу, экран по-прежнему остается её «больным» местом. Чаще всего к нам обращаются заказчики по следующим причинам:"
          array={listOfTrouble}
          paragraphDown="Чтобы оценить серьезность нанесенного ущерба, прежде чем приступать к ремонту, мы обязательно проводим комплексную диагностику. Если необходима только замена стекла iPad 4, то эта работа будет выполнена в течение суток. При наличии дополнительных дефектов, сроки восстановления согласовываются."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Почему не стоит менять стекло iPad 4 самостоятельно",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Мы настоятельно не рекомендуем самостоятельно пытаться отремонтировать испорченный планшет. Практически в 90% случаев ремонта своими силами, владельцам устройства все-таки приходится обращаться в сервис, вот только заплатить предстоит в несколько раз больше, чем изначально за простую замену экрана. И вот почему:"
          array={listForText}
          paragraphDown="Экономия в этом вопросе нецелесообразна, тем более что речь идет об iPad 4 – дорогостоящем приобретении. Лучше не рисковать, а сразу обратиться к мастерам, которые способны предоставить гарантии качества на свои услуги."
          paragraphDown2="В сервисном центре Oh!MyGadget! работают профессионалы, которые быстро и качественно выполнят поставленную задачу. Приезжайте к нам, и уже спустя сутки вы сможете забрать обновленный iPad 4, наслаждаясь его стабильной работой."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModel4 = () => {
  return (
    <Page title="Качественная замена стекла iPad 4, стоимость, сроки замены таскрина">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Когда требуется замена стекла iPad 4, сервисный центр Oh!MyGadget! готов предоставить вам полноценный результат по выгодной цене и в короткие сроки. Качество гарантируется!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена стекла на iPad 4"
          alt="Замена стекла на iPad 4"
          titleImg="Замена стекла на iPad 4"
          text="Вам необходима замена стекла iPad 4? Опытные мастера сервисного центра Oh!MyGadget! помогут в решении этой проблемы. Вне зависимости от того, что привело к возникновению трещин и других дефектов, мы оперативно устраним любые поломки и установим новый экран. При этом диагностика – абсолютно бесплатна."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="2600 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModel4;
