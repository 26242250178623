export const pricelists5mini = [
  {
    text: "Замена дисплея",
    price: "3 500 P",
  },
  {
    text: "Замена аккумулятора",
    price: "1 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена микрофона",
    price: "1 950 P",
  },
  {
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2 000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена кнопки включения",
    price: "1 950 P",
  },
  {
    text: "Замена антенны",
    price: "2 700 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Ремонт Wi-Fi модуля",
    price: "4 000 P",
  },
  {
    text: "Замена датчика приближения",
    price: "2 000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена сим-держателя",
    price: "490 P",
  },
  {
    text: "Замена основной камеры",
    price: "2 500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена передней камеры",
    price: "2 000 P",
  },
  {
    text: "Замена разъема зарядки",
    price: "1 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена разъема наушников",
    price: "1 950 P",
  },
  {
    text: "Замена кнопки Home (домой)",
    price: "1 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена задней крышки",
    price: "3 100 P",
  },
  {
    text: "Замена корпуса",
    price: "3 100 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена вибромотора",
    price: "1 500 P",
  },
  {
    text: "Замена слухового динамика",
    price: "1 800 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена полифонического динамика",
    price: "1 800 P",
  },
];

export const pricelist2Grand = [
  {
    text: "Замена дисплея",
    price: "1 000 P",
  },
  {
    text: "Замена аккумулятора",
    price: "1 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена микрофона",
    price: "1 000 P",
  },
  {
    text: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1 000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена кнопки включения",
    price: "1 950 P",
  },
  {
    text: "Замена антенны",
    price: "2 700 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Ремонт Wi-Fi модуля",
    price: "4 000 P",
  },
  {
    text: "Замена датчика приближения",
    price: "2 000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена сим-держателя",
    price: "490 P",
  },
  {
    text: "Замена основной камеры",
    price: "2 500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена передней камеры",
    price: "2 000 P",
  },
  {
    text: "Замена разъема зарядки",
    price: "1 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена разъема наушников",
    price: "1 950 P",
  },
  {
    text: "Замена кнопки Home (домой)",
    price: "1 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена задней крышки",
    price: "3 100 P",
  },
  {
    text: "Замена корпуса",
    price: "3 100 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена вибромотора",
    price: "1 500 P",
  },
  {
    text: "Замена слухового динамика",
    price: "1 800 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена полифонического динамика",
    price: "1 800 P",
  },
];
