import React from "react";
import Section from "../Section/Section";
import iWatchBanner from "../../images/banners/repaip-iphone-banner.png";

const RepairIwatchBanner = () => {
  return (
    <Section>
      <img
        className="repair-iwatch__banner"
        src={iWatchBanner}
        alt="ремонт apple watch"
        title="ремонт apple watch"
      />
    </Section>
  );
};

export default RepairIwatchBanner;
