import React from "react";
import { RepairIphoneModelLink } from "../index";
import { iPodListOfModels } from "../../utils/ipodModels";
import RepairIpodModelLinksMobile from "./RepairIpodModelLinksMobile";
import { useMobileScreen } from "../../utils/useMedia";

function PageLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairIpodModelLinksMobile />;
  }
  return (
    <RepairIphoneModelLink
      heading="Больше детальной информации по каждой модели:"
      links={iPodListOfModels}
    />
  );
}

const RepairIpodModelLinks = () => {
  return PageLinks();
};

export default RepairIpodModelLinks;
