import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery8plus.png";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfBattery8Plus = [
  {
    id: 1,
    title: "Особенности работы при замене аккумулятора iPhone 8 Plus",
    text: "Замена аккумулятора может быть выполнена за считанные минуты, данная манипуляция не отнимает много времени и может быть проведена прямо перед глазами заказчика. Аккумулятор заменяется на оригинальную заводскую деталь того же типа, техника тестируется и проверяется, далее передается заказчику под длительную гарантию, которая будет распространяться не только на работы, но и на сам новый аккумулятор. Помимо этого мастер подскажет вам, как добиться максимально длительной работы нового элемента, и эти рекомендации будет разумно соблюсти, поскольку от того, как осуществляется процесс зарядки-разрядки в первые разы, будет зависеть многое в дальнейшей эксплуатации устройства. Об этом стоит помнить.",
  },
  {
    id: 2,
    title: "Замена аккумулятора iPhone 8 Plus в сервисе Мак Профи в Москве",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если аккумулятор Айфона стал работать некорректно, переживать по этому
          поводу не стоит, равно как и терпеть неудобства. На практике проблема
          устранима с очень высокой долей вероятности, и сервис Мак профи
          приглашает всех желающих для ремонта путем замены. Этот подход
          позволит решить основную часть проблем, и в большинстве случаев
          сложности не возобновляются, а техника становится работоспособной еще
          на долгий срок, радуя длительным периодом сохранения заряда.{" "}
        </p>
        <p className="repair-iphone-display__paragraph">
          Если у вас возникли проблемы с аккумулятором, наши мастера выполнят
          предварительную диагностику, которая позволит уточнить причину
          проблем, и далее будет осуществлена замена – это действительно очень
          быстрое мероприятие. Вы сможете получить гарантию на батарею и
          оказанные услуги, и продолжите беспроблемное использование устройства.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас вы сможете порадоваться разумной ценовой политике, качественному
          оказанию услуг и длительному сроку гарантии. Мы создаем для клиентов
          оптимальные условия сотрудничества, обеспечиваем разумные расценки и
          прочие преимущества. Если в эксплуатации устройства возникли проблемы
          – обращайтесь к нам, и мастера устранят их!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels8Plus = () => {
  return (
    <Page title="Замена батареи (аккумулятора) на iPhone 8 Plus за 25 минут в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora/8-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если потребовалась замена аккумулятора iPhone 8 Plus, обращайтесь в сервисный центр Oh!MyGadget! Здесь вас порадуют разумными расценками и качеством выполнения работ!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPhone 8 Plus"
          titleImg="Замена аккумулятора iPhone 8 Plus"
          banner={banner}
          title="Замена аккумулятора iPhone 8 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Несмотря на всю надежность Айфонов, с ними все равно происходят
                время от времени неприятности. От поломки не застрахован никто,
                а что касается проблем с аккумулятором, то здесь и вовсе может
                наблюдаться немалое число причин, приводящих к проблеме. Среди
                основных из них стоит отметить такие:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Длительный период эксплуатации и выработка ресурса. В этом
                    случае пользователь замечает, что заряда хватает все на
                    меньший срок, и в итоге приходит к выводу, что замена станет
                    наиболее целесообразным решением проблемы.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    {" "}
                    Попадание воды. Утопление – это одна из наиболее критичных
                    для Айфонов ситуаций, и в этом случае вполне может отказать
                    аккумулятор. Технику после такого случая необходимо
                    проверить детально, провести комплексную диагностику в
                    профессиональных условиях, так как вода или влага могут
                    негативно отразиться на любой составляющей оборудования.
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Следствие механических повреждений, заводские браки и другие
                    случаи. Иногда пользователю и самому трудно понять, из-за
                    чего произошла поломка, однако диагностика позволит
                    предположить ее причины и найти наиболее рациональный способ
                    ремонта.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Практически в любом случае, если с этим элементом возникают
                проблемы, оптимальным выходом становится замена аккумулятора
                iPhone 8 Plus. Это не слишком сложная процедура, при которой
                корпус вскрывается, осуществляется замена, закрытие и проверка,
                однако даже ее необходимо проводить в профессиональных условиях.
                Именно в сервисном центре профессионального типа все работы
                смогут выполнить так, чтобы риски для оборудования были
                минимальными и фактически отсутствовали, а результат радовал в
                полной мере.
              </p>
            </>
          }
        />
        <RepairApplePrice
          subtitle="Стоимость замены аккумулятора iPhone 8 Plus"
          text="Замена аккумулятора"
          price="3000 P"
          paragraph="Цены указаны в рублях РФ."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBattery8Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels8Plus;
