import styles from "./contactsMobile.module.css";

export default function ContactsMobile() {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.title}>ЖДЕМ ВАС В НАШИХ ОФИСАХ</h2>
        <ul className={styles.contacts}>
          <li className={styles.item}>
            <p className={styles.subtitle_bold}>Москва</p>
            <div className={styles.wrapper}>
              <div>
                <a className={styles.phone_link} href="tel:+74953745481">
                  +7(495)374-54-81
                </a>
              </div>
              <div>
                <a className={styles.phone_link} href="tel:+74955405067">
                  +7(495)540-50-67
                </a>
              </div>
            </div>
          </li>
          <li className={styles.item}>
            <p className={styles.subtitle}>м. Сходненская</p>
            <p className={styles.text}>
              125363, Москва, Нелидовская 20к1, помещение 5П
            </p>
          </li>
          <li className={styles.item}>
            <p className={styles.subtitle}>E-mail:</p>
            <a
              className={styles.mail_link}
              href="mailto:client-service@ohmygadget.su"
            >
              client-service@ohmygadget.su
            </a>
          </li>
          <li className={styles.item}>
            <p className={styles.subtitle}>График работы сервесного центра:</p>
            <p className={styles.text}>Пн - Пт: с 10 до 20 часов</p>
            <p className={styles.text}>Сб - Вс: Выходной</p>
          </li>
        </ul>
      </div>
    </section>
  );
}
