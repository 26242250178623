import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadPro12.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTextPro12 = [
  {
    id: 1,
    text: "Гарантия качества",
    number: "01",
  },
  {
    id: 2,
    text: "Запчасти в наличии",
    number: "02",
  },
  {
    id: 3,
    text: "Высокая скорость ремонта",
    number: "03",
  },
  {
    id: 4,
    text: "Специальные предложения для постоянных клиентов",
    number: "04",
  },
  {
    id: 5,
    text: "Услуга бесплатной курьерской доставки по Москве.",
    number: "05",
  },
];

const textOfCasePro12 = [
  {
    id: 1,
    title: "Особенности замены корпуса iPad Pro 12.9",
    text: "Несмотря на то, что техника Apple не отличается стойкостью к механическим повреждениям, ремонт представляет собой тонкую, поистине ювелирную работу. Аккуратно разобрать устройство, используя для этой цели подручные инструменты и не имея специального оборудования, практически невозможно. Более того, можно легко повредить конструкцию, что в результате приведет к повреждению стекла, тачскрина или же внутренних частей. Поэтому, если вы не хотите переплачивать, самостоятельно увеличив стоимость ремонта – лучше сразу обратиться к специалистам. В Мак Профи действуют честные расценки, а срок ремонта составит меньше часа.",
  },
];

const RepairIpadServiceLinkCaseModelPro12 = () => {
  return (
    <Page title="Доступная замена корпуса iPad Pro 12.9 в Москве за 2 часа, скидки, акции!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/pro-12"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена корпуса iPad Pro 12.9 в сервисном центре Oh!MyGadget!: выгодные цены, курьерские услуги в Москве, оригинальные запчасти, быстрая установка и настройка устройства."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad pro 12.9"
          titleImg="замена корпуса ipad pro 12.9"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Pro&nbsp;12.9</>}
          text="Разбить корпус iPad Pro 12.9 очень легко – иногда трещины появляются даже после слабого удара. При дальнейшем активном использовании устройства они будут лишь увеличиваться, что приведет к появлению зазоров между корпусом и стеклом, нарушению работы кнопок и другим проблемам. Чтобы этого не допустить, вам нужно выделить лишь час времени на посещение сервиса – и мы устраним повреждения."
        />
        <Section className="section_group">
          {textOfCasePro12.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены корпуса iPad Pro 12.9"
          text="	Замена корпуса/задней крышки"
          price="16500 P"
          paragraph={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp="Подробная информация о расценках размещена на сайте – наши клиенты застрахованы от неприятных «сюрпризов». Вы можете получить необходимую информацию, как и ответы на все интересующие вопросы, в телефонном режиме. Контактные данные представлены в верхней части страницы – позвоните нам в любое удобное время, чтобы уточнить детали."
                paragraphUp2="До начала ремонта проводится диагностика, которая позволит обнаружить скрытые поломки. Вместе с предварительным тестированием планшета, вся процедура занимает не больше часа – стандартная замена корпуса iPad Pro 12.9 и вовсе занимает около 30 минут."
                paragraphUp3="Что вы получаете, доверяя замену корпуса iPad Pro 12.9 нашим мастерам"
                paragraphUp4="Для нас важно обеспечить клиентам комфортный сервис, а также предложить лояльные расценки на все виды работ. Наша репутация заработана длительным трудом, ведь именно у нас:"
                array={listOfTextPro12}
                paragraphDown="Не стоит экономить в мелочах, чтобы в результате не потерять гораздо больше. Лучше своевременно обратиться в сервисный центр, пока проблема не усугубилась и не привела к появлению новых повреждений. В Мак Профи вам будут предоставлены требующиеся услуги, а наша ценовая политика более чем лояльна – обратитесь к нам, чтобы убедиться в этом и оценить многочисленные преимущества сервиса."
              />
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelPro12;
