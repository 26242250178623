import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadMini.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Повреждения отражаются на функциональности iPad Mini",
    number: "01",
  },
  {
    id: 2,
    text: "Между экраном и корпусом появился зазор",
    number: "02",
  },
  {
    id: 3,
    text: "Нарушена работа основных кнопок.",
    number: "03",
  },
];

const textOfCaseMini = [
  {
    id: 1,
    title: "Рихтовка или замена корпуса iPad Mini?",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Выход устройства из строя, как и нарушение его нормальной работы – повод обратиться к специалистам. Вот только стоит ли менять корпус планшета, если испорчен его внешний вид? Можно выделить ряд причин, по которым подобная процедура будет необходима:"
          array={listOfTrouble}
          paragraphDown="Если же дефекты не влияют на работу устройства, наши мастера проведут рихтовку крышки и восстановят её нормальную геометрию. При отсутствии прямой необходимости, корпус также может быть заменен, что актуально перед продажей планшета или же при желании владельца вернуть ему красивый первоначальный вид."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModelMini = () => {
  return (
    <Page title="Стоимость замены корпуса на iPad Mini - минимальные сроки замены">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/mini"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена корпуса iPad Mini в срочном порядке и ремонт техники Apple в Москве. Современное оборудование, лучшие специалисты и гарантия на работу и запчасти."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad mini"
          titleImg="замена корпуса ipad mini"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Mini</>}
          text="Царапины, дефекты, сколы – все эти повреждения неизбежны при активном использовании планшета. Но устройству можно с легкостью вернуть безупречный вид – достаточно обратиться в сервисный центр, чтобы квалифицированные мастера могли выполнить рихтовку или полную замену корпуса iPad Mini."
        />
        <Section className="section_group">
          {textOfCaseMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Цена замены корпуса iPad Mini"
          text="Замена сенсорного стекла (тачскрина)"
          price="6950 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Мы не предлагаем дешевый ремонт, который обычно является
                синонимом низкого качества – хорошая работа требует достойной
                оплаты. Но наши расценки не завышены и они в полной мере
                соответствуют квалификации сотрудников – при посещении сервиса
                Мак Профи вы сможете убедиться в этом самостоятельно.
              </p>
              <p className="repair-iphone-display__paragraph">
                Подробная информация о ценах представлена на сайте, и вы можете
                ознакомиться со стоимостью услуг перед обращением в сервисный
                центр. Назвать точную сумму мастер сможет только после
                тестирования устройства. Диагностика выявляет незаметные
                внутренние повреждения, что дает возможность оценить серьезность
                последствий ударов и падений.
              </p>
              <p className="repair-iphone-display__paragraph">
                Замена корпуса iPad Mini в Мак Профи
              </p>
              <p className="repair-iphone-display__paragraph">
                У нас вы сможете получить качественный ремонт, который будет
                выполнен непосредственно в день обращения. Используя фирменный
                комплект инструментов и необходимое оборудование, наши мастера
                аккуратно разберут устройство, не повредив при этом внутренние
                части.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если проблема заключается исключительно в замене корпуса iPad
                Mini, то работа будет произведена непосредственно при клиенте.
                Нам потребуется меньше часа, чтобы обновить планшет и
                восстановить его работу – оплата производится только после
                проведения ремонта.
              </p>
              <p className="repair-iphone-display__paragraph">
                Приезжайте к нам с понедельника по воскресенье, чтобы получить
                необходимые услуги. Работаем ежедневно и без перерывов,
                благодаря чему вы в любой удобный день получите
                квалифицированную помощь, а ремонт будет проведен в сжатые
                сроки.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelMini;
