import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-camera.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import { Helmet } from "react-helmet";

const listOfCamera = [
  {
    id: 1,
    number: "01",
    subtitle: "Фиолетовые полосы при съемке камерой iPhone.",
    textLi:
      "В большинстве случаев это вызвано заводским браком самой камеры iPhone, либо ее механическим повреждением. После замены камеры на исправную – при съемке не появляются фиолетовые полосы.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "При открытии приложения “Камера” черный экран.",
    textLi:
      "Данная проблема может возникать как с передней, так и с задней камерой iPhone. Скорее всего в данном случае проблема вызвана выходом из строя камеры iPhone.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Не фокусируется камера iPhone.",
    textLi:
      "Причина – механическое повреждение линзы камеры. После замены камеры проблема будет устранена. Фокус будет работать корректно.",
  },
  {
    id: 4,
    number: "04",
    subtitle: "Не работает камера iPhone – другие причины неисправности.",
    textLi:
      "Камера может не работать и по причине проблемы в материнской плате, шлейфах. В данном случае производится аппаратная диагностика iPhone, после чего мы выявляем неисправность и точно говорим, в чем причина неисправности, точную стоимость и сроки ремонта.",
  },
];

const textOfCamera = [
  {
    id: 1,
    title:
      "Как мы производим замену камеры на iPhone 7, 7 Plus, 6, 6 Plus, 5S, 5, 4S, 4",
    text: (
      <>
        Специалист в ходе бесплатной диагностики выявляет причину неисправности
        камеры. Клиент получает полную информацию по неисправности, точную
        стоимость замены камеры на iPhone. После одобрения ремонта клиентом,
        технический специалист устраняет причину неисправности – производит
        замену камеры, либо восстанавливает причину по которой камера не
        работает. Срок замены камеры iPhone 20 - 40 минут в зависимости от
        модели iPhone. Клиент проверяет работоспособность камеры iPhone и
        оплачивает стоимость ремонта любым удобным способом: наличный,
        безналичный расчет. Точную стоимость замены камеры на вашу модель iPhone
        вы можете посмотреть вот{" "}
        <Link
          to="/remont-apple/remont-iphone"
          className="repair-mb__group-links-array"
        >
          тут
        </Link>
        .
      </>
    ),
  },
  {
    id: 2,
    title:
      "Гарантия на замену камеры iPhone 7, 7 Plus, 6, 6 Plus, 5S, 5, 4S, 4",
    text: (
      <>
        Сервисный центр предоставляем гарантию от 1-го до 3-х месяцев на замену
        камеры iPhone. В течении гарантийного срока, при повторной неисправности
        камеры, мы полностью меняем камеру на новую.
      </>
    ),
  },
];

const RepairIphoneServiceLinkCamera = () => {
  return (
    <Page title="Не работает камера iPhone? Замена камеры на iPhone в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Производим замену камеры iPhone 7, 7+, 6, 6+, 5, 5S. Все камеры в наличии, предоставляем гарантию. Срок замены камеры 20-40 минут в зависимости от модели iPhone."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена камеры iphone"
          titleImg="замена камеры iphone"
          banner={banner}
          title="Замена камеры на iPhone 5, 5S, 6, 6 Plus, 7, 7 Plus"
          text="Производим ремонт и замену камеры на iPhone 4S, 5, 5S, 6, 6+, 7, 7+. Основные неисправности камеры на iPhone:"
        />
        <Section className="section_group" style={{ padding: "0 0 34px" }}>
          <div className="repair-mb__group-list-container">
            {listOfCamera.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          <div className="repair-mb__group-list-container">
            {textOfCamera.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCamera;
