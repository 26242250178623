import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairIpadModelLinksForServiceMobile,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadMini4.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTextMini4 = [
  {
    id: 1,
    text: "Набор профессиональных инструментов",
    number: "01",
  },
  {
    id: 2,
    text: "Термофен",
    number: "02",
  },
  {
    id: 3,
    text: "Расходные материалы, которые будут использованы для удаления излишков клея и повторного скрепления элементов конструкции.",
    number: "03",
  },
];

const RepairIpadServiceLinkCaseModelMini4 = () => {
  return (
    <Page title="Рихтовка и замена корпуса iPad Mini 4 (Айпад Мини 4) - качество гарантировано">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/mini-4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена корпуса iPad Mini 4 в Москве: сервисный центр Oh!MyGadget! предлагает доступный ремонт на выгодных условиях. Диагностика проводится бесплатно."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad mini 4"
          titleImg="замена корпуса ipad mini 4"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Mini&nbsp;4</>}
          text="Корпус вашего планшета покрыт царапинами? Между ними и стеклом появился зазор? Кнопки некорректно работают или же не нажимаются вовсе? Не стоит приниматься за исправление этой проблемы самостоятельно – обратившись в сервисный центр, вы получите необходимые услуги по приемлемой цене. В Мак Профи вас ожидают опытные мастера, которые устранят поломку – мы позаботимся о том, чтобы ремонт был завершен в день обращения."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="4500 P"
          paragraph={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp="В первую очередь необходимо выяснить, какая именно помощь необходима устройству. Для этого мы проведем диагностику, которая поможет определить истинное состояние планшета, а также убедиться в том, что внешние повреждения не привели к повреждению шлейфов и плат, расположенных внутри."
                paragraphUp2="Чтобы выполнить замену корпуса iPad Mini 4, потребуются:"
                array={listOfTextMini4}
                paragraphDown="Если в процессе тестирования планшета не выявлены серьезные повреждения, то ремонт будет выполнен за 20-30 минут. В противном случае может потребоваться оставить устройство в сервисном центре на сутки. О сроках и стоимости мы уведомим владельца перед началом работ – вы получаете уверенность в том, что первоначальные цифры останутся неизменны и не потребуется дополнительная оплата. Рассчитаться за ремонт владелец может после завершения работ – это главная гарантия того, что работа будет выполнена качественно и аккуратно."
                paragraphDown2="Наш центр специализируется исключительно на ремонте техники Apple, а потому он укомплектован всем необходимым для проведения работ. Благодаря нашим мастерам сотни планшетов были успешно возвращены владельцам, что подтверждают многочисленные благодарные отзывы и положительные отклики о сервисе Мак Профи."
              />
              <p className="repair-iphone-display__paragraph">
                Нередко клиенту требуется не только замена корпуса iPad Mini 4,
                но и установка нового стекла. У нас всегда есть в наличии
                необходимые детали, что значительно ускоряет процесс проведения
                ремонта. Комплексный подход значительно сэкономит ваше время –
                все повреждения будут устранены в кратчайшие сроки.
              </p>
              <p className="repair-iphone-display__paragraph">
                Вы можете обратиться в сервисный центр самостоятельно, либо
                воспользоваться курьерской доставкой – в Москве эта услуга
                предоставляется абсолютно бесплатно. Мы работаем для тех, кто не
                желает переплачивать и выбирает качественный ремонт по честным
                ценам.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelMini4;
