import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairIphoneServicesLinks,
  RepairIphoneModelsLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-dead.png";
import { Helmet } from "react-helmet";

const listOfPower = [
  {
    id: 1,
    number: "01",
    subtitle: "Не работает шлейф синхронизации iPhone (нижний шлейф).",
    textLi: (
      <>
        Причина выхода из строя шлейфа – механическое повреждение. В данном
        случае производится замена шлейфа синхронизации на новый, после чего
        iPhone начинает корректно заряжаться и синхронизироваться с компьютером
        через кабель. Стоимость замены шлейфа вы можете посмотреть вот{" "}
        <Link
          to="/remont-apple/remont-iphone"
          className="repair-mb__group-links-array"
        >
          тут
        </Link>{" "}
        , выбрав вашу модель iPhone.
      </>
    ),
  },
  {
    id: 2,
    number: "02",
    subtitle: "Не работает контроллер питания на iPhone.",
    textLi: (
      <>
        Причина выхода из строя контроллера питания на iPhone – механическое
        повреждение, короткое замыкание, следствие залития iPhone влагой и
        жидкостями (
        <Link
          to="/remont-apple/remont-iphone/zalil_iphone"
          className="repair-mb__group-links-array"
        >
          подробнее
        </Link>{" "}
        по залитию). В данном случае наш сервисный центр предлагает замену
        контроллера питания iPhone на новый. После замены контроллера питания,
        iPhone начинает корректно заряжаться и включаться. Стоимость замены
        контроллера питания от 3850 руб. до 6500 руб. в зависимости от модели
        вашего iPhone.
      </>
    ),
  },
  {
    id: 3,
    number: "03",
    subtitle: "Не работает контроллер тристар (tristar) на iPhone.",
    textLi:
      "В данном случае причины выхода из строя контроллера тристар те же, что и контроллера питания. После замены контроллера тристар, iPhone корректно заряжается и синхронизируется с компьютером.",
  },
  {
    id: 4,
    number: "04",
    subtitle: (
      <>
        Повреждены цепи питания iPhone. Причина – механическое повреждение,
        залитие (
        <Link
          to="/remont-apple/remont-iphone/zalil_iphone"
          className="repair-mb__group-links-array"
        >
          подробнее
        </Link>
        ).
      </>
    ),
    textLi:
      "Для восстановления работоспособности iPhone, в данном случае, требуется восстановление цепей питания.",
  },
];

const textOfPower = [
  {
    id: 1,
    title: "Как мы ремонтируем iPhone который не заряжается и не включается",
    text: "Специалисты выявляют неисправность при бесплатной диагностики. После выявления причин, по которым iPhone не заряжается и не включается, специалисты составляют диагностическую карту. Клиенту называется точная стоимость ремонта, если клиент соглашается на ремонт, специалисты приступают к ремонту iPhone. После ремонта, iPhone полностью корректно заряжается и включается. Клиент проверяет аппарат и оплачивает полную стоимость ремонта",
  },
  {
    id: 2,
    title:
      "Какую гарантию мы предоставляем на ремонт iPhone, который не заряжается и не включается",
    text: "Гарантия на ремонтные работы от 3-х до 6-и месяцев и зависит от того, какие ремонтные работы проводились. Гарантия предоставляется на услуги по ремонту и комплектующие. В гарантийный период мы несем все обязательства по работоспособности iPhone.",
  },
];

const RepairIphoneServiceLinkPower = () => {
  return (
    <Page title="Не заряжается iPhone? Производим ремонт iPhone который не заряжается и не включается">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/ne-zarjazhaetsja"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Производим ремонт iPhone, которые на заряжаются и не включаются. Предоставляем гарантию на все виды работ и комплектующие. Профессиональное оборудование, самые быстрые сроки ремонта!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="iphone не заряжается"
          titleImg="iphone не заряжается"
          banner={banner}
          title="iPhone не заряжается и не включается"
          text="Сервисный центр Мак Профи производит ремонт не заряжающихся iPhone. В следствии чего iPhone не заряжается:"
        />
        <Section className="section_group" style={{ paddingBottom: 34 }}>
          <div className="repair-mb__group-list-container">
            {listOfPower.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          <div className="repair-mb__group-list-container">
            {textOfPower.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkPower;
