import React from "react";
import Section from "../../Section/Section";
import icon1 from "../../../images/icons/center-mobile-advantage-icon1.svg";
import icon2 from "../../../images/icons/center-mobile-advantage-icon2.svg";
import icon3 from "../../../images/icons/center-mobile-advantage-icon3.svg";

const advantages = [
  {
    class: "center-mobile__general-page-advantages-image-file",
    title: "СТАНДАРТ КАЧЕСТВА",
    text: "Клиентский сервис, качество ремонта, гарантия в любой точке как в Столице (в главном офисе).",
  },
  {
    class: "center-mobile__general-page-advantages-image-finger",
    title: "УДОБНО",
    text: "Все бренды, в любой точке РФ, легкий доступ к информации о заказе и опциям управления заказом (оплата, доставка, доп. услуги).",
  },
  {
    class: "center-mobile__general-page-advantages-image-dialogue",
    title: "ПРОСТО",
    text: "Легко сдать в сервис (звонок или кнопка на сайте), быстро продиагностируем и отремонтируем, выдадим или доставим.",
  },
];

const CenterMobileGeneralPageAdvantages = () => {
  return (
    <Section>
      <div className="center-mobile__general-page-advantages">
        <h2 className="center-mobile__general-page-advantages-title">
          Наши преимущества
        </h2>
        <ul className="center-mobile__general-page-advantages-list">
          {advantages.map((advantage, i) => {
            return (
              <li
                className="center-mobile__general-page-advantages-item"
                key={i}
              >
                <div className={advantage.class}>
                  {/* <img src={advantage.img} alt="" /> */}
                </div>
                <h4 className="center-mobile__general-page-advantages-subtitle">
                  {advantage.title}
                </h4>
                <p className="center-mobile__general-page-advantages-text">
                  {advantage.text}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default CenterMobileGeneralPageAdvantages;
