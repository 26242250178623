import { useState, useEffect } from "react";

const useFetch = (fetchFunc) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [filters, setFilters] = useState({ page: 1 });

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      // setLoading(true);

      try {
        const data = await fetchFunc(filters);

        if (!ignore) {
          setData(data);
          //setTimeout(() => setLoading(false), 700);
        }
      } catch (err) {
        if (!ignore) {
          setIsError(true);
          setErrorText(err.message);
          setData(null);
        }
      } finally {
        if (!ignore) {
          setLoading(false);
        }
        // setTimeout(() => setLoading(false), 700);
      }
    };

    fetchData();

    return () => (ignore = true);
  }, [filters, fetchFunc]);

  return {
    data,
    loading,
    isError,
    errorText,
    apiFilters: filters,
    setErrorText,
    setApiFilters: setFilters,
    setData,
    setLoading,
  };
};

export default useFetch;
