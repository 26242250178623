import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageWorkMobile.module.css";
import NoteExpertGeneralPageWorkSliderMobile from "./NoteExpertGeneralPageWorkSliderMobile";
import NoteExpertWorkSlide from "./NoteExpertWorkSlide";

function NoteExpertGeneralPageWorkMobile({ stages }) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>Как мы работаем</h2>
        <NoteExpertGeneralPageWorkSliderMobile stages={stages}>
          {stages.map((item, i) => {
            return (
              <NoteExpertWorkSlide
                key={i}
                number={item.number}
                text={item.text}
                paragraph={item.paragraph}
              />
            );
          })}
        </NoteExpertGeneralPageWorkSliderMobile>
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPageWorkMobile;
