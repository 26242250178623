import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpad3.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Высокий уровень сервиса – нам потребуется около суток, чтобы поменять стекло, после чего устройство будет возвращено владельцу. В сложных случаях сроки могут быть увеличены – этот момент оговаривается в индивидуальном порядке после проведения диагностики.",
    number: "01",
  },
  {
    id: 2,
    text: "Лояльные расценки – наши цены вполне доступны. Они уже включают в себя и работу, и стоимость необходимых комплектующих, а потому вам не придется вносить дополнительные платежи после проведения ремонта – точная сумма будет озвучена еще до замены стекла.",
    number: "02",
  },
  {
    id: 3,
    text: "Услуга курьерской доставки – если у вас нет возможности обратиться в сервис, то наш сотрудник прибудет на дом или в офис, чтобы забрать в планшет, а после ремонта вернуть его заказчику.",
    number: "03",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title: "Особенности замены стекла iPad 3",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Конструкция iPad такова, что самостоятельно его разобрать и
          отремонтировать – довольно сложно. Это задача для квалифицированных
          мастеров, которые обладают не только достаточными знаниями и опытом,
          но и соответствующим оборудованием.
        </p>
        <p className="repair-iphone-display__paragraph">
          Важно! Для замены стекла iPad 3 необходимо нагреть поверхность и
          растопить клей, фиксирующий экран и сенсорную панель. Для этого
          необходима специальная установка – в домашних условиях справиться с
          этой задачей не удастся.
        </p>
        <p className="repair-iphone-display__paragraph">
          Самостоятельно эту работу не выполнить, а риск испортить дорогостоящее
          устройство очень велик. Лучше заплатить за ремонт, зато получить
          уверенность в качественном выполнении поставленной задачи, что
          подтвердит соответствующая гарантия.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Преимущества замены стекла iPad 3 в Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="В Москве для замены стекла iPad 3 вы можете обратиться в сервисный центр Мак Профи. Наши специалисты проведут бесплатную диагностику, чтобы проверить: не стало ли падение или удар причиной иных, внутренних повреждений. Это позволит за одно посещение устранить все поломки, чтобы в дальнейшем не остаться без планшета в непредвиденный момент."
          paragraphUp2="Мы ценим каждого клиента, а потому обращаясь к нам, вы получаете:"
          array={listOfTrouble}
          paragraphDown="Доверьте нам замену стекла iPad 3 – приезжайте в сервисный центр Мак Профи в любой удобный день. Мы работаем без выходных, благодаря чему вы можете получить полный комплекс услуг с понедельника по воскресенье."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModel3 = () => {
  return (
    <Page title="Разбилось стекло iPad 3? Замена стекла на iPad 3 (Айпад 3) в Москве по низкой стоимости">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/3"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена стекла iPad 3 – это не сложная задача, если обратиться к хорошим специалистам. Сервис Oh!MyGadget! обеспечит выгодные цены и высокое качество выполняемых работ."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          alt="Замена стекла на iPad 3"
          titleImg="Замена стекла на iPad 3"
          title="Замена стекла на iPad 3"
          text="iPad 3 – красивый, удобный и функциональный планшет. Вот только он достаточно хрупкий, а потому любое падение может привести к серьезному повреждению стекла. Но даже если вы и разбили любимый планшет, не стоит отчаиваться – мы восстановим его в кратчайшие сроки. Ремонт будет выполнен за сутки, после чего владелец сможет забрать устройство и наслаждаться комфортной работой на нем."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="2950 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModel3;
