import React from "react";

function CenterMobileFeedback(props) {
  return (
    <li className="center-mobile__general-page-feedback-item">
      <img
        src={props.img}
        className="center-mobile__general-page-feedbacks-img"
        alt="аватар пользователя"
      />
      <h4 className="center-mobile__general-page-feedbacks-name">
        {props.name}
      </h4>
      <p className="center-mobile__general-page-feedbacks-text">{props.text}</p>
    </li>
  );
}

export default CenterMobileFeedback;
