import React from "react";
import styles from "./NoteExpertGeneralPageReasons.module.css";
import triangle from "../../../images/icons/note-expert-triangle.svg";

function NoteExpertReason(props) {
  return (
    <li className={styles.item}>
      <div className={styles.circle}>
        <img className={styles.img} src={props.icon} alt="иконка" />
      </div>
      <h4 className={styles.subtitle}>{props.subtitle}</h4>
      <div className={styles.wrapper}>
        <img src={triangle} className={styles.triangle} />
        <p className={styles.text}>{props.text1}</p>
      </div>
      <div className={styles.wrapper}>
        <img src={triangle} className={styles.triangle} />
        <p className={styles.text}>{props.text2}</p>
      </div>
      <div className={styles.wrapper}>
        <img src={triangle} className={styles.triangle} />
        <p className={styles.text}>{props.text3}</p>
      </div>
    </li>
  );
}

export default NoteExpertReason;
