import React from "react";
import Carousel from "react-elastic-carousel";
import Section from "../Section/Section";
import { Link } from "react-router-dom";
import LInk from "./LInk";

const links = [
  {
    to: "/remont-apple/remont-imac",
    text: "ремонт",
    gadget: "iMac",
    classImg: "mac-profi-list__mobile-icon",
  },
  {
    to: "/remont-apple/remont-macbook",
    text: "ремонт",
    gadget: "MacBook",
    classImg: "mac-profi-list__mobile-icon-note",
  },
  {
    to: "/remont-apple/remont-ipad",
    text: "ремонт",
    gadget: "iPad",
    classImg: "mac-profi-list__mobile-icon-ipad",
  },
  {
    to: "/remont-apple/remont-iphone",
    text: "ремонт",
    gadget: "iPhone",
    classImg: "mac-profi-list__mobile-icon-iphone",
  },
  {
    to: "/remont-apple/remont_mac_pro",
    text: "ремонт",
    gadget: "Mac Pro",
    classImg: "mac-profi-list__mobile-icon-macpro",
  },
  {
    to: "/remont-apple/remont_mac_mini",
    text: "ремонт",
    gadget: "Mac Mini",
    classImg: "mac-profi-list__mobile-icon-macmini",
  },
  {
    to: "/remont-apple/remont_apple_watch",
    text: "ремонт",
    gadget: "Apple Watch",
    classImg: "mac-profi-list__mobile-icon-iwatch",
  },
  {
    to: "/remont-apple/remont-ipod",
    text: "ремонт",
    gadget: "iPod",
    classImg: "mac-profi-list__mobile-icon-ipod",
  },
  {
    to: "#",
    text: "Разработка",
    gadget: "под iOS",
    classImg: "mac-profi-list__mobile-icon-ios",
  },
];

const MacProfiListMobile = () => {
  const breakPoints = [
    { width: 340, itemsToShow: 3.5 },
    { width: 370, itemsToShow: 4 },
    { width: 400, itemsToShow: 4.5 },
    { width: 430, itemsToShow: 5 },
    { width: 470, itemsToShow: 5.5 },
    { width: 500, itemsToShow: 6 },
    { width: 570, itemsToShow: 7 },
  ];

  return (
    <>
      <Section>
        <div className="mac-profi-list__mobile-container">
          <ul className="mac-profi-list__mobile-list">
            <Carousel breakPoints={breakPoints}>
              {links.map((link, i) => {
                return (
                  <LInk
                    key={i}
                    to={link.to}
                    text={link.text}
                    gadget={link.gadget}
                    classImg={link.classImg}
                  />
                );
              })}
            </Carousel>
          </ul>
        </div>
      </Section>
    </>
  );
};

export default MacProfiListMobile;
