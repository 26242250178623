import React from "react";

const RepairIphonePriceList = ({ list }) => {
  return (
    <>
      {list.map((item) => {
        return (
          <div
            key={item.id}
            className="repair-iphone__pricelist-item"
            style={{ backgroundColor: item.color, boxShadow: item.boxShadow }}
          >
            <p className="repair-iphone__pricelist-text">{item.text}</p>
            <p className="repair-iphone__pricelist-price">{item.price} P</p>
          </div>
        );
      })}
    </>
  );
};

export default RepairIphonePriceList;
