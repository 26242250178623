import React from "react";
import Section from "../Section/Section";

const RepairIphoneAdvantages = () => {
  return (
    <Section>
      <div className="repair-iphone__advantages-content">
        <h2 className="repair-iphone__advantages-title">
          Наши преимущества при ремонте iPhone
        </h2>
        <p className="repair-iphone__advantages-text">
          У нас всегда есть необходимые для ремонта запчасти, и потому задержек
          с выполнением ремонтных работ не будет. Мы используем только
          качественные и оригинальные детали, и потому уверенно даем на них
          гарантию – они обеспечат работоспособность гаджета на долгий срок, не
          вызовут новых проблем. Все работы выполняются в профессиональных
          условиях, на специально оборудованных рабочих местах, что исключит
          попадание пыли и другие риски, позволит мастерам полностью реализовать
          их талант в деле ремонта и предоставить полноценный результат, который
          вас порадует.
        </p>
        <div className="repair-iphone__advantages-border"></div>
        <p className="repair-iphone__advantages-text">
          У нас есть все необходимое техническое оснащение, которое позволяет
          добиваться успеха даже в самом сложном и тонком ремонте, в буквально
          ювелирной пайке. Все эти и многие другие нюансы становятся слагаемыми
          нашего успеха – компания работает давно и имеет прекрасную репутацию,
          мы можем похвастаться положительными отзывами, тем, что нас
          рекомендуют. Мы поддерживаем репутацию мастерской честной ежедневной
          работой, производя как простой, так и сложный ремонт, учитывая все
          пожелания наших клиентов, консультируя их и помогая.
        </p>
        <div className="repair-iphone__advantages-border"></div>
        <p className="repair-iphone__advantages-text">
          Все работы осуществляются под договор, при составлении полного перечня
          необходимых бумаг, ведь мы работаем официально. В первую очередь
          выполняется диагностика, которую мы проводим бесплатно и в
          экспресс-режиме. Что случилось с айфоном, и почему он перестал
          нормально работать? Узнать об этом вы сможете практически немедленно,
          и также вам огласят стоимость ремонта – она будет окончательной, и в
          процессе выполнения ремонта не изменится. При вашем согласии будет
          немедленно заключен договор, и мастера возьмутся за работу, приводя
          ваш гаджет в норму. Так как мы работаем оперативно в любой ситуации,
          это не отнимет много времени, а при необходимости вы можете
          воспользоваться срочными услугами.
        </p>
      </div>
    </Section>
  );
};

export default RepairIphoneAdvantages;
