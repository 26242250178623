import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Section from "../../Section/Section";
import styles from "./NoteExpertInsidePageCallback.module.css";
import iconTel from "../../../images/icons/center-mobile-icon-tel.svg";

// const callbackText = [
//   "Специалистам Note Expert постоянно приходится сталкиваться с большим разнообразием проблем, с которыми в наш центр обращаются пользователи ноутбуков разных марок.",
//   "Профессиональная настройка нового ноутбука под потребности пользователя может решить многие из них с самого начала, раскрыв все возможности функционала этой портативной техники. ",
//   "Кроме установки операционной системы и ПО (приложений, драйверов, утилит), в нее входит настройка BIOS, экрана, видеокарты, микрофона, камеры, батареи, клавиатуры ноутбука, а также многих других важных для его полноценной работы систем и компонентов.",
// ];

function NoteExpertInsidePageCallback({ title, callbackText }) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.list}>
          {callbackText.map((item, i) => {
            return (
              <li key={i} className={styles.item}>
                <div className={styles.circle}></div>
                <p className={styles.text}>{item}</p>
              </li>
            );
          })}
        </ul>
        <div className={styles.wrapper}>
          <ul className={styles.listLinks}>
            <li className={styles.linkItem}>
              <img
                className={styles.iconTel}
                src={iconTel}
                alt=" иконка телефона"
              />
              <a href="tel:+74953745481" className={styles.link}>
                +7(495)374-54-81
              </a>
            </li>
            <li className={styles.linkItem}>
              <img
                className={styles.iconTel}
                src={iconTel}
                alt=" иконка телефона"
              />
              <a href="tel:+74955405067" className={styles.link}>
                +7(495)540-50-67
              </a>
            </li>
          </ul>
          <Link
            className={styles.button}
            to={"orderForm"}
            smooth={true}
            duration={1500}
            offset={50}
            delay={200}
          >
            Мы Вам перезвоним!
          </Link>
        </div>
      </div>
    </Section>
  );
}

export default NoteExpertInsidePageCallback;
