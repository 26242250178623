import React from "react";
import Section from "../Section/Section";
import iPadBanner from "../../images/banners/repaip-iphone-banner.png";

const RepairIpadBanner = () => {
  return (
    <Section>
      <img
        className="repair-ipad__banner"
        src={iPadBanner}
        alt="ремонт ipad"
        title="ремонт ipad"
      />
    </Section>
  );
};

export default RepairIpadBanner;
