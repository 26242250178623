import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairIphoneServicesLinks,
  RepairIphoneModelsLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-network.png";
import { Helmet } from "react-helmet";

const listOfNetwork = [
  {
    id: 1,
    number: "01",
    subtitle: "Не работает сим карта.",
    textLi:
      "Проблема в данном случае решается путем замены сим карты на новую.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Попала пыль или грязь в сим лоток iPhone.",
    textLi:
      "Часто бывает достаточно обычной чистки сим лотка для возобновления работы сети на iPhone.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Вышел из строя модем.",
    textLi:
      "Характерно в данном случае отсутствие EMEI в iPhone. Проблема решается путем ремонта модема. Ремонт производится на аппаратном уровне и занимает от 3-х часов до 2-х суток.",
  },
  {
    id: 4,
    number: "04",
    subtitle: "Повреждена антенна iPhone.",
    textLi: "Производится замена антенны, либо восстановление антенны iPhone.",
  },
  {
    id: 5,
    number: "05",
    subtitle: "Другие неисправности.",
    textLi:
      "Произведем аппаратную диагностику, выявим неисправность и поможем решить проблему.",
  },
];

const RepairIphoneServiceLinkNetwork = () => {
  return (
    <Page title="iPhone 7, 7 Plus, 6, 6 Plus, 5S, 5 не ловит сеть? Производим восстановление модема, замену Wi-Fi модуля на iPhone!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/ne-lovit-set"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Решаем проблему с iPhone, которые не ловят сеть. Полная бесплатная аппаратная диагностика, кратчайшие сроки ремонта, все комплектующие в наличии, гарантия на работу."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="iphone не ловит сеть"
          titleImg="iphone не ловит сеть"
          banner={banner}
          title="iPhone 7, 7 Plus, 6, 6 Plus, 5S, 5, 4S, 4 не ловит сеть"
          text="Довольно частая проблема, когда iPhone перестает ловить сеть оператора сотовой связи. Данная проблема с iPhone не ловит сеть может возникнуть по следующим причинам:"
        />
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          <div className="repair-mb__group-list-container">
            {listOfNetwork.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
            <p className="repair-mb__group-links-paragraph">
              Будем рады помочь вам решить проблему с iPhone не ловящим сеть
              сотового оператора. Обращайтесь!
            </p>
          </div>
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkNetwork;
