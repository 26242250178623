import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
  RepairAppleTableMobile,
} from "../index";
import { Link } from "react-router-dom";
import banner from "../../images/banners/mb-ssd.jpeg";
import { useMedia } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

const listOfSSD = [
  {
    id: 1,
    number: "01",
    subtitle: "Скорость работы.",
    textLi:
      "SSD диски предоставляют несравненно выше скорость работы системы, в отличии от HDD жестки дисков. Скорость загрузки, выполнения задач, открытия приложения будет в разы выше при установки SSD диска.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Обработка данных на MacBook и iMac.",
    textLi:
      "Скорость копирования с SSD диска в десятки раз ваше чем с HDD. Все данные будут мгновенно копироваться с SSD диска на внешние носители информации, скорость копирования будет ограничена только скоростью внешнего носителя.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Скорость замены SSD.",
    textLi: (
      <>
        Наш сервисный центр произведет замену SSD в течении часа, по вашему
        желанию мы также можем{" "}
        <Link
          to="/remont-apple/remont-macbook/ustanovka_windows_na_macbook_imac"
          className="repair-mb__group-links-array"
        >
          установить Windows на Macbook и iMac
        </Link>
        . Установка и замена SSD производится высококвалифицированными
        специалистами, вы можете быть уверены в качестве работ.
      </>
    ),
  },
];

const priceOfSSD = [
  {
    id: 1,
    number: "01",
    subtitle: "Медленно работает MacBook iMac.",
    textLi:
      "Если вас не устраивает скорость работы вашего MacBook, iMac, установка SSD диска поможет решить эту проблему.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Тормозят игры и приложения на MacBook и iMac.",
    textLi:
      "Чаще всего установка SSD диска решает данную проблему. Если у вас стоит мощная видеокарта, если у вас достаточно оперативной памяти, но игры и приложения по прежнему тормозят, SSD диск решит данную проблему.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "SSD на MacBook вышел из строя, сломался.",
    textLi:
      "Очевидно в данном случае требуется замена SSD диска на новый, наш сервисный центр поможет решить данную проблему, установим SSD диска на MacBook iMac и дадим гарантию на все комплектующие и произведенные работы по установке диска.",
  },
];

const iMacPrice = [
  {
    service: "SSD 128 GB",
    time: "4 часа",
    price: "7100 руб.",
  },
  {
    service: "SSD 256 GB",
    time: "4 часа",
    price: "12700 руб.",
  },
  {
    service: "SSD 512 GB",
    time: "4 часа",
    price: "20300 руб.",
  },
  {
    service: "SSD 960 GB",
    time: "4 часа",
    price: "31200 руб.",
  },
];
const iMacOptions = [
  {
    value: "iMac 2008, 2009, 2010, 2011, 2012, 2013, 2014 годов",
    label: "iMac 2008, 2009, 2010, 2011, 2012, 2013, 2014 годов",
    list: iMacPrice,
  },
];

const macBookAirPriceList1 = [
  {
    service: "SSD 128 GB",
    time: "30 минут",
    price: "14300 руб.",
  },
  {
    service: "SSD 256 GB",
    time: "30 минут",
    price: "18700 руб.",
  },
  {
    service: "SSD 512 GB",
    time: "30 минут",
    price: "25600 руб.",
  },
];
const macBookAirPriceList2 = [
  {
    service: "SSD 128 GB",
    time: "30 минут",
    price: "15100 руб.",
  },
  {
    service: "SSD 256 GB",
    time: "30 минут",
    price: "19800 руб.",
  },
  {
    service: "SSD 512 GB",
    time: "30 минут",
    price: "27200 руб.",
  },
];
const macBookAirOptions = [
  {
    value: "MacBook Air A1370 A1369",
    label: "MacBook Air A1370 A1369",
    list: macBookAirPriceList1,
  },
  {
    value: "MacBook Air A1465 A1466",
    label: "MacBook Air A1465 A1466",
    list: macBookAirPriceList2,
  },
];

const macBookProPriceList = [
  {
    service: "SSD 128 GB",
    time: "30 минут",
    price: "7200 руб.",
  },
  {
    service: "SSD 256 GB",
    time: "30 минут",
    price: "12700 руб.",
  },
  {
    service: "SSD 512 GB",
    time: "30 минут",
    price: "20300 руб.",
  },
  {
    service: "SSD 960 GB",
    time: "30 минут",
    price: "31200 руб.",
  },
];
const macBookProOptions = [
  {
    value: "MacBook White, MacBook Unibody, MacBook Pro",
    label: "MacBook White, MacBook Unibody, MacBook Pro",
    list: macBookProPriceList,
  },
];

const macBookProRetinaPriceList = [
  {
    service: "SSD 512 GB",
    time: "30 минут",
    price: "35600 руб.",
  },
  {
    service: "SSD 768 GB",
    time: "30 минут",
    price: "44900 руб.",
  },
];
const macBookProRetinaOptions = [
  {
    value: "MacBook Pro Retina A1398",
    label: "MacBook Pro Retina A1398",
    list: macBookProRetinaPriceList,
  },
];

function ChangeTableIMac() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={iMacOptions}
        serviceText="Наименование SSD"
      />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>Модель техники</th>
          <th>Наименование SSD</th>
          <th>Стоимость</th>
          <th>Сроки установки</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>iMac 2008, 2009, 2010, 2011, 2012, 2013, 2014 годов</td>
          <td>SSD 128 GB</td>
          <td>7200 руб.</td>
          <td>4 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>iMac 2008, 2009, 2010, 2011, 2012, 2013, 2014 годов</td>
          <td>SSD 256 GB</td>
          <td>12700 руб.</td>
          <td>4 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>iMac 2008, 2009, 2010, 2011, 2012, 2013, 2014 годов</td>
          <td>SSD 512 GB</td>
          <td>20300 руб.</td>
          <td>4 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>iMac 2008, 2009, 2010, 2011, 2012, 2013, 2014 годов</td>
          <td>SSD 960 GB</td>
          <td>31200 руб.</td>
          <td>4 часа</td>
        </tr>
      </tbody>
    </table>
  );
}

function ChangeTableMacBookAir() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={macBookAirOptions}
        serviceText="Наименование SSD"
      />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>Модель техники</th>
          <th>Наименование SSD</th>
          <th>Стоимость</th>
          <th>Сроки установки</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1370 A1369</td>
          <td>SSD 128 GB</td>
          <td>14300 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1370 A1369</td>
          <td>SSD 256 GB</td>
          <td>18700 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1370 A1369</td>
          <td>SSD 512 GB</td>
          <td>25600 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1465 A1466</td>
          <td>SSD 128 GB</td>
          <td>15100 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1465 A1466</td>
          <td>SSD 256 GB</td>
          <td>19800 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1465 A1466</td>
          <td>SSD 512 GB</td>
          <td>27200 руб.</td>
          <td>30 минут</td>
        </tr>
      </tbody>
    </table>
  );
}

function ChangeTableMacBookPro() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={macBookProOptions}
        serviceText="Наименование SSD"
      />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>Модель техники</th>
          <th>Наименование SSD</th>
          <th>Стоимость</th>
          <th>Сроки установки</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook White, MacBook Unibody, MacBook Pro</td>
          <td>SSD 128 GB</td>
          <td>7200 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook White, MacBook Unibody, MacBook Pro</td>
          <td>SSD 256 GB</td>
          <td>12700 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook White, MacBook Unibody, MacBook Pro</td>
          <td>SSD 512 GB</td>
          <td>20300 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook White, MacBook Unibody, MacBook Pro</td>
          <td>SSD 960 GB</td>
          <td>31200 руб.</td>
          <td>30 минут</td>
        </tr>
      </tbody>
    </table>
  );
}

function ChangeTableMacBookProRetina() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={macBookProRetinaOptions}
        serviceText="Наименование SSD"
      />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>Модель техники</th>
          <th>Наименование SSD</th>
          <th>Стоимость</th>
          <th>Сроки установки</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Pro Retina A1398</td>
          <td>SSD 512 GB</td>
          <td>35600 руб.</td>
          <td>30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Pro Retina A1398</td>
          <td>SSD 768 GB</td>
          <td>44900 руб.</td>
          <td>30 минут</td>
        </tr>
      </tbody>
    </table>
  );
}

const RepairMacBookLinkSSD = () => {
  return (
    <Page title="Замена и установка SSD на&nbsp;MacBook Air, Pro, Retina, iMac">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/zamena_ssd_na_macbook_pro_air"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Производим замену неисправных SSD дисков на MacBook Air, устанавливаем высокоскоростные диски в MacBook Pro, Retina. Предоставляем гарантию на SSD и работу."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена и установка ssd на macbook"
          titleImg="замена и установка ssd на macbook"
          banner={banner}
          title="Замена и установка SSD на MacBook Air, Pro, Retina, iMac"
          text="Если у вас MacBook или iMac с обычным HDD (жестким диском) и вам не хватает скорости работы вашего MacBook или iMac - наш сервисный центр знает решение. Предоставляем услугу по установке и замене SSD дисков на MacBook Air, Pro, Retina, iMac. Преимущество SSD:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfSSD.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              В каких случаях стоит менять HDD на&nbsp;SSD диск
            </h2>
            {priceOfSSD.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену и&nbsp;установку SSD на&nbsp;iMac
            </h2>
            {ChangeTableIMac()}
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену и&nbsp;установку SSD на&nbsp;MacBook Air
            </h2>
            {ChangeTableMacBookAir()}
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену и установку SSD на&nbsp;MacBook, MacBook Pro,
              MacBook Unibody
            </h2>
            {ChangeTableMacBookPro()}
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену и установку SSD на&nbsp;MacBook Pro Retina
            </h2>
            {ChangeTableMacBookProRetina()}
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkSSD;

// toDo:
// вынести в константы списки
// вынести банер в индекс
