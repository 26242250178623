import React from "react";

const RepairAppleIphonePrice = ({ title, prices, text }) => {
  return (
    <div className="repair-apple-iphone__price-container">
      <h2 className="repair-apple-iphone__price-title">{title}</h2>
      <ul className="repair-apple-iphone__price-list">
        {prices.map((item) => {
          return (
            <li
              key={item.id}
              className="repair-apple-iphone__price-item"
              style={{ backgroundColor: item.color }}
            >
              <h4 className="repair-apple-iphone__price-span">{item.option}</h4>
              <p className="repair-apple-iphone__price-price">{item.price}</p>
            </li>
          );
        })}
      </ul>
      {text && <p className="repair-apple-price__text">{text}</p>}
    </div>
  );
};

export default RepairAppleIphonePrice;
