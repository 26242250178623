import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames/bind";

const CompanyCard = ({ title, src, imageSrc, to, className, alt }) => {
  return (
    <div className="card">
      <div className="card__cover">
        <img className="card__icon" src={imageSrc} alt="изображение техники" />
      </div>
      <div className="card__wrapper">
        <img className="card__image" alt={alt} src={src} />
        <h4 className="card__title">{title}</h4>
        <Link className={cn("card__link", className)} to={to}>
          Перейти
        </Link>
      </div>
    </div>
  );
};

export default CompanyCard;
