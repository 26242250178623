import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphonese.jpeg";
import { Helmet } from "react-helmet";

const textOfModelSE = [
  {
    id: 1,
    title: "Информация по ремонту iPhone SE",
    text: (
      <>
        Айфон СЕ - относительный новичок на рынке. Этот телефон - ответ
        Купертиновцев тем, кто полюбил старый "кирпичик" пятерки за удачный
        форм-фактор, но хочет на полную использовать все новшества шестёрки.
        Именно таким гибридом лучшего от двух поколений и стал iPhone SE. Он
        идеально чувствуется в руке, буквально врастает в неё, но одновременно
        имеет дисплей "Ретину", отличный процессор A9, камеру на 12 мегапикселей
        и прочие "радости" последних яблочных смартфонов. Эта специфика iPhone
        SE напрямую отражается на его ремонте. Мощный процессор означает почти
        исключённую вероятность "зависания" и прочих программных неприятностей.
        Относительно небольшой размер телефона уменьшает возможность выпадения
        из рук. Да и случае падения iPhone SE благодаря его небольшому экрану
        владелец может отделаться только испугом. Если всё таки вы разбили
        экран, не стоит особенно растраиваться и пересматривать в интернете
        ценники на новый телефон. В конечном счёте, замена дисплея или стекла -
        это задача легко решаемая профессиональными специалистами. Такими,
        например, как наши сотрудники. Ремонт Айфона СЕ может понадобится также
        в следствии износа деталей. Наиболее часто данная проблема возникает у
        аккумулятора и шлейфов телефона. Влага - будь то простая вода, или чай,
        кофе и прочие бытовые жидкости, при попадании внутрь iPhone SE также
        могут принести много неприятностей. Тут в первую очередь потребуется
        диагностика, так как возможно замыкание и выход из строя самых разных
        деталей. К слову, диагностика в наших центрах услуга всегда и безусловно
        бесплатная. Раз зашла речь про наши ремонтные центры, не можем не
        похвастать еще несколькими нашими преимуществами. Во-первых, у нас
        работают курьеры, так что все проблемы вашего Айфона СЕ вы сможете
        решить даже не выходя из дому. Во-вторых, мы даём гарантию на 12
        месяцев. В-третьих, нас выгодно отличает исключительная прозрачность
        ценообразования и весьма умеренные аппетиты. Будьте уверены: за ремонт
        iPhone SE у нас вы заплатите минимально возможное количество из ваших
        "кровно заработанных".
      </>
    ),
  },
];

const priceSE = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPageSE = () => {
  return (
    <Page title="Срочный ремонт iPhone SE (Айфон SE) В Москве, бесплатная диагностика и доставка">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/se"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстрый ремонт iPhone SE в Москве, профессионально, качественно, недорого, бесплатная доставка в сц, диагностика бесплатно."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone SE"
          titleImg="Ремонт iPhone SE"
          banner={banner}
          title="Ремонт iPhone SE"
          text={
            <>
              Мы производим ремонт iPhone SE в Москве, делаем это
              профессионально, на новейшем оборудовании, недорого, быстро и
              надёжно.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceSE}
            title="Цены на ремонт iPhone SE"
          />
        </Section>
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfModelSE.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneModelPageSE;
