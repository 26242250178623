import React from "react";
import { Link } from "react-router-dom";
import { NavList } from "../../components/index";
import { logoHeader } from "../../images/index";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";

const Header = () => {
  const phoneNumber1 = "+74953745481";
  const phoneNumber2 = "+74955405067";

  return (
    <header className="header">
      <div className="header__content">
        <Link to="/">
          <img className="header__logo" src={logoHeader} alt="Oh!MyGadget!" />
        </Link>
        <div className="header__wrapper">
          <ul className="header__tels">
            <li className="header__tel">
              <a href={`tel:${phoneNumber1}?call`} className="header__button">
                {formatPhoneNumber(phoneNumber1)}
              </a>
            </li>
            <li className="header__tel">
              <a href={`tel:${phoneNumber2}?call`} className="header__button">
                {formatPhoneNumber(phoneNumber2)}
              </a>
            </li>
          </ul>
          {/* <Link to="https://ohmygadget.su/login" className="header__link">
            Личный кабинет
          </Link> */}
          <a
            href="https://ohmygadget.su/login"
            rel="noopener noreferrer"
            className="header__link"
          >
            Личный кабинет
          </a>
        </div>
      </div>
      <NavList />
    </header>
  );
};

export default Header;
