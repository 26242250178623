import react from "react";
import FirstIcon from "../../images/icons/cooper-hands.svg";
import SecondIcon from "../../images/icons/cooper_money.svg";
import ThirdIcon from "../../images/icons/cooper-stick.svg";
import { Section } from "../index";
import useMedia from "use-media";
import FirstIconMobile from "../../images/icons/cooperation-icon-mobile.svg";
import SecondIconMobile from "../../images/icons/cooperation-money-mobile.svg";
import ThirdIconMobile from "../../images/icons/cooperation-stick-mobile.svg";

const CooperationInfo = () => {
  const isWide = useMedia({ minWidth: 500 });

  return (
    <Section className="cooperation-info">
      <div className="cooperation-info__container">
        <h2 className="cooperation-info__title">
          Сервисный центр Oh!MyGadget! всегда открыт для сотрудничества.
        </h2>
        <div className="cooperation-info__border"></div>
        <ul className="cooperation-info__list">
          <li className="cooperation-info__list-box">
            {isWide ? (
              <img
                className="cooperation-info__list-image"
                src={FirstIcon}
                alt="иконка"
              />
            ) : (
              <img
                className="cooperation-info__list-image"
                src={FirstIconMobile}
                alt="иконка"
              />
            )}
            <h4 className="cooperation-info__list-text">
              Сервисный центр Oh!MyGadget! всегда открыт для сотрудничества.
            </h4>
          </li>
          <li className="cooperation-info__list-box">
            {isWide ? (
              <img
                className="cooperation-info__list-image"
                src={SecondIcon}
                alt="иконка"
              />
            ) : (
              <img
                className="cooperation-info__list-image"
                src={SecondIconMobile}
                alt="иконка"
              />
            )}
            <h4 className="cooperation-info__list-text">
              Мы предлагаем эксклюзивные цены и индивидуальный подход к
              корпоративным клиентам.
            </h4>
          </li>
          <li className="cooperation-info__list-box">
            {isWide ? (
              <img
                className="cooperation-info__list-image"
                src={ThirdIcon}
                alt="иконка"
              />
            ) : (
              <img
                className="cooperation-info__list-image"
                src={ThirdIconMobile}
                alt="иконка"
              />
            )}
            <h4 className="cooperation-info__list-text">
              Производим ремонт смартфонов, ноутбуков и моноблоков всех
              производителей.
            </h4>
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default CooperationInfo;

// toDo:
// перенести иконки в index, импортировать одной строкой
