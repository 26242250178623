import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpadMini2.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Пятна на экране. Дефект заметен при выключенном дисплее. Он возникает из-за того, что изношенная батарея вздувается и начинает давить на экран изнутри.",
    number: "01",
  },
  {
    id: 2,
    text: "Планшет не заряжается до 100%. Даже при длительном подключении к зарядному устройству, индикатор никогда не заполняется полностью – он достигает лишь определенной отметки, которая периодически варьируется. Но в этом случае решением проблемы может стать не только замена батареи, но и покупка нового зарядного устройства.",
    number: "02",
  },
  {
    id: 3,
    text: "Неожиданное отключение устройства. Несмотря на то, что заряд не опустился ниже 30-40%, планшет выключается. При попытке его включить появляется сообщение о том, что батарея полностью разряжена.",
    number: "03",
  },
  {
    id: 4,
    text: "Время работы составляет меньше 10 часов. Даже при активном использовании iPad Mini 2 Retina, он должен работать хотя бы 8-9 часов – в противном случае, это явный сигнал о необходимости заменить устаревшую батарею.",
    number: "04",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Когда нужно менять аккумулятор iPad Mini 2",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Как только устройство начинает быстро разряжаться и теряет проценты заряда практически ежеминутно, нужно в срочном порядке обратиться к квалифицированным мастерам. Если же эта процедура не будет выполнена своевременно, то могут возникнуть и другие проявления проблем с аккумулятором:"
          array={listOfTrouble}
          paragraphDown="При появлении подобных «симптомов» ни в коем случае нельзя пытаться восстановить планшет самостоятельно. Этим можно лишь значительно усугубить положение, повредив один из шлейфов или разъемов – подобная экономия нецелесообразна, а потому важно изначально обратиться к профессионалам."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelMini2 = () => {
  return (
    <Page title="Замена аккумулятора (АКБ) на iPad Mini 2 в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-akkumuljatora/mini-2"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Теперь вы можете осуществить замену аккумулятора iPad Mini 2 Retina в сервисном центре Oh!MyGadget!. Качество обслуживания, доступные цены обеспечиваются."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          alt="Замена аккумулятора iPad Mini 2"
          titleImg="Замена аккумулятора iPad Mini 2"
          title="Замена аккумулятора iPad Mini 2"
          text="В сервисном центре Oh!MyGadget! вы можете воспользоваться услугой замены аккумулятора iPad Mini 2 Retina. Наши специалисты помогут установить новый источник питания вместо изношенной батареи, а также определят причины, вызвавшие её преждевременный износ."
        />
        <Section className="section_group">
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены аккумулятора Айпад Мини 2"
          text="Замена аккумулятора"
          price="2900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Стандартные расценки на все группы услуг представлены на сайте –
                вы можете ознакомиться с ними в соответствующем разделе. Но
                окончательная стоимость будет озвучена только после проведения
                комплексной диагностики. В некоторых случаях можно обойтись и
                без замены батареи, если быстрая разрядка устройства вызвана
                иными причинами. Диагностика займет всего 10-15 минут, после
                чего вы получите точную информацию о цене и сроках проведения
                ремонта.
              </p>
              <p className="repair-iphone-display__paragraph">
                Вы можете приехать в сервисный центр Мак Профи в любой удобный
                день – мы работаем и на выходных, чтобы каждый клиент мог
                своевременно получить профессиональную техническую помощь.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelMini2;
