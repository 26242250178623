import React from "react";
import Page from "../../Page";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import flyBanner from "../../../images/banners/center-mobile-fly-banner.png";
import {
  pricelists5mini,
  pricelist2Grand,
} from "../../../utils/CenterMobileSamsungPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "УСЛУГИ КУРЬЕРА",
    text: "Если не работает Fly, а времени для самостоятельного визита в центр нет, можно воспользоваться услугами курьера. В этом случае сотрудник «Центр Мобайл» предоставит вам все необходимые документы. После того, как ремонт телефона Fly закончен, устройство сразу возвращается владельцу.",
  },
  {
    img: icon2,
    title: "БЕСПЛАТНАЯ ДОСТАВКА",
    text: "Ремонт телефонов Fly в Москве предусматривает бесплатную доставку в пределах МКАД с 10:00 до 20:00 по рабочим дням. За пределы МКАД и в другое время доставка будет платной. Стоимость согласуется отдельно.",
  },
  {
    img: icon3,
    title: "ГАРАНТИЯ",
    text: "На все виды ремонта смартфонов Флай «Центр Мобайл» предоставляет гарантию, сроки зависят от вида работ и комплектующих. Например, на паяльные работы гарантия действует на протяжении 3 месяцев, а на замену комплектующих – от 6 месяцев.",
  },
  {
    img: icon4,
    title: "ВЫЗОВ МАСТЕРА НА ДОМ",
    text: "При несерьезной поломке можно вызвать мастера на дом. Для этого достаточно связаться с нами по электронной почте zakaz@centre-mobile.ru или по телефону.",
  },
];

const mobiles = [
  { name: "Fly Life Compact 4G", pricelist: pricelists5mini },
  { name: "Fly Power Plus 3", pricelist: pricelist2Grand },
  { name: "Fly Life Mega", pricelist: pricelists5mini },
  { name: "Fly Life Compact", pricelist: pricelist2Grand },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileIRepairFly() {
  return (
    <Page title="Квалифицированный ремонт смартфонов Fly, сервисный центр Oh!MyGadget! (Москва)">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-smartfonov-fly"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Специалисты компании Oh!MyGadget! предоставляют профессиональные услуги по ремонту смартфонов Fly в Москве. Конкурентоспособные цены на все виды услуг, гарантии на работу и комплектующие."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={flyBanner}
          alt="смартфон Fly"
          title="смартфон Fly"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ СМАРТФОНОВ FLY"
          text="Качественный и быстрый ремонт мобильных телефонов Fly по выгодной цене гарантирует сервисный центр «Центр Мобайл». Многолетний опыт, современное оснащение и индивидуальный подход к каждой поломке позволяет решить даже самые сложные проблемы за оптимально короткий срок и по демократичным ценам."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт FLY"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            FLY:"
          mobiles={mobiles}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА РЕМОНТА ТЕЛЕФОНОВ FLY В Oh!MyGadget!"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ЗАКАЗАТЬ РЕМОНТ ?"
          text="Для того чтобы воспользоваться профессиональными услугами мастеров «Центр Мобайл», можно привезти свое неработающее устройство к нам в офис, можно вызвать курьера, а при небольшой поломке – мастера на дом. «Центр Мобайл» – это удобный сервис, доступные цены и качественный ремонт."
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileIRepairFly;
