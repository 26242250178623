import React from "react";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const RepairAppleTextWithCheckMark = (props) => {
  return (
    <>
      {props.paragraphUp ? (
        <p className="repair-iphone-display__paragraph">{props.paragraphUp}</p>
      ) : null}
      {props.paragraphUp2 ? (
        <p className="repair-iphone-display__paragraph">{props.paragraphUp2}</p>
      ) : null}
      {props.paragraphUp3 ? (
        <p className="repair-iphone-display__paragraph">{props.paragraphUp3}</p>
      ) : null}
      {props.paragraphUp4 ? (
        <p className="repair-iphone-display__paragraph">{props.paragraphUp4}</p>
      ) : null}
      <ul className="repair-iphone-display__list">
        {props.array.map((item) => {
          return (
            <li key={item.id} className="repair-iphone-display__item">
              <p className="repair-iphone__models-links-number">
                {item.number}
              </p>
              <img
                src={IconCheckMark}
                alt="иконка галочки"
                className="repair-iphone__models-links-img"
              />
              <p className="repair-iphone-display__paragraph">{item.text}</p>
            </li>
          );
        })}
      </ul>
      {props.paragraphDown ? (
        <p className="repair-iphone-display__paragraph-down">
          {props.paragraphDown}
        </p>
      ) : null}
      {props.paragraphDown2 ? (
        <p className="repair-iphone-display__paragraph-down">
          {props.paragraphDown2}
        </p>
      ) : null}
    </>
  );
};

export default RepairAppleTextWithCheckMark;
