import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone8plus.png";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfMicrophone8Plus = [
  {
    id: 1,
    title: "Причины для замены микрофона iPhone 8 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если проблема так и не была решена, вероятно, вам потребуется замена
          микрофона iPhone 8 Plus, и нужно будет обратиться в сервис. Но даже
          тут есть и еще один шанс на минимальные затраты. Иногда звук пропадает
          из-за того, что динамик засоряется, и в этом случае достаточно бывает
          просто прочистить его. Если вы столкнулись именно с такой ситуацией,
          все же не стоит пытаться решить проблему в домашних условиях. Чистка
          Айфонов – это тоже удел профессионалов, и такая манипуляция должна
          проводиться в особых условиях. Доверьте выполнение работ мастерам!
        </p>
        <p className="repair-iphone-display__paragraph">
          Если динамик чист, то вероятнее всего придется выполнять его замену.
          Если вы столкнулись с подобной необходимостью – важно найти достойного
          исполнителя, который сможет реализовать все поставленные задачи в
          минимальные сроки, качественно и с гарантией. Радует, что подобные
          возможности сегодня существуют, и вы можете воспользоваться ими без
          проблем.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Замена микрофона iPhone 8 Plus от профессионалов",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если ваша техника стала причиной проблем, если она отказала полностью
          или утратила какие-либо свои функции, переживать не стоит. Наши
          специалисты оказывают профессиональную помощь всем владельцам Айфонов
          – мы специализируемся на работе с высокотехнологичным оборудованием, и
          добиваемся прекрасных результатов в его ремонте! Задачи любой
          сложности оказываются нам по плечу: многолетний опыт и индивидуальное
          внимание к каждой из проблемных ситуаций позволяют оказывать
          качественные услуги ремонта, на которые можно уверенно давать
          длительную гарантию.
        </p>
        <p className="repair-iphone-display__paragraph">
          В нашем центра гарантия распространяется не только на услуги, но и на
          все детали, которые были использованы в процессе ремонтных работ. Мы
          используем запчасти только официального происхождения, только
          заводские детали, в качестве которых не приходится сомневаться. Работы
          выполняются квалифицированными специалистами со стажем и опытом, с
          профильным образованием – они знают об Айфонах и современном
          высокотехнологичном оборудовании буквально все. Обратившись к нам, вы
          сможете получить качественный профессиональный ремонт по доступной
          цене.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels8Plus = () => {
  return (
    <Page title="Замена микрофона на iPhone 8 Plus за 35 минут в Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-mikrofona/8-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Когда требуется замена микрофона iPhone 8 Plus, переживать не стоит, техника будет отремонтирована. Сервис Oh!MyGadget! в Москве ждет вас!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена микрофона iPhone 8 Plus"
          titleImg="Замена микрофона iPhone 8 Plus"
          banner={banner}
          title="Замена микрофона iPhone 8 Plus"
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="3700 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Как правило, Айфон оказывается весьма устойчивым к любым
                поломкам оборудованием, за что его и ценят высоко. Как
                специалисты, так и рядовые пользователи отмечают, что поломки и
                неисправности случаются крайне редко. Однако они все же
                происходят, и посетители порой обращаются в центры с такой
                необходимостью, как замена динамика iPhone 8 Plus. Большинство
                проблем в этом секторе решается именно посредством замены
                элемента, и весьма радует, что эту процедуру можно выполнить
                очень быстро, не обременяя человека излишними финансовыми
                затратами. Замена позволяет исключить проблемы в следующих
                случаях:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Не работает функция звукозаписи в диктофоне и при съемке
                    видео, результат, если он есть, оказывается крайне
                    неудовлетворительным,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    При попытке разговора с собеседником он жалуется на шумы
                    разного рода,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Вас просто не слышат, если вы пытаетесь позвонить.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Иногда проблема такого рода решается довольно легко, и прежде
                чем обращаться в сервис, необходимо провести некоторые
                мероприятия. Перезагрузите девайс, если сбой носит программный
                характер, этим удастся устранить проблему. Попробуйте позвонить
                другим абонентам, возможно, что тот, кого вы не слышите,
                находится в зоне плохой связи. Также попытайтесь сменить
                дислокацию самостоятельно, особенно если записывается звук
                хорошо, а при разговоре его не слышно. Иногда эти простейшие
                мероприятия позволяют полностью решить проблему.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone8Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels8Plus;
