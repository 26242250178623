import React from "react";
import { NavLink } from "react-router-dom";
import { Section } from "../index";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";

const iMacLinks = [
  {
    id: 1,
    src: "/remont-apple/remont-imac/chistka_matricy_imac",
    text: "Чистка матрицы iMac",
  },
  {
    id: 2,
    src: "/remont-apple/remont-imac/remont_materinskoj_platy_imac",
    text: "iMac ремонт материнской платы",
  },
  {
    id: 3,
    src: "/remont-apple/remont-imac/zamena_stekla_imac",
    text: "Замена защитного стекла iMac",
  },
  {
    id: 4,
    src: "/remont-apple/remont-imac/zamena_zhestkogo_diska_na_imac",
    text: "iMac замена жесткого диска",
  },
];

const RepairIMacLinks = () => {
  return (
    <Section className="repair-imac__links-section" style={{ marginTop: 47 }}>
      <div className="repair-imac__links-container">
        <h2 className="repair-imac__links-title">Детально по услугам:</h2>
        <ul className="repair-imac__links-list">
          {iMacLinks.map((link) => {
            return (
              <li className="repair-imac__links-item" key={link.id}>
                <img
                  src={IconCheckMark}
                  alt="иконка галочки"
                  className="repair-imac__links-icon"
                />
                <NavLink to={link.src} className="repair-imac__links-link">
                  {link.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default RepairIMacLinks;
