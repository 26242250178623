import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PopupAddNotification, PopupConfirm, Preloader } from "../components";
import { notificationsApi } from "../utils/api";
import { pencilIcon, trashIcon, undoIcon, userIDIcon } from "./newIcons/index";
import usePopup from "../hooks/usePopup";

const AdminNotificationDetail = () => {
  const { id } = useParams();
  const [notificationById, setNotificationById] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const { isPopupOpen, togglePopup, closePopup } = usePopup();
  const {
    isPopupOpen: isChangePopupOpen,
    togglePopup: toggleChangePopup,
    closePopup: closeChangePopup,
  } = usePopup();

  useEffect(() => {
    const fetchNotificationById = async () => {
      setLoading(true);

      try {
        const notificationById = await notificationsApi.getNotificationById(id);
        setNotificationById(notificationById);
      } catch (error) {
        setIsError(true);
      }
      setTimeout(() => setLoading(false), 1200);
    };
    fetchNotificationById();
  }, [id]);

  const handleDeleteNotification = async () => {
    const thisNotification = await notificationsApi.getNotificationById(id);

    await notificationsApi.deleteNotification(thisNotification._id);
    setIsDeleted(true);
  };

  const handleSubmit = () => {
    handleDeleteNotification();
    closePopup();
  };

  const handleChangeNotification = async (title, text) => {
    const thisNotification = await notificationsApi.getNotificationById(id);
    const editedNotification = await notificationsApi.editNotification(
      thisNotification._id,
      { title: title, text: text }
    );
    setNotificationById(editedNotification);

    // Update data on page !!!!!!!!!
  };

  return (
    <>
      <section className="info">
        <div className="info__container">
          {loading ? (
            <Preloader />
          ) : (
            <>
              <ul className="info__card">
                <li className="info__li">
                  <div className="info__wrapper">
                    <img
                      src={userIDIcon}
                      className="info__icon"
                      alt="иконка профиля"
                    />
                    <p className="info__item">id: {id}</p>
                  </div>
                  <Link className="info__link" to="/admin-page/notifications/">
                    <img
                      src={undoIcon}
                      alt="иконка возвращения в предыдущий раздел"
                      className="info__icon"
                    />
                    назад ко всем оповещениям
                  </Link>
                </li>
                {isError && (
                  <p className="info__item">
                    Проверьте id. Оповещение с id: {id} в базе данных не найдено
                  </p>
                )}

                {isDeleted ? (
                  <>
                    <p className="info__item info__item_type_deleted">
                      Оповещение с id: {id} было успешно удалено
                    </p>
                    <Link
                      className="info__delited"
                      to="/admin-page/notifications/"
                    >
                      Вы можете вернуться ко всем оповещениям
                    </Link>
                  </>
                ) : (
                  <>
                    {notificationById && (
                      <li className="info__li">
                        <button
                          className="info__button"
                          onClick={toggleChangePopup}
                          aria-label="изменить заголовок оповещения"
                        >
                          <img
                            src={pencilIcon}
                            className="info__icon"
                            alt="иконка кнопки редактирования"
                          />
                        </button>
                        {notificationById && (
                          <p className="info__item">
                            повод: {notificationById.title}
                          </p>
                        )}
                      </li>
                    )}

                    {notificationById && (
                      <li className="info__li">
                        <div className="info__space"></div>
                        {notificationById && (
                          <p className="info__item">
                            текст: {notificationById.text}
                          </p>
                        )}
                      </li>
                    )}
                  </>
                )}
              </ul>
              {!isError && !isDeleted && (
                <button
                  className="info__button"
                  onClick={togglePopup}
                  aria-label="удалить оповещение"
                >
                  <img
                    src={trashIcon}
                    className="info__icon"
                    alt="иконка удаления профиля"
                  />
                  <p className="info__item">удалить оповещение</p>
                </button>
              )}
            </>
          )}
        </div>
      </section>
      <PopupConfirm
        isOpenPopup={isPopupOpen}
        onClose={closePopup}
        handleSubmit={handleSubmit}
      />
      <PopupAddNotification
        isOpenPopup={isChangePopupOpen}
        onClose={closeChangePopup}
        handleAddNotification={handleChangeNotification}
      />
    </>
  );
};

export default AdminNotificationDetail;
