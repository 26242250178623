import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
} from "../index";
import banner from "../../images/banners/repair-ipadPro9.jpeg";
import { Helmet } from "react-helmet";

const priceIpadPro9 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "13900 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "13900 P",
  },
  {
    id: 3,
    option: "Замена аккумулятора",
    price: "8500 P",
  },
  {
    id: 4,
    option: "Замена микрофона",
    price: "2950 P",
  },
  {
    id: 5,
    option: "Замена динамика",
    price: "4450 P",
  },
  {
    id: 6,
    option: "Замена кнопки включения",
    price: "3850 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "6350 P",
  },
  {
    id: 8,
    option: "Замена основной камеры",
    price: "4450 P",
  },
  {
    id: 9,
    option: "Замена передней камеры",
    price: "4450 P",
  },
  {
    id: 10,
    option: "Замена разъема наушников",
    price: "3450 P",
  },
  {
    id: 11,
    option: "Замена разъема зарядки",
    price: "3450 P",
  },
  {
    id: 12,
    option: "Замена кнопки Home (домой)",
    price: "2900 P",
  },
];

const textOfIpadPro9 = [
  {
    id: 1,
    title: 'Специфика ремонта iPad Pro 9.7"',
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Внешне "младшая" версия Pro-серии практически не отличается от своего
          большого собрата, однако, столь впечатляющего сочетания малых
          габаритов и поразительной производительности специалистам из Apple
          удалось достичь благодаря многим специфичным конструктивным решениям,
          которые делают ремонт 9,7-дюймового iPad Pro весьма непростым делом.
          Без специфичного профессионального оборудования и, главное,
          безукоризненных навыков ремонта ремонт планшета практически
          невозможен.
        </p>
        <p className="repair-iphone-display__paragraph">
          Дисплей и стекло Айпэд Про 9.7 соединены вместе в единый дисплейный
          модуль, то есть, их раздельная замена невозможна. При сборке щедро
          использовался специальный клей, на котором держится абсолютное
          большинство деталей. Особо следует отметить приклеенный к корпусу iPad
          Pro 9.7" аккумулятор. Активно используются специфические кабели, а
          замена разъёма Smart Connector может стать настоящей головной болью
          для специалистов ремонта.
        </p>
        <p className="repair-iphone-display__paragraph">
          В iPad Pro 9.7" использован тот же фото модуль iSight что и в 6s+
          айфонах. С одной стороны - это хорошая новость, так как облегчает
          поиск соответствующих деталей. С другой, инженерам из Купертино, к
          сожалению, не удалось полностью уместить камеру внутри изящного
          корпуса 9.7" модели, и она немного выпирает наружу. Последнее
          увенчивает опасность повреждения камеры от падения или удара.
        </p>
        <p className="repair-iphone-display__paragraph">
          Казалось бы единственный совет, который можно дать гордым обладателям
          iPad Pro 9.7" - планшет лучше не ронять и не ударять. Однако, избежать
          житейских случайностей невозможно, и те или иные поломки все равно
          рано или поздно появятся. В таком случае нельзя надеяться на авось,
          пробуя починить Айпэд Про 9.7 самостоятельно, или же в сомнительных
          ремонтных мастерских.
        </p>
      </>
    ),
  },
];

const RepairIpadModelPagesIpadPro9 = () => {
  return (
    <Page
      title={"Срочный ремонт iPad Pro 9.7 Москва, цены на ремонт Айпад Про 9.7"}
    >
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="http://localhost:3000/remont-apple/remont-ipad/pro-9"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Oh!MyGadget! специализируется на ремонте iPad Pro 9.7"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title='Ремонт iPad Pro 9.7"'
          alt="Ремонт iPad Pro 9.7"
          titleImg="Ремонт iPad Pro 9.7"
          text='Наша кампания специализируется на ремонте iPad Pro 9.7" и других продуктов знаменитой купертиновской компании. Ремонт производится исключительно на современном и профессиональном оборудовании и только лишь мастерами высочайших навыков. Всем клиентам выдаётся гарантия на все произведённые работы. Наши центры находятся в Москве. Кроме того, при необходимости, вы можете воспользоваться услугами наших курьеров.'
        />
        <Section style={{ marginTop: 65 }}>
          <RepairAppleIphonePrice
            prices={priceIpadPro9}
            title='Цены на ремонт iPad Pro 9.7"'
            text="* Цены указаны в рублях РФ. Стоимость включает комплектующее и установку."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpadPro9.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpadPro9;
