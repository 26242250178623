import React from "react";
import { Link } from "react-router-dom";
import iconGeo from "../../images/icons/mac-profi_icon1.svg";
import iconDelivery from "../../images/icons/mac-profi_icon2.svg";
import Section from "../Section/Section";
const bannerLinks = [
  {
    img: iconGeo,
    link: "/#contacts",
    text: "Наш сервисный центр в Москве",
  },
  {
    img: iconDelivery,
    link: "/dostavka",
    text: "Курьерская доставка",
  },
];

const MacProfiBanner = () => {
  return (
    <Section>
      <div className="mac-profi__content">
        <div className="mac-profi__container">
          <h1 className="mac-profi__title">
            Сервисный центр Мак Профи - Ремонт Apple
          </h1>
          <ul className="mac-profi__links">
            {bannerLinks.map((link, i) => {
              return (
                <li key={i} className="mac-profi__item">
                  <img src={link.img} alt="" className="mac-profi__lable" />
                  <Link to={link.link} className="mac-profi__link">
                    {link.text}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="mac-profi__selects"></div>
        </div>
      </div>
    </Section>
  );
};

export default MacProfiBanner;

// toDo:
// h1 ???
// прописать и заполнить всем img альты!
// заменить теги a на реактовские Link или NavLink
// проверить семантику. можно ли заменить на что-то дивы?
// вынести все иконки в index и импортировать одной строкой
