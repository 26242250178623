import React from "react";
import Section from "../../Section/Section";
import icon1 from "../../../images/icons/center-mobile-repair-icon1.svg";
import icon2 from "../../../images/icons/center-mobile-repair-icon2.svg";
import icon3 from "../../../images/icons/center-mobile-repair-icon3.svg";
import icon4 from "../../../images/icons/center-mobile-repair-icon4.svg";
import icon5 from "../../../images/icons/center-mobile-repair-icon5.svg";
import icon6 from "../../../images/icons/center-mobile-repair-icon6.svg";

const elements = [
  {
    img: icon1,
    text: "Настройка и оптимизация ОС",
  },
  {
    img: icon2,
    text: "Срочный ремонт",
  },
  {
    img: icon3,
    text: "Высокоточная диагностика неисправностей",
  },
  {
    img: icon4,
    text: "Установка программного обеспечения",
  },
  {
    img: icon5,
    text: "Профилактическое обслуживание",
  },
  {
    img: icon6,
    text: "Ремонт любой степени сложности",
  },
];

const CenterMobileGeneralPageRepair = () => {
  return (
    <Section className="center-mobile__general-page-repair">
      <div className="center-mobile__general-page-repair-container">
        <h2 className="center-mobile__general-page-repair-title">
          КАЧЕСТВЕННЫЙ РЕМОНТ
        </h2>
        <ul className="center-mobile__general-page-repair-list">
          {elements.map((item, i) => {
            return (
              <li
                key={i}
                className="center-mobile__general-page-repair-element"
              >
                <img
                  src={item.img}
                  className="center-mobile__general-page-repair-image"
                  alt="иконка"
                />
                <p className="center-mobile__general-page-repair-item">
                  {item.text}
                </p>
              </li>
            );
          })}
        </ul>
        <p className="center-mobile__general-page-repair-text">
          Специалисты нашего сервисного центра имеют большой опыт ремонта
          планшетов и смартфоновразличных брендов, отлично знают проблемные
          места каждой марки и модели и досконально разбираются во всех нюансах
          ремонта этих коммуникационных устройств.
        </p>
      </div>
    </Section>
  );
};

export default CenterMobileGeneralPageRepair;
