import React from "react";
import Section from "../../Section/Section";
import macBook from "../../../images/center-mobile-full-macbook.svg";

function CenterMobileGeneralPageDescription() {
  return (
    <Section className="center-mobile__general-page-description">
      <div className="center-mobile__general-page-description-content">
        <h2 className="center-mobile__general-page-description-title">
          Ремонт планшетов и смартфонов в москве
        </h2>
        <div className="center-mobile__general-page-description-wrapper">
          <p className="center-mobile__general-page-description-text">
            Мобильные коммуникационные устройства, степень популярности которых
            сегодня сложно переоценить, получили широкое распространение в
            современной жизни. Это, в свою очередь, делает актуальными и весьма
            востребованными услуги сервисных центров по ремонту планшетов и
            смартфонов, поскольку какими бы надежными и высокотехнологичными не
            были эти гаджеты,они остаются всего лишь техникой. А значит, могут
            некорректно работать, выходить из строя, получать повреждения и
            программные сбои.
          </p>
          <img
            className="center-mobile__general-page-description-image"
            src={macBook}
            alt="картинка компьютера"
          />
        </div>
      </div>
    </Section>
  );
}

export default CenterMobileGeneralPageDescription;
