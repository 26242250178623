import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileAdvantages.module.css";
// import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
// import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
// import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
// import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import CenterMobileIRepairMobileAdvantagesTabletScreen from "./CenterMobileIRepairMobileAdvantagesTabletScreen";
import CenterMobileIRepairMobileAdvantagesMobile from "./CenterMobileIRepairMobileAdvantagesMobile";
import { useMedia } from "../../../utils/useMedia";

function ChangePage({ title, titleMobile, contentForAdvantages }) {
  const isBreakpointTableScreen = useMedia("(min-width: 980px)");
  const isBreakpointMobile = useMedia("(max-width: 600px)");

  if (isBreakpointTableScreen) {
    return (
      <Section>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <ul className={styles.list}>
            {contentForAdvantages.map((box, i) => {
              return (
                <li key={i} className={styles.box}>
                  <div className={styles.image}>
                    <img src={box.img} alt="иконка" />
                  </div>
                  <h4 className={styles.boxTitle}>{box.title}</h4>
                  <p className={styles.boxText}>{box.text}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </Section>
    );
  } else if (isBreakpointMobile) {
    return (
      <CenterMobileIRepairMobileAdvantagesMobile
        titleMobile={titleMobile}
        contentForAdvantages={contentForAdvantages}
      />
    );
  }
  return (
    <CenterMobileIRepairMobileAdvantagesTabletScreen
      title={title}
      contentForAdvantages={contentForAdvantages}
    />
  );
}

function CenterMobileIRepairMobileAdvantages({
  title,
  titleMobile,
  contentForAdvantages,
}) {
  return ChangePage({ title, titleMobile, contentForAdvantages });
}

export default CenterMobileIRepairMobileAdvantages;
