import React from "react";
import Section from "../Section/Section";
import bannerMacMini from "../../images/banners/repair-mb-banner.png";

const RepairMacMiniBanner = () => {
  return (
    <Section>
      <img
        className="repair-mac-mini__banner"
        src={bannerMacMini}
        alt="ремонт mac mini"
        title="ремонт mac mini"
      />
    </Section>
  );
};

export default RepairMacMiniBanner;
