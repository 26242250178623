import React from "react";
import { Link } from "react-router-dom";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageServiceLinks.module.css";
import tre from "../../../images/icons/note-expert-service-links-icon.svg";

const links = [
  {
    text: "ремонт материнской платы",
    to: "/note-expert/remont-materinskoy-plati",
  },
  {
    text: "ремонт и замена матрицы",
    to: "/note-expert/zamena-matricy",
  },
  {
    text: "ремонт охлаждения",
    to: "#",
  },
  {
    text: "модернизация ноутбука",
    to: "/note-expert/upgrade",
  },
  {
    text: "увеличение оперативной памяти",
    to: "/note-expert/ustanovka-ozu",
  },
  {
    text: "замена жесткого диска",
    to: "/note-expert/zamena-hdd",
  },
  {
    text: "настройка ноутбука",
    to: "/note-expert/nastroyka",
  },
  {
    text: "установка Windows",
    to: "/note-expert/ustanovka-windows",
  },
  {
    text: "установка программ",
    to: "/note-expert/ustanovka-programm",
  },
];

function NoteExpertGeneralPageServiceLinks() {
  return (
    <Section className={styles.section}>
      <div className={styles.content}>
        <ul className={styles.list}>
          {links.map((link, i) => {
            return (
              <li key={i}>
                <img className={styles.icon} src={tre} alt="иконка" />
                <Link className={styles.link} to={link.to}>
                  {link.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPageServiceLinks;
