import React from "react";
import clock from "../../images/icons/mp-reasons-clock.svg";
import set from "../../images/icons/mp-reasons-set.svg";
import star from "../../images/icons/mp-reasons-star.svg";
import man from "../../images/icons/mp-reasons-man.svg";
import key from "../../images/icons/mp-reasons-key.svg";
import lable from "../../images/icons/mp-reasons-lable.svg";
import price from "../../images/icons/mp-reasons-nagr.svg";
import Section from "../Section/Section";
import { useMobileScreen } from "../../utils/useMedia";

const reasons = [
  {
    image: clock,
    title: "Быстрый ремонт",
    text: "Ремонт сложной техники iMac и MacBook в 90% осуществляется в течении 1-го - 3-х рабочих дней. Ремонт iPhone и iPad производится в большинстве случаев при клиенте и занимает от 10 минут до 2-х часов.",
  },
  {
    image: set,
    title: "Комплектующие",
    text: "Мы располагаем в наличии всеми комплектующими для ремонта Apple. Вам не придется ждать доставки комплектующих, все комплектующие у нас есть в наличии.",
  },
  {
    image: star,
    title: "Качество",
    text: "Каждый ремонт производится с контролем качества ремонта, что гарантирует вероятность того, что ваша техника после ремонта будет исправно функционировать и вы не столкнетесь с последующими проблемами при использовании своей техники.",
  },
  {
    image: man,
    title: "Сертифицированные специалисты",
    text: "Ремонтные работы техники Apple в нашем сервисном центре производят только квалифицированные специалисты по ремонту Apple. Отдавая свою технику в ремонт, вы можете быть уверены, что отдаете ее профессионалам и ремонт будет квалифицированным.",
  },
  {
    image: key,
    title: "Фирменное оборудование",
    text: "Мы располагаем фирменным оборудованием для ремонта техники Apple.",
  },
  {
    image: lable,
    title: "Приемлемые цены",
    text: "Цены на ремонт Apple  в нашей компании среднерыночные. Мы выполняем работу качественно и по самым приемлемым ценам.",
  },
  {
    image: price,
    title: "Гарантия",
    text: "Мы предоставляем гарантию на все работы до 2-х лет, произведенные у нас в сервисном центре. Срок гарантии зависит от типа работ и комплектующих применяемых в процессе ремонта.",
  },
];

function ChangeBlock() {
  const isMedia = useMobileScreen();
  if (isMedia) {
    return (
      <>
        {reasons.map((item, i) => {
          return (
            <li key={i} className="mac-profi__reasons-item-mobile">
              <div className="mac-profi__reasons-list-wrapper-mobile">
                <img
                  src={item.image}
                  className="mac-profi__reasons-icon"
                  alt="иконка"
                />
                <h4 className="mac-profi__reasons">{item.title}</h4>
              </div>
              <p className="mac-profi__reasons-text">{item.text}</p>
            </li>
          );
        })}
      </>
    );
  }
  return (
    <>
      {reasons.map((item, i) => {
        return (
          <li key={i} className="mac-profi__reasons-item">
            <img
              src={item.image}
              className="mac-profi__reasons-icon"
              alt="иконка"
            />
            <div className="mac-profi__reasons-list-wrapper">
              <h4 className="mac-profi__reasons">{item.title}</h4>
              <p className="mac-profi__reasons-text">{item.text}</p>
            </div>
          </li>
        );
      })}
    </>
  );
}

const MacProfiReasons = () => {
  return (
    <Section>
      <div className="mac-profi__reasons-container">
        <h3 className="mac-profi__reasons-title">
          Клиенты выбирают Мак Профи - сервисный центр по ремонту техники Apple,
          по следующим причинам:
        </h3>
        <ul className="mac-profi__reasons-list">{ChangeBlock()}</ul>
        <div className="mac-profi__reasons-border"></div>
        <p className="mac-profi__reasons-paragraph">
          Наш сервисный центр Apple всегда рад новым клиентам. Мы ценим каждого
          клиента и делаем все возможное, чтобы вы получили от нашей работы
          только положительные эмоции.
        </p>
      </div>
    </Section>
  );
};

export default MacProfiReasons;

// toDo:
// перенести все иконки/картинки в индекс и импортировать одной строкой
// h1 ???
// добавить и заполнить всем img альты
// проверить верстку на семантику
// точно ли div, а не section или что-то еще?
