import React, { useState } from "react";
import Section from "../Section/Section";
import Select, { components } from "react-select";
import vector from "../../images/icons/delivery-vector.svg";
import { useMobileScreen } from "../../utils/useMedia";
import CallBackFormPopup from "../CallBackFormPopup/CallBackFormPopup";
import { Dialog } from "@headlessui/react";
import DeliveryFormApplication from "../DeliveryFormApplication/DeliveryFormApplication";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img alt="" src={vector} style={{ marginRight: 17 }} />
  </components.IndicatorsContainer>
);

const DeliveryForms = ({
  isDeliveryFormApplication,
  setIsDeliveryFormApplication,
}) => {
  const [city, setCity] = useState(0);
  const [device, setDevice] = useState(0);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  // const [isOpenDeliveryFormPopup, setIsOpenDeliveryFormPopup] = useState(false);

  function ChangeFormButton() {
    const isMedia = useMobileScreen();
    if (isMedia) {
      return (
        <>
          <button className="delivery-form__button-mobile">
            Заполнить форму
          </button>
        </>
      );
    }
    return (
      <>
        <ul className="delivery-forms__buttons">
          <li className="delivery-forms__item">
            <button
              type="button"
              className="delivery-forms__button"
              onClick={handleOpenDeliveryForm}
            >
              Заполнить форму
            </button>
          </li>
          <li className="delivery-forms__item">|</li>
          <li className="delivery-forms__item">
            <button
              onClick={() => setIsOpenPopup(true)}
              type="button"
              className="delivery-forms__button"
            >
              Перезвоните мне
            </button>
          </li>
        </ul>
      </>
    );
  }

  const cities = [
    { value: "moscow", label: "г.Москва", price: 200 },
    { value: "petersburg", label: "г.Санкт-Петербург", price: 400 },
    { value: "moscow", label: "г.Москва", price: 600 },
    { value: "moscow", label: "г.Москва", price: 500 },
    { value: "moscow", label: "г.Москва", price: 450 },
    { value: "moscow", label: "г.Москва", price: 550 },
    { value: "moscow", label: "г.Москва", price: 670 },
    { value: "moscow", label: "г.Москва", price: 1500 },
    { value: "moscow", label: "г.Москва", price: 900 },
    { value: "moscow", label: "г.Москва", price: 850 },
  ];

  const devices = [
    { value: "phones", label: "Смартфоны", price: 500 },
    { value: "notebooks", label: "Ноутбуки", price: 300 },
    { value: "tv", label: "ТВ", price: 600 },
    { value: "phones", label: "Смартфоны", price: 450 },
    { value: "notebooks", label: "Ноутбуки", price: 560 },
    { value: "tv", label: "ТВ", price: 520 },
    { value: "phones", label: "Смартфоны", price: 200 },
    { value: "notebooks", label: "Ноутбуки", price: 500 },
    { value: "tv", label: "ТВ", price: 550 },
  ];

  const handleSelectCity = (options) => {
    setCity(options.price);
  };

  const handleSelectDevice = (options) => {
    setDevice(options.price);
  };

  const handleOpenDeliveryForm = (e) => {
    e.preventDefault();
    setIsDeliveryFormApplication(!isDeliveryFormApplication);
  };

  return (
    <Section className="delivery-forms">
      <div className="delivery-forms__container">
        <h3 className="delivery-forms__title">
          Для определения стоимости доставки заполните поля
        </h3>
        <ul className="delivery-forms__selects">
          <li className="delivery-forms__select">
            <p className="delivery-forms__subtitle">город отправки</p>
            <Select
              components={{ IndicatorsContainer }}
              options={cities}
              onChange={handleSelectCity}
              placeholder="Укажите город отправки"
              styles={{
                container: (styles) => ({
                  ...styles,
                  width: "100%",
                  fontFamily: "Open Sans, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#028FE8",
                  boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
                  margin: "auto",
                }),
                valueContainer: (styles) => ({
                  ...styles,
                  paddingLeft: 6,
                }),
                control: (styles) => ({
                  ...styles,
                  borderRadius: 6,
                  borderColor: null,
                  border: "none",
                  boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
                  "&:hover": null,
                }),
                option: (styles, state) => ({
                  ...styles,
                  fontFamily: "Open Sans, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#028FE8;",
                  borderRadius: 6,
                  marginLeft: 6,
                  marginRight: 7,
                  width: 301,
                  height: 24,
                  backgroundColor: state.isSelected
                    ? "rgba(2, 143, 232, 0.08);"
                    : styles.backgroundColor,
                  display: "flex",
                  alignItems: "center",
                  "@media only screen and (max-width: 500px)": {
                    ...styles["@media only screen and (max-width: 500px)"],
                    width: 250,
                  },
                }),
                menuList: (styles) => ({
                  ...styles,
                  width: 330,
                  backgroundColor: "#fff",
                  borderRadius: 20,
                  maxHeight: 251,
                  overflow: "auto",
                  "@media only screen and (max-width: 500px)": {
                    ...styles["@media only screen and (max-width: 500px)"],
                    width: 275,
                  },
                }),
                menu: (styles) => ({
                  ...styles,
                  width: 330,
                  maxHeight: 251,
                  borderRadius: 6,
                  boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  "@media only screen and (max-width: 500px)": {
                    ...styles["@media only screen and (max-width: 500px)"],
                    width: 275,
                  },
                }),
              }}
            />
          </li>
          <li className="delivery-forms__select">
            <p className="delivery-forms__subtitle">Тип Обородувания</p>
            <Select
              components={{ IndicatorsContainer }}
              options={devices}
              onChange={handleSelectDevice}
              placeholder="Укажите город отправки"
              styles={{
                container: (styles) => ({
                  ...styles,
                  width: "100%",
                  fontFamily: "Open Sans, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "rgba(136, 137, 141, 0.75)",
                  boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
                  margin: "auto",
                }),
                valueContainer: (styles) => ({
                  ...styles,
                  paddingLeft: 6,
                }),
                control: (styles) => ({
                  ...styles,
                  borderRadius: 6,
                  borderColor: null,
                  border: "none",
                  boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
                  "&:hover": null,
                }),
                option: (styles, state) => ({
                  ...styles,
                  fontFamily: "Open Sans, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#028FE8;",
                  borderRadius: 6,
                  marginLeft: 6,
                  marginRight: 7,
                  width: 301,
                  height: 24,
                  backgroundColor: state.isSelected
                    ? "rgba(2, 143, 232, 0.08);"
                    : styles.backgroundColor,
                  display: "flex",
                  alignItems: "center",
                  "@media only screen and (max-width: 500px)": {
                    ...styles["@media only screen and (max-width: 500px)"],
                    width: 250,
                  },
                }),
                menuList: (styles) => ({
                  ...styles,
                  width: 330,
                  backgroundColor: "#fff",
                  borderRadius: 20,
                  maxHeight: 251,
                  overflow: "auto",
                  "@media only screen and (max-width: 500px)": {
                    ...styles["@media only screen and (max-width: 500px)"],
                    width: 275,
                  },
                }),
                menu: (styles) => ({
                  ...styles,
                  width: 330,
                  maxHeight: 251,
                  borderRadius: 6,
                  boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  "@media only screen and (max-width: 500px)": {
                    ...styles["@media only screen and (max-width: 500px)"],
                    width: 275,
                  },
                }),
              }}
            />
          </li>
        </ul>
        <p className="delivery-forms__price">
          {city && device
            ? `Стоимость доставки: ${city + device} рублей. Время доставки до 2
            дней.`
            : ""}
        </p>
        {ChangeFormButton()}
      </div>
      <CallBackFormPopup isOpen={isOpenPopup} setIsOpen={setIsOpenPopup} />
      {/* <Dialog
        open={isOpenDeliveryFormPopup}
        onClose={() => setIsOpenDeliveryFormPopup(false)}
      >
        <div
          className="delivery-form__form-modal-backdrop"
          aria-hidden="true"
        ></div>
        <div className="delivery-form__form-modal-container">
          <Dialog.Panel> 
            <div className="delivery-form__form-modal-content">*/}
      {/* <div className="delivery-form__form-modal-wrapper"></div> */}
      {/* <DeliveryFormApplication />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog> */}
    </Section>
  );
};

export default DeliveryForms;

// toDo:
// проверить верстку. почему столько закомменчено?
