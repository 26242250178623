import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone5c.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfMicrophone5c = [
  {
    id: 1,
    title: "Замена микрофона iPhone 5c в сервисном центре Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если проблема уже произошла, и вы не знаете, что делать – просто
          обращайтесь в сервисный центр Мак Профи. Мы работаем уже много лет,
          имеем богатый опыт и массу положительных отзывов, а специализируемся
          именно на работе с высокотехнологичным оборудованием. Наши мастера
          успешно справляются с задачами любой сложности, и работа с Айфонами,
          замена микрофона для них – это вовсе не проблема. На все работы и
          запчасти дается гарантия, компания работает официально, через
          заключение договоров – мы бережем свою репутацию и создаем наиболее
          прозрачные условия сотрудничества с клиентами. Мы придерживаемся
          политики разумных цен, и переплачивать за ремонт вам не придется.
        </p>
        <p className="repair-iphone-display__paragraph">
          Все работы осуществляются в профессиональных условиях, в сервисном
          центре, оснащенном по последнему слову техники. Каждое рабочее место
          оснащено должным образом для выполнения сложнейших задач вплоть до
          самой тонкой пайки. Подобные условия позволяют мастерам выполнять
          работу точно и качественно, действовать безошибочно. Также в наличии
          всегда имеются запчасти официального заводского производства, на них
          дается отдельная гарантия, и в их качестве сомневаться не приходится.
          Работами занимаются только опытные профессионалы, которые имеют
          профильное образование и знают о современных высокотехнологичных
          девайсах буквально все – это тоже становится важным слагаемым успеха.
          И потому в качестве выполнения работ и профессионализме вы можете не
          сомневаться.
        </p>
        <p className="repair-iphone-display__paragraph">
          Первым шагом при обращении с ремонтом становится диагностика – у нас
          она выполняется бесплатно, в считанные минуты. Мастер найдет причину
          проблем, и после этого можно будет узнать стоимость ремонта, задать
          любые вопросы. При вашем согласии штатный или срочный ремонт начнется
          немедленно, и уже вскоре вы сможете получить необходимый результат. С
          таким подходом техника прослужит вам еще много лет, и не создаст новых
          проблем!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels5c = () => {
  return (
    <Page title="Срочная замена микрофона iPhone 5c - стоимость замены микрофона Айфон 5c">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-mikrofona/5c"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстрая замена микрофона iPhone 5c возможна в сервисе Oh!MyGadget!. Мы работаем качественно и грамотно, выполняем все гарантии и обязательства."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена микрофона iPhone 5c"
          titleImg="Замена микрофона iPhone 5c"
          banner={banner}
          title="Замена микрофона iPhone 5c"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Поломка Айфона – это неприятная ситуация, однако такое все же
                происходит. Если вы пытаетесь позвонить абонентам, а они упорно
                не слышат вашего голоса – речь идет о поломке микрофона. То же
                самое можно сказать и про ситуации, когда звук перестает быть
                достаточно четким и громким, возникают посторонние шумы. В таких
                ситуациях необходимо еще убедиться, что динамик и отверстия под
                него чистые, провести чистку устройства – для этого тоже лучше
                обратиться в сервисный центр, так как своими силами разбирать,
                чистить и ремонтировать чувствительную современную технику не
                стоит. Обычно такой подход приводит только к еще более
                существенным проблемам, или вовсе к необходимости менять
                оборудование, тратя на это массу денег. Куда более целесообразно
                будет провести ремонт.
              </p>
              <p className="repair-iphone-display__paragraph">
                Поломки, из-за которых требуется замена микрофона iPhone 5c,
                могут возникать по целому ряду причин. Среди таковых необходимо
                отметить следующие:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Падения, удары, механические повреждения,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Самый банальный износ и выработка ресурса детали,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Попадание воды и окисление.
                  </p>
                </li>
              </ul>
            </>
          }
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="1400 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Какой бы ни была эта причина, технику удается восстановить и
                привести в порядок. И главным залогом успеха становится именно
                обеспечение профессионального подхода, без которого любой ремонт
                обречен на провал. А для того, чтобы исключить эти и другие
                проблемы в будущем, имеет смысл помнить о некоторых правилах
                обращения с тонкой современной техникой. Так, следует
                пользоваться чехлами, покупая надежные модели, удобные и
                обеспечивающие устройству защиту. Также стоит приучить себя
                пользоваться девайсом с осторожностью. Эти простые советы
                позволят надолго сохранить его в работоспособном состоянии. Но
                что же делать, если проблема уже наступила? Как быть с ремонтом?
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone5c.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels5c;
