import React from "react";
import Section from "../Section/Section";
import certificate from "../../images/omg-certificate.png";
import { saveAs } from "file-saver";

const AboutCompanyMacProfiMobile = () => {
  const requisites = [
    {
      title: "Полное наименование предприятия:",
      text: "Общество с ограниченной ответственностью «Мак-профи»",
    },
    {
      title: "Краткое наименование предприятия:",
      text: "ООО «Мак-профи»",
    },
    {
      title: "ИНН/КПП",
      text: "7710924828 / 771001001",
    },
    {
      title: "ОГРН",
      text: "1127747101877",
    },
    {
      title: "Юридический  адрес",
      text: "125363, Москва, Нелидовская 20к1, помещение 5П",
    },
    {
      title: "Фактический адрес",
      text: "125363, Москва, Нелидовская 20к1, помещение 5П",
    },
    {
      title: "Банк",
      text: "АО “АЛЬФА-БАНК”",
    },
    {
      title: "Р/счет",
      text: "40702810101600004371",
    },
    {
      title: "К/счет",
      text: "30101810200000000593",
    },
    {
      title: "БИК",
      text: "044525593",
    },
    {
      title: "ОКАТО",
      text: "45286585000",
    },
    {
      title: "ОКВЭД",
      text: "72.5",
    },
    {
      title: "ОКПО",
      text: "13278274",
    },
    {
      title: "ОКФС",
      text: "16",
    },
    {
      title: "ОКОПФ",
      text: "65",
    },
    {
      title: "ОКОГУ",
      text: "4210014",
    },
    {
      title: "ОКТМО",
      text: "45382000",
    },
    {
      title: "Телефон",
      text: "8(495)540-50-67",
    },
    {
      title: "Генеральный директор",
      text: "Малышков Олег Андреевич",
    },
  ];

  const downloadFile = () => {
    const fileContent = `Карточка ООО «Мак-профи»
      Полное наименование предприятия: Общество с ограниченной ответственностью «Мак-профи»
      Краткое наименование предприятия: ООО «Мак-профи»
      ИНН/КПП: 7710924828 / 771001001
      ОГРН: 1127747101877
      Юридический  адрес: 125363, Москва, Нелидовская 20к1, помещение 5П
      Фактический адрес: 125363, Москва, Нелидовская 20к1, помещение 5П
      Банк: АО “АЛЬФА-БАНК”
      Р/счет: 40702810101600004371
      К/счет: 30101810200000000593
      БИК: 044525593
      ОКАТО: 45286585000
      ОКВЭД: 72.5
      ОКПО: 13278274
      ОКФС: 16
      ОКОПФ: 65
      ОКОГУ: 4210014
      ОКТМО: 45382000
      Телефон: 8(495)540-50-67
      Генеральный директор: Малышков Олег Андреевич
      `;
    const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "Реквизиты ООО Мак-профи.docx");

    //http://localhost:3000/public/req.docx
  };

  return (
    <Section>
      <div className="about-company-mobile__contant">
        <h1 className="about-company-mobile__title">
          Контакты сервисного центра OhMyGadget
        </h1>
        <h4 className="about-company__subtitle">
          Добро пожаловать в Oh!MyGadget! — ваш надежный партнер в мире ремонта
          электроники с 2012 года.
        </h4>
        <div className="about-company-mobile__border-title"></div>
        <ul className="about-company-mobile__list">
          <li className="about-company-mobile__item">
            <p className="about-company-mobile__paragraph">
              Мы гордимся нашей командой высококвалифицированных профессионалов,
              которые занимаются ремонтом техники любой сложности. Благодаря
              нашему профессиональному оборудованию, мы способны проводить
              точную диагностику и эффективный ремонт, возвращая вашу технику к
              жизни с высочайшей степенью мастерства и точности.
            </p>
            <div className="about-company-mobile__border"></div>
          </li>
          <li className="about-company-mobile__item">
            <h2 className="about-company-mobile__title">
              Местоположение и доступность:
            </h2>
            <p className="about-company-mobile__paragraph">
              Наш центр удачно расположен в Москве, всего в двух минутах ходьбы
              от метро Сходненская, делая нас легкодоступными для всех наших
              клиентов.
            </p>
            <div className="about-company-mobile__border"></div>
          </li>
          <li className="about-company-mobile__item">
            <h2 className="about-company-mobile__title">
              Опыт и профессионализм:
            </h2>
            <p className="about-company-mobile__paragraph">
              За годы работы мы отремонтировали более 750,000 устройств, что
              является ярким свидетельством нашего опыта и профессионализма.
              Этот опыт позволяет нам гарантировать качественный ремонт и
              удовлетворение потребностей каждого клиента.
            </p>
          </li>
          <li className="about-company-mobile__item">
            <h2 className="about-company-mobile__title">
              Отличный сервис и гарантии:
            </h2>
            <p className="about-company-mobile__paragraph">
              Наши менеджеры отличаются вежливостью и готовностью помочь, а
              также стремятся предложить нашим клиентам оптимальные варианты
              ремонта. Мы ценим ваше время и стремимся к минимальным срокам
              ремонта. В Oh!MyGadget! мы предлагаем полностью бесплатную
              диагностику, даже если вы решите не продолжать ремонт с нами. На
              все платные услуги предоставляется гарантия, и мы несем
              ответственность за каждое устройство, которое ремонтируем.
            </p>
          </li>
          <li className="about-company-mobile__item">
            <h2 className="about-company-mobile__title">
              Надежность и устойчивость:
            </h2>
            <p className="about-company-mobile__paragraph">
              В отличие от небольших сервисов, мы обладаем более чем 11-летним
              опытом работы в этой области. Наша стабильность и надежность -
              гарантия того, что мы всегда будем здесь, чтобы помочь вам.
            </p>
          </li>
          <li className="about-company-mobile__item">
            <h2 className="about-company-mobile__title">
              Товарный знак и юридическая уверенность:
            </h2>
            <p className="about-company-mobile__paragraph">
              С 02 марта 2017 года, наше ООО "Мак-профи" является официальным
              владельцем зарегистрированного товарного знака Oh!MyGadget!, что
              подтверждает нашу приверженность качеству и профессионализму.
              Свидетельство на товарный знак:
            </p>
            <img
              src={certificate}
              alt="сертификат компании"
              className="about-company-mobile__certificate"
            />
          </li>
          <li className="about-company-mobile__item">
            <h2 className="about-company-mobile__title">Наши Реквизиты:</h2>
          </li>
          <ul className="about-company-mobile__requisites-list">
            {requisites.map((req, i) => {
              return (
                <li key={i} className="about-company-mobile__requisites-item">
                  <p className="about-company-mobile__requisites-title">
                    {req.title}
                  </p>
                  <p className="about-company-mobile__requisites-text">
                    {req.text}
                  </p>
                  <div className="about-company-mobile__requisites-border"></div>
                </li>
              );
            })}
          </ul>
        </ul>
        <button
          onClick={downloadFile}
          className="about-company-mobile__button-req"
        >
          Скачать файл с реквизитами
        </button>
      </div>
    </Section>
  );
};

export default AboutCompanyMacProfiMobile;
