import React from "react";
import { Section } from "../index";
import { Carousel } from "react-responsive-carousel";
import { counter } from "../../images/index";
import {
  alfaActive,
  fiatActive,
  mailruActive,
  majorActive,
  mercedesActive,
  rossiaActive,
  standartActive,
  vtbActive,
} from "../../images/index";

const PartnersMobile = () => {
  return (
    <Section className="partners-mobile" id="clients">
      <div className="partners-mobile__container">
        <h2 className="partners-mobile__title">Нам можно доверять!</h2>
        <img style={{ maxWidth: 274 }} alt="счетчик клиентов" src={counter} />
        <h3 className="partners-mobile__subtitle">
          Клиентов нашей компании не могут ошибаться!
        </h3>
        <Carousel
          autoPlay={false}
          infiniteLoop={false}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          centerMode
          centerSlidePercentage={("centerSlidePercentage", 50)}
        >
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={alfaActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={fiatActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={mailruActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={majorActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={mercedesActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={rossiaActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={standartActive}
            />
          </div>
          <div className="partners-mobile__wrapper">
            <img
              className="partners-mobile__logo"
              alt="главный баннер"
              src={vtbActive}
            />
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default PartnersMobile;
