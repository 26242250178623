import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageWork.module.css";
import NoteExpertGeneralPageWorkMobile from "./NoteExpertGeneralPageWorkMobile";
import { useMedia } from "../../../utils/useMedia";

const stages = [
  {
    number: "1",
    text: "Вы или наш курьер привозите ноутбук к нам в сервис по адресу рядом с м. Белорусская",
    paragraph:
      "Бесплатный выезд осуществляется по Москве в пределах МКАД с 10:00 до 20:00",
  },
  {
    number: "2",
    text: "Наши эксперты быстро и профессионально проводят бесплатную диагностику",
    paragraph:
      "Программная диагностика от 10 минут, аппаратная от 30 минут. Среднее время диагностики составляет 1 час",
  },
  {
    number: "3",
    text: "После выявления неисправности наши менеджеры согласовывают с вами все условия ремонта",
    paragraph:
      "Время ремонта от 30 минут до нескольких дней. Среднее время ремонта 4 часа. Средняя стоимость ремонта 3750 рублей",
  },
  {
    number: "4",
    text: "Мастера ремонтируют несправные комплектующие, при необходимости меняют на новые",
    paragraph:
      "У нас в наличии около 10 000 запчастей на самые распространенные модели ноутбуков и ежедневные поставки от проверенных дистрибьютеров",
  },
  {
    number: "5",
    text: "По окончании ремонтных работ вы забираете работающий ноутбук и получаете гарантию",
    paragraph:
      "Гарантия на паяльные работы от 3 месяцев, на замену элементов от 6 месяцев, на комплектующие до 2 лет",
  },
];

function ChangePage() {
  const isBreakpoint = useMedia("(min-width: 601px)");

  if (isBreakpoint) {
    return (
      <Section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.title}>КАК МЫ РАБОТАЕМ</h2>
          <ul className={styles.list}>
            {stages.map((stage, i) => {
              return (
                <li key={i} className={styles.item}>
                  <div className={styles.circle}>
                    <p className={styles.number}>{stage.number}</p>
                  </div>
                  <div className={styles.wrapper}>
                    <b className={styles.text}>{stage.text}</b>
                    <p className={styles.paragraph}>{stage.paragraph}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Section>
    );
  } else {
    return <NoteExpertGeneralPageWorkMobile stages={stages} />;
  }
}

function NoteExpertGeneralPageWork() {
  return ChangePage();
}

export default NoteExpertGeneralPageWork;
