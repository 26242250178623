import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/repair-ipadPro9.jpeg";
import { Helmet } from "react-helmet";

const priceIpadPro12 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "12500 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "12500 P",
  },
  {
    id: 3,
    option: "Замена аккумулятора",
    price: "6900 P",
  },
  {
    id: 4,
    option: "Ремонт Wi-Fi модуля",
    price: "6450 P",
  },
  {
    id: 5,
    option: "Замена разъема наушников",
    price: "3500 P",
  },
  {
    id: 6,
    option: "Замена разъема зарядки",
    price: "3500 P",
  },
  {
    id: 7,
    option: "Замена кнопки Home (домой)",
    price: "2850 P",
  },
  {
    id: 8,
    option: "Замена кнопки включения",
    price: "3850 P",
  },
  {
    id: 9,
    option: "Замена основной камеры",
    price: "4450 P",
  },
  {
    id: 10,
    option: "Замена микрофона",
    price: "2900 P",
  },
];

const textForBanner = [
  {
    id: 1,
    text: "На все наши услуги и детали, подвергшиеся замене, предоставляется гарантия до 1 года,",
    number: "01",
  },
  {
    id: 2,
    text: "Удобное расположение сервисных центров в Москве, плюс - услуги курьера, если доехать до сервисного центра самостоятельно невозможно (или, даже, просто не хочется) по тем или иным причинам,",
    number: "02",
  },
  {
    id: 3,
    text: "Ремонт производится исключительно профессиональными и опытными специалистами, и только на новом, высокоточном оборудовании,",
    number: "03",
  },
  {
    id: 4,
    text: "Полная укомплектованность наших складов всеми необходимыми деталями.",
    number: "04",
  },
];

const textOfIpadPro12 = [
  {
    id: 1,
    title: 'Последовательность ремонта iPad Pro 12.9" в Мак Профи',
    text: 'Сначала абсолютно и гарантировано бесплатная диагностика, по результату которой мы выявим все слабые места и возможные поломки вашего планшета. Далее мы известим вас о результатах диагностики и вместе с вами обсудим какие варианты ремонта будут произведены. Именно на данном этапе озвучивается, опять же таки, гарантировано конечная сумма ремонта. То есть, у нас вам не грозят никакие предоплаты и незапланированные растраты. Сам ремонт занимает, как правило, не более двадцати минут, по истечению которых вы получаете исправный, "как новый" iPad Pro 12.9" на руки и сможете проверить и убедиться в качества нашего ремонта.',
  },
  {
    id: 2,
    title: 'Специфика ремонта iPad Pro 12.9"',
    text: 'Помимо ожидаемых для любого планшета динамиков и опять же таки ожидаемого для любого айпэда разъема Lightning, торец Айпэд Про 12.9" прирос новым разъёмом Smart Connector, который, с одной стороны, позволяет подключить как фирменную клавиатуру, так и другие устройства, или же банально зарядить айпэд. С другой стороны, замена подобного проприетарного разъёма дело не простое и "влетит в копейку". На дисплей не жалели специального клея, что вызывает необходимость использовать специальную грелку при разборке. К положительным моментам надо отнести простоту съёма аккумулятора, который не соединён с "материнской" и оснащён специальными ярлыками, для упрощения жизни ремонтников. Совмещённость в единое целое дисплея и фронтальной панели имеют свои плюсы - в частности, проще стало добраться до электронной начинки планшета. Но есть у такого решения и не менее очевидные недостатки: теперь замена возможна только в отношении всего дисплейного модуля целиком, что скажется на стоимости на ремонта.',
  },
];

const RepairIpadModelPagesIpadPro12 = () => {
  return (
    <Page
      title={"Ремонт iPad Pro 12.9, цены на ремонт Айпад Про 12.9 в Москве!"}
    >
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/pro-12"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Осуществляем качественный и профессиональный ремонт iPad Pro 12.9 в Москве и по всей РФ. Все цены на ремонт iPad Pro 12.9."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title='Ремонт iPad Pro 12.9"'
          alt="Ремонт iPad Pro 12.9"
          titleImg="Ремонт iPad Pro 12.9"
          text={
            <>
              <RepairAppleTextWithCheckMark
                paragraphUp='Производим ремонт iPad Pro 12.9" в Москве. Быстро. Качественно. Надёжно. Да ещё и по весьма умеренным ценам. Если сказанное не убеждает вас обратиться за ремонтом Айпэд Про 12.9" именно к нам, вот ещё пару отличных причин:'
                array={textForBanner}
              />
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpadPro12}
            title='Стоимость ремонта Айпад Про 12.9"'
            text="* Все цены указаны в рублях РФ. Стоимость включает работу и комплектующее."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpadPro12.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpadPro12;
