import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileAdvantagesMobile.module.css";
import AdvantagesMobile from "./AdvantagesMobile";
import Carousel from "react-elastic-carousel";

function CenterMobileIRepairMobileAdvantagesMobile({
  titleMobile,
  contentForAdvantages,
}) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>{titleMobile}</h2>
        <Carousel>
          {contentForAdvantages.map((item, i) => {
            return (
              <div key={i} className={styles.wrapper}>
                <AdvantagesMobile
                  img={item.img}
                  title={item.title}
                  text={item.text}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobileAdvantagesMobile;
