import React from "react";
import { Section } from "../index";

const RepairIwatchTitle = () => {
  return (
    <Section>
      <div className="repair-watch__title-container">
        <article>
          <h1 className="repair-watch__title-title">Ремонт Apple Watch</h1>
          <div className="repair-watch__title-border"></div>
          <p className="repair-watch__title-paragraph">
            Специальное предложение для владельцев Apple Watch: качественный
            ремонт по низким ценам. Работаем быстро, используем качественные
            детали. Выполним работу точно в срок.
          </p>
          <p className="repair-watch__title-paragraph">
            Apple Watch – многофункциональное устройство, которое удалось
            разместить в миниатюрном комплексе. Эта особенность значительно
            осложнила процесс восстановления, ведь ремонт требует максимальной
            точности и строго соблюдения последовательности действий. Но
            обращаясь в Мак Профи, вы можете быть уверенны – все работы будут
            выполнены должным образом, а на замену неисправных деталей нам
            потребуется меньше часа.
          </p>
        </article>
        <div className="repair-watch__title-icon"></div>
      </div>
    </Section>
  );
};

export default RepairIwatchTitle;
