import React from "react";

const RepairAppleLinksText = (props) => {
  return (
    <div className='repair-apple-links-text__container'>
      <div className='repair-mb__group-links-item'>
        <h2 className='repair-mb__group-links-subtitle'>{props.title}</h2>
        <article className='repair-mb__group-links-paragraph'>{props.text}</article>
      </div>
    </div>
  )
};

export default RepairAppleLinksText;