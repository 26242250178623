import { myFetch } from "./myFetch";

export const register = (data) => {
  return myFetch(`users`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const authorize = (login, password) => {
  return myFetch(`admin-login`, {
    method: "POST",
    body: JSON.stringify({ login, password }),
  });
};

export const logout = () => {
  return myFetch(`logout`, { method: "POST" });
};
