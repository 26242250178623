import style from "./contacts.module.css";
import { YMaps, Map, Placemark } from "react-yandex-maps";

const pg = [55.846674, 37.436104];

export default function Contacts() {
  return (
    <section className={style.section} id="contacts">
      <h2 className={style.title}>ЖДЕМ ВАС В НАШИХ ОФИСАХ</h2>
      <div className={style.border}></div>
      <YMaps query={{ apikey: "fdcc573a-d5df-4fb4-80a8-4c9c4ce050c2" }}>
        <div style={{ width: "100%", height: "500px", position: "relative" }}>
          <ul className={style.contacts}>
            <li className={style.item}>
              <p className={style.subtitle_bold}>Москва</p>
              <div>
                <a className={style.phone_link} href="tel:+74953745481">
                  +7(495)374-54-81
                </a>
              </div>
              <div>
                <a className={style.phone_link} href="tel:+74955405067">
                  +7(495)540-50-67
                </a>
              </div>
            </li>
            <li className={style.item}>
              <p className={style.subtitle}>м. Сходненская</p>
              <p className={style.text}>
                125363, Москва, Нелидовская 20к1, помещение 5П
              </p>
            </li>
            <li className={style.item}>
              <p className={style.subtitle}>E-mail:</p>
              <a
                className={style.mail_link}
                href="mailto:client-service@ohmygadget.su"
              >
                client-service@ohmygadget.su
              </a>
            </li>
            <li className={style.item}>
              <p className={style.subtitle}>График работы сервесного центра:</p>
              <p className={style.text}>Пн - Пт: с 10 до 20 часов</p>
              <p className={style.text}>Сб - Вс: Выходной</p>
            </li>
          </ul>
          <Map
            defaultState={{ center: [55.846684, 37.446057], zoom: 15 }}
            width="100%"
            height="500px"
          >
            <Placemark geometry={pg} />
          </Map>
        </div>
      </YMaps>
    </section>
  );
}
