import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/displaySE.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfDisplaySE = [
  {
    id: 1,
    title: "Айфон SE: замена дисплея",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Любая работа требует соответствующих знаний и навыков, поэтому у нас
          работают только квалифицированные мастера, обладающие достаточным
          опытом. Замена дисплея iPhone SE – довольно сложная задача, требующая
          соблюдения следующей последовательности действий:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Открутить винты, расположенные в нижней части смартфона, поднять
              экран и отключить шлейфы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выкрутить винты по всему периметру устройства, извлечь динамик и
              фронтальную камеру
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Удалить защитную пластину, выдавить кнопку Home
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Извлечь фронтальную камеру, снять инфракрасный датчик.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Действовать нужно очень аккуратно, внимательно соблюдая заданную
          схему. После этого подсоединить новый экран и собрать устройство.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Стоит ли выполнять замену дисплея iPhone SE самостоятельно?",
    text: "Чтобы заменить дисплей, вам нужно приобрести хотя бы базовый комплект инструментов – ни в коем случае не нужно использовать для ремонта подручные материалы, такие как отвертки, пинцеты или же маникюрные ножницы. Это может повредить хрупкие элементы устройства. Помимо этого нужно приобрести необходимые запчасти – среди широчайшего ассортимента вариантов, не так уж просто найти действительно качественные оригинальные комплектующие. К тому же стать жертвой мошенников очень легко, напрасно отдав крупную сумму.",
  },
  {
    id: 3,
    title: "Замена дисплея Айфон SE на выгодных условиях",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Наш центр сотрудничает только с проверенными производителями, которые
          готовы предложить гарантию качества на реализуемые товары. Благодаря
          использованию профессионального комплекта инструментов необходимые
          детали будут заменены максимально быстро – большинство поломок
          устраняются в присутствии заказчика.
        </p>
        <p className="repair-iphone-display__paragraph">
          Кроме того, мы готовы предложить своим клиентам и другие преимущества:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Лояльная ценовая политика
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Индивидуальный подход, учитываем все заявленные требования
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Помощь не только в восстановлении, но и настройке устройства.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Замена дисплея Айфон SE – работа, которая требует определенных
          навыков. Доверяйте опытным мастерам, чтобы не испортить дорогостоящее
          устройство – мы готовы предложить своим клиентам широчайший спектр
          услуг, при этом цены неизменно доступны и станут приятным бонусом для
          покупателей.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModelsSE = () => {
  return (
    <Page title="Замена дисплея (экрана) iPhone SE, заменить дисплей на Айфон SE за 20 минут!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-displeja/se"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Срочно меняем дисплеи на iPhone SE за 20 минут с гарантией на работу и дисплейный модуль в Москве!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена дисплея iPhone SE"
          titleImg="Замена дисплея iPhone SE"
          banner={banner}
          title="Замена дисплея iPhone SE"
          text="В нашем сервисном центре владельцы iPhone получают полный комплекс услуг – успешно справляемся с любыми типами поломок, при этом диагностика устройства абсолютно бесплатна. Своевременное обращение к мастерам поможет восстановить поврежденный дисплей Айфон SE без дополнительных затрат – мы предложим вам лучшие условия."
        />
        <RepairApplePrice
          subtitle="Стоимость замены дисплея iPhone SE"
          text="Замена дисплея"
          price="	2850 P"
          paragraph="Цены указаны в рублях РФ."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplaySE.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModelsSE;
