import React from "react";
import Section from "../Section/Section";

const RepairAppleDoublePrice = (props) => {
  return (
    <Section>
      <div className="repair-apple-dprice__container">
        {props.title ? (
          <>
            <h2 className="repair-apple-dprice__title">{props.title}</h2>
            <ul className="repair-apple-dprice__checklist">
              <li className="repair-apple-dprice__item">
                <h4 className="repair-mb__price-span">{props.textUp}</h4>
                <p className="repair-mb__price-price">{props.priceUp}</p>
              </li>
              <li className="repair-apple-dprice__item">
                <h4 className="repair-mb__price-span">{props.textDown}</h4>
                <p className="repair-mb__price-price">{props.priceDown}</p>
              </li>
            </ul>
            <article className="repair-apple-dprice__paragraph">
              {props.paragraph}
            </article>
          </>
        ) : (
          <ul className="repair-apple-dprice__list">
            <li className="repair-apple-dprice__item">
              <h4 className="repair-mb__price-span">{props.textUp}</h4>
              <p className="repair-mb__price-price">{props.priceUp}</p>
            </li>
            <li className="repair-apple-dprice__item">
              <h4 className="repair-mb__price-span">{props.textDown}</h4>
              <p className="repair-mb__price-price">{props.priceDown}</p>
            </li>
          </ul>
        )}
      </div>
    </Section>
  );
};

export default RepairAppleDoublePrice;
