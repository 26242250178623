import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/repair-ipadBatteryMini.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Вздутие батареи",
    number: "01",
  },
  {
    id: 2,
    text: "Перегрев устройства даже при непродолжительной работе",
    number: "02",
  },
  {
    id: 3,
    text: "Быстрая разрядка аккумулятора",
    number: "03",
  },
  {
    id: 4,
    text: "«Прыгающий» заряд.",
    number: "04",
  },
];

const listOfRecommendations = [
  {
    id: 1,
    text: "Важно соблюдать рекомендации производителя, не допуская полной разрядки батареи и не ставя устройство на зарядку, прежде чем оно полностью разрядится.",
    number: "01",
  },
  {
    id: 2,
    text: "Не стоит эксплуатировать планшет на холоде. Это не только сокращает емкость заряда, но и может стать причиной короткого замыкания, возникшего из-за собравшегося конденсата.",
    number: "02",
  },
  {
    id: 3,
    text: "Необходимо отключать неиспользуемые приложения, которые активно расходуют батарею.",
    number: "03",
  },
  {
    id: 4,
    text: "Нужно беречь устройство от ударов, а также держать его вдали от влаги.",
    number: "04",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "4 признака износа аккумулятора iPad Mini",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Период использования источника питания зависит от того, насколько активно эксплуатируется планшет. Если владелец ежедневно работает на нем, то уже в течение полутора лет устройство достигнет предельного числа циклов заряда. Это можно легко заметить по следующим проявлениям:"
          array={listOfTrouble}
          paragraphDown="Своими силами планшет лучше не разбирать, иначе можно лишь повредить хрупкие элементы. В сервисном центре будет проведена комплексная диагностика, которая поможет установить причины нарушения. В некоторых случаях проблемы с зарядом возникают из-за падения устройства, длительной эксплуатации его на холоде или же проникновения жидкости под корпус. И лишь после проведения тестирования, наши мастера приступят к работе, предварительно согласовав детали будущего ремонта с заказчиком."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Замена аккумулятора iPad Mini: полезные рекомендации",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Наши специалисты легко устранят проблему – на это потребуется меньше часа, включая диагностику. Расценки на все услуги представлены на сайте, и вы можете ознакомиться с ними перед посещением сервисного центра. А чтобы в дальнейшем не возникала подобная проблема, после плановой замены аккумулятора iPad Mini стоит соблюдать несколько простых правил:"
          array={listOfRecommendations}
          paragraphDown="Воспользуйтесь услугой замены аккумулятора iPad Mini в сервисном центре Мак Профи, и ваш планшет вновь порадует длительной автономной работой. Именно у нас вы сможете получить качественный ремонт по приятной стоимости."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelIpadMini = () => {
  return (
    <Page title="Качественная замена аккумулятора (батареи) iPad Mini (Айпад Мини) в сервисе Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-akkumuljatora/mini"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена аккумулятора iPad Mini будет выполнена легко и быстро, если вы обратитесь в сервисный центр Мак Профи. Мы обеспечим вам качество и идеальный результат."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad Mini"
          alt="замена аккумулятора ipad mini"
          titleImg="замена аккумулятора ipad mini"
          text="Срок службы аккумулятора iPad Mini составляет около двух лет – по истечении этого периода времени нужно позаботиться о его замене. Сделать это самостоятельно не представляется возможным, ведь чтобы разобрать сложную конструкцию планшета потребуется соответствующее оборудование. Вы можете воспользоваться этой услугой в сервисном центре Мак Профи – квалифицированные специалисты успешно справятся с этой задачей."
        />
        <RepairApplePrice text="Замена аккумулятора" price="2950 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelIpadMini;
