import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLink,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIpodModelLinks,
} from "../index";
import banner from "../../images/banners/ipodNano7.jpeg";
import { iPodListOfModels } from "../../utils/ipodModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Диагностика – проводится абсолютно бесплатно;",
    number: "01",
  },
  {
    id: 2,
    text: "Согласование деталей – мастер озвучит стоимость и сроки ремонта;",
    number: "02",
  },
  {
    id: 3,
    text: "Выполнение поставленной задачи – если ремонт iPod Nano 7G заключается в замене отработанных деталей новыми запчастями, то он занимает около получаса;",
    number: "03",
  },
  {
    id: 4,
    text: "Оплата – заказчик рассчитывается за услуги, только оценив результаты ремонта. А мы в свою очередь предоставляем чек и гарантийный талон.",
    number: "04",
  },
];

const textIpodNano7 = [
  {
    id: 1,
    title: "Ремонт iPod Nano 7G в сервисном центре Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Мы позаботились о том, чтобы клиенту было предложено качественное обслуживание, а ремонт техники выполнялся максимально быстро. Стандартный процесс обращения в сервис включает в себя несколько основных этапов:"
          array={listOfTrouble}
          paragraphDown="Наша ценовая политика достаточно прозрачна – прайс доступен на сайте, благодаря чему не составит просчитать ориентировочную стоимость даже сложного ремонта, включающего целый ряд услуг. Но окончательную сумму мы назовем только после тщательной проверки устройства, что позволит учесть все нюансы предстоящего ремонта. Сроки также могут варьироваться – в большинстве случаев нашим мастерам удается справиться за 30-60 минут, но могут возникнуть определенные сложности и плеер останется в ремонте на сутки."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Гарантия на ремонт iPod Nano 7G",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Одно из важных преимуществ, которые мы готовы предложить клиентам –
          гарантия. Это значит, что если после восстановления гаджета в его
          работе вновь возникнет проблема, вызванная неправильными действиями
          мастера, то мы устраним её абсолютно бесплатно. То есть если вы
          обратились с разбитым дисплеем, а спустя короткое время после
          восстановления повредили экран вновь – эта поломка произошла по вине
          владельца. Если мы установили вам новую камеру или поменяли кнопки, то
          можете не сомневаться – в ближайшее время эта проблема уж точно не
          возникнет повторно. И что самое важное, гарантия предоставляется не
          только на работу, но и используемые запчасти.
        </p>
        <p className="repair-iphone-display__paragraph">
          Обращайтесь в наш сервисный центр, если вас интересует ремонт iPod
          Nano 7G – и мы уверенны, что вы обязательно останетесь довольны
          обслуживанием, а также качеством и скоростью выполнения поставленной
          задачи.
        </p>
      </>
    ),
  },
];

const RepairIpodModelLinkNano7 = () => {
  return (
    <Page title="Срочный ремонт iPod Nano 7G в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipod/nano-7g"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт iPod Nano 7G: быстрая замена комплектующих и восстановление устройства"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPod Nano 7G"
          titleImg="Ремонт iPod Nano 7G"
          banner={banner}
          title="Ремонт iPod Nano 7G"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Срочный ремонт iPod Nano 7G в Москве: широчайший комплекс услуг
                в сервисе Мак Профи. Удобное расположение, выгодные цены,
                специальные предложения.
              </p>
              <p className="repair-iphone-display__paragraph">
                Серию iPod Nano 7G выбирают для себя те, кто ценит комфорт и
                предпочитает качественную и многофункциональную технику. И это
                приобретение действительно окупает все финансовые затраты, ведь
                устройство рассчитано на продолжительный период эксплуатации и
                радует владельца надежностью и стабильной работой. Если же в
                системе возникнет поломка, мешающая комфортному использованию
                гаджета, то наши мастера быстро заменят неисправные детали и
                вернут владельцу восстановленное устройство.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIpodNano7.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpodModelLinks />
      </main>
    </Page>
  );
};

export default RepairIpodModelLinkNano7;
