import React, { useState } from "react";
import arrowLeft from "../../../images/arrows/note-expert-arrow-left.svg";
import arrowRight from "../../../images/arrows/note-expert-arrow-right.svg";
import styles from "./NoteExpertGeneralPageSlider.module.css";

function NoteExpertGeneralPageSlider({ children, videos }) {
  const [offset, setOffset] = useState(0);

  const handleLeftArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + 526;

      return Math.min(newOffset, 0);
    });
  };

  const handleRightArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - 526;

      const maxOffset = -(526 * (children.length - 1));

      return Math.max(newOffset, maxOffset);
    });
  };

  return (
    <div className={styles.wrapper}>
      <ul
        style={{
          transform: `translateX(${offset}px)`,
        }}
        className={styles.list}
      >
        {children}
      </ul>
      <div className={styles.buttonWrapper}>
        {offset === 0 ? (
          <img
            onClick={handleLeftArrowClick}
            src={arrowRight}
            style={{ transform: "scale(-1, -1)", opacity: 0.5 }}
            alt="стрелка влево"
          />
        ) : (
          <img
            onClick={handleLeftArrowClick}
            src={arrowRight}
            style={{ transform: "scale(-1, -1)" }}
            alt="стрелка влево"
          />
        )}
        {-(526 * videos.length - 526) === offset ? (
          <img
            onClick={handleRightArrowClick}
            src={arrowRight}
            style={{ opacity: 0.5 }}
            alt="стрелка вправо"
          />
        ) : (
          <img
            onClick={handleRightArrowClick}
            src={arrowRight}
            alt="стрелка вправо"
          />
        )}
      </div>
    </div>
  );
}

export default NoteExpertGeneralPageSlider;
