import React from 'react';
import cn from 'classnames/bind';

const BlockTitle = ({ title, className = '' }) => {
  return (
    <h3 className={cn('subtitle', className)}>{title}</h3>
  )
};

export default BlockTitle;
