import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import styles from "./CallBackFormPopup.module.css";
// import ok from "../../../images/icons/popup-ok.svg";
import ok from "../../images/icons/popup-ok.svg";
import popupCloseIcon from "../../images/icons/popup-close.svg";
import InputMask from "react-input-mask";
import titleIcon from "../../images/icons/callback-titile-icon.svg";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

function CallBackFormPopup({ isOpen, setIsOpen }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [typeOfGadget, setTypeOfGadget] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [nameError, setNameError] = useState("Поле не может быть пустым");
  const [phoneError, setPhoneError] = useState("Поле не может быть пустым");
  const [formValid, setFormValid] = useState(false);

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);

  const recaptchaKey = "6LdyKB4pAAAAAH7PM32pkohmTDPzxVoX219YBFqN";

  function onCaptchaChange() {
    setIsCaptchaDone(true);
  }

  const blurHandler = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
    }
  };

  const nameHandler = (e) => {
    let newState = e.target.value;
    setName(newState);
    if (newState.length < 2) {
      setNameError("Здесь должно быть минимум 2 символа");
      if (!e.target.value) {
        setNameError("Поле не может быть пустым");
      }
    } else {
      setNameError("");
    }
  };

  const phoneHandler = (e) => {
    let newState = e.target.value;
    setPhone(newState);
    if (newState.includes("_")) {
      setPhoneError("Введите корректный номер телефона");
      if (newState == "+_ (___) ___-__-__") {
        setPhoneError("Поле не может быть пустым");
      }
    } else if (!e.target.value) {
      setPhoneError("Поле не может быть пустым");
    } else {
      setPhoneError("");
    }
  };

  const typeHandler = (e) => {
    let newState = e.target.value;
    setTypeOfGadget(newState);
  };

  useEffect(() => {
    if (nameError || phoneError || !isCaptchaDone) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [nameError, phoneError, isCaptchaDone]);

  function sendEmail(e) {
    e.preventDefault();
    setIsSubmiting(true);
    emailjs
      .sendForm(
        "service_gdq4iwq",
        "template_0zr059h",
        e.target,
        "EZfZDetK08h7QxQFY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitSuccessful(true);
          setIsSubmiting(false);
          setTimeout(() => {
            setIsSubmitSuccessful(false);
            setIsOpen(false);
          }, 3000);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const buttonClassName = formValid ? styles.buttonActive : styles.button;

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div className={styles.backdrop} aria-hidden="true"></div>
      <div className={styles.container}>
        {isSubmitSuccessful ? (
          <div className={styles.contentSuccess}>
            <Dialog.Panel className={styles.success}>
              <img
                src={popupCloseIcon}
                alt="иконка закрытия окна"
                className="center-mobile-general-page__popup-success-close"
                onClick={() => setIsOpen(false)}
              />
              <img
                src={ok}
                className="center-mobile-general-page__popup-success-finger"
                alt="иконка успешной отправки"
              />
              <Dialog.Title className="center-mobile-general-page__popup-success-title">
                Мы вам перезвоним в течении 30 минут!
              </Dialog.Title>
              <p className="center-mobile-general-page__popup-success-subtitle">
                звонки осуществляются только в рабочее время
              </p>
            </Dialog.Panel>
          </div>
        ) : (
          <Dialog.Panel className={styles.contentMobileForm}>
            <form onSubmit={sendEmail} className={styles.content}>
              <input
                name="subject"
                value="заявка на обратный звонок"
                type="hidden"
              />
              <div className={styles.wrapper}>
                <Dialog.Title className={styles.title}>
                  <img
                    src={titleIcon}
                    alt="иконка заголовка"
                    style={{ marginRight: 10 }}
                  />
                  ПОЛУЧИТЕ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
                </Dialog.Title>
                <div className={styles.form}>
                  <div className={styles.inputWrapper}>
                    <label className={styles.label} htmlFor="username">
                      ВАШЕ ИМЯ
                    </label>
                    <input
                      onBlur={(e) => blurHandler(e)}
                      className={styles.input}
                      name="name"
                      value={name}
                      id="username"
                      type="text"
                      placeholder="Иван"
                      onChange={(e) => nameHandler(e)}
                    />
                    {nameDirty && nameError ? (
                      <em className={styles.errorText}>{nameError}</em>
                    ) : (
                      <div className={styles.hiddenErrorText}></div>
                    )}
                  </div>
                  <div className={styles.inputWrapper}>
                    <label className={styles.label} htmlFor="phone">
                      КОНТАКТНЫЙ ТЕЛЕФОН
                    </label>
                    <InputMask
                      onBlur={(e) => blurHandler(e)}
                      onChange={(e) => phoneHandler(e)}
                      name="phone"
                      value={phone}
                      autoComplete="none"
                      mask="+7 (999) 999-99-99"
                      className={styles.input}
                      id="phone"
                      type="tel"
                    />
                    {phoneDirty && phoneError ? (
                      <em className={styles.errorText}>{phoneError}</em>
                    ) : (
                      <div className={styles.hiddenErrorText}></div>
                    )}
                  </div>
                  <div className={styles.inputWrapper}>
                    <label className={styles.label} htmlFor="gadget">
                      ВАШЕ УСТРОЙСТВО
                    </label>
                    <input
                      name="gadget"
                      value={typeOfGadget}
                      className={styles.input}
                      id="gadget"
                      type="text"
                      placeholder="Iphone 11 pro"
                      onChange={(e) => typeHandler(e)}
                    />
                  </div>
                  <button
                    disabled={!formValid}
                    className={buttonClassName}
                    type="submit"
                  >
                    {isSubmiting ? "Отправка..." : "МЫ ВАМ ПЕРЕЗВОНИМ!"}
                  </button>
                </div>
              </div>
              <ReCAPTCHA sitekey={recaptchaKey} onChange={onCaptchaChange} />
            </form>
          </Dialog.Panel>
        )}
      </div>
    </Dialog>
  );
}

export default CallBackFormPopup;
