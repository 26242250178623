import React from "react";
import Section from "../../Section/Section";
import iconMobile from "../../../images/icons/center-mobile-links-mobile.svg";
import iconPad from "../../../images/icons/center-mobile-links-pad.svg";
import { Link } from "react-router-dom";

const linksMobile = [
  {
    link1: "SAMSUNG",
    link2: "FLY",
    to1: "remont-smartfonov-samsung",
    to2: "remont-smartfonov-fly",
  },
  {
    link1: "LENOVO",
    link2: "LG",
    to1: "remont-smartfonov-lenovo",
    to2: "remont-smartfonov-lg",
  },
  {
    link1: "NOKIA",
    link2: "HTC",
    to1: "remont-smartfonov-nokia",
    to2: "remont-smartfonov-htc",
  },
  {
    link1: "PHILIPS",
    link2: "SONY",
    to1: "remont-smartfonov-philips",
    to2: "remont-smartfonov-sony",
  },
];

const linksPad = [
  {
    link1: "ASUS",
    link2: "EXPLAY",
    to1: "remont-planshetov-asus",
    to2: "remont-planshetov-explay",
  },
  {
    link1: "SAMSUNG",
    link2: "SONY",
    to1: "remont-planshetov-samsung",
    to2: "remont-planshetov-sony",
  },
  {
    link1: "PRESTIGIO",
    link2: "ACER",
    to1: "remont-planshetov-prestigio",
    to2: "remont-planshetov-acer",
  },
  {
    link1: "LENOVO",
    link2: "DIGMA",
    to1: "remont-planshetov-lenovo",
    to2: "remont-planshetov-digma",
  },
];

const CenterMobileGeneralPageLinks = () => {
  return (
    <Section>
      <div className="center-mobile__general-page-link">
        <ul className="center-mobile__general-page-link-columns">
          <li className="center-mobile__general-page-link-item">
            <h2 className="center-mobile__general-page-link-title">
              Ремонт смартфонов
            </h2>
            <div className="center-mobile__general-page-link-wrapper">
              <div className="center-mobile__general-page-link-img">
                <img src={iconMobile} alt="" />
              </div>
              <ul className="center-mobile__general-page-link-list">
                {linksMobile.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="center-mobile__general-page-link-links"
                    >
                      <Link
                        className="center-mobile__general-page-link-link first-link"
                        to={link.to1}
                      >
                        {link.link1}
                      </Link>
                      <Link
                        className="center-mobile__general-page-link-link"
                        to={link.to2}
                      >
                        {link.link2}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
          <li className="center-mobile__general-page-link-item">
            <h2 className="center-mobile__general-page-link-title">
              Ремонт планшетов
            </h2>
            <div className="center-mobile__general-page-link-wrapper">
              <div className="center-mobile__general-page-link-img">
                <img src={iconPad} alt="" />
              </div>
              <ul className="center-mobile__general-page-link-list">
                {linksPad.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="center-mobile__general-page-link-links"
                    >
                      <Link
                        className="center-mobile__general-page-link-link first-link"
                        to={link.to1}
                      >
                        {link.link1}
                      </Link>
                      <Link
                        className="center-mobile__general-page-link-link"
                        to={link.to2}
                      >
                        {link.link2}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default CenterMobileGeneralPageLinks;
