import { useEffect, useState } from "react";

const usePopup = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const closePopupByClick = (event) => {
      if (event.target.classList.contains("modal_is-opened")) {
        closePopup();
      }
    };
    document.addEventListener("mousedown", closePopupByClick);
    return () => document.removeEventListener("mousedown", closePopupByClick);
  }, []);

  useEffect(() => {
    const closePopupByEscape = (event) => {
      if (event.key === "Escape") {
        closePopup();
      }
    };
    document.addEventListener("keydown", closePopupByEscape);
    return () => document.removeEventListener("keydown", closePopupByEscape);
  }, []);

  return { isPopupOpen, setIsPopupOpen, togglePopup, closePopup };
};

export default usePopup;
