import React, { useState } from "react";
import arrowLeft from "../../../images/arrows/note-expert-feedbacks-arrowleft-mobile.svg";
import arrowRight from "../../../images/arrows/note-expert-feedbacks-arrowright-mobile.svg";
import styles from "./NoteExpertGeneralPageSliderMobile.module.css";

function NoteExpertGeneralPageSliderMobile({ children, videos }) {
  const [offset, setOffset] = useState(0);

  const handleLeftArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + 289;

      return Math.min(newOffset, 0);
    });
  };

  const handleRightArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - 289;

      const maxOffset = -(289 * (children.length - 1));

      return Math.max(newOffset, maxOffset);
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons}>
        {offset === 0 ? (
          <div style={{ width: 10 }}></div>
        ) : (
          <img
            onClick={handleLeftArrowClick}
            src={arrowLeft}
            alt="стрелка влево"
          />
        )}
        {-(289 * videos.length - 289) === offset ? null : (
          <img
            onClick={handleRightArrowClick}
            src={arrowRight}
            alt="стрелка вправо"
          />
        )}
      </div>
      <ul
        style={{
          transform: `translateX(${offset}px)`,
        }}
        className={styles.list}
      >
        {children}
      </ul>
    </div>
  );
}

export default NoteExpertGeneralPageSliderMobile;
