import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileAdvantagesTabletScreen.module.css";
import Carousel from "react-elastic-carousel";
import Advantage from "./Advantage";

function CenterMobileIRepairMobileAdvantagesTabletScreen({
  title,
  contentForAdvantages,
}) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <Carousel>
          {contentForAdvantages.map((item, i) => {
            return (
              <Advantage
                key={i}
                img={item.img}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Carousel>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobileAdvantagesTabletScreen;
