import React from "react";
import {
  Section,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
} from "../index";

const repair = [
  {
    id: 1,
    text: "Classic – классический вариант, не теряющий своей актуальности, несмотря на появление более функциональных устройств.",
    number: "01",
  },
  {
    id: 2,
    text: "Touch – для тех, кто выбирает максимум. Этот гаджет мог быть стать прекрасной заменой iPhone, если бы еще и поддерживал функцию звонков.",
    number: "02",
  },
  {
    id: 3,
    text: "Nano – прекрасная «начинка» в миниатюрном комплексе. Устройство практически не занимает места, что делает его оптимальным решением для спортивных занятий и активного времяпровождения.",
    number: "03",
  },
];

const textFoIpod = [
  {
    id: 1,
    title: "Ремонт всех моделей iPod в сервисе Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Мы работаем с различными моделями iPod, неизменно предоставляя качественный и профессиональный сервис. Вы можете доверить нашим мастерам следующие серии устройств:"
          array={repair}
          paragraphDown="Если вы предпочитаете, чтобы плеер всегда был под рукой, то активная эксплуатация негативно отразиться на его состоянии: он станет хуже держать заряд, могут возникнуть проблемы с работой кнопок, а случайные падения приведут к повреждениям внутренних элементов. Но благодаря использованию фирменных инструментов и оборудования – все эти проблемы легко устранимы."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сроки и стоимость ремонта iPod в сервисном центре Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Чтобы обеспечить качественное обслуживание, мы оснастили центр всем
          необходимым, а также наладили долгосрочные контакты с ведущими
          поставщиками комплектующих. Благодаря этому в наличии всегда есть
          детали, которые могут потребоваться в процессе выполнения ремонта.
        </p>
        <p className="repair-iphone-display__paragraph">
          В среднем, на восстановление устройства уходит около получаса – этого
          времени более чем достаточно для проведения диагностики и
          разборки-сборки конструкции. Но вам не придется беспокоиться, скорость
          ничуть не отразится на качестве, ведь наши мастера – это
          профессионалы, за плечами у которых огромный опыт работ. Ежедневно мы
          проводим десятки ремонтных работ, устраняя поломки любой сложности.
        </p>
        <p className="repair-iphone-display__paragraph">
          Важно! Вы платите только после того, как специалист продемонстрирует
          безупречную работу устройства. На все детали предоставляется
          обязательная гарантия – это главное подтверждение высокой квалификации
          наших мастеров.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мак Профи работает каждый день, к тому же у нас действует бесплатная
          услуга курьерской доставки по столице – наш сотрудник прибудет в любой
          район Москвы, чтобы забрать устройство и доставить его в сервис. Если
          вы выбираете качество и предпочитаете рационально вкладывать средства,
          то на 100% останетесь довольны обращением в наш сервисный центр.
        </p>
      </>
    ),
  },
];

const RepairIpodText = () => {
  return (
    <Section className="section_group" style={{ paddingBottom: 65 }}>
      {textFoIpod.map((item) => {
        return (
          <RepairAppleLinksText
            key={item.id}
            title={item.title}
            text={item.text}
          />
        );
      })}
    </Section>
  );
};

export default RepairIpodText;
