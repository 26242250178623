import react from "react";
import Section from "../Section/Section";

const MacProfiAboutUs = () => {
  return (
    <Section>
      <div className='mac-profi__au-container'>
        <h2 className='mac-profi__au-title'>о нас</h2>
        <div className='mac-profi__au-border'></div>
        <p className='mac-profi__au-text'>Сервисный центр работает с 2006 года. Мы располагаем штатом сертифицированных специалистов, которые производят ремонт любой степени сложности.</p>
        <p className='mac-profi__au-text'> Профессиональное оборудование позволяет производить точную диагностику и ремонт Вашей техники с высокой эффективностью.</p>
        <p className='mac-profi__au-text'>Наш сервисный центр удобно расположен в центре Москвы, что позволяет нашим клиентам в кратчайшие сроки добраться в сервисный центр</p>
      </div>
    </Section>
  )
};

export default MacProfiAboutUs;

// toDo:
// h1 ???
// можно ли div заменить на семантический тег?
