import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  SelectForIphoneServiceLinks,
  RepairIphoneModelLinksForServiceMobile,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-battery.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import { Helmet } from "react-helmet";

const listOfBattery = [
  {
    id: 1,
    number: "01",
    subtitle: "Срок службы аккумуляторной батареи.",
    textLi:
      "Со временем аккумуляторная батарея на iPhone теряет свою емкость. При  потере емкости, аккумулятор iPhone мало держит заряд. В данном случае замена аккумулятора на iPhone решает проблему.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Использование неоригинального зарядного устройства.",
    textLi:
      "При использовании неоригинального зарядного устройства, аккумуляторная батарея iPhone быстро выходит из строя. Характерные симптомы выхода из строя батареи – резкое падение уровня заряда батареи, самопроизвольное отключение iPhone, неполный заряд аккумулятора.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Неисправные компоненты.",
    textLi:
      "При механическом повреждении iPhone, аккумуляторная батарея может выйти из строя.",
  },
];

const textOfBattery = [
  {
    id: 1,
    title: "Как мы меняем аккумуляторные батареи на iPhone",
    text: (
      <>
        Мы всегда имеем в наличии все аккумуляторы на все модели iPhone.
        Аккумуляторная батарея на iPhone меняется в течении 10-15 минут в
        зависимости от модели iPhone. После замены аккумуляторной батареи на
        iPhone все данные остаются сохраненными, никакие данные не теряются.
      </>
    ),
  },
  {
    id: 2,
    title:
      "Какую гарантию мы предоставляем на замену аккумуляторной батареи iPhone",
    text: (
      <>
        На все батареи предоставляется гарантия 1 месяц. В свою очередь для
        продления срока службы батареи, а также для наибольшей продолжительности
        мы рекомендуем раскачать аккумулятор. Для того, чтобы аккумуляторная
        батарея набрала большую емкость мы рекомендуем после замены батареи
        поставить заряжаться iPhone на 12 часов, после разрядить до 1% и снова
        поставить iPhone на зарядку на 12 часов. Такая не хитрая процедура
        поможет вам продлить срок службы аккумуляторной батареи iPhone и время
        работы в автономном режиме от аккумуляторной батареи. Вот{" "}
        <Link
          to="/remont-apple/remont-iphone"
          className="repair-mb__group-links-array"
        >
          тут
        </Link>{" "}
        вы можете посмотреть стоимость замены аккумуляторной батареи iPhone
        выбрав вашу модель. Все цены в прайс листе актуальны!
      </>
    ),
  },
];

const optionsBattery = [
  { value: "iPhone 4", label: "iPhone 4", price: "1100" },
  { value: "iPhone 4s", label: "iPhone 4s", price: "900" },
  { value: "iPhone 5", label: "iPhone 5", price: "1450" },
  { value: "iPhone 5c", label: "iPhone 5c", price: "1500" },
  { value: "iPhone 5s", label: "iPhone 5s", price: "1500" },
  { value: "iPhone 6", label: "iPhone 6", price: "1600" },
  { value: "iPhone 6s", label: "iPhone 6s", price: "1900" },
  { value: "iPhone 6 Plus", label: "iPhone 6 Plus", price: "1900" },
  { value: "iPhone 6s Plus", label: "iPhone 6s Plus", price: "3700" },
  { value: "iPhone 7", label: "iPhone 7", price: "2500" },
  { value: "iPhone 7 Plus", label: "iPhone 7 Plus", price: "1111" },
  { value: "iPhone 8", label: "iPhone 8", price: "2222" },
  { value: "iPhone 8 Plus", label: "iPhone 8 Plus", price: "3333" },
  { value: "iPhone X", label: "iPhone X", price: "4444" },
  { value: "iPhone XS", label: "iPhone XS", price: "5555" },
  { value: "iPhone XS Max", label: "iPhone XS Max", price: "6666" },
  { value: "iPhone 11", label: "iPhone 11", price: "7777" },
  { value: "iPhone 11 Pro", label: "iPhone 11 Pro", price: "8888" },
  { value: "iPhone 11 Pro Max", label: "iPhone 11 Pro Max", price: "9999" },
  { value: "iPhone SE 2020", label: "iPhone SE 2020", price: "0000" },
  { value: "iPhone 12", label: "iPhone 12", price: "10000" },
  { value: "iPhone 12 mini", label: "iPhone 12 mini", price: "11000" },
  { value: "iPhone 12 Pro", label: "iPhone 12 Pro", price: "12000" },
  { value: "iPhone 12 Pro Max", label: "iPhone 12 Pro Max", price: "13000" },
  { value: "iPhone 13", label: "iPhone 13", price: "14000" },
  { value: "iPhone 13 mini", label: "iPhone 13 mini", price: "15000" },
  { value: "iPhone 13 Pro", label: "iPhone 13 Pro", price: "16000" },
  { value: "iPhone 13 Pro Max", label: "iPhone 13 Pro Max", price: "17000" },
];

const RepairIphoneServiceLinkBattery = () => {
  return (
    <Page title="Срочная замена аккумулятора (батареи) на iPhone в Москве, цена на замену батареи iPhone!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена аккумулятора iPhone 7, 7 Plus, 6, 5, 5S. Все аккумуляторы в наличии, срок замены аккумуляторной батареи 10-15 минут. Гарантия на все батареи!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора на iPhone"
          titleImg="Замена аккумулятора на iPhone"
          banner={banner}
          title="Замена аккумулятора (батареи) на iPhone"
          text="Меняем аккумуляторные батареи на iPhone 4, 4S, 5, 5S, 6, 6+, 6S, 6S+, 7, 7+. Причины выхода из строя аккумулятора iPhone:"
        />
        <Section className="section_group" style={{ padding: "0 0 59px" }}>
          <div className="repair-mb__group-list-container">
            {listOfBattery.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 75px",
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Цены на замену батареи iPhone
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsBattery}
              text="аккумулятора"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          <div className="repair-mb__group-list-container">
            {textOfBattery.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBattery;
