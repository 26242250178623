import React from 'react';
import cn from 'classnames/bind';

const SectionTitle = ({ title, className }) => {
  return (
    <div className='title'>
      <h2 className={cn('title__text', className)}>{title}</h2>
      <span className='title__underline'></span>
    </div>
  )
};

export default SectionTitle;

