import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker4s.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfSpeaker4s = [
  {
    id: 1,
    title: "Помощь нашего сервиса по замене динамика iPhone 4s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с проблемами динамика, и ваш Айфон перестал
          работать в этом отношении корректно, помощь наших квалифицированных
          специалистов будет кстати, и позволит решить проблему. У нас все
          работы проводятся в профессиональных условиях, на специально
          подготовленных рабочих местах, что сводит к риску неудачи и
          дополнительные осложнения. Мастерская оснащена по последнему слову
          техники, у нас есть все для пайки и выполнения множества других
          манипуляций, это позволяет работать не только быстро, но и
          обеспечивать качество любых выполняемых работ. Кроме того, современное
          оснащение позволяет выполнять работы любой сложности, и добиваться при
          этом необходимого результата. На все работы мы даем гарантии на долгий
          срок, берем на себя полную ответственность за результат.
        </p>
        <p className="repair-iphone-display__paragraph">
          Также в наличии всегда есть полный набор запчастей, которые могут
          пригодиться в ремонте, не исключая и динамика. Как правило, при
          неполадках возникает необходимость его замены, и вам не придется ждать
          долго, мастер сможет немедленно взять деталь заводского оригинального
          производства, установить ее и дать на нее гарантию. К работам у нас
          могут быть допущены только грамотные квалифицированные специалисты,
          которые знают об Айфонах все, и потому не имеют проблем при
          осуществлении диагностики и ремонта. Мы внимательно относимся ко всем
          аспектам, от которых может зависеть качество выполнения работ, и
          потому всегда предоставляем прекрасный результат. Мастерская имеет
          прекрасную репутацию и массу положительных отзывов, нас рекомендуют. А
          еще у нас можно получить ремонт по выгодной цене и без переплат, что
          также становится существенным преимуществом.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если со звуком Айфона возникли проблемы, обращайтесь к нам. Мастер
          выполнит бесплатную экспресс-диагностику, найдет причину неисправности
          и устранит ее в самые короткие сроки – снова поломка не повторится. На
          все работы и запчасти будет предоставлена гарантия, и все
          обязательства по ней будут выполнены. Обращайтесь, и профессиональный,
          качественный ремонт будет обеспечен вам на высочайшем уровне,
          грамотно, по выгодной цене. Все проблемы устранимы, и ваш Айфон снова
          будет работать как новый.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModels4s = () => {
  return (
    <Page title="Профессиональная замена динамика iPhone 4s в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-dinamika/4s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Оперативная, профессиональна замена динамика iPhone 4s выполняется в сервисном центре Oh!MyGadget!. Качество и оперативность гарантируются."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена динамика iPhone 4s"
          titleImg="Замена динамика iPhone 4s"
          banner={banner}
          title="Замена динамика iPhone 4s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Необходимость ремонта, замены динамика для Айфона может
                возникнуть в целом ряде ситуаций, и причин такой поломки может
                быть немало. Это довольно распространенная поломка, которая
                имеет собственные специфические симптомы, которые позволяют
                судить о возникновении именно такой проблемы с большей или
                меньшей долей вероятности. Как правило, к симптомам именно такой
                категории относят:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Проблемы при прослушивании музыки в виде глухоты и
                    посторонних шумов на низких частотах,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Приглушенный звук и его дефекты в целом,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Проблемы при телефонных разговорах, невозможность слышать
                    собеседника, слишком тихий или некачественный звук.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Проблему позволяет решить замена динамика iPhone 4s, ремонт
                такого рода может выполняться в срочном порядке, и в целом он
                занимает мало времени. Однако для того, чтобы получить такие
                услуги, необходимо обратиться к грамотным и компетентным
                специалистам. Айфон – это высокотехнологичный девайс, который в
                обязательном порядке требует профессионального ремонта, а также
                и обеспечения особых условий при вскрытии, выполнении любых
                работ.
              </p>
              <p className="repair-iphone-display__paragraph">
                Ремонт выполняется обычно путем замены, для этого выполняется
                вскрытие девайса, удаляется неисправный динамик и ставится
                новый. При всей видимой простоте такой манипуляции и специальное
                оборудование, и особые условия, и компетентность мастеров
                остаются необходимыми в полной мере.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1150 P"
          priceDown="1000 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeaker4s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels4s;
