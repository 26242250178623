import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery6.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfBattery6 = [
  {
    id: 1,
    title:
      "Почему нужно менять батарею iPhone 6 спустя 2 года после покупки устройства",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Нередко причина поломки устройства заключается в неправильной
          эксплуатации устройства. А именно:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Нарушение цикличности заряда – частые краткосрочные подзарядки
              быстро «убивают» батарею.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Использование устройства в суровых условиях – холод, избыточная
              влажность или же частые скачки температур, приводят к повреждениям
              аккумулятора.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Активная эксплуатация устройства – работа большого числа
              приложений в фоновом режиме приводит к тому, что к значительному
              снижению емкости батареи гораздо раньше положенного срока.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Попытки самостоятельно устранить поломку, устанавливая различное
          программное обеспечение, не решают проблему. Рано или поздно
          повреждение аккумулятора может привести к его вздутию, а
          соответственно и появлению трещин на корпусе устройства. В худшем
          случае, это приведет к нарушению работы других элементов системы, что
          многократно увеличит стоимость ремонта. Более того, рано или поздно
          смартфон и вовсе может не запуститься, из-за чего его владелец может
          не только остаться без связи, но и лишиться важных для него функций.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels6 = () => {
  return (
    <Page title="Грамотная замена аккумулятора iPhone 6 в сервисном центре Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora/6"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена батареи iPhone 6 – это не проблема, если вы обратитесь в сервисный центр Oh!MyGadget!. Мы гарантируем выгодные цены и оперативную работу, качество и результативность."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPhone 6"
          titleImg="Замена аккумулятора iPhone 6"
          banner={banner}
          title="Замена аккумулятора iPhone 6"
          text="Постоянные скачки заряда, частые выключения устройства или же и вовсе невозможность включить смартфон – всё это сигнализирует о необходимости замены аккумулятора iPhone 6. Наши мастера профессионально выполнят необходимый ремонт, подберут качественные запчасти и восстановят телефон – все работы выполняются в присутствии клиента."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery6.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Срочная замена аккумулятора iPhone 6 в Москве"
          text="Замена аккумулятора"
          price="1600 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Вам не придется искать замену любимому телефону, тратя время на
                освоение временной техники. Все работы будут проведены в
                присутствии клиента – срочное восстановление займет около
                получаса.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Сервисный центр Мак Профи работает ежедневно, включая
                праздничные и выходные дни – обращайтесь к нам тогда, когда это
                действительно удобно. Все работы будут выполнены в срочном
                порядке, без непредвиденных задержек и длительного ожидания
                поставки комплектующих элементов – всё, что необходимо для
                работы уже есть в наличии.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels6;
