import { myFetch } from "./myFetch";

export const getNotifications = (params = {}) => {
  const queryParams = new URLSearchParams(params);

  return myFetch(`notifications?${queryParams}`);
};

export const getNotificationById = (notificationId) => {
  return myFetch(`notifications/${notificationId}`);
};

export const editNotification = (notificationId, data) => {
  return myFetch(`notifications/${notificationId}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
};

export const hideNotification = (active) => {
  return myFetch(`notifications/hide`, {
    method: "PATCH",
    body: JSON.stringify(active),
  });
};

export const postNotification = (title, text) => {
  return myFetch(`notifications`, {
    method: "POST",
    body: JSON.stringify({ title, text }),
  });
};

export const deleteNotification = (notificationId) => {
  return myFetch(`notifications/${notificationId}`, { method: "DELETE" });
};

// export const makeNotificationActive = (notificationId) => {
//   return myFetch(`notifications/active`, {
//     method: "PATCH",
//     body: JSON.stringify({ notification: notificationId }),
//   });
// };

export const deleteNotifications = (notificationsIds) => {
  return myFetch(`notifications`, {
    method: "DELETE",
    body: JSON.stringify({ notifications: notificationsIds }),
  });
};
