import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
} from "../index";
import banner from "../../images/banners/repair-ipadAir2.jpeg";
import { Helmet } from "react-helmet";

const priceIpadAir2 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "9850 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "9850 P",
  },
  {
    id: 3,
    option: "Замена корпуса/задней крышки",
    price: "7900 P",
  },
  {
    id: 4,
    option: "Замена аккумулятора",
    price: "3600 P",
  },
  {
    id: 5,
    option: "Замена микрофона",
    price: "2350 P",
  },
  {
    id: 6,
    option: "Замена динамика",
    price: "2350 P",
  },
  {
    id: 7,
    option: "Замена кнопки Home (домой)",
    price: "2350 P",
  },
  {
    id: 8,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1950 P",
  },
  {
    id: 9,
    option: "Замена кнопки включения",
    price: "2100 P",
  },
  {
    id: 10,
    option: "Замена антенны",
    price: "2100 P",
  },
  {
    id: 11,
    option: "Ремонт Wi-Fi модуля",
    price: "6000 P",
  },
  {
    id: 12,
    option: "Замена датчика приближения",
    price: "2100 P",
  },
  {
    id: 13,
    option: "Замена сим-держателя",
    price: "1500 P",
  },
  {
    id: 14,
    option: "Замена основной камеры",
    price: "2400 P",
  },
  {
    id: 15,
    option: "Замена передней камеры",
    price: "2350 P",
  },
  {
    id: 16,
    option: "Замена разъема зарядки",
    price: "2400 P",
  },
  {
    id: 17,
    option: "Замена разъема наушников",
    price: "2400 P",
  },
];

const textOfIpadAir2Up = [
  {
    id: 1,
    title: "Особенности ремонта iPad Air 2",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мы не зря подчеркнули профессионализм наших сотрудников выше. Дело в
          том, что ремонт Айпад Эйр 2 дело не из лёгких, и "по плечу" только
          специалистам самого высокого уровня.
        </p>
        <p className="repair-iphone-display__paragraph">
          При сборке iPad Air 2, также как и в младшей версии iPad Air, активно
          используется специальный клей, на который посажены фронтальная панель
          и другие детали. Для того чтобы просто разобрать планшет и получить
          доступ к большинству его деталей приходится использовать специальный
          фен, жар от которого может повредить чувствительную электронику. В
          свое время данное обстоятельство принудило признать специалистов iPad
          Air 2 вообще неремонтопригодным. Как показывает наш опыт, в данном
          выводе аналитики поспешили. А вот доверять ваш айпэд сомнительным
          специалистам, и, тем более, пытаться отремонтировать его
          самостоятельно, "на коленке", ни в коем случае не стоит.
        </p>
      </>
    ),
  },
];

const textOfIpadAir2Down = [
  {
    id: 1,
    title: "Последовательность ремонта Айпада Эир 2",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Первым делом мы проводим полную диагностику. Она бесплатна. Ни одна
          неисправность, сколько бы незначительная, не будет пропущена. Далее мы
          оговариваем с вами "фронт работ" и их стоимость. Вы сами решаете какие
          виды ремонтных работ вы готовы оплатить. После того, как вы получаете
          назад исправный гаджет и можете протестировать его работоспособность,
          происходит оплата. Обращаем ваше внимание на то, что у нас нет никакой
          предоплаты. Также вы получаете гарантийный талон сроком до года
          действия.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады видеть вас в наших центрах и оказать помощь в решении любых
          проблем с вашими iPad Air 2!
        </p>
      </>
    ),
  },
];

const RepairIpadModelPagesIpadAir2 = () => {
  return (
    <Page title={"Ремонт iPad Air 2 в сервисном центре в Москве!"}>
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/air-2"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Айпад Эир 2 ремонт в Москве. Поможем восстановить работоспособность вашего гаджета в самые кратчайшие сроки! Ждем Вас!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPad Air 2"
          alt="Ремонт iPad Air 2"
          titleImg="Ремонт iPad Air 2"
          text="Вам необходим ремонт iPad Air 2 в Москве? Тогда вы попали в нужное место! Специализация наших центров - это, как раз, ремонт различных устройств и гаджетов от Apple. Через руки наших специалистов прошло бесчисленное множество поломанной купертиновской продукции, и на этом поприще ими приобретены бесценные опыт и знания, что позволяет нам заявлять: любые неисправности вашего Айпад Эйр 2 мы устраним без последствий, в кратчайший срок и по разумной цене."
        />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpadAir2Up.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpadAir2}
            title="Стоимость ремонта iPad Air 2"
            text="* Все цены указаны в рублях рф. В стоимость входит комплектующее и услуга по установки."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpadAir2Down.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpadAir2;
