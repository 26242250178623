import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery7.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfBattery7 = [
  {
    id: 1,
    title: <>Что включает в себя замена аккумулятора iPhone&nbsp;7</>,
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Первоначальный этап ремонта – диагностика. Это позволяет определить
          истинную причину возникновения поломки, а значит, и продлить период
          эксплуатации устройства. В центре используется современное
          оборудование, благодаря которому выявим любые недочеты в работе
          устройства и устраним их непосредственно при клиенте.
        </p>
        <p className="repair-iphone-display__paragraph">
          После проведения диагностики, заказчику будет озвучена точная
          стоимость и сроки выполнения услуг. Вы застрахованы от непредвиденного
          изменения цены, а также необоснованных наценок и дополнительных
          переплат – мы детально распишем предстоящие затраты и подробно ответим
          на все вопросы.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Когда нужно менять батарею iPhone 7",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Средний срок службы батареи Айфон 7 – 1,5-2 года. В среднем,
          устройство рассчитано на 500 циклов перезарядки, поэтому, чем активнее
          эксплуатация смартфона, тем раньше предстоит обратиться в сервис для
          проведения замены аккумулятора.
        </p>
        <p className="repair-iphone-display__paragraph">
          Чтобы правильно оценить работу устройства, необходимо провести простой
          тест. Для этого потребуется засечь время и понаблюдать, как быстро
          телефон будет терять заряд – если за час вы «потеряете» 10-20%, то это
          повод срочно обратиться к специалистам.
        </p>
        <p className="repair-iphone-display__paragraph">
          Быстрая и недорогая замена аккумулятора iPhone 7 в Мак Профи
        </p>
        <p className="repair-iphone-display__paragraph">
          В нашем центре доступна услуга срочного ремонта – при наличии
          свободных мастеров, все работы будут выполнены непосредственно при
          клиенте. Мы поможем с настройкой программного обеспечения и заменим
          вышедший из строя аккумулятор – сотрудничая с нами, вы получаете
          следующие преимущества:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Все комплектующие в наличии – сотрудничаем только с проверенными
              поставщиками, что позволяет гарантировать регулярность поставок и
              отменное качество запчастей.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Лояльная стоимость – наши расценки выгодны и доступны, а потому вы
              сможете восстановить неисправное устройство при небольших
              финансовых затратах.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Работаем 7 дней в неделю – если же у вас нет времени обратиться в
              наш сервисный центр, то вы можете воспользоваться услугой
              курьерской доставки. Восстановленный смартфон будет доставлен
              владельцу сразу после проведения ремонта.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Обратитесь к нам уже сегодня, и замена аккумулятора iPhone 7 будет
          выполнена максимально быстро. Только честные цены и высокий уровень
          сервиса – получите полный комплекс услуг на выгодных условиях.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels7 = () => {
  return (
    <Page title="Быстрая замена аккумулятора iPhone 7: быстрая диагностика и замена батареи">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora/7"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Качественная, оперативная замена аккумулятора iPhone 7 возможна в сервисном центре Oh!MyGadget! – выгодные цены и внимание к вашим пожеланиям гарантируются."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPhone 7"
          titleImg="Замена аккумулятора iPhone 7"
          banner={banner}
          title={<>Замена аккумулятора iPhone&nbsp;7</>}
          text="Если ваш смартфон неожиданно выключается, а период его автономной работы значительно сократился, то это повод срочно обратиться в Мак Профи. Оперативная замена аккумулятора iPhone 7 поможет восстановить нормальную работу устройства. Предложим лучшие условия и длительную гарантию качества на все виды работ."
        />
        <RepairApplePrice
          subtitle="Стоимость замены аккумулятора iPhone 7"
          text="Замена аккумулятора"
          price="2500 P"
          paragraph="Цены указаны в рублях РФ."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBattery7.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels7;
