import React from "react";
import styles from "./AdvantagesMobile.module.css";

function AdvantagesMobile(props) {
  return (
    <>
      <div className={styles.image}>
        <img src={props.img} className={styles.icon} />
      </div>
      <h4 className={styles.title}>{props.title}</h4>
      <p className={styles.text}>{props.text}</p>
    </>
  );
}

export default AdvantagesMobile;
