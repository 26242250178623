import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpadMini4.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfRecommendations = [
  {
    id: 1,
    text: "Бесплатная диагностика. Тестирование планшета будет проведено абсолютно бесплатно. С его помощью удается выявить любые, даже скрытые повреждения, которые появились после удара или иных воздействий.",
    number: "01",
  },
  {
    id: 2,
    text: "Срочный ремонт. Наши мастера готовы выполнить ремонт непосредственно при клиенте – все манипуляции займут не более получаса. Точный срок замены аккумулятора iPad Mini 4 будет назван только после диагностики, ведь может потребоваться дополнительный ремонт для полного устранения текущих неполадок.",
    number: "02",
  },
  {
    id: 3,
    text: "Гарантия на все виды работ. После установки новой батареи, мастер обязательно включит и протестирует устройство. Лишь оценив стабильную работу планшета, заказчик оплачивает предоставленные услуги.",
    number: "03",
  },
];

const textOfBatteryMini4 = [
  {
    id: 1,
    title: <>Особенности замены аккумулятора iPad Mini&nbsp;4</>,
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Чтобы «добраться» до аккумулятора, потребуется практически полностью
          разобрать устройство. И это не так уж просто, как может показаться на
          первый взгляд, ведь нужно соблюдать максимальную аккуратность,
          контролируя каждое движение. Шлейфы отсоединяются в определенной
          последовательности, а для открытия корпуса его придется нагреть – без
          специального оборудования и инструментов, сделать это очень сложно.
        </p>
        <p className="repair-iphone-display__paragraph">
          Важно понимать, что несвоевременная замена аккумулятора iPad Mini 4,
          как и самостоятельный ремонт, нередко приводит к повреждению иных
          элементов конструкции. Поэтому не стоит откладывать обращение в сервис
          или же пытаться сэкономить, выполняя работы своими руками или доверяя
          эту работу неопытным мастерам.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title:
      "Что вы получаете, доверив замену батареи iPad Mini 4 специалистам Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Нас ценят за индивидуальную работу с каждым клиентом, отличную репутацию и лояльные расценки. Обеспечить стабильно высокий уровень сервиса, нам помогают следующие преимущества:"
          array={listOfRecommendations}
          paragraphDown="Обращайтесь в сервисный центр Мак Профи, чтобы получить качественный и своевременный ремонт iPad Mini 4. Вы можете позвонить нам и выбрать подходящую дату для посещения, либо самостоятельно прибыть в любой день недели, в соответствии с графиком работы, представленном на сайте."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelMini4 = () => {
  return (
    <Page title="Срочная замена аккумулятора iPad Mini 4 в Москве по низким ценам">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-akkumuljatora/mini-4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Когда необходима замена аккумулятора iPad Mini 4, наши специалисты уверенно и оперативно возьмутся за работу. Сервис Oh!MyGadget! ждет новых клиентов всегда!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена аккумулятора ipad mini 4"
          titleImg="замена аккумулятора ipad mini 4"
          banner={banner}
          title="Срочная замена аккумулятора iPad&nbsp;Mini&nbsp;4"
          text="iPad Mini 4 – идеальное решение для комфортной работы. Планшет отличается небольшим весом и компактным размерами, чем и обусловлена сложная конструкция этого устройства. Самостоятельно его разобрать, починить или же провести иные манипуляции не удастся, а потому для замены аккумулятора стоит обратиться в специализированный сервис. Именно в Мак Профи вы сможете получить все необходимые услуги на выгодных условиях – качество гарантировано."
        />
        <RepairApplePrice text="Замена аккумулятора" price="2500 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryMini4.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelMini4;
