import React from "react";
import { Link } from "react-router-dom";
import Section from "../../Section/Section";
import styles from "./CenterMobileGeneralPageGadgetsTableScreen.module.css";
import phoneWorked from "../../../images/phone-worked.svg";
import padWorked from "../../../images/pad-worked.svg";

function CenterMobileGeneralPageGadgetsTableScreen({ mobileLinks, PadLinks }) {
  return (
    <Section className={styles.gadgetSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>КАКОЕ У ВАС УСТРОЙСТВО?</h2>
        <div className={styles.mobileWrapper}>
          <div className={styles.gadgetNameWrapper}>
            <img src={phoneWorked} alt="картинка телефона" />
            <p className={styles.gadgetName}>СМАРТФОН</p>
          </div>
          <ul className={styles.list}>
            {mobileLinks.map((item, i) => {
              return (
                <li
                  key={i}
                  className="center-mobile__general-page-gadgets-links"
                >
                  <Link
                    className="center-mobile__general-page-gadgets-link"
                    to={item.to1}
                  >
                    {item.link1}
                  </Link>
                  <Link
                    className="center-mobile__general-page-gadgets-link"
                    to={item.to2}
                  >
                    {item.link2}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.padWrapper}>
          <ul className={styles.list}>
            {PadLinks.map((item, i) => {
              return (
                <li
                  key={i}
                  className="center-mobile__general-page-gadgets-links"
                >
                  <Link
                    className="center-mobile__general-page-gadgets-link"
                    to={item.to1}
                  >
                    {item.link1}
                  </Link>
                  <Link
                    className="center-mobile__general-page-gadgets-link"
                    to={item.to2}
                  >
                    {item.link2}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className={styles.gadgetNameWrapper}>
            <img src={padWorked} alt="картинка планшета" />
            <p className={styles.gadgetName}>ПЛАНШЕТ</p>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CenterMobileGeneralPageGadgetsTableScreen;
