import React from "react";
import Section from "../Section/Section";
import iphoneBanner from "../../images/banners/repaip-iphone-banner.png";

const RepairIphoneBanner = () => {
  return (
    <Section>
      <img
        className="repair-iphone__banner"
        src={iphoneBanner}
        alt="ремонт iphone"
        title="ремонт iphone"
      />
    </Section>
  );
};

export default RepairIphoneBanner;
