import React from "react";
import Section from "../Section/Section";
import iPodBanner from "../../images/banners/repair-mb-banner.png";

const RepairIpodBanner = () => {
  return (
    <Section>
      <img
        className="repair-ipod__banner"
        src={iPodBanner}
        alt="ремонт ipod"
        title="ремонт ipod"
      />
    </Section>
  );
};

export default RepairIpodBanner;
