import React, { useEffect, useRef } from "react";
import { Popup } from "../../index";
import useFormWithValidation from "../../../hooks/useFormWithValidation";

const PopupAddUser = ({ isOpenPopup, onClose, handleChangeUserData }) => {
  const { values, handleChange, errors, isValid, resetForm } =
    useFormWithValidation({
      login: "",
      email: "",
      name: "",
      role: "",
    });

  const isDisabled =
    !isValid || Object.values(values).some((value) => value === "");

  const loginButtonClassName = !isDisabled
    ? "form__button"
    : "form__button form__button-disabled";

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isDisabled) {
      handleChangeUserData(
        values.login,
        values.email,
        values.name,
        values.role
      );
    }

    resetForm();
    onClose();
  };

  const loginRef = useRef(null);

  useEffect(() => {
    if (isOpenPopup) {
      loginRef.current?.focus();
    }
  }, [isOpenPopup]);

  return (
    <Popup className="form__error" isOpenPopup={isOpenPopup} onClose={onClose}>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="form__fields-container"
      >
        <fieldset className="form__login">
          <label htmlFor="login" className="form__label">
            Логин
          </label>
          <input
            ref={loginRef}
            id="user-login"
            name="login"
            type="text"
            className="form__field"
            required={true}
            value={values.login}
            onChange={handleChange}
          />
          {errors.login && <p className="form__error">{errors.login}</p>}
          <label htmlFor="email" className="form__label">
            Email
          </label>
          <input
            id="user-email"
            name="email"
            type="email"
            className="form__field"
            required={true}
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p className="form__error">{errors.email}</p>}
          <label htmlFor="name" className="form__label">
            Имя
          </label>
          <input
            id="user-name"
            name="name"
            type="text"
            className="form__field"
            required={true}
            value={values.name}
            onChange={handleChange}
            minLength={2}
          />
          {errors.name && <p className="form__error">{errors.name}</p>}
          <label htmlFor="role" className="form__label">
            Должность
          </label>
          <input
            id="user-role"
            name="role"
            type="text"
            className="form__field"
            required={true}
            value={values.role}
            onChange={handleChange}
          />
          {errors.role && <p className="form__error">{errors.role}</p>}
        </fieldset>
        <button type="submit" className={loginButtonClassName}>
          Сохранить
        </button>
      </form>
    </Popup>
  );
};

export default PopupAddUser;
