import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import nokiaBanner from "../../../images/banners/mobile-nokia-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import {
  pricelists5mini,
  pricelist2Grand,
} from "../../../utils/CenterMobileSamsungPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "НАЛИЧИЕ КОМПЛЕКТУЮЩИХ",
    text: "В «Центр Мобайл» имеются оригинальные и аналоговые комплектующие ко всем моделям смартфонов Нокиа. Благодаря этому сроки выполнения ремонта у нас оптимально короткие, а качество – неизменно высокое.",
  },
  {
    img: icon2,
    title: "ДОСТАВКА СМАРТФОНОВ В ЦЕНТР И ОБРАТНО",
    text: "При нехватке времени можно воспользоваться услугами нашего курьера, который приедет за вашим смартфоном в назначенный срок. Наш сотрудник надлежащим образом оформит вызов, оставив владельцу необходимые документы. В рабочее время действует зона бесплатной доставки (в пределах МКАД).",
  },
  {
    img: icon3,
    title: "ВЫЗОВ МАСТЕРА НА ДОМ",
    text: "В тех случаях, когда ремонт телефонов Нокиа не требует сервисных условий, можно вызвать мастера на дом по телефону +7 (495) 540-43-19. Наш специалист устранит неисправность на месте.",
  },
  {
    img: icon4,
    title: "ГАРАНТИЯ",
    text: "«Центр Мобайл» не только обеспечивает качественный ремонт смартфонов Nokia Lumia и других моделей, но и предусматривает их гарантийное обслуживание.",
  },
];

const mobiles = [
  { name: "Lumia", pricelist: pricelists5mini },
  { name: "N", pricelist: pricelist2Grand },
  { name: "C100", pricelist: pricelists5mini },
  { name: "C2 2nd Edition", pricelist: pricelist2Grand },
  { name: "C200", pricelist: pricelists5mini },
  { name: "C21", pricelist: pricelist2Grand },
  { name: "C21 Plus", pricelist: pricelists5mini },
  { name: "C31", pricelist: pricelist2Grand },
  { name: "G100", pricelist: pricelists5mini },
  { name: "G11", pricelist: pricelist2Grand },
  { name: "G11 Plus", pricelist: pricelists5mini },
  { name: "G21", pricelist: pricelist2Grand },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairMobileNokia() {
  return (
    <Page title="Профессиональный ремонт смартфонов Nokia в Москве, сервисный центр Oh!MyGadget!">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-smartfonov-nokia"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Специализированный сервисный центр Oh!MyGadget! предлагает квалифицированные услуги по ремонту смартфонов Nokia в Москве. Обслуживаем и ремонтируем все модели телефонов Нокиа по доступным ценам."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={nokiaBanner}
          alt="смартфон Nokia"
          title="смартфон Nokia"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ СМАРТФОНОВ NOKIA"
          text="Oh!MyGadget! осуществляет качественный ремонт смартфонов Nokia по самым приятным ценам. Если у вас не включается телефон Nokia, не стоит отчаиваться и пытаться починить его самостоятельно. Дешевле и результативнее обратиться к нашим специалистам, у которых за плечами огромный практический опыт, а в руках самое современное профессиональное оборудование и инструменты."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Nokia"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Nokia:"
          mobiles={mobiles}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА ПРОФЕССИОНАЛЬНОГО РЕМОНТА"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ОТДАТЬ В РЕМОНТ СМАРТФОН НОКИА ?"
          text={
            <>
              Чтобы воспользоваться услугами нашего специализированного центра,
              можно привезти смартфон по адресу: Москва, ул. Лесная, 1/2, офис
              351; при ограниченном времени – вызвать курьера или даже мастера
              на дом. По всем вопросам можно обращаться на электронную почту{" "}
              <a
                href="mailto:info@centre-mobile.ru"
                className={styles.emaillink}
              >
                info@centre-mobile.ru
              </a>
              , гарантируем быстрый и компетентный ответ.
            </>
          }
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairMobileNokia;
