import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIwatchModeslLinks,
} from "../index";
import banner from "../../images/banners/iwatch2.jpeg";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Глубокие царапины на корпусе, возникшие при попытке заменить дисплей",
    number: "01",
  },
  {
    id: 2,
    text: "Испорченная батарея, которая вышла из строя при нагреве устройства",
    number: "02",
  },
  {
    id: 3,
    text: "Повреждения шлейфов и разъемов из-за неправильных или неаккуратных действий.",
    number: "03",
  },
];

const reasons = [
  {
    id: 1,
    text: "Гарантии – это ключевой показатель выбора надежного сервиса. У нас работают только высококвалифицированные мастера, а комплектующие мы закупаем у проверенных поставщиков, что позволяет не сомневаться в качестве предоставленных услуг.",
    number: "01",
  },
  {
    id: 2,
    text: "Лояльные расценки – мы не завышаем стоимостью работ, и стремимся предоставлять качественный сервис по честной цене.",
    number: "02",
  },
  {
    id: 3,
    text: "Полный комплекс услуг – обязательно проведем диагностику, и лишь после этого приступим к ремонту Apple Watch Series 2.",
    number: "03",
  },
];

const textIwatch2 = [
  {
    id: 1,
    title: <>Самостоятельный ремонт Apple Watch&nbsp;Series&nbsp;2</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Последствия самостоятельного проведения ремонта довольно печальны. Чаще всего к нам обращаются со следующими проблемами:"
          array={listOfTrouble}
          paragraphDown="Эта модель часов радует надежностью – многочисленные отзывы подтверждают, что она рассчитана на продолжительный период эксплуатации. Поэтому не стоит рисковать, самостоятельно занимаясь ремонтом устройства – сделать это качественно без специальных инструментов и оборудования просто-напросто невозможно. Лучше доверьте ремонт Apple Watch Series 2 квалифицированным мастерам, чтобы еще долгие годы успешно пользоваться любимыми часами."
        />
      </>
    ),
  },
  {
    id: 2,
    title: (
      <>
        3 причины доверить ремонт Apple Watch&nbsp;Series&nbsp;2 профессионалам
      </>
    ),
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Прежде чем обращаться в сервис, вы можете самостоятельно перезагрузить устройство, после чего полностью его зарядить. Остальные действия – задача наших специалистов. Если поломка несерьезная, то нам удастся её решить меньше, чем за полчаса, и лишь в некоторых случаях этот период будет увеличен. И это далеко не единственная причина обратиться именно в сервис Мак Профи, ведь именно у нас:"
          array={reasons}
          paragraphDown="Приезжайте к нам прямо сегодня – сервисный центр очень удобно расположен возле станции метро Белорусская. Или свяжитесь с нами по телефонам, представленным на сайте, чтобы заказать курьерскую доставку. Наш сотрудник прибудет по указанному адресу и заберет устройство для дальнейшего проведения ремонта."
        />
      </>
    ),
  },
];

const RepairIwatchModelLink2 = () => {
  return (
    <Page title="Ремонт Apple Watch Series 2 в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont_apple_watch/series-2"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Все виды ремонта Apple Watch Series 2 в Oh!MyGadget!. Починить Apple Watch в Москве с гарантией."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="ремонт apple watch series 2"
          titleImg="ремонт apple watch series 2"
          banner={banner}
          title={<>Ремонт Apple Watch&nbsp;Series&nbsp;2</>}
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Быстрый ремонт Apple Watch Series 2: квалифицированная помощь,
                курьерская доставка, бесплатная диагностика. Широкий спектр
                услуг по честным ценам.
              </p>
              <p className="repair-iphone-display__paragraph">
                Apple Watch Series 2 – модель часов, которая неизменно лидирует
                в рейтингах продаж. Доверие покупателей вполне оправдано, ведь
                устройство представляет собой оптимальный тандем качества и
                функциональности – вот только от поломок не застрахован никто.
                Если вы разбили экран, испортили ремешок или же аккумулятор
                пришел в негодность, не спешите пытаться решить эту проблему
                самостоятельно – обратитесь в сервисный центр, чтобы
                восстановление устройства было действительно качественным.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIwatch2.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIwatchModeslLinks />
      </main>
    </Page>
  );
};

export default RepairIwatchModelLink2;
