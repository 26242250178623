import { useState } from "react";

const useFormWithValidation = (defaultValues) => {
  const defaultErrors = Object.fromEntries(
    Object.keys(defaultValues).map((key) => [key, ""])
  );

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultErrors);
  const [isValid, setIsValid] = useState(false);
  const [startChange, setStartChange] = useState(false);

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: target.validationMessage });
    setIsValid(target.closest("form").checkValidity());

    // в этом месте нужно исправить так, чтобы изменения применялись к каждому инпуту по отдельности;
    setStartChange((prevValue) => true); // не работает!!!!
  };

  const resetForm = (
    newValues = defaultValues,
    newErrors = defaultErrors,
    newIsValid = false
  ) => {
    setValues(newValues);
    setErrors(newErrors);
    setIsValid(newIsValid);
  };

  return {
    values,
    setValues,
    handleChange,
    errors,
    isValid,
    resetForm,
    startChange,
  };
};

export default useFormWithValidation;
