import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleLinksText,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  SelectForIphoneServiceLinks,
} from "../index";
import banner from "../../images/banners/repair-ipadPower.jpeg";
import { Helmet } from "react-helmet";

const textServiceLinlPower = [
  {
    id: 1,
    title: "Основные причины по которым Айпад может не включаться",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Во-первых, iPad может не включатся из-за банальной полной разрядки
          аккумулятора. Казалось бы — как можно «проворонить» разрядившийся
          планшет? Это уж точно не про вас — говорите, наверное, сейчас вы. И
          всё же — попробуйте. Именно разряженный в ноль iPad становится
          причиной преждевременных нервов в абсолютном большинстве случаев.
          Поставьте ваш Айпэд на зарядку — видите значок красной батареи и
          индикацию заряда? Тогда всё — ваша проблема разрешилась. Иначе —
          переходим ко второй процедуре.
        </p>
        <p className="repair-iphone-display__paragraph">
          Во-вторых, возможна та или иная проблема с прошивкой вашего
          "яблочного" планшета. В таком случае должен помочь сброс настроек. Для
          этого необходимо зажать и удерживать в течении десяти секунд
          одновременно две кнопки -- "Home" и "Power".
        </p>
        <p className="repair-iphone-display__paragraph">
          В-третьих, если все предыдущие действия никакого результата не
          возымели, у вас остаётся ещё возможность восстановления через iTunes.
          Подключите iPad к вашему компьютеру. Предполагается, что iTunes на
          вашем компьютере уже установлен, если нет — скачайте и установите его.
          Далее, необходимо опять одновременно нажать и удерживать на протяжении
          тех же десяти секунд всё те же две кнопки: включения и "Домой". Вы
          должны будите увидеть сообщение, что обнаружено новое устройство в
          режиме восстановления. После этого начнётся процесс перепрошивки
          Айпэда, по окончанию которого, он должен включится без каких-либо
          проблем.
        </p>
        <p className="repair-iphone-display__paragraph">
          Внимание! После восстановления будут утеряны все ваши персональные
          данные, так что советуем сделать предварительно резервную копию, если,
          конечно, iPad позволяет это сделать.
        </p>
        <p className="repair-iphone-display__paragraph">
          Вы проделали всё вышеописанное без какого-либо положительного
          результата и всё равно iPad не включается? Это означает, что проблемы
          вашего Айпэда достаточно серьёзные и за их решением надо идти в наш
          сервисный центр.
        </p>
      </>
    ),
  },
];

const optionsIpadPower = [
  {
    value: "iPad",
    label: "iPad",
    price: "2400",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 2",
    label: "iPad 2",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 3",
    label: "iPad 3",
    price: "1600",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 4",
    label: "iPad 4",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad mini",
    label: "iPad mini",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Air",
    label: "iPad Air",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad mini 2",
    label: "iPad mini 2",
    price: "2350",
    price2: "1950",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Air 2",
    label: "iPad Air 2",
    price: "2350",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad mini 3",
    label: "iPad mini 3",
    price: "2500",
    price2: "1950",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 12.9",
    label: "iPad Pro 12.9",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad mini 4",
    label: "iPad mini 4",
    price: "2080",
    price2: "2080",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 9.7",
    label: "iPad Pro 9.7",
    price: "4450",
    price2: "4450",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 5",
    label: "iPad 5",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 2 10.5",
    label: "iPad Pro 2 10.5",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 2 12.9",
    label: "iPad Pro 2 12.9",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 6 (2018)",
    label: "iPad 6 (2018)",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 11 (2018)",
    label: "iPad Pro 11 (2018)",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 12.9 (2018)",
    label: "iPad Pro 12.9 (2018)",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Air 3 (2019)",
    label: "iPad Air 3 (2019)",
    price: "1600",
    price2: "1600",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad mini 5",
    label: "iPad mini 5",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 7",
    label: "iPad 7",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 8",
    label: "iPad 8",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Air 4 (2020)",
    label: "iPad Air 4 (2020)",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 11 (2021)",
    label: "iPad Pro 11 (2021)",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad Pro 12.9 (2021)",
    label: "iPad Pro 12.9 (2021)",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad 9",
    label: "iPad 9",
    pprice: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
  {
    value: "iPad mini 6",
    label: "iPad mini 6",
    price: "1900",
    price2: "1900",
    price3: "2400",
    price4: "1900",
  },
];

const RepairIpadServiceLinkPower = () => {
  return (
    <Page title="Не включается iPad? Причины поломки, цена ремонта, сроки ремонта">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/ne-vkljuchaetsja"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Что делать если не включается iPad? Причины поломок, срочный ремонт, стоимость ремонта не включающегося Айпад в Москве."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="не включается ipad"
          titleImg="не включается ipad"
          banner={banner}
          title="iPad не включается"
          text='iPad не включается? Что делать? Для начала — не паниковать. Возможно, один из описанных ниже советов позволит решить вашу проблему, без особого труда и необходимости обращаться в ремонтный сервис. Более того, девяносто процентов случаев "лечится" именно такими способами. Если же, всё таки, ничего из приведённых советов вам не помогло, то вы оказались в несчастливых 10%, и за решением проблемы придётся обратится в профессиональную ремонтную мастерскую. Такую как наша.'
        />
        <Section
          className="section_group"
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            padding: "55px 0 75px",
            marginTop: 55,
          }}
        >
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Характерные поломки и цены на iPad в состоянии не включается
            </h2>
            <SelectForIphoneServiceLinks
              options={optionsIpadPower}
              text="аккумулятора"
              text2="кнопки включения"
              text3="разъема зарядки"
              text4="Не заряжается"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textServiceLinlPower.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkPower;
