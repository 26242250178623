import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/repair-ipadMini3.jpeg";
import { Helmet } from "react-helmet";

const priceIpadMini3 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "3500 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "5500 P",
  },
  {
    id: 3,
    option: "Замена корпуса/задней крышки",
    price: "6400 P",
  },
  {
    id: 4,
    option: "Замена аккумулятора",
    price: "2900 P",
  },
  {
    id: 5,
    option: "Замена микрофона",
    price: "2000 P",
  },
  {
    id: 6,
    option: "Замена динамика",
    price: "2000 P",
  },
  {
    id: 7,
    option: "Замена кнопки Home (домой)",
    price: "2500 P",
  },
  {
    id: 8,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2200 P",
  },
  {
    id: 9,
    option: "Замена кнопки включения",
    price: "2100 P",
  },
  {
    id: 10,
    option: "Замена антенны",
    price: "2100 P",
  },
  {
    id: 11,
    option: "Ремонт Wi-Fi модуля",
    price: "3300 P",
  },
  {
    id: 12,
    option: "Замена датчика приближения",
    price: "2100 P",
  },
  {
    id: 13,
    option: "Замена основной камеры",
    price: "2500 P",
  },
  {
    id: 14,
    option: "Замена передней камеры",
    price: "1950 P",
  },
  {
    id: 15,
    option: "Замена разъема наушников",
    price: "2100 P",
  },
];

const textIpadMini3 = [
  {
    id: 1,
    text: "Компетентные специалисты: наши сотрудники изрядно поднаторели в исправлении самых различных неисправностей техники Apple и обладают всеми на то нужными знаниями и навыками.",
    number: "01",
  },
  {
    id: 2,
    text: "Профессиональное оборудование - ремонт и диагностика производятся исключительно с помощью современного, высокоточного инструментария.",
    number: "02",
  },
  {
    id: 3,
    text: "Склад запчастей укомплектованный всем необходимым для ремонта Айпад Мини 3.",
    number: "03",
  },
  {
    id: 4,
    text: "Бесплатная диагностика и гарантия на все работы сроком до 12-и месяцев.",
    number: "04",
  },
];

const textOfIpadMini3 = [
  {
    id: 1,
    title: "Преимущества ремонта iPad Mini 3 в Мак Профи",
    text: <RepairAppleTextWithCheckMark array={textIpadMini3} />,
  },
  {
    id: 2,
    title: "Как происходит ремонт iPad Mini 3 в Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Вы или приезжаете в один из наших офисов в Москве, или же вызываете
          нашего курьера на дом.
        </p>
        <p className="repair-iphone-display__paragraph">
          Следующий этап - полная диагностика вашего Айпэда. Данная услуга
          совершенно бесплатная. После выявления и локализации всех
          неисправностей, мы известим о них вас, и определим сроки и стоимость
          необходимых работ. Далее начинается этап собственно ремонта. Как
          правило, большинство работ наши сотрудники совершают за 20 минут -
          полчаса. После устранения всех поломок и тестирования, iPad
          возвращается владельцу, вместе с гарантией на год. Таким образом, уже
          в тот же день, вы уходите от нас счастливым обладателем исправного,
          "как нового" iPad Mini 3.
        </p>
        <p className="repair-iphone-display__paragraph">
          Специфика ремонта iPad Mini 3.
        </p>
        <p className="repair-iphone-display__paragraph">
          Когда iPad Mini 3 впервые появился на рынке его встретило много
          нареканий по поводу того, что в планшете не изменилось практически
          ничего помимо появления функции "Touch ID". Собственно, это
          единственное изменение и сказалось на специфике его ремонта. Защищая
          планшет от взлома, Apple решили припаять кнопку к процессору. Её
          простая замена, таким образом, грозит полной утратой возможности
          разблокировки устройства с помощью отпечатка пальца. Так что поломка
          кнопки "Home" перешла в разряд серьёзных, требующих обстоятельного
          ремонта.
        </p>
      </>
    ),
  },
];

const RepairIpadModelPagesIpadMini3 = () => {
  return (
    <Page title={"Ремонт iPad Mini 3 в Москве с гарантией профессионалами!"}>
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/mini-3"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Весь спектр услуг по ремонту Айпада Мини 3 по доступным ценам высококвалифицированными специалистами в Москве и все России!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPad Mini 3"
          alt="Ремонт iPad Mini 3"
          titleImg="Ремонт iPad Mini 3"
          text='Разбитый, щедро залитый влагой или просто "глючащий" iPad Mini 3, еще не повод изучать ценники на новое устройство. Ремонт Айпэда Мини 3, будучи доверенным профессионалам, может легко решить большинство подобных проблем и сэкономить семейный бюджет.'
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpadMini3}
            title="Прайс лист с ценами на ремонт iPad Mini 3"
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpadMini3.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpadMini3;
