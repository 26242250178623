import react from "react";
import Section from "../Section/Section";

const AboutCompanyTrust = () => {
  return (
    <Section className='ac-trust__content'>
      <div className='ac-trust__container'>
        <div className='ac-trust__border'></div>
        <p className='ac-trust__text'>Обратившись в Мак Профи, вы можете быть уверены, что Ваша техника
  будет ремонтироваться профессионалами.</p>
        <p className='ac-trust__text'>Мы ценим Ваше доверие и всегда рады новым клиентам!</p>
      </div>
    </Section>
  )
};

export default AboutCompanyTrust;
