import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileCallback.module.css";
import iconTel from "../../../images/icons/center-mobile-icon-tel.svg";
import { Link, animateScroll as scroll } from "react-scroll";

const reasons = [
  "Телефон плохо заряжается, а зарядки хватает только на пару часов?",
  "Вы не слышите, что говорит собеседник, или он не слышит вас?",
  "Часто зависает и уходит в перезагрузку?",
  "Всё это стандартные неисправности, с которыми мы сталкиваемся каждый день.",
  "Доверьтесь нам – специалисты Центр Мобайл отремонтируют ваш смартфон быстро и качественно!",
];

function CenterMobileIRepairMobileCallback() {
  return (
    <Section className={styles.callbackSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          Повредили дисплей, и перестал работать тачскрин?
        </h2>
        <ul className={styles.list}>
          {reasons.map((item, i) => {
            return (
              <li key={i} className={styles.item}>
                <div className={styles.circle}></div>
                <p className={styles.text}>{item}</p>
              </li>
            );
          })}
        </ul>
        <div className={styles.wrapper}>
          <ul className={styles.listLinks}>
            <li className={styles.linkItem}>
              <img
                className={styles.iconTel}
                src={iconTel}
                alt=" иконка телефона"
              />
              <a href="tel:+74953745481" className={styles.link}>
                +7(495)374-54-81
              </a>
            </li>
            <li className={styles.linkItem}>
              <img
                className={styles.iconTel}
                src={iconTel}
                alt=" иконка телефона"
              />
              <a href="tel:+74955405067" className={styles.link}>
                +7(495)540-50-67
              </a>
            </li>
          </ul>
          <Link
            className={styles.button}
            to={"orderForm"}
            smooth={true}
            duration={1500}
            offset={50}
            delay={200}
          >
            Мы Вам перезвоним!
          </Link>
        </div>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobileCallback;
