import React from "react";
import cn from "classnames";
import "./Preloader.css";

const Preloader = ({ overlay = false }) => {
  return (
    <div className={cn("preloader", overlay && "preloader_overlayed")}>
      <div className="preloader__item"></div>
    </div>
  );
};

export default Preloader;

// you can add className preloader_overlayed for spinning up the screen
