import React, { useState } from "react";
import line from "../../../images/arrows/note-expert-work-line.svg";
import arrowUp from "../../../images/arrows/note-expert-work-arrowup.svg";
import arrowDown from "../../../images/arrows/note-expert-work-arrowdown.svg";
import styles from "./NoteExpertGeneralPageWorkSliderMobile.module.css";

function NoteExpertGeneralPageWorkSliderMobile({ children, stages }) {
  const [offset, setOffset] = useState(0);

  const handleLeftArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + 368;

      return Math.min(newOffset, 0);
    });
    console.log(offset);
  };

  const handleRightArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - 368;

      const maxOffset = -(368 * (children.length - 1));

      return Math.max(newOffset, maxOffset);
    });
    console.log(offset);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons}>
        {offset === 0 ? (
          <img src={line} alt="начало слайдера" />
        ) : (
          <img
            onClick={handleLeftArrowClick}
            src={arrowUp}
            alt="стрелка вниз"
          />
        )}
        {-(368 * stages.length - 368) === offset ? (
          <img src={line} alt="конец слайдера" />
        ) : (
          <img
            onClick={handleRightArrowClick}
            src={arrowDown}
            alt="стрелка вправо"
          />
        )}
      </div>
      <ul
        style={{
          transform: `translateY(${offset}px)`,
        }}
        className={styles.list}
      >
        {children}
      </ul>
    </div>
  );
}

export default NoteExpertGeneralPageWorkSliderMobile;
