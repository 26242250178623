import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PopupEditUser, PopupConfirm, Preloader } from "../components";
import { useUser } from "../providers/UserProvider";
import { usersApi } from "../utils/api";
import { pencilIcon, trashIcon, undoIcon, userIDIcon } from "./newIcons/index";
import usePopup from "../hooks/usePopup";

export default function AdminUserDetail() {
  const { id } = useParams();
  const { user } = useUser();
  const [userbyId, setUserById] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const { isPopupOpen, togglePopup, closePopup } = usePopup();
  const {
    isPopupOpen: isChangePopupOpen,
    togglePopup: toggleChangePopup,
    closePopup: closeChangePopup,
  } = usePopup();

  useEffect(() => {
    const fetchUserById = async () => {
      setLoading(true);

      try {
        const userbyId = await usersApi.getUserById(id);
        setUserById(userbyId);

        setTimeout(() => setLoading(false), 1200);
      } catch (error) {
        setIsError(true);
        setTimeout(() => setLoading(false), 1200);
      }
    };
    fetchUserById();
  }, [id]);

  const handleChangeUser = async (login, email, name, role) => {
    const thisUser = await usersApi.getUserById(id);
    const editedUser = await usersApi.changeUserData(thisUser._id, {
      login: login,
      email: email,
      name: name,
      role: role,
    });
    setUserById(editedUser);

    // Update data on page !!!!!!!!!
  };

  const handleDeleteUser = async () => {
    const thisUser = await usersApi.getUserById(id);

    await usersApi.deleteUser(thisUser._id);
    setIsDeleted(true);
  };

  const handleSubmit = () => {
    handleDeleteUser();
    closePopup();
  };

  const alertMessage =
    id === user._id
      ? "Данное действите приведет к удалению вашего аккаунта и немедленному выходу из системы без возможности повторной авторизации"
      : "";

  return (
    <section className="info">
      <div className="info__container">
        {isError && !isDeleted && (
          <p className="info__item info__deleted">
            Пользователь с данным id не найден
          </p>
        )}
        {user.role !== "admin" ? (
          <p className="info__item">
            Данный раздел недоступен. Уточните права доступа у администратора
          </p>
        ) : (
          <>
            {loading ? (
              <Preloader />
            ) : (
              <>
                <ul className="info__card">
                  <li className="info__li">
                    <div className="info__wrapper">
                      <img
                        src={userIDIcon}
                        className="info__icon"
                        alt="иконка профиля"
                      />
                      <p className="info__item">id: {id}</p>
                    </div>
                    <Link className="info__link" to="/admin-page/users/">
                      <img
                        src={undoIcon}
                        alt="иконка возвращения в предыдущий раздел"
                        className="info__icon"
                      />
                      назад ко всем пользователям
                    </Link>
                  </li>

                  {isDeleted ? (
                    <>
                      <p className="info__item">
                        Пользователь с id: {id} был успешно удален
                      </p>
                      <Link className="info__delited" to="/admin-page/users/">
                        Вы можете вернуться ко всем пользователям
                      </Link>
                    </>
                  ) : (
                    <>
                      {userbyId && (
                        <li className="info__li">
                          <button
                            className="info__button"
                            onClick={toggleChangePopup}
                            aria-label="изменить логин"
                          >
                            <img
                              src={pencilIcon}
                              className="info__icon"
                              alt="иконка кнопки редактирования"
                            />
                          </button>
                          <p className="info__item">login: {userbyId.login}</p>
                        </li>
                      )}

                      {userbyId && (
                        <li className="info__li">
                          <div className="info__space"></div>
                          <p className="info__item">email: {userbyId.email}</p>
                        </li>
                      )}

                      {userbyId && (
                        <li className="info__li">
                          <div className="info__space"></div>
                          <p className="info__item">имя: {userbyId.name}</p>
                        </li>
                      )}

                      {userbyId && (
                        <li className="info__li">
                          <div className="info__space"></div>
                          <p className="info__item">
                            должность: {userbyId.role}
                          </p>
                        </li>
                      )}
                    </>
                  )}
                </ul>
                {!isError && !isDeleted && (
                  <button
                    className="info__button"
                    onClick={togglePopup}
                    aria-label="удалить пользователя"
                  >
                    <img
                      src={trashIcon}
                      className="info__icon"
                      alt="иконка удаления профиля"
                    />
                    <p className="info__item">удалить пользователя</p>
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
      <PopupEditUser
        isOpenPopup={isChangePopupOpen}
        onClose={closeChangePopup}
        handleChangeUserData={handleChangeUser}
      />
      <PopupConfirm
        message={alertMessage}
        isOpenPopup={isPopupOpen}
        onClose={closePopup}
        handleSubmit={handleSubmit}
      />
    </section>
  );
}
