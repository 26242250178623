import React from "react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import Section from "../Section/Section";
import iconFirst from "../../images/icons/delivery-banner-icon1.svg";
import iconSecond from "../../images/icons/delivery-banner-icon2.svg";
import iconThird from "../../images/icons/delivery-banner-icon3.svg";
import iconFourth from "../../images/icons/delivery-banner-icon4.svg";
import iconFifth from "../../images/icons/delivery-banner-icon5.svg";
import deliveryBanner from "../../images/banners/delivery_page_banner_mobile.png";

const deliveryInfoList = [
  {
    id: 1,
    image: iconFirst,
    text: "Бесплатная доставка по всей России",
  },
  {
    id: 2,
    image: iconSecond,
    text: "Диагностика в течении 1 - 3 часов",
  },
  {
    id: 3,
    image: iconThird,
    text: "Оплата онлайн",
  },
  {
    id: 4,
    image: iconFourth,
    text: "Гарантия на осуществленный ремонт до 12 месяцев",
  },
  {
    id: 5,
    image: iconFifth,
    text: "Ремонт любого уровня сложности",
  },
];

const DeliveryBannerMobile = () => {
  return (
    <Section>
      <div className="delivery-banner-mobile__content">
        <img
          src={deliveryBanner}
          alt=""
          className="delivery-banner-mobile__banner"
        />
        <BreadCrumbs />
        <ul className="delivery-banner-mobile__list">
          {deliveryInfoList.map((item) => {
            return (
              <li key={item.id} className="delivery-banner-mobile__item">
                <img src={item.image} alt="" />
                <p className="delivery-banner-mobile__text">{item.text}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default DeliveryBannerMobile;
