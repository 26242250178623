import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPagePrice.module.css";

const notePrice = [
  {
    text: "Диагностика неисправности",
    price: "Бесплатно",
  },
  {
    text: "Консультация экспертов",
    price: "Бесплатно",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Удаление вирусов",
    price: <>от 500 &#8381;</>,
  },
  {
    text: "Замена комплектующих",
    price: <>от 700 &#8381;</>,
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена матрицы",
    price: <>от 1500 &#8381;</>,
  },
  {
    text: "Ремонт материнской платы",
    price: <>от 700 &#8381;</>,
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function NoteExpertGeneralPagePrice() {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>цены на ремонт ноутбуков в note expert</h2>
        <div className={styles.header}>
          <p className={styles.headerText}>УСЛУГИ</p>
          <p className={styles.headerText}>ЦЕНА</p>
        </div>
        <ul className={styles.list}>
          {notePrice.map((item, i) => {
            return (
              <li
                key={i}
                style={{
                  backgroundColor: item.color,
                  boxShadow: item.boxShadow,
                }}
                className={styles.item}
              >
                <p className={styles.priceText}>{item.text}</p>
                <b className={styles.pricePrice}>{item.price}</b>
              </li>
            );
          })}
        </ul>
        <div className={styles.border}></div>
        <p className={styles.paragraph}>* Все цены указаны в рублях РФ.</p>
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPagePrice;
