import React from "react";
import { RepairIwatchModelsLinksMobile } from "../index";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import Section from "../Section/Section";
import { useMobileScreen } from "../../utils/useMedia";
import { Link } from "react-router-dom";

const iWatchListOfModels = [
  {
    model: "Ремонт Apple Watch",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 1",
    href: "/remont-apple/remont_apple_watch/series-1",
  },
  {
    model: "Ремонт Apple Watch Series 2",
    href: "/remont-apple/remont_apple_watch/series-2",
  },
  {
    model: "Ремонт Apple Watch Series 3",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 4",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 5",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 6",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series SE",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 7",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Nike",
    href: "/remont-apple/remont_apple_watch/nike",
  },
  {
    model: "Ремонт Apple Watch Edition",
    href: "/remont-apple/remont_apple_watch/edition",
  },
  {
    model: "Ремонт Apple Watch Sport",
    href: "/remont-apple/remont_apple_watch/sport",
  },
];

function PageLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairIwatchModelsLinksMobile />;
  }
  return (
    <Section className="repair-iphone__models-links-container">
      <div className="repair-iphone__models-links-wrapper">
        <h2 className="repair-iphone__models-links-title">
          Больше детальной информации по каждой модели:
        </h2>
        <ul
          className="repair-iphone__models-links-grid"
          style={{ gridTemplateAreas: "col-1 col-2" }}
        >
          {iWatchListOfModels.map((link, i) => {
            return (
              <li key={i} className="repair-iphone__models-links-item">
                <img
                  src={IconCheckMark}
                  alt="иконка галочки"
                  className="repair-iphone__models-links-img"
                />
                <Link
                  className="repair-iphone__models-links-link"
                  to={link.href}
                >
                  {link.model}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

const RepairIwatchModeslLinks = () => {
  return PageLinks();
};

export default RepairIwatchModeslLinks;
