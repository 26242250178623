import React from "react";
import {
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
} from "../index";

const text = [
  {
    id: 1,
    text: "Сдача в ремонт Mac Mini. В данном случае вы можете сдать ваш Mac Mini в ремонт любым удобным для вас способом: в сервисном центре, курьерской службой сервисного центра.",
    number: "01",
  },
  {
    id: 2,
    text: "Согласование стоимости ремонта. После диагностики менеджер оповещает клиента о стоимости и сроках ремонта. На данном этапе клиент принимает решение о целесообразности ремонта. Ваше решение вы можете сообщить любым доступным способом, по телефону, по электронной почте, а также лично присутствуя в сервисном центре.",
    number: "02",
  },
  {
    id: 3,
    text: "Выдача восстановленного Mac Mini клиенту. Проверка качества работы клиентом, оплата заказа. Оплату восстановленной техники вы можете произвести: наличными в офисе, наличными курьеру, банковской картой в офисе, онлайн в личном кабинете, со счета юридического лица, банковским переводом.",
    number: "03",
  },
];

const textWithCkeckMark = [
  {
    id: 1,
    title: "Никаких забот, ремонт вашего Мак Мини в 3 шага",
    text: (
      <RepairAppleTextWithCheckMark
        paragraphUp={<>3 простых шага и ваш Mac Mini как новый:</>}
        array={text}
        paragraphDown={
          <>
            Профессиональный подход к полному восстановлению ваших Mac Mini наша
            задача. Мы всегда рады делать свою работу качественно,
            профессионально и с максимальным удобством для клиента. Будем рады
            помочь и восстановить ваш Mac Mini!
          </>
        }
      />
    ),
  },
];

const textWithoutChechMark = [
  {
    id: 1,
    title: "Как и какими средствами мы производим ремонт Mac Mini",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          При поступлении Mac Mini в сервисный центр, производится детальный
          опрос клиента на предмет неисправности. После оформления заказа на
          диагностику, Mac Mini поступает специалистам на тщательное
          тестирование.
        </p>
        <p className="repair-iphone-display__paragraph">
          При программной неисправности. Производится полная проверка
          программного обеспечения, проверка поверхности накопителя. Как правило
          в 80% случаев при диагностике и исправлении программных ошибок, Mac
          Mini работает корректно и вам не приходится оплачивать стоимость
          ремонта. В случае отказа от ремонта, для клиента диагностика
          бесплатная.
        </p>
        <p className="repair-iphone-display__paragraph">
          Проблема с аппаратным обеспечением. В данном случае речь идет о
          поломках, которые вызваны отказам в работоспособности аппаратного
          обеспечения. Производится тщательное тестирование Мак Мини на
          аппаратном стенде. При выявлении кодов ошибок, специалист точно
          выявляет причину неисправности. После согласовании ремонта с клиентом,
          приступает к устранению причин неисправности. При аппаратном ремонте
          Мак Мини наши специалисты используют высокотехнологичное оборудование,
          автоматизированные паяльные станции.
        </p>
        <p className="repair-iphone-display__paragraph">
          После произведенного ремонта, Apple Mac Mini поступает в отдел
          контроля качества, где в течении суток происходит полное аппаратное
          тестирование, после прохождения отдела контроля качества, аппарат
          поступает на склад готовой продукции, клиент оповещается о завершении
          ремонта.
        </p>
      </>
    ),
  },
];

const RepairMacMiniText = () => {
  return (
    <>
      <Section className="section_group">
        {textWithoutChechMark.map((item) => {
          return (
            <RepairAppleLinksText
              key={item.id}
              title={item.title}
              text={item.text}
            />
          );
        })}
      </Section>
      <Section className="section_group" style={{ paddingBottom: 65 }}>
        {textWithCkeckMark.map((item) => {
          return (
            <RepairAppleLinksText
              key={item.id}
              title={item.title}
              text={item.text}
            />
          );
        })}
      </Section>
    </>
  );
};

export default RepairMacMiniText;
