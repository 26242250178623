import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIwatchModeslLinks,
} from "../index";
import banner from "../../images/banners/iwatchEdition.jpeg";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Грамотные специалисты – в штате компании только лучшие инженеры, у которых есть опыт работы с различными типами устройств.",
    number: "01",
  },
  {
    id: 2,
    text: "Услуга доставки – курьер прибудет на дом или на работу, чтобы забрать, а потом привезти обратно восстановленное устройство. Это предложение актуально и для Москвы, и для регионов – в столице вы можете воспользоваться им абсолютно бесплатно.",
    number: "02",
  },
  {
    id: 3,
    text: "Выгодные расценки – мы установили конкурентоспособные цены на все группы услуг, чтобы вы могли получить качественный ремонт, не переплачивая при этом.",
    number: "03",
  },
];

const textIwatchEdition = [
  {
    id: 1,
    title: "Ремонт Apple Watch Edition: качество гарантировано",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Мы работаем с различными видами техники Apple, предлагая широкий
          спектр услуг для любой модели. Вы можете обратиться к нам, чтобы
          воспользоваться помощью в настройке гаджета, его перепрошивке,
          установке необходимого программного обеспечения. При посещении
          сервиса, мастера проводят диагностику, оценивая характер и серьезность
          возникшей неисправности.
        </p>
        <p className="repair-iphone-display__paragraph">
          Предварительное тестирование определяет ход будущего ремонта Apple
          Watch Edition, ведь многие поломки взаимосвязаны друг с другом.
          Например, если своевременно не заменить неисправный аккумулятор,
          конструкция начнет перегреваться, что приводит к выходу из строя
          расположенных поблизости плат. Поэтому не стоит откладывать посещение
          сервиса – в результате, слишком дорого может обойтись подобное
          промедление.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title:
      "Квалифицированная помощь и быстрый ремонт Apple Watch Edition в Москве",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Почему именно Мак Профи? Потому что это специализированный сервис, который дорожит своей репутацией. Мы используем современное оборудование и качественные инструменты, ведь ремонт Apple Watch Edition требует максимальной аккуратности. Вы получите профессиональную помощь, ведь у нас:"
          array={listOfTrouble}
          paragraphDown="Мы подготовили целый ряд преимуществ каждому клиенту – свяжитесь с нами, чтобы подробнее узнать о действующих специальных предложениях. А лучше, сразу приезжайте в центр, чтобы наши специалисты проверили диагностику устройства и назвали точную стоимость и сроки ремонта."
        />
      </>
    ),
  },
];

const RepairIwatchModelLinkEdition = () => {
  return (
    <Page title="Быстрый ремонт Apple Watch Edition">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont_apple_watch/edition"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Профессиональный ремонт Apple Watch Edition: проводим работы при клиенте."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="ремонт apple watch edition"
          titleImg="ремонт apple watch edition"
          banner={banner}
          title={<>Ремонт Apple&nbsp;Watch&nbsp;Edition</>}
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ремонт Apple Watch Edition в сервисе Мак Профи: новые технологии
                и современное оборудование. Только лучшие комплектующие от
                крупнейших поставщиков. Гарантия.
              </p>
              <p className="repair-iphone-display__paragraph">
                Apple Watch Edition – изысканная модель в потрясающе красивом
                корпусе. Благодаря отменной прочности используемых материалов,
                подобная поверхность легко выдержит даже сильные удары, что
                делает её не только практичной, но и долговечной. Но, несмотря
                на отличную стойкость к внешним воздействиям, всё равно
                существует риск повреждения внутренних элементов конструкции.
                Если функциональность гаджета была нарушена, привозите его в
                сервисный центр Мак Профи – и мы устраним неисправность.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIwatchEdition.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIwatchModeslLinks />
      </main>
    </Page>
  );
};

export default RepairIwatchModelLinkEdition;
