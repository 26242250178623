import React from "react";

const AdvantageCard = ({ src, title, text, alt, titleImg }) => {
  return (
    <div className="item">
      <img className="item__icon" src={src} alt={alt} title={titleImg} />
      <h3 className="item__title">{title}</h3>
      <p className="item__text">{text}</p>
    </div>
  );
};

export default AdvantageCard;
