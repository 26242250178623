import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Ordering = {
  ASC: "ascending",
  DESC: "descending",
};

export const Table = ({
  title,
  columns = [],
  data = [],
  actions = [],
  rowsPerPage = 5,
  totalDataCount = data.length,
  onPageChange = (page) => {},
  onSearchChange = (search) => {},
  onSortChange = (field, direction) => {},
  errorMessage = "",
}) => {
  const idColumns = columns.filter((column) => column.id);
  if (idColumns.length !== 1) {
    throw new Error('Table must have one column with "id": true');
  }
  const idColumn = idColumns[0];

  const visibleColumns = columns.filter((column) => column.name);

  const [checkedRowsIds, setCheckedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorted, setSorted] = useState({
    column: columns[1],
    direction: Ordering.ASC,
  });

  const tableState = {
    isChecked: checkedRowsIds.length !== 0,
    selectedRows: data.filter((row) =>
      checkedRowsIds.includes(idColumn.selector(row))
    ),
    uncheckSelectedRows() {
      setCheckedRows([]);
    },
  };

  const navigate = useNavigate();

  const isFirstRenderRef = useRef(true);
  const isFirstSortRenderRef = useRef(true);
  const onPageChangeRef = useRef(onPageChange);
  const onSortChangeRef = useRef(onSortChange);

  useEffect(() => {
    onPageChangeRef.current = onPageChange;
  }, [onPageChange]);

  useEffect(() => {
    onSortChangeRef.current = onSortChange;
  }, [onSortChange]);

  useEffect(() => {
    if (!isFirstSortRenderRef.current) {
      onSortChangeRef.current(sorted.column.field, sorted.direction);
    }

    isFirstSortRenderRef.current = false;
  }, [sorted]);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      onPageChangeRef.current(currentPage);
    }
    navigate(`?page=${currentPage}`, { replace: true });
    isFirstRenderRef.current = false;
  }, [navigate, currentPage]);

  const lastItemIndex = currentPage * rowsPerPage;
  const firstItemIndex = lastItemIndex - rowsPerPage;

  const isAllDataAvailable = data.length === totalDataCount;
  const paginatedData = isAllDataAvailable
    ? data.slice(firstItemIndex, lastItemIndex)
    : data;
  const totalPages = Math.ceil(totalDataCount / rowsPerPage);

  const renderRowsCounter = () => {
    const firstCounterNumber = firstItemIndex + 1;
    const secondCounterNumber =
      currentPage === totalPages ? totalDataCount : lastItemIndex;

    if (firstCounterNumber === secondCounterNumber) {
      return `${secondCounterNumber} из ${totalDataCount}`;
    } else {
      return `${firstCounterNumber} - ${secondCounterNumber} из ${totalDataCount}`;
    }
  };

  return (
    <div>
      <div className="action__buttons-header">
        <div className="action__buttons-wrapper">
          <div className="action__buttons">
            {actions.map((action) => {
              const isDisabled = action.disabled?.(tableState);

              return (
                <button
                  key={action.name}
                  onClick={() => action.onClick?.(tableState)}
                  className={cn(
                    "table__btn",
                    `table__btn_type_${action.type ?? "default"}`,
                    isDisabled && "table__btn_type_disabled"
                  )}
                  disabled={isDisabled}
                >
                  <img
                    alt="иконка кнопки"
                    className="table__icon"
                    src={action.icon}
                  />
                  {action.name}
                </button>
              );
            })}
          </div>
          <form className="table__wrapper">
            <input
              type="search"
              required={true}
              className="table__search"
              placeholder="Поиск..."
              aria-label="Поиск"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <button
              className="table__search-clear"
              type="reset"
              onClick={onSearchChange}
            />
          </form>
        </div>
        <p className="pagination-counter">
          <span className="pagination-sign">Показано </span>
          {renderRowsCounter()}
        </p>
      </div>

      <table className="table">
        <caption className="table__caption">{title}</caption>
        <thead>
          <tr>
            <th scope="col" className="table__heading">
              <input
                type="checkbox"
                className="table__checkbox"
                disabled={paginatedData.length === 0 ? true : false}
                onChange={(e) =>
                  setCheckedRows(
                    e.target.checked
                      ? data.map((row) => idColumn.selector(row))
                      : []
                  )
                }
              />
            </th>
            {visibleColumns.map((column) => (
              <th key={column.name} scope="col" className="table__heading">
                <button
                  className="table__sort-button"
                  type="button"
                  onClick={() => {
                    setSorted((prevSorted) => {
                      const newDirection =
                        prevSorted.direction === Ordering.ASC
                          ? Ordering.DESC
                          : Ordering.ASC;
                      return { direction: newDirection, column };
                    });
                  }}
                >
                  {column.name}
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table__body">
          {errorMessage.length > 0 && (
            <NavLink to={`/users`}>
              <tr className="table__row">
                <td colSpan="5" className="table__alert">
                  {errorMessage}
                </td>
              </tr>
            </NavLink>
          )}
          {paginatedData.map((row, index) => {
            const handleCheckBoxChange = (e) => {
              const newCheckedRows = e.target.checked
                ? [...checkedRowsIds, idColumn.selector(row)]
                : checkedRowsIds.filter(
                    (rowId) => rowId !== idColumn.selector(row)
                  );
              setCheckedRows(newCheckedRows);
            };

            return (
              <tr key={index} className="table__row">
                <td className="table__data">
                  <input
                    type="checkbox"
                    className="table__checkbox"
                    checked={checkedRowsIds.includes(idColumn.selector(row))}
                    onChange={handleCheckBoxChange}
                  />
                </td>
                {visibleColumns.map((column) => (
                  <td
                    className="table__data"
                    key={column.name + idColumn.selector(row)}
                  >
                    {column.render ? column.render(row) : column.selector(row)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {totalDataCount > rowsPerPage && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-nav">
      <button
        onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
        className="pagination-link"
        aria-label="перейти на предыдущую страницу"
        disabled={currentPage === 1}
      >
        &lt;
      </button>

      <nav className="info__pages">
        {pageNumbers.map((number) => (
          <Link
            key={number}
            onClick={() => setCurrentPage(number)}
            to={`?page=${number}`}
            className="pagination-link"
            aria-label={`перейти на страницу ${number}`}
          >
            {number}
          </Link>
        ))}
      </nav>

      <button
        className="pagination-link"
        onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
        aria-label="перейти на следующую страницу"
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Table;
