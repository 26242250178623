import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadAir.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Поступление устройства на диагностику и анализ полученной информации",
    number: "01",
  },
  {
    id: 2,
    text: "Устранение недочетов и замена корпуса iPad Air",
    number: "02",
  },
  {
    id: 3,
    text: "Возврат планшета владельцу и получение оплаты за выполненные услуги.",
    number: "03",
  },
];

const textOfCaseAir = [
  {
    id: 1,
    title: "Замена корпуса iPad Air в сервисе Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Обращаясь в сервисный центр, вам не придется беспокоиться о проведении ремонта – мы гарантируем профессиональное выполнение поставленной задачи. Работа будет проведена непосредственно при клиенте, благодаря чему вы сможете проконтролировать процесс. Процедура включает в себя несколько основных этапов:"
          array={listOfTrouble}
          paragraphDown="Мы используем в работе только качественные задачи, ведь для нас важно доверие клиентов. С новым корпусом устройство будет выглядеть, словно только после покупки, и вы сможете наслаждаться комфортной работой планшета. Рекомендуем приобрести качественный чехол, который поможет предотвратить подобные повреждения в дальнейшем."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сроки замены корпуса iPad Air",
    text: "Все запчасти уже в наличии, благодаря чему замена корпуса iPad Air будет выполнена непосредственно в день обращения. Вам не придется долго ждать, напрасно тратя свое время – мы позаботимся о том, чтобы в сжатые сроки выполнить необходимые работы и вернуть устройство владельцу.",
  },
];

const RepairIpadServiceLinkCaseModelAir = () => {
  return (
    <Page title="Оперативная замена корпуса Apple iPad Air в сервисе Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/air"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Oh!MyGadget!: быстрая замена корпуса iPad Air, оригинальные комплектующие, бесплатная доставка, услуга курьерской доставки."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена корпуса iPad Air"
          titleImg="Замена корпуса iPad Air"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Air</>}
          text="iPad Air – многофункциональный планшет с красивым и необычным дизайном. Вот только новинка от Apple обладает существенным недостатком, свойственным её предшественникам – она по-прежнему довольно хрупкая и плохо переносит механические воздействия. Даже падение с небольшой высоты может стать причиной повреждения стекла, тачскрина и уж тем более корпуса, но любой из этих недочетов будет устранен специалистами Мак Профи."
        />
        <Section className="section_group">
          {textOfCaseAir.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены корпуса на iPad Air"
          text="Замена сенсорного стекла (тачскрина)"
          price="7900 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Стоимость ремонта зависит от сложности поломки, а также цены
                запчастей, которые были заменены в процессе восстановления. Если
                повреждения минимальны, мастер ограничиться рихтовкой корпуса,
                но в большинстве случаев этого недостаточно – деформированная
                поверхность создаст давление на внутренние части конструкции,
                что и приведет к возникновению сбоев в работе устройства. Наши
                специалисты подберут наиболее выгодные и рациональные способы
                решения возникшей проблемы.
              </p>
              <p className="repair-iphone-display__paragraph">
                Нас ценят за оперативность и индивидуальную работу с каждым
                клиентом. Вы получите все необходимые услуги на выгодных
                условиях, а потому не стоит пытаться справиться с ремонтом
                самостоятельно – в Москве вы можете обратиться в Мак Профи,
                восстановив нормальную работу и безупречный вид планшета без
                дополнительных финансовых затрат.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelAir;
