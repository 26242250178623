import React from "react";
import visa from "../../images/icons/delivery-visa-mobile.svg";
import mastercard from "../../images/icons/delivery-mastercard-mobile.svg";
import mir from "../../images/icons/delivery-mir-mobile.svg";
import bitcoin from "../../images/icons/delivery-bitcoin-mobile.svg";

const icons = [visa, mastercard, mir, bitcoin];

const DeliveryPayment = () => {
  return (
    <div className="payment">
      <h4 className="payment__title">Мы принимаем к оплате</h4>
      <ul className="payment__list">
        {icons.map((icon, i) => {
          return (
            <li key={i}>
              <img src={icon} alt="иконка варианта оплаты" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DeliveryPayment;
