import React from "react";
import { BlockTitle, DeliveryPayment, Section } from "../../components/index";
import { box as boxMobile } from "../../images/index";

const textInfoMobile = [
  {
    text: "Срок осуществления доставки вы можете посмотреть, выбрав ваш город из списка. Курьерская доставка осуществляется непосредственно от клиента в сервисный центр и из сервисного центра непосредственно в руки клиенту. Вся техника перед отправкой в сервисный центр и из сервисного центра упаковывается для ее сохранности. Упаковка техники включена в стоимость доставки.",
    class: "delivery-info-mobile__paragraph-border",
  },
  {
    text: "Для оформления доставки вашей техники, пожалуйста, заполните форму доставки. Доставку вы можете оплатить как наличными курьеру (для Москвы), так и банковской картой на сайте при оформлении (для Москвы и из городов России). При оформлении доставки выберите приемлемый для вас вариант.",
    class: "delivery-info-mobile__paragraph-border",
  },
  {
    text: "Доставка в сервисный центр оформляется через форму доставки с сайта. Доставка из сервисного центра клиенту оформляется через личный кабинет клиента. Данные доступа в личный кабинет вы получаете при создании заказа (после получения техники сервисным центром от клиента) в виде смс.",
    class: "delivery-info-mobile__paragraph-border",
  },
  {
    text: "Оплату за ремонт техники вы можете произвести из личного кабинета банковской картой на сайте, а также безналичным расчетом.",
    class: "delivery-info-mobile__paragraph-border",
  },
  {
    text: "Уважаемые клиенты, мы стремимся сделать наш сервис максимально удобным для вас, будем рады помочь вам решить любые проблемы с вашей техникой!",
    class: "",
  },
];

const DeliveryInfoMobile = () => {
  return (
    <Section>
      <article className="delivery-info-mobile__container">
        <BlockTitle
          title="Подробная информация о доставке"
          className="delivery-info-mobile__title"
        />
        <ul className="delivery-info-mobile__list">
          {textInfoMobile.map((text, i) => {
            return (
              <li key={i} className="delivery-info-mobile__item">
                <p className="delivery-info-mobile__paragraph">{text.text}</p>
                <div className={text.class}></div>
              </li>
            );
          })}
        </ul>
        <img
          className="delivery-info-mobile__image"
          src={boxMobile}
          alt="фоновое изображение с коробкой"
        />
        <DeliveryPayment />
      </article>
    </Section>
  );
};

export default DeliveryInfoMobile;
