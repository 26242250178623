import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone8.png";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfModel8 = [
  {
    id: 1,
    title: "Проблемы и ремонт iPhone 8 в Москве",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Данная модель имеет собственные специфические зоны риска – среди них
          оказался и экран. Он имеет крупные размеры и практически лишен рамки,
          и потому если техника используется без чехла и защиты, действительно
          есть риск повреждения. Новый тип дисплея, уменьшенное
          энергопотребление и другие особенности устройства делают его более
          уязвимым и сложным в ремонте, и потому ремонтировать новинку бывает не
          просто – даже на этапе поиска мастера вас могут поджидать затруднения.
          Но если вы все же желаете решить возникшую проблему оптимальным
          образом – все возможности для этого у вас существуют. Вы можете
          заказать полный спектр необходимых услуг в нашей компании и получить
          прекрасный результат с длительной гарантией – вам не придется снова
          обращаться за помощью по тем же проблемам, а техника прослужит еще
          много лет.
        </p>
        <p className="repair-iphone-display__paragraph">
          Наши специалисты успешно справляются с проблемами любой сложности и
          всегда обеспечивают результативный ремонт. У нас работают только
          профессионалы с профильным образованием и опытом – они знают все даже
          о самых новых Айфонах, и потому с легкостью диагностируют и устраняют
          проблемы. У нас созданы идеальные условия для результативного труда,
          каждое рабочее место имеет самое современное оснащение, что позволяет
          успешно реализовывать работы даже ювелирной сложности. А кроме того, в
          наличии постоянно имеется полный перечень необходимых запчастей, что
          существенно ускоряет процесс выполнения работ. Мы используем только
          качественные запчасти заводского происхождения, даем на них гарантию.
          А заказчик может находиться у нас на момент выполнения ремонтных работ
          – все действия мастеров открыты.
        </p>
        <p className="repair-iphone-display__paragraph">
          Первым делом у нас проводится бесплатная диагностика, которая
          позволяет в точности узнать, в чем заключается причина неисправности.
          Далее, узнав цену и условия, вы можете согласиться на ремонт или
          отказаться. При вашем согласии мастера оперативно начнут свою
          деятельность, и уже в ближайшие часы вы сможете снова наслаждаться
          беспроблемной эксплуатацией устройства. Обращайтесь к нам за ремонтом!
        </p>
      </>
    ),
  },
];

const price8 = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage8 = () => {
  return (
    <Page title="Ремонт iPhone 8, стоимость ремонта Айфон 8 в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/8"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если вам потребовался ремонт iPhone 8, профессиональный подход будет наилучшим выбором. Мастерская Oh!MyGadget! обеспечит качественное выполнение сложнейших работ."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 8"
          titleImg="Ремонт iPhone 8"
          banner={banner}
          title="Ремонт iPhone 8"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Новейший iPhone 8 начал продаваться в 2017 году, а
                следовательно, все аппараты, которые имеются сегодня на руках у
                владельцев, являются новыми. Приобретения этих образцов желали
                многие, так как они действительно обладают массой преимуществ
                перед большинством предшественников, и компания приурочила
                выпуск этой модели к своему десятилетию вовсе не случайно.
                Однако это не исключает риска их поломки - ремонт iPhone 8 все
                равно остается востребованной задачей, и его осуществление
                пользуется спросом. Существует множество причин, из-за которых
                подобная техника может прийти в негодность, и в частности,
                мастерам приходится сталкиваться с необходимостью реанимации
                техники после:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Утопления устройства – воздействие воды может крайне
                    неблагоприятно на нем отразиться,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Ударов, падений – при этом из строя может выйти любой
                    элемент,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Различных механических повреждений, заводского брака – пусть
                    редкого, но все же.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Все эти и многие другие проблемы, связанные с поломкой
                оборудования, вызывают крайнее разочарование у владельцев
                техники. Однако унывать и отказываться от устройства не стоит,
                ведь Айфоны – это надежное оборудование, которое практически в
                любом случае остается пригодным к ремонту, и важно бывает только
                доверить эти работы грамотному и опытному исполнителю. От
                попытки привести технику в порядок своими руками стоит
                отказаться заблаговременно, однако профессиональная помощь в
                этом вопросе в любом случае будет кстати.
              </p>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price8}
            title="Цены на ремонт Айфон 8"
          />
        </Section>
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfModel8.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage8;
