import React from "react";
import styles from "./NoteExpertWorkSlide.module.css";

function NoteExpertWorkSlide(props) {
  return (
    <li className={styles.item}>
      <div className={styles.circle}>
        <span className={styles.number}>{props.number}</span>
      </div>
      <b className={styles.text}>{props.text}</b>
      <p className={styles.paragraph}>{props.paragraph}</p>
    </li>
  );
}

export default NoteExpertWorkSlide;
