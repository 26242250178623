import React, { useState } from "react";
import { arrowUp } from "../../../images/index";
import useScrollPosition from "../../../hooks/useScrollPosition";

const ScrollUpButton = () => {
  const [hidden, setHidden] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y <= -300) {
        setHidden(false);
      } else {
        setHidden(true);
      }
    },
    [hidden]
  );

  const scrollToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <button
      onClick={scrollToTopClick}
      className={hidden ? "scroll-hidden" : "scroll"}
    >
      <img
        className="scroll__icon"
        alt="кнопка прокрутки страницы наверх"
        src={arrowUp}
      />
    </button>
  );
};

export default ScrollUpButton;
