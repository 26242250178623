import React from "react";
import Section from "../../Section/Section";
// import styles from "./NoteExpertInsidePageAdvantagesCarousel.module.css";
import styles from "./NoteExpertInsidePageAdvantages.module.css";
import Carousel from "react-elastic-carousel";
import NoteExpertAdventage from "./NoteExpertAdventage";

function NoteExpertInsidePageAdvantagesCarousel({ advantages, title }) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <Carousel>
          {advantages.map((item, i) => {
            return (
              <NoteExpertAdventage
                key={i}
                title={item.title}
                text={item.text}
                icon={item.icon}
              />
            );
          })}
        </Carousel>
      </div>
    </Section>
  );
}

export default NoteExpertInsidePageAdvantagesCarousel;
