import React from "react";
import Section from "../Section/Section";

const RepairApplePrice = (props) => {
  return (
    <Section>
      <div className="repair-apple-price__container">
        {props.subtitle && (
          <h2 className="repair-apple-price__subtitle">{props.subtitle}</h2>
        )}
        <div className="repair-apple-price__wrapper">
          <h4 className="repair-mb__price-span">{props.text}</h4>
          <p className="repair-mb__price-price">{props.price}</p>
        </div>
        {props.paragraph && (
          <div className="repair-apple-price__paragraph">{props.paragraph}</div>
        )}
      </div>
    </Section>
  );
};

export default RepairApplePrice;
