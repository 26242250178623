import React, { useState } from "react";
import { Navigate, Outlet, NavLink } from "react-router-dom";
import { Aside } from "../components/index";
import { useUser } from "../providers/UserProvider";
import { auth } from "../utils/api";
import { burgerIcon, exitIcon } from "./newIcons/index";

export default function AdminLayout() {
  const { user, setUser } = useUser();

  const [burgerClick, setBurgerClick] = useState(false);

  const handleBurgerClick = () => {
    setBurgerClick((burgerClick) => !burgerClick);
  };

  if (user === undefined) {
    return null;
  }

  if (user === null) {
    return <Navigate to="/admin-login" />;
  }

  const handleOut = async () => {
    await auth.logout();
    setUser(null);
  };

  return (
    <main className="admin-main">
      <div className="admin-main__header">
        <button
          type="button"
          className="admin-main__burger"
          onClick={handleBurgerClick}
        >
          <img
            className="admin-main__icon"
            src={burgerIcon}
            alt="иконка показа-скрытия названий разделовы"
          />
        </button>
        <p className="admin-main__greeting">Привет, {user.name}!</p>
        <NavLink
          className="admin-main__button"
          to="/admin-login"
          onClick={handleOut}
        >
          <img
            className="admin-main__icon"
            alt="иконка кнопки выхода из админки"
            src={exitIcon}
          />
          <p className="admin-main__subtitle">Выйти</p>
        </NavLink>
      </div>
      <div className="admin-main__wrapper">
        <Aside user={user} burgerClick={burgerClick} />
        <Outlet />
      </div>
    </main>
  );
}
