import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIMacPrice,
  RepairIMacLinks,
  RepairIMacBanner,
  RepairIMacTroubleList,
  RepairIMacTitle,
  MacProfiListMobile,
} from "../index";
import { useMedia } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

function PageList() {
  const isBreakpoint = useMedia("(max-width: 680px)");
  if (isBreakpoint) {
    return <MacProfiListMobile />;
  }
  return <MacProfiList />;
}

const RepairIMacPage = () => {
  return (
    <main className="repair-imac__page">
      <Page title="Ремонт iMac в Москве и регионах РФ">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-imac"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт iMac в сервисном центре Apple в Москве. Гарантия на все виды работ. Быстрая бесплатная диагностика!"
          />
        </Helmet>
        {PageList()}
        <BreadCrumbs />
        <RepairIMacBanner />
        <RepairIMacTitle />
        <RepairIMacTroubleList />
        <RepairIMacPrice />
        <RepairIMacLinks />
      </Page>
    </main>
  );
};

export default RepairIMacPage;
