import React from "react";
import { BlockTitle } from "../../components/index";

const AdminIndex = () => {
  return (
    <section className="info">
      <div className="info__container">
        <>
          <BlockTitle title="Для начала работы перейдите в один из разделов в панели слева" />
          <ul className="info__list">
            <li className="info__item">
              Раздел
              <span className="info__span"> "Пользователи"</span>
              предусмотрен для создания, удаления и редактирования данных
              пользователей, имеющих доступ к системе.
            </li>
            <li className="info__item">
              Раздел
              <span className="info__span"> "Оповещения" </span>
              позволяет управлять оповещением для клиентов на главной странице.
            </li>
            <li className="info__item">
              В разделе
              <span className="info__span"> "Отзывы" </span>
              происходит модерация новых отзывов клиентов и добавление их в
              соответствующий раздел сайта.
            </li>
          </ul>
        </>
      </div>
    </section>
  );
};

export default AdminIndex;
