import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

const AncorLinks = () => {
  //  <a onClick={this.scrollToTop}>To the top!</a>

  return (
    <>
      <li className="ancor-links-li">
        <Link
          className="nav__item"
          activeClass="active"
          to={"omg"}
          smooth={true}
          duration={2000}
          offset={50}
          delay={200}
        >
          Омайгаджет
        </Link>
      </li>
      <li className="ancor-links-li">
        {/* <Link
          className="nav__item"
          to={"otzyvy"}
          smooth={true}
          duration={2000}
          offset={50}
          delay={200}
        >
          Отзывы
        </Link> */}
      </li>
      <li className="ancor-links-li">
        <Link
          className="nav__item"
          to={"clients"}
          smooth={true}
          duration={2000}
          offset={50}
          delay={200}
        >
          Нам можно доверять
        </Link>
      </li>
    </>
  );
};

export default AncorLinks;
