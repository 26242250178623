import React from "react";

const Section = ({ className, children, id, style }) => {
  return (
    <section
      className={"section " + (className ? ' ' + className : '')}
      id={id}
      style={style}
    >
      {children}
    </section>
  )
}

export default Section;