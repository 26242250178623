import React, { useEffect, useRef, useState } from "react";
import cn from "classnames/bind";
import { Popup } from "../../index";
import useFormWithValidation from "../../../hooks/useFormWithValidation";

const PopupAddUser = ({ isOpenPopup, onClose, hadleRegisterUser }) => {
  const { values, handleChange, errors, isValid, resetForm } =
    useFormWithValidation({
      login: "",
      email: "",
      password: "",
      name: "",
      role: "",
    });

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePassword = () => {
    setIsPasswordShown((isPasswordShown) => !isPasswordShown);
  };
  const isDisabled =
    !isValid || Object.values(values).some((value) => value === "");

  const loginButtonClassName = !isDisabled
    ? "form__button"
    : "form__button form__button-disabled";

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isDisabled) {
      hadleRegisterUser(values);
    }

    resetForm();
    onClose();
  };

  const loginRef = useRef(null);

  useEffect(() => {
    if (isOpenPopup) {
      loginRef.current?.focus();
    }
  }, [isOpenPopup]);

  return (
    <Popup className="form__error" isOpenPopup={isOpenPopup} onClose={onClose}>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="form__fields-container"
      >
        <fieldset className="form__login">
          <label htmlFor="login" className="form__label">
            Логин
          </label>
          <input
            ref={loginRef}
            id="user-login"
            name="login"
            type="text"
            className="form__field"
            required={true}
            value={values.login}
            onChange={handleChange}
          />
          {errors.login && <p className="form__error">{errors.login}</p>}
          <label htmlFor="email" className="form__label">
            Email
          </label>
          <input
            id="user-email"
            name="email"
            type="email"
            className="form__field"
            required={true}
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p className="form__error">{errors.email}</p>}
          <label htmlFor="password" className="form__label">
            Пароль
          </label>
          <input
            id="user-password"
            name="password"
            type={isPasswordShown ? "text" : "password"}
            className="form__field"
            required={true}
            minLength={6}
            value={values.password}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="form__wrapper">
            <button
              type="button"
              onClick={togglePassword}
              className={
                isPasswordShown
                  ? cn(
                      "form__password",
                      "form__password_type_shown",
                      "form__password_add_user"
                    )
                  : cn("form__password", "form__password_add_user")
              }
            />
          </span>
          {errors.password && <p className="form__error">{errors.password}</p>}
          <label htmlFor="name" className="form__label">
            Имя
          </label>
          <input
            id="user-name"
            name="name"
            type="text"
            className="form__field"
            required={true}
            value={values.name}
            onChange={handleChange}
            minLength={2}
          />
          {errors.name && <p className="form__error">{errors.name}</p>}
          <label htmlFor="role" className="form__label">
            Должность
          </label>
          <input
            id="user-role"
            name="role"
            type="text"
            className="form__field"
            required={true}
            value={values.role}
            onChange={handleChange}
          />
          {errors.role && <p className="form__error">{errors.role}</p>}
        </fieldset>
        <button type="submit" className={loginButtonClassName}>
          Сохранить
        </button>
      </form>
    </Popup>
  );
};

export default PopupAddUser;

// todo:
// подключить библиотеку для анимации для открытия/закрытия попапа
// framer motion
// react transition groupe
