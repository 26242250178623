import { Popup } from "../../index";
import useFormWithValidation from "../../../hooks/useFormWithValidation";
import cn from "classnames/bind";
import { useEffect, useRef } from "react";

const PopupAddNotification = ({
  isOpenPopup,
  onClose,
  handleAddNotification,
  formClassName,
}) => {
  const { values, handleChange, errors, isValid, resetForm } =
    useFormWithValidation({ title: "", text: "" });

  const isDisabled = values.title === "" || values.text === "" || !isValid;

  const loginButton = !isDisabled
    ? "form__button"
    : "form__button form__button-disabled";

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isDisabled) {
      handleAddNotification(values.title, values.text);
    }

    resetForm();
    onClose();
  };

  const titleRef = useRef(null);

  useEffect(() => {
    if (isOpenPopup) {
      titleRef.current?.focus();
    }
  }, [isOpenPopup]);

  return (
    <Popup className="form__error" isOpenPopup={isOpenPopup} onClose={onClose}>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="form__fields-container"
      >
        <fieldset className={cn("form__login", formClassName)}>
          <label htmlFor="title" className="form__label">
            Заголовок
          </label>
          <input
            ref={titleRef}
            id="title"
            name="title"
            type="text"
            className="form__field"
            required={true}
            placeholder=""
            value={values.title}
            onChange={handleChange}
          />
          {errors.title && <p className="form__error">{errors.title}</p>}
          <label htmlFor="text" className="form__label">
            Текст
          </label>
          <textarea
            id="text"
            name="text"
            className="form__field"
            required={true}
            placeholder=""
            value={values.text}
            onChange={handleChange}
          />
          {errors.text && <p className="form__error">{errors.text}</p>}
        </fieldset>
        <button type="submit" className={loginButton}>
          Сохранить
        </button>
      </form>
    </Popup>
  );
};

export default PopupAddNotification;

// todo:
// подключить библиотеку для анимации для открытия/закрытия попапа
// framer motion
// react transition groupe
