import React from "react";
import styles from "./CooperationAdditionalAdvantages.module.css";

const CooperationAdditionalAdvantages = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        ДОПОЛНИТЕЛЬНЫЕ ПРЕИМУЩЕСТВА СОТРУДНИЧЕСТВА С Oh!MyGadget!:
      </h2>
      <ul className={styles.list}>
        <li className={styles.element}>
          <label className={styles.number}>01</label>
          <p className={styles.text}>
            Удобство и эффективность: Ваш личный менеджер всегда на связи для
            удобства коммуникации и оперативного решения любых вопросов.
          </p>
        </li>
        <li className={styles.element}>
          <label className={styles.number}>02</label>
          <p className={styles.text}>
            Всероссийское обслуживание: Наша курьерская служба оперативно
            доставляет и забирает технику из любой точки России.
          </p>
        </li>
        <li className={styles.element}>
          <label className={styles.number}>03</label>
          <p className={styles.text}>
            Централизованный ремонт в Москве: Гарантия высокого качества ремонта
            благодаря централизованной работе нашего сервисного центра.
          </p>
        </li>
        <li className={styles.element}>
          <label className={styles.number}>04</label>
          <p className={styles.text}>
            Профессионализм и опыт: Наша команда - это опытные специалисты,
            готовые предложить лучшие решения для вашего бизнеса.
          </p>
        </li>
        <li className={styles.element}>
          <label className={styles.number}>05</label>
          <p className={styles.text}>
            Гарантия качества: Предоставляем гарантию на все виды ремонтных
            работ, подтверждая высокий стандарт наших услуг.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default CooperationAdditionalAdvantages;
