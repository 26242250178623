import React from "react";
import { Link } from "react-router-dom";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageDecisionsTableScreen.module.css";

function NoteExpertGeneralPageDecisionsTableScreen({ decisions }) {
  return (
    <Section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.title}>ваши проблемы - наши решения</h2>
        <p className={styles.subtitle}>
          Мы работаем 7 дней в неделю. Если ваш ноутбук отказывается работать,
          мы вернем его в рабочее состояние. В компетенции специалистов Note
          Expert выявление и устранение любых неисправностей ноутбуков Acer,
          Asus, Dell, HP, Lenovo, Samsung, Sony, Toshiba и др. – от самых
          простых до самых серьезных.
        </p>
        <ul className={styles.list}>
          {decisions.map((item, i) => {
            return (
              <li key={i} className={styles.item}>
                <img src={item.icon} alt="иконка" className={styles.img} />
                <b className={styles.text}>{item.text}</b>
                <p className={styles.paragraph}>{item.paragraph}</p>
                <Link to={item.link} className={styles.button}>
                  ПОДРОБНЕЕ
                </Link>
                <div className={styles.border}></div>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPageDecisionsTableScreen;
