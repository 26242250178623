import { myFetch } from "./myFetch";

export const getFeedbacks = (params = {}) => {
  const queryParams = new URLSearchParams(params);
  return myFetch(`feedbacks?${queryParams}`);
};

export const sortFeedbacks = () => {
  return myFetch(`feedbacks/sorted`);
};

export const getFeedBackById = (feedbackId) => {
  return myFetch(`feedbacks/${feedbackId}`);
};

export const editFeedback = (feedbackId, data) => {
  return myFetch(`feedbacks/${feedbackId}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
};

export const postFeedback = (text, name, email) => {
  return myFetch(`feedbacks`, {
    method: "POST",
    body: JSON.stringify({ text, name, email }),
  });
};

export const deleteFeedback = (feedbackId) => {
  return myFetch(`feedbacks/${feedbackId}`, { method: "DELETE" });
};

export const deleteFeedbacks = (feedbacksId) => {
  return myFetch(`feedbacks`, {
    method: "DELETE",
    body: JSON.stringify({ feedbacks: feedbacksId }),
  });
};

export const approveFeedbacks = (feedbacksIds) => {
  return myFetch(`feedbacks/approve`, {
    method: "PATCH",
    body: JSON.stringify({ feedbacks: feedbacksIds }),
  });
};

export const approveFeedback = (approved) => {
  return myFetch(`feedbacks/approve`, {
    method: "PATCH",
    body: JSON.stringify({ approved }),
  });
};
