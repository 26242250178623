import { Popup } from "../../index";
import useFormWithValidation from "../../../hooks/useFormWithValidation";
import cn from "classnames/bind";
import { useEffect, useRef } from "react";

const PopupAdminFeedback = ({
  isOpenPopup,
  onClose,
  handleAddFeedbackFromAdmin,
  formClassName,
}) => {
  const { values, handleChange, errors, isValid, resetForm } =
    useFormWithValidation({
      text: "",
      name: "",
      email: "",
    });

  const isDisabled =
    !isValid || Object.values(values).some((value) => value === "");

  const loginButtonClassName = !isDisabled
    ? "form__button"
    : "form__button form__button-disabled";

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isDisabled) {
      handleAddFeedbackFromAdmin(values.text, values.name, values.email);
    }

    resetForm();
    onClose();
  };

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isOpenPopup) {
      textAreaRef.current?.focus();
    }
  }, [isOpenPopup]);

  return (
    <Popup className="form__error" isOpenPopup={isOpenPopup} onClose={onClose}>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="form__fields-container"
      >
        <fieldset className={cn("form__login", formClassName)}>
          <label htmlFor="text" className="form__label">
            Текст
          </label>
          <textarea
            ref={textAreaRef}
            id="text"
            name="text"
            className="form__field"
            required={true}
            placeholder=""
            value={values.text}
            onChange={handleChange}
          />
          {errors.text && <p className="form__error">{errors.text}</p>}
          <label htmlFor="name" className="form__label">
            Имя
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className="form__field"
            required={true}
            placeholder=""
            value={values.name}
            onChange={handleChange}
          />
          {errors.name && <p className="form__error">{errors.name}</p>}
          <label htmlFor="email" className="form__label">
            Email
          </label>
          <input
            id="user-email"
            name="email"
            type="email"
            className="form__field"
            required={true}
            placeholder=""
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p className="form__error">{errors.email}</p>}
        </fieldset>
        <button type="submit" className={loginButtonClassName}>
          Сохранить
        </button>
      </form>
    </Popup>
  );
};

export default PopupAdminFeedback;

// todo:
// подключить библиотеку для анимации для открытия/закрытия попапа
// framer motion
// react transition groupe
