export const ITEM_WIDTH = 360;
export const ESC = "Escape";
export const BASE_URL = "https://api.ohmygadget.ru";

export const feedbacksList = [
  {
    id: 1,
    text: "Все понравилось, большое спасибо, все починили быстро и качественно!",
  },
  {
    id: 2,
    text: "Все сделали быстро и красиво, вежливый персонал, качественный ремонт (меняли дисплей). Остался доволен, спасибо огромное)",
  },
  {
    id: 3,
    text: "Спасибо всей команде работников! Обработка заказа, логистика, ремонт - все быстро и качественно.Спасибо!!! В случае необходимости,обязательно обращусь в Ваш сервисный центр.",
  },
  {
    id: 4,
    text: "Специалисты сервисного центра спасли мой ноутбук от вируса из-за которого компьютер начал без конца перезагружаться. У меня не получилось даже переустановить систему из-за этой постоянной перезагрузки. Спасибо!",
  },
  {
    id: 5,
    text: "Заменили дисплей. Все хорошо",
  },
  {
    id: 6,
    text: "Специалисты сервисного центра спасли мой ноутбук от вируса из-за которого компьютер начал без конца перезагружаться. У меня не получилось даже переустановить систему из-за этой постоянной перезагрузки. Спасибо!",
  },
  {
    id: 7,
    text: "Спасибо, mac-profi!",
  },
];
