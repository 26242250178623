import React from "react";
import { logoFooter } from "../../images/index";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AncorLinks, PageLinks } from "../index";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";
import useMedia from "use-media";
import footerMobileLogo from "../../images/logos/footer-mobile-logo.svg";

const FooterNav = () => {
  const phoneNumber1 = "+74953745481";
  const phoneNumber2 = "+74955405067";
  const isWide = useMedia({ minWidth: 520 });
  const location = useLocation();

  return (
    <section className="footer-nav">
      <div className="footer-nav__content">
        <Link className="footer-nav__logo-link" to="/">
          <img
            className="footer-nav__logo"
            src={isWide ? logoFooter : footerMobileLogo}
            alt="логотип компании"
          />
        </Link>
        <ul className="footer-nav__adress-info">
          <li className="footer-nav__adress-li">
            <h4 className="footer-nav__title">Контактные телефоны:</h4>
            <ul className="footer-nav__tels">
              <li>
                <a
                  href={`tel:${phoneNumber2}?call`}
                  className="footer-nav__tel"
                >
                  {formatPhoneNumber(phoneNumber2)}
                </a>
              </li>
              <li>
                <a
                  href={`tel:${phoneNumber1}?call`}
                  className="footer-nav__tel"
                >
                  {formatPhoneNumber(phoneNumber1)}
                </a>
              </li>
            </ul>
          </li>
          <li className="footer-nav__adress-li">
            <h4 className="footer-nav__title">Почта для обращений клиентов:</h4>
            <p className="footer-nav__tel">
              <a
                className="footer-nav__email"
                href="mailto:client-service@ohmygadget.su"
              >
                client-service@ohmygadget.su
              </a>
            </p>
          </li>
          <li className="footer-nav__adress-li">
            <h4 className="footer-nav__title">
              График работы сервисного центра:
            </h4>
            <ul className="footer-nav__adress">
              <li className="footer-nav__adress-item">
                Пн - Пт<span className="footer-nav__span"> 10 - 20</span>
              </li>
              <li className="footer-nav__adress-item">
                Сб - Вс<span className="footer-nav__span"> выходной</span>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="footer-nav__links">
          {location.pathname === "/" ? (
            <>
              <AncorLinks />
              <PageLinks />
              <li>
                <NavLink className="footer-nav__link page-link__li" to="/login">
                  Личный кабинет
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li className="footer-nav__general">
                <Link className="footer-nav__link" to="/">
                  Главная
                </Link>
              </li>
              <li>
                <NavLink className="footer-nav__link page-link__li" to="/login">
                  Личный кабинет
                </NavLink>
              </li>
              <PageLinks />
            </>
          )}
        </ul>
      </div>
    </section>
  );
};

export default FooterNav;
