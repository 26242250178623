import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone7plus.jpeg";
import { Helmet } from "react-helmet";

const textOfModel7Plus = [
  {
    id: 1,
    title: "Как мы производим ремонт iPhone 7 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Для начала - всесторонняя и дотошная диагностика. У нас она
          безоговорочно бесплатная. После этого наш менеджер свяжется с вами,
          подробно и доступно объяснит все выявленные неисправности. Дальше -
          дело за вами и вашим выбором. Не всегда финансы позволяют совершить
          ремонт Айфон 7 Плюс в полном объёме. Поэтому часть мелких недочетов
          или неисправностей клиент иногда хочет отложить на завтра (хотя,
          конечно, особенно затягивать и не стоит). У нас вы оплачиваете только
          те типы ремонта, которые выбрали сами. После того, как ваш телефон
          будет отремонтирован и вы убедитесь в его бесперебойной работе, вы
          получаете гарантийный талон на срок до года, и оплачиваете ремонт
          вашего iPhone 7 Plus. Таким образом, в нашем центре с вас не потребуют
          какой-либо предоплаты, а все расходы вы полностью контролируете и
          можете спланировать заранее.
        </p>
        <p className="repair-iphone-display__paragraph">
          Какие виды ремонта iPhone 7 Plus мы производим? Проще ответить на
          вопрос: какие нет. Ответ - никакие! Замена стекла или сенсорного
          экрана, динамиков, микрофона или батареи, прошивка или перепрошивка -
          со всеми этими работами вы можете смело обращаться за помощью в наши
          центры.
        </p>
      </>
    ),
  },
];

const price7Plus = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage7Plus = () => {
  return (
    <Page title="Ремонт iPhone 7 Plus c гарантией в Москве!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/7-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Нужен ремонт iPhone 7 Plus? Производим ремонт Айфона 7 Плюс в сервисном центре в Москве с гарантией на все виды работ!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 7 Plus"
          titleImg="Ремонт iPhone 7 Plus"
          banner={banner}
          title="Ремонт iPhone 7 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Если вы ищите, где отремонтировать iPhone 7 Plus в Москве, то
                вы, наконец, попали по адресу. Именно в нашем центре вы найдете
                тех искусников ремонта, которые вернут ваш Айфон 7 Плюс
                практически неотличимым от нового, после буквально любой
                поломки.
              </p>
              <p className="repair-iphone-display__paragraph">
                iPhone 7 Plus появились на рынке совсем не давно, а наши
                специалисты уже успели приобрести опыт в их разборке и починке,
                а наш центр - укомплектовать свой склад набором всем необходимых
                деталей.
              </p>
              <p className="repair-iphone-display__paragraph">
                На все виды нашего ремонта предоставляется гарантия до 12
                месяцев. Сам ремонт при этом происходит практически в мгновение
                ока: большая часть поломок не отнимет у наших специалистов более
                двадцати минут. Кроме того, в нашем центре работают и курьеры,
                которые могут, при вашем желании, забрать поломанный телефон по
                указанному вами адресу, и привезти его после починки прямиком к
                вам домой.
              </p>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price7Plus}
            title="Стоимость ремонта Айфон 7 Плюс"
            text="* Все цены в рублях РФ. В цену входит комплектующее и услуга по установки."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel7Plus.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage7Plus;
