import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageReasons.module.css";
import icon1 from "../../../images/icons/note-expert-general-page-icon1.svg";
import icon2 from "../../../images/icons/note-expert-general-page-icon2.svg";
import icon3 from "../../../images/icons/note-expert-general-page-icon3.svg";
import triangle from "../../../images/icons/note-expert-triangle.svg";
import NoteExpertGeneralPageReasonsCarousel from "./NoteExpertGeneralPageReasonsCarousel";
import { useMedia } from "../../../utils/useMedia";

const elements = [
  {
    icon: icon1,
    subtitle: "ПРОФЕССИОНАЛЬНЫЙ РЕМОНТ НОУТБУКОВ",
    text1: "Современное оборудование",
    text2: "Сертифицированные специалисты",
    text3: "Наличие всех комплектующих",
  },
  {
    icon: icon2,
    subtitle: "СРОЧНЫЙ РЕМОНТ НОУТБУКОВ",
    text1: "Бесплатная диагностика",
    text2: "Время ремонта от 30 минут",
    text3: "Цены на ремонт от 500 рублей",
  },
  {
    icon: icon3,
    subtitle: "РЕМОНТ ЛЮБЫХ НОУТБУКОВ",
    text1: "Все виды неисправностей",
    text2: "Любые марки и модели ноутбуков",
    text3: "Гарантия до 3 лет",
  },
];

function ChangePage() {
  const isBreakpoint = useMedia("(min-width: 1161px)");

  if (isBreakpoint) {
    return (
      <ul className={styles.list}>
        {elements.map((element, i) => {
          return (
            <li key={i} className={styles.item}>
              <div className={styles.circle}>
                <img src={element.icon} alt="иконка" />
              </div>
              <h4 className={styles.subtitle}>{element.subtitle}</h4>
              <div className={styles.wrapper}>
                <img src={triangle} className={styles.triangle} />
                <p className={styles.text}>{element.text1}</p>
              </div>
              <div className={styles.wrapper}>
                <img src={triangle} className={styles.triangle} />
                <p className={styles.text}>{element.text2}</p>
              </div>
              <div className={styles.wrapper}>
                <img src={triangle} className={styles.triangle} />
                <p className={styles.text}>{element.text3}</p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
  return <NoteExpertGeneralPageReasonsCarousel elements={elements} />;
}

function NoteExpertGeneralPageReasons() {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>Почему note expert?</h2>
        {ChangePage()}
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPageReasons;
