import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Header, Footer, ScrollUpButton } from "../../components/index";
import useMedia from "use-media";
import HeaderBurger from "../Header/HeaderBurger";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

const Layout = () => {
  const isWide = useMedia({ minWidth: 800 });
  const [burgerMenuActive, setBurgerMenuActive] = useState(false);

  useEffect(() => {
    if (isWide) {
      setBurgerMenuActive(false);
    }
  }, [isWide]);

  return (
    <>
      {isWide ? (
        <Header />
      ) : (
        <HeaderBurger
          active={burgerMenuActive}
          setActive={setBurgerMenuActive}
        />
      )}
      <Outlet />
      <Footer />
      <ScrollUpButton />
      <BurgerMenu active={burgerMenuActive} setActive={setBurgerMenuActive} />
    </>
  );
};

export default Layout;
