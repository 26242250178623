import React from "react";
import Section from "../Section/Section";
import mackBookBanner from "../../images/banners/repair-mb-banner.png";

const RepairMacBookBanner = () => {
  return (
    <Section>
      <img
        src={mackBookBanner}
        alt="ремонт MacBook"
        title="ремонт MacBook"
        className="repair-mb__banner"
      />
    </Section>
  );
};

export default RepairMacBookBanner;
