import react from "react";
import {
  MacProfiAboutUs,
  MacProfiAboutUsMobile,
  MacProfiApple,
  MacProfiBanner,
  MacProfiBannerMobile,
  MacProfiList,
  MacProfiListMobile,
  MacProfiReasons,
  BreadCrumbs,
  Page,
} from "../index";
import { useMobileScreen, useBigTabletScreen } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

function Banner() {
  const isMedia = useBigTabletScreen();

  if (isMedia) {
    return <MacProfiBannerMobile />;
  }
  return <MacProfiBanner />;
}

function AboutUs() {
  const isMedia = useMobileScreen();

  if (isMedia) {
    return <MacProfiAboutUsMobile />;
  }
  return <MacProfiAboutUs />;
}

const MacProfiPage = () => {
  return (
    <Page title="Oh!MyGadget! Сервисный центр Apple">
      <main className="mac-profi__page">
        <Helmet>
          <link rel="canonical" href="https://ohmygadget.ru/remont-apple" />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Сервисные центры Oh!MyGadget! в Москве производят быстрый и качественный ремонт Apple всех моделей! "
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        {Banner()}
        {AboutUs()}
        <MacProfiApple />
        <MacProfiReasons />
      </main>
    </Page>
  );
};

export default MacProfiPage;
