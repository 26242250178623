import React from "react";
import { Popup } from "../../index";
import { userAttantionIcon } from "../../../admin/newIcons/index";

const PopupConfirm = ({ isOpenPopup, onClose, message }) => {
  return (
    <Popup isOpenPopup={isOpenPopup} onClose={onClose}>
      <form className="form__container">
        <img
          className="form__icon"
          alt="иконка попапа ошибки"
          src={userAttantionIcon}
        />
        <h3 className="form__title">Произошла ошибка:</h3>
        <p className="form__alert-message">{message}</p>
      </form>
    </Popup>
  );
};

export default PopupConfirm;

// 1. Вместо результата выполнения функции showMessage() в попапе должна показываться ошибка с бэкенда, из res.Error, потому что они туда передаются;

// 2. Надо подобрать иконку для ошибки;

// 3. Нужно, чтобы этот попап показывался в случае ошибок на всех нужных страницах:

// - главная, при попытке оставить отзыв
// - доставки, при попытке заполнить форму доставки
// - страницы админки, при попытке добавить, удалить или изменить данные

// 4. Решить, нужен ли попап с подтверждением успешно завершенного действия. Например, чтобы пользователь мог понять, что отзыв оставить удалось - все ок. И сделать его

// 5. Последним шагом - надо залить изменения в репозиторий и оставить в Trello оставшиеся задачи при необходимости
