import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpad2.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Вскрыть устройство",
    number: "01",
  },
  {
    id: 2,
    text: "Аккуратно извлечь элементы, мешающие добраться до стекла",
    number: "02",
  },
  {
    id: 3,
    text: "Использовать нагревающее устройство, которое поможет снять приклеенное стекло",
    number: "03",
  },
  {
    id: 4,
    text: "Очистить поверхность от остатков старого клея, грязи, осколков",
    number: "04",
  },
  {
    id: 5,
    text: "Проклеить корпус",
    number: "05",
  },
  {
    id: 6,
    text: "Установить новое стекло.",
    number: "06",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title: "Как заменить стекло iPad 2",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Если устройству были нанесены даже незначительные повреждения, откладывать процедуру замены экрана не рекомендуется. В любом случае рано или поздно трещина начнет увеличиваться, а любая встряска может привести к тому, что сенсор и вовсе перестанет реагировать на действия пользователя. Чтобы не лишиться планшета именно тогда, когда он больше всего нужен, стоит своевременно обратиться в сервисный центр."
          paragraphUp2="Чтобы заменить стекло iPad 2, потребуется:"
          array={listOfTrouble}
          paragraphDown="Процедура непростая – важно на каждом этапе соблюдать аккуратность. В противном случае замена стекла завершится повреждениями сопутствующих частей конструкции, которым также потребуется восстановление. Если из-за попытки самостоятельно поставить новый экран будет испорчен тачскрин, то подобная «экономия» потребует значительных финансовых затрат – риск явно слишком велик."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Замена стекла iPad 2 в сервисном центра Мак Профи в Москве",
    text: "В сервисном центре Мак Профи есть всё необходимое оборудование, которое позволит нам аккуратно произвести замену стекла, не повредив основную конструкцию. Вы и не заметите следов ремонта на поверхности – все работы выполняют профессионалы, которые быстрыми и точными движениями установят новый экран. Все работы занимают около суток, после чего вы сможете забрать домой обновленный планшет.",
  },
];

const RepairIpadServiceLinkDisplayModel2 = () => {
  return (
    <Page title="Выгодная замена стекла на iPad 2 за 50 минут при клиенте!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/2"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена стекла iPad 2 – это не сложно, если обратиться к грамотным специалистам. Сервисный центр Oh!MyGadget! всегда готов помочь своим клиентам, отремонтировать технику."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена стекла на iPad 2"
          alt="Замена стекла на iPad 2"
          titleImg="Замена стекла на iPad 2"
          text="Замена стекла iPad 2 – частная причина обращения в сервисный центр. Если сесть на планшет, ударить или же уронить его, что бывает отнюдь не редко, можно легко повредить даже самую качественную и прочную поверхность. Это отразится и на дальнейшей эксплуатации устройства, так как разбитый экран помешает наслаждаться его комфортной работой. Чтобы исправить эту проблему – отдайте iPad на ремонт в Мак Профи. Наши цены неизменно лояльны, а высокий уровень сервиса удовлетворит самых требовательных клиентов."
        />
        <Section className="section_group">
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена стекла iPad 2"
          text="Замена сенсорного стекла (тачскрина)"
          price="2250 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Изначально мы проведем бесплатную диагностику, оценив состояние
                устройства. Если, например, из-за падения или удара были
                повреждены и другие детали, то владелец будет об этом уведомлен.
                Окончательная стоимость будет зависеть от выбранных услуг и
                сложности поставленной задачи – на нашем сайте вы можете
                ознакомиться с расценками.
              </p>
              <p className="repair-iphone-display__paragraph">
                Если вас интересует ремонт iPad 2 на выгодных условиях,
                приезжайте к нам, и вы сможете получить полный комплекс услуг по
                честным ценам. Работаем ежедневно, оперативно устраняем любые
                поломки и обязательно предоставляем гарантии качества.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModel2;
