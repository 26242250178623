import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker5c.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfSpeaker5c = [
  {
    id: 1,
    title: "Наш подход к замене динамика iPhone 5c",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с проблемами Айфона, обнаружили, что его звучание
          оставляет желать лучшего, имеет дефекты, или если звук пропал
          полностью – обращайтесь к нам, в сервисный центр Мак Профи. Наши
          мастера прекрасно справляются с ремонтными работами такого рода,
          добиваются прекрасного результата даже в сложных случаях.
        </p>
        <p className="repair-iphone-display__paragraph">
          Многолетний опыт работы позволяет нам успешно справляться с любыми
          задачами, и благодаря качественному труду, быстрому и полному
          выполнению работ наш центр имеет хорошую репутацию. Мы работаем
          официально, даем все гарантии, заключаем договор на ремонт, и
          ответственно относимся к своим обязательствам. Ремонт может быть
          выполнен срочно или в штатном режиме, и даже в последнем случае он не
          займет много времени, будет реализован за день. А кроме того, мы
          придерживаемся политики доступных цен, и потому наши услуги не
          окажутся для вас обременительными в финансовом плане.
        </p>
        <p className="repair-iphone-display__paragraph">
          Сервисный центр имеет самое современное оснащение, которое позволяет
          ему эффективно и быстро выполнять работы любой сложности. Пайка и
          другие сложные моменты не составляют для нас большой проблемы, рабочие
          места оснащены всем необходимым, и оптимальные условия для выполнения
          высокотехнологичных работ созданы. Наши мастера – это компетентные
          люди с опытом и соответствующим образованием, им известны все нюансы
          устройства Айфонов, и потому выполнить ремонт с гарантией отличного
          результата они могут без труда. А еще у нас всегда есть нужные
          запчасти, и вам не придется ожидать их поиска и доставки – динамик для
          Айфона найдется немедленно и будет установлен в короткие сроки. Мы
          даем гарантию на запчасти, они имеют официальное заводское
          происхождение и являются качественными.
        </p>
        <p className="repair-iphone-display__paragraph">
          Перед выполнением работ мастер проведет диагностику, чтобы уточнить
          причину проблем. Это бесплатная услуга, которая проводится в считанные
          минуты, и после нее можно будет узнать стоимость ремонта, при
          необходимости задать все сопутствующие вопросы. Мы работаем
          официально, и при вашем согласии будет заключен договор на ремонт, и
          далее вы получите и гарантийные документы. Обращайтесь к нам, чтобы
          доверить ремонт ответственному и грамотному исполнителю, который
          выполнит его наилучшим образом.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModels5c = () => {
  return (
    <Page title="Быстрая замена динамика iPhone 5c в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-dinamika/5c"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Необходима замена динамика iPhone 5c? Сервис Oh!MyGadget! выполнит эту работу в Москве, быстро, качественно, недорого."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена динамика iPhone 5c"
          titleImg="Замена динамика iPhone 5c"
          banner={banner}
          title="Замена динамика iPhone 5c"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с динамиком – это одна из весьма распространенных
                причин ремонта айфонов. Этот элемент выходит из строя нередко, и
                причины тому могут быть самые разные. Такое происходит из-за
                попадания воды, падений и ударов, износа и массы других
                нежелательных явлений, однако вне зависимости от причины поломки
                ее можно исправить – обычно это выполняется путем замены
                динамика. Ситуации, в которых необходима замена динамика iPhone
                5c, выглядят обычно следующим образом:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Пропадает звук при прослушивании музыки без наушников,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Звучание нарушается, появляется хрип на низких частотах,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Не удается нормально слышать абонента при разговоре.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                В этих и подобных ситуациях необходимо обратиться в
                профессиональную мастерскую, потому как самостоятельный или
                дилетантский подход к этому вопросу недопустим. Айфоны – это
                высокотехнологичные девайсы, которые требуют соответствующего
                подхода при выполнении работ, и необходимо не только обеспечить
                усилия квалифицированных специалистов, но и создать специальные
                условия, в которых ремонт этой тонкой техники будет осуществим.
                Ведь даже ее пайка является ювелирным делом и требует
                специального оборудования. Если вы столкнулись с проблемами
                динамика – обращайтесь к нам, в нашем сервисе вам обеспечат
                грамотный подход и выполнение работ на высочайшем уровне.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1200 P"
          priceDown="1200 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeaker5c.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels5c;
