import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpadPro9.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Мастер бережно разберет планшет;",
    number: "01",
  },
  {
    id: 2,
    text: "Снимет основные запчасти;",
    number: "02",
  },
  {
    id: 3,
    text: "Перенесет комплектующие в новый корпус;",
    number: "03",
  },
  {
    id: 4,
    text: "Включит планшет и проведет диагностику.",
    number: "04",
  },
];

const textOfCasePro9 = [
  {
    id: 1,
    title: <>Стоит ли менять корпус iPad&nbsp;Pro&nbsp;9.7</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Важно понимать, что деформация крышки портит лишь визуальное впечатление. Из-за нарушения геометрии создается давление на внутренние, части из-за чего планшет может начать перегреваться. Это приведет к преждевременному износу аккумулятора и других систем, что значительно сократит период эксплуатации устройства. Именно поэтому лучше доверить ремонт мастерам, в результате которого будет произведена замена корпуса iPad Pro 9.7:"
          array={listOfTrouble}
          paragraphDown="Вы оплачиваете предоставленные услуги только после того, как убедитесь в том, что корпус идеально прилегает к экрану, а работа устройства установлена. Более того, действует длительная гарантия на все запчасти – мы используем только высококачественные детали, а потому готовы подтвердить их надежность."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModelPro9 = () => {
  return (
    <Page title="Профессиональная замена корпуса iPad Pro 9.7 при Вас за 1.5 часа">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/pro-9"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена корпуса iPad Pro 9.7 в Москве – лучшие специалисты в сервисном центре Oh!MyGadget!. Ремонт за полчаса. Качество гарантируем."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad pro 9.7"
          titleImg="замена корпуса ipad pro 9.7"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;Pro&nbsp;9.7</>}
          text="Корпус и стекло – самые уязвимые части любого устройства. Качественный чехол защитит планшет от царапин, но не поможет при падении с большой высоты или других серьезных повреждениях. Наши мастера устранят нанесенные повреждения и обновят внешний вид iPad Pro 9.7 – и он вновь будет выглядеть безупречно."
        />
        <RepairApplePrice text="Замена корпуса/задней крышки" price="10500 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCasePro9.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModelPro9;
