import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import avatar from "../../../images/center-mobile-feedbacks-avater2.svg";
import ok from "../../../images/icons/popup-ok.svg";
import popupCloseIcon from "../../../images/icons/popup-close.svg";
import titleIcon from "../../../images/icons/feedback-popup-title.svg";
import recaptcha from "../../../images/recaptcha.svg";

const initialUser = {
  username: "Иван",
  city: "Москва",
  avatar: avatar,
};

function isEmailValid(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function isUsernameValid(username) {
  return username.trim().length > 1;
}

function isFeedbackValid(feedback) {
  return feedback !== "";
}

function CenterMobileFeedbacksPopup({ isOpen, setIsOpen, user = initialUser }) {
  const [errors, setErrors] = useState({
    username: false,
    email: false,
    feedback: false,
  });
  const [values, setValues] = useState({
    username: "",
    email: "",
    feedback: "",
  });
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  let buttonClassName = "center-mobile-general-page__popup-button";

  if (user) {
    if (isFeedbackValid(values.feedback)) {
      buttonClassName = "center-mobile-general-page__popup-button-active";
    }
  } else {
    if (
      isUsernameValid(values.username) &&
      isEmailValid(values.email) &&
      isFeedbackValid(values.feedback)
    ) {
      buttonClassName = "center-mobile-general-page__popup-button-active";
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    const newErrors = {
      username: !isUsernameValid(values.username),
      email: !isEmailValid(values.email),
      feedback: !isFeedbackValid(values.feedback),
    };

    const hasErrors = Object.values(newErrors).some(Boolean);
    if (hasErrors) setErrors(newErrors);
    setIsSubmitSuccessful(!hasErrors);
  }

  return (
    <>
      <Dialog
        className="center-mobile-general-page__popup-content"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div
          className="center-mobile-general-page__popup-backdrop"
          aria-hidden="true"
        ></div>
        <div className="center-mobile-general-page__popup-center">
          {isSubmitSuccessful ? (
            <div className="center-mobile-general-page__popup-qwer-success">
              <Dialog.Panel className="center-mobile-general-page__popup-success">
                <img
                  src={popupCloseIcon}
                  alt="иконка закрытия окна"
                  className="center-mobile-general-page__popup-success-close"
                  onClick={() => setIsOpen(false)}
                />
                <img
                  src={ok}
                  className="center-mobile-general-page__popup-success-finger"
                  alt="иконка успешной отправки"
                />
                <Dialog.Title className="center-mobile-general-page__popup-success-title">
                  Ваш отзыв успешно отправлен!
                </Dialog.Title>
                <p className="center-mobile-general-page__popup-success-subtitle">
                  ваш отзыв будет одобрен после проверки модератором
                </p>
              </Dialog.Panel>
            </div>
          ) : (
            <div className="center-mobile-general-page__popup-qwer">
              <Dialog.Panel className="center-mobile-general-page__popup-container">
                <Dialog.Title className="center-mobile-general-page__popup-title">
                  <img
                    src={titleIcon}
                    alt="иконка"
                    style={{ marginRight: 10 }}
                  />
                  ОСТАВИТЬ ОТЗЫВ
                </Dialog.Title>
                <div className="center-mobile-general-page__popup-title-border"></div>
                <form noValidate onSubmit={handleSubmit}>
                  {user ? (
                    <div className="center-mobile-general-page__popup-box">
                      <img
                        src={user.avatar}
                        alt="аватар"
                        className="center-mobile-general-page__popup-avatar"
                      />
                      <div>
                        <h3 className="center-mobile-general-page__popup-name">
                          {user.username}
                        </h3>
                        <p className="center-mobile-general-page__popup-city">
                          {user.city}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="center-mobile-general-page__popup-wrapper">
                      <div className="center-mobile-general-page__popup-input-wrapper">
                        <label
                          className="center-mobile-general-page__paragraph"
                          htmlFor="username"
                        >
                          ВАШЕ ИМЯ
                        </label>
                        <input
                          id="username"
                          type="text"
                          required
                          minLength={2}
                          placeholder="Иван"
                          name="username"
                          className="center-mobile-general-page__popup-input"
                          onChange={(e) => {
                            setValues({ ...values, username: e.target.value });
                            if (
                              errors.username &&
                              isUsernameValid(e.target.value)
                            ) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                username: false,
                              }));
                            }
                          }}
                        />
                        {errors.username ? (
                          <p className="center-mobile-general-page__errors-text">
                            *не указали имя
                          </p>
                        ) : (
                          <p className="center-mobile-general-page__errors-hidden-text"></p>
                        )}
                      </div>
                      <div className="center-mobile-general-page__popup-input-wrapper">
                        <label
                          className="center-mobile-general-page__paragraph"
                          htmlFor="email"
                        >
                          ВАШ E-MAIL
                        </label>
                        <input
                          id="email"
                          type="email"
                          required
                          placeholder="email@imail.com"
                          name="email"
                          className="center-mobile-general-page__popup-input"
                          onChange={(e) => {
                            setValues({ ...values, email: e.target.value });
                            if (errors.email && isEmailValid(e.target.value)) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                email: false,
                              }));
                            }
                          }}
                        />
                        {errors.email ? (
                          <p className="center-mobile-general-page__errors-text">
                            *не указали почту
                          </p>
                        ) : (
                          <p className="center-mobile-general-page__errors-hidden-text"></p>
                        )}
                      </div>
                    </div>
                  )}

                  <div>
                    <label
                      className="center-mobile-general-page__paragraph"
                      htmlFor="feedback"
                    >
                      НАПИШИТЕ ОТЗЫВ
                    </label>
                    <textarea
                      className="center-mobile-general-page__popup-textarea"
                      id="feedback"
                      name="feedback"
                      onChange={(e) => {
                        setValues({ ...values, feedback: e.target.value });
                        if (
                          errors.feedback &&
                          isFeedbackValid(e.target.value)
                        ) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            feedback: false,
                          }));
                        }
                      }}
                    ></textarea>
                    {errors.feedback ? (
                      <p className="center-mobile-general-page__errors-text">
                        *ничего не написали
                      </p>
                    ) : (
                      <p className="center-mobile-general-page__errors-hidden-text"></p>
                    )}
                  </div>
                  <button className={buttonClassName} type="submit">
                    Оставить отзыв
                  </button>
                </form>
              </Dialog.Panel>
              <img
                src={recaptcha}
                alt="рекапча"
                className="center-mobile-general-page__recaptcha"
              />
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default CenterMobileFeedbacksPopup;
