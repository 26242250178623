import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
} from "../index";
import banner from "../../images/banners/repair-ipad3.jpeg";
import { Helmet } from "react-helmet";

const priceIpad3 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "2950 P",
  },
  {
    id: 2,
    option: "Замена корпуса/задней крышки",
    price: "5900 P",
  },
  {
    id: 3,
    option: "Замена аккумулятора",
    price: "2850 P",
  },
  {
    id: 4,
    option: "Замена микрофона",
    price: "2350 P",
  },
  {
    id: 5,
    option: "Замена динамика",
    price: "2350 P",
  },
  {
    id: 6,
    option: "Замена кнопки Home (домой)",
    price: "1850 P",
  },
  {
    id: 7,
    option: "Замена кнопки включения",
    price: "1700 P",
  },
  {
    id: 8,
    option: "Замена антенны",
    price: "1600 P",
  },
  {
    id: 9,
    option: "Ремонт Wi-Fi модуля",
    price: "4000 P",
  },
  {
    id: 10,
    option: "Замена датчика приближения",
    price: "1600 P",
  },
  {
    id: 11,
    option: "Замена сим-держателя",
    price: "1600 P",
  },
  {
    id: 12,
    option: "Замена основной камеры",
    price: "1600 P",
  },
  {
    id: 13,
    option: "Замена передней камеры",
    price: "1600 P",
  },
  {
    id: 14,
    option: "Замена разъема зарядки",
    price: "1600 P",
  },
  {
    id: 15,
    option: "Замена разъема наушников",
    price: "1600 P",
  },
  {
    id: 16,
    option: "Замена кнопки Home (домой)",
    price: "4000 P",
  },
];

const textOfIpad3 = [
  {
    id: 1,
    title: "Характерные поломки и типы ремонта iPad 3",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Чаще всего необходимость обратится за помощью в сервисный центр
          возникает в следствии неосторожности самого пользователя Айпад 3.
          Благо, дотошность купертиновцев в создании и сборке своих продуктов
          практически сводит на нет возможность заводского брака и изначальных
          неисправностей "яблочных" планшетов. Наиболее частые поломки связаны с
          механическими повреждениями: падение, сильный удар и т.п. При этом,
          чаще всего страдает экран iPad 3. Именно желание заменить разбитый или
          треснувший экран, и приводит в наш центр большинство обладателей
          данных планшетов. Вторая по популярности поломка - это различные
          повреждения корпуса, после которых требуется его замена.
        </p>
        <p className="repair-iphone-display__paragraph">
          Кроме того, источником и причиной болезни вашего Айпэд 3 может стать
          перегрев, в следствии неисправности аккумулятора, или же попадание
          влаги. В первом случае, скорее всего, все проблемы можно решить просто
          заменив аккумулятор на новый. А вот попадание влаги - проблема куда
          более коварная, т.к. при этом часто происходит замыкание, после
          которого может понадобится замена материнской платы.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Как происходит ремонт Айпада 3 в сц Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          В начале, конечно же, диагностика. В наших центрах она всегда и
          безусловно бесплатная. После этого, наш менеджер связывается с вами и
          еще раз уточняет необходимые работы и называет их стоимость. Цена
          после этого остаётся неизменной. Проходит двадцать минут - полчаса, и
          мы уже готовы вернуть вам ваш исправный iPad 3. Оплата происходит
          только после того, как вы протестировали планшет и смогли убедится в
          его полноценной и бесперебойной работе.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады видеть вас в наших центрах Москвы и помочь вам в любых
          проблемах ваших iPad 3!
        </p>
      </>
    ),
  },
];

const RepairIpadModelPagesIpad3 = () => {
  return (
    <Page title={"Ремонт iPad 3 в Москве в кратчайшие сроки с гарантией!"}>
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/3"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Профессиональный ремонт iPad 3 при клиенте в кратчайшие сроки. Привлекательные цены, удобное расположение, квалифицированный персонал!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPad 3"
          alt="ремонт ipad 3"
          titleImg="ремонт ipad 3"
          text={
            <>
              Мы занимаемся ремонтом iPad 3 в Москве. Наш сервис выгодно
              отличает отменное качество нашего ремонта, высокая квалификация
              наших специалистов, оперативность и умеренная цена. На все работы
              предоставляется гарантия, большинство видов ремонта iPad 3 мы
              производим за 20 минут.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpad3}
            title="Цены на ремонт iPad 3"
            text="Р - Российский рубль, все цены включают стоимость комплектующего и услуги по установки."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfIpad3.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpad3;
