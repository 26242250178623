import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone7.jpeg";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfModel7 = [
  {
    id: 1,
    title: "Специфика ремонта iPhone 7",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Особенности ремонта Айфонов 7 связаны с нововведениями, которые Apple
          применила в своих новых моделях.
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Из кнопки "Домой" яблочники изъяли все механические детали -
              теперь она полностью сенсорная. Таким образом, вместе с появлением
              на рынке семёрки ушли в прошлое все возможные поломки связанные с
              механикой кнопки.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Еще более была усилена защита телефона от влаги и пыли. Теперь
              вероятность того, что ваш iPhone 7 пострадает от воды, практически
              свелась на нет, что, безусловно, является плюсом. Минус же в том,
              что усиленная герметизация заставит всерьез попотеть специалистов
              при ремонте Айфона 7. Разобрать "яблочные" телефоны никогда не
              было простым делом, теперь же это дело для настоящих ювелиров.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выход шестого айфона был встречен волной слухов и интернет-юмора
              относительно его гнущегося корпуса. Не сказать чтобы это серьезно
              сказалось на продажах "шестёрок", но репутация Apple была
              подмочена. Специалисты кампании учли негативный опыт: iPhone 7,
              хоть и стал еще тоньше и легче, практически не поддаётся изгибу.
              Чтобы погнуть или, тем более, сломать телефон надо затратить
              максимум усилий.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Айфоны всегда "славились" в среде специалистов по ремонту своим
          проприетарным крепежом. Однако в случае с Айфон 7 Apple превзошли
          самих себя. Мало того что все винтики были созданы заново, так и еще и
          целых четыре типа! Теперь производить ремонт iPhone 7 можно только
          вооружившись четырьмя различными типами отвёрток.
        </p>
        <p className="repair-iphone-display__paragraph">
          Подведём итоги. Создатели iPhone 7 поработали на славу, и постарались
          исключить целый ряд факторов возможных поломок. При разумном
          обращении, телефон прослужит вам верой и правдой не один год. Однако,
          если уж приключилась с вашим Айфон 7 неприятность, его ремонт ни в
          коем случае нельзя доверять кустарям, и уж тем более - пытаться
          произвести дома. Такое произведение инженерной и технической мысли как
          iPhone 7 требует заботы рук профессионалов и мастеров своего дела.
          Таких, например, как работают в нашем центре.
        </p>
      </>
    ),
  },
];

const price7 = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage7 = () => {
  return (
    <Page title="Ремонт iPhone 7 в Москве! Бесплатная курьерская доставка Айфона 7 в СЦ">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/7"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Срочный ремонт iPhone 7 по доступным ценам. Производим ремонт Айфон 7 в большинстве случаем за 20 минут с гарантией!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 7"
          titleImg="Ремонт iPhone 7"
          banner={banner}
          title="Ремонт iPhone 7"
          text={
            <>
              Наша специализация - оперативный и профессиональный ремонт iPhone
              7 в Москве. Произведём весь спектр ремонтных работ, устраним
              практически любые неприятности, с которыми может столкнуться
              владелец седьмого айфона. На все виды наших работ предоставляется
              гарантия до года, ремонт производится профессионально и тщательно,
              но и оперативно: большую часть поломок мы сможем устранить за 20
              минут.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price7}
            title="Стоимость ремонта Айфон 7"
            text="* Все цены в рублях РФ. В цену входит комплектующее и услуга по установки."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel7.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage7;
