import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIMacLinks,
} from "../index";
import banner from "../../images/banners/imac-cleaning.jpeg";
import { Helmet } from "react-helmet";

const textBanner = [
  {
    id: 1,
    text: "Пыль и копоть на поверхности матрицы. Проблема появляется при перегреве iMac, повышенной запыленности. В данном случае чистка матрицы iMac устранит неисправность.",
    number: "01",
  },
  {
    id: 2,
    text: "Следы отпечатков пальцев на поверхности матрицы iMac. Следы отпечатков появляются в результате механического вмешательства, в данном случае чистка матрицы устранит проблему, матрица будет выглядеть как новая.",
    number: "02",
  },
  {
    id: 3,
    text: "Разводы на поверхности матрицы iMac. Проблема может быть вызвана как механическим вмешательством, так и неисправностью матрицы. В 50% случаев вам не нужно менять матрицу с такой неисправностью, будет достаточно произвести ее чистку.",
    number: "03",
  },
];

const text = [
  {
    id: 1,
    text: "Для начала, мастера разбирают оборудование и снимают матрицу.",
    number: "01",
  },
  {
    id: 2,
    text: "Затем, снимается защитная крышка и открывается доступ к плате матрицы.",
    number: "02",
  },
  {
    id: 3,
    text: "После снятия самой матрицы, получаем доступ ко всем местам, в которых может быть загрязнение.",
    number: "03",
  },
  {
    id: 4,
    text: "Далее, вся грязь вычищается с помощью специальных средств, которые наш сервисный центр приобретает у официальных поставщиков.",
    number: "04",
  },
  {
    id: 5,
    text: "Очищаются поверхность отражателя подсветки и поверхность стекла матрицы.",
    number: "05",
  },
  {
    id: 6,
    text: "Осуществляется сборка оборудования в обратном порядке.",
    number: "06",
  },
];

const textWithCkeckMark = [
  {
    id: 1,
    title: "Как производится процесс чистки матрицы iMac",
    text: (
      <RepairAppleTextWithCheckMark
        array={text}
        paragraphDown={
          <>
            Следует отметить, что чистка матрицы iMac производится в чистом
            герметичном помещении и максимально аккуратно, что исключает
            попадание пыли в слои матрицы.
          </>
        }
      />
    ),
  },
];

const textWithoutChechMark = [
  {
    id: 1,
    title: "Гарантии на чистку матрицы iMac",
    text: "Собранный iMac после очистки передаётся в отдел контроля качества и проверяется на исправность - специалисты сервисного центра MacProfi с должным вниманием относятся к имуществу своих клиентов и, потому, в итоге Вы получаете качественный результат по приемлемым ценам. Сервисный центр предоставляет гарантию 3 месяца, в течении гарантийного периода, если попадает пыль в матрицу iMac, мы бесплатно повторно произведем ее чистку.",
  },
];

const RepairIMacLinkMatrixCleaning = () => {
  return (
    <Page title="Чистка матрицы iMac 21, 24, 27 от пыли и копоти">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-imac/chistka_matricy_imac"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Производим чистку матриц на iMac всех моделей и годов выпуска. Профессиональная чистка матрицы производится высококвалифицированными специалистами с применением спец средств!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Чистка матрицы iMac всех моделей от копоти и пыли"
          alt="чистка матрицы imac"
          titleImg={"чистка матрицы imac"}
          text={
            <RepairAppleTextWithCheckMark
              paragraphUp={
                <>
                  Сервисный центр Mac Profi специализируется на чистки матриц в
                  iMac. В каких случаях требуется чистка матрицы iMac:
                </>
              }
              array={textBanner}
            />
          }
        />
        <Section className="section_group">
          {textWithCkeckMark.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <Section className="section_group" style={{ paddingBottom: 18 }}>
          {textWithoutChechMark.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIMacLinks />
      </main>
    </Page>
  );
};

export default RepairIMacLinkMatrixCleaning;
