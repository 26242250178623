import React from "react";
import { Section } from "../index";

const RepairIpodTitle = () => {
  return (
    <Section>
      <div className="repair-ipod__title-container">
        <article>
          <h1 className="repair-ipod__title-title">Ремонт iPod</h1>
          <div className="repair-ipod__title-border"></div>
          <p className="repair-ipod__title-paragraph">
            Ремонт iPod: быстрое восстановление любых моделей плеера в сервисе
            Мак Профи. Проведение ремонта любой сложности. Замена корпуса,
            экрана и аккумулятора. Гарантии.
          </p>
          <p className="repair-ipod__title-paragraph">
            iPod – это возможность наслаждаться любимой музыкой в любое время.
            Компактный плеер отличается не только аккуратностью и красотой
            внешнего вида, но и надежностью – вот только не бывает техники,
            которая на 100% защищена от поломок. Если с вашим гаджетом произошла
            неприятность, поспешите в Мак Профи – предоставим все необходимые
            услуги на выгодных и прозрачных условиях.
          </p>
        </article>
        <div className="repair-ipod__title-icon"></div>
      </div>
    </Section>
  );
};

export default RepairIpodTitle;
