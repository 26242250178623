import React from "react";
import { Link } from "react-router-dom";
import Section from "../Section/Section";
import { useMedia } from "../../utils/useMedia";
import MacProfiListMobile from "./Mac-profiListMobile";

const links = [
  {
    to: "/remont-apple/remont-imac",
    text: "ремонт",
    gadget: "iMac",
    classImg: "mac-profi__list-icon",
  },
  {
    to: "/remont-apple/remont-macbook",
    text: "ремонт",
    gadget: "MacBook",
    classImg: "mac-profi__list-icon-note",
  },
  {
    to: "/remont-apple/remont-ipad",
    text: "ремонт",
    gadget: "iPad",
    classImg: "mac-profi__list-icon-ipad",
  },
  {
    to: "/remont-apple/remont-iphone",
    text: "ремонт",
    gadget: "iPhone",
    classImg: "mac-profi__list-icon-iphone",
  },
  {
    to: "/remont-apple/remont_mac_pro",
    text: "ремонт",
    gadget: "Mac Pro",
    classImg: "mac-profi__list-icon-macpro",
  },
  {
    to: "/remont-apple/remont_mac_mini",
    text: "ремонт",
    gadget: "Mac Mini",
    classImg: "mac-profi__list-icon-macmini",
  },
  {
    to: "/remont-apple/remont_apple_watch",
    text: "ремонт",
    gadget: "Apple Watch",
    classImg: "mac-profi__list-icon",
  },
  {
    to: "/remont-apple/remont-ipod",
    text: "ремонт",
    gadget: "iPod",
    classImg: "mac-profi__list-icon-ipod",
  },
  {
    to: "#",
    text: "Разработка",
    gadget: "под iOS",
    classImg: "mac-profi__list-icon-ios",
  },
];

function ChangeMenu() {
  const isBreakpoint = useMedia("(max-width: 680px)");
  if (isBreakpoint) {
    return <MacProfiListMobile />;
  }
  return (
    <Section className="mac-profi__list-container">
      <div className="mac-profi__list-contant">
        <ul className="mac-profi__list">
          {links.map((link, i) => {
            return (
              <li key={i} className="mac-profi__list-item">
                <Link to={link.to} className="mac-profi__list-link">
                  <div className={link.classImg}></div>
                  <p className="mac-profi__list-repair">{link.text}</p>
                  <p className="mac-profi__list-name">{link.gadget}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

const MacProfiList = () => {
  return ChangeMenu();
};

export default MacProfiList;

// toDo:
// заменить теги a на реактовские Link или NavLink
// проверить семантику. может есть более подходящий тег вместо дива?
