import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIwatchBanner,
  RepairIwatchTitle,
  RepairIwatchText,
  RepairIwatchModeslLinks,
} from "../index";
import { Helmet } from "react-helmet";

const RepairIwatchPage = () => {
  return (
    <main className="repair-iwatch__page">
      <Helmet>
        <link
          rel="canonical"
          href="https://ohmygadget.ru/remont-apple/remont_apple_watch"
        />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Сервисные центры Oh!MyGadget! производят ремонт Apple Watch в Москве и регионах РФ. Стоимость ремонта Apple Watch, сроки ремонта."
        />
      </Helmet>
      <Page title="Ремонт часов Apple Watch в Москве">
        <MacProfiList />
        <BreadCrumbs />
        <RepairIwatchBanner />
        <RepairIwatchTitle />
        <RepairIwatchText />
        <RepairIwatchModeslLinks />
      </Page>
    </main>
  );
};

export default RepairIwatchPage;
