import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
  RepairAppleTableMobile,
} from "../index";
import banner from "../../images/banners/mb-windows.jpeg";
import { useMedia } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

const listOfWindows = [
  {
    id: 1,
    number: "01",
    subtitle: "Лицензия.",
    textLi:
      "В нашем сервисном центре производится установка только с лицензионных дистрибутивов, что гарантирует стабильную работу Windows на всем протяжении использования системы.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Качество.",
    textLi:
      "После установки Windows настраиваются полностью все драйверы, полностью обновляется Windows. Данная процедура гарантирует качественную работу программного обеспечения, Windows после установки будет работать безотказно и без конфликтов с вашим аппаратным обеспечением.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Сроки.",
    textLi:
      "Установка Windows в сервисном центре занимает от 1-го до 4-х часов в зависимости от мощности вашего iMac или MacBook. В стационарных условиях вам гарантируется, что все программное обеспечение будет протестировано после установки на предмет безотказной работоспособности. Мы экономим ваше время и предоставляем лучшие условия для вашего комфорта.",
  },
];

const iMacWindowsPrice = [
  {
    service: "Установка Windows 7 на iMac",
    time: "от 1-го часа",
    price: "1000 р.",
  },
  {
    service: "Установка Windows 8 на iMac",
    time: "от 2-х часов",
    price: "1500 р.",
  },
  {
    service: "Установка Windows XP на iMac",
    time: "от 1-го часа",
    price: "500 р.",
  },
];
const macBookWindowsPrice = [
  {
    service: "Установка Windows 7 на MacBook",
    time: "от 1-го часа",
    price: "от 1,5 часов.",
  },
  {
    service: "Установка Windows 8 на MacBook",
    time: "от 2-х часов",
    price: "1500 р.",
  },
  {
    service: "Установка Windows XP на MacBook",
    time: "от 1-го часа",
    price: "500 р.",
  },
];
const iMacWindowsOptions = [
  {
    value: "Установка Windows на iMac",
    label: "Установка Windows на iMac",
    list: iMacWindowsPrice,
  },
  {
    value: "Установка Windows на MacBook",
    label: "Установка Windows на MacBook",
    list: macBookWindowsPrice,
  },
];

function ChangeTablePriceWindows() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={iMacWindowsOptions}
        serviceText="Наименование услуги"
      />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>НАИМЕНОВАНИЕ УСЛУГИ</th>
          <th>Стоимость</th>
          <th>Сроки установки</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка Windows 7 на iMac</td>
          <td>1000 р.</td>
          <td>от 1-го часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка Windows 7 на MacBook</td>
          <td>1000 р.</td>
          <td>от 1,5 часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка Windows 8 на MacBook</td>
          <td>1500 р.</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка Windows 8 на iMac</td>
          <td>1500 р.</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка Windows XP на iMac</td>
          <td>500 р.</td>
          <td>от 1-го часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Установка Windows XP на MacBook</td>
          <td>500 р.</td>
          <td>от 1-го часа</td>
        </tr>
      </tbody>
    </table>
  );
}

const RepairMacBookLinkWindows = () => {
  return (
    <Page title="Установка Windows 7, 8, XP на MacBook и iMac">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/ustanovka_windows_na_macbook_imac"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Предлагаем быструю и качественную установку Windows на iMac, MacBook. Только лицензионное программное обеспечение, профессиональная настройка системы."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="установка windows на macbook и imac"
          titleImg="установка windows на macbook и imac"
          banner={banner}
          title="Установка Windows 7, 8, XP на&nbsp;MacBook и&nbsp;iMac"
          text="В нашем сервисном центре вы можете заказать услугу по установке Windows XP, Windows 7, Windows 8, Windows 10 на ваш MacBook или iMac. Преимущество установки Windows на Макбук и Аймак в нашем сервисном центре:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfWindows.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на установку Windows на&nbsp;MacBook и&nbsp;iMac
            </h2>
            {ChangeTablePriceWindows()}
            <p className="repair-mb__group-links-paragraph">
              Если вам требуется установка Windows в Parallels (виртуальная
              машина в MacOS), стоимость Parallels Desktop 2640 руб.
            </p>
            <h2 className="repair-mb__group-links-subtitle">
              Стоимость дистрибутива с Windows XP, 7, 8
            </h2>
            <table>
              <tbody>
                <tr className="mb-matrix__table-title">
                  <th>НАИМЕНОВАНИЕ ЛИЦЕНЗИИ</th>
                  <th>Стоимость</th>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows XP Home Basic</td>
                  <td>1200 р.</td>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows XP Home Premium</td>
                  <td>1800 р.</td>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows 7 Home Basic</td>
                  <td>3000 р.</td>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows 7 Home Premium</td>
                  <td>4500 р.</td>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows 7 Professional</td>
                  <td>6000 р.</td>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows 8</td>
                  <td>5000 р.</td>
                </tr>
                <tr className="mb-matrix__table-title">
                  <td>Windows 8 Professional</td>
                  <td>8000 р.</td>
                </tr>
              </tbody>
            </table>
            <p className="repair-mb__group-links-paragraph">
              Указана стоимость лицензионного дистрибутива Windows. Если у вас
              имеется свой лицензионный дистрибутив с фирменным логотипом,
              наклейкой с лицензионным ключом и галограммой - вы можете привезти
              свой дистрибутив и мы произведем установку вашего Windows. Если
              Windows устанавливается с вашего дистрибутива - вы оплачиваете
              только стоимость установки.
            </p>
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkWindows;

// toDo:
// вынести в константы массив
// вынести banner в индекс
