import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/batteryIpadPro12.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Скачки напряжения, возникающие во время зарядки планшета",
    number: "01",
  },
  {
    id: 2,
    text: "Короткое замыкание, вызванное появлением конденсата из-за резкого перепада температур или попаданием влаги",
    number: "02",
  },
  {
    id: 3,
    text: "Механические воздействия, которые привели к повреждению шлейфа, коннектора или других элементов системы.",
    number: "03",
  },
];

const listOfRecommendations = [
  {
    id: 1,
    text: "Нагрев корпуса",
    number: "01",
  },
  {
    id: 2,
    text: "Удаление основных винтов",
    number: "02",
  },
  {
    id: 3,
    text: "Поочередное отключение шлейфов, которые мешают добраться до источника питания",
    number: "03",
  },
  {
    id: 4,
    text: "Удаление старой и установка новой батареи",
    number: "04",
  },
  {
    id: 5,
    text: "Обратная сборка устройства",
    number: "05",
  },
  {
    id: 6,
    text: "Тестирование автономной работы",
    number: "06",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: <>Из-за чего нужна замена аккумулятора iPad&nbsp;Pro&nbsp;12.9</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="При правильной эксплуатации устройства, его аккумулятор способен прослужить на порядок дольше установленного срока. Но существует ряд факторов, которые приводят к его преждевременному износу. Замена аккумулятора iPad Pro 12.9 может потребоваться по следующим причинам:"
          array={listOfTrouble}
          paragraphDown="Также стоит беречь iPad от холода и прямых солнечных лучей, стараться полностью не разряжать батарею и ни в коем случае не оставлять устройство в выключенном состоянии и без подзарядки на длительный период времени. Это позволит значительно сократить количество посещений сервисного центра и не испытывать неудобств, работая на планшете."
        />
      </>
    ),
  },
  {
    id: 2,
    title: <>Этапы замены аккумулятора iPad&nbsp;Pro&nbsp;12.9</>,
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Своими силами разбирать планшет не рекомендуется – без оборудования и инструментов с этой задачей аккуратно не справиться. Процедура замены батареи включает в себя следующие этапы:"
          array={listOfRecommendations}
          paragraphDown="Сроки оговариваются индивидуально, но в срочном порядке процедура может быть выполнена и за полчаса. Точную стоимость и время восстановления мастер сможет назвать только после диагностики, которая проводится абсолютно бесплатно."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelPro12 = () => {
  return (
    <Page title="Замена аккумулятора Apple iPad Pro 12.9 в Москве в Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-akkumuljatora/pro-12"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Теперь замена аккумулятора iPad Pro 12.9 не составляет проблем, грамотные специалисты всегда готовы взяться за выполнение задач, и сервис Oh!MyGadget! в Москве ждет клиентов."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена аккумулятора ipad pro 12.9"
          titleImg="замена аккумулятора ipad pro 12.9"
          banner={banner}
          title="Замена аккумулятора Apple iPad Pro 12.9"
          text="Планшет iPad Pro 12.9 – прекрасная альтернатива даже полноценному компьютеру или ноутбуку, ведь он отличается отменным быстродействием и функциональностью. Вот только спустя несколько лет активного пользования, аккумулятор устройства начинает постепенно «сдавать» свои позиции: медленно заряжается, разряжается меньше, чем за 5-6 часов, планшет периодически отключается. Это последствия естественного износа батареи, которые можно устранить лишь установкой нового элемента питания – вы сможете воспользоваться этой услугой в сервисном центре Мак Профи."
        />
        <RepairApplePrice text="Замена аккумулятора" price="6900 P" />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelPro12;
