import React from "react";
import Section from "../Section/Section";

const RepairIphoneInfo = () => {
  return (
    <Section>
      <div className="repair-iphone__info-container">
        <h1 className="repair-iphone__info-title">Ремонт Iphone</h1>
        <div className="repair-iphone__info-section">
          <div className="repair-iphone__info-wrapper">
            <h3 className="repair-iphone__info-qwerty">
              <div className="repair-iphone__info-border"></div>
              Айфон радовал своей работой, но теперь он сломан, пришел в
              негодность?
            </h3>
            <p className="repair-iphone__info-text">
              Паниковать не стоит, ведь это вполне решимая проблема, и, несмотря
              на всю чувствительность этого высокотехнологичного оборудования, в
              большинстве случаев оно поддается ремонту. Незачем спешить
              избавляться от дорогостоящего гаджета, ведь его ремонт позволит
              восстановить его работоспособность при вполне посильных вложениях.
            </p>
          </div>
          <div className="repair-iphone__info-image-iphone"></div>
        </div>
        <div className="repair-iphone__info-section">
          <div className="repair-iphone__info-image-stick"></div>
          <div className="repair-iphone__info-wrapper">
            <h3 className="repair-iphone__info-qwerty">
              Он отказал полностью, был поврежден, или начал работать со сбоями?
            </h3>
            <p className="repair-iphone__info-text">
              Cегодня многие мастерские предлагают ремонт iPhone однако для
              того, чтобы получить гарантированный результат, вам стоит
              обратиться к специалистам. Только профессиональный исполнитель
              сможет выполнить такую работу во всех нюансах, и дать технике шанс
              вернуться к нормальному режиму работы. Не стоит забывать, что
              Айфон – это сложная и чувствительная техника, ремонт которой
              приближен к ювелирному искусству, требует не только знаний, умений
              и навыков, но и соответствующего образования, использования строго
              определенного оборудования, и соблюдения множества других нюансов.
              Только в этом случае результат будет успешным и полноценным.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default RepairIphoneInfo;
