import { BASE_URL } from "../constants";

export class MyFetchError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

export const myFetch = async (url, options = {}) => {
  const headers = {
    "Content-Type": "application/json",
    ...options.headers,
  };

  const urlClean = url.startsWith("/") ? url.slice(1) : url;

  const response = await fetch(`${BASE_URL}/${urlClean}`, {
    credentials: "include",
    ...options,
    headers,
  });

  if (!response.ok) {
    throw new MyFetchError(`Ошибка: ${response.status}`, response);
  }

  try {
    return await response.json();
  } catch (error) {}
};
