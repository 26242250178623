import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIpadBanner,
  RepairIpadInfo,
  RepairIpadPrice,
  RepairIpadAboutUs,
  RepairIpadModelLinks,
  RepairIpadText,
  RepairIpadServiceLinks,
  RepairIpadModelsLinksMobile,
} from "../index";
import { useMobileScreen } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

function PageModelLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairIpadModelsLinksMobile />;
  }
  return <RepairIpadModelLinks />;
}

const RepairIpadPage = () => {
  return (
    <main className="repair-ipad__page">
      <Helmet>
        <link
          rel="canonical"
          href="https://ohmygadget.ru/remont-apple/remont-ipad"
        />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Ремонт iPad в Москве осуществляет сервис Oh!MyGadget!. Гарантированно качественный ремонт Айпад, без переплат и в короткие сроки"
        />
      </Helmet>
      <Page title="Ремонт iPad в Москве, срочный ремонт Айпад.">
        <MacProfiList />
        <BreadCrumbs />
        <RepairIpadBanner />
        <RepairIpadInfo />
        <RepairIpadPrice />
        <RepairIpadAboutUs />
        {PageModelLinks()}
        <RepairIpadText />
        <RepairIpadServiceLinks />
      </Page>
    </main>
  );
};

export default RepairIpadPage;
