import React from 'react'
import { Section } from '../index';

const macProPrice = [
  {
    id: 1,
    option: 'Аппаратная/программная диагностика неисправности Mac PRO сервисном центре',
    price: 'БЕСЛПАТНО',
  },
  {
    id: 2,
    option: 'Установка программ на Mac PRO (цена зависит от программного обеспечения, которое вы хотите установить)',
    price: 'от 600 P',
  },
  {
    id: 3,
    option: 'Настройка программного обеспечения (стоимость зависит от сложности настройки конкретного программного обеспечения, точную цену настройки именно вашего пакета программ уточняйте у менеджеров по телефону)',
    price: 'от 500 P',
  },
  {
    id: 4,
    option: 'Восстановление данных (точная стоимость определяется после диагностики вашего HDD или SSD в сервисном центре)',
    price: 'от 2000 P',
  },
  {
    id: 5,
    option: 'Замена жесткого диска HDD/SSD (цена жесткого диска или SSD зависит от объема и модели)',
    price: 'от 1500 P',
  },
  {
    id: 6,
    option: 'Подключение и настройка монитора Thunderbold',
    price: 'от 600 P',
  },
  {
    id: 7,
    option: 'Настройка Wi-Fi роутера в том числе Time Capsule',
    price: 'от 700 P',
  },
  {
    id: 8,
    option: 'Профилактическая чистка (от пыли)',
    price: 'от 1900 P',
  },
  {
    id: 9,
    option: 'Замена термопасты',
    price: 'от 1700 P',
  },
  {
    id: 10,
    option: 'Замена системы охлаждения',
    price: 'от 700 P',
  },
  {
    id: 11,
    option: 'Ремонт системной платы 1-й категории (программирование BIOS)',
    price: 'от 500 P',
  },
  {
    id: 12,
    option: '	Ремонт системной платы 2-й категории (монтаж/демонтаж BIOS)',
    price: 'от 600 P',
  },
  {
    id: 13,
    option: 'Ремонт системной платы 3-й категории (дискретные элементы, шим-контроллер, шим-питания, усилители и др.)',
    price: 'от 800 P',
  },
  {
    id: 14,
    option: 'Ремонт системной платы 4-й категории (BGA, видеочип, северный мост, южный мост, системная логика, мультиконтроллер и др.)',
    price: 'от 800 P',
  },
  {
    id: 15,
    option: 'Замена USB, HDMI, Audio и пр. внешних разъемов',
    price: 'от 700 P',
  },
  {
    id: 16,
    option: 'Замена разъема слота памяти, разъема плоских шлейфов, и др.',
    price: 'от 700 P',
  },
  {
    id: 17,
    option: 'Ремонт/замена корпусных деталей',
    price: 'от 500 P',
  }
]

const RepairMacProPrice = () =>  {
  return (
    <Section style={{paddingBottom: 47}}>
      <div className='repair-mb__price-container'>
        <h1 className='repair-mb__price-title'>Ремонт Mac Pro в Москве</h1>
        <ul className='repair-mb__price-list'>
          {macProPrice.map((item) => {
            return (
              <li key={item.id} className='repair-mb__price-item'>
                <h4 className='repair-mb__price-text'>{item.option}</h4>
                <p className='repair-mb__price-price'>{item.price}</p>
              </li>
            )
          })}
          <p className='repair-mb__price-paragraph'>* р. - Российский Рубль (все цены на сайте указаны в рублях). Все цены указаны без учета стоимости комплектующих, стоимость комплектующего уточняйте у менеджера по телефону.</p>
        </ul>
      </div>
    </Section>
  )
};

export default RepairMacProPrice;