import React from "react";
import Section from "../Section/Section";
import bannerMacPro from "../../images/banners/repair-mb-banner.png";

const RepairMacProBanner = () => {
  return (
    <Section>
      <img
        src={bannerMacPro}
        alt="ремонт mac pro"
        title="ремонт mac pro"
        className="repair-mb__banner"
      />
    </Section>
  );
};

export default RepairMacProBanner;
