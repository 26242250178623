import React from "react";
import { Section } from "../index";

const RepairIpadInfo = () => {
  return (
    <Section>
      <div className="repair-ipad__info-container">
        <h1 className="repair-ipad__info-title">Ремонт iPad</h1>
        <div className="repair-ipad__info-wrapper">
          <div>
            <h2 className="repair-ipad__info-subtitle">
              Сломался Айпад, и вы не можете разобраться, что с ним?
            </h2>
            <div className="repair-ipad__info-border"></div>
            <article className="repair-ipad__info-article">
              <p className="repair-ipad__info-paragraph">
                Он упал в воду, получил механические повреждения при падении,
                или просто внезапно перестал корректно работать? Эта неприятная
                ситуация не должна вас расстраивать, ведь ремонт подобных
                девайсов для профессионалов не составляет никакой сложности.
                Наши мастера успешно осуществляют ремонт iPad уже не первый год,
                и мы справляемся с задачами любой сложности, успешно
                диагностируя поломку и устраняя ее в кратчайшие сроки.
                Обращайтесь в сервис Мак Профи для того, чтобы получить
                качественный результат под гарантию и услуги ремонта, в том
                числе и срочного, который может быть осуществлен за считанные
                часы.
              </p>
              <div className="repair-ipad__info-icon"></div>
            </article>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default RepairIpadInfo;
