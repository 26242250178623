import React from "react";
import {
  MacProfiList,
  BreadCrumbs,
  RepairMacProBanner,
  Page,
  RepairMacProPrice,
} from "../index";
import { Helmet } from "react-helmet";

const RepairMacProPage = () => {
  return (
    <main className="repair-mb__page">
      <Helmet>
        <link
          rel="canonical"
          href="https://ohmygadget.ru/remont-apple/remont_mac_pro"
        />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Ремонт рабочих станций Mac Pro, детальная информация по ремонту, ценам, срокам и запчастям. Москва, Санкт-Петербург"
        />
      </Helmet>
      <Page title="Ремонт Mac Pro в Москве">
        <MacProfiList />
        <BreadCrumbs />
        <RepairMacProBanner />
        <RepairMacProPrice />
      </Page>
    </main>
  );
};

export default RepairMacProPage;
