import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone7plus.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfMicrophone7Plus = [
  {
    id: 1,
    title: "Грамотно меняем микрофон для вашего iPhone 7 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если такое случилось, вам необходимо обратиться к специалистам,
          способным реализовать ремонт быстро и качественно. Сервисный центр Мак
          Профи всегда рад клиентам, и здесь вы сможете получить ремонтные
          услуги в полном объеме, в качественном исполнении и с гарантией.
          Мастерская работает официально, с заключением договоров, полной
          ответственностью за оказанные услуги. Мы придерживаемся выгодной для
          клиента ценовой политики, работаем качественно и грамотно, оперативно
          – возможен и срочный ремонт.
        </p>
        <p className="repair-iphone-display__paragraph">
          Обращаясь к нам, вы доверяете технику проверенному исполнителю с
          хорошими отзывами и достойной репутацией.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас имеется все необходимое для работ техническое оснащение, которое
          обеспечивает скорость и качество выполнения работ. Запчасти тоже
          всегда под рукой, и на них дается отдельная гарантия – каждая деталь
          имеет официальное происхождение и достойное качество. Работами
          занимаются только компетентные лица с опытом и детальным пониманием
          устройства современных гаджетов – дилетантский подход мы не приемлем.
          Все это становится залогом нашего успеха.
        </p>
        <p className="repair-iphone-display__paragraph">
          У нас вы сможете получить бесплатную диагностику, после которой
          узнаете стоимость ремонта. После нее мы сможем немедленно приступить к
          устранению поломки, и уже вскоре ваш девайс будет работать как новый!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels7Plus = () => {
  return (
    <Page title="Грамотная замена микрофона iPhone 7 Plus с гарантией - Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-mikrofona/7-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если потребовалась замена микрофона iPhone 7 Plus, мы готовы выполнить ее для вас в любой момент быстро и качественно, обращайтесь в Oh!MyGadget!."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена микрофона iPhone 7 Plus"
          titleImg="Замена микрофона iPhone 7 Plus"
          banner={banner}
          title="Замена микрофона iPhone 7 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с любимым гаджетом портят настроение мгновенно.
                Несмотря на то, что Айфоны признаются качественной и долговечной
                техникой, не склонной к неполадкам, проблемы в их эксплуатации
                порой все же наступают. У них есть свои слабые места, к которым
                специалисты относят и микрофон, который может отказать при
                определенном стечении обстоятельств. Однако паниковать не стоит:
                профессиональный ремонт позволит вернуть технику в рабочее
                состояние и убрать эту проблему.
              </p>
              <p className="repair-iphone-display__paragraph">
                Главное – обратиться для этого к хорошим специалистам, а не
                пытаться вскрыть и отремонтировать чувствительно оборудование
                своими силами. Ведь высокотехнологичное решение требует к себе
                соответствующего отношения, а домашний ремонт может только
                усугубить проблему, что приведет к необходимости еще более
                дорогого ремонта или замены аппаратуры. Не желаете сталкиваться
                с такими сложностями? В таком случае вам необходимо просто найти
                хороших специалистов, которые выполнят работу ответственно и с
                пониманием.
              </p>
            </>
          }
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="2400 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Судить о том, что необходима именно замена микрофона iPhone 7
                Plus, позволяют некоторые симптомы. Так, речь идет именно о
                такой проблеме, если:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Пропала слышимость, и абонент не слышит вас вообще,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Звук стал очень тихим, а с настройками все в порядке,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Есть посторонние шумы, дефекты звука.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                В последних двух случаях может быть необходима чистка, а вот
                первый со стопроцентной точностью указывает на проблемы с
                микрофоном. Но ремонт возможен в любом случае, и потому
                переживать не стоит.
              </p>
              <p className="repair-iphone-display__paragraph">
                Некоторых людей интересует, почему же могут возникнуть такие
                проблемы. Причин тому может быть немало, и в частности, отказ
                может произойти после падения техники, удара, механического
                повреждения. Динамик может окислиться, отказать из-за попадания
                влаги, а кроме того, он может просто износиться. Какой бы ни
                была причина, ремонт, опять же, остается вполне реализуемым, и
                мастера справятся со своей задачей. А пользователю же стоит
                подумать о необходимости использования защитного чехла, который
                существенно снижает риск поломки чувствительного девайса, и
                кроме того, о необходимости аккуратного с ним обращения. Эти два
                простейших фактора значительно снижают риск поломки. Однако что
                же делать, если проблема уже произошла, и ее надо решать?
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone7Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels7Plus;
