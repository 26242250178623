import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display6s.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfDisplay6s = [
  {
    id: 1,
    title: "Замена дисплея iPhone 6s: прежде всего - диагностика",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Что интересно, далеко не всегда проблема заключается именно в экране –
          нередко требуется ремонт других элементов смартфона. Например, при
          нарушенной работе сенсора может потребоваться восстановление
          контролера дисплея, а полосы на экране могут свидетельствовать не
          только о повреждениях экрана, но и замене микросхем.
        </p>
        <p className="repair-iphone-display__paragraph">
          Именно поэтому диагностика в нашем центре абсолютно бесплатна – мастер
          осмотрит устройство, оценит его состояние и сможет понять, в чем
          заключается поломка. В зависимости от объемов повреждений, подбираются
          оптимальные способы восстановления – ремонт будет выполнен в течение
          нескольких часов.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Почему стоит доверить замену дисплея iPhone 6s именно нам",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          В нашем сервисном центре вы сможете быстро и не дорого починить
          поврежденный iPhone, а также получить необходимые дополнительные
          услуги. А главное, с нами выгодно, ведь именно у нас клиенты получают
          следующие выгоды:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Оперативное восстановление – быстро исправим возникшую проблему,
              проведя диагностику смартфона и выполнив замену дисплея
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Индивидуальный подход – поддерживаем стабильно высокий уровень
              сервиса, уделяя достаточное внимание каждому клиенту
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выгодные цены – наши расценки вполне доступны, а для постоянных
              клиентов действуют специальные скидки
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Гарантия качества – вы получаете уверенность в том, что на
              поверхности корпуса не появятся мелкие трещины и сколы, а
              устройство порадует стабильной работой без каких-либо сбоев.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Если вам необходима замена дисплея Айфон 6s – позвоните нам прямо
          сейчас или же посетите сервисный центр в рабочее время, чтобы
          восстановить работу устройства и вновь наслаждаться отличным качеством
          изображения.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels6s = () => {
  return (
    <Page title="Замена дисплея iPhone 6s, поменять дисплей на Айфон 6s с гарантией быстро">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-displeja/6s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстро и качественно поменяем дисплей на iPhone 6s за 30 минут при Вас в центре Москвы!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена дисплея iPhone 6s"
          titleImg="Замена дисплея iPhone 6s"
          banner={banner}
          title="Замена дисплея iPhone 6s"
          text="Никто не застрахован от случайностей – достаточно всего одной трещины на экране, чтобы устройством стало невозможно пользоваться. Сенсор действует произвольно, а некоторые участки и вовсе не нажимаются, а потому значительное число функций становится недоступным. В нашем сервисном центре выполняется замена дисплея iPhone 6s – восстановим геометрию корпуса и устраним поломку."
        />
        <RepairApplePrice
          subtitle="Сколько стоит замена дисплея Айфон 6s"
          text="Замена дисплея"
          price="3500 P"
          paragraph="В эту сумму уже включена цена запчастей, работы мастера, выполнения профилактики и другие сопутствующие затраты."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay6s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels6s;
