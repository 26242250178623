import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairAppleLinksBanner,
  Section,
  RepairAppleIphonePrice,
  RepairIpadModelLinks,
  RepairIpadServiceLinks,
  RepairAppleLinksText,
} from "../index";
import banner from "../../images/banners/repair-ipadMini4.jpeg";
import { Helmet } from "react-helmet";

const priceIpadMini4 = [
  {
    id: 1,
    option: "Замена сенсорного стекла (тачскрина)",
    price: "8800 P",
  },
  {
    id: 2,
    option: "Замена дисплея",
    price: "8800 P",
  },
  {
    id: 3,
    option: "Замена корпуса/задней крышки",
    price: "4500 P",
  },
  {
    id: 4,
    option: "Замена аккумулятора",
    price: "2500 P",
  },
  {
    id: 5,
    option: "Замена микрофона",
    price: "1700 P",
  },
  {
    id: 6,
    option: "Замена динамика",
    price: "2080 P",
  },
  {
    id: 7,
    option: "Замена кнопки Home (домой)",
    price: "2080 P",
  },
  {
    id: 8,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "2080 P",
  },
  {
    id: 9,
    option: "Замена кнопки включения",
    price: "2100 P",
  },
  {
    id: 10,
    option: "Замена антенны",
    price: "2080 P",
  },
  {
    id: 11,
    option: "Ремонт Wi-Fi модуля",
    price: "4500 P",
  },
  {
    id: 12,
    option: "Замена датчика приближения",
    price: "2080 P",
  },
  {
    id: 13,
    option: "Замена основной камеры",
    price: "2080 P",
  },
  {
    id: 14,
    option: "Замена передней камеры",
    price: "2080 P",
  },
  {
    id: 15,
    option: "Замена разъема наушников",
    price: "2080 P",
  },
];

const textOfIpadMini4 = [
  {
    id: 1,
    title: "Тонкости при ремонте iPad mini 4.",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          К сожалению, сочетания столь впечатляющих характеристик в столь
          миниатюрном корпусе "яблочникам" удалось достигнуть только за счёт
          ремонтопригодности планшета. В экране iPad mini 4 стекло и дисплей
          спаяны воедино. Что, переходя на язык рублей, означает: при разбитом
          или треснувшем стекле будьте готовы оплатить замену всего дисплейного
          модуля. Никак по другому нельзя. Сканер отпечатков также слит с
          дисплеем, разборка Айпад мини 4 в следствии этого превращается совсем
          уж в нелёгкое дело. По зубам она только исключительным специалистам.
          Хорошо что таковые у нас есть. Фирменный разъём Lighning инженеры из
          Купертино тоже решили спаять с системной платой. Ну и в довершение
          всех бед потенциальных ремонтников все внутренности iPad mini 4 щедро
          залиты специальным клеем. Так что при ремонте придётся "расчехлять"
          специальный фен, обращение с которым требует немалого навыка и умения,
          ведь при нагревании велика вероятность повредить чувствительные детали
          планшета. Такой фен у нас есть, да и обращаться с ним наши специалисты
          умеют в совершенстве.
        </p>
        <p className="repair-iphone-display__paragraph">
          Вывод можно сформулировать просто: не пытайтесь разбирать или чинить
          iPad mini 4 самостоятельно, а при выборе ремонтной мастерской не
          доверяйте сомнительным специалистам! Доверить ремонт Айпад мини 4
          лучше всего специалистам - таким как работающие у нас!
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Как мы производим ремонт iPad mini 4",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Все начинается с диагностики. Сразу отметим - данная услуга у нас
          бесплатная. После того как все неполадки вашего телефона выявлены, мы
          советуемся с вами - какие типы ремонта вы готовы оплатить. На данной
          стадии оглашается окончательная цена. Сам ремонт занимает в
          большинстве стандартных ситуаций не более получаса. Мы отдаём вам ваш
          телефон - вы убеждаетесь что всё работает как надо и оплачиваете нашу
          работу.
        </p>
        <p className="repair-iphone-display__paragraph">
          Будем рады видеть вас в наших центрах, ответить на любые ваши вопросы
          по телефону, и решить любые проблемы вашего iPad mini 4!
        </p>
      </>
    ),
  },
];

const RepairIpadModelPagesIpadMini4 = () => {
  return (
    <Page title={"Ремонт iPad Mini 4, срочный ремонт Айпад Мини 4"}>
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/mini-4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт iPad Mini 4 в сервисном центре Oh!MyGadget!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт iPad mini 4"
          alt="Ремонт iPad mini 4"
          titleImg="Ремонт iPad mini 4"
          text="Мы предлагаем услуги ремонта iPad mini 4 в Москве. Ремонт у нас отличает быстрота, исключительный профессионализм наших специалистов, демократическая и прозрачная цена, гарантия на 12 месяцев на все виды ремонта и все заменённые детали. Отремонтировать Айпад мини 4 у нас можно прийдя в один из наших центров, или же воспользовавшись услугами наших курьеров."
        />
        <Section className="section_group" style={{ padding: "50px 0 0" }}>
          {textOfIpadMini4.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <Section>
          <RepairAppleIphonePrice
            prices={priceIpadMini4}
            title="Стоимость ремонт Айпад Мини 4"
            text="Стоимость ремонта указана в рублях РФ."
          />
        </Section>
        <RepairIpadModelLinks />
        <RepairIpadServiceLinks />
      </main>
    </Page>
  );
};

export default RepairIpadModelPagesIpadMini4;
