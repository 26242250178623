import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker5.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfSpeaker5 = [
  {
    id: 1,
    title: "Помощь нашего сервиса по замене динамика iPhone 4s",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с проблемами динамика, и ваш Айфон перестал
          работать в этом отношении корректно, помощь наших квалифицированных
          специалистов будет кстати, и позволит решить проблему. У нас все
          работы проводятся в профессиональных условиях, на специально
          подготовленных рабочих местах, что сводит к риску неудачи и
          дополнительные осложнения. Мастерская оснащена по последнему слову
          техники, у нас есть все для пайки и выполнения множества других
          манипуляций, это позволяет работать не только быстро, но и
          обеспечивать качество любых выполняемых работ. Кроме того, современное
          оснащение позволяет выполнять работы любой сложности, и добиваться при
          этом необходимого результата. На все работы мы даем гарантии на долгий
          срок, берем на себя полную ответственность за результат.
        </p>
        <p className="repair-iphone-display__paragraph">
          Также в наличии всегда есть полный набор запчастей, которые могут
          пригодиться в ремонте, не исключая и динамика. Как правило, при
          неполадках возникает необходимость его замены, и вам не придется ждать
          долго, мастер сможет немедленно взять деталь заводского оригинального
          производства, установить ее и дать на нее гарантию. К работам у нас
          могут быть допущены только грамотные квалифицированные специалисты,
          которые знают об Айфонах все, и потому не имеют проблем при
          осуществлении диагностики и ремонта. Мы внимательно относимся ко всем
          аспектам, от которых может зависеть качество выполнения работ, и
          потому всегда предоставляем прекрасный результат. Мастерская имеет
          прекрасную репутацию и массу положительных отзывов, нас рекомендуют. А
          еще у нас можно получить ремонт по выгодной цене и без переплат, что
          также становится существенным преимуществом.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если со звуком Айфона возникли проблемы, обращайтесь к нам. Мастер
          выполнит бесплатную экспресс-диагностику, найдет причину неисправности
          и устранит ее в самые короткие сроки – снова поломка не повторится. На
          все работы и запчасти будет предоставлена гарантия, и все
          обязательства по ней будут выполнены. Обращайтесь, и профессиональный,
          качественный ремонт будет обеспечен вам на высочайшем уровне,
          грамотно, по выгодной цене. Все проблемы устранимы, и ваш Айфон снова
          будет работать как новый.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModels5 = () => {
  return (
    <Page title="Качественная замена динамика iPhone 5 в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-dinamika/5"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Профессиональная замена динамика iPhone 5 возможна в сервисном центре Oh!MyGadget!. Качественное исполнение, гарантии, профессиональный подход."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена динамика iPhone 5"
          titleImg="Замена динамика iPhone 5"
          banner={banner}
          title="Замена динамика iPhone 5"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Поломка Айфона – это крайне неприятное явление. И неисправная
                работа динамика тоже приносит мало радости, однако эта проблема
                оказывается на практике вполне устранимой. Если он отказал
                полностью, и вы не можете слушать музыку без наушников, не
                слышите собеседника, замена или ремонт позволят вернуть все на
                круги своя, и покупать новый дорогостоящий гаджет вам не
                придется. Существуют и другие симптомы проблем с динамиком, и
                среди таковых стоит отметить:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Хрипы на низких частотах,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Слабый звук или помехи в звучании,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Проблемы со слышимостью речи собеседника.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Все эти и некоторые другие типичные явления наглядно
                свидетельствуют о том, что необходим именно ремонт или замена
                динамика, и по счастью, это вполне осуществимо. В
                профессиональном исполнении такая работа отнимет минимум
                времени, позволит полноценно добиться необходимого результата.
              </p>
              <p className="repair-iphone-display__paragraph">
                Ремонт такого рода может выполняться и в срочном порядке,
                проблем в этом отношении не возникает. Но профессиональные
                условия выполнения работ и компетентность исполнителя в любом
                случае будут играть значимую роль в достижении необходимого
                результата, и об этом стоит помнить. Айфон – это чувствительный
                высокотехнологичный девайс, в ремонте он требует буквально
                ювелирного обращения, высокого профессионализма и применения
                специализированного оборудования. Без всего этого ремонт рискует
                создать еще больше проблем, и даже привести технику в
                окончательную негодность с необходимостью приобретения нового
                образца. Если вы желаете отремонтировать технику с
                гарантированным результатом, добиться ее работоспособности на
                долгий срок, обращайтесь к профессионалам!
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          title="Быстрая замена динамика iPhone 5 в нашем сервисе Мак Профи"
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1200 P"
          priceDown="1200 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Сервисный центр Мак Профи выполняет ремонт Айфонов
                профессионально, на высоком уровне, решая проблемы любой
                сложности. Ремонт динамика для наших специалистов – это вовсе не
                проблема, и они прекрасно справятся с такими работами,
                предоставив вам отличный результат. Мы работаем официально,
                имеем многолетний опыт и массу положительных отзывов, нас
                рекомендуют, а нашу работу высоко ценят. Специализация на
                высоких технологиях и самых современных гаджетах позволяет нам
                успешно справляться со всеми тонкостями.
              </p>
              <p className="repair-iphone-display__paragraph">
                Мастерская оснащена подобающим для этого образом, имеется все
                необходимое для выполнения сложнейших работ оборудование, что
                позволяет успешно сочетать скорость выполнения работ и качество.
                Кроме того, в наличии всегда есть необходимые запчасти, и потому
                срочный ремонт не становится проблемой – мы уважаем клиентов и
                не заставляем их ждать. Все детали имеют официальное заводское
                происхождение, мы даем на них долгосрочную гарантию.
              </p>
              <p className="repair-iphone-display__paragraph">
                Работы выполняют только профессиональные исполнители, с
                профильным образованием и большим опытом – они знают все о
                Айфонах, и потому без труда справляются с поставленными перед
                ними задачами. Ремонт динамика не составит для них труда.
              </p>
              <p className="repair-iphone-display__paragraph">
                Перед тем, как начать устранение поломки, необходимо провести
                диагностику, и мы прекрасно справляемся с этой задачей.
                Диагностика у нас бесплатная, она выполняется в экспресс-режиме,
                и после ее осуществления мастер может уверенно сказать, в чем
                причина проблем, сколько будет стоить ремонт, за сколько времени
                его удастся осуществить.
              </p>
              <p className="repair-iphone-display__paragraph">
                Наши расценки всегда остаются доступными и демократичными, мы
                делаем нашим клиентам только выгодные предложения. Обращайтесь к
                нам, чтобы получить ремонт на простых и выгодных основаниях, без
                переплат, в качественном профессиональном исполнении. Это
                исключит необходимость покупать новый девайс, и позволит
                пользоваться им еще очень долго, не сталкиваясь с новыми
                затруднениями.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeaker5.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels5;
