import React, { useState } from "react";
import Section from "../../Section/Section";
import CallBackFormPopup from "../../CallBackFormPopup/CallBackFormPopup";

const CenterMobileGeneralPageBannerMobile = ({ title, banner }) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <Section className="center-mobile__general-page-banner">
      <div
        className="center-mobile__general-page-banner-container"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="center-mobile__general-page-banner-content">
          <h1 className="center-mobile__general-page-banner-title">{title}</h1>
          <p className="center-mobile__general-page-banner-subtitle">
            по всей России
          </p>
          <p className="center-mobile__general-page-banner-text">
            <span style={{ color: "#ff4141" }}>&#42;</span> Диагностика
            бесплатно
          </p>
        </div>
      </div>
      <button
        className="center-mobile__general-page-banner-button"
        onClick={() => setIsOpenPopup(true)}
      >
        Мы Вам перезвоним!
      </button>
      <CallBackFormPopup isOpen={isOpenPopup} setIsOpen={setIsOpenPopup} />
    </Section>
  );
};

export default CenterMobileGeneralPageBannerMobile;
