import React from "react";
import { Carousel } from "react-responsive-carousel";
import Section from "../Section/Section";
import { Link } from "react-router-dom";
import { useMobileScreen } from "../../utils/useMedia";
import RepairIphoneModelLink from "../RepairIphoneModelLink/RepairIphoneModelLink";

function PageModelLinks(links) {
  const listOfModelIpadSlide1 = links.slice(0, 7);
  const listOfModelIpadSlide2 = links.slice(7, 14);
  const listOfModelIpadSlide3 = links.slice(14, 21);
  const listOfModelIpadSlide4 = links.slice(21);

  const isMobile = useMobileScreen();
  if (isMobile) {
    return (
      <Section className="repair-iphone__models-links-container">
        <div className="repair-iphone-mobile__models-links-content">
          <h2 className="repair-iphone-mobile__models-links-title">
            Больше детальной информации по каждой модели
          </h2>
          <Carousel
            autoPlay={false}
            infiniteLoop={true}
            swipeable={true}
            emulateTouch={true}
            showIndicators={true}
            stopOnHover={true}
            dynamicHeight={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <div className="repair-iphone-mobile__models-links-container">
              <ul className="repair-iphone-mobile__models-links-list">
                {listOfModelIpadSlide1.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="repair-iphone-mobile__models-links-item"
                    >
                      <div className="repair-mb__links-mobile-icon"></div>
                      <Link
                        to={link.href}
                        className="repair-iphone-mobile__models-links-link"
                      >
                        {link.model}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="repair-iphone-mobile__models-links-container">
              <ul className="repair-iphone-mobile__models-links-list">
                {listOfModelIpadSlide2.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="repair-iphone-mobile__models-links-item"
                    >
                      <div className="repair-mb__links-mobile-icon"></div>
                      <Link
                        to={link.href}
                        className="repair-iphone-mobile__models-links-link"
                      >
                        {link.model}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="repair-iphone-mobile__models-links-container">
              <ul className="repair-iphone-mobile__models-links-list">
                {listOfModelIpadSlide3.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="repair-iphone-mobile__models-links-item"
                    >
                      <div className="repair-mb__links-mobile-icon"></div>
                      <Link
                        to={link.href}
                        className="repair-iphone-mobile__models-links-link"
                      >
                        {link.model}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="repair-iphone-mobile__models-links-container">
              <ul className="repair-iphone-mobile__models-links-list">
                {listOfModelIpadSlide4.map((link, i) => {
                  return (
                    <li
                      key={i}
                      className="repair-iphone-mobile__models-links-item"
                    >
                      <div className="repair-mb__links-mobile-icon"></div>
                      <Link
                        to={link.href}
                        className="repair-iphone-mobile__models-links-link"
                      >
                        {link.model}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Carousel>
        </div>
      </Section>
    );
  }
  return (
    <RepairIphoneModelLink
      heading="Мы также производим замену:"
      links={links}
    />
  );
}

const RepairIpadModelLinksForServiceMobile = ({ links }) => {
  return PageModelLinks(links);
};

export default RepairIpadModelLinksForServiceMobile;
