import React from "react";
import { SectionTitle, AdvantageCard, Section } from "../index";
import { comfort, easy, quality } from "../../images/index";

const Advantages = () => {
  return (
    <Section className="advantages">
      <div className="advantages__container">
        <SectionTitle title="Преимущества" className="advantages__title" />
        <ul className="advantages__items">
          <li className="advantages__item">
            <AdvantageCard
              src={quality}
              title="Стандарт качества"
              text="Клиентский сервис, качество ремонта, гарантия в любой точке&nbsp;как в Столице (в&nbsp;главном офисе)."
              alt="контроль качества"
              titleImg="контроль качества"
            />
          </li>
          <li className="advantages__item">
            <AdvantageCard
              src={comfort}
              title="Удобно"
              text="Все бренды, в любой точке РФ, легкий доступ к&nbsp;информации о заказе и&nbsp;опциям управления заказом (оплата, доставка, доп. услуги)."
              alt="удобно работать"
              titleImg="удобно работать"
            />
          </li>
          <li className="advantages__item">
            <AdvantageCard
              src={easy}
              title="Просто"
              text="Легко сдать в сервис (звонок или кнопка на&nbsp;сайте), быстро продиагностируем и&nbsp;отремонтируем, выдадим или доставим."
              alt="легко сдать в сервис"
              titleImg="легко сдать в сервис"
            />
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default Advantages;
