import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertInsidePageBanner.module.css";

function NoteExpertInsidePageBanner({ banner, alt, title }) {
  return (
    <Section>
      <img src={banner} alt={alt} title={title} className={styles.banner} />
    </Section>
  );
}

export default NoteExpertInsidePageBanner;
