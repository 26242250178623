import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpad2.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Самостоятельная замена корпуса iPad 2 может привести к повреждениям стекла. В результате стоимость ремонта многократно увеличиться, ведь новый дисплей, а уж тем более тачскрин, является одной из самых дорогих частей устройства.",
    number: "01",
  },
  {
    id: 2,
    text: "Отказ от своевременного ремонта приводит к выходу планшета из строя. Деформированный корпус создает давление на внутренние части конструкции – при активной эксплуатации техника будет перегреваться, что может стать причиной поломке ряда систем.",
    number: "02",
  },
  {
    id: 3,
    text: "Проведение рихтовки целесообразно лишь при относительно небольших повреждениях. Если геометрия корпуса изменилась незначительно, то его можно легко восстановить, в противном случае лучше выполнить замену.",
    number: "03",
  },
];

const textOfCase2 = [
  {
    id: 1,
    title: "Замена корпуса iPad 2: важные рекомендации",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Дорогостоящее устройство требует профессионального подхода, а также использования фирменного оборудования и качественных расходных материалов. Только в этом случае можно гарантировать правильное проведение ремонта и бережное устранение появившихся повреждений. Существуют также некоторые нюансы, которые важно обязательно учитывать:"
          array={listOfTrouble}
          paragraphDown="Из-за особой конструкции техники Apple, в которой основные элементы соединены между собой, ремонт становится достаточно трудоемкой задачей. Эта работа требует внимательности и аккуратности, поэтому стоит доверить её выполнение квалифицированным мастерам, которые имеют достаточный опыт в этом вопросе."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModel2 = () => {
  return (
    <Page title="Срочная замена корпуса на iPad 2 в Москве в сервисе Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/2"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Честные цены на срочную замену корпуса iPad 2 – специальное предложение от сервисного центра Oh!MyGadget!. Бесплатная диагностика и гарантия на все виды работ."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad 2"
          titleImg="замена корпуса ipad 2"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;2</>}
          text="Если вы активно используете свой планшет, то повреждения на корпусе не станут неожиданностью. Уронить устройство очень легко, как и случайно ударить его, а в результате безупречная геометрия крышки будет нарушена. Если это произошло, вам стоит обратиться в сервис, в котором будет проведена рихтовка, что и позволит решить возникшую проблему."
        />
        <Section className="section_group">
          {textOfCase2.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена корпуса iPad 2"
          text="Замена сенсорного стекла (тачскрина)"
          price="12500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Полный перечень доступных услуг представлен на сайте – вы можете
                с ним ознакомиться, чтобы выбрать необходимые работы. Нередко в
                комплексе с заменой корпуса требуется установка и нового экрана
                – мы выполним обе задачи в один день, что позволит значительно
                сэкономить время. Стоимость указана в прайсе – все цены
                фиксированы и останутся неизменным в процессе ремонта. В общую
                сумму уже включена покупка комплектующих, а потому вам не
                придется доплачивать за используемые детали.
              </p>
              <p className="repair-iphone-display__paragraph">
                Приезжайте в Мак Профи в любой удобный день – и замена корпуса
                iPad 2 будет выполнена быстро и качественно. А на все виды работ
                будет предоставлена длительная гарантия.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModel2;
