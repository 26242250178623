import react from "react";
import Section from "../Section/Section";

const CooperationWithUs = () => {
  return (
    <Section className="cooperation-with__content">
      <div className="cooperation-with__container">
        <h2 className="cooperation-with__title">
          Сотрудничая с нами вы получаете:
        </h2>
        <ul className="cooperation-with__list">
          <li className="cooperation-with__item">
            <label className="cooperation-with__lable">01</label>
            <p className="cooperation-with__text">
              Экономию материальных средств по аренде помещения, зарплате
              специалистов, закупке комплектующих.
            </p>
          </li>
          <li className="cooperation-with__item">
            <label className="cooperation-with__lable">02</label>
            <p className="cooperation-with__text">
              Сокращение издержек на складе и покупке оборудования для ремонта.
            </p>
          </li>
          <li className="cooperation-with__item">
            <label className="cooperation-with__lable">03</label>
            <p className="cooperation-with__text">
              Экономию времени и средств на поиск и обучение технических
              специалистов.
            </p>
          </li>
          <li className="cooperation-with__item">
            <label className="cooperation-with__lable">04</label>
            <p className="cooperation-with__text">
              Работая с нами, вы сокращаете издержки своей компании и
              сосредотачиваетесь на своих клиентах, в то время как мы
              гарантируем полное и бесперебойное обеспечение ремонта вашей
              техники по максимально выгодным для вас условиям.
            </p>
          </li>
        </ul>
        <div className="cooper-offers__line"></div>
        <p className="cooper-offers__signature">
          Работая с нами, вы сокращаете издержки своей компании и
          сосредотачиваетесь на своих клиентах, в то время как мы гарантируем
          полное и бесперебойное обеспечение ремонта вашей техники по
          максимально выгодным для вас условиям.
        </p>
      </div>
    </Section>
  );
};

export default CooperationWithUs;

// toDo:
// h1 ?????
