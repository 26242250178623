import React from "react";
import { Carousel } from "react-responsive-carousel";
import Section from "../Section/Section";
import { Link } from "react-router-dom";

const listOfModelIphoneSlide1 = [
  {
    model: "Ремонт iPhone 4",
    href: "#",
  },
  {
    model: "Ремонт iPhone 4s",
    href: "/remont-apple/remont-iphone/4s",
  },
  {
    model: "Ремонт iPhone 5",
    href: "/remont-apple/remont-iphone/5",
  },
  {
    model: "Ремонт iPhone 5s",
    href: "/remont-apple/remont-iphone/5s",
  },
  {
    model: "Ремонт iPhone SE",
    href: "/remont-apple/remont-iphone/se",
  },
  {
    model: "Ремонт iPhone 5c",
    href: "/remont-apple/remont-iphone/5c",
  },
  {
    model: "Ремонт iPhone 6",
    href: "/remont-apple/remont-iphone/6",
  },
];

const listOfModelIphoneSlide2 = [
  {
    model: "Ремонт iPhone 6s",
    href: "/remont-apple/remont-iphone/6s",
  },
  {
    model: "Ремонт iPhone 6 Plus",
    href: "/remont-apple/remont-iphone/6-plus",
  },
  {
    model: "Ремонт iPhone 6s Plus",
    href: "/remont-apple/remont-iphone/6s-plus",
  },
  {
    model: "Ремонт iPhone 7",
    href: "/remont-apple/remont-iphone/7",
  },
  {
    model: "Ремонт iPhone 7 Plus",
    href: "/remont-apple/remont-iphone/7-plus",
  },
  {
    model: "Ремонт iPhone 8",
    href: "/remont-apple/remont-iphone/8",
  },
  {
    model: "Ремонт iPhone 8 Plus",
    href: "/remont-apple/remont-iphone/8-plus",
  },
  {
    model: "Ремонт iPhone X",
    href: "/remont-apple/remont-iphone/x",
  },
];

const listOfModelIphoneSlide3 = [
  {
    model: "Ремонт iPhone XS",
    href: "#",
  },
  {
    model: "Ремонт iPhone XS Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone XR",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 11 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone SE 2020",
    href: "#",
  },
];

const listOfModelIphoneSlide4 = [
  {
    model: "Ремонт iPhone 12",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 12 Pro Max",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 mini",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro",
    href: "#",
  },
  {
    model: "Ремонт iPhone 13 Pro Max",
    href: "#",
  },
];

const RepairIphoneModelsLinksMobile = (array) => {
  return (
    <Section className="repair-iphone__models-links-container">
      <div className="repair-iphone-mobile__models-links-content">
        <h2 className="repair-iphone-mobile__models-links-title">
          Больше детальной информации по каждой модели
        </h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className="repair-iphone-mobile__models-links-container">
            <ul className="repair-iphone-mobile__models-links-list">
              {listOfModelIphoneSlide1.map((link, i) => {
                return (
                  <li
                    key={i}
                    className="repair-iphone-mobile__models-links-item"
                  >
                    <div className="repair-mb__links-mobile-icon"></div>
                    <Link
                      to={link.href}
                      className="repair-iphone-mobile__models-links-link"
                    >
                      {link.model}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="repair-iphone-mobile__models-links-container">
            <ul className="repair-iphone-mobile__models-links-list">
              {listOfModelIphoneSlide2.map((link, i) => {
                return (
                  <li
                    key={i}
                    className="repair-iphone-mobile__models-links-item"
                  >
                    <div className="repair-mb__links-mobile-icon"></div>
                    <Link
                      to={link.href}
                      className="repair-iphone-mobile__models-links-link"
                    >
                      {link.model}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="repair-iphone-mobile__models-links-container">
            <ul className="repair-iphone-mobile__models-links-list">
              {listOfModelIphoneSlide3.map((link, i) => {
                return (
                  <li
                    key={i}
                    className="repair-iphone-mobile__models-links-item"
                  >
                    <div className="repair-mb__links-mobile-icon"></div>
                    <Link
                      to={link.href}
                      className="repair-iphone-mobile__models-links-link"
                    >
                      {link.model}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="repair-iphone-mobile__models-links-container">
            <ul className="repair-iphone-mobile__models-links-list">
              {listOfModelIphoneSlide4.map((link, i) => {
                return (
                  <li
                    key={i}
                    className="repair-iphone-mobile__models-links-item"
                  >
                    <div className="repair-mb__links-mobile-icon"></div>
                    <Link
                      to={link.href}
                      className="repair-iphone-mobile__models-links-link"
                    >
                      {link.model}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default RepairIphoneModelsLinksMobile;
