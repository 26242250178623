import React from "react";
import Section from "../Section/Section";
import { useMobileScreen } from "../../utils/useMedia";

const priceList = [
  {
    span: "Аппаратная/программная диагностика неисправности MacBook в сервисном центре",
    text: "",
    price: "БЕСПЛАТНО",
  },
  {
    span: "Установка программ на MacBook, Pro, Air ",
    text: "(цена зависит от программного обеспечения, которое вы хотите установить на свой MacBook, MacBook Air, MacBook Pro)",
    price: <>от &nbsp; 600 &#8381;</>,
  },
  {
    span: "Настройка программного обеспечения на MacBook, MacBook Air, MacBook Pro ",
    text: "(стоимость зависит от сложности настройки конкретного программного обеспечения, точную цену настройки именно вашего пакета программ уточняйте у менеджеров по телефону)",
    price: <>от &nbsp; 300 Р</>,
  },
  {
    span: "Восстановление данных на MacBook, MacBook Air, MacBook Pro ",
    text: "(точная стоимость определяется после диагностики вашего HDD или SSD в сервисном центре)",
    price: <>от &nbsp; 2 000 Р</>,
  },
  {
    span: "Замена матрицы MacBook, MacBook Air, MacBook Pro ",
    text: "(стоимость матрицы зависит от модели вашего MacBook)",
    price: <>от &nbsp; 2 500 Р</>,
  },
  {
    span: "Замена жесткого диска HDD/SSD ",
    text: "(цена жесткого диска или SSD зависит от объема и модели вашего MacBook)",
    price: <>от &nbsp; 700 Р</>,
  },
  {
    span: "Замена/ремонт клавиатуры/topcase в MacBook, MacBook Air, MacBook Pro ",
    text: "(цена зависит от модели вашего MacBook)",
    price: <>от &nbsp; 300 Р</>,
  },
  {
    span: "Ремонт материнской платы MacBook, MacBook Pro, MacBook Air ",
    text: "(ремонт после залития MacBook, замена южного/серверного моста, замена/ремонт USB портов, ремонт прочих неисправностей)",
    price: <>от &nbsp; 500 Р</>,
  },
  {
    span: "Ремонт/замена видеокарты в MacBook, MacBook Pro, MacBook Air ",
    text: "(зависит от модели видеокарты и модели вашего MacBook)",
    price: <>от &nbsp; 1 500 Р</>,
  },
  {
    span: "Ремонт/ замена прочих комплектующих ",
    text: "(уточняйте стоимость у менеджера компании)",
    price: <>от &nbsp; 1 000 Р</>,
  },
];

function ChangeBlockWithPrice() {
  const isMobile = useMobileScreen();

  if (isMobile) {
    return (
      <>
        {priceList.map((price, i) => {
          return (
            <li key={i} className="repair-mb__price-item">
              <span className="repair-mb__price-span">{price.span}</span>
              <p className="repair-mb__price-text">{price.text}</p>
              <p className="repair-mb__price-price">{price.price}</p>
            </li>
          );
        })}
      </>
    );
  }
  return (
    <>
      {priceList.map((price, i) => {
        return (
          <li key={i} className="repair-mb__price-item">
            <h4 className="repair-mb__price-text">
              <span className="repair-mb__price-span">{price.span}</span>
              {price.text}
            </h4>
            <p className="repair-mb__price-price">{price.price}</p>
          </li>
        );
      })}
    </>
  );
}

const RepairMacBookPrice = () => {
  return (
    <Section>
      <div className="repair-mb__price-container">
        <h2 className="repair-mb__price-title">
          Цены на ремонт makbook, makbook air, makbook PRO
        </h2>
        <ul className="repair-mb__price-list">
          {ChangeBlockWithPrice()}
          <p className="repair-mb__price-paragraph">
            *Р - Российский Рубль (все цены на сайте указаны в рублях). Все цены
            указаны без учета стоимости комплектующих, стоимость комплектующего
            уточняйте у менеджера по телефону.
          </p>
        </ul>
      </div>
    </Section>
  );
};

export default RepairMacBookPrice;

// toDo:
// проверить  &nbsp;
