import React from "react";
import Section from "../Section/Section";

const RepairIpadText = () => {
  return (
    <Section>
      <div className="repair-iphone__au-container">
        <h2 className="repair-iphone__au-title">
          Работы по ремонту iPad и их исполнение
        </h2>
        <div className="repair-iphone__au-border"></div>
        <p className="repair-iphone__au-text">
          Наши мастера успешно справляются с задачами разного типа, и знают
          специфику всех моделей таких устройств. Программные сбои и
          механические, а также и любые другие поломки устраняются со знанием
          дела, оперативно и полноценно. И потому результат продолжает радовать
          нашего клиента на протяжении многих лет. Свою компетентность и
          качество работы мы можем подтвердить массой положительных отзывов и
          хорошей репутацией компании. А еще мы радуем наших клиентов выгодными
          расценками – все цены полностью объективны, и ни о каких переплатах
          речи не пойдет.
        </p>
        <div className="repair-iphone-au-border-paragraph"></div>
        <p className="repair-iphone__au-text">
          Диагностика оборудования проводится у нас бесплатно и в
          экспресс-режиме. Буквально за считанные минуты вы сможете узнать, что
          именно случилось с девайсом, сколько будет стоить ремонт, и также вы
          сможете задать мастеру любые вопросы, которые вас интересуют. Если
          стоимость работ вас устроит – а она не изменится в процессе их
          выполнения, – будет заключен договор, и специалисты примутся за
          работу, в срочном или штатном порядке, согласно тому, как оно будет
          для вас необходимо. И уже в самое ближайшее время вы сможете получить
          полностью работающее устройство, чтобы пользоваться им и более не
          сталкиваться с теми же проблемами. Также вы получите и гарантии,
          которые позволят вам вернуться в сервис, если результат покажется вам
          неудовлетворительным.
        </p>
        <div className="repair-iphone-au-border-paragraph"></div>
        <p className="repair-iphone__au-text">
          Айпад – это сложное и чувствительное оборудование, ремонт которого
          можно доверить только компетентным исполнителям. Практика многократно
          доказывала тот факт, что при дилетантском подходе этот девайс можно
          окончательно привести в негодность, и тогда восстанавливать его будет
          уже бесполезно, придется идти на существенные затраты и покупать новый
          образец. Не желаете сталкиваться с такой необходимостью? Тогда
          обращайтесь за ремонтом только к компетентным исполнителям.
        </p>
        <div className="repair-iphone-au-border-paragraph"></div>
        <p className="repair-iphone__au-text">
          Наши специалисты не подведут вас и сделают все возможное для
          восстановления работоспособности устройства даже в самом сложном
          случае, и приведут вещь в полный порядок. Поломка – это не проблема,
          после качественного ремонта Айпад прослужит вам еще долго, и его
          работа будет вызывать только положительные эмоции!
        </p>
      </div>
    </Section>
  );
};

export default RepairIpadText;
