import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageAdvantages.module.css";
import tick from "../../../images/icons/note-expert-advantages-icon.svg";

const advantages = [
  "Экспресс диагностика занимает от 10 минут (в сложных случаях – до 1 дня)",
  "Собственный склад запчастей для ноутбуков любых марок и моделей",
  "Сертифицированные специалисты с большим практическим опытом",
  "Срочный ремонт по требованию клиента производится в день обращения",
];

function NoteExpertGeneralPageAdvantages() {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>с нами удобно</h2>
        <p className={styles.paragraph}>
          Чтобы отремонтировать ноутбук, приезжайте к нам в сервисный центр по
          адресу: г. Москва, ул. Лесная, д. 1/2 (1 минута от м. Белорусская). Мы
          примем его на диагностику и определим неисправность. Согласуем
          стоимость, сроки, объем работ и приступим к ремонту. Среднее время
          ремонта в нашем сервисе составляет 1 день.
        </p>
        <h3 className={styles.subtitle}>Наши преимущества</h3>
        <ul className={styles.list}>
          {advantages.map((text, i) => {
            return (
              <li key={i} className={styles.item}>
                <img src={tick} alt="" />
                <p className={styles.text}>{text}</p>
              </li>
            );
          })}
        </ul>
        <p className={styles.paragraph}>
          Оплатить услуги вы можете наличным, безналичным расчетом или
          пластиковой картой. На все работы предоставляется гарантия до 3-х лет
          и скидка на повторное обращение. По всем интересующим вопросам вы
          можете проконсультироваться у наших экспертов по телефону: +7(495)
          374-54-81.
        </p>
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPageAdvantages;
