import { useEffect } from "react";

const useDocumentTitle = (title, description) => {
  useEffect(() => {
    document.title = title;
    document.description = description;
  }, [title, description]);
};

export default useDocumentTitle;
