import React from "react";
import {
  Page,
  MacProfiList,
  BreadCrumbs,
  RepairIpodBanner,
  RepairIpodTitle,
  RepairIpodText,
  RepairIpodModelLinks,
  RepairIpodTable,
} from "../index";
import { Helmet } from "react-helmet";

const RepairIpodPage = () => {
  return (
    <main className="repair-ipod__page">
      <Helmet>
        <link
          rel="canonical"
          href="https://ohmygadget.ru/remont-apple/remont-ipod"
        />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Ремонт iPod с гарантией в Москве: профессиональная помощь по низким ценам"
        />
      </Helmet>
      <Page title="Ремонт iPod в Москве">
        <MacProfiList />
        <BreadCrumbs />
        <RepairIpodBanner />
        <RepairIpodTitle />
        <RepairIpodText />
        <RepairIpodModelLinks />
        <RepairIpodTable />
      </Page>
    </main>
  );
};

export default RepairIpodPage;
