import React from "react";
import Section from "../Section/Section";

const listOfTrouble = [
  {
    numder: "01",
    title: "Нет звука",
    text: "Проблема может быть связана с динамиками, звуковой картой, программным обеспечением. Ремонт MacBook с данным дефектом производится в течении 2-х часов.",
  },
  {
    numder: "02",
    title:
      "Полосы на экране, тусклый экран, экран MacBook некорректно отображает цвета",
    text: "Дефект чаще всего возникает при механическом повреждении дисплейного модуля MacBook, также проблема может быть в видео карте, шлейфе матрицы. Срок диагностики MacBook с данной поломкой - 1 рабочий день. Срок ремонта, как правило, не превышает 3 рабочих дня.",
  },
  {
    numder: "03",
    title: "Не работает CD/DVD привод",
    text: "Чаще всего поломка вызвана самим приводом, проблей с шпинделем привода, проблемой с лазерной головкой. Сроки ремонта - один рабочий день.",
  },
  {
    numder: "04",
    title: "Не загружается, медленно работает, виснет",
    text: "Данные дефекты могут быть вызваны некорректной работой жесткого диска или SSD диска в MacBook, а также южным или северным мостом материнской платы. Срок диагностики 1 рабочий день, срок ремонта 1-3 рабочих дня.",
  },
  {
    numder: "05",
    title: "Залили MacBook",
    text: "Данные дефекты могут быть вызваны некорректной работой жесткого диска или SSD диска в MacBook, а также южным или северным мостом материнской платы. Срок диагностики 1 рабочий день, срок ремонта 1-3 рабочих дня.",
  },
  {
    numder: "06",
    title: "Разбили дисплей, MacBook упал",
    text: "Замена дисплея производится в течении одного - трех рабочих дней. Выявление других неисправностей после падения MacBook производится в сервисном центре в течении бесплатной диагностики.",
  },
  {
    numder: "07",
    title: "Не включается",
    text: "Возможен дефект одного из компонентов MacBook. Производится аппаратная диагностика в течении одного рабочего дня, после которой менеджер нашей компании сообщает Вам по какой причине вышел из строя Ваш MacBook, а также стоимость ремонта.",
  },
  {
    numder: "08",
    title: "Застрял диск в приводе",
    text: "Наши специалисты помогут Вам бесплатно извлечь диск, если это возможно сделать без разбора Вашего MacBook.",
  },
  {
    numder: "09",
    title: "Прочие неисправности",
    text: "Если вы не нашли расценки на ремонт MacBook ниже в прайс листе, пожалуйста, уточните стоимость ремонта MacBook у менеджера нашей компании в онлайн консультации или по телефону.",
  },
  {
    numder: "10",
    title: "Другие дефекты",
    text: "Если вы заметили другие дефекты в работе Вашего Макбука - обратитесь по телефону к менеджеру нашей компании, Вам будет оказана квалифицированная помощь.",
  },
];

const RepairMacBookTrouble = () => {
  return (
    <Section className="repair-mb__trouble-content">
      <div className="repair-mb__trouble-container">
        <h2 className="repair-mb__trouble-title">
          Основные неисправности MacBook
        </h2>
        <ul className="repair-mb__trouble-list">
          {listOfTrouble.map((item, i) => {
            return (
              <li key={i} className="repair-mb__trouble-item">
                <label className="repair-mb__trouble-lable">
                  {item.numder}
                </label>
                <div className="repair-mb__trouble-wrapper">
                  <h4 className="repair-mb__trouble-subtitle">{item.title}</h4>
                  <p className="repair-mb__trouble-text">{item.text}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default RepairMacBookTrouble;
