import React from "react";
import Section from "../../Section/Section";
import { useMedia } from "../../../utils/useMedia";
import CenterMobileGeneralPageBannerMobile from "./CenterMobileGeneralPageBannerMobile";
import { useState } from "react";
import CallBackFormPopup from "../../CallBackFormPopup/CallBackFormPopup";
// import banner from "../../../images/banners/center-mobile-general-banner.svg";

function ChangeBanner(title, banner) {
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const isBreakpoint = useMedia("(max-width: 660px)");

  if (isBreakpoint) {
    return (
      <CenterMobileGeneralPageBannerMobile title={title} banner={banner} />
    );
  }
  return (
    <Section>
      <div
        className="center-mobile__general-page-banner-container"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="center-mobile__general-page-banner-content">
          <h1 className="center-mobile__general-page-banner-title">{title}</h1>
          <p className="center-mobile__general-page-banner-subtitle">
            по всей России
          </p>
          <button
            className="center-mobile__general-page-banner-button"
            onClick={() => setIsOpenPopup(true)}
          >
            Мы Вам перезвоним!
          </button>
          <p className="center-mobile__general-page-banner-text">
            <span style={{ color: "#ff4141" }}>&#42;</span> Диагностика
            бесплатно
          </p>
        </div>
      </div>
      <CallBackFormPopup isOpen={isOpenPopup} setIsOpen={setIsOpenPopup} />
    </Section>
  );
}

const CenterMobileGeneralPageBanner = ({ title, banner }) => {
  return ChangeBanner(title, banner);
};

export default CenterMobileGeneralPageBanner;
