import React from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import Section from "../Section/Section";

const routesMacBook = [
  {
    path: "/",
    breadcrumb: () => <b>Главная</b>,
    props: { title: "Главная" },
  },
  {
    path: "/dostavka",
    breadcrumb: () => <b>&ensp;→ Доставка</b>,
    props: { title: "Доставка" },
  },
  {
    path: "/remont-apple",
    breadcrumb: () => <b>&ensp;→ Ремонт Apple&ensp;</b>,
    props: { title: "Ремонт Apple" },
  },
  {
    path: "remont-apple/remont-macbook",
    breadcrumb: () => <b>→ Ремонт MacBook, Pro, Air в Москве</b>,
    props: { title: "Ремонт MacBook, Pro, Air в Москве" },
  },
  {
    path: "/remont-apple/remont-macbook/zamena_akkumuljatora_macbook",
    breadcrumb: () => (
      <b>&ensp;→ Замена аккумулятора MacBook, Pro, Air, Retina</b>
    ),
    props: { title: "Замена аккумулятора MacBook, Pro, Air, Retina" },
  },
  {
    path: "/remont-apple/remont-macbook/zamena_remont_tachpad_na_macbook",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт и замена тачпад (touchpad) на MacBook, Air, Pro, Retina
      </b>
    ),
    props: {
      title: "Ремонт и замена тачпад (touchpad) на MacBook, Air, Pro, Retina",
    },
  },
  {
    path: "/remont-apple/remont-macbook/zalil_macbook",
    breadcrumb: () => (
      <b>&ensp;→ Залил MacBook? Производим ремонт залитых MacBook, Pro, Air</b>
    ),
    props: { title: "Замена аккумулятора MacBook, Pro, Air, Retina" },
  },
  {
    path: "/remont-apple/remont-macbook/sbros_parolya_MacBook",
    breadcrumb: () => (
      <b>
        &ensp;→ Сброс пароля на MacBook Pro, Air, Retina, разблокировка Макбук в
        Mac OS
      </b>
    ),
    props: {
      title:
        "Сброс пароля на MacBook Pro, Air, Retina, разблокировка Макбук в Mac OS",
    },
  },
  {
    path: "/remont-apple/remont-macbook/zamena_remont_klaviatury_macbook_pro_air",
    breadcrumb: () => (
      <b>
        &ensp;→ Сломалась клавиатура MacBook? Ремонт и замена клавиатуры на
        MacBook, Pro, Air
      </b>
    ),
    props: {
      title:
        "Сломалась клавиатура MacBook? Ремонт и замена клавиатуры на MacBook, Pro, Air",
    },
  },
  {
    path: "/remont-apple/remont-macbook/zamena_matrici_MacBook",
    breadcrumb: () => (
      <b>&ensp;→ Замена матрицы MacBook, MacBook Pro, MacBook Air</b>
    ),
    props: { title: "Замена матрицы MacBook, MacBook Pro, MacBook Air" },
  },
  {
    path: "/remont-apple/remont-macbook/vosstanovlenie_dannyh_na_MacBook_iMac",
    breadcrumb: () => (
      <b>&ensp;→ Восстановление данных на MacBook и iMac в Москве</b>
    ),
    props: { title: "Восстановление данных на MacBook и iMac в Москве" },
  },
  {
    path: "/remont-apple/remont-macbook/zamena_operativnoj_pamyati_na_MacBook_Pro",
    breadcrumb: () => (
      <b>
        &ensp;→ Увеличение, замена оперативной памяти на MacBook, MacBook Pro
      </b>
    ),
    props: {
      title: "Увеличение, замена оперативной памяти на MacBook, MacBook Pro",
    },
  },
  {
    path: "/remont-apple/remont-macbook/zamena_ssd_na_macbook_pro_air",
    breadcrumb: () => (
      <b>&ensp;→ Замена и установка SSD на MacBook Air, Pro, Retina, iMac</b>
    ),
    props: {
      title: "Замена и установка SSD на MacBook Air, Pro, Retina, iMac",
    },
  },
  {
    path: "/remont-apple/remont-macbook/ustanovka_windows_na_macbook_imac",
    breadcrumb: () => (
      <b>&ensp;→ Установка Windows 7, 8, XP на MacBook и iMac</b>
    ),
    props: { title: "Установка Windows 7, 8, XP на MacBook и iMac" },
  },
  {
    path: "remont-apple/remont-iphone",
    breadcrumb: () => <b>→ Ремонт iPhone в Москве</b>,
    props: { title: "Ремонт iPhone в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja",
    breadcrumb: () => <b>&ensp;→ Замена дисплея (экрана) iPhone в Москве</b>,
    props: { title: "Замена дисплея (экрана) iPhone в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zalil_iphone",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт iPhone залитых водой и жидкостью, ремонт материнской
        платы iPhone
      </b>
    ),
    props: {
      title:
        "Ремонт iPhone залитых водой и жидкостью, ремонт материнской платы iPhone",
    },
  },
  {
    path: "remont-apple/remont-iphone/ne-zarjazhaetsja",
    breadcrumb: () => (
      <b>
        &ensp;→ Не заряжается iPhone? Производим ремонт iPhone который не
        заряжается и не включается
      </b>
    ),
    props: {
      title:
        "Не заряжается iPhone? Производим ремонт iPhone который не заряжается и не включается",
    },
  },
  {
    path: "remont-apple/remont-iphone/ne-lovit-set",
    breadcrumb: () => (
      <b>
        &ensp;→ iPhone 7, 7 Plus, 6, 6 Plus, 5S, 5 не ловит сеть? Производим
        восстановление модема, замену Wi-Fi модуля на iPhone!
      </b>
    ),
    props: {
      title:
        "iPhone 7, 7 Plus, 6, 6 Plus, 5S, 5 не ловит сеть? Производим восстановление модема, замену Wi-Fi модуля на iPhone!",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена аккумулятора (батареи) на iPhone в Москве, цена
        на замену батареи iPhone!
      </b>
    ),
    props: {
      title:
        "Срочная замена аккумулятора (батареи) на iPhone в Москве, цена на замену батареи iPhone!",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery",
    breadcrumb: () => (
      <b>&ensp;→ Не работает камера iPhone? Замена камеры на iPhone в Москве</b>
    ),
    props: {
      title: "Не работает камера iPhone? Замена камеры на iPhone в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-knopok",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена кнопки питания (блокировки), громкости, Home (домой) на
        iPhone 5, 5S, 6, 6 Plus, 7, 7 Plus
      </b>
    ),
    props: {
      title:
        "Замена кнопки питания (блокировки), громкости, Home (домой) на iPhone 5, 5S, 6, 6 Plus, 7, 7 Plus",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika",
    breadcrumb: () => (
      <b>
        &ensp;→ Не работает динамик на iPhone? Замена динамика iPhone в Мак
        Профи
      </b>
    ),
    props: {
      title:
        "Не работает динамик на iPhone? Замена динамика iPhone в Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona",
    breadcrumb: () => <b>&ensp;→ Замена микрофона iPhone в Москве</b>,
    props: { title: "Замена микрофона iPhone в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/4s",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея iPhone 4s в Москве - стоимость замены дисплея на
        Айфон 4s
      </b>
    ),
    props: {
      title:
        "Замена дисплея iPhone 4s в Москве - стоимость замены дисплея на Айфон 4s",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/5",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена дисплея iPhone 5. Цены на замену дисплея Айфон 5.
      </b>
    ),
    props: {
      title: "Срочная замена дисплея iPhone 5. Цены на замену дисплея Айфон 5",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/5s",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея iPhone 5s - стоимость замены экрана Айфон 5s в
        Москве
      </b>
    ),
    props: {
      title:
        "Замена дисплея iPhone 5s - стоимость замены экрана Айфон 5s в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/5c",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея iPhone 5c - дешевая замена дисплея на Айфон 5c в
        Москве
      </b>
    ),
    props: {
      title:
        "Замена дисплея iPhone 5c - дешевая замена дисплея на Айфон 5c в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/se",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея (экрана) iPhone SE, заменить дисплей на Айфон SE
        за 20 минут!
      </b>
    ),
    props: {
      title:
        "Замена дисплея (экрана) iPhone SE, заменить дисплей на Айфон SE за 20 минут!",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/6",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена дисплея (экрана) iPhone 6 с гарантией в Москве
      </b>
    ),
    props: {
      title: "Срочная замена дисплея (экрана) iPhone 6 с гарантией в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/6s",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея iPhone 6s, поменять дисплей на Айфон 6s с
        гарантией быстро
      </b>
    ),
    props: {
      title:
        "Замена дисплея iPhone 6s, поменять дисплей на Айфон 6s с гарантией быстро",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/6-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Быстрая замена дисплея iPhone 6 Plus, заменим дисплей Айфон 6
        Plus за 35 минут
      </b>
    ),
    props: {
      title:
        "Быстрая замена дисплея iPhone 6 Plus, заменим дисплей Айфон 6 Plus за 35 минут",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/6s-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея iPhone 6s Plus срочно за 30 минут в сервисном
        центре в Москве
      </b>
    ),
    props: {
      title:
        "Замена дисплея iPhone 6s Plus срочно за 30 минут в сервисном центре в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/7",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея iPhone (Айфон) 7 - сервисный центр Мак Профи в
        Москве
      </b>
    ),
    props: {
      title:
        "Замена дисплея iPhone (Айфон) 7 - сервисный центр Мак Профи в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-displeja/7-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена дисплея (экрана) iPhone 7 Plus - быстрый ремонт Айфон 7
        Plus в Москве
      </b>
    ),
    props: {
      title:
        "Замена дисплея (экрана) iPhone 7 Plus - быстрый ремонт Айфон 7 Plus в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/4s",
    breadcrumb: () => (
      <b>
        &ensp;→ Выгодная замена аккумулятора (батареи) на iPhone 4s в Мак Профи
      </b>
    ),
    props: {
      title: "Выгодная замена аккумулятора (батареи) на iPhone 4s в Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/5",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена аккумулятора iPhone 5, замена батареи Айфон 5 за
        30 минут
      </b>
    ),
    props: {
      title:
        "Срочная замена аккумулятора iPhone 5, замена батареи Айфон 5 за 30 минут",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/5s",
    breadcrumb: () => (
      <b>
        &ensp;→ Надежная замена батареи (аккумулятора) iPhone 5s в течении 25
        минут
      </b>
    ),
    props: {
      title:
        "Надежная замена батареи (аккумулятора) iPhone 5s в течении 25 минут",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/5c",
    breadcrumb: () => (
      <b>&ensp;→ Недорогая замена аккумулятора iPhone 5с в сервисе в Москве</b>
    ),
    props: {
      title: "Недорогая замена аккумулятора iPhone 5с в сервисе в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/se",
    breadcrumb: () => (
      <b>
        &ensp;→ Профессиональная замена аккумулятора (батареи) iPhone SE за 15
        минут
      </b>
    ),
    props: {
      title:
        "Профессиональная замена аккумулятора (батареи) iPhone SE за 15 минут",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/6",
    breadcrumb: () => (
      <b>
        &ensp;→ Грамотная замена аккумулятора iPhone 6 в сервисном центре Мак
        Профи
      </b>
    ),
    props: {
      title:
        "Грамотная замена аккумулятора iPhone 6 в сервисном центре Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/6-plus",
    breadcrumb: () => (
      <b>&ensp;→ Качественная замена аккумулятора на iPhone 6 Plus</b>
    ),
    props: { title: "Качественная замена аккумулятора на iPhone 6 Plus" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/6s",
    breadcrumb: () => (
      <b>&ensp;→ Оперативная замена аккумулятора iPhone 6s за 20 минут</b>
    ),
    props: { title: "Оперативная замена аккумулятора iPhone 6s за 20 минут" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/6s-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Не держит заряд iPhone 6s Plus? Замена аккумулятора на iPhone 6s
        Plus - Мак Профи
      </b>
    ),
    props: {
      title:
        "Не держит заряд iPhone 6s Plus? Замена аккумулятора на iPhone 6s Plus - Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/7",
    breadcrumb: () => (
      <b>
        &ensp;→ Быстрая замена аккумулятора iPhone 7: быстрая диагностика и
        замена батареи
      </b>
    ),
    props: {
      title:
        "Быстрая замена аккумулятора iPhone 7: быстрая диагностика и замена батареи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/7-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Доступная замена батареи (аккумулятора) на iPhone 7 Plus за 35
        минут
      </b>
    ),
    props: {
      title:
        "Доступная замена батареи (аккумулятора) на iPhone 7 Plus за 35 минут",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/8",
    breadcrumb: () => (
      <b>&ensp;→ Срочная замена аккумулятора iPhone 8 в Москве</b>
    ),
    props: { title: "Срочная замена аккумулятора iPhone 8 в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/8-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена батареи (аккумулятора) на iPhone 8 Plus за 25 минут в
        Москве
      </b>
    ),
    props: {
      title:
        "Замена батареи (аккумулятора) на iPhone 8 Plus за 25 минут в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-akkumuljatora/x",
    breadcrumb: () => (
      <b>&ensp;→ Замена аккумулятора iPhone X за 20 минут в Москве</b>
    ),
    props: { title: "Замена аккумулятора iPhone X за 20 минут в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/4s",
    breadcrumb: () => <b>&ensp;→ Срочная замена камеры iPhone 4s</b>,
    props: { title: "Срочная замена камеры iPhone 4s" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/5",
    breadcrumb: () => (
      <b>&ensp;→ Замена камеры iPhone 5, цена замены камеры Айфон 5</b>
    ),
    props: { title: "Замена камеры iPhone 5, цена замены камеры Айфон 5" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/5s",
    breadcrumb: () => (
      <b>&ensp;→ Замена камеры iPhone 5s в Москве по низким ценам</b>
    ),
    props: { title: "Замена камеры iPhone 5s в Москве по низким ценам" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/se",
    breadcrumb: () => (
      <b>&ensp;→ Быстрая замена камеры iPhone SE с гарантией</b>
    ),
    props: { title: "Быстрая замена камеры iPhone SE с гарантией" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/5c",
    breadcrumb: () => (
      <b>&ensp;→ Замена камеры iPhone 5с срочно с гарантией в Москве</b>
    ),
    props: { title: "Замена камеры iPhone 5с срочно с гарантией в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/6",
    breadcrumb: () => (
      <b>
        &ensp;→ Качественная замена камеры iPhone 6, цены на замену камеры Айфон
        6
      </b>
    ),
    props: {
      title:
        "Качественная замена камеры iPhone 6, цены на замену камеры Айфон 6",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/6s",
    breadcrumb: () => (
      <b>&ensp;→ Качественная замена камеры iPhone 6s в Мак Профи</b>
    ),
    props: { title: "Качественная замена камеры iPhone 6s в Мак Профи" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/6-plus",
    breadcrumb: () => (
      <b>&ensp;→ Замена камеры iPhone 6 Plus, быстро заменим по низким ценам</b>
    ),
    props: {
      title: "Замена камеры iPhone 6 Plus, быстро заменим по низким ценам",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/6s-plus",
    breadcrumb: () => <b>&ensp;→ Замена камеры iPhone 6s Plus</b>,
    props: { title: "Замена камеры iPhone 6s Plus" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/7",
    breadcrumb: () => <b>&ensp;→ Цены на замену камеры iPhone 7 в Москве</b>,
    props: { title: "Цены на замену камеры iPhone 7 в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/7-plus",
    breadcrumb: () => (
      <b>&ensp;→ Замена камеры iPhone 7 Plus в Москве по доступной цене</b>
    ),
    props: { title: "Замена камеры iPhone 7 Plus в Москве по доступной цене" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/8",
    breadcrumb: () => (
      <b>&ensp;→ Замена камеры iPhone 8, не работает камера Айфон 8</b>
    ),
    props: { title: "Замена камеры iPhone 8, не работает камера Айфон 8" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/8-plus",
    breadcrumb: () => (
      <b>&ensp;→ Надежная замена камеры на iPhone 8 Plus по низкой цене</b>
    ),
    props: { title: "Надежная замена камеры на iPhone 8 Plus по низкой цене" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-kamery/x",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена камеры на iPhone iPhone X, сколько стоит поменять камеру
        Айфон Икс в Москве
      </b>
    ),
    props: {
      title:
        "Замена камеры на iPhone iPhone X, сколько стоит поменять камеру Айфон Икс в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/4s",
    breadcrumb: () => (
      <b>&ensp;→ Профессиональная замена динамика iPhone 4s в Москве</b>
    ),
    props: { title: "Профессиональная замена динамика iPhone 4s в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/5",
    breadcrumb: () => (
      <b>&ensp;→ Качественная замена динамика iPhone 5 в Москве</b>
    ),
    props: { title: "Качественная замена динамика iPhone 5 в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/5s",
    breadcrumb: () => (
      <b>&ensp;→ Замена динамика iPhone 5s в сервисном центре Мак Профи</b>
    ),
    props: { title: "Замена динамика iPhone 5s в сервисном центре Мак Профи" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/5c",
    breadcrumb: () => <b>&ensp;→ Быстрая замена динамика iPhone 5c в Москве</b>,
    props: { title: "Быстрая замена динамика iPhone 5c в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/se",
    breadcrumb: () => <b>&ensp;→ Срочная замена динамика iPhone SE в Москве</b>,
    props: { title: "Срочная замена динамика iPhone SE в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/6",
    breadcrumb: () => (
      <b>&ensp;→ Цена на замену динамика iPhone 6 в Мак Профи</b>
    ),
    props: { title: "Цена на замену динамика iPhone 6 в Мак Профи" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/6s",
    breadcrumb: () => (
      <b>&ensp;→ Грамотная замена динамика iPhone 6s в Москве</b>
    ),
    props: { title: "Грамотная замена динамика iPhone 6s в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/6-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена динамика iPhone 6 Plus стоимость, сроки замены в Москве
      </b>
    ),
    props: {
      title: "Замена динамика iPhone 6 Plus стоимость, сроки замены в Москве",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/6s-plus",
    breadcrumb: () => (
      <b>&ensp;→ Недорогая замена динамика iPhone 6s Plus в Москве</b>
    ),
    props: { title: "Недорогая замена динамика iPhone 6s Plus в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/7",
    breadcrumb: () => (
      <b>&ensp;→ Замена слухового и полифонического динамика на iPhone 7</b>
    ),
    props: { title: "Замена слухового и полифонического динамика на iPhone 7" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/7-plus",
    breadcrumb: () => (
      <b>&ensp;→ Замена динамика основного / полифонии iPhone 7 Plus</b>
    ),
    props: { title: "Замена динамика основного / полифонии iPhone 7 Plus" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/8",
    breadcrumb: () => (
      <b>&ensp;→ Быстрая замена разговорного динамика iPhone 8 в Москве</b>
    ),
    props: { title: "Быстрая замена разговорного динамика iPhone 8 в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/8-plus",
    breadcrumb: () => (
      <b>&ensp;→ Надежная замена динамика на iPhone 8 Plus за 40 минут</b>
    ),
    props: { title: "Надежная замена динамика на iPhone 8 Plus за 40 минут" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-dinamika/x",
    breadcrumb: () => (
      <b>&ensp;→ Замена динамика iPhone X дешево с гарантией за 1 час</b>
    ),
    props: { title: "Замена динамика iPhone X дешево с гарантией за 1 час" },
  },

  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/4s",
    breadcrumb: () => (
      <b>&ensp;→ Оперативная замена микрофона iPhone 4s в Москве</b>
    ),
    props: { title: "Оперативная замена микрофона iPhone 4s в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/5",
    breadcrumb: () => (
      <b>&ensp;→ Замена микрофона iPhone 5 в Москве - низкие цены</b>
    ),
    props: { title: "Замена микрофона iPhone 5 в Москве - низкие цены" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/5s",
    breadcrumb: () => (
      <b>&ensp;→ Замена микрофона iPhone 5s - сервис Мак Профи</b>
    ),
    props: { title: "Замена микрофона iPhone 5s - сервис Мак Профи" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/5c",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена микрофона iPhone 5c - стоимость замены микрофона
        Айфон 5c
      </b>
    ),
    props: {
      title:
        "Срочная замена микрофона iPhone 5c - стоимость замены микрофона Айфон 5c",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/se",
    breadcrumb: () => (
      <b>&ensp;→ Сервисный центр: замена микрофона iPhone SE</b>
    ),
    props: { title: "Сервисный центр: замена микрофона iPhone SE" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/6",
    breadcrumb: () => (
      <b>&ensp;→ Профессиональная замена микрофона iPhone 6 в Москве</b>
    ),
    props: { title: "Профессиональная замена микрофона iPhone 6 в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/6s",
    breadcrumb: () => (
      <b>&ensp;→ Качественная замена микрофона iPhone 6s в Москве</b>
    ),
    props: { title: "Качественная замена микрофона iPhone 6s в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/6-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Надежная замена микрофона iPhone 6 Plus в сервисном центре Мак
        Профи
      </b>
    ),
    props: {
      title:
        "Надежная замена микрофона iPhone 6 Plus в сервисном центре Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/6s-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Оперативная замена микрофона iPhone 6s Plus по приемлемой цене!
      </b>
    ),
    props: {
      title: "Оперативная замена микрофона iPhone 6s Plus по приемлемой цене!",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/7",
    breadcrumb: () => <b>&ensp;→ Быстрая замена микрофона iPhone 7 в Москве</b>,
    props: { title: "Быстрая замена микрофона iPhone 7 в Москве" },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/7-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Грамотная замена микрофона iPhone 7 Plus с гарантией - Мак Профи
      </b>
    ),
    props: {
      title: "Грамотная замена микрофона iPhone 7 Plus с гарантией - Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/8",
    breadcrumb: () => (
      <b>&ensp;→ Замена микрофона на iPhone 8 в Москве в присутствии клиента</b>
    ),
    props: {
      title: "Замена микрофона на iPhone 8 в Москве в присутствии клиента",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/8-plus",
    breadcrumb: () => (
      <b>&ensp;→ Замена микрофона на iPhone 8 Plus за 35 минут в Мак Профи</b>
    ),
    props: {
      title: "Замена микрофона на iPhone 8 Plus за 35 минут в Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-iphone/zamena-mikrofona/x",
    breadcrumb: () => (
      <b>
        &ensp;→ Оперативная замена микрофона iPhone X за 50 минут в Мак Профи
      </b>
    ),
    props: {
      title: "Оперативная замена микрофона iPhone X за 50 минут в Мак Профи",
    },
  },
  {
    path: "/remont-apple/remont-iphone/4s",
    breadcrumb: () => (
      <b>&ensp;→ Цены на ремонт iPhone 4S в сервисном центре в Москве</b>
    ),
    props: { title: "Цены на ремонт iPhone 4S в сервисном центре в Москве" },
  },
  {
    path: "/remont-apple/remont-iphone/5",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт iPhone 5 в Москве, стоимость ремонта Айфон 5 в сервисном
        центре!
      </b>
    ),
    props: {
      title:
        "Ремонт iPhone 5 в Москве, стоимость ремонта Айфон 5 в сервисном центре!",
    },
  },
  {
    path: "/remont-apple/remont-iphone/5s",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPhone 5S, цены на ремонт Айфон 5с в Москве.</b>
    ),
    props: { title: "Ремонт iPhone 5S, цены на ремонт Айфон 5с в Москве." },
  },
  {
    path: "/remont-apple/remont-iphone/se",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочный ремонт iPhone SE (Айфон SE) В Москве, бесплатная
        диагностика и доставка
      </b>
    ),
    props: {
      title:
        "Срочный ремонт iPhone SE (Айфон SE) В Москве, бесплатная диагностика и доставка",
    },
  },
  {
    path: "/remont-apple/remont-iphone/5c",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPhone 5C в Москве - цены, сроки, гарантия</b>
    ),
    props: { title: "Ремонт iPhone 5C в Москве - цены, сроки, гарантия" },
  },
  {
    path: "/remont-apple/remont-iphone/6",
    breadcrumb: () => (
      <b>
        &ensp;→ Цены ремонт iPhone 6 в Москве. Бесплатная доставка в СЦ - Мак
        Профи
      </b>
    ),
    props: {
      title:
        "Цены ремонт iPhone 6 в Москве. Бесплатная доставка в СЦ - Мак Профи",
    },
  },
  {
    path: "/remont-apple/remont-iphone/6s",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPhone 6s по привлекательным ценам в Москве.</b>
    ),
    props: { title: "Ремонт iPhone 6s по привлекательным ценам в Москве." },
  },
  {
    path: "/remont-apple/remont-iphone/6s-plus",
    breadcrumb: () => (
      <b>&ensp;→ Срочный ремонт iPhone 6s Plus в Москве - Мак Профи</b>
    ),
    props: { title: "Срочный ремонт iPhone 6s Plus в Москве - Мак Профи" },
  },
  {
    path: "/remont-apple/remont-iphone/6-plus",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт iPhone 6 Plus в Москве, бесплатная диагностика,
        бесплатная доставка в СЦ
      </b>
    ),
    props: {
      title:
        "Ремонт iPhone 6 Plus в Москве, бесплатная диагностика, бесплатная доставка в СЦ",
    },
  },
  {
    path: "/remont-apple/remont-iphone/7",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт iPhone 7 в Москве! Бесплатная курьерская доставка Айфона
        7 в СЦ
      </b>
    ),
    props: {
      title:
        "Ремонт iPhone 7 в Москве! Бесплатная курьерская доставка Айфона 7 в СЦ",
    },
  },
  {
    path: "/remont-apple/remont-iphone/7-plus",
    breadcrumb: () => <b>&ensp;→ Ремонт iPhone 7 Plus c гарантией в Москве!</b>,
    props: { title: "Ремонт iPhone 7 Plus c гарантией в Москве!" },
  },
  {
    path: "/remont-apple/remont-iphone/8",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPhone 8, стоимость ремонта Айфон 8 в Москве</b>
    ),
    props: { title: "Ремонт iPhone 8, стоимость ремонта Айфон 8 в Москве" },
  },
  {
    path: "/remont-apple/remont-iphone/8-plus",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPhone 8 Plus - бесплатная диагностика</b>
    ),
    props: { title: "Ремонт iPhone 8 Plus - бесплатная диагностика" },
  },
  {
    path: "/remont-apple/remont-iphone/x",
    breadcrumb: () => <b>&ensp;→ Дешевый ремонт iPhone X в Москве от 300 р.</b>,
    props: { title: "Дешевый ремонт iPhone X в Москве от 300 р." },
  },
  {
    path: "/remont-apple/remont_mac_pro",
    breadcrumb: () => <b>&ensp;→ Ремонт Mac Pro в Москве</b>,
    props: { title: "Ремонт Mac Pro в Москве" },
  },
  {
    path: "remont-apple/remont-imac",
    breadcrumb: () => <b>→ Ремонт iMac в Москве и регионах РФ</b>,
    props: { title: "Ремонт iMac в Москве и регионах РФ" },
  },
  {
    path: "remont-apple/remont-imac/chistka_matricy_imac",
    breadcrumb: () => (
      <b>&ensp;→ Чистка матрицы iMac 21, 24, 27 от пыли и копоти</b>
    ),
    props: { title: "Чистка матрицы iMac 21, 24, 27 от пыли и копоти" },
  },
  {
    path: "remont-apple/remont-imac/remont_materinskoj_platy_imac",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт материнской платы iMac в Москве и всех регионах РФ</b>
    ),
    props: {
      title: "Ремонт материнской платы iMac в Москве и всех регионах РФ",
    },
  },
  {
    path: "remont-apple/remont-imac/zamena_zhestkogo_diska_na_imac",
    breadcrumb: () => <b>&ensp;→ Замена жесткого диска на iMac в Москве</b>,
    props: { title: "Замена жесткого диска на iMac в Москве" },
  },
  {
    path: "remont-apple/remont-imac/zamena_stekla_imac",
    breadcrumb: () => <b>&ensp;→ Замена стекла на iMac 21.5, 24, 27 дюймов</b>,
    props: { title: "Замена стекла на iMac 21.5, 24, 27 дюймов" },
  },
  {
    path: "/remont-apple/remont_mac_mini",
    breadcrumb: () => (
      <b>&ensp;→ Срочный ремонт Mac Mini в сервисном центре Мак Профи</b>
    ),
    props: { title: "Срочный ремонт Mac Mini в сервисном центре Мак Профи" },
  },
  {
    path: "/remont-apple/remont-ipad",
    breadcrumb: () => <b>&ensp;→ Ремонт iPad в Москве, срочный ремонт Айпад</b>,
    props: { title: "Ремонт iPad в Москве, срочный ремонт Айпад" },
  },
  {
    path: "/remont-apple/remont-ipad/2",
    breadcrumb: () => (
      <b>&ensp;→ Срочный ремонт iPad 2. Ремонт Айпэда 2 в Москве!</b>
    ),
    props: { title: "Срочный ремонт iPad 2. Ремонт Айпэда 2 в Москве!" },
  },
  {
    path: "/remont-apple/remont-ipad/3",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPad 3 в Москве в кратчайшие сроки с гарантией!</b>
    ),
    props: { title: "Ремонт iPad 3 в Москве в кратчайшие сроки с гарантией!" },
  },
  {
    path: "/remont-apple/remont-ipad/4",
    breadcrumb: () => (
      <b>&ensp;→ Срочный ремонт iPad 4, цены на ремонт Айпад 4!</b>
    ),
    props: { title: "Срочный ремонт iPad 4, цены на ремонт Айпад 4!" },
  },
  {
    path: "/remont-apple/remont-ipad/air",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPad Air в Москве. Цены на ремонт Айпада Эир!</b>
    ),
    props: { title: "Ремонт iPad Air в Москве. Цены на ремонт Айпада Эир!" },
  },
  {
    path: "/remont-apple/remont-ipad/air-2",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPad Air 2 в сервисном центре в Москве</b>
    ),
    props: { title: "Ремонт iPad Air 2 в сервисном центре в Москве" },
  },
  {
    path: "/remont-apple/remont-ipad/mini",
    breadcrumb: () => (
      <b>&ensp;→ Цены на ремонт iPad Mini. Ремонт Айпада Мини Москва!</b>
    ),
    props: { title: "Цены на ремонт iPad Mini. Ремонт Айпада Мини Москва!" },
  },
  {
    path: "/remont-apple/remont-ipad/mini-2-retina",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPad Mini 2 Retina по приемлемым ценам в Москве!</b>
    ),
    props: { title: "Ремонт iPad Mini 2 Retina по приемлемым ценам в Москве!" },
  },
  {
    path: "/remont-apple/remont-ipad/mini-3",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPad Mini 3 в Москве с гарантией профессионалами!</b>
    ),
    props: {
      title: "Ремонт iPad Mini 3 в Москве с гарантией профессионалами!",
    },
  },
  {
    path: "/remont-apple/remont-ipad/mini-4",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPad Mini 4, срочный ремонт Айпад Мини 4</b>
    ),
    props: { title: "Ремонт iPad Mini 4, срочный ремонт Айпад Мини 4" },
  },
  {
    path: "/remont-apple/remont-ipad/pro-9",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочный ремонт iPad Pro 9.7 Москва, цены на ремонт Айпад Про 9.7
      </b>
    ),
    props: {
      title: "Срочный ремонт iPad Pro 9.7 Москва, цены на ремонт Айпад Про 9.7",
    },
  },
  {
    path: "/remont-apple/remont-ipad/pro-12",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт iPad Pro 12.9, цены на ремонт Айпад Про 12.9 в Москве!
      </b>
    ),
    props: {
      title: "Ремонт iPad Pro 12.9, цены на ремонт Айпад Про 12.9 в Москве!",
    },
  },

  {
    path: "remont-apple/remont-ipad/zamena-kamery",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена камеры iPad в Москве, цены на замену камеры Айпад
      </b>
    ),
    props: {
      title: "Срочная замена камеры iPad в Москве, цены на замену камеры Айпад",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора iPad в Москве, цены на замену аккумулятора
        Айпад
      </b>
    ),
    props: {
      title: "Замена аккумулятора iPad в Москве, цены на замену аккумулятора",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla",
    breadcrumb: () => (
      <b>&ensp;→ Замена стекла iPad в Москве. Стоимость замены стекла Айпад.</b>
    ),
    props: {
      title: "Замена стекла iPad в Москве. Стоимость замены стекла Айпад.",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa",
    breadcrumb: () => <b>&ensp;→ Замена корпуса iPad (Айпад) в Москве</b>,
    props: { title: "Замена корпуса iPad (Айпад) в Москве" },
  },
  {
    path: "remont-apple/remont-ipad/ne-vidit-sim-karty",
    breadcrumb: () => (
      <b>&ensp;→ iPad не видит сим карты, причина поломки, сроки ремонта</b>
    ),
    props: { title: "iPad не видит сим карты, причина поломки, сроки ремонта" },
  },
  {
    path: "remont-apple/remont-ipad/ne-vkljuchaetsja",
    breadcrumb: () => (
      <b>
        &ensp;→ Не включается iPad? Причины поломки, цена ремонта, сроки ремонта
      </b>
    ),
    props: {
      title: "Не включается iPad? Причины поломки, цена ремонта, сроки ремонта",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-dinamika",
    breadcrumb: () => <b>&ensp;→ Замена динамика на iPad в Москве</b>,
    props: { title: "Замена динамика на iPad в Москве" },
  },
  {
    path: "remont-apple/remont-ipad/ne-lovit-set",
    breadcrumb: () => (
      <b>
        &ensp;→ iPad не ловит сеть: возможные неисправности, стоимость
        восстановления в Москве
      </b>
    ),
    props: {
      title:
        "iPad не ловит сеть: возможные неисправности, стоимость восстановления в Москве",
    },
  },

  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/mini",
    breadcrumb: () => (
      <b>
        &ensp;→ Качественная замена аккумулятора (батареи) iPad Mini (Айпад
        Мини) в сервисе Мак Профи
      </b>
    ),
    props: {
      title:
        "Качественная замена аккумулятора (батареи) iPad Mini (Айпад Мини) в сервисе Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/2",
    breadcrumb: () => (
      <b>
        &ensp;→ Быстрая замена аккумулятора iPad 2 в Москве в сервисном центре
        Мак Профи
      </b>
    ),
    props: {
      title:
        "Быстрая замена аккумулятора iPad 2 в Москве в сервисном центре Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/mini-4",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена аккумулятора iPad Mini 4 в Москве по низким ценам
      </b>
    ),
    props: {
      title: "Срочная замена аккумулятора iPad Mini 4 в Москве по низким ценам",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/3",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора iPad 3 в Москве по доступной цене в Мак
        Профи
      </b>
    ),
    props: {
      title:
        "Замена аккумулятора iPad 3 в Москве по доступной цене в Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/pro-12",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора Apple iPad Pro 12.9 в Москве в Мак Профи
      </b>
    ),
    props: {
      title: "Замена аккумулятора Apple iPad Pro 12.9 в Москве в Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/pro-9",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора (батареи) iPad Pro 9.7 в сервисном центре в
        Москве
      </b>
    ),
    props: {
      title:
        "Замена аккумулятора (батареи) iPad Pro 9.7 в сервисном центре в Москве",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/mini-3",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора (батареи) iPad Mini 3 (Айпад Мини 3):
        быстрый ремонт с гарантией качества
      </b>
    ),
    props: {
      title:
        "Замена аккумулятора (батареи) iPad Mini 3 (Айпад Мини 3): быстрый ремонт с гарантией качества",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/mini-2",
    breadcrumb: () => (
      <b>&ensp;→ Замена аккумулятора (АКБ) на iPad Mini 2 в Москве</b>
    ),
    props: { title: "Замена аккумулятора (АКБ) на iPad Mini 2 в Москве" },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/air-2",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора iPad Air 2 без дополнительных наценок и
        переплат
      </b>
    ),
    props: {
      title:
        "Замена аккумулятора iPad Air 2 без дополнительных наценок и переплат",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/4",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора iPad 4 (Айпад 4) в Москве быстро и
        качественно
      </b>
    ),
    props: {
      title:
        "Замена аккумулятора iPad 4 (Айпад 4) в Москве быстро и качественно",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-akkumuljatora/air",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена аккумулятора (батареи) iPad Air (Айпад Эйр): полный цикл
        услуг в сервисе Мак Профи
      </b>
    ),
    props: {
      title:
        "Замена аккумулятора (батареи) iPad Air (Айпад Эйр): полный цикл услуг в сервисе Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/air",
    breadcrumb: () => (
      <b>
        &ensp;→ Замена стекла iPad Air (Айпад Эйр) в сервисе Мак Профи в Москве
      </b>
    ),
    props: {
      title: "Замена стекла iPad Air (Айпад Эйр) в сервисе Мак Профи в Москве",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/mini",
    breadcrumb: () => (
      <b>&ensp;→ Срочная замена стекла (тачскрина) iPad Mini</b>
    ),
    props: { title: "Срочная замена стекла (тачскрина) iPad Mini" },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/2",
    breadcrumb: () => (
      <b>&ensp;→ Выгодная замена стекла на iPad 2 за 50 минут при клиенте!</b>
    ),
    props: {
      title: "Выгодная замена стекла на iPad 2 за 50 минут при клиенте!",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/4",
    breadcrumb: () => (
      <b>
        &ensp;→ Качественная замена стекла iPad 4, стоимость, сроки замены
        таскрина
      </b>
    ),
    props: {
      title:
        "Качественная замена стекла iPad 4, стоимость, сроки замены таскрина",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/air-2",
    breadcrumb: () => (
      <b>
        &ensp;→ Быстрая замена стекла на iPad Air 2 в Москве по низким ценам
      </b>
    ),
    props: {
      title: "Быстрая замена стекла на iPad Air 2 в Москве по низким ценам",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/mini-2",
    breadcrumb: () => (
      <b>&ensp;→ Грамотная замена стекла на iPad Mini 2 в кратчайшие сроки!</b>
    ),
    props: {
      title: "Грамотная замена стекла на iPad Mini 2 в кратчайшие сроки!",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/mini-3",
    breadcrumb: () => (
      <b>&ensp;→ Надежная замена стекла (тачскрина) iPad Mini 3 за 40 минут</b>
    ),
    props: {
      title: "Надежная замена стекла (тачскрина) iPad Mini 3 за 40 минут",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/pro-9",
    breadcrumb: () => (
      <b>
        &ensp;→ Доступная замена стекла на iPad Pro 9.7, стоимость замены стекла
        на Айпад Про 9.7
      </b>
    ),
    props: {
      title:
        "Доступная замена стекла на iPad Pro 9.7, стоимость замены стекла на Айпад Про 9.7",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/3",
    breadcrumb: () => (
      <b>
        &ensp;→ Разбилось стекло iPad 3? Замена стекла на iPad 3 (Айпад 3) в
        Москве по низкой стоимости
      </b>
    ),
    props: {
      title:
        "Разбилось стекло iPad 3? Замена стекла на iPad 3 (Айпад 3) в Москве по низкой стоимости",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/mini-4",
    breadcrumb: () => (
      <b>&ensp;→ Профессиональная замена стекла iPad Mini 4 за 1 час</b>
    ),
    props: { title: "Профессиональная замена стекла iPad Mini 4 за 1 час" },
  },
  {
    path: "remont-apple/remont-ipad/zamena-stekla/pro-12",
    breadcrumb: () => (
      <b>
        &ensp;→ Недорогая замена стекла (таскрина) iPad Pro 12.9 в Мак Профи
      </b>
    ),
    props: {
      title: "Недорогая замена стекла (таскрина) iPad Pro 12.9 в Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/2",
    breadcrumb: () => (
      <b>
        &ensp;→ Срочная замена корпуса на iPad 2 в Москве в сервисе Мак Профи
      </b>
    ),
    props: {
      title: "Срочная замена корпуса на iPad 2 в Москве в сервисе Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/3",
    breadcrumb: () => (
      <b>
        &ensp;→ Доверьте замену корпуса iPad 3 сервисному центру Мак Профи в
        Москве
      </b>
    ),
    props: {
      title:
        "Доверьте замену корпуса iPad 3 сервисному центру Мак Профи в Москве",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/4",
    breadcrumb: () => (
      <b>&ensp;→ Быстрая замена корпуса iPad 4 в день обращения в Москве</b>
    ),
    props: { title: "Быстрая замена корпуса iPad 4 в день обращения в Москве" },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/air",
    breadcrumb: () => (
      <b>
        &ensp;→ Оперативная замена корпуса Apple iPad Air в сервисе Мак Профи
      </b>
    ),
    props: {
      title: "Оперативная замена корпуса Apple iPad Air в сервисе Мак Профи",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/air-2",
    breadcrumb: () => (
      <b>
        &ensp;→ Качественная замена корпуса на iPad Air 2 в Москве: ремонт по
        честным ценам
      </b>
    ),
    props: {
      title:
        "Качественная замена корпуса на iPad Air 2 в Москве: ремонт по честным ценам",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/mini",
    breadcrumb: () => (
      <b>
        &ensp;→ Стоимость замены корпуса на iPad Mini - минимальные сроки замены
      </b>
    ),
    props: {
      title: "Стоимость замены корпуса на iPad Mini - минимальные сроки замены",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/mini-2",
    breadcrumb: () => (
      <b>
        &ensp;→ Повредили корпус Айпад Мини 2? Замена корпуса iPad Mini 2 при
        клиенте в день обращения
      </b>
    ),
    props: {
      title:
        "Повредили корпус Айпад Мини 2? Замена корпуса iPad Mini 2 при клиенте в день обращения",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/mini-3",
    breadcrumb: () => (
      <b>&ensp;→ Выгодная замена корпуса iPad Mini 3 за 60 минут</b>
    ),
    props: { title: "Выгодная замена корпуса iPad Mini 3 за 60 минут" },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/mini-4",
    breadcrumb: () => (
      <b>
        &ensp;→ Рихтовка и замена корпуса iPad Mini 4 (Айпад Мини 4) - качество
        гарантировано
      </b>
    ),
    props: {
      title:
        "Рихтовка и замена корпуса iPad Mini 4 (Айпад Мини 4) - качество гарантировано",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/pro-9",
    breadcrumb: () => (
      <b>
        &ensp;→ Профессиональная замена корпуса iPad Pro 9.7 при Вас за 1.5 часа
      </b>
    ),
    props: {
      title: "Профессиональная замена корпуса iPad Pro 9.7 при Вас за 1.5 часа",
    },
  },
  {
    path: "remont-apple/remont-ipad/zamena-korpusa/pro-12",
    breadcrumb: () => (
      <b>
        &ensp;→ Доступная замена корпуса iPad Pro 12.9 в Москве за 2 часа,
        скидки, акции!
      </b>
    ),
    props: {
      title:
        "Доступная замена корпуса iPad Pro 12.9 в Москве за 2 часа, скидки, акции!",
    },
  },
  {
    path: "remont-apple/remont_apple_watch",
    breadcrumb: () => <b>&ensp;→ Ремонт часов Apple Watch в Москве</b>,
    props: { title: "Ремонт часов Apple Watch в Москве" },
  },
  {
    path: "remont-apple/remont_apple_watch/series-1",
    breadcrumb: () => <b>&ensp;→ Срочный ремонт Apple Watch Series 1</b>,
    props: { title: "Срочный ремонт Apple Watch Series 1" },
  },
  {
    path: "remont-apple/remont_apple_watch/series-2",
    breadcrumb: () => <b>&ensp;→ Ремонт Apple Watch Series 2 в Москве</b>,
    props: { title: "Ремонт Apple Watch Series 2 в Москве" },
  },
  {
    path: "remont-apple/remont_apple_watch/nike",
    breadcrumb: () => <b>&ensp;→ Цены на ремонт Apple Watch Nike+</b>,
    props: { title: "Цены на ремонт Apple Watch Nike+" },
  },
  {
    path: "remont-apple/remont_apple_watch/edition",
    breadcrumb: () => <b>&ensp;→ Быстрый ремонт Apple Watch Edition</b>,
    props: { title: "Быстрый ремонт Apple Watch Edition" },
  },
  {
    path: "remont-apple/remont_apple_watch/sport",
    breadcrumb: () => <b>&ensp;→ Быстрый ремонт Apple Watch Sport</b>,
    props: { title: "Быстрый ремонт Apple Watch Sport" },
  },
  {
    path: "remont-apple/remont-ipod",
    breadcrumb: () => <b>&ensp;→ Ремонт iPod в Москве</b>,
    props: { title: "Ремонт iPod в Москве" },
  },
  {
    path: "remont-apple/remont-ipod/classic",
    breadcrumb: () => <b>&ensp;→ Быстрый ремонт iPod Classic</b>,
    props: { title: "Быстрый ремонт iPod Classic" },
  },
  {
    path: "remont-apple/remont-ipod/nano-7g",
    breadcrumb: () => <b>&ensp;→ Срочный ремонт iPod Nano 7G в Москве</b>,
    props: { title: "Срочный ремонт iPod Nano 7G в Москве" },
  },
  {
    path: "remont-apple/remont-ipod/nano-6g",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPod Nano 6G по низким ценам в Москве</b>
    ),
    props: { title: "Ремонт iPod Nano 6G по низким ценам в Москве" },
  },
  {
    path: "remont-apple/remont-ipod/5g",
    breadcrumb: () => <b>&ensp;→ Ремонт iPod Touch 5G в Москве </b>,
    props: { title: "Ремонт iPod Touch 5G в Москве" },
  },
  {
    path: "remont-apple/remont-ipod/1G-2G-3G",
    breadcrumb: () => (
      <b>&ensp;→ Ремонт iPod Touch 3G, 2G, 1G в Москве по низким ценам </b>
    ),
    props: { title: "Ремонт iPod Touch 3G, 2G, 1G в Москве по низким ценам" },
  },
  {
    path: "center-mobile",
    breadcrumb: () => <b>&ensp;→ Center Mobile</b>,
    props: { title: "Center Mobile" },
  },
  {
    path: "center-mobile/remont-smartfonov-samsung",
    breadcrumb: () => <b>&ensp;→ Ремонт смартфонов Samsung</b>,
    props: { title: "Ремонт смартфонов Samsung" },
  },
  {
    path: "center-mobile/remont-smartfonov-fly",
    breadcrumb: () => <b>&ensp;→ Ремонт Смартфонов Fly</b>,
    props: { title: "Ремонт Смартфонов Fly" },
  },
  {
    path: "center-mobile/remont-smartfonov-lenovo",
    breadcrumb: () => <b>&ensp;→ Ремонт смартфонов Lenovo</b>,
    props: {
      title: "Ремонт смартфонов Lenovo",
    },
  },
  {
    path: "center-mobile/remont-smartfonov-lg",
    breadcrumb: () => (
      <b>
        &ensp;→ Ремонт телефонов LG в Москве, сервисный ремонт смартфонов LG
        всех моделей
      </b>
    ),
    props: {
      title:
        "Ремонт телефонов LG в Москве, сервисный ремонт смартфонов LG всех моделей",
    },
  },
  {
    path: "center-mobile/remont-smartfonov-nokia",
    breadcrumb: () => <b>&ensp;→ Ремонт смартфонов Nokia</b>,
    props: {
      title: "Ремонт смартфонов Nokia",
    },
  },
  {
    path: "center-mobile/remont-smartfonov-htc",
    breadcrumb: () => <b>&ensp;→ Ремонт смартфонов HTC</b>,
    props: {
      title: "Ремонт смартфонов HTC",
    },
  },
  {
    path: "center-mobile/remont-smartfonov-philips",
    breadcrumb: () => <b>&ensp;→ Ремонт смартфонов Philips</b>,
    props: {
      title: "Ремонт смартфонов Philips",
    },
  },
  {
    path: "center-mobile/remont-smartfonov-sony",
    breadcrumb: () => <b>&ensp;→ Ремонт смартфонов Sony</b>,
    props: {
      title: "Ремонт смартфонов Sony",
    },
  },
  {
    path: "center-mobile/remont-planshetov-asus",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Asus</b>,
    props: {
      title: "Ремонт планшетов Asus",
    },
  },
  {
    path: "center-mobile/remont-planshetov-explay",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Explay</b>,
    props: {
      title: "Ремонт планшетов Explay",
    },
  },
  {
    path: "center-mobile/remont-planshetov-samsung",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Samsung</b>,
    props: {
      title: "Ремонт планшетов Samsung",
    },
  },
  {
    path: "center-mobile/remont-planshetov-sony",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Sony</b>,
    props: {
      title: "Ремонт планшетов Sony",
    },
  },
  {
    path: "center-mobile/remont-planshetov-prestigio",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Prestigio</b>,
    props: {
      title: "Ремонт планшетов Prestigio",
    },
  },
  {
    path: "center-mobile/remont-planshetov-acer",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Acer</b>,
    props: {
      title: "Ремонт планшетов Acer",
    },
  },
  {
    path: "center-mobile/remont-planshetov-lenovo",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Lenovo</b>,
    props: {
      title: "Ремонт планшетов Lenovo",
    },
  },
  {
    path: "center-mobile/remont-planshetov-digma",
    breadcrumb: () => <b>&ensp;→ Ремонт планшетов Digma</b>,
    props: {
      title: "Ремонт планшетов Digma",
    },
  },
  {
    path: "note-expert",
    breadcrumb: () => <b>&ensp;→ Note Expert</b>,
    props: {
      title: "Note Expert",
    },
  },
  {
    path: "note-expert/nastroyka",
    breadcrumb: () => <b>&ensp;→ Настройка ноутбука в сервисе Note Expert</b>,
    props: {
      title: "Настройка ноутбука в сервисе Note Expert",
    },
  },
  {
    path: "note-expert/zamena-matricy",
    breadcrumb: () => <b>&ensp;→ Профессиональная замена матриц ноутбуков</b>,
    props: {
      title: "Профессиональная замена матриц ноутбуков",
    },
  },
  {
    path: "note-expert/remont-materinskoy-plati",
    breadcrumb: () => <b>&ensp;→ Ремонт материнской платы в Note Expert</b>,
    props: {
      title: "Ремонт материнской платы в Note Expert",
    },
  },
  {
    path: "note-expert/zamena-klaviaturi",
    breadcrumb: () => <b>&ensp;→ Ремонт и замена клавиатуры ноутбука</b>,
    props: {
      title: "Ремонт и замена клавиатуры ноутбука",
    },
  },
  {
    path: "note-expert/chistka",
    breadcrumb: () => <b>&ensp;→ Профессиональная чистка ноутбуков в Москве</b>,
    props: {
      title: "Профессиональная чистка ноутбуков в Москве",
    },
  },
  {
    path: "note-expert/ne-vkluchaetsya",
    breadcrumb: () => <b>&ensp;→ Не включается ноутбук</b>,
    props: {
      title: "Не включается ноутбук",
    },
  },
  {
    path: "note-expert/peregrevaetsya",
    breadcrumb: () => <b>&ensp;→ Перегревается ноутбук</b>,
    props: {
      title: "Перегревается ноутбук",
    },
  },
  {
    path: "note-expert/shumit",
    breadcrumb: () => <b>&ensp;→ Ноутбук шумит при работе</b>,
    props: {
      title: "Ноутбук шумит при работе",
    },
  },
  {
    path: "note-expert/perezagruzhaetsya",
    breadcrumb: () => <b>&ensp;→ Ноутбук перезагружается сам</b>,
    props: {
      title: "Ноутбук перезагружается сам",
    },
  },
  {
    path: "note-expert/ne-zaryazhaetsya",
    breadcrumb: () => <b>&ensp;→ Не заряжается аккумулятор ноутбука</b>,
    props: {
      title: "Не заряжается аккумулятор ноутбука",
    },
  },
  {
    path: "note-expert/ustanovka-ozu",
    breadcrumb: () => <b>&ensp;→ Увеличение ОЗУ в Note Expert</b>,
    props: {
      title: "Увеличение ОЗУ в Note Expert",
    },
  },
  {
    path: "note-expert/ustanovka-programm",
    breadcrumb: () => (
      <b>&ensp;→ Установка программного обеспечения на ноутбук</b>
    ),
    props: {
      title: "Установка программного обеспечения на ноутбук",
    },
  },
  {
    path: "note-expert/upgrade",
    breadcrumb: () => <b>&ensp;→ Модернизация ноутбуков в Note Expert</b>,
    props: {
      title: "Модернизация ноутбуков в Note Expert",
    },
  },
  {
    path: "note-expert/zamena-hdd",
    breadcrumb: () => <b>&ensp;→ Замена жесткого диска в Note Expert</b>,
    props: {
      title: "Замена жесткого диска в Note Expert",
    },
  },
  {
    path: "note-expert/ustanovka-windows",
    breadcrumb: () => <b>&ensp;→ Установка Windows в сервисе Note Expert</b>,
    props: {
      title: "Установка Windows в сервисе Note Expert",
    },
  },
];

function BreadCrumbs() {
  const breadcrumbs = useBreadcrumbs(routesMacBook);
  return (
    <Section>
      <div className="bread-crumbs">
        {breadcrumbs.map(({ match, breadcrumb, title }) => {
          return (
            <span key={match.pathname}>
              <Link
                className="bread-crumbs__link"
                to={match.pathname}
                title={title}
              >
                {breadcrumb}
              </Link>
            </span>
          );
        })}
      </div>
    </Section>
  );
}

export default BreadCrumbs;
