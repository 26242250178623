import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/caseIpad3.jpeg";
import { iPadListOfModelsForCase } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Профессиональная и быстрая помощь – не придется долго ждать",
    number: "01",
  },
  {
    id: 2,
    text: "Отсутствие дополнительных рисков – самостоятельно меняя корпус, вы можете случайно нанести еще более серьезные повреждения",
    number: "02",
  },
  {
    id: 3,
    text: "Лояльные расценки – при отсутствии своевременного ремонта устройству потребуется гораздо более дорогостоящий ремонт, чем пришлось бы заплатить изначально.",
    number: "03",
  },
];

const listOfText = [
  {
    id: 1,
    text: "Гарантия качества",
    number: "01",
  },
  {
    id: 2,
    text: "Высокий уровень сервиса",
    number: "02",
  },
  {
    id: 3,
    text: "Широкий комплекс услуг",
    number: "03",
  },
  {
    id: 4,
    text: "Использование качественных запчастей.",
    number: "04",
  },
];

const textOfCase3 = [
  {
    id: 1,
    title: "Замена корпуса iPad 3 в сервисе Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Специалисты Мак Профи на протяжении долгих лет занимаются ремонтом всех видов техники Apple. Поэтому если вы разбили, ударили или же даже наступили или сели на планшет, мы поможем восстановить его первоначальное состояние."
          paragraphUp2="Почему стоит обязательно обратиться в сервисный центр? На это есть целый ряд причин, среди которых:"
          array={listOfTrouble}
          paragraphDown="В первую очередь мы тщательно проверим планшет, чтобы выявить возможные внутренние повреждения – иногда из-за удара деформируются и другие элементы системы. Если потребуется, будет проведен комплексный ремонт, благодаря чему всего за одно посещение сервисного центра, вы сможете устранить любые недочеты в работе используемого устройства. Мак Профи – сервис, который заработал безупречную репутацию, благодаря широкому спектру услуг и лояльной ценовой политике."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Что вы получаете, обращаясь в Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Мы постоянно поддерживаем в наличии запас необходимых запчастей, чтобы иметь возможность выполнять оперативный ремонт. Более того, вам даже не придется лично обращаться к нашим мастерам, тратя на поездки и ожидание драгоценное время – доступна услуга доставки, благодаря чему на дом или в офис прибудет курьер, чтобы забрать, а в дальнейшем обратно привести устройство."
          paragraphUp2="Доверив замену корпуса iPad 3 нашим мастерам, вы получаете целый ряд преимуществ:"
          array={listOfText}
          paragraphDown="Мы стремимся сделать обслуживание максимально комфортным для наших клиентов, а потому вы можете обратиться для проведения ремонта даже на выходных. Ознакомьтесь с расценками на сайте, чтобы оценить выгоды нашего предложения – и приезжайте в Мак Профи, чтобы наши специалисты вновь вернули вашему планшету нормальную геометрию корпуса."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkCaseModel3 = () => {
  return (
    <Page title="Доверьте замену корпуса iPad 3 сервисному центру Oh!MyGadget! в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-korpusa/3"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена корпуса iPad 3 в Москве – сервисный центр Oh!MyGadget! работает ежедневно. Предлагаем бесплатную диагностику, качественный ремонт и оригинальные комплектующие, а также доступные цены."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена корпуса ipad 3"
          titleImg="замена корпуса ipad 3"
          banner={banner}
          title={<>Замена корпуса iPad&nbsp;3</>}
          text="Замена корпуса iPad 3 является одной из самых востребованных услуг, ведь, несмотря на надежность и функциональность, планшет обладает значительным недостатком – он довольно хрупкий. Поэтому даже аккуратный владелец может случайно повредить крышку устройства, после чего ему потребуется профессиональная помощь в этом вопросе."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="5900 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCase3.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile links={iPadListOfModelsForCase} />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkCaseModel3;
