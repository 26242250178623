import React from "react";
import {
  Section,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
} from "../index";

const text = [
  {
    id: 1,
    text: "Разбито защитное стекло iMac.",
    number: "01",
  },
  {
    id: 2,
    text: "Зависает, медленно работает iMac",
    number: "02",
  },
  {
    id: 3,
    text: "Полосы и разводы на дисплее iMac",
    number: "03",
  },
  {
    id: 4,
    text: "Некорректно работает жесткий диск, жесткий диск iMac вышел из строя.",
    number: "04",
  },
  {
    id: 5,
    text: "iMac не включается",
    number: "05",
  },
  {
    id: 6,
    text: "Сломался CD/DVD привод iMac",
    number: "06",
  },
  {
    id: 7,
    text: "Сломался CD/DVD привод iMac",
    number: "07",
  },
  {
    id: 8,
    text: "iMac не загружается",
    number: "08",
  },
];

const listOfTrouble = [
  {
    id: 1,
    title:
      "ОСНОВНЫЕ ВИДЫ ПОЛОМОК IMAC С КОТОРЫМИ ОБРАЩАЮТСЯ В НАШ СЕРВИСНЫЙ ЦЕНТР:",
    text: <RepairAppleTextWithCheckMark array={text} />,
  },
];

const RepairIMacTroubleList = () => {
  return (
    <Section
      className="section_group"
      style={{
        background:
          "linear-gradient(268deg, rgba(196, 196, 196, 0.09) 36.68%, rgba(196, 196, 196, 0) 88.72%)",
      }}
    >
      {listOfTrouble.map((item) => {
        return (
          <RepairAppleLinksText
            key={item.id}
            title={item.title}
            text={item.text}
          />
        );
      })}
    </Section>
  );
};

export default RepairIMacTroubleList;
