import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display4s.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfDisplay4s = [
  {
    id: 1,
    title: "Как заменить дисплей iPhone 4s: основные этапы",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Чтобы самостоятельно заменить дисплей, потребуется приобрести
          необходимые для работы инструменты и материалы, а главное,
          оригинальные комплектующие. Сама процедура состоит из нескольких
          основных этапов:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выключить устройство, выкрутить винты, расположенные возле разъема
              док-станции
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Сдвинуть крышку, после чего полностью снять заднюю панель
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Снять винты, фиксирующие аккумулятор и отсоединить находящийся
              рядом с разъемом контакт
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Извлечь сначала разъем, а потому и сам аккумулятор
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Выкрутить винты, фиксирующие разъем нижнего шлейфа, удалить
              защитную пластину
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">06</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Извлечь разъем, а потом отделить нижний шлейф от материнской платы
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">07</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Отсоединить кабель Wi-Fi антенны, и разложить его таким образом,
              что он не дотрагивался до металлических элементов
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">08</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Снять защитную пластину, фиксирующую заднюю камеру, после чего
              извлечь её.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">09</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Остается лишь извлечь СИМ-карту вместе с держателем, после чего
              можно приступить к извлечению материнской платы, а потом
              аналогичным образом достать вибромотор, полифонический модуль. И
              лишь после этого аккуратно достать дисплей, выкрутив основные
              болты – справившись с этой задачей, предстоит установить новый
              дисплей, после чего проделать все вышеперечисленные действия в
              обратном порядке.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: "Почему не стоит менять дисплей iPhone 4s самостоятельно?",
    text: "Замена дисплея Айфон 4s – трудоемкая задача, которая требует не только внимательности и аккуратности, но и базовых навыков. Нередко возникают сложности при подключении кнопки Home, кроме того, неаккуратные действия могут привести к повреждению контактов или же важных элементов, что отразится на работоспособности устройства. Поэтому проще и дешевле все-таки изначально обратиться к профессионалам – не стоит рисковать дорогостоящей техникой. Сэкономить вряд ли удастся, а вот заплатить за ремонт значительную сумму уж точно придется. Нас ценят за качественное и быстрое выполнение поставленной задачи, лояльную ценовую политику и неизменно высокий уровень сервиса. Приезжайте уже сейчас, и мы восстановим ваше устройство, а замена дисплея на iPhone 4s будет выполнена настолько быстро, насколько это возможно.",
  },
];

const RepairIphoneServiceLinkDisplayModels4s = () => {
  return (
    <Page title="Замена дисплея iPhone 4s в Москве - стоимость замены дисплея на Айфон 4s">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-displeja/4s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Точная информация по стоимости замены дисплея на iPhone 4s. Сроки замены дисплея Айфон 4s."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена дисплея iPhone 4s"
          titleImg="Замена дисплея iPhone 4s"
          banner={banner}
          title="Замена дисплея iPhone 4s"
          text="Любой, даже самый аккуратный владелец, может рано или поздно случайно уронить смартфон – достаточно падения с относительно небольшой высоты, чтобы потребовалась замена дисплея iPhone 4s. Наши специалисты оперативно справятся с поставленной задачей, и в обязательном порядке предоставят необходимые гарантии работоспособности устройства."
        />
        <RepairApplePrice
          subtitle="Стоимость замены дисплея iPhone 4s"
          text="Замена дисплея"
          price="1700 P"
          paragraph="Цены указаны в рублях РФ."
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay4s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels4s;
