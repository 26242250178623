import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import samsungBanner from "../../../images/banners/pad-samsung-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "ГАРАНТИИ",
    text: "Каждый планшет наши мастера ремонтируют как собственный, что уже является гарантией качественной работы. Кроме того, на все виды услуг и комплектующие «Центр Мобайл» предоставляет официальную гарантию на определенный срок.",
  },
  {
    img: icon2,
    title: "ВЫЗОВ МАСТЕРА НА ДОМ",
    text: "При несерьезной поломке ремонт планшета Самсунг может осуществляться на дому или в офисе. Для вызова мастера нужно обратиться по телефону +7 (495) 540-43-19.",
  },
  {
    img: icon3,
    title: "СРОЧНЫЙ РЕМОНТ",
    text: "Ряд работ, например, замена стекла на планшете Самсунг, может быть выполнен в срочном режиме. При этом качество оперативного ремонта ничем не отличается от обычного.",
  },
  {
    img: icon4,
    title: "ДОСТАВКА",
    text: "Если у вас нет времени самостоятельно доставить гаджет, наш сервисный центр по ремонту планшетов Samsung предлагает вам доставку по Москве и Московской области. А находясь в пределах МКАД и заказывая доставку в рабочее время, вам даже не придется оплачивать услуги курьера.",
  },
];

const pads = [
  { name: "Galaxy View", pricelist: desireHD },
  { name: "Galaxy Tab S8+", pricelist: desire400 },
  { name: "Galaxy Tab S8", pricelist: desireHD },
  { name: "Galaxy Tab A8 (2021)", pricelist: desire400 },
  { name: "Galaxy Tab S7 FE", pricelist: desireHD },
  { name: "Galaxy Tab Active3", pricelist: desire400 },
  { name: "Galaxy Tab A7 10.4 (2020)", pricelist: desireHD },
  { name: "Galaxy Tab S7 LTE", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadSamsung() {
  return (
    <Page title="Ремонт планшетов Самсунг в Москве, сервисный ремонт Samsung Galaxy Tab, Note">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-planshetov-samsung"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Квалифицированные услуги по ремонту планшетов Самсунг в Москве от специалистов сервисного центра Oh!MyGadget!. Все виды ремонта планшетов Samsung по доступным ценам. Гарантии на работу."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={samsungBanner}
          alt="планшет Samsung"
          title="планшет Samsung"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ SAMSUNG"
          text="Специализированный сервис «Центр Мобайл» предлагает эффективный ремонт планшетов Самсунг в Москве. Для этого используются исключительно качественные комплектующие, богатый практический опыт мастеров и современное оборудование. Однако, мы принимаем в работу и любые другие устройства, помимо Samsung, ремонт планшетов всех марок и моделей осуществляется на высокопрофессиональном уровне и с отличными результатами."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Samsung"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Samsung:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="СЕРВИСНЫЙ РЕМОНТ: НАШИ ПРЕИМУЩЕСТВА"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ВОСПОЛЬЗОВАТЬСЯ УСЛУГАМИ КОМПАНИИ «ЦЕНТР МОБАЙЛ» ?"
          text="Если не работает клавиатура планшета Самсунг, или случилась другая неприятность с устройством, достаточно позвонить по нашему многоканальному телефону для вызова мастера на дом или курьера для доставки гаджета в центр. Зная наш адрес (Москва, ул. Лесная, 1/2, офис 351), планшет можно привезти самостоятельно. Вас ожидает качественный сервис и профессиональный ремонт."
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadSamsung;
