import React, { useState, useEffect } from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageForm.module.css";
import InputMask from "react-input-mask";
import emailjs from "@emailjs/browser";
import { Dialog } from "@headlessui/react";
import ok from "../../../images/icons/popup-ok.svg";
import popupCloseIcon from "../../../images/icons/popup-close.svg";

function NoteExpertGeneralPageForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [typeOfGadget, setTypeOfGadget] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [nameError, setNameError] = useState("Поле не может быть пустым");
  const [phoneError, setPhoneError] = useState("Поле не может быть пустым");
  const [formValid, setFormValid] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [isSubmiting, setIsSubmiting] = useState(false);

  const buttonClassName = formValid ? styles.button : styles.buttonInactive;

  const blurHandler = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
    }
  };

  const nameHandler = (e) => {
    let newState = e.target.value;
    setName(newState);
    if (newState.length < 2) {
      setNameError("Здесь должно быть минимум 2 символа");
      if (!e.target.value) {
        setNameError("Поле не может быть пустым");
      }
    } else {
      setNameError("");
    }
  };

  const phoneHandler = (e) => {
    let newState = e.target.value;
    setPhone(newState);
    if (newState.includes("_")) {
      setPhoneError("Введите корректный номер телефона");
      if (newState == "+_ (___) ___-__-__") {
        setPhoneError("Поле не может быть пустым");
      }
    } else if (!e.target.value) {
      setPhoneError("Поле не может быть пустым");
    } else {
      setPhoneError("");
    }
  };

  const typeHandler = (e) => {
    let newState = e.target.value;
    setTypeOfGadget(newState);
  };

  useEffect(() => {
    if (nameError || phoneError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [nameError, phoneError]);

  function sendEmail(e) {
    e.preventDefault();
    setIsSubmiting(true);
    emailjs
      .sendForm(
        "service_gdq4iwq",
        "template_0zr059h",
        e.target,
        "EZfZDetK08h7QxQFY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmiting(false);
          setIsOpen(true);
          setName("");
          setPhone("");
          setTypeOfGadget("");
          setNameDirty(false);
          setPhoneDirty(false);
          setNameError("Поле не может быть пустым");
          setPhoneError("Поле не может быть пустым");
          setFormValid(false);
          setTimeout(() => {
            setIsOpen(false);
          }, 3000);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <Section className={styles.section}>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={sendEmail}>
          <input name="subject" value="заявка на ремонт" type="hidden" />
          <h2 className={styles.title}>ЗАЯВКА НА РЕМОНТ</h2>
          <ul className={styles.list}>
            <li className={styles.item}>
              <label className={styles.label} htmlFor="username">
                ВВЕДИТЕ ВАШи ИМЯ и фамилию
              </label>
              <input
                placeholder="Иван Иванов"
                className={styles.input}
                type="text"
                id="username"
                onBlur={(e) => blurHandler(e)}
                name="name"
                value={name}
                onChange={(e) => nameHandler(e)}
              />
              {nameDirty && nameError ? (
                <em className={styles.errorText}>{nameError}</em>
              ) : (
                <div className={styles.hiddenErrorText}></div>
              )}
            </li>
            <li className={styles.item}>
              <label className={styles.label} htmlFor="phone">
                ВВЕДИТЕ КОНТАКТНЫЙ ТЕЛЕФОН
              </label>
              <InputMask
                onBlur={(e) => blurHandler(e)}
                autoComplete="none"
                name="phone"
                value={phone}
                mask="+7 (999) 999-99-99"
                className={styles.input}
                type="tel"
                id="phone"
                onChange={(e) => phoneHandler(e)}
              />
              {phoneDirty && phoneError ? (
                <em className={styles.errorText}>{phoneError}</em>
              ) : (
                <div className={styles.hiddenErrorText}></div>
              )}
            </li>
            <li className={styles.item}>
              <label className={styles.label} htmlFor="type">
                Тип устройства
              </label>
              <input
                placeholder="iPhone 11"
                className={styles.input}
                type="text"
                id="type"
                name="gadget"
                value={typeOfGadget}
                onChange={(e) => typeHandler(e)}
              />
            </li>
          </ul>
          <button
            disabled={!formValid}
            className={buttonClassName}
            type="submit"
          >
            {isSubmiting ? "Отправка..." : "ОТПРАВИТЬ"}
          </button>
        </form>
      </div>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={styles.backdrop} aria-hidden="true"></div>
        <div className={styles.container_popup}>
          <div className={styles.contentSuccess}>
            <Dialog.Panel className={styles.success}>
              <img
                src={popupCloseIcon}
                alt="иконка закрытия окна"
                className="center-mobile-general-page__popup-success-close"
                onClick={() => setIsOpen(false)}
              />
              <img
                src={ok}
                className="center-mobile-general-page__popup-success-finger"
                alt="иконка успешной отправки"
              />
              <Dialog.Title className="center-mobile-general-page__popup-success-title">
                Мы вам перезвоним в течении 30 минут!
              </Dialog.Title>
              <p className="center-mobile-general-page__popup-success-subtitle">
                звонки осуществляются только в рабочее время
              </p>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Section>
  );
}

export default NoteExpertGeneralPageForm;
