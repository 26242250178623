import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PopupConfirm, Preloader } from "../components";
import { feedbacksApi } from "../utils/api";
import { pencilIcon, trashIcon, undoIcon, userIDIcon } from "./newIcons/index";
import usePopup from "../hooks/usePopup";
import useFormWithValidation from "../hooks/useFormWithValidation";

const AdminFeedbackDetail = () => {
  const { id } = useParams();
  const [feedbackById, setFeedbackById] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [toggleInput, setToggleInput] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const { isPopupOpen, togglePopup, closePopup } = usePopup();

  useEffect(() => {
    const fetchFeedbackById = async () => {
      setLoading(true);
      try {
        setFeedbackById(await feedbacksApi.getFeedBackById(id));
        setIsError(false);
      } catch (error) {
        setIsError(true);
      }
      setTimeout(() => setLoading(false), 1200);
    };
    fetchFeedbackById();
  }, [id]);

  useEffect(() => {
    const setData = async () => {
      const data = await feedbacksApi.getFeedBackById(id);
      setTextValue(data.text);
      setNameValue(data.name);
      setEmailValue(data.email);
    };

    setData();
  }, [id]);

  const { values, handleChange, errors, isValid, startChange } =
    useFormWithValidation({
      text: "",
      name: "",
      email: "",
    });

  const isDisabled =
    values.text === "" || values.name === "" || values.email === "" || !isValid;

  const submitButtonClassName = !isDisabled
    ? "info__btn"
    : "info__btn info__btn_type_disabled";

  const cancellButtonClassName = startChange
    ? "info__btn"
    : "info__btn info__btn_type_disabled";

  const handleToggleInput = () => {
    setToggleInput((toggleInput) => !toggleInput);
  };

  const handleChangeSubmit = (event) => {
    event.preventDefault();

    if (!isDisabled) {
      handleChangeFeedback(values.text, values.name, values.email);
      handleToggleInput();
    }
  };

  const handleChangeFeedback = async (text, name, email) => {
    const thisFeedback = await feedbacksApi.getFeedBackById(id);

    const editedFeedback = await feedbacksApi.editFeedback(thisFeedback._id, {
      text: text,
      name: name,
      email: email,
    });
    setFeedbackById(editedFeedback);
  };

  const handleDeleteFeedback = async () => {
    const thisFeedback = await feedbacksApi.getFeedBackById(id);

    await feedbacksApi.deleteFeedback(thisFeedback._id);
    setIsDeleted(true);
  };

  const handleSubmit = () => {
    handleDeleteFeedback();
    closePopup();
  };

  const showDate = () => {
    const dateFromDB = feedbackById.created;
    const dateToShow = dateFromDB.toString().substring(0, 10);

    return dateToShow;
  };

  return (
    <>
      <section className="info">
        <div className="info__container">
          {loading ? (
            <Preloader />
          ) : (
            <>
              <article className="info__card">
                <div className="info__header">
                  <div className="info__wrapper">
                    <img
                      src={userIDIcon}
                      className="info__icon"
                      alt="иконка профиля"
                    />
                    <p className="info__item">id: {id}</p>
                  </div>
                  <Link className="info__link" to="/admin-page/otzivy/">
                    <img
                      src={undoIcon}
                      alt="иконка возвращения в предыдущий раздел"
                      className="info__icon"
                    />
                    назад ко всем отзывам
                  </Link>
                </div>

                {isError && !isDeleted && (
                  <p className="info__item">
                    Проверьте id. Данные в базе не найдены
                  </p>
                )}

                {isDeleted ? (
                  <>
                    <p className="info__item info__item_type_deleted">
                      Отзыв с id: {id} был успешно удален
                    </p>
                    <Link className="info__delited" to="/admin-page/otzivy/">
                      Вы можете вернуться ко всем отзывам
                    </Link>
                  </>
                ) : (
                  <>
                    {feedbackById && (
                      <div className="info__main">
                        <button
                          className="info__button"
                          onClick={handleToggleInput}
                          aria-label="изменить текст"
                        >
                          <img
                            src={pencilIcon}
                            className="info__icon"
                            alt="иконка кнопки редактирования"
                          />
                        </button>
                        {feedbackById && !toggleInput ? (
                          <ul className="info__fields">
                            <li className="info__field">
                              <p className="info__item">
                                текст: {feedbackById.text}
                              </p>
                            </li>
                            <li className="info__field">
                              <p className="info__item">
                                клиент: {feedbackById.name}
                              </p>
                            </li>
                            <li className="info__field">
                              <p className="info__item">
                                email: {feedbackById.email}
                              </p>
                            </li>
                            <li className="info__field">
                              <p className="info__item">
                                размещен: {showDate()}
                              </p>
                            </li>
                            <li>
                              <p className="info__item">
                                статус:{" "}
                                {feedbackById.approved === true
                                  ? "Опубликован"
                                  : "Не опубликован"}
                              </p>
                            </li>
                          </ul>
                        ) : (
                          <form
                            className="info__form"
                            onSubmit={handleChangeSubmit}
                          >
                            <fieldset className="info__inputs">
                              <div className="info__line">
                                <label
                                  className="info__label"
                                  htmlFor="feedback-text"
                                >
                                  текст
                                </label>
                                <textarea
                                  id="feedback-text"
                                  name="text"
                                  className="info__change"
                                  placeholder="Введите текст"
                                  value={!startChange ? textValue : values.text}
                                  onChange={handleChange}
                                  required={true}
                                />
                                {errors.text && (
                                  <p className="info__error">{errors.text}</p>
                                )}
                              </div>
                              <div className="info__line">
                                <label
                                  className="info__label"
                                  htmlFor="feedback-name"
                                >
                                  клиент
                                </label>
                                <input
                                  id="feedback-name"
                                  name="name"
                                  className="info__change"
                                  placeholder="Введите имя"
                                  value={!startChange ? nameValue : values.name}
                                  onChange={handleChange}
                                  required={true}
                                />
                                {errors.name && (
                                  <p className="info__error">{errors.name}</p>
                                )}
                              </div>
                              <div className="info__line">
                                <label
                                  className="info__label"
                                  htmlFor="feedback-email"
                                >
                                  email
                                </label>
                                <input
                                  type="email"
                                  required={true}
                                  id="feedback-email"
                                  name="email"
                                  className="info__change"
                                  placeholder={"Введите email"}
                                  value={
                                    !startChange ? emailValue : values.email
                                  }
                                  onChange={handleChange}
                                />
                                {errors.email && (
                                  <p className="info__error">{errors.email}</p>
                                )}
                              </div>
                              <p className="info__item">
                                размещен: {showDate()}
                              </p>
                              <p className="info__item">
                                статус:{" "}
                                {feedbackById.approved === true
                                  ? "Опубликован"
                                  : "Не опубликован"}
                              </p>
                              <ul className="info__buttons">
                                <li>
                                  <button
                                    type="submit"
                                    className={submitButtonClassName}
                                  >
                                    Сохранить
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    onClick={handleToggleInput}
                                    className={cancellButtonClassName}
                                  >
                                    Отменить
                                  </button>
                                </li>
                              </ul>
                            </fieldset>
                          </form>
                        )}
                      </div>
                    )}
                  </>
                )}
              </article>

              {!isDeleted && (
                <button
                  className="info__button"
                  onClick={togglePopup}
                  aria-label="удалить отзыв"
                >
                  <img
                    src={trashIcon}
                    className="info__icon"
                    alt="иконка удаления отзыва"
                  />
                  <p className="info__item">удалить отзыв</p>
                </button>
              )}
            </>
          )}
        </div>
      </section>
      <PopupConfirm
        isOpenPopup={isPopupOpen}
        onClose={closePopup}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default AdminFeedbackDetail;
