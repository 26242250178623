import React from "react";
import {
  MacProfiList,
  BreadCrumbs,
  RepairMacBookBanner,
  RepairMacBookInfo,
  RepairMacBookLinks,
  RepairMacBookPrice,
  RepairMacBookTrouble,
  RepairMacBookInfoMobile,
  Page,
} from "../index";
import { useMedia } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

function PageInfo() {
  const isBreakpoint = useMedia("(max-width: 720px)");
  if (isBreakpoint) {
    return <RepairMacBookInfoMobile />;
  }
  return <RepairMacBookInfo />;
}

const RepairMacBookPage = () => {
  return (
    <main className="repair-mb__page">
      <Page title="Ремонт MacBook, Pro, Air в Москве. Срочный ремонт Макбук!">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Качественный ремонт MacBook, Pro, Air. Производим ремонт Apple MacBook с гарантией на все виды работ и комплектующих."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairMacBookBanner />
        {PageInfo()}
        <RepairMacBookTrouble />
        <RepairMacBookPrice />
        <RepairMacBookLinks />
      </Page>
    </main>
  );
};

export default RepairMacBookPage;
