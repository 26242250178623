import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertInsidePageAdvantages.module.css";
import { useMedia } from "../../../utils/useMedia";
import NoteExpertInsidePageAdvantagesCarousel from "./NoteExpertInsidePageAdvantagesCarousel";

function ChangePage(title, advantages) {
  const isBreakpoint = useMedia("(min-width: 981px)");

  if (isBreakpoint) {
    return (
      <Section>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <ul className={styles.list}>
            {advantages.map((item, i) => {
              return (
                <li key={i} className={styles.item}>
                  <div className={styles.circle}>
                    <img className={styles.image} src={item.icon} alt="" />
                  </div>
                  <h4 className={styles.subtitle}>{item.title}</h4>
                  <p className={styles.text}>{item.text}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </Section>
    );
  } else {
    return (
      <NoteExpertInsidePageAdvantagesCarousel
        title={title}
        advantages={advantages}
      />
    );
  }
}

function NoteExpertInsidePageAdvantages({ title, advantages }) {
  return ChangePage(title, advantages);
}

export default NoteExpertInsidePageAdvantages;
