import React from "react";
import { Link } from "react-router-dom";

const LInk = (props) => {
  return (
    <li>
      <Link to={props.to} className="mac-profi-list__mobile-link">
        <div className={props.classImg}></div>
        <p className="mac-profi-list__mobile-repair">{props.text}</p>
        <p className="mac-profi-list__mobile-name">{props.gadget}</p>
      </Link>
    </li>
  );
};

export default LInk;
