import React from "react";
import { Link } from "react-router-dom";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageNoteLinks.module.css";

const noteLinks = [
  {
    text: "SONY",
    to: "#",
  },
  {
    text: "TOSHIBA",
    to: "#",
  },
  {
    text: "LENOVO",
    to: "#",
  },
  {
    text: "ASUS",
    to: "#",
  },
  {
    text: "SAMSUNG",
    to: "#",
  },
  {
    text: "DELL",
    to: "#",
  },
  {
    text: "ACER",
    to: "#",
  },
  {
    text: "HP",
    to: "#",
  },
];

function NoteExpertGeneralPageNoteLinks() {
  return (
    <Section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.title}>КАКОй у ВАс ноутбук?</h2>
        <ul className={styles.list}>
          {noteLinks.map((link, i) => {
            return (
              <li key={i} className={styles.item}>
                <Link className={styles.link} to={link.to}>
                  {link.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

export default NoteExpertGeneralPageNoteLinks;
