import React from "react";
import { Carousel } from "react-responsive-carousel";
import Section from "../Section/Section";
import { NavLink } from "react-router-dom";

const linksPart1 = [
  {
    to: "/remont-apple/remont-macbook/zamena_akkumuljatora_macbook",
    text: "MacBook замена аккумулятора",
  },
  {
    to: "/remont-apple/remont-macbook/zalil_macbook",
    text: "Ремонт MacBook залитых жидкостью",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_remont_tachpad_na_macbook",
    text: "MacBook ремонт и замена тачпад",
  },
  {
    to: "/remont-apple/remont-macbook/sbros_parolya_MacBook",
    text: "Сброс пароля на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_remont_klaviatury_macbook_pro_air",
    text: "MacBook замена и ремонт клавиатуры",
  },
];

const linksPart2 = [
  {
    to: "/remont-apple/remont-macbook/zamena_matrici_MacBook",
    text: "Замена матрицы MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/vosstanovlenie_dannyh_na_MacBook_iMac",
    text: "Восстановление данных на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_operativnoj_pamyati_na_MacBook_Pro",
    text: "Увеличение оперативной памяти на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/zamena_ssd_na_macbook_pro_air",
    text: "Замена и установка SSD на MacBook",
  },
  {
    to: "/remont-apple/remont-macbook/ustanovka_windows_na_macbook_imac",
    text: "Установка Windows на MacBook",
  },
];

const RepairMacBookLinksMobile = () => {
  return (
    <Section className="repair-mb__links-mobile-section">
      <div className="repair-mb__links-mobile-content">
        <h2 className="repair-mb__links-mobile-title">
          Подробнее по неисправностям
        </h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className="repair-mb__links-mobile-box">
            <ul className="repair-mb__links-mobile-list">
              {linksPart1.map((link, i) => {
                return (
                  <li key={i} className="repair-mb__links-mobile-item">
                    <div className="repair-mb__links-mobile-icon"></div>
                    <NavLink
                      to={link.to}
                      className="repair-mb__links-mobile-link"
                    >
                      {link.text}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="repair-mb__links-mobile-content">
            <ul className="repair-mb__links-mobile-list">
              {linksPart2.map((link, i) => {
                return (
                  <li key={i} className="repair-mb__links-mobile-item">
                    <div className="repair-mb__links-mobile-icon"></div>
                    <NavLink
                      to={link.to}
                      className="repair-mb__links-mobile-link"
                    >
                      {link.text}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default RepairMacBookLinksMobile;
