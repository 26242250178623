import React from "react";
import { SectionTitle, Section } from "../index";
import {
  alfa,
  fiat,
  mail,
  major,
  mercedes,
  rossia,
  standart,
  vtb,
  counter,
} from "../../images/index";
import {
  alfaActive,
  fiatActive,
  mailruActive,
  majorActive,
  mercedesActive,
  rossiaActive,
  standartActive,
  vtbActive,
} from "../../images/index";

const Partners = () => {
  return (
    <Section className="partners" id="clients">
      <div className="partners__container">
        <SectionTitle title="Нам можно доверять!" className="partners__title" />
        <img
          className="partners__counter"
          alt="счетчик клиентов"
          src={counter}
        />
        <h3 className="partners__subtitle">
          <span className="partners__span">довольных </span>клиентов нашей
          компании не могут ошибаться!
        </h3>
        <ul className="partners__logos">
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={mail}
              onMouseOver={(e) => (e.currentTarget.src = mailruActive)}
              onMouseOut={(e) => (e.currentTarget.src = mail)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={mercedes}
              onMouseOver={(e) => (e.currentTarget.src = mercedesActive)}
              onMouseOut={(e) => (e.currentTarget.src = mercedes)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={standart}
              onMouseOver={(e) => (e.currentTarget.src = standartActive)}
              onMouseOut={(e) => (e.currentTarget.src = standart)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={vtb}
              onMouseOver={(e) => (e.currentTarget.src = vtbActive)}
              onMouseOut={(e) => (e.currentTarget.src = vtb)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={alfa}
              onMouseOver={(e) => (e.currentTarget.src = alfaActive)}
              onMouseOut={(e) => (e.currentTarget.src = alfa)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={major}
              onMouseOver={(e) => (e.currentTarget.src = majorActive)}
              onMouseOut={(e) => (e.currentTarget.src = major)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={rossia}
              onMouseOver={(e) => (e.currentTarget.src = rossiaActive)}
              onMouseOut={(e) => (e.currentTarget.src = rossia)}
            />
          </li>
          <li className="partners__logo">
            <img
              className="patrners__image"
              alt="логотип компании-партнера"
              src={fiat}
              onMouseOver={(e) => (e.currentTarget.src = fiatActive)}
              onMouseOut={(e) => (e.currentTarget.src = fiat)}
            />
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default Partners;
