import React from "react";
import { Popup } from "../../index";
import { questionIcon } from "../../../admin/newIcons/index";

const PopupConfirm = ({ isOpenPopup, onClose, handleSubmit, message }) => {
  return (
    <Popup isOpenPopup={isOpenPopup} onClose={onClose}>
      <form
        className="form__container"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit?.();
          onClose?.();
        }}
      >
        <img
          className="form__icon"
          alt="иконка попапа подтверждения"
          src={questionIcon}
        />
        <h3 className="form__title">Вы уверены?</h3>{" "}
        {message && <p className="form__alert-message">{message}</p>}
        <button type="submit" className="form__button">
          Подтвердить
        </button>
      </form>
    </Popup>
  );
};

export default PopupConfirm;
