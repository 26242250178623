import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairIphoneModelLinksForServiceMobile,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone4s.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const RepairIphoneServiceLinkMicrophoneModels4s = () => {
  return (
    <Page title="Оперативная замена микрофона iPhone 4s в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-mikrofona/4s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстрая замена микрофона iPhone 4s возможна – сервисный центр Oh!MyGadget! специализируется на оказании таких услуг. Недорого, грамотный подход."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена микрофона iPhone 4s"
          titleImg="Замена микрофона iPhone 4s"
          banner={banner}
          title="Замена микрофона iPhone 4s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ситуации, связанные с необходимостью выполнения таких работ, как
                замена микрофона iPhone 4s, редкостью не являются. Это одна из
                довольно распространенных поломок, и к счастью, она легко
                устраняется в сервисном центре. Существует целый ряд симптомов,
                которые позволяют судить о том, что речь идет именно о проблемах
                микрофона:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Собеседник вас просто не слышит,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Наблюдаются помехи при передаче вашего голоса,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Собеседник слышит вашу речь слишком тихо.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Если звука нет вообще, проблемы с микрофоном очевидны. При
                слабом звуке и помехах можно попробовать прочистить устройство,
                и проверить его работоспособность и звучание уже потом, однако в
                любом случае при таких проблемах стоит обратиться в мастерскую,
                чтобы воспользоваться услугами компетентных специалистов.
                Следует понимать, что Айфон – это чувствительная техника,
                которая требует особого отношения уже на этапе разборки, и
                потому дилетантский подход создает только дополнительные риски.
              </p>
              <p className="repair-iphone-display__paragraph">
                Поломки микрофона могут происходить по разным причинам. Это
                падение или удар, попадание воды, самый обычный износ. Во всех
                случаях устранение проблемы остается возможным, а ремонт –
                целесообразным. В будущем же, во избежание повторения этих и
                других поломок, стоит пользоваться качественным чехлом и
                стремиться к аккуратному обращению с техникой, эти меры позволят
                продлить срок беспроблемной работы устройства. Но если поломка
                уже произошла, главным и наиболее целесообразным выходом станет
                обращение в профессиональную мастерскую, где специалисты смогут
                уделить достойное внимание проблеме.
              </p>
            </>
          }
        />
        <RepairApplePrice
          subtitle="Быстрая замена микрофона на вашем iPhone 4s"
          text="Замена микрофона"
          price="1150 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если с Айфоном произошла поломка, и проблему необходимо
                устранить как можно скорее, наша мастерская готова оказать вам
                любую необходимую помощь в этом вопросе. Мы работаем уже много
                лет и специализируемся именно на современном высокотехнологичном
                оборудовании, оказываем услуги официальным порядком, с
                гарантиями и всеми документами, с обеспечением прекрасного
                сервиса и доступных цен.
              </p>
              <p className="repair-iphone-display__paragraph">
                Мастерская оснащена всем современным оборудованием для ремонта,
                и потому мы с легкостью выполняем даже сложнейшие манипуляции,
                реализуя поставленные задачи быстро и качественно. Все рабочие
                места оборудованы необходимым, у нас проработаны все условия для
                ремонта тонкой чувствительной техники. Запчасти всегда имеются в
                наличии, включая и микрофоны, и потому замена не отнимет много
                времени, вам не придется ждать поставки деталей. На все запчасти
                мы даем гарантию, равно как и на оказанные услуги – мы полностью
                уверены в качестве, и детали используем только заводские,
                официального происхождения.
              </p>
              <p className="repair-iphone-display__paragraph">
                Работы выполняют только квалифицированные специалисты, мы
                допускаем до работ исключительно проверенный персонал с опытом и
                профильным образованием – дилетантский подход исключен, наши
                мастера знают об Айфонах все. Все эти и многие другие аспекты
                позволяют нам оказывать услуги ремонта на высочайшем уровне и
                добиваться положительного результата там, где другие просто
                пасуют.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Если произошла поломка микрофона, или другая неисправность,
                приходите в сервис – первым делом мастер проведет диагностику,
                которая выполняется бесплатно и в считанные минуты. Обнаруженная
                неисправность будет оглашена, вы сможете узнать стоимость
                ремонта и сроки его выполнения. При вашем согласии мы немедленно
                начнем ремонтные работы, и будет оперативно действовать до
                полного устранения неполадки. Сервис выполняет ремонт быстро и
                по выгодным расценкам, если вы желаете получить услуги
                наивысшего качества и порадоваться результату, обращайтесь
                именно к нам. И ваша техника прослужит еще много лет.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels4s;
