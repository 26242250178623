import React from "react";
import { Link } from "react-router-dom";
import styles from "./NoteExpertDecision.module.css";

function NoteExpertDecision(props) {
  return (
    <li className={styles.item}>
      <img src={props.icon} alt="" />
      <b className={styles.title}>{props.text}</b>
      <p className={styles.text}>{props.paragraph}</p>
      <Link to={props.link} className={styles.button}>
        ПОДРОБНЕЕ
      </Link>
    </li>
  );
}

export default NoteExpertDecision;
