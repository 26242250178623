import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
  RepairAppleTableMobile,
} from "../index";
import banner from "../../images/banners/mb-matrix.jpeg";
import { useMedia } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

const listOfMatrix = [
  {
    id: 1,
    number: "01",
    subtitle: "Все матрицы в наличии.",
    textLi:
      "Вам не придется ждать долго поставку вашей матрицы, они всегда есть у нас в наличии. Среднее время установки матрицы - 1-2 часа.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Оптимальные цены.",
    textLi:
      "Стоимость замены матрицы у нас всегда на оптимальном уровне, мы не делаем высоких наценок. Наш сервисный центр получает матрицы непосредственно от производителей, в результате наши клиенты получают самые низкие цены на дисплеи и матрицы для MacBook всех моделей.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Качество.",
    textLi:
      "Опытные специалисты качественно установят матрицу на ваш MacBook. Вы можете быть уверены, никакие повреждения в результате установки ваш MacBook не понесет, установка матрицы происходит в стационарном сервисном центре с применением профессионального оборудования.",
  },
  {
    id: 4,
    number: "04",
    subtitle: "Гарантия.",
    textLi:
      "На все матрицы и услуги по установке в нашем сервисном центре распространяется гарантия от 6-и месяцев.",
  },
];

const textOfMatrix = [
  {
    id: 1,
    title: (
      <>
        Как производится замена матрицы на&nbsp;MacBook в&nbsp;сервисном центре
      </>
    ),
    text: "После согласования суммы ремонта с клиентом, специалист приступает к замене матрицы. Матрица меняется от 1-го до 4-х часов, срок замены зависит от модели MacBook. После замены матрицы на MacBook, специалист отправляет макбук в отдел контроля качества. После полной проверки работоспособности матрицы во всех режимах, макбук поступает на выдачу. Клиент проверяет полную работоспособность матрицы, после проверки оплачивает стоимость ремонтных работ. Оплата может быть произведена как наличным платежом, так и банковской картой и безналичным платежом.",
  },
  {
    id: 2,
    title: (
      <>
        Какую гарантию мы предоставляем на&nbsp;замену матрицы на&nbsp;MacBook
      </>
    ),
    text: "Клиенту, после произведения работ по замене матрицы, предоставляется гарантийный талон и чек. В случае, если оплата производилась по безналичному расчету - предоставляется полный пакет документов. На все произведенные работы по замене матрицы предоставляется гарантия от 3-х до 6-и месяцев. В гарантийный период, в случае если матрица оказалась бракованной, что бывает крайне редко, менее 1% случаев, мы безоговорочно меняем матрицу на новую.",
  },
];

const macBookWhiteMatrix = [
  {
    service: "Глянцевая 13,3', 1280х800",
    time: "от 2-х часов",
    price: "1150 руб. стоимость матрицы",
  },
];
const macBook1304Matrix = [
  {
    service: "Глянцевая 13,3', 1280х800",
    time: "от 2-х часов",
    price: "1850 руб. стоимость матрицы",
  },
];
const macBook1369Matrix = [
  {
    service: "Дисплей в сборе 13,3', 1440х900",
    time: "от 2-х часов",
    price: "1450 руб. стоимость матрицы",
  },
];
const macBook1370Matrix = [
  {
    service: "Дисплей в сборе 11,6', 1366х768",
    time: "от 2-х часов",
    price: "1450 руб. стоимость матрицы",
  },
];
const macBook1369rdMatrix = [
  {
    service: "Дисплей в сборе 11,6', 1366х768",
    time: "от 2-х часов",
    price: "3450 руб. стоимость матрицы",
  },
];
const macBook1370rdMatrix = [
  {
    service: "Дисплей в сборе 11,6', 1366х768",
    time: "от 2-х часов",
    price: "3450 руб. стоимость матрицы",
  },
];
const macBookProMatrix = [
  {
    service: "Ламповая (глянцевая) 15.4' 1440x900",
    time: "от 1 часа",
    price: "1150 руб. стоимость матрицы",
  },
  {
    service: "LED 15.4' 1440x900",
    time: "от 1 часа",
    price: "1150 руб. стоимость матрицы",
  },
  {
    service: "Ламповая (глянцевая) 17' 1680x1050",
    time: "от 1 часа",
    price: "1150 руб. стоимость матрицы",
  },
];

const iMacOptions = [
  {
    value: "MacBook White/Black",
    label: "MacBook White/Black",
    list: macBookWhiteMatrix,
  },
  {
    value: "MacBook Air A1304",
    label: "MacBook Air A1304",
    list: macBook1304Matrix,
  },
  {
    value: "MacBook Air A1369 2010 2011 года",
    label: "MacBook Air A1369 2010 2011 года",
    list: macBook1369Matrix,
  },
  {
    value: "MacBook Air A1370 2010 2011 года",
    label: "MacBook Air A1370 2010 2011 года",
    list: macBook1370Matrix,
  },
  {
    value: "MacBook Air A1369 3-rd 2010 2011 года",
    label: "MacBook Air A1369 3-rd 2010 2011 года",
    list: macBook1369rdMatrix,
  },
  {
    value: "MacBook Air A1370 3-rd 2010 2011 года",
    label: "MacBook Air A1370 3-rd 2010 2011 года",
    list: macBook1370rdMatrix,
  },
  {
    value: "MacBook Pro",
    label: "MacBook Pro",
    list: macBookProMatrix,
  },
];

function ChangeTableMacBookMatrix() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile options={iMacOptions} serviceText="Тип матрицы" />
    );
  }
  return (
    <table>
      <tbody>
        <tr className="mb-matrix__table-title">
          <th>Модель MacBook</th>
          <th>Тип матрицы</th>
          <th>Стоимость замены</th>
          <th>Сроки замены</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Диагностика в сервисном центре</td>
          <td colSpan="2">
            Полностью бесплатная аппаратная и программная диагностика
          </td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook White/Black</td>
          <td>Глянцевая 13,3", 1280х800</td>
          <td>1150 руб. стоимость матрицы</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1304</td>
          <td>Глянцевая 13,3", 1280х800</td>
          <td>1850 руб. стоимость матрицы</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1369 2010 2011 года</td>
          <td> Дисплей в сборе 13,3", 1440х900</td>
          <td>1450 руб. стоимость матрицы</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1370 2010 2011 года</td>
          <td>Дисплей в сборе 11,6", 1366х768</td>
          <td>1450 руб. стоимость матрицы</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1369 3-rd 2010 2011 года</td>
          <td>Дисплей в сборе 13,3", 1440х900</td>
          <td>3450 руб. стоимость матрицы</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Air A1370 3-rd 2010 2011 года</td>
          <td>Дисплей в сборе 11,6", 1366х768</td>
          <td>3450 руб. стоимость матрицы</td>
          <td>от 2-х часов</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Pro</td>
          <td> Ламповая (глянцевая) 15.4" 1440x900</td>
          <td>1150 руб. стоимость матрицы</td>
          <td>от 1 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Pro</td>
          <td>LED 15.4" 1440x900</td>
          <td>1150 руб. стоимость матрицы</td>
          <td>от 1 часа</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>MacBook Pro</td>
          <td> Ламповая (глянцевая) 17" 1680x1050</td>
          <td>1150 руб. стоимость матрицы</td>
          <td>от 1 часа</td>
        </tr>
      </tbody>
    </table>
  );
}

const RepairMacBookLinkMatrix = () => {
  return (
    <Page title="Замена матрицы MacBook, MacBook Pro, MacBook Air">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/zamena_matrici_MacBook"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Производим замену матриц на MacBook в Москве. Стационарный сервисный центр, все матрицы в наличии, быстрый ремонт."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена матрицы macbook"
          titleImg="замена матрицы macbook"
          banner={banner}
          title="Замена матрицы на MacBook, MacBook Pro, MacBook Air"
          text="Сервисный центр Mac Profi производит замену матриц на всех моделях MacBook. Преимущества замены матрицы в Mac Profi:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfMatrix.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {textOfMatrix.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Прайс лист на замену матриц на MacBook, MacBook Pro, MacBook Air
            </h2>
            {ChangeTableMacBookMatrix()}
            <p className="repair-mb__group-links-paragraph">
              Стоимость матриц постоянно меняется в меньшую сторону ввиду выхода
              новых поколений MacBook. Ввиду этого, уточняйте стоимость самой
              матрицы у оператора по телефону.
            </p>
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkMatrix;

// toDo:
// импортировать банер из индекса
// перенести списки в константы
