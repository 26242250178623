export const desireHD = [
  {
    text: "Диагностика",
    price: "Бесплатно",
  },
  {
    text: "Замена полифонического динамика",
    price: "1 000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена аккумулятора",
    price: "1 200 P",
  },
  {
    text: "Замена дисплейного модуля в сборе c тачскрином",
    price: "3 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена микрофона",
    price: "1 400 P",
  },
  {
    text: "Замена разъема заряда/синхронизации",
    price: "1 200 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Прошивка",
    price: "1 500 P",
  },
  {
    text: "Замена задней камеры",
    price: "2 100 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена задней крышки",
    price: "3 100 P",
  },
  {
    text: "Замена разговорного динамика",
    price: "1 100 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена кнопки включения",
    price: "1 200 P",
  },
];

export const desire400 = [
  {
    text: "Диагностика",
    price: "Бесплатно",
  },
  {
    text: "Замена полифонического динамика",
    price: "1 000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена аккумулятора",
    price: "1 200 P",
  },
  {
    text: "Замена дисплейного модуля в сборе c тачскрином",
    price: "3 900 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена микрофона",
    price: "1 400 P",
  },
  {
    text: "Замена разъема заряда/синхронизации",
    price: "1 200 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Прошивка",
    price: "1 500 P",
  },
  {
    text: "Замена задней камеры",
    price: "2 100 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена задней крышки",
    price: "3 100 P",
  },
  {
    text: "Замена разговорного динамика",
    price: "1 100 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена кнопки включения",
    price: "1 200 P",
  },
];
