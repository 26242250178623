import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone6plus.jpeg";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfModel6Plus = [
  {
    id: 1,
    title: "Порядок ремонта iPhone 6 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Большинство поломок и неисправностей Айфон 6 плюс имеют ту же природу,
          что и на других "яблочных" смартфонах:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Первое место, безусловно, принадлежит механическим повреждениям.
              Падение, удар или изгиб все ещё являются главными препятствиями на
              пути долгой и бесперебойной жизни вашего купертиновского телефона.
              Внушительные размеры iPhone 6 Plus в сочетании с округлыми рамками
              делают его даже еще более уязвимым в этом плане. После подобных
              неприятностей, скорее всего, понадобится замена стекла или
              дисплейного модуля.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Попадание воды или другой влаги на Айфон 6 Плюс с усиленной
              влагозащитной, по идее, должно остаться без последствий. Однако,
              если влага всё таки проникла внутрь корпуса, телефон потребует
              срочной диагностики.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Среди самых изнашиваемых деталей на первом месте стоит
              аккумулятор. Его замена требует особых навыков и оборудования, так
              как он почти намертво приклеен к корпусу. Однако, наши специалисты
              способны решить и эту проблему.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Скорее всего, вы видели подобные предупреждения, но не будет лишним
          подчеркнуть еще раз - ремонт Айфон 6 плюс не терпит вмешательства
          непрофессионалов. Пробуя разобраться с проблемами вашего телефона дома
          "на коленке" вы наверняка усугубите ситуацию.
        </p>
      </>
    ),
  },
];

const price6Plus = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage6Plus = () => {
  return (
    <Page title="Ремонт iPhone 6 Plus в Москве, бесплатная диагностика, бесплатная доставка в СЦ">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/6-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстрый и качественный ремонт iPhone 6 Plus в Москве. Бесплатная доставка и диагностика вашего Айфон 6 Плюс в сервисном центре Oh!MyGadget!."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 6 Plus"
          titleImg="Ремонт iPhone 6 Plus"
          banner={banner}
          title="Ремонт iPhone 6 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Любые проблемы связанные с ремонтом iPhone 6 Plus вы можете
                решить без труда и без излишних, неразумных трат в нашем
                сервисном центре. Ремонт Айфон 6 Плюс у нас, в сопоставлении с
                другими ремонтными мастерскими, отличается, рядом безусловных
                плюсов.
              </p>
              <p className="repair-iphone-display__paragraph">
                Во-первых, это абсолютная прозрачность ценообразования. Цену за
                очевидные поломки мы назовём вам сразу, в остальных случаях - по
                итогам диагностики (которая у нас, кстати, всегда бесплатна). С
                тех пор ценник остаётся неизменным. Если мы назвали вам цену по
                телефону, то точно такой же она останется и по приезду в офис.
                Озвученная цена включает и необходимый ремонт, и стоимость
                запчастей - если их понадобится менять. Мы всегда подробно
                объясняем нашим клиентам и советуемся с ними - какие виды
                ремонта будут сделаны, и какие детали подвергнутся замене.
              </p>
              <p className="repair-iphone-display__paragraph">
                Во-вторых, наши клиенты получают на все виды ремонта iPhone 6
                Plus и на все заманённые детали полноценную гарантию на 12
                месяцев. Деталь, которую мы поставили, ведёт себя не так?
                Заменим - бесплатно, и без лишних нареканий.
              </p>
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price6Plus}
            title="Цены на ремонт Айфон 6 Плюс"
          />
        </Section>
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfModel6Plus.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage6Plus;
