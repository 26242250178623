import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camerase.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCameraSE = [
  {
    id: 1,
    title: "Нарушения работы камеры iPhone SE: как они проявляются?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Нередко поломка одной из камер приводит к тому, что и другая перестает
          функционировать должным образом. Причиной неисправности может быть
          заводской брак, сбой программного обеспечения, попадание под корпус
          влаги или же механические повреждения. Проявляется это следующим
          образом:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Полосы или пятна на экране при попытке сделать снимок;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Черный экран или перезагрузка телефона при нажатии на камеру;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Неработающая вспышка;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Ухудшившееся качество снимков.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          В большинстве случаев ремонт проводится непосредственно при клиенте –
          это позволяет владельцу устройства проконтролировать все этапы
          процесса. Вместе с диагностикой, эта замена камеры iPhone SE занимает
          примерно полчаса. Если же после тестирования смартфона будут
          обнаружены и другие поломки, то длительность ремонта увеличиться на
          сутки – только после осмотра гаджета мастер назовет точные сроки и
          стоимость.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Сколько стоит замена камеры iPhone SE в Москве?",
    text: "Нас ценят за честность – гарантируем, что первоначальная цена не изменится и после завершения ремонта. На сайте представлены расценки на все базовые услуги, что позволяет оценить выгоды и преимущества сотрудничества с нашим сервисом. В стоимость уже включены затраты на комплектующие – мы приобретаем запчасти у постоянных поставщиков, с которыми работаем на специальных условиях. Это позволило формировать для клиентов действительно выгодные предложения – убедитесь в этом сами.",
  },
  {
    id: 3,
    title: "Преимущества московского сервиса Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          У нас работают лучшие мастера, которые используют специализированное
          оборудование и профессиональные инструменты. Благодаря этому
          нормальная работа устройства будет оперативно восстановлена, и даже
          сложный ремонт будет выполнен максимально быстро и качественно. Если
          замена камеры iPhone SE потребовалась из-за удара и падения смартфона,
          то мы устраним и сопутствующее повреждения – установим новый корпус и
          поменяем стекло.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мак Профи – это специализированный сервис в Москве, который оборудован
          всем необходимым. Доверьте свое устройство квалифицированным мастерам
          – и мы выполним эту задачу на высшем уровне, предоставив заказчику
          гарантии качества на все работы и установленные комплектующие.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModelsSE = () => {
  return (
    <Page title="Быстрая замена камеры iPhone SE с гарантией">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/se"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Срочная замена камеры iPhone SE: быстро заменим основную и фронтальную камеру"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone SE"
          titleImg="Замена камеры iPhone SE"
          banner={banner}
          title="Замена камеры iPhone SE"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone SE: профессиональный ремонт Apple iPhone в
                сервисном центре Мак Профи. Качественный сервис, курьерская
                доставка по Москве и честная ценовая политика.
              </p>
              <p className="repair-iphone-display__paragraph">
                В iPhone SE предусмотрены сразу 2 камеры: фронтальная и
                основная. Обе радуют хорошим качеством снимков, а поломка одной
                из них становится серьезной проблемой. В нашем сервисе работают
                квалифицированные инженеры, которые ответят на все вопросы,
                проведут диагностику и устранят поломки.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="1450 P"
          priceDown="1450 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCameraSE.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModelsSE;
