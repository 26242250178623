import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
} from "../index";
import { Link } from "react-router-dom";
import banner from "../../images/banners/mb-water.jpeg";
import { Helmet } from "react-helmet";

const listOfWater = [
  {
    id: 1,
    number: "01",
    subtitle: "Химическая чистка после залития MacBook",
    textLi:
      "Производим химическую чистку элементов MacBook в ультразвуковой камере. В 30% случаев залитый макбук после чистки начинает корректно работать.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "BGA пайка на материнской плате MacBook.",
    textLi:
      "Меняем BGA чипы на всех материнских платах MacBook. Благодаря профессиональным паяльным станциям, мы производим пайку и замену BGA чипов заводского уровня.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Замена дискретных элементов на материнской плате MacBook.",
    textLi:
      "Специалисты сервисного центра выявляют неисправные дискретные элементы (транзисторы, конденсаторы) и производят их замену на новые.",
  },
  {
    id: 4,
    number: "04",
    subtitle: (
      <>
        Замена клавиатуры MacBook{" "}
        <Link
          to="/remont-apple/remont-macbook/zamena_remont_klaviatury_macbook_pro_air"
          className="repair-mb__group-links-array"
        >
          (подробнее)
        </Link>
      </>
    ),
    textLi: "Все клавиатуры в наличии, меняются в течении 1 часа.",
  },
  {
    id: 5,
    number: "05",
    subtitle: "Восстановление цепей питания на материнской плате MacBook.",
    textLi:
      "Специалисты сервисного центры выявляют неисправные цепи питания, восстанавливают цепи питания до заводского уровня. После восстановления цепей питания, токопроводящие дорожки работают корректно, питания поступает ко всем компонентам платы MacBook.",
  },
  {
    id: 6,
    number: "06",
    subtitle: "Модульная замены компонентов MacBook.",
    textLi: (
      <>
        Также после залития выходят из строя жесткие диски, платы, модули,
        тачпады, дисплейные модули и матрицы{" "}
        <Link
          to="/remont-apple/remont-macbook/zamena_matrici_MacBook"
          className="repair-mb__group-links-array"
        >
          (подробнее)
        </Link>
        . Специалисты компании готовы произвести замену необходимых элементов на
        профессиональном уровне.
      </>
    ),
  },
];

const textOfWater = [
  {
    id: 1,
    title: "Как происходит процесс ремонта залитого MacBook",
    text: "Специалист сервисного центра выявляет неисправные компоненты MacBook, делает полную карту неисправности и передает информацию менеджерам. Менеджеры передают полную информацию клиенту, озвучивают полную стоимость ремонта. После подтверждения клиентом ремонта, специалисты преступают к ремонту. Прежде всего, при залитии макбук жидкостью, производится химическая чистка с сушкой всех компонентов. После химической чистки, специалисты восстанавливают и меняют все поврежденные элементы, аппарат проверяется в ОТК (отделе контроля качества), поступает на выдачу. Клиент проверяет аппарат, оплачивает полную стоимость ремонта.",
  },
  {
    id: 2,
    title: "Гарантия на ремонт залитых жидкостью MacBook, Pro, Air",
    text: "Гарантия предоставляется на каждый замененный компонент и составляет от 3 месяцев до 1 года в зависимости от компонента. Также предоставляется гарантия на все услуги по замене. В гарантийный период при возникновении проблем, мы полностью бесплатно обслуживаем MacBook и восстанавливаем его работоспособность. Специалисты Mac Profi восстанавливают 97% залитых MacBook. Обращайтесь!",
  },
];

const RepairMacBookLinkWater = () => {
  return (
    <Page title="Залит MacBook? Производим ремонт залитых MacBook, Pro, Air">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/zalil_macbook"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Весь комплекс услуг по восстановлению и ремонту залитых MacBook. Все детали в наличии, высокая скорость диагностики и ремонта всех моделей MacBook, Pro, Air."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="ремонт залитых macbook"
          titleImg="ремонт залитых macbook"
          banner={banner}
          title="Ремонт залитых MacBook, Pro, Air"
          text="Выполняем весь комплекс работ по ремонту залитых MacBook, Pro, Air. Ремонтные работы по залитым MacBook:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfWater.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {textOfWater.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkWater;

// toDo:
// вынести списки в константы
