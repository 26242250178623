import React from "react";
import Section from "../Section/Section";

const RepairIphoneAboutUs = () => {
  return (
    <Section>
      <div className="repair-iphone__au-container">
        <h2 className="repair-iphone__au-title">
          Поиск исполнителя и услуги нашего сервиса по ремонту Айфонов
        </h2>
        <div className="repair-iphone__au-border"></div>
        <h3 className="repair-iphone__au-subtitle">
          Потребовался ремонт Айфонов в Москве?
        </h3>
        <p className="repair-iphone__au-text">
          Понимаете всю важность выбора надежного исполнителя, который успешно
          справится с работой? В таком случае вам стоит обратиться в мастерскую
          Мак Профи. Мы занимаемся выполнением ремонта уже много лет,
          специализируемся именно на высокотехнологичных современных образцах, и
          справляемся со сложнейшими проблемами – даже в тех случаях, когда
          другие исполнители просто пасуют.
        </p>
        <div className="repair-iphone-au-border-paragraph"></div>
        <p className="repair-iphone__au-text">
          Специалисты сервисного центра – это не просто компетентные люди, они
          знают об Айфонах буквально все, и потому с любой задачей они справятся
          уверенно и оперативно. На все работы, а также и на использованные при
          ремонте запчасти, мы даем длительный срок гарантии – у нас нет никаких
          сомнений в том, что аналогичная поломка не повторится.
        </p>
        <div className="repair-iphone-au-border-paragraph"></div>
        <p className="repair-iphone__au-text">
          Ремонт Айфонов любых моделей, и вне зависимости от специфики
          устройства ему будет обеспечен профессиональный подход с пониманием
          всех его особенностей. Мы выполняем срочные работы по ремонту – если
          вы не желаете и не можете расставаться с Айфоном надолго, мы можем
          выполнить его ремонт в первоочередном порядке, за считанные часы-
          время будет зависеть от сложности проблемы.
        </p>
        <div className="repair-iphone-au-border-paragraph"></div>
        <p className="repair-iphone__au-text">
          У нас осуществим ремонт при получении гаджетом механических
          повреждений, при его утоплении, программных сбоях и любых других
          проблемах – практика показала, что устранимо если не все, то
          практически все.
        </p>
      </div>
    </Section>
  );
};

export default RepairIphoneAboutUs;
