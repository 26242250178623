import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileFeedback.module.css";

function CenterMobileFeedbackMobile(props) {
  return (
    <li className={styles.feedback}>
      <img
        src={props.img}
        className="center-mobile__general-page-feedbacks-img"
        alt="аватар пользователя"
      />
      <h4 className="center-mobile__general-page-feedbacks-name">
        {props.name}
      </h4>
      <p className="center-mobile__general-page-feedbacks-text">{props.text}</p>
    </li>
  );
}

export default CenterMobileFeedbackMobile;
