import React from "react";
import { Section } from "../index";

const RepairIMacTitle = () => {
  return (
    <Section>
      <div className="repair-imac__title-container">
        <article>
          <h1 className="repair-imac__title-title">Ремонт iMac</h1>
          <div className="repair-imac__title-border"></div>
          <p className="repair-imac__title-paragraph">
            Наш сервисный центр специализируется на ремонте iMac. Мы
            осуществляем ремонтные работы любой степени сложности.
          </p>
        </article>
        <div className="repair-imac__title-icon"></div>
      </div>
    </Section>
  );
};

export default RepairIMacTitle;
