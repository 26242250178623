import React from "react";
import Section from "../Section/Section";

const RepairMacBookInfo = () => {
  return (
    <Section>
      <div className="repair-mb__info-container">
        <div className="repair-mb__info-wrapper">
          <h1 className="repair-mb__info-title">Ремонт MacBook</h1>
          <h4 className="repair-mb__info-subtitle">
            Сервисный центр Мак Профи производит ремонт MacBook в Москве.
          </h4>
          <p className="repair-mb__info-text">
            Обратите внимание, Вам не обязательно иметь при себе гарантийный
            талон, идентификация вашего MacBook производится по серийному
            номеру. При обращении в сервисный центр Вам достаточно указать
            серийный номер вашего MacBook.
          </p>
        </div>
        <div className="repair-mb__info-img"></div>
      </div>
    </Section>
  );
};

export default RepairMacBookInfo;

// toDo:
// проверить, точно на ли здесь h1 ??
