import React from "react";
import { Carousel } from "react-responsive-carousel";
import Section from "../Section/Section";
import { Link } from "react-router-dom";

const listOfModelIwatchSlide1 = [
  {
    model: "Ремонт Apple Watch",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 1",
    href: "/remont-apple/remont_apple_watch/series-1",
  },
  {
    model: "Ремонт Apple Watch Series 2",
    href: "/remont-apple/remont_apple_watch/series-2",
  },
  {
    model: "Ремонт Apple Watch Series 3",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 4",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 5",
    href: "#",
  },
];

const listOfModelIwatchSlide2 = [
  {
    model: "Ремонт Apple Watch Series 6",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series SE",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Series 7",
    href: "#",
  },
  {
    model: "Ремонт Apple Watch Nike",
    href: "/remont-apple/remont_apple_watch/nike",
  },
  {
    model: "Ремонт Apple Watch Edition",
    href: "/remont-apple/remont_apple_watch/edition",
  },
  {
    model: "Ремонт Apple Watch Sport",
    href: "/remont-apple/remont_apple_watch/sport",
  },
];

const RepairIwatchModelsLinksMobile = () => {
  return (
    <Section className="repair-iphone__models-links-container">
      <div className="repair-iphone-mobile__models-links-content">
        <h2 className="repair-iphone-mobile__models-links-title">
          Больше детальной информации по каждой модели
        </h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className="repair-iphone-mobile__models-links-container">
            <ul className="repair-iphone-mobile__models-links-list">
              {listOfModelIwatchSlide1.map((link, i) => {
                return (
                  <li
                    key={i}
                    className="repair-iphone-mobile__models-links-item"
                  >
                    <div className="repair-mb__links-mobile-icon"></div>
                    <Link
                      to={link.href}
                      className="repair-iphone-mobile__models-links-link"
                    >
                      {link.model}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="repair-iphone-mobile__models-links-container">
            <ul className="repair-iphone-mobile__models-links-list">
              {listOfModelIwatchSlide2.map((link, i) => {
                return (
                  <li
                    key={i}
                    className="repair-iphone-mobile__models-links-item"
                  >
                    <div className="repair-mb__links-mobile-icon"></div>
                    <Link
                      to={link.href}
                      className="repair-iphone-mobile__models-links-link"
                    >
                      {link.model}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default RepairIwatchModelsLinksMobile;
