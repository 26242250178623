import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speakerse.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfSpeakerSE = [
  {
    id: 1,
    title: "Замена динамика iPhone SE в сервисном центре Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Столкнувшись с ситуацией, когда звуковоспроизведение вашего Айфона
          перестало быть удовлетворительным, обращайтесь в сервисный центр Мак
          Профи! Мы много лет специализируемся на ремонте высокотехнологичных
          девайсов, и выполняем свою работу уверенно, качественно и в короткие
          сроки. После работы наших мастеров вам не придется снова сталкиваться
          с теми же проблемами, и мы дадим долговременную гарантию как на
          работы, так и на использованные запчасти. Наш центр имеет прекрасную
          репутацию, за годы работы он получил массу хвалебных отзывов. Нас
          рекомендуют.
        </p>
        <p className="repair-iphone-display__paragraph">
          Центр работает официально, с подписанием всех необходимых бумаг,
          договоров и гарантийных талонов. Для клиентов создаются удобные
          условия сотрудничества, а кроме того, обращение к нам не предполагает
          переплат, ведь мы не завышаем расценки. Если вы желаете получить
          качественный ремонт с предсказуемым результатом, мы предоставим вам
          наилучшие условия для его получения, в срочном или штатном режиме.
        </p>
        <p className="repair-iphone-display__paragraph">
          Наша мастерская укомплектована всем необходимым оборудованием для
          реализации работ любой сложности, каждое рабочее место адаптировано
          под ремонт высокотехнологичного оборудования. Запчасти всегда есть в
          наличии, включая и динамики – мы используем только высококачественные
          заводские изделия оригинального происхождения, и даем на них гарантию.
          Наличие деталей существенно ускоряет выполнение работ, вам не придется
          ждать их доставки, заказа. Работами занимается только компетентный
          высококвалифицированный персонал, мы не допускаем дилетантов на
          рабочие места, и это тоже становится существенным преимуществом и
          надежным гарантом результативности выполнения задач.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если с вашим Айфоном произошла поломка, просто приходите к ним, и
          мастер проведет экспресс-диагностику, которая займет несколько минут и
          позволит судить о сути дефекта. После этого он сможет назвать причину,
          стоимость ремонтных работ, ответит на любые ваши вопросы. Диагностика
          проводится бесплатно, а стоимость работ по ремонту вы узнаете после
          нее – в дальнейшем расценки меняться не будут. Если проблема именно в
          динамике – мы устраним ее за считанные часы с гарантированным
          результатом. Обращайтесь к нам, если вы желаете обеспечить своему
          девайсу еще много лет исправной работы.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkSpeakerModelsSE = () => {
  return (
    <Page title="Срочная замена динамика iPhone SE в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-dinamika/SE"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстрая замена динамика iPhone se возможна в сервисном центре Oh!MyGadget!. Гарантированно профессиональный подход и выгодные цены, оперативная работа."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена динамика iPhone SE"
          titleImg="Замена динамика iPhone SE"
          banner={banner}
          title="Замена динамика iPhone SE"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с динамиков у Айфонов носят довольно распространенный
                характер, они встречаются часто. В некоторых случаях динамик
                отказывает полностью и не воспроизводит звук, в других же
                ситуациях могут наблюдаться такие явления:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Шипение, хрип на низких частотах при прослушивании музыки
                    без наушников,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Искажения звука самого разного типа,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Невозможность вести диалог с собеседником из-за проблем
                    слышимости.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Все эти и многие другие показатели говорят о том, что необходима
                замена динамика iPhone se, и именно таким образом удастся
                вернуть нормальную работоспособность устройства. Если вы
                столкнулись с такими проблемами, крайне важно не пытаться
                сделать что-либо своими силами – непрофессиональный подход в
                ремонте высокотехнологичной аппаратуры часто приводит к
                неутешительным результатам, и вещь просто выходит из строя
                полностью без шансов на восстановление.
              </p>
              <p className="repair-iphone-display__paragraph">
                Чтобы не покупать новый Айфон и не тратить массу средств на его
                приобретение, лучше сразу обратиться в мастерскую, где
                действительно смогут оказать помощь, и получить результат,
                быстро и за умеренные деньги. Сервисный центр Мак Профи
                предлагает услуги по ремонту и замене динамиков на простых и
                выгодных условиях, с возможностью выполнения работ в срочном и
                штатном порядке.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1500 P"
          priceDown="1500 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfSpeakerSE.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModelsSE;
