import React from 'react';
import { BlockTitle, Section } from '../../components/index';

const DeliveryAbout = () => {
  return (
    <Section className='delivery-about'>
      <div className='delivery-about__container'>
        <BlockTitle className='subtitle-long' title='Оформление заявки на доставку' />
        <h4 className='delivery-about__subtitle'>Для удобства наших клиентов мы готовы предложить доставку техники в сервисный центр и из сервисного центра по всей России</h4>
      </div>
    </Section>
  )
};

export default DeliveryAbout;
