import React from 'react';
import { Section } from '../index';
import qualityMobile from '../../images/icons/qualityMobile.svg';
import comfortMobile from '../../images/icons/comfortMobile.svg';
import easyMobile from '../../images/icons/easyMobile.svg';

const advantagesList = [
  {
    src: qualityMobile,
    text: 'Стандарт качества',
    paragraph: 'Клиентский сервис, качество ремонта, гарантия в любой точке как в Столице (в главном офисе).',
  },
  {
    src: comfortMobile,
    text: 'УДОБНО',
    paragraph: 'Все бренды, в любой точке РФ, легкий доступ к информации о заказе и опциям управления заказом (оплата, доставка, доп. услуги).',
  },
  {
    src: easyMobile,
    text: 'Просто',
    paragraph: 'Легко сдать в сервис (звонок или кнопка на сайте), быстро продиагностируем и отремонтируем, выдадим или доставим.',
  },
]

const AdvantagesMobile = () => {
  return (
    <Section className='advantages-mobile'>
      <div className='advantages-mobile__content'>
        <h2 className='advantages-mobile__title'>Преимущества</h2>
        <div className='advantages-mobile__border'></div>
        <ul className='advantages-mobile__list'>
          {advantagesList.map((item, i) => {
            return (
              <li key={i} className='advantages-mobile__item'>
                <div className='advantages-mobile__list-wrapper'>
                  <img className='advantages-mobile__icon' src={item.src} alt='иконка сайта'/>
                  <h4 className='advantages-mobile__subtitle'>{item.text}</h4>
                </div>
                <p className='advantages-mobile__paragraph'>{item.paragraph}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </Section>
  )
};

export default AdvantagesMobile;