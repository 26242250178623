import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
  RepairIpadModelLinksForServiceMobile,
} from "../index";
import banner from "../../images/banners/batteryIpadAir.jpeg";
import { iPadListOfModelsForBattery } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Планшет можно включить только на зарядке",
    number: "01",
  },
  {
    id: 2,
    text: "iPad не включается",
    number: "02",
  },
  {
    id: 3,
    text: "Заряда хватает лишь на несколько часов",
    number: "03",
  },
  {
    id: 4,
    text: "Устройство самостоятельно отключается",
    number: "04",
  },
];

const textOfBatteryMini = [
  {
    id: 1,
    title: "Когда нужна замена аккумулятора iPad Air",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="О том, что пора приобретать новый источник питания, сигнализируют следующие «симптомы»:"
          array={listOfTrouble}
          paragraphDown="Причин возникновения подобных неполадок может быть много. Это и естественный износ аккумулятора, который неизбежен, и использование китайских аксессуаров (кабели, зарядные устройства). Кроме того, работа планшета может быть нарушена после сильного удара или же проникновения под корпус жидкости – в этом случае существует риск повреждения и других систем. По этой причине мы обязательно проводим первичную диагностику, чтобы оценить серьезность проблемы и комплексно подойти к её решению."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Особенности замены аккумулятора iPad Air",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Замена аккумулятора iPad Air – сложная процедура, которая требует
          предельной точности. Чтобы сократить размеры планшета, производитель
          скомпоновал «начинку» таким образом, что места под корпусом
          практически не осталось. Соответственно, чтобы добраться до источника
          питания, предстоит аккуратно извлечь целый ряд шлейфов, делая это в
          определенной последовательности. Малейшая ошибка – и стоимость ремонта
          возрастет в несколько раз.
        </p>
        <p className="repair-iphone-display__paragraph">
          Гораздо дешевле однократно заплатить мастерам в сервисе, которые
          быстро заменят неисправный аккумулятор и предоставят необходимые
          гарантии качественного выполнения работы. Тем более работники Мак
          Профи специализируются на ремонте техники Apple, и хорошо знакомы с
          основными тонкостями подобного ремонта.
        </p>
      </>
    ),
  },
];

const RepairIpadServiceLinkBatteryModelAir = () => {
  return (
    <Page title="Замена аккумулятора (батареи) iPad Air (Айпад Эйр): полный цикл услуг в сервисе Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-akkumuljatora/air"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Оперативная, профессиональная замена аккумулятора iPad Air выполняется в сервисном центре Oh!MyGadget!. Мы обеспечим вам прекрасный результат и индивидуальное внимание!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора iPad Air"
          alt="Замена аккумулятора iPad Air"
          titleImg="Замена аккумулятора iPad Air"
          text="Заявленное время работы iPad Air составляет 10 часов – этот легкий и компактный планшет с легкостью обходит большинство конкурентов. Вот только этот показатель актуален лишь при использовании новых элементов питания, а уже спустя год он несколько снизится. Если же период автономной работы составит меньше 5 часов, значит, пора поспешить в сервис – требуется замена аккумулятора iPad Air."
        />
        <Section className="section_group">
          {textOfBatteryMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость и сроки замены аккумулятора в сервисе Мак Профи"
          text="Замена аккумулятора"
          price="3450 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                На замену аккумулятора iPad Air потребуется около 2-3 часов, а в
                сложных случаях придется оставить устройство в сервисе на сутки.
                При необходимости выполним срочный ремонт, который с учетом
                диагностики займет меньше часа – все работы могут быть проведены
                в присутствии клиента.
              </p>
              <p className="repair-iphone-display__paragraph">
                Стоимость этой услуги достаточно лояльна, а для постоянных
                клиентов у нас действуют специальные условия. Актуальные
                расценки представлены на сайте – и вы можете с ними
                ознакомиться, чтобы оценить преимущества этого предложения.
              </p>
              <p className="repair-iphone-display__paragraph">
                Приносите устройство на диагностику – наши мастера определяет
                причину поломки, выявят скрытые повреждения и заменят батарею
                iPad Air. Минимум времени и финансовых затрат, чтобы ваш планшет
                вновь радовал длительной автономной работой.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForBattery}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkBatteryModelAir;
