import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairIphoneServicesLinks,
  RepairIphoneModelsLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-water.png";
import { Helmet } from "react-helmet";

const listOfWater = [
  {
    id: 1,
    number: "01",
    subtitle: "Производим диагностику залитого iPhone.",
    textLi:
      "Диагностика производится в течении суток. Столь долгий срок обусловлен выявлением всех неисправных компонентов, а также оценкой характера повреждения.",
  },
  {
    id: 2,
    number: "02",
    subtitle:
      "Связываемся с клиентом и полностью описываем необходимые ремонтные работы.",
    textLi:
      "После диагностики менеджеры связываются с клиентом удобным для клиента способом: телефон, e-mail, полностью предоставляют всю карту неисправности, а также называют точную сумму ремонта.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Ремонт залитого iPhone.",
    textLi:
      "Ремонтные работы по айфонам залитых жидкостью производятся, как правило, от 1-го до 4-х рабочих дней. После ремонта производится 12 часовое тестирование айфона, если тестирование не выявило неисправностей, айфон поступает на выдачу.",
  },
  {
    id: 4,
    number: "04",
    subtitle: "Оплата ремонтных работ.",
    textLi:
      "Оплата производится после проверки iPhone клиентом. Оплатить вы можете как банковской картой, наличными, так и по безналичному расчету.",
  },
];

const listOfWaterBreakdowns = [
  {
    id: 1,
    number: "01",
    subtitle: "Залил iPhone водой.",
    textLi:
      "Наиболее встречается при использовании iPhone в дождь, либо в странах с влажным климатом. Влага попадает внутрь устройства, что приводит к короткому замыканию, в результате короткого замыкания выходят из строя дискретные элементы, контроллеры, цепи питания на материнской плате.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Залил iPhone жидкостью (алкогольные напитки).",
    textLi:
      "Неисправности в данном случае такие же, как и в случае залития водой.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "iPhone залит вязкой жидкостью.",
    textLi:
      "Встречается реже, вязкие жидкости как правило очень тяжело очищаются, попадание на микрофон, слуховые динамики, полифонические динамики вязкой жидкости приводит к выходу из строя данных компонентов. В случае попадания вязкой жидкости, выход из строя материнской платы iPhone наблюдается реже.",
  },
];

const textOfWater = [
  {
    id: 1,
    title: "Немного о том, как мы производим ремонт залитого iPhone",
    text: (
      <>
        После поступление iPhone в технический отдел специалисту, специалист
        производит полную очистку iPhone от жидкостей и влаги в ультразвуковой
        камере. После ультразвуковой камеры производится полная сушка всех
        компонентов айфона. После того, как iPhone высох, производится выявление
        неисправностей материнской платы на аппаратном стенде. После выявления
        неисправности, специалист делает полную карту неисправности и передает
        информацию менеджерам. После подтверждения ремонта клиентов, специалист
        приступает к ремонтным работам. Ремонт производится на
        высококвалифицированном уровне с применением оригинальных комплектующих,
        а также с применением профессионального оборудования с качеством
        заводской пайки. После завершения ремонта материнской платы iPhone,
        производится полное тестирование, в случае если выявлены неисправности в
        процессе тестирование, специалист их устраняет. После прохождения ОТК,
        специалист ставит iPhone на выдачу.{" "}
        <Link
          to="/remont-apple/remont-iphone"
          className="repair-mb__group-links-array"
        >
          Здесь
        </Link>{" "}
        вся актуальна информация по замене компонентов и ценам.
      </>
    ),
  },
];

const RepairIphoneServiceLinkWater = () => {
  return (
    <Page title="Ремонт iPhone залитых водой и жидкостью, ремонт материнской платы iPhone">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zalil_iphone"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Полный комплекс по ремонту iPhone 4, 4S, 5, 5S, 6, 6+, 6S, 6S+, 7, 7+ залитых водой и жидкостью. Производим полный аппаратный ремонт материнских плат iPhone всех моделей после попадания влаги. Предоставляем гарантия!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="ремонт залитых iphone"
          titleImg="ремонт залитых iphone"
          banner={banner}
          title="Ремонт iPhone залитых водой и жидкостью, ремонт материнской платы iPhone"
          text="Выполняем весь комплекс ремонтных работ по iPhone залитых водой и жидкостями. Производим сложный ремонт с заменой чипов, дискретных элементов, цепей питания, восстанавливаем токопроводящие дорожки. Порядок выполнения ремонтных работ по iPhone залитых жидкостью:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfWater.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "34px 0 34px" }}>
          <div className="repair-mb__group-list-container">
            <h2 className="repair-mb__group-links-subtitle">
              Наиболее встречающиеся виды поломок залитых iPhone
            </h2>
            {listOfWaterBreakdowns.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          <div className="repair-mb__group-list-container">
            {textOfWater.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkWater;
