import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone8plus.png";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfModel8Plus = [
  {
    id: 1,
    title: "Что приводит к ремонту iPhone 8 Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Любая техника, даже самая надежная, имеет собственные уязвимые места,
          и Айфон не является исключением. В мастерские и сервисные центры
          крайне часто обращаются с такими проблемами:
        </p>
        <p className="repair-iphone-display__paragraph">
          Обратившись за ремонтом iPhone 5s в наш центр вы получаете:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Необходимость замены аккумулятора, который стал быстро садиться
              или получил иную неисправность,
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Замена динамика, диктофона,
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              На практике все это оказывается вполне возможным, и большинство
              проблем, которые случаются с Айфонами, вполне устранимы.
              Своевременное обращение к грамотным специалистам позволит
              исключить массу сложностей и получить прекрасный результат – ваша
              техника прослужит еще долго.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    title: "Как выполняется ремонт iPhone 8 Plus в Москве",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы заметили, что ваша техника перестала работать должным образом,
          или если она получила повреждения, обращайтесь в сервисный центр Мак
          Профи, и ваша проблема будет решена оперативным образом, качественно и
          с гарантией. Услуги ремонта могут быть оказаны практически в любом
          случае, и выглядеть они будут примерно следующим образом.
        </p>
        <p className="repair-iphone-display__paragraph">
          В первую очередь вам предложат бесплатную диагностику – это
          мероприятие позволяет установить причину симптомов неисправности,
          детализировать поломки и увидеть их в полном спектре. Она отнимет
          всего несколько минут, зато предоставит специалистам максимум
          информации, исходя из которой они смогут назвать стоимость ремонта и
          условия. Если предложение вас устроит – будет подписан договор, и
          мастера примутся за свою работу. Результат вы сможете получить через
          считанные часы, в крайнем случае – через сутки, это зависит от
          сложности проблемы и нагрузки на мастерскую. Далее оборудование будет
          протестировано перед вами, и вы получите его с гарантией, которая
          будет распространяться как на саму работу, так и на использованные
          запчасти.
        </p>
        <p className="repair-iphone-display__paragraph">
          Если вы столкнулись с поломками устройства и нуждаетесь в ремонте,
          сервис Мак Профи будет рад оказать вам помощь в полном необходимом
          спектре. Мы работаем уже много лет и добиваемся прекрасных результатов
          даже в сложных случаях. Компания имеет прекрасную репутацию,
          пользуется заслуженной доброй славой. Обращаясь к нам, вы доверите
          Айфон в проверенные руки.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы используем только качественные запчасти, квалифицированный труд, а
          работы проводятся только в специализированных условиях. Обращаясь к
          нам, вы сможете получить прекрасный результат под гарантию на долгий
          срок!
        </p>
      </>
    ),
  },
];

const price8Plus = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage8Plus = () => {
  return (
    <Page title="Ремонт iPhone 8 Plus - бесплатная диагностика">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/8-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Мастерская выполнит ремонт iPhone 8 Plus вне зависимости от сложности поломки. Обращайтесь в Oh!MyGadget!, чтобы получить профессиональный ремонт в Москве!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 8 Plus"
          titleImg="Ремонт iPhone 8 Plus"
          banner={banner}
          title="Ремонт iPhone 8 Plus"
          text={
            <>
              Айфоны восьмой серии начали выпускаться в 2017 году, и как это
              бывает характерно для новинок данной компании, раскупать их начали
              мгновенно. Фактически, все устройства, которые имеются сейчас у
              владельцев, являются новыми, однако не стоит полагать, что ремонт
              таковых является невостребованной задачей. Услуги ремонта нужны,
              хотя бы потому, что основная часть проблем, которые происходят с
              подобными гаджетами, возникает по неосторожности, из-за
              механических повреждений и прочих труднопредсказуемых
              случайностей. Также проблемой может стать заводской брак, хотя и
              такие случаи крайне редки. В целом же, Айфоны – это надежная
              техника, хорошо приспособленная для длительной эксплуатации, и
              даже если в силу обстоятельств она получает повреждения, в
              большинстве случаев гаджет остается пригодным к ремонту и
              восстановлению. Если вам потребовался ремонт iPhone 8 Plus –
              важнейшим моментом станет поиск специалистов, которые смогут
              выполнить эту работу на достойном уровне, без риска для
              чувствительной техники и с длительным сроком гарантии.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price8Plus}
            title="Цены на ремонт Айфон 8 Plus"
          />
        </Section>
        <Section className="section_group" style={{ padding: "0 0 65px" }}>
          {textOfModel8Plus.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage8Plus;
