import react from "react";
import { Section } from "../index";
import banner from "../../images/banners/cooperation-banner.png";

const CooperationBanner = () => {
  return (
    <Section>
      <img
        src={banner}
        alt="сотрудничество с Oh!MyGadget!"
        title="сотрудничество с Oh!MyGadget!"
        className="cooperation-banner"
      />
    </Section>
  );
};

export default CooperationBanner;
