import react from "react";
import IconTel from "../../images/icons/cooper-tel.svg";
import IconEmail from "../../images/icons/cooper-mail.svg";
import Section from "../Section/Section";
import IconTelMobile from "../../images/icons/cooperation-icon-phone-mobile.svg";
import IconMailMobile from "../../images/icons/cooperation-icon-mail-mobile.svg";
import { useMedia } from "use-media";

const CooperationContacts = () => {
  const isWide = useMedia({ minWidth: 510 });

  return (
    <Section>
      <div className="cooperation-cont__contant">
        <h2 className="cooperation-cont__title">
          Подробная информация по сотрудничеству
        </h2>
        <h4 className="cooperation-cont__subtitle">
          Бодриченко Дмитрий Николаевич
        </h4>
        <p className="cooperation-cont__text">
          Менеджер по работе с корпоративными клиентами ООО “Мак-профи”
        </p>
        <ul className="cooperation-cont__links">
          <li className="cooperation-cont__item">
            {isWide ? (
              <img
                className="cooperation-cont__icon"
                src={IconTel}
                alt="иконка"
              />
            ) : (
              <img
                className="cooperation-cont__icon"
                src={IconTelMobile}
                alt="иконка"
              />
            )}
            <a className="cooperation-cont__link" href="tel:+74955405067?call">
              +7 (495) 540-50-67
            </a>
          </li>
          <li className="cooperation-cont__item">
            {isWide ? (
              <img
                className="cooperation-cont__icon"
                src={IconEmail}
                alt="иконка"
              />
            ) : (
              <img
                className="cooperation-cont__icon"
                src={IconMailMobile}
                alt="иконка"
              />
            )}
            <a
              className="cooperation-cont__link"
              href="mailto:gorbunov@mac-profi.ru"
            >
              manager@mac-profi.ru
            </a>
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default CooperationContacts;

// toDo:
// перенести иконки в index, импортировать одной строкой
// h1 ???
