import React from "react";
import {
  DeliveryAbout,
  DeliveryForms,
  DeliveryInfo,
  DeliveryBanner,
  DeliveryBannerMobile,
  DeliveryInfoMobile,
  Page,
} from "../../components/index";
import DeliveryFormApplication from "../DeliveryFormApplication/DeliveryFormApplication";
import { useMobileScreen, useTabletScreen } from "../../utils/useMedia";
import { Helmet } from "react-helmet";

function ChangeBannerComponent() {
  const isMedia = useTabletScreen();
  if (isMedia) {
    return <DeliveryBannerMobile />;
  }
  return <DeliveryBanner />;
}

function ChangeInfoComponent() {
  const isMedia = useMobileScreen();
  if (isMedia) {
    return <DeliveryInfoMobile />;
  }
  return <DeliveryInfo />;
}

const DeliveryPage = ({
  isDeliveryFormApplication,
  setIsDeliveryFormApplication,
}) => {
  return (
    <main className="delivery-page">
      <Page title="Доставка техники Apple в сервисный центр Oh!MyGadget!">
        <Helmet>
          <link rel="canonical" href="https://ohmygadget.ru/dostavka" />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Предоставляем срочную доставку в ремонт смартфонов и планшетов из всех регионах России в сервисный центр Oh!MyGadget!"
          />
        </Helmet>
        {ChangeBannerComponent()}
        <DeliveryAbout />
        <DeliveryForms
          setIsDeliveryFormApplication={setIsDeliveryFormApplication}
          isDeliveryFormApplication={isDeliveryFormApplication}
        />
        <DeliveryFormApplication
          isDeliveryFormApplication={isDeliveryFormApplication}
          setIsDeliveryFormApplication={setIsDeliveryFormApplication}
        />
        {ChangeInfoComponent()}
      </Page>
    </main>
  );
};

export default DeliveryPage;

// toDo:
// проверить почему DeliveryFormApplication не в индексе, а импортируется отдельно??
