import React, { useState } from "react";
import Section from "../../Section/Section";
import irina from "../../../images/center-mobile-feedbacks-avater.svg";
import Anatoliy from "../../../images/center-mobile-feedbacks-avater2.svg";
import Vitaliy from "../../../images/center-mobile-feedbacks-avater3.svg";
import { useMedia } from "../../../utils/useMedia";
import Carousel from "react-elastic-carousel";
import CenterMobileFeedback from "./CenterMobileFeedback";
import CenterMobileFeedbackMobile from "./CenterMobileFeedbackMobile";
import CenterMobileFeedbacksPopup from "./CenterMobileFeedbacksPopup";
import CenterMobileFeedbacksSlider from "./CenterMobileFeedbacksSlider";

const feedbacks = [
  {
    img: Anatoliy,
    name: "Анатолий",
    text: "Спасибо всей команде работников! Обработка заказа, логистика, ремонт - все быстро и качественно.Спасибо!!! В случае необходимости, обязательно обращусь в Ваш сервисный центр.",
  },
  {
    img: irina,
    name: "Ирина",
    text: "Все сделали быстро и красиво, вежливый персонал, качественный ремонт (меняли дисплей). Остался доволен, спасибо огромное)",
  },
  {
    img: Vitaliy,
    name: "Виталий",
    text: "Специалисты сервисного центра спасли мой ноутбук от вируса из-за которого компьютер начал без конца перезагружаться. У меня не получилось даже переустановить систему из-за этой постоянной перезагрузки. Спасибо!",
  },
  {
    img: Vitaliy,
    name: "Виталий",
    text: "Специалисты сервисного центра спасли мой ноутбук от вируса из-за которого компьютер начал без конца перезагружаться. У меня не получилось даже переустановить систему из-за этой постоянной перезагрузки. Спасибо! Специалисты сервисного центра спасли мой ноутбук от вируса из-за которого компьютер начал без конца перезагружаться. У меня не получилось даже переустановить систему из-за этой постоянной перезагрузки. Спасибо!",
  },
];

function OtherPage() {
  let [isOpen, setIsOpen] = useState(false);

  const isBreakpoint = useMedia("(max-width: 1150px)");
  const breakPoints = [
    { width: 1100, itemsToShow: 1 },
    { width: 740, itemsToShow: 2 },
  ];

  if (isBreakpoint) {
    return (
      <Section className="center-mobile__general-page-feedbacks">
        <div className="center-mobile__general-page-feedbacks-container">
          <h2 className="center-mobile__general-page-feedbacks-title-mobile">
            ОТЗЫВЫ
          </h2>
          <ul className="center-mobile__general-page-feedbacks-list-mobile">
            <Carousel breakPoints={breakPoints}>
              {feedbacks.map((feedback, i) => {
                return (
                  <CenterMobileFeedbackMobile
                    key={i}
                    img={feedback.img}
                    name={feedback.name}
                    text={feedback.text}
                  />
                );
              })}
            </Carousel>
          </ul>
          <button
            onClick={() => setIsOpen(true)}
            className="center-mobile__general-page-feedbacks-button"
          >
            Оставить отзыв
          </button>
          <CenterMobileFeedbacksPopup isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </Section>
    );
  }
  return (
    <Section className="center-mobile__general-page-feedbacks">
      <div className="center-mobile__general-page-feedbacks-container">
        <h2 className="center-mobile__general-page-feedbacks-title">ОТЗЫВЫ</h2>

        <CenterMobileFeedbacksSlider feedbacks={feedbacks}>
          {feedbacks.map((feedback, i) => {
            return (
              <CenterMobileFeedback
                key={i}
                img={feedback.img}
                name={feedback.name}
                text={feedback.text}
              />
            );
          })}
        </CenterMobileFeedbacksSlider>

        <button
          onClick={() => setIsOpen(true)}
          className="center-mobile__general-page-feedbacks-button"
        >
          Оставить отзыв
        </button>

        <CenterMobileFeedbacksPopup isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </Section>
  );
}

const CenterMobileGeneralPageFeedbacks = () => {
  return OtherPage();
};

export default CenterMobileGeneralPageFeedbacks;
