import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/microphone5s.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfMicrophone5s = [
  {
    id: 1,
    title: "Замена микрофона вашего iPhone 5s в нашей мастерской",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если такое произошло, ваша основная и наиглавнейшая задача – это поиск
          достойного исполнителя, способного успешно решить создавшуюся
          проблему. Если ваш Айфон пострадал – свои услуги по ремонту вам готов
          предоставить сервисный центр Мак Профи.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы успешно занимаемся ремонтом современной высокотехнологичной
          аппаратуры не первый год, специализируемся на выполнении таких задач,
          и имеем хорошую репутацию, которая говорит сама за себя. К нам
          обращаются и нас рекомендуют. Мастерская придерживается доступной
          ценовой политики, работает официально и с полной ответственностью,
          последовательно решает задачи любой сложности, и замена микрофона не
          станет для нас проблемой.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мастерская имеет современное оснащение, каждое рабочее место
          адаптировано для качественного и быстрого выполнения сложнейших задач.
          У нас всегда в наличии детали, на которые предоставляется отдельная
          гарантия – все они имеют заводское официальное происхождение, высокое
          качество. Кроме того, у нас работают только профессиональные
          исполнители, которые детально знают свою работу и выполняют ее на
          высочайшем уровне, это грамотные и компетентные люди. И потому
          обращение к нам даст вам сразу массу преимуществ.
        </p>
        <p className="repair-iphone-display__paragraph">
          Первым этапом проводится бесплатная диагностика, после которой можно с
          точностью судить о причинах поломки, узнать стоимость ремонта. Эта
          работа осуществляется за считанные минуты, и затем, после вашего
          согласия, начинается сам ремонт, который реализуется за считанные
          часы. При обращении к нам вы сможете уже вскоре пользоваться девайсом,
          работающим качественно и безотказно, и также вы получите все гарантии
          на долгий срок.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkMicrophoneModels5s = () => {
  return (
    <Page title="Замена микрофона iPhone 5s - сервис Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-mikrofona/5s"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Когда нужна замена микрофона iPhone 5s, наши мастера готовы взяться за работу. Сервис Oh!MyGadget! в Москве, быстро и выгодно, надежно и удобно."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена микрофона iPhone 5s"
          titleImg="Замена микрофона iPhone 5s"
          banner={banner}
          title="Замена микрофона iPhone 5s"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Ситуация, связанная с поломкой микрофона, вполне типична для
                Айфонов – с такими проблемами в мастерские обращаются довольно
                часто. Это неприятное явление, но к счастью, проблем с
                устранением этой неисправности не возникает, добиться этого в
                профессиональных условиях не сложно.
              </p>
              <p className="repair-iphone-display__paragraph">
                Для того, чтобы получить необходимый результат и обеспечить
                технике работоспособность, требуется замена микрофона iPhone 5s,
                и эта работа должна выполняться мастерами. Дилетантский подход
                часто приводит к возникновению новых проблем и еще большим
                сложностям при выполнении ремонта, и потому его стоит исключить.
                Если вы не желаете тратить новые средства на покупку Айфона
                взамен поврежденного окончательно, доверьте работу мастерам, и
                это потребует куда меньших вложений.
              </p>
            </>
          }
        />
        <RepairApplePrice
          text="Замена микрофона"
          price="1300 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Симптомами данной неисправности становится:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Полная невозможность собеседника слышать то, что вы
                    пытаетесь сказать,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Посторонние шумы при прослушивании вашего голоса,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Слишком тихий звук, когда регулировка не помогает поднять
                    этот показатель.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Как правило, поломки данного рода наступают после падения или
                ударов Айфона, из-за механических повреждений или попадания
                воды, влаги. Бывает и так, что микрофон выходит из строя из-за
                износа, выработки ресурса детали, или даже без видимых на то
                причин. Однако профессиональный подход позволяет добиться
                результата в любой ситуации, и вернуть технике
                работоспособность.
              </p>
              <p className="repair-iphone-display__paragraph">
                Существуют определенные меры профилактики, которые позволяют
                исключить эти и другие поломки устройства. Так, стоит аккуратно
                и бережно обращаться с подобным образцом высоких технологий, по
                максимуму исключать разного рода нелепые случайности. Также
                стоит использовать хорошие защитные чехлы, которые действительно
                могут обеспечить определенную протекцию. Но что же делать, если
                все это не помогло, и техника уже пострадала, создавая серьезные
                проблемы в ее эксплуатации?
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfMicrophone5s.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophoneModels5s;
