import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import asusBanner from "../../../images/banners/pad-asus-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "РЕМОНТ В СЕРВИСНОМ ЦЕНТРЕ И НА ДОМУ",
    text: "В зависимости от сложности поломки работы могут быть выполнены в сервисных условиях либо прямо на дому/ в офисе у заказчика.",
  },
  {
    img: icon2,
    title: "ОПЕРАТИВНОСТЬ РЕМОНТА",
    text: "Скорость восстановления работоспособности планшетов Асус обеспечивается опытом мастеров и наличием комплектующих для этой марки в большом ассортименте. Срочный ремонт производится прямо в присутствии заказчика.",
  },
  {
    img: icon3,
    title: "ДОСТУПНЫЕ ЦЕНЫ И ГАРАНТИЯ НА ВСЕ ВИДЫ РАБОТ",
    text: "Стоимость услуг и гарантийный срок зависят от вида выполненных работ и установленных в процессе ремонта комплектующих.",
  },
  {
    img: icon4,
    title: "ЗОНА БЕСПЛАТНОЙ ДОСТАВКИ",
    text: "Ваше местонахождение в пределах МКАД позволяет вам воспользоваться услугой бесплатной доставки планшета в сервис и обратно в рабочие часы.",
  },
];

const pads = [
  { name: "ZenPad 10 Z300CNL", pricelist: desireHD },
  { name: "Zenpad 3S 10", pricelist: desire400 },
  { name: "ZenPad 3 8.0", pricelist: desireHD },
  { name: "Transformer 3", pricelist: desire400 },
  { name: "ZenPad C 7.0 Z171KG", pricelist: desireHD },
  { name: "MeMO Pad 7 LTE", pricelist: desire400 },
  { name: "ZenPad C 7.0", pricelist: desireHD },
  { name: "ZenPad S 8.0", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadAsus() {
  return (
    <Page title="Ремонт планшетов Asus в Москве – сервисный центр Oh!MyGadget!">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-planshetov-asus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Oh!MyGadget! – сервисный центр, предоставляющий услуги по ремонту планшетов Asus в Москве. Все виды услуг по ремонту, обслуживанию, настройке. Квалифицированные специалисты, демократичные цены."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={asusBanner}
          alt="планшет Asus"
          title="планшет Asus"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ ASUS"
          text="Большой практический опыт и современное высокотехнологичное оборудование позволяют нам оказывать услуги по ремонту планшетов Асус на самом высоком уровне. Наши квалифицированные специалисты не понаслышке знакомы с тонкостями восстановления мобильных устройств, знают все нюансы и особенности таких работ. Именно поэтому наш ремонт планшетов Asus отличается качеством и невысокой стоимостью. При этом мы одинаково эффективно выполняем ремонт планшетов Asus Nexus 7, MeMO Pad и других, менее популярных моделей."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Asus"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Asus:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА СЕРВИСНОГО РЕМОНТА"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="РЕМОНТ ПЛАНШЕТОВ ASUS В МОСКВЕ"
          text="Если планшет дал сбой и нуждается в ремонте, доставить его к нам можно самостоятельно. Наш сервисный центр по ремонту планшетов Asus находится рядом со ст. метро Белорусская, схема проезда в разделе «Контакты». А можно вызвать нашего курьера, который отвезет ваш планшет в сервис и доставит его обратно после ремонта. В случае небольшой поломки существует возможность вызова мастера на дом. Получить более подробную информацию можно по многоканальному телефону +7 (495) 540-43-19."
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadAsus;
