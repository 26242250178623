import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertInsidePageInfo.module.css";
import triangle from "../../../images/icons/note-expert-blue-triangle.svg";

function NoteExpertInsidePageInfo({ title, infoTexts }) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.list}>
          {infoTexts.map((text, i) => {
            return (
              <li key={i} className={styles.item}>
                <img className={styles.icon} src={triangle} alt="иконка" />
                <p className={styles.text}>{text}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

export default NoteExpertInsidePageInfo;
