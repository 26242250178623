import React from 'react';
import { Link } from 'react-router-dom';
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import Section from '../Section/Section';

function RepairIphoneModelLink ({ heading, links }) {
  return (
      <Section className='repair-iphone__models-links-container'>
      <div className='repair-iphone__models-links-wrapper'>
        <h2 className='repair-iphone__models-links-title'>{heading}</h2>
        <ul className='repair-iphone__models-links-grid' style={{ gridTemplateAreas: 'col-1 col-2' }}>
          {links.map((link, i) => {
            return (
              <li key={i} className='repair-iphone__models-links-item'>
                <img src={IconCheckMark} alt="иконка галочки" className='repair-iphone__models-links-img' />
                <Link className='repair-iphone__models-links-link' to={link.href}>{link.model}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </Section>
  )
}

export default RepairIphoneModelLink;