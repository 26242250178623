import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadPro12.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Срочный ремонт – оперативно выполним замену стекла iPad Pro 12.9, ведь необходимые запчасти уже есть на нашем складе, и вам не придется тратить времени на ожидание поставки.",
    number: "01",
  },
  {
    id: 2,
    text: "Гарантия качества – вы получаете 100%, что восстановленный планшет порадует стабильной работой.",
    number: "02",
  },
  {
    id: 3,
    text: "Бесплатная диагностика – прежде чем приступать к ремонту, оценим состояние аппарата и выявим скрытые дефекты.",
    number: "03",
  },
  {
    id: 4,
    text: "Выгодные цены – наши расценки достаточно лояльны, благодаря чему профессиональный ремонт стал еще доступнее.",
    number: "04",
  },
];

const textOfDisplayMini4 = [
  {
    id: 1,
    title: "Замена стекла iPad Pro 12.9: важные особенности",
    text: "Чем больше диагональ, тем выше вероятность разбить дисплей, а потому владельцам iPad Pro 12.9 стоит соблюдать особую аккуратность. Падения, удары, нажатия – всё это противопоказано достаточно хрупкому устройству, которое легко может быть испорчено в результате подобного воздействия. При этом такая техника не рассчитана на самостоятельный ремонт из-за сложной конструкции – под корпусом практически не осталось свободного места из-за плотного расположения основных элементов системы. Пытаясь добраться до дисплейного модуля, можно легко задеть один из них, что приведет к поломке устройства. Не стоит рисковать ради сомнительной экономии, лучше изначально обратиться к профессионалам, которые успешно выполнят поставленную задачу и предоставят соответствующие гарантии.",
  },
  {
    id: 2,
    title: "4 причины доверить замену стекла iPad Pro 12.9 мастерам Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Почему в Москве стоит обратиться именно в Мак Профи? Потому что мы используем только качественные комплектующие, а для диагностики применяется новое современное оборудование. Более того, мы заботимся о каждом клиенте, а потому сотрудничая с нами, вы получаете целый ряд выгод:"
          array={listOfTrouble}
          paragraphDown="Все работы производятся только после полного согласования с клиентом. Завершив ремонт, мастер включит устройство и продемонстрирует его работу владельцу – и только оценив результат, заказчик оплачивает услуги. Приезжайте в сервисный центр Мак Профи, чтобы заменить стекло iPad Pro 12.9 – и мы позаботимся о быстром и качественном выполнении поставленной задачи."
        />
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelPro12 = () => {
  return (
    <Page title="Недорогая замена стекла (таскрина) iPad Pro 12.9 в Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/pro-12"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если потребовалась замена стекла iPad Pro 12.9, мастера сервисного центра Oh!MyGadget! выполнят такую работу без проблем. Быстрая и качественная работа гарантируется."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена стекла ipad pro 12.9"
          titleImg="замена стекла ipad pro 12.9"
          banner={banner}
          title={<>Замена стекла iPad&nbsp;Pro&nbsp;12.9</>}
          text="Pad Pro 12.9 – стильный и удобный планшет, который стал поистине статусной вещью. Он может использоваться и для развлечений, и для работы, а его возможности практически безграничны – они полностью удовлетворяют требованиям пользователя. Вот только разбитое стекло лишает владельца всех необходимых ему функций, что превращается в серьезную проблему. Устранить её вы сможете в сервисном центре Мак Профи – сотрудничайте с профессионалами, чтобы получить качественный ремонт по доступным ценам."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="12500 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplayMini4.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelPro12;
