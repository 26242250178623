import React from "react";
import { Carousel } from "react-responsive-carousel";
import Section from "../Section/Section";

const firstSlide = [
  {
    id: 1,
    number: "01",
    text: "Экономию материальных средств по аренде помещения для размещения технических специалистов",
  },
  {
    id: 2,
    number: "02",
    text: "Экономию материальных средств на зарплате технических специалистов",
  },
  {
    id: 3,
    number: "03",
    text: "Экономию материальных средств и времени на закупке комплектующих (налаженная нами система работы с поставщиками комплектующих гарантирует низкие цены на любые детали для ремонтируемой нами техники)",
  },
  {
    id: 4,
    number: "04",
    text: "Экономию материальных средств на отделе «Склад» (Для эффективной работы технического отдела все комплектующие должны быть в наличии, это требует больших материальных вложений, а также денежные средства постоянно заморожены в уже готовых заказах, но которые клиент еще не успел забрать)",
  },
];

const secondSlide = [
  {
    id: 1,
    number: "05",
    text: "Экономию материальных средств на покупке дорогостоящего оборудования для ремонта (даже лучшие специалисты неспособности производить качественный ремонт без надлежащей техники)",
  },
  {
    id: 2,
    number: "06",
    text: "Экономию времени и материальных средств на поиск квалифицированных сервисных специалистов",
  },
  {
    id: 3,
    number: "07",
    text: "Экономию времени на реализации взаимодействия отделов компании с техническим отделом, контролем его работы",
  },
  {
    id: 4,
    number: "08",
    text: "Экономия материальных средств и времени на обучении и оснащении технических специалистов",
  },
];

const CooperationWithUsMobile = () => {
  return (
    <Section>
      <div className="cooperation-wu-mobile__contant">
        <h2 className="cooperation-wu-mobile__title">
          Сотрудничая с нами вы получаете:
        </h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className="cooperation-wu-mobile__carousel-box">
            <ul className="cooperation-wu-mobile__carousel-list">
              {firstSlide.map((item) => {
                return (
                  <li key={item.id} className="cooperation-wu-mobile__item">
                    <p className="cooperation-wu-mobile__carousel-number">
                      {item.number}
                    </p>
                    <p className="cooperation-wu-mobile__carousel-text">
                      {item.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="cooperation-wu-mobile__carousel-box">
            <ul className="cooperation-wu-mobile__carousel-list">
              {secondSlide.map((item) => {
                return (
                  <li key={item.id} className="cooperation-wu-mobile__item">
                    <p className="cooperation-wu-mobile__carousel-number">
                      {item.number}
                    </p>
                    <p className="cooperation-wu-mobile__carousel-text">
                      {item.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default CooperationWithUsMobile;
