import react from "react";
import Section from "../Section/Section";

const aboutText = [
  {
    text: "Сервисный центр работает с 2006 года. Мы располагаем штатом сертифицированных специалистов, которые производят ремонт любой степени сложности.",
    class: "mac-profi-mobile__border-paragraph",
  },
  {
    text: "Профессиональное оборудование позволяет производить точную диагностику и ремонт Вашей техники с высокой эффективностью.",
    class: "mac-profi-mobile__border-paragraph",
  },
  {
    text: "Наш сервисный центр удобно расположен в центре Москвы, что позволяет нашим клиентам в кратчайшие сроки добраться в сервисный центр.",
    class: "",
  },
];

const MacProfiAboutUsMobile = () => {
  return (
    <Section>
      <div className="mac-profi-mobile__au-container">
        <h2 className="mac-profi-mobile__au-title">о нас</h2>
        <div className="mac-profi-mobile__au-border"></div>
        <ul className="mac-profi-mobile__au-list">
          {aboutText.map((paragraph, i) => {
            return (
              <li key={i} className="mac-profi-mobile__au-item">
                <p className="mac-profi-mobile__au-text">{paragraph.text}</p>
                <div className={paragraph.class}></div>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default MacProfiAboutUsMobile;
