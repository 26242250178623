import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/display6splus.png";
import { listOfModelforDisplay } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfDisplay6sPlus = [
  {
    id: 1,
    title:
      "Что включает в себя замена дисплея Айфон 6s Plus в сервисном центре",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Стоимость замены дисплея на iPhone 6s Plus рассчитывается на основании
          нескольких факторов, среди которых:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Диагностика – мастера всесторонне изучат устройство, выявив любые
              поломки.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Запчасти – мы поддерживаем достаточное количество комплектующих
              элементов в наличии, работая только с проверенными поставщиками,
              зарекомендовавшими себя исключительно с лучшей стороны.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Работа мастера – ремонтом занимаются исключительно профессионалы,
              услуги которых просто не могут стоить дешево. Зато вы платите за
              качество, и получаете необходимые гарантии.
            </p>
          </li>
        </ul>
      </>
    ),
  },
];

const textOfDisplay6sPlusDown = [
  {
    id: 1,
    title: "Когда нужна замена дисплея Айфон 6s Plus",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Можно выделить множество причин повреждения устройства, из-за которых
          работоспособность смартфона будет существенно нарушена. А именно:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Падения. Обещанный небьющийся экран неплохо выдерживает негативные
              воздействия, но и его могут покрыть сетки трещин. Более того,
              из-за сильного удара могут образоваться и другие повреждения,
              которые хоть и не видны невооруженным глазом, но все-таки
              отразятся на функциональности iPhone.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Сжатие. Устройство легко придавить, согнуть или же и вовсе сесть
              на него. Это приводит к возникновению внешних и внутренних
              дефектов. Именно поэтому ремонту в обязательном порядке
              предшествует диагностика, которая в нашем сервисном центре
              проводится бесплатно.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Влага. Даже несколько капель жидкости могут превратиться в
              серьезнейшую проблему, решение которой требует значительных
              финансовых затрат. Если на устройство попала влага, нужно сразу же
              выключить его и обратиться к мастерам.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          {" "}
          Дети и домашние любимцы очень часто выводят из строя дорогостоящую
          технику, а потому стоит постараться держать смартфоны как можно дальше
          от них. Если же это не удалось, то вы сможете исправить возникшую
          проблему в нашем сервисном центре.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkDisplayModels6sPlus = () => {
  return (
    <Page title="Замена дисплея iPhone 6s Plus срочно за 30 минут в сервисном центре в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-displeja/6s-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена дисплея iPhone 6s Plus в сервисном центре Oh!MyGadget! в Москве. Если Вам нужна замена дисплея Айфон 6s Plus, мы с радостью Вам поможем, выполним работу быстро и качественно!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена дисплея iPhone 6s Plus"
          titleImg="Замена дисплея iPhone 6s Plus"
          banner={banner}
          title="Замена дисплея iPhone 6s Plus"
          text="Замена дисплея iPhone 6s Plus – одна из самых востребованных услуг, предоставляемых нашим центром. Мастерам ежедневно приходится ремонтировать поврежденные устройства, удаляя разбитые части и заменяя их новыми комплектующими. Сэкономьте вместе с нами, воспользовавшись действительно выгодными условиями – лояльная цена станет приятным бонусом для каждого клиента."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfDisplay6sPlus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Стоимость замены дисплея на Айфон 6s Plus:"
          text="Замена дисплея"
          price="3700 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Стоимость указана в рублях РФ, включает комплектующее и работу
                по установке, стоимость окончательная.
              </p>
              <p className="repair-iphone-display__paragraph">
                Помимо этого, в обязательном порядке проводится профилактика
                смартфона, что позволит значительно продлить срок службы
                устройства.
              </p>
              <p className="repair-iphone-display__paragraph">
                Свяжитесь с нами, если вас интересует замена дисплея Айфон 6s
                Plus – все работы будут выполнены в кратчайшие сроки, и при этом
                цена предоставляемых услуг неизменно выгодна и доступна.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplay6sPlusDown.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelforDisplay} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkDisplayModels6sPlus;
