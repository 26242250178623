import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import digmaBanner from "../../../images/banners/pad-digma-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import { desireHD, desire400 } from "../../../utils/CenterMobileHTCPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "ГАРАНТИИ ОТ ПРОФЕССИОНАЛОВ",
    text: "На все виды работ и установленные детали «Центр Мобайл» предоставляет гарантию, срок которой зависит от типа оказанной услуги и используемых комплектующих.",
  },
  {
    img: icon2,
    title: "КУРЬЕР И БЕСПЛАТНАЯ ДОСТАВКА",
    text: "При ограничении во времени можно воспользоваться услугами нашего курьера, который приедет по указанному адресу в удобное для вас время, оформит заказ и доставит неработающий планшет в офис. При нахождении в зоне бесплатной доставки данная услуга будет оказана на бесплатной основе.",
  },
  {
    img: icon3,
    title: "СРОЧНЫЙ РЕМОНТ ПЛАНШЕТА ДИГМА",
    text: "Если ваш планшет Digma не работает и нуждается в срочном восстановлении, наши мастера приложат максимум усилий, чтобы вернуть работоспособность гаджета в самые короткие сроки, обычно это происходит в присутствии владельца.",
  },
  {
    img: icon4,
    title: "НАЛИЧИЕ КОМПЛЕКТУЮЩИХ",
    text: "Большинство комплектующих для планшетов Дигма имеется у нас в наличии, что обеспечивает самые оптимальные сроки выполнения ремонтных работ.",
  },
];

const pads = [
  { name: "CITI Octa 80", pricelist: desireHD },
  { name: "Plane 8548S 3G", pricelist: desire400 },
  { name: "Plane 7574S 4G", pricelist: desireHD },
  { name: "Plane 1523 3G", pricelist: desire400 },
  { name: "Plane 8540E 4G", pricelist: desireHD },
  { name: "CITI 8542 4G", pricelist: desire400 },
  { name: "CITI 1804", pricelist: desireHD },
  { name: "7100R 3G", pricelist: desire400 },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairPadDigma() {
  return (
    <Page title="Сервисный ремонт планшетов Digma в Oh!MyGadget! (Москва), недорого">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-planshetov-digma"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Специалисты Oh!MyGadget! оказывают квалифицированные услуги по ремонту и обслуживанию планшетов Digma в Москве. Профессиональный сервис по доступным ценам, с предоставлением гарантий."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={digmaBanner}
          alt="ремонт планшетов digma"
          title="ремонт планшетов digma"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ ПЛАНШЕТОВ DIGMA"
          text="На протяжении нескольких лет наш специализированный центр выполняет ремонт планшетов Digma. Благодаря техническому оснащению, квалификации и практическому опыту мастеров, «Центр Мобайл» обеспечивает высокий уровень сервиса и качества предоставляемых услуг. Лучшим свидетельством нашей надежности являются многочисленные отзывы клиентов, положительно оценивающих нашу работу и ее результаты."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт Digma"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            Digma:"
          mobiles={pads}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ПРЕИМУЩЕСТВА НАШЕЙ КОМПАНИИ"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ВОСПОЛЬЗОВАТЬСЯ УСЛУГОЙ ?"
          text="Доставить планшет к нам можно самостоятельно, воспользовавшись удобной схемой проезда http://centre-mobile.ru/kontakty/. А при нехватке времени можно вызвать курьера или даже мастера на дом (при небольшой поломке ремонт может быть выполнен на территории заказчика). Специалисты «Центр Мобайл» всегда идут навстречу клиенту!"
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairPadDigma;
