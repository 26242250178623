import React, { useState, useEffect, Children, cloneElement } from "react";
import arrowLeft from "../../../images/arrows/CenterMobile-arrowLeft.svg";
import arrowRight from "../../../images/arrows/CenterMobile-arrowRight.svg";
import { ITEM_WIDTH } from "../../../utils/constants";

const CenterMobileFeedbacksSlider = ({ children, feedbacks }) => {
  const [offset, setOffset] = useState(0); /* стейт для сдвига элемента */

  const handleLeftArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + ITEM_WIDTH;

      return Math.min(newOffset, 0);
    });
  };

  const handleRightArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - ITEM_WIDTH;

      const maxOffset = -(ITEM_WIDTH * (children.length - 1));

      return Math.max(newOffset, maxOffset);
    });
  };

  return (
    <div className="center-mobile-general-page-slider">
      {offset === 0 ? (
        <img
          className="slider__arrow"
          src={arrowLeft}
          alt="слайдер влево"
          style={{ opacity: 0.5 }}
          onClick={handleLeftArrowClick}
        />
      ) : (
        <img
          className="slider__arrow"
          src={arrowLeft}
          alt="слайдер влево"
          onClick={handleLeftArrowClick}
        />
      )}
      <div className="center-mobile-general-page-slider__wrapper">
        <ul
          className="center-mobile__general-page-feedbacks-list"
          style={{
            transform: `translateX(${offset}px)`,
          }}
        >
          {children}
        </ul>
      </div>
      {-(ITEM_WIDTH * feedbacks.length - ITEM_WIDTH) === offset ? (
        <img
          className="slider__arrow"
          src={arrowRight}
          alt="слайдер вправо"
          style={{ opacity: 0.5 }}
          onClick={handleRightArrowClick}
        />
      ) : (
        <img
          className="slider__arrow"
          src={arrowRight}
          alt="слайдер вправо"
          onClick={handleRightArrowClick}
        />
      )}
    </div>
  );
};

export default CenterMobileFeedbacksSlider;
