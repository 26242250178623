import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera6.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCamera6 = [
  {
    id: 1,
    title: "Замена камеры iPhone 6: основные причины",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Несмотря на то, что устройство заслужило популярность благодаря
          надежности и длительному периоду эксплуатации, можно выделить ряд
          факторов, которые приводят к необходимости замены камеры iPhone 6:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Удар. При падении или сильном механическом воздействии, основную
              камеру не защитит даже усиленное сапфировое стекло, а уж
              фронтальная еще более уязвима. Нарушить нормальную работу камеры
              может и деформация корпуса, что тоже важно учитывать.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Влага. Не стоит брать телефон мокрыми руками, а попав под дождь,
              стоит позаботиться о его защите. Но все равно от проникновения
              жидкости под корпус никто не застрахован – если это произойдет,
              нужно поторопиться в сервис, чтобы не были испорчены другие
              компоненты устройства.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Установка вредоносного ПО. Наши специалисты проведут диагностику.
              Если проблема действительно заключается в использовании
              некачественного софта – есть шанс исправить неисправность, не
              меняя камеру, а лишь настроив смартфон.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Невозможность включить камеру или же вспышку, потеря нормальной
          фокусировки, мутные снимки – приезжайте к нам, и эти проблемы будут
          решены. Мастер разберет устройство, бережно удалит старую камеру и
          установит новую. При необходимости будет проведена рихтовка или же
          полная замена корпуса.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Особенности замены камеры Айфон 6",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Не стоит пытаться выполнить ремонт самостоятельно – это сложная
          работа, требующая особой аккуратности. Нужно правильно отключить
          основные шлейфы, бережно отсоединяя защитные пластины. Чтобы добраться
          до камеры, нужно практически полностью разобрать устройство, и при
          этом не нарушить работу иных элементов.
        </p>
        <p className="repair-iphone-display__paragraph">
          Базовая стоимость услуги указана в соответствующем разделе. Если в
          процессе диагностики не будут выявлены дополнительные повреждения, то
          сумма останется неизменной – она уже включает в себя цену
          комплектующих.
        </p>
        <p className="repair-iphone-display__paragraph">
          Приезжайте в Мак Профи, чтобы выполнить замену камеры iPhone 6 – у нас
          вас ждет отменный сервис, индивидуальная работа с каждым клиентом и
          лояльные расценки на все услуги. Мы сделали профессиональный ремонт
          доступным – только лучшие условия и гарантированное качество
          выполнения работы.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels6 = () => {
  return (
    <Page title="Качественная замена камеры iPhone 6, цены на замену камеры Айфон 6">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/6"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена камеры iPhone 6 в сервисе Oh!MyGadget!: срочно, недорого, с гарантией"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone 6"
          titleImg="Замена камеры iPhone 6"
          banner={banner}
          title="Замена камеры iPhone 6"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 6 в Москве. Быстрый ремонт основной и
                фронтальной камеры. Гарантия качества. Индивидуальный подход.
                Доступные расценки.
              </p>
              <p className="repair-iphone-display__paragraph">
                iPhone 6 оснащен сразу двумя отличными камерами, каждая из
                которых обеспечивает отличное качество снимков. Вот только, как
                и другие элементы устройства, они могут выйти из строя – ремонт
                в этом случае нецелесообразен, а потому потребуется заменить
                неисправный элемент. Вы сможете воспользоваться этой услугой в
                московском сервисе Мак Профи – и мы успешно решим проблему всего
                за 30 минут.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="1700 P"
          priceDown="1600 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera6.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels6;
