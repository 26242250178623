import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera7.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCamera7 = [
  {
    id: 1,
    title: "Замена камеры iPhone 7: основные «симптомы» поломки",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Причин возникновения неисправности довольно много, от поломки
          светодиода до отказа всего модуля, до повреждения стекла камеры на
          задней крышке. Как правило, нарушения работы проявляются следующими
          признаками:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Перестала работать вспышка
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Не удается сделать снимок – приложение не отвечает
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Камера находится в режиме ожидания
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Фотографии утратили резкость и четкость.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Мы проведем бесплатную диагностику – в некоторых случаях удается
          решить проблему без замены модуля камеры. Есть шанс, что неисправность
          возникла из-за системного сбоя, а потому наши мастера проведут
          настройку устройства и устранят поломку при минимальных финансовых
          затратах.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title:
      "Замена камеры iPhone 7: комплексная диагностика и профессиональный ремонт",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если же все-таки потребуется установка новой камеры, то для этого
          необходимо разобрать устройство, достать поврежденный элемент, после
          чего собрать все детали в обратном порядке. Важно делать это
          исключительно в специализированном центре – и на это есть целый ряд
          причин:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Квалифицированный мастер качественно выполнит поставленную задачу
              – замена камеры iPhone 7 не станет причиной выхода из строя других
              элементов конструкции. Оплата за ремонт производится только после
              проверки устройства, а потому владелец сможет убедиться в том, что
              Айфон нормально функционирует.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              В Мак Профи есть фирменные инструменты и оборудование,
              применяющееся для тестирования устройства – все процедуры
              проводятся очень аккуратно, чтобы в результате ремонта на корпусе
              или же экране не появились царапины и другие дефекты.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Все комплектующие уже в наличии – мы регулярно пополняем запасы,
              чтобы иметь возможность предложить посетителям не только
              профессиональный, но и быстрый ремонт устройства.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Фотографии утратили резкость и четкость.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Приезжайте в сервисный центр, чтобы получить подробную консультацию и
          квалифицированную помощь. Основную часть поломок мы устраняем
          непосредственно в день обращения, и замена камеры не станет
          исключением.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels7 = () => {
  return (
    <Page title="Цены на замену камеры iPhone 7 в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/7"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена камеры iPhone 7 в Oh!MyGadget!: срочный ремонт по фиксированной цене"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone 7"
          titleImg="Замена камеры iPhone 7"
          banner={banner}
          title="Замена камеры iPhone 7"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры в Москве без дополнительных затрат и с гарантией
                качества: Мак Профи – сервис, которому действительно доверяют.
                Гарантия на все услуги и детали.
              </p>
              <p className="repair-iphone-display__paragraph">
                Разработчики Айфон 7 позаботились о том, чтобы сделать новую
                модель действительно удобной для пользователя. Более того,
                предусмотрена дополнительная защита корпуса от водных брызг, что
                позволило значительно продлить период эксплуатации устройства и
                сократить количество обращений в сервис. Но даже в этой модели
                могут возникнуть определенные неисправности, а в результате
                потребуется замена камеры iPhone 7 – доверьте нам эту работу, и
                вы получите качественный и быстрый ремонт, который будет
                выполнен в течение 20-30 минут.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="3000 P"
          priceDown="2900 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera7.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels7;
