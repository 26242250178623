import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "../NoteExpertReasonsLinks/NoteExpertInsidePage.module.css";
import { Helmet } from "react-helmet";

const callbackText = [
  "В московском сервисном центре Note Expert операции по замене и установке оперативной памяти в ноутбук производят на высокопрофессиональном уровне, с использованием оригинальных комплектующих.",
  "В распоряжении экспертов нашего центра находится широкий ассортимент комплектующих для ноутбуков, включая блоки оперативной памяти, ОЗУ любых объемов.",
  "Мы поможем вам подобрать подходящую для вашего ноутбука модель с учетом ее характеристик, а затем качественно выполним поэтапную установку в устройство с использованием профессиональных инструментов.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Установить в ноутбук новый модуль оперативной памяти его владелец может самостоятельно, но только настоящие профессионалы знают, как увеличить ОЗУ на ноутбуке быстро, качественно и с ожидаемым результатом. Очень важно при выборе оперативной памяти учесть не только ее тип и объем (в пределах поддерживаемого конкретной моделью ноутбука), но и количество слотов для модулей памяти, параметры частоты работы микросхем.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Это можно сделать только после проведения диагностики аппаратной части ноутбука. А в процессе установки, которая потребует от мастера полной или частичной (в зависимости от марки и модели) разборки ноутбука, учесть, что модули памяти крайне чувствительны к статическому электричеству.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Увеличение оперативной памяти – самый распространенный вид модернизации ноутбуков, который заказывают специалистам сервисного центра Note Expert, поскольку является не только очень эффективным, но и самым доступным.",
  },
  {
    icon: priceIcon2,
    text: "Он позволяет за сравнительно небольшие деньги значительно повысить производительность и быстродействие своего портативного компьютерного устройства (скорость загрузки операционной системы, программного обеспечения).",
  },
  {
    icon: priceIcon3,
    text: "Итоговая стоимость работ определяется по результатам диагностики, которая выполняется бесплатно.",
  },
];

const prices = [
  {
    text: "Диагностика ОЗУ ноутбука",
    price: "БЕСПЛАТНО",
  },
  {
    text: "Модуль 1 GB",
    price: "от 800 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Модуль 2 GB",
    price: "от 1200 P",
  },
  {
    text: "Модуль 4 GB",
    price: "от 2200 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Модуль  GB",
    price: "от 3300 P",
  },
  {
    text: "Модуль 16 GB",
    price: "от 5400 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Замена оперативной памяти ноутбука обычно производится при необходимости повысить производительность системы либо в случае неисправности этого модуля.",
  "Поэтому вам точно стоит заменить оперативную память на своем ноутбуке, если она вышла из строя, и заменить или добавить, если ноутбук стал медленно работать, зависать, долго обрабатывать информацию и т.д.",
];

function NoteExpertGeneralPageServiceLinkMemory() {
  return (
    <Page title="Замена оперативной памяти в ноутбуке от 800 рублей">
      <main className={styles.main}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/note-expert/ustanovka-ozu"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Профессиональные услуги по замене, увеличению оперативной памяти в ноутбуках всех марок от экспертов московского сервиса Oh!MyGadget!. Широкий спектр услуг по приемлемым ценам. Качественный сервис."
          />
        </Helmet>
        <NoteExpertInsidePageBanner
          banner={banner}
          alt="увеличение озу, установка"
          title="увеличение озу, установка"
        />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Увеличение ОЗУ в Note Expert"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Профессиональная замена оперативной памяти"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Стоимость услуг по замене и увеличению оперативной памяти"
          subtitle="Стоимость оперативной памяти"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="В каких случаях ноутбуку нужна замена ОЗУ"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertGeneralPageServiceLinkMemory;
