import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadMini4.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Экран перестает реагировать на прикосновения",
    number: "01",
  },
  {
    id: 2,
    text: "Изображение частично отсутствует",
    number: "02",
  },
  {
    id: 3,
    text: "Неожиданно выключается дисплей",
    number: "03",
  },
  {
    id: 4,
    text: "Устройство некорректно работает",
    number: "04",
  },
  {
    id: 5,
    text: "На поверхности экрана появились разноцветные полосы.",
    number: "05",
  },
];

const textOfDisplayMini4 = [
  {
    id: 1,
    title: "Срочная замена стекла iPad Mini 4 в Москве",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Чаще всего о необходимости замены стекла свидетельствуют явные повреждения, возникшие на поверхности устройства. Но в некоторых случаях проблемы, возникшие после падения, проявляются спустя короткое время, и они выражаются следующим образом:"
          array={listOfTrouble}
          paragraphDown="Иногда достаточно даже легкого удара, чтобы функциональность устройства была нарушена. Помимо дефектов на экране могут появиться и другие сбои в работе планшета, а потому мы обязательно проведем комплексную диагностику. Результаты тестирования будут озвучены владельцу, на основании чего и будет рассчитана стоимость замены стекла iPad Mini 4. В сервисном центре Мак Профи действуют честные и лояльные расценки, а оплата производится только после проведения ремонта и финального тестирования устройства."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Можно ли заменить стекло iPad Mini 4 самостоятельно",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Своими руками устранять поломки не стоит, даже если речь идет о менее
          дорогостоящей технике. Вероятность успешного восстановления устройства
          гораздо ниже, чем риск повредить важные элементы конструкции – проще
          все-таки изначально обратиться к квалифицированным специалистам.
        </p>
        <p className="repair-iphone-display__paragraph">
          Это связано с тем, что серия iPad Mini отличается небольшим весом и
          минимальной толщиной корпуса. Чтобы снять экран, удалить остатки клея
          и установить целую поверхность, потребуется выполнить практически
          ювелирную работу. Более того, для этого потребуются специальные
          инструменты и оборудования, что тоже немаловажно. Прежде чем
          приниматься за эту задачу своими силами, стоит оценить, насколько
          целесообразна подобная экономия. Вы можете потратить гораздо больше,
          одним неловким движением руки повредив шлейф, один из разъемов или
          плату.
        </p>
        <p className="repair-iphone-display__paragraph">
          Чтобы узнать стоимость и сроки замены стекла iPad Mini 4 – обращайтесь
          в сервисный центр Мак Профи. 7 дней в неделю без выходных и праздников
          мы работаем для вас – даже самые сложные поломки будут устранены в
          срочном порядке.
        </p>
      </>
    ),
  },
];

const RepairIpadServiceLinkDisplayModelMini4 = () => {
  return (
    <Page title="Профессиональная замена стекла iPad Mini 4 за 1 час">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/mini-4"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена стекла iPad Mini 4 не создаст проблем, если вы обратитесь к хорошим специалистам. Сервис Oh!MyGadget! выполняет такие работы на высочайшем уровне и с гарантией."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена стекла ipad mini 4"
          titleImg="замена стекла ipad mini 4"
          banner={banner}
          title={<>Замена стекла iPad&nbsp;Mini&nbsp;4</>}
          text="В большинстве случаев замена стекла iPad Mini 4 требуется по вине владельцев. При активном использовании нередко на поверхности возникают трещины или другие дефекты, да и малыши легко могут уронить или испортить дорогостоящую технику. В сервисном центре Мак Профи мастера установят новый экран, и нормальная работа планшета будет восстановлена."
        />
        <RepairApplePrice
          text="Замена сенсорного стекла (тачскрина)"
          price="8800 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfDisplayMini4.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelMini4;
