export const iPodListOfModels = [
  {
    model: 'Ремонт iPod Touch 1G, 2G, 3G',
    href: '/remont-apple/remont-ipod/1G-2G-3G'
  },
  {
    model: 'Ремонт Touch Nano 5G',
    href: '/remont-apple/remont-ipod/5g'
  },
  {
    model: 'Ремонт iPod Nano 6G',
    href: '/remont-apple/remont-ipod/nano-6g'
  },
  {
    model: 'Ремонт iPod Nano 7G',
    href: '/remont-apple/remont-ipod/nano-7g'
  },
  {
    model: 'Ремонт iPod Classic',
    href: '/remont-apple/remont-ipod/classic'
  },
  {
    model: 'Ремонт iPod Mini Series 1',
    href: '/remont-apple/remont-ipod/classic'
  },
  {
    model: 'Ремонт iPod Mini Series 2',
    href: '/remont-apple/remont-ipod/classic'
  },
  {
    model: 'Ремонт iPod Shuffle Series 1',
    href: '/remont-apple/remont-ipod/classic'
  },
  {
    model: 'Ремонт iPod Shuffle Series 2',
    href: '/remont-apple/remont-ipod/classic'
  },
  {
    model: 'Ремонт iPod Shuffle Series 3',
    href: '/remont-apple/remont-ipod/classic'
  },
  {
    model: 'Ремонт iPod Shuffle Series 4',
    href: '/remont-apple/remont-ipod/classic'
  },
]