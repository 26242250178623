import React from "react";
import {
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
} from "../index";

const text = [
  {
    id: 1,
    text: "Для того, чтобы обеспечить возможность ремонта современной высокотехнологичной техники, необходимо использовать особое оборудование, ведь пайка и другие манипуляции могут иметь буквально ювелирную сложность. В нашей мастерской все рабочие места оборудованы профессиональным образом, что снимает риски попадания пыли и прочие нежелательные возможности, и позволяет специалистам уверенно и производительно работать.",
    number: "01",
  },
  {
    id: 2,
    text: "Выполнением задач занимаются только профессионалы с опытом и профильным образованием – они знают об Айпадах все, и потому способны выполнить ремонт любой сложности, даже в той ситуации, когда другие компании предпочитают отказывать.",
    number: "02",
  },
  {
    id: 3,
    text: "Запчасти и детали есть в наличии всегда – высококачественные, официального заводского производства, они предоставляются нами с отдельной гарантией и по выгодной цене. Мы берем на себя полную ответственность за качество деталей и работы, и устраняем поломки полноценно, так, чтобы в дальнейшем они уже никогда вас не беспокоили.",
    number: "03",
  },
];

const textWithCkeckMark = [
  {
    id: 1,
    title: "Что случилось с устройством, почему необходим ремонт Айпад",
    text: (
      <RepairAppleTextWithCheckMark
        paragraphUp={
          "Ситуации, при которых необходимым становится ремонт Айпад, могут носить разный характер. Привести к поломкам может попадание воды – даже немедленное выключение и просушивание девайса не всегда исключает повреждения в такой ситуации, хотя поступать стоит именно таким образом."
        }
        paragraphUp2={
          "Падение или удар, неаккуратное обращение и нелепая случайность – все это может привести к поломке. Также к такому результату приводит банальный износ, связанный с долгой и активной эксплуатацией устройства."
        }
        paragraphUp3={
          "Но ремонт iPad в Москве возможен в любом случае, и задачей владельца гаджета при его поломке является в первую очередь поиск грамотного и компетентного исполнителя, который сможет выполнить работы наилучшим образом, с предоставлением всех гарантий на долгий срок. Наш сервисный центр уже много лет работает на высоком уровне, имеет прекрасную репутацию, и мы каждый день подтверждаем ее честной и грамотной работой."
        }
        paragraphUp4={"Наши преимущества"}
        array={text}
      />
    ),
  },
];

const RepairIpadAboutUs = () => {
  return (
    <Section className="section_group" style={{ padding: "55px 0 65px" }}>
      {textWithCkeckMark.map((item) => {
        return (
          <RepairAppleLinksText
            key={item.id}
            title={item.title}
            text={item.text}
          />
        );
      })}
    </Section>
  );
};

export default RepairIpadAboutUs;
