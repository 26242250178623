import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";
import { Helmet } from "react-helmet";

const callbackText = [
  "Повреждение клавиатуры ноутбука может быть спровоцировано самыми разнообразными причинами и иметь разные степени тяжести, в зависимости от которых выбирается способ устранения проблемы.",
  "Специалисты Note Expert после проведения диагностики могут предложить владельцам этих устройств чистку клавиатуры от пыли и после попадания влаги/ жидкости, ее ремонт (с заменой поврежденных клавиш, клавиатурного контроллера, touchpad, восстановлением токопроводящих дорожек).",
  "Или полную замену на оригинальную или аналогичную модель, которая осуществляется на ноутбуках всех марок и производится в тех случаях, когда ремонт технически невозможен или экономически нецелесообразен.",
];

const advantages = [
  {
    title: "ВЫСОКОТОЧНАЯ ДИАГНОСТИКА",
    text: "При восстановлении работоспособности этой важной части ноутбука обычно требуется его частичная или полная разборка, а также тестирование в рабочем режиме по окончании работ.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: " Во многих случаях помогает профилактическая чистка или замена кнопок на клавиатуре ноутбука, более серьезные случаи могут потребовать восстановления дорожек, замены клавиатурного контроллера, touchpad и всей клавиатуры полностью. В этом случае главное правильно подобрать устройство, подходящее к конкретной модели ноутбука, иначе может потребоваться замена разъемов, креплений, другие специфические работы, которые лучше всего доверить специалистам.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "В своем прайс-листе мы приводим цены на услуги по ремонту и замене клавиатур/ touchpad ноутбуков с учетом стоимости комплектующих.",
  },
  {
    icon: priceIcon2,
    text: "Вы можете видеть, что даже стоимость замены клавиатуры на ноутбуке в нашем центре совершенно доступна и позволяет пользователям заказывать эту услугу без каких-либо финансовых затруднений.",
  },
  {
    icon: priceIcon3,
    text: "Мы предлагаем все виды ремонтных работ для ее качественного восстановления по самым оптимальным ценам, итоговая стоимость определяется после проведения диагностики, которая осуществляется бесплатно.",
  },
];

const prices = [
  {
    text: "Диагностика клавиатуры, touchpad",
    price: "Бесплатно",
  },
  {
    text: "Замена клавиатуры",
    price: "от 1000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Замена кнопок",
    price: "от 200 P",
  },
  {
    text: "Ремонт/замена touchpad",
    price: "от 1200 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Необходимость в ремонте либо замене на ноутбуке клавиатуры и touchpad чаще всего вызывают физический износ, повреждения кнопок при эксплуатации, механические повреждения при ударах или падении, попадание внутрь пыли, мусора, жидкости.",
  "Если повреждения небольшие (например, не функционируют отдельные клавиши), целесообразнее будет выполнить ремонт, в противном случае рекомендуем полную замену этих комплектующих.",
];

function NoteExpertNoteSettingKeyboard() {
  return (
    <Page title="Замена тачпад или клавиатуры ноутбука от 200 рублей">
      <main className={styles.main}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/note-expert/zamena-klaviaturi"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Качественный и недорогой ремонт клавиатур на ноутбуках всех марок в сервисном центре Oh!MyGadget!. Оперативная замена клавиатуры на оригинальную (аналогичную). Цены, условия оказания услуг по восстановлению клавиатур."
          />
        </Helmet>
        <NoteExpertInsidePageBanner
          banner={banner}
          alt="замена клавиатуры"
          title="замена клавиатуры"
        />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Ремонт и замена клавиатуры ноутбука"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Особенности восстановления клавиатуры"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Сколько стоит ремонт/ замена клавиатуры на ноутбуке"
          subtitle="Стоимость ремонта устройств ввода"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="В каких случаях необходим ремонт клавиатуры"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingKeyboard;
