import { Navigate } from "react-router-dom";
import { LoginForm } from "../../components/index";
import { useUser } from "../../providers/UserProvider";

const Admin = () => {
  const { user } = useUser();

  if (user) {
    return <Navigate to="/admin-page" />;
  }

  return (
    <main className="admin">
      <div className="admin__container">
        <LoginForm
          formTitle="Вход в панель админа"
          className="admin__form"
          formClassName="admin__fieldset"
        />
      </div>
    </main>
  );
};

export default Admin;
