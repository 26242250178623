import React from "react";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
} from "../index";
import { Link } from "react-router-dom";
import banner from "../../images/banners/mb-keyboard.jpeg";
import { Helmet } from "react-helmet";

const listOfKeyboard = [
  {
    id: 1,
    number: "01",
    subtitle: (
      <>
        Залили клавиатуру MacBook жидкостью (подробнее по залитым макбук{" "}
        <Link
          to="/remont-apple/remont-macbook/zalil_macbook"
          className="repair-mb__group-links-array"
        >
          тут
        </Link>
        ).
      </>
    ),
    textLi:
      "В данном случае клавиатура MacBook подлежит замене. Ввиду того, что клавиатура на MacBook не разборная, чистку клавиатуры произвести невозможно. В некоторых моделях MacBook Pro, MacBook Air клавиатура меняется с подсветкой в комплекте.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Оторвалась клавиша на клавиатуре MacBook.",
    textLi:
      "В данном случае, при условии целостности крепления клавиши, меняется только клавиша на клавиатуре. Если сломан разъем клавиши, то придется менять всю клавиатуру целиком, ремонт крепления клавиши произвести невозможно.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Не работают клавиши на клавиатуре MacBook.",
    textLi:
      "Как правило, данная проблема вызвана механическим повреждением клавиатуры, либо следствие залития клавиатуры жидкостью. Причиной может быть как сама вышедшая из строя клавиатура, так и контроллеры и цепи питания на материнской плате.",
  },
  {
    id: 4,
    number: "04",
    subtitle: "Хрустят клавиши на клавиатуре MacBook.",
    textLi:
      "Следствие пролитой жидкости. Клавиатура функционирует, но клавиши хрустят. В данном случае мы рекомендуем менять клавиатуру на новую ввиду того, что со временем цепи питания на клавиатуре начинают прогнивать, что может привести к короткому замыканию и выходу из строя всего MacBook.",
  },
];

const textOfKeyboard = [
  {
    id: 1,
    title: <>Как производится замена клавиатуры MacBook,&nbsp;Pro, Air</>,
    text: (
      <>
        После выявления неисправной клавиатуры техническими специалистами,
        менеджеры компании озвучивают полную стоимость новой клавиатуры и
        стоимости работы по замене клиенту. Стоимость работы по замене
        варьируется от 2500 до 3500 в зависимости от модели MacBook плюс
        стоимость самой клавиатуры. Стоимость клавиатуры на MacBook меняется
        всегда в меньшую сторону, ввиду падения стоимости при выходе новых
        моделей MacBook. Точную стоимость клавиатуры вы можете уточнить у
        менеджеров по телефону{" "}
        <a href="tel: +74955405067" className="repair-mb__group-links-array">
          8(495)540-50-67
        </a>
        . После согласования замены клавиатуры с клиентом, технический
        специалист приступает к замене клавиатуры на новую. Срок замены
        клавиатуры от 2-х часов до 4-х часов в зависимости от модели вашего
        MacBook. После замены клавиатуры, менеджеры компании выдают макбук
        клиенту, клиент проверяет работоспособность клавиатуры и оплачивает
        полную стоимость работы и клавиатуры.
      </>
    ),
  },
  {
    id: 2,
    title: (
      <>
        Какую гарантию мы предоставляем на&nbsp;замену клавиатуры MacBook, Pro,
        Air
      </>
    ),
    text: "Гарантия на клавиатуру и работу предоставляется в течении 3-х – 6-и месяцев и зависит от модели вашего MacBook. Клиент получает чек и гарантийный талон в котором прописана полная стоимость услуги и комплектующих.",
  },
];

const RepairMacBookLinkKeyboard = () => {
  return (
    <Page title="Сломалась клавиатура MacBook? Ремонт и замена клавиатуры на MacBook, Pro, Air">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/zamena_remont_klaviatury_macbook_pro_air"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Быстро и качественно ремонтируем и меняем клавиатуру на MacBook. Предоставляем гарантию на клавиатуру и работу. В наличии клавиатуры на все модели MacBook."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="ремонт и замена клавиатуры на macbook"
          titleImg="ремонт и замена клавиатуры на macbook"
          banner={banner}
          title="Ремонт и замена клавиатуры на&nbsp;MacBook, Pro, Air"
          text="Производим ремонт и замену клавиатуры на MacBook всех моделей. В каких случаях требуется замена клавиатуры:"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfKeyboard.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {textOfKeyboard.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkKeyboard;

// toDo:
// импортировать банер из индекса
// перенести списки в константы
