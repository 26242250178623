import react from "react";
import IconCheck from "../../images/icons/cooper-offers_icon.svg";
import Logo from "../../images/logos/Omg_logo.svg";
import IconSet from "../../images/icons/cooper-offers_set.svg";
import { Section } from "../index";
import { useMedia } from "use-media";

const CooperationOffers = () => {
  const midleWide = useMedia({ minWidth: 1100 });

  return (
    <Section className="cooper-offers__content">
      <div className="cooper-offers__container">
        <div className="cooper-offers__box">
          <ul className="cooper-offers__wrapper">
            <h2 className="cooper-offers__title">Мы можем предложить</h2>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                Потоковую обработку большого количества как розничной, так и
                корпоративной техники.
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                Быструю аппаратную диагностику
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                BGA пайку любого уровня сложности
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">Ремонт залитых аппаратов</p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                Ремонт после механического повреждения
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                Ремонт техники в состоянии “не включается”
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                Наличие всех комплектующих для ремонта
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">
                Онлайн базу с полной информацией о статусе ремонта, четкой и
                подробной диагностикой вашей техники, полный список цен перед
                произведением ремонта
              </p>
            </li>
            <li className="cooper-offers__item">
              <img
                src={IconCheck}
                alt="иконка галочки"
                className="cooper-offers__icon"
              />
              <p className="cooper-offers__text">Отсрочку платежа</p>
            </li>
          </ul>
          {midleWide ? (
            <div className="cooper-offers__images">
              <img
                src={Logo}
                alt="логотип сайта"
                className="cooper-offers__logo"
              />
              <div className="cooper-offers__icon-set"></div>
              <div className="cooper-offers__icon-stick"></div>
              <div className="cooper-offers__icon-mobile"></div>
              <div className="cooper-offers__icon-comp"></div>
            </div>
          ) : null}
        </div>
        {/* <div className="cooper-offers__line"></div>
        <p className="cooper-offers__signature">
          Мы стараемся сделать нашу работу максимально комфортной для
          сотрудничества.
        </p>
        <p className="cooper-offers__signature">
          Мы производим ремонт по четко оговоренным ценам, оптимальная цена на
          комплектующие, минимальные сроки ремонта.{" "}
        </p> */}
      </div>
    </Section>
  );
};

export default CooperationOffers;

// toDO
// вынести в map и проверить верстку
