import React from "react";
import banner from "../../images/banners/mac-profi_banner.svg";
import Section from "../Section/Section";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import icon1 from "../../images/icons/mac-profi-icon1-mobile.svg";
import icon2 from "../../images/icons/mac-profi-icon2-mobile.svg";
import icon3 from "../../images/icons/mac-profi-icon3-mobile.svg";
import vector from "../../images/icons/mac-profi-mobile-select.svg";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img alt="" src={vector} />
  </components.IndicatorsContainer>
);

const bannerMobileLink = [
  {
    img: icon1,
    link: "#",
    text: "Наш сервисный центр в Москве",
  },
  {
    img: icon2,
    link: "/dostavka",
    text: "Курьерская доставка",
  },
];

const MacProfiBannerMobile = () => {
  return (
    <Section>
      <div className="mac-profi-mobile__container">
        <img className="mac-profi-mobile__banner" src={banner} alt="баннер" />
        <div className="mac-profi-mobile__content">
          <h1 className="mac-profi-mobile__title">
            Сервисный центр Мак Профи - Ремонт Apple
          </h1>
          <div className="mac-profi-mobile__border"></div>
          <ul className="mac-profi-mobile__list">
            {bannerMobileLink.map((link, i) => {
              return (
                <li key={i} className="mac-profi-mobile__item">
                  <img
                    src={link.img}
                    alt="иконка ссылки"
                    className="mac-profi-mobile__icon"
                  />
                  <Link className="mac-profi-mobile__link" to={link.link}>
                    {link.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Section>
  );
};

export default MacProfiBannerMobile;
