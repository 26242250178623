import React from "react";

export function useMedia(query) {
  const [matches, setMatches] = React.useState(() => {
    return typeof window !== "undefined"
      ? window.matchMedia(query).matches
      : false;
  });

  React.useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [query]);

  return matches;
}

export const useMobileScreen = () => useMedia("(max-width: 500px)");
export const useTabletScreen = () => useMedia("(max-width: 650px)");
export const useBigTabletScreen = () => useMedia("(max-width: 720px)");
export const useDesktopScreen = () => useMedia("(min-width: 1200px)");
export const useBigDesktopScreen = () => useMedia("(min-width: 1800px)");
