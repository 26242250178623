import React from "react";
import Section from "../../Section/Section";
import { useState } from "react";
import iconStar from "../../../images/icons/center-mobile-icon-star.svg";
import { Link } from "react-router-dom";
import phoneCrashed from "../../../images/phone-crashed.svg";
import phoneWorked from "../../../images/phone-worked.svg";
import padCrashed from "../../../images/pad-crashed.svg";
import padWorked from "../../../images/pad-worked.svg";
import CenterMobileGeneralPageGadgetsMobile from "./CenterMobileGeneralPageGadgetsMobile";
import CenterMobileGeneralPageGadgetsTableScreen from "./CenterMobileGeneralPageGadgetsTableScreen";
import { useMedia } from "../../../utils/useMedia";

const mobileLinks = [
  {
    link1: "SAMSUNG",
    link2: "FLY",
    to1: "remont-smartfonov-samsung",
    to2: "remont-smartfonov-fly",
  },
  {
    link1: "LENOVO",
    link2: "LG",
    to1: "remont-smartfonov-lenovo",
    to2: "remont-smartfonov-lg",
  },
  {
    link1: "NOKIA",
    link2: "HTC",
    to1: "remont-smartfonov-nokia",
    to2: "remont-smartfonov-htc",
  },
  {
    link1: "PHILIPS",
    link2: "SONY",
    to1: "remont-smartfonov-philips",
    to2: "remont-smartfonov-sony",
  },
];

const PadLinks = [
  {
    link1: "ASUS",
    link2: "EXPLAY",
    to1: "remont-planshetov-asus",
    to2: "remont-planshetov-explay",
  },
  {
    link1: "SAMSUNG",
    link2: "SONY",
    to1: "remont-planshetov-samsung",
    to2: "remont-planshetov-sony",
  },
  {
    link1: "PRESTIGIO",
    link2: "ACER",
    to1: "remont-planshetov-prestigio",
    to2: "remont-planshetov-acer",
  },
  {
    link1: "LENOVO",
    link2: "DIGMA",
    to1: "remont-planshetov-lenovo",
    to2: "remont-planshetov-digma",
  },
];

const CenterMobileGeneralPageGadgets = () => {
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const [isPadVisible, setIsPadVisible] = useState(false);
  const [isMobileLinksVisible, setIsMobileLinksVisible] = useState(false);
  const [isPadLinksVisible, setIsPadLinksVisible] = useState(false);

  const showMobile = () => setIsMobileVisible(true);
  const hideMobile = () => setIsMobileVisible(false);
  const showPad = () => setIsPadVisible(true);
  const hidePad = () => setIsPadVisible(false);
  const showMobileLinks = () => setIsMobileLinksVisible(true);
  const hideMobileLinks = () => setIsMobileLinksVisible(false);
  const showPadLinks = () => setIsPadLinksVisible(true);
  const hidePadLinks = () => setIsPadLinksVisible(false);

  function ChangePage() {
    const isBreakpointTableScreen = useMedia("(min-width: 950px)");
    const isBreakpointMobile = useMedia("(max-width: 650px)");

    if (isBreakpointTableScreen) {
      return (
        <Section className="center-mobile__general-page-gadgets">
          <div className="center-mobile__general-page-gadgets-container">
            <h2 className="center-mobile__general-page-gadgets-title">
              КАКОЕ У ВАС УСТРОЙСТВО?
            </h2>
            <div className="center-mobile__general-page-gadgets-wrapper">
              <div className="center-mobile__general-page-gadgets-mobile-wrapper">
                <div
                  className="center-mobile__general-page-gadgets-mobile-group" // Телефон с невидимым блоком
                  onMouseLeave={() => {
                    hideMobile();
                    hideMobileLinks();
                  }}
                >
                  {/* ссылки телефона */}
                  {isMobileLinksVisible ? (
                    <ul className="center-mobile__general-page-gadgets-list">
                      {mobileLinks.map((item, i) => {
                        return (
                          <li
                            key={i}
                            className="center-mobile__general-page-gadgets-links"
                          >
                            <Link
                              className="center-mobile__general-page-gadgets-link"
                              to={item.to1}
                            >
                              {item.link1}
                            </Link>
                            <Link
                              className="center-mobile__general-page-gadgets-link"
                              to={item.to2}
                            >
                              {item.link2}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    // Текст и кнопка при наведении на телефон
                    <p
                      className="center-mobile__general-page-gadgets-mobile-text"
                      style={{
                        visibility: isMobileVisible ? "visible" : "hidden",
                      }}
                    >
                      Мы знаем все популярные марки и модели смартфонов до
                      последнего винтика. И Ваш смартфон - не исключение!
                      <button
                        onClick={showMobileLinks}
                        className="center-mobile__general-page-gadgets-button"
                      >
                        <img
                          className="center-mobile__general-page-gadgets-icon"
                          src={iconStar}
                          alt="иконка звезды"
                        />
                        Популярные бренды
                      </button>
                    </p>
                  )}

                  <div>
                    {/* Экран телефона при наведении */}
                    {isMobileVisible ? (
                      <img src={phoneWorked} alt="" onMouseEnter={showMobile} />
                    ) : (
                      <img
                        src={phoneCrashed}
                        alt=""
                        onMouseEnter={showMobile}
                        className="phone-image"
                      />
                    )}

                    <p className="center-mobile__general-page-gadgets-device">
                      СМАРТФОН
                    </p>
                  </div>
                </div>
              </div>
              <div className="center-mobile__general-page-gadgets-pad-wrapper">
                <div
                  className="center-mobile__general-page-gadgets-pad-group"
                  onMouseLeave={() => {
                    hidePad();
                    hidePadLinks();
                  }}
                >
                  <div>
                    {isPadVisible ? (
                      <img src={padWorked} alt="" onMouseEnter={showPad} />
                    ) : (
                      <img
                        src={padCrashed}
                        alt=""
                        onMouseEnter={showPad}
                        className="pad-image"
                      />
                    )}
                    <p className="center-mobile__general-page-gadgets-device">
                      ПЛАНШЕТ
                    </p>
                  </div>
                  {isPadLinksVisible ? (
                    <ul className="center-mobile__general-page-gadgets-padlist">
                      {PadLinks.map((item, i) => {
                        return (
                          <li
                            key={i}
                            className="center-mobile__general-page-gadgets-links"
                          >
                            <Link
                              className="center-mobile__general-page-gadgets-link"
                              to={item.to1}
                            >
                              {item.link1}
                            </Link>
                            <Link
                              className="center-mobile__general-page-gadgets-link"
                              to={item.to2}
                            >
                              {item.link2}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p
                      className="center-mobile__general-page-gadgets-pad-text"
                      style={{
                        visibility: isPadVisible ? "visible" : "hidden",
                      }}
                    >
                      Мы знаем все популярные марки и модели планшетов до
                      последнего винтика. И Ваш планшет - не исключение!
                      <button
                        onClick={showPadLinks}
                        className="center-mobile__general-page-gadgets-button"
                      >
                        <img
                          className="center-mobile__general-page-gadgets-icon"
                          src={iconStar}
                          alt="иконка звезды"
                        />
                        Популярные бренды
                      </button>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Section>
      );
    } else if (isBreakpointMobile) {
      return <CenterMobileGeneralPageGadgetsMobile />;
    }
    return (
      <CenterMobileGeneralPageGadgetsTableScreen
        mobileLinks={mobileLinks}
        PadLinks={PadLinks}
      />
    );
  }

  return ChangePage();
};

export default CenterMobileGeneralPageGadgets;
