import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertInsidePagePrice.module.css";

function NoteExpertInsidePagePrice({ title, subtitle, priceElements, prices }) {
  return (
    <Section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.list}>
          {priceElements.map((element, i) => {
            return (
              <li key={i} className={styles.item}>
                <img src={element.icon} alt="" className={styles.icon} />
                <p className={styles.text}>{element.text}</p>
              </li>
            );
          })}
        </ul>
        <h3 className={styles.subtitle}>{subtitle}</h3>
        <ul className={styles.priceList}>
          {prices.map((item, i) => {
            return (
              <li
                key={i}
                style={{
                  backgroundColor: item.color,
                  boxShadow: item.boxShadow,
                }}
                className={styles.priceItem}
              >
                <p className={styles.priceText}>{item.text}</p>
                <b className={styles.pricePrice}>{item.price}</b>
              </li>
            );
          })}
        </ul>
        <div className={styles.border}></div>
        <p className={styles.paragraph}>* Все цены указаны в рублях РФ.</p>
        <button className={styles.button}>Скачать весь прайс-лист</button>
      </div>
    </Section>
  );
}

export default NoteExpertInsidePagePrice;
