import React from "react";
import Section from "../../Section/Section";
import styles from "./NoteExpertGeneralPageFeedbacksMobile.module.css";
import NoteExpertGeneralPageSliderMobile from "./NoteExpertGeneralPageSliderMobile";
import NoteExpertVideoMobile from "./NoteExpertVideoMobile";

function NoteExpertGeneralPageFeedbacksMobile({ videos }) {
  return (
    <Section>
      <Section className={styles.section}>
        <div className={styles.content}>
          <h2 className={styles.title}>отзывы наших клиентов</h2>
          <NoteExpertGeneralPageSliderMobile videos={videos}>
            {videos.map((video, i) => {
              return <NoteExpertVideoMobile key={i} link={video.link} />;
            })}
          </NoteExpertGeneralPageSliderMobile>
          <h3 className={styles.subtitle}>СУПЕР СКИДКА</h3>
          <p className={styles.text}>
            Снимите ваш видео-отзыв о работе Note Expert на камеру или мобильный
            телефон, отправьте его нам и получите персональную скидку до 25%
          </p>
          <button className={styles.button}>Оставить отзыв</button>
        </div>
      </Section>
    </Section>
  );
}

export default NoteExpertGeneralPageFeedbacksMobile;
