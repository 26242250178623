import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";
import { Helmet } from "react-helmet";

const callbackText = [
  "Одна из проблем, с которыми рано или поздно приходится сталкиваться большинству владельцев ноутбуков, – это отсутствие заряда аккумуляторной батареи от сети при подключении к ней.",
  "Кроме того, что, если ноутбук перестал заряжаться, это создает пользователю много неудобств и даже может сделать использование устройства абсолютно невозможным, эта проблема еще и достаточно сложно диагностируется, поскольку может иметь довольно приличное количество причин.",
  "Однако волноваться раньше времени не стоит, большинство из них легко устранимы, и могут быть ликвидированы даже без вмешательства специалистов.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Чаще всего ноутбук не заряжается, если есть проблемы с аккумулятором либо зарядным устройством. Аккумуляторная батарея может снизить емкость, исчерпать свой рабочий ресурс, перестать заряжаться в результате каких-либо аппаратных или программных сбоев. К аппаратным относятся неисправности самой батареи либо цепи питания (повреждения разъемов, штекеров, шнура питания, сетевого адаптера).",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Программные сбои связаны с ошибками BIOS, драйверов или контроллера питания аккумулятора. Выявить причину, по которой переносное компьютерное устройство перестало заряжаться, можно после его пошагового тестирования.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Ремонт ноутбуков в сервисном центре Note Expert включает весь спектр услуг по восстановлению работоспособности устройства с самопроизвольной перезагрузкой.",
  },
  {
    icon: priceIcon2,
    text: "Мы предлагаем заказчикам профессиональный сервис и высокое качество по самым разумным ценам.",
  },
  {
    icon: priceIcon3,
    text: "Диагностику неисправностей выполняем бесплатно!",
  },
];

const prices = [
  {
    text: "Внешние блоки питания",
    price: "от 1000 Р",
  },
  {
    text: "Ремонт цепи питания",
    price: "от 2500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Аккумуляторы для ноутбуков",
    price: "от 800 P",
  },
  {
    text: "Замена разъема питания",
    price: "от 700 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Обнаружили, что аккумулятор ноутбука не заряжается? Обратитесь в наш специализирующийся на ремонте и обслуживании ноутбуков всех марок сервисный центр.",
  "Наши эксперты точно и быстро диагностируют неисправность и профессионально ее устранят.",
  "Получить ответы на интересующие вопросы или полную консультацию по ремонту не заряжающегося ноутбука можно по телефону +7(495) 374-54-81. Круглосуточно",
];

function NoteExpertNoteSettingCharge() {
  return (
    <Page title="Ремонт ноутбука, который не заряжается">
      <main className={styles.main}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/note-expert/ne-zaryazhaetsya"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Не заряжается аккумулятор ноутбука – специалисты сервисного центра Oh!MyGadget! выполнят диагностику и предложат оптимальное решение проблемы. Полный спектр услуг по конкурентным ценам."
          />
        </Helmet>
        <NoteExpertInsidePageBanner
          banner={banner}
          alt="Не заряжается ноутбук"
          title="Не заряжается ноутбук"
        />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Не заряжается аккумулятор ноутбука"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Почему на ноутбуке не заряжается батарея"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Сколько стоит отремонтировать не заряжающийся ноутбук"
          subtitle="Стоимость ремонта не заряжающегося ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Ноутбук не заряжается: что делать?"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingCharge;
