import React from 'react'
import { Section } from '../index';

const iMacPrice = [
  {
    id: 1,
    option: 'Полная диагностика iMac в сервисном центре',
    price: 'БЕСЛПАТНО',
  },
  {
    id: 2,
    option: 'Установка программного обеспечения на iMac (стоимость рассчитывается от набора выбранных Вами программ)',
    price: 'от 600 P',
  },
  {
    id: 3,
    option: 'Настройка программ на iMac (цены работы зависит от сложности настройки конкретного программного обеспечения, точную стоимость настройки Вы можете уточнить у менеджера по телефону или в онлайн консультанте)',
    price: 'от 400 P',
  },
  {
    id: 4,
    option: 'Восстановление данных c HDD/SDD iMac (стоимость рассчитывается после проведения аппаратной/программной диагностики в сервисном центре)',
    price: 'от 400 P',
  },
  {
    id: 5,
    option: 'Замена матрицы iMac (стоимость зависит от модели вашего iMac)',
    price: 'от 3500 P',
  },
  {
    id: 6,
    option: 'Замена жесткого диска HDD/SSD (стоимость рассчитывается исходя из объема жесткого диска или SSD)',
    price: 'от 1000 P',
  },
  {
    id: 7,
    option: 'Замена защитного стекла на iMac (стоимость определяется исходя из модели вашего iMac)',
    price: 'от 2000 P',
  },
  {
    id: 8,
    option: 'Ремонт материнской платы iMac (ремонт после перегрева, перебоя электропитания, залития, некорректной работы и при других неисправностях)',
    price: 'от 2300 P',
  },
  {
    id: 9,
    option: 'Замена и ремонт видеокарты в iMac (стоимость рассчитывается исходя из модели видеокарты)',
    price: 'от 900 P',
  },
  {
    id: 10,
    option: 'Ремонт прочих поломок в iMac (проконсультируйтесь у менеджера компании по телефону или в онлайн консультанте на сайте)',
    price: 'от 500 P',
  }
]

const RepairIMacPrice = () =>  {
  return (
    <Section>
      <div className='repair-mb__price-container'>
        <h2 className='repair-mb__price-title'>Наши решения. Расценки на ремонт Apple iMac:</h2>
        <ul className='repair-mb__price-list'>
          {iMacPrice.map((item) => {
            return (
              <li key={item.id} className='repair-mb__price-item'>
                <h4 className='repair-mb__price-text'>{item.option}</h4>
                <p className='repair-mb__price-price'>{item.price}</p>
              </li>
            )
          })}
          <p className='repair-mb__price-paragraph'>* р. - Российский Рубль (все цены на сайте указаны в рублях). Все цены указаны без учета стоимости комплектующих, стоимость комплектующего уточняйте у менеджера по телефону.</p>
        </ul>
      </div>
    </Section>
  )
};

export default RepairIMacPrice;