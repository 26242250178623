import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelsLinks,
  Section,
  RepairIphoneServicesLinks,
  RepairAppleIphonePrice,
} from "../index";
import banner from "../../images/banners/repair-iphone6splus.jpeg";
import { Helmet } from "react-helmet";

const textOfModel6sPlus = [
  {
    id: 1,
    title: "Последовательность ремонта Айфон 6с плюс в наших центрах",
    text: (
      <>
        Все начинается с диагностики. Наши специалисты выявят все очевидные и
        неочевидные проблемные места в работе вашего телефона. Кстати,
        диагностика в наших центрах - бесплатна. Совсем и безоговорочно. Далее:
        мы расскажем (и покажем если надо) обо всех выявленных неисправностях.
        Какие из них надо исправить - решаете вы. После этого пройдет не более
        20 минут - и вот он уже у вас на руках, ваш исправный, работающий и
        выглядящий как новый iPhone 6s Plus!
      </>
    ),
  },
  {
    id: 2,
    title: "Особенности поломок и ремонта iPhone 6s Plus",
    text: (
      <>
        У iPhone 6s Plus по сравнению с предшествующими моделями в линейке, был
        усилен корпус, в котором был использован новый план алюминия с марганцем
        и цинком, а также специальное стекло - по заверениям самих Apple, самое
        ударостойкое из имеющихся на рынке. В отличие от первых "шестёрок",
        чтобы погнуть данный телефон потребуется недюжинная сила, да и
        большинство падении на пол, телефон должен пережить. Как там не было, но
        позаботиться о вашем телефоне, приобретя чехол всё же следует. Айфон 6с
        плюс оснащен специальным уплотнителем экрана, разъёмы - защищены
        резиновыми протекторами. Нет, это не полная влагостойкость такая как у
        iPhone 7, но принятые инженерами Apple скорее всего уберегут ваш телефон
        и его электронное содержимое при кратковременном попадании в воду.
      </>
    ),
  },
];

const price6sPlus = [
  {
    id: 1,
    option: "Замена дисплея",
    price: "1700 P",
  },
  {
    id: 2,
    option: "Замена аккумулятора",
    price: "900 P",
  },
  {
    id: 3,
    option: "Замена микрофона",
    price: "1150 P",
  },
  {
    id: 4,
    option: "Замена кнопки вибро/Замена кнопок громкости",
    price: "1100 P",
  },
  {
    id: 5,
    option: "Замена кнопки включения",
    price: "990 P",
  },
  {
    id: 6,
    option: "	Замена антенны",
    price: "1100 P",
  },
  {
    id: 7,
    option: "Ремонт Wi-Fi модуля",
    price: "3000 P",
  },
  {
    id: 8,
    option: "Замена датчика приближения",
    price: "1300 P",
  },
  {
    id: 9,
    option: "Замена сим-держателя",
    price: "500 P",
  },
  {
    id: 10,
    option: "Замена основной камеры",
    price: "990 P",
  },
  {
    id: 11,
    option: "Замена передней камеры",
    price: "1100 P",
  },
  {
    id: 12,
    option: "Замена разъема зарядки",
    price: "1000 P",
  },
  {
    id: 13,
    option: "Замена разъема наушников",
    price: "1350 P",
  },
  {
    id: 14,
    option: "Замена кнопки Home (домой)",
    price: "1000 P",
  },
  {
    id: 15,
    option: "Замена задней крышки",
    price: "900 P",
  },
  {
    id: 16,
    option: "Замена корпуса",
    price: "2020 P",
  },
  {
    id: 17,
    option: "Замена вибромотора",
    price: "800 P",
  },
  {
    id: 18,
    option: "Замена слухового динамика",
    price: "1150 P",
  },
  {
    id: 19,
    option: "Замена полифонического динамика",
    price: "1000 P",
  },
];

const RepairIphoneModelPage6sPlus = () => {
  return (
    <Page title="Срочный ремонт iPhone 6s Plus в Москве - Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/6s-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Если вам нужен ремонт iPhone 6s Plus в Москве, наш сервисный центр Oh!MyGadget! с радостью вам в этом поможет. Цены на ремонт Айфон 6с Плюс в Москве."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPhone 6s Plus"
          titleImg="Ремонт iPhone 6s Plus"
          banner={banner}
          title="Ремонт iPhone 6s Plus"
          text={
            <>
              Ремонт iPhone 6s Plus может показаться их владельцам событием
              маловероятным - ведь такое произведение инженерного искусства вряд
              ли спасует перед проблемами характерными для более простых и
              прозаичных телефонов! На самом деле, это не так. Преимущества
              телефона могут обернутся его же недостатками, когда речь заходит о
              возможности поломок. Так, широкий экран и элегантный тонкий корпус
              со скруглёнными краями крайне уязвимы к механическим повреждениям.
              Падение из рук iPhone 6s Plus без защитного чехла, практически
              наверняка приведёт к необходимости ремонта. Сенсорный экран,
              оснащенный технологией 3D Touch, как и любое нововведение, более
              подвержен программным сбоям и неполадкам.
            </>
          }
        />
        <Section>
          <RepairAppleIphonePrice
            prices={price6sPlus}
            title="Цены на ремонт iPhone 6s Plus"
            text="* Стоимость включает комплектующее и услугу по ремонту. Все цены в рублях РФ."
          />
        </Section>
        <RepairIphoneModelsLinks />
        <RepairIphoneServicesLinks />
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          {textOfModel6sPlus.map((item) => {
            return <RepairAppleLinksText key={item.id} {...item} />;
          })}
        </Section>
      </main>
    </Page>
  );
};

export default RepairIphoneModelPage6sPlus;
