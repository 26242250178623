import React from "react";
import { Section } from "../index";
import { useMedia } from "../../utils/useMedia";
import RepairAppleTableMobile from "../RepairAppleTableMobile/RepairAppleTableMobile";

const iPodTouch5G = [
  {
    service: "Диагностика в сервисном центре",
    time: "20 минут",
    price: "Бесплатно",
  },
  {
    service: "Резервная копия данных с iPad",
    time: "20 минут",
    price: "Бесплатное резервное копирование данных с iPod",
  },
  {
    service: "Прошивка iPod",
    time: "20 минут",
    price: "850 р.",
  },
  {
    service: "Замена дисплея iPod",
    time: "от 30 минут",
    price: "7950 р.",
  },
  {
    service: "Замена тачскрина (стекла) iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена разъема подключения наушников iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена верхнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена нижнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена аккумулятора iPod",
    time: "от 25 минут",
    price: "3450 р.",
  },
  {
    service: "Замена кнопки Home (домой) iPod",
    time: "от 25 минут",
    price: "1150 р.",
  },
  {
    service: "Замена задней крышки iPod",
    time: "от 25 минут",
    price: "3450 р.",
  },
  {
    service: "Замена камеры iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
];

const iPodTouch4G = [
  {
    service: "Диагностика в сервисном центре",
    time: "20 минут",
    price: "Бесплатно",
  },
  {
    service: "Резервная копия данных с iPad",
    time: "20 минут",
    price: "Бесплатное резервное копирование данных с iPod",
  },
  {
    service: "Прошивка iPod",
    time: "20 минут",
    price: "850 р.",
  },
  {
    service: "Замена дисплея iPod",
    time: "от 30 минут",
    price: "1950 р.",
  },
  {
    service: "Замена тачскрина (стекла) iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена разъема подключения наушников iPod",
    time: "от 25 минут",
    price: "1450 р",
  },
  {
    service: "Замена верхнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена нижнего шлейфа iPod",
    time: "от 25 минут",
    price: "1450 р.",
  },
  {
    service: "Замена аккумулятора iPod",
    time: "от 25 минут",
    price: "2450 р.",
  },
  {
    service: "Замена кнопки Home (домой) iPod",
    time: "от 25 минут",
    price: "1150 р.",
  },
  {
    service: "Замена задней крышки iPod",
    time: "от 25 минут",
    price: "2450 р.",
  },
  {
    service: "Замена камеры iPod",
    time: "от 25 минут",
    price: "1150 р.",
  },
];

const iPodTouch3G2G1G = [
  {
    service: "Диагностика в сервисном центре",
    time: "20 минут",
    price: "Бесплатно",
  },
  {
    service: "Резервная копия данных с iPad",
    time: "20 минут",
    price: "Бесплатное резервное копирование данных с iPod",
  },
  {
    service: "Прошивка iPod",
    time: "20 минут",
    price: "850 р.",
  },
  {
    service: "Замена дисплея iPod",
    time: "от 30 минут",
    price: "1850 р.",
  },
  {
    service: "Замена тачскрина (стекла) iPod",
    time: "от 25 минут",
    price: "1450 р.",
  },
  {
    service: "Замена разъема подключения наушников iPod",
    time: "от 25 минут",
    price: "1450 р",
  },
  {
    service: "Замена верхнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена нижнего шлейфа iPod",
    time: "от 25 минут",
    price: "1450 р.",
  },
  {
    service: "Замена аккумулятора iPod",
    time: "от 25 минут",
    price: "1550 р.",
  },
  {
    service: "Замена кнопки Home (домой) iPod",
    time: "от 25 минут",
    price: "1150 р.",
  },
  {
    service: "Замена задней крышки iPod",
    time: "от 25 минут",
    price: "2150 р.",
  },
  {
    service: "Замена камеры iPod",
    time: "от 25 минут",
    price: "1150 р.",
  },
];

const iPodNano7G = [
  {
    service: "Диагностика в сервисном центре",
    time: "20 минут",
    price: "Бесплатно",
  },
  {
    service: "Резервная копия данных с iPad",
    time: "20 минут",
    price: "Бесплатное резервное копирование данных с iPod",
  },
  {
    service: "Прошивка iPod",
    time: "20 минут",
    price: "850 р.",
  },
  {
    service: "Замена дисплея iPod",
    time: "от 30 минут",
    price: "1850 р.",
  },
  {
    service: "Замена тачскрина (стекла) iPod",
    time: "от 25 минут",
    price: "2950 р",
  },
  {
    service: "Замена разъема подключения наушников iPod",
    time: "от 25 минут",
    price: "1950 р",
  },
  {
    service: "Замена верхнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена нижнего шлейфа iPod",
    time: "от 25 минут",
    price: "1450 р.",
  },
  {
    service: "Замена аккумулятора iPod",
    time: "от 25 минут",
    price: "2450 р.",
  },
  {
    service: "Замена кнопки Home (домой) iPod",
    time: "от 25 минут",
    price: "1050 р.",
  },
  {
    service: "Замена задней крышки iPod",
    time: "от 25 минут",
    price: "3150 р.",
  },
  {
    service: "Замена камеры iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
];

const iPodNano6G = [
  {
    service: "Диагностика в сервисном центре",
    time: "20 минут",
    price: "Бесплатно",
  },
  {
    service: "Резервная копия данных с iPad",
    time: "20 минут",
    price: "Бесплатное резервное копирование данных с iPod",
  },
  {
    service: "Прошивка iPod",
    time: "20 минут",
    price: "850 р.",
  },
  {
    service: "Замена дисплея iPod",
    time: "от 30 минут",
    price: "1950 р",
  },
  {
    service: "Замена тачскрина (стекла) iPod",
    time: "от 25 минут",
    price: "1950 р",
  },
  {
    service: "Замена разъема подключения наушников iPod",
    time: "от 25 минут",
    price: "1450 р",
  },
  {
    service: "Замена верхнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена нижнего шлейфа iPod",
    time: "от 25 минут",
    price: "1450 р.",
  },
  {
    service: "Замена аккумулятора iPod",
    time: "от 25 минут",
    price: "1550 р.",
  },
  {
    service: "Замена задней крышки iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена камеры iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
];

const iPodClassic = [
  {
    service: "Диагностика в сервисном центре",
    time: "20 минут",
    price: "Бесплатно",
  },
  {
    service: "Резервная копия данных с iPad",
    time: "20 минут",
    price: "Бесплатное резервное копирование данных с iPod",
  },
  {
    service: "Прошивка iPod",
    time: "20 минут",
    price: "850 р.",
  },
  {
    service: "Замена дисплея iPod",
    time: "от 30 минут",
    price: "1950 р",
  },
  {
    service: "Замена тачскрина (стекла) iPod",
    time: "от 25 минут",
    price: "1450 р",
  },
  {
    service: "Замена разъема подключения наушников iPod",
    time: "от 25 минут",
    price: "1450 р",
  },
  {
    service: "Замена верхнего шлейфа iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена нижнего шлейфа iPod",
    time: "от 25 минут",
    price: "1450 р.",
  },
  {
    service: "Замена аккумулятора iPod",
    time: "от 25 минут",
    price: "2050 р.",
  },
  {
    service: "Замена кнопки Home (домой) iPod",
    time: "от 25 минут",
    price: "1050 р.",
  },
  {
    service: "Замена задней крышки iPod",
    time: "от 25 минут",
    price: "1950 р.",
  },
  {
    service: "Замена HDD (жесткий диск) iPod",
    time: "от 25 минут",
    price: "80 GB - 2650 р. 120 GB - 3650 р. 160 GB - 4150 р.",
  },
];

const ipodOptions = [
  {
    value: "iPod Touch 5G",
    label: "iPod Touch 5G",
    list: iPodTouch5G,
  },
  {
    value: "iPod Touch 4G",
    label: "iPod Touch 4G",
    list: iPodTouch4G,
  },
  {
    value: "iPod Touch 3G, 2G, 1G",
    label: "iPod Touch 3G, 2G, 1G",
    list: iPodTouch3G2G1G,
  },
  {
    value: "iPod Nano 7G",
    label: "iPod Nano 7G",
    list: iPodNano7G,
  },
  {
    value: "iPod Nano 6G",
    label: "iPod Nano 6G",
    list: iPodNano6G,
  },
  {
    value: "iPod Classic",
    label: "iPod Classic",
    list: iPodClassic,
  },
];

function ChangeIpodTable() {
  const isBreakpoint = useMedia("(max-width: 950px)");

  if (isBreakpoint) {
    return (
      <RepairAppleTableMobile
        options={ipodOptions}
        serviceText="Наименование услуги"
      />
    );
  }
  return (
    <table>
      <thead>
        <tr className="mb-matrix__table-title">
          <th>НАИМЕНОВАНИЕ УСЛУГИ</th>
          <th>iPod Touch 5G</th>
          <th>iPod Touch 4G</th>
          <th>iPod Touch 3G, 2G, 1G</th>
          <th>iPod Nano 7G</th>
          <th>iPod Nano 6G</th>
          <th>iPod Classic</th>
          <th>СРОКИ РЕМОНТА</th>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Диагностика в сервисном центре</td>
          <td colSpan={6}>Бесплатно</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Резервная копия данных с iPad</td>
          <td colSpan={6}>Бесплатное резервное копирование данных с iPod</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Прошивка iPod</td>
          <td colSpan={6}>850 р.</td>
          <td>20 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена дисплея iPod</td>
          <td>7950 р.</td>
          <td>1950 р.</td>
          <td>1850 р.</td>
          <td>1850 р.</td>
          <td>1950 р</td>
          <td>1950 р.</td>
          <td>от 30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена тачскрина (стекла) iPod</td>
          <td>7950 р.</td>
          <td>1950 р.</td>
          <td>1450 р.</td>
          <td>2950 р.</td>
          <td>1950 р</td>
          <td>1450 р.</td>
          <td>от 30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена разъема подключения наушников iPod</td>
          <td>1950 р.</td>
          <td>1450 р</td>
          <td>1450 р.</td>
          <td> 1950 р.</td>
          <td>1450 р</td>
          <td>1450 р.</td>
          <td>от 25 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена верхнего шлейфа iPod</td>
          <td>1950 р.</td>
          <td>1950 р</td>
          <td>1950 р.</td>
          <td>1950 р.</td>
          <td>1950 р</td>
          <td>1950 р.</td>
          <td>от 25 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена нижнего шлейфа iPod</td>
          <td>1950 р.</td>
          <td>1450 р</td>
          <td>1450 р.</td>
          <td>1450 р.</td>
          <td>1450 р</td>
          <td>1450 р.</td>
          <td>от 25 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена аккумулятора iPod</td>
          <td>3450 р.</td>
          <td>2450 р.</td>
          <td>1550 р.</td>
          <td>2450 р.</td>
          <td>1550 р.</td>
          <td>2050 р.</td>
          <td>от 25 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена кнопки Home (домой) iPod</td>
          <td>1150 р.</td>
          <td>1150 р.</td>
          <td>1150 р.</td>
          <td>1050 р.</td>
          <td>-</td>
          <td>1050 р.</td>
          <td>от 25 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена HDD (жесткий диск) iPod</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>
            <p style={{ margin: 0, padding: 0 }}>80 GB - 2650 р.</p>
            <p style={{ margin: 0, padding: 0 }}>120 GB - 3650 р.</p>
            <p style={{ margin: 0, padding: 0 }}>160 GB - 4150 р.</p>
          </td>
          <td>от 30 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена задней крышки iPod</td>
          <td>3450 р.</td>
          <td>2450 р.</td>
          <td>2150 р.</td>
          <td>3150 р.</td>
          <td>1950 р.</td>
          <td>1950 р.</td>
          <td>от 25 минут</td>
        </tr>
        <tr className="mb-matrix__table-title">
          <td>Замена камеры iPod</td>
          <td>1950 р.</td>
          <td>1150 р. </td>
          <td>1150 р. </td>
          <td>1950 р.</td>
          <td>1950 р.</td>
          <td>-</td>
          <td>от 25 минут</td>
        </tr>
      </thead>
    </table>
  );
}

const RepairIpodTable = () => {
  return (
    <Section className="section_group" style={{ padding: "50px 0 49px" }}>
      <div className="repair-mb__group-list-container">
        <h2 className="repair-mb__group-links-subtitle">
          Полный прайс лист на ремонт iPod Touch 5G, 4G, 3G, 2G, 1G, iPod Nano
          7G, 6G, iPod Classic
        </h2>
        {ChangeIpodTable()}
      </div>
    </Section>
  );
};

export default RepairIpodTable;
