import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { PopupAdminFeedback, PopupConfirm, Preloader } from "../components";
import useFetch from "../hooks/useFetch";
import { feedbacksApi } from "../utils/api";
import Table from "./Table";
import usePopup from "../hooks/usePopup";
import { addIcon, trashTableIcon, pinIcon } from "./newIcons/index";

const columns = [
  {
    name: "id",
    selector: (row) => row._id,
    id: true,
    field: "_id",
  },
  {
    name: "Дата",
    selector: (row) => row.created.toString().substring(0, 10),
    field: "created",
  },
  {
    name: "Текст отзыва",
    selector: (row) => row.text,
    render: (row) => (
      <Link to={`/admin-page/otzivy/${row._id}`} className="admin__data-link">
        {row.text}
      </Link>
    ),
    field: "text",
  },
  {
    name: "v",
    selecor: (row) => row.approved,
    render: (row) => (
      <div className="admin__checkbox">
        <input
          type="checkbox"
          onChange={(event) => {
            feedbacksApi.editFeedback(row._id, {
              approved: event.target.checked,
            });
          }}
          defaultChecked={row.approved}
          id="checkbox"
          className={["admin__checkbox-image"]}
        />
      </div>
    ),
    // field: "created",
  },
];

const AdminFeedbacksList = () => {
  const { isPopupOpen, togglePopup, closePopup } = usePopup();
  const {
    isPopupOpen: isRemovePopupOpen,
    togglePopup: toggleRemovePopup,
    closePopup: closeRemovePopup,
  } = usePopup();

  const {
    data: feedbacksPage,
    loading,
    setApiFilters: setFilters,
    setData: setFeedbacksPage,
    setLoading,
  } = useFetch(feedbacksApi.getFeedbacks);

  const tableStateRef = useRef(null);

  const handlePageChange = (nextPage) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: nextPage }));
  };

  const handleSearchChange = (search) => {
    setFilters((prevFilters) => ({ ...prevFilters, search }));
  };

  const handleRemove = async (state) => {
    const { selectedRows } = state;
    const feedbacksIds = selectedRows.map((selectedRow) => selectedRow._id);

    await feedbacksApi.deleteFeedbacks(feedbacksIds);
    const feedbacks = await feedbacksApi.getFeedbacks();

    setFeedbacksPage(feedbacks);

    tableStateRef.current = null;
  };

  const handleApprove = async (state) => {
    const { selectedRows } = state;
    const feedbacksApproveStatus = selectedRows.map(
      (selectedRow) => selectedRow._id
    );

    await feedbacksApi.approveFeedbacks(feedbacksApproveStatus);

    const feedbacks = await feedbacksApi.getFeedbacks();

    setFeedbacksPage(feedbacks);

    tableStateRef.current = null;
  };

  const handleAddFeedbackFromAdmin = async (text, name, email) => {
    setLoading(true);

    await feedbacksApi.postFeedback(text, name, email);

    const feedbacks = await feedbacksApi.getFeedbacks();
    setFeedbacksPage(feedbacks);

    setTimeout(() => setLoading(false), 700);
  };

  const handleSortFeedbacks = async (field, direction) => {
    const params = {
      ordering: `${direction === "descending" ? "-" : "+"}${field}`,
    };
    const sortedFeedbacks = await feedbacksApi.getFeedbacks(params);

    setFeedbacksPage(sortedFeedbacks);
  };

  const actions = [
    { name: "Создать", onClick: togglePopup, icon: addIcon },
    {
      name: "Разместить",
      onClick: async (state) => {
        tableStateRef.current = state;
        await handleApprove(tableStateRef.current);
        state.uncheckSelectedRows();
      },
      icon: pinIcon,
      type: "post",
      disabled: (state) =>
        !state.isChecked ||
        feedbacksPage.results.every((feedback) => feedback.approved),
    },
    {
      name: "Удалить",
      onClick: (state) => {
        tableStateRef.current = state;
        toggleRemovePopup();
      },
      icon: trashTableIcon,
      type: "danger",
      disabled: (state) => !state.isChecked,
    },
  ];

  let errorMessage = "";
  if (!loading && feedbacksPage.results && feedbacksPage.results.length === 0) {
    errorMessage = "Данные по запросу не найдены";
  }

  return (
    <>
      {loading && <Preloader overlay={true} />}
      <section className="info">
        <div className="info__container">
          <Table
            title="Таблица пользователей с доступом в систему"
            columns={columns}
            data={feedbacksPage.results}
            actions={actions}
            totalDataCount={feedbacksPage.count}
            onPageChange={handlePageChange}
            onSearchChange={handleSearchChange}
            errorMessage={errorMessage}
            onSortChange={handleSortFeedbacks}
          />
        </div>
      </section>
      <PopupAdminFeedback
        isOpenPopup={isPopupOpen}
        onClose={closePopup}
        handleAddFeedbackFromAdmin={handleAddFeedbackFromAdmin}
      />
      <PopupConfirm
        isOpenPopup={isRemovePopupOpen}
        onClose={closeRemovePopup}
        handleSubmit={() => handleRemove(tableStateRef.current)}
      />
    </>
  );
};

export default AdminFeedbacksList;
