import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIpadModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
  RepairAppleTextWithCheckMark,
} from "../index";
import banner from "../../images/banners/displayIpadMini3.jpeg";
import { iPadListOfModelsForDisplay } from "../../utils/ipadModels";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Замену стекла iPad Mini 2 Retina – снимем разбитую поверхность, которую «украшают» трещины и сколы, установив новое стекло.",
    number: "01",
  },
  {
    id: 2,
    text: "Замену тачскрина – в этом не всегда есть необходимость, всё зависит от серьезности и характера повреждений. Но если потребуется заменить не только стекло, но и сенсор, то наши специалисты успешно справятся с поставленной задачей.",
    number: "02",
  },
  {
    id: 3,
    text: "Восстановление корпуса – если не выполнить рихтовку, но деформированная конструкция будет оказывать давление на внутренние части, что приведет к скорому выходу из строя и других элементов конструкции.",
    number: "03",
  },
];

const textOfDisplayMini = [
  {
    id: 1,
    title: "Когда требуется замена стекла iPad Mini 3",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Падение редко проходит без последствий, поэтому ремонту всегда предшествует диагностика. Исследование проводится абсолютно бесплатно – после его завершения наши специалисты сообщат заказчику о результатах, и назовут точную стоимость предстоящего восстановления."
          paragraphUp2="При появлении одного из списка «симптомов», стоит обратиться к специалистам:"
          array={listOfTrouble}
          paragraphDown="Если подобные проблемы появились после воздействия на стекло, то вероятнее всего причина кроется в его повреждения. Но они могут быть связаны и с поломкой шлейфа или другими дефектами, вызвавшими нарушение работы."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Сроки замены стекла в сервисном центре Мак Профи",
    text: "Для восстановления потребуется около суток, но при необходимости может быть выполнен и срочный ремонт. Сложность работы заключается в том, что «начинка» занимает практически весь уменьшенный корпус устройства – любое неправильное действие приведет к повреждению соседних элементов. Заниматься восстановлением планшета должны профессионалы, которые используют полный комплект инструментов и оборудования – в сервисном центре Мак Профи есть всё необходимое.  ",
  },
];

const RepairIpadServiceLinkDisplayModelMini3 = () => {
  return (
    <Page title="Надежная замена стекла (тачскрина) iPad Mini 3 за 40 минут">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipad/zamena-stekla/mini-3"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Такая необходимость, как замена стекла iPad Mini 3, может стать актуальной в любой момент. Сервисный центр Oh!MyGadget! обеспечит вам такую возможность без переплат. "
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="замена стекла ipad mini 3"
          titleImg="замена стекла ipad mini 3"
          banner={banner}
          title={<>Замена стекла iPad&nbsp;Mini&nbsp;3</>}
          text="Apple iPad – надежная техника, которая практически не доставляет владельцу неудобств на протяжении всего периода эксплуатации. Среди распространенных причин выхода устройства из строя можно выделить лишь различные варианты механических воздействий, из-за которых страдает экран. Если вы случайно уронили планшет, обратитесь в сервисный центр Мак Профи, и сможете получить полный комплекс услуг по доступным ценам."
        />
        <Section className="section_group">
          {textOfDisplayMini.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена стекла iPad Mini 3"
          text="Замена сенсорного стекла (тачскрина)"
          price="3500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Базовая стоимость замены стекла iPad Mini 3 указана на сайте, но
                точная сумма будет озвучена после проведения диагностики. Цена
                зависит от сложности повреждений, а также затрат на
                комплектующие, используемые для проведения ремонта. Наш
                сервисный центр на протяжении длительного времени сотрудничает с
                постоянными поставщиками, благодаря чему в наличии всегда есть
                необходимые детали, а расценки выгодны и лояльны. Дополнительные
                скидки и специальные предложения позволят восстановить
                устройство при небольших финансовых затратах.
              </p>
              <p className="repair-iphone-display__paragraph">
                Обращайтесь к нам в удобное время – сервис Мак Профи работает с
                понедельника по пятницу. Выполним замену стекла iPad Mini 3,
                проконсультируем и поможем с настройкой устройства – широчайший
                спектр услуг по приятным ценам.
              </p>
            </>
          }
        />
        <RepairIpadModelLinksForServiceMobile
          links={iPadListOfModelsForDisplay}
        />
      </main>
    </Page>
  );
};

export default RepairIpadServiceLinkDisplayModelMini3;
