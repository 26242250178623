import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logos/header_burger.svg";

const HeaderBurger = ({ active, setActive }) => {
  return (
    <header className="header-burger">
      <div className="header-burger__content">
        <Link to="/">
          <img
            className="header-burger-logo"
            src={logo}
            alt="логотип компании"
          />
        </Link>
        <a href="tel:+74955405067?call" className="header-burger__tel-link">
          +7(495)540-50-67
        </a>
        <div
          className={active ? "header-burger_btn-close" : "header-burger_btn"}
          onClick={() => setActive((prevActive) => !prevActive)}
        ></div>
      </div>
    </header>
  );
};

export default HeaderBurger;
