import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairApplePrice,
} from "../index";
import banner from "../../images/banners/battery5c.jpeg";
import { listOfModelForBattery } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfBattery5c = [
  {
    id: 1,
    title: "Причины выхода из строя батареи iPhone 5с",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Стандартный срок эксплуатации устройства составляет 2 года, но в
          большинстве случае сами владельцы значительно сокращают его. Это
          происходит по следующим причинам:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Эксплуатация в условиях повышенной влажности или проникновение
              влаги внутрь телефона.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Несоблюдение температурного режима – как высокие, так и низкие
              температуры негативно отражаются на работе батареи.
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Некачественное зарядное устройство – использование китайской
              продукции выведет аккумулятор из строя.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Нередко обладатели iPhone 5с узнают о проблеме не только из-за частых
          скачков заряда – испорченная батарея вздувается и начинает давить на
          корпус. Если её не заменить, то рано или поздно появятся трещины, а в
          дальнейшем выйдут из строя и другие элементы конструкции.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Нужно ли менять аккумулятор iPhone 5с при снижении его емкости",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Даже при достаточно активном использовании устройства, его аккумулятор
          должен обеспечивать работу смартфона на протяжении 8 часов. Если же
          этот период сократился, владельцу предстоит обратиться в сервисный
          центр. Опытные мастера проведут необходимые манипуляции, чтобы
          аккуратно извлечь отработавшую свой срок деталь и установить новую.
          Именно поэтому, если вы:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Устали от постоянных выключений аппарата
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Вынуждены подзаряжать Айфон каждые 3-4 часа
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Не можете включить смартфон
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Обращайтесь к нашим специалистам, и мы решим эту проблему. Изначально
          специалисты проведут диагностику и проанализируют причины поломки. Мы
          не только восстановим устройство, но и предоставим все необходимые
          рекомендации, позволяющие значительно продлить период использования
          нового аккумулятора.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkBatteryModels5c = () => {
  return (
    <Page title="Недорогая замена аккумулятора iPhone 5с в сервисе в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-akkumuljatora/5c"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена аккумулятора iPhone 5с – это просто и недорого, если вы обратитесь в сервисный центр Oh!MyGadget!. Мы обеспечим персональное внимание и все гарантии."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена аккумулятора iPhone 5с"
          titleImg="Замена аккумулятора iPhone 5с"
          banner={banner}
          title="Замена аккумулятора iPhone 5с"
          text="Айфон – устройство, которое выбирают за функциональность, стабильную работу и другие преимущества. Вот только любая техника имеет свой срок службы, и он не может быть вечным. Уже спустя два года после приобретения смартфона, необходимо позаботиться о замене аккумулятора iPhone 5с – в Москве вы сможете получить эту услугу в сервисном центре Мак Профи."
        />
        <Section className="section_group" style={{ padding: "31px 0 5px" }}>
          {textOfBattery5c.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairApplePrice
          subtitle="Сколько стоит замена батареи iPhone 5с"
          text="Замена аккумулятора"
          price="1500 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Стоимость предоставления услуги зависит от сложности предстоящей
                работы и цены выбранных комплектующих. Мы предложим вам выгодные
                условия, а специальные скидки для постоянных клиентов сделают
                расценки еще более лояльными.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Мы восстановим ваш смартфон в течение 20-30 минут – вы сможете
                понаблюдать за процессом замены батареи iPhone 5с. Все детали
                уже находятся в наличии, благодаря чему в сервисном центре Мак
                Профи вы можете получить срочный ремонт, без задержек и
                ожидания.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForBattery} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkBatteryModels5c;
