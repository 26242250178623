import React from "react";
import Section from "../../Section/Section";
// import samsungBanner from "../../../images/banners/center-mobile-samsung-banner.png";
import styles from "./CenterMobileIRepairMobileBanner.module.css";

function CenterMobileIRepairMobileBanner({ image, alt, title }) {
  return (
    <Section>
      <img src={image} alt={alt} title={title} className={styles.banner} />
    </Section>
  );
}

export default CenterMobileIRepairMobileBanner;
