import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames/bind";
import useFormWithValidation from "../../hooks/useFormWithValidation";
import { useUser } from "../../providers/UserProvider";
import { auth } from "../../utils/api";
import { Preloader, PopupError } from "../index";
import usePopup from "../../hooks/usePopup";

const LoginForm = ({ formTitle, className, formClassName }) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePassword = () => {
    setIsPasswordShown((isPasswordShown) => !isPasswordShown);
  };

  const navigate = useNavigate();

  const { values, handleChange, isValid, resetForm } = useFormWithValidation({
    login: "",
    password: "",
  });
  const { setUser } = useUser();
  const { isPopupOpen, setIsPopupOpen, togglePopup, closePopup } = usePopup();

  const isDisabled = values.login === "" || values.password === "" || !isValid;

  const loginButtonClassName = !isDisabled
    ? "form__button"
    : "form__button form__button-disabled";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!isDisabled) {
      try {
        const user = await auth.authorize(values.login, values.password);
        setUser(user);
        setTimeout(() => setLoading(false), 1500);

        return navigate("/admin-page", { replace: true });
      } catch (error) {
        if (error.message === "Ошибка: 401") {
          setMessage("Проверьте логин и пароль");
        } else if (error.message === "Ошибка: 500") {
          setMessage("Произошла ошибка на сервере. Повторите попытку позже");
        } else {
          setMessage("Произошла ошибка");
        }
        setTimeout(() => setLoading(false), 1500);
        setTimeout(() => setIsPopupOpen(true), 1800);
        setIsError(true);
      }
    }
    setTimeout(() => setLoading(false), 1500);
    resetForm();
  };

  return (
    <>
      {loading && <Preloader overlay={true} />}
      <form
        className={cn("form", className)}
        action=""
        method="post"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <h2 className="form__title">{formTitle}</h2>
        <fieldset className={cn("form__login", formClassName)}>
          <label htmlFor="login" className="form__label">
            Логин
          </label>
          <input
            autoFocus
            id="admin-login"
            name="login"
            type="text"
            className="form__field"
            required={true}
            tabIndex={1}
            placeholder=""
            value={values.login || ""}
            onChange={handleChange}
          />
          <label htmlFor="password" className="form__label">
            Пароль
          </label>
          <input
            id="password"
            name="password"
            type={isPasswordShown ? "text" : "password"}
            className="form__field"
            required={true}
            tabIndex={2}
            minLength={6}
            placeholder=""
            value={values.password || ""}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="form__wrapper">
            <button
              type="button"
              onClick={togglePassword}
              className={
                isPasswordShown
                  ? cn("form__password", "form__password_type_shown")
                  : "form__password"
              }
            />
          </span>
        </fieldset>
        <button type="submit" className={loginButtonClassName} tabIndex={3}>
          Войти
        </button>
      </form>
      {isError && (
        <PopupError
          isOpenPopup={isPopupOpen}
          message={message}
          onClose={closePopup}
          togglePopup={togglePopup}
        />
      )}
    </>
  );
};

export default LoginForm;
