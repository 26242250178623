import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  RepairAppleLinksList,
  RepairAppleLinksText,
  RepairMacBookLinks,
  MacProfiList,
  Page,
  BreadCrumbs,
  Section,
} from "../index";
import banner from "../../images/banners/mb-battery.jpeg";
import { Helmet } from "react-helmet";

const listOfBattery = [
  {
    id: 1,
    number: "01",
    subtitle: "Аккумулятор MacBook требует обслуживания.",
    textLi:
      "Если данное сообщение выдается в Mac OS, это означает, что количество циклов зарядки аккумулятора подходит к концу и аккумуляторную батарею необходимо заменить на новую, либо восстановить.",
  },
  {
    id: 2,
    number: "02",
    subtitle: "Аккумуляторная батарея MacBook требует замены.",
    textLi:
      "Данное сообщение Mac OS сигнализирует о том, что аккумуляторная батарея неисправна. Очевидно, в данном случае требуется ее замена на новую.",
  },
  {
    id: 3,
    number: "03",
    subtitle: "Аккумулятор MacBook не держит заряд.",
    textLi: (
      <>
        Аккумуляторная батарея MacBook может потерять свою емкость и перестать
        держать заряд по нескольким причинам: использование неоригинального
        зарядного устройства, следствие залития жидкостью{" "}
        <Link
          to="/remont-apple/remont-macbook/zalil_macbook"
          className="repair-mb__group-links-array"
        >
          (подробнее)
        </Link>
        , механического повреждения. В данном случае аккумуляторная батарея
        также подлежит замене.
      </>
    ),
  },
  {
    id: 4,
    number: "04",
    subtitle: "Аккумулятор MacBook вздулся.",
    textLi:
      "Данная проблема характерна при использовании неоригинального зарядного устройства, бракованного аккумулятора, а также использование MacBook в странах с влажным климатом. В данном случае рекомендуем незамедлительно выключить ваш MacBook и заменить аккумуляторную на новую. Использование MacBook с вздутым аккумулятором может привести к возгоранию MacBook!",
  },
];

const textOfBaterry = [
  {
    id: 1,
    title: (
      <>Как происходит процесс замены аккумулятора MacBook на&nbsp;новый</>
    ),
    text: "Специалист сервисного центра аккуратно разбирает ваш MacBook, снимает неисправную аккумуляторную батарею, отправляет ее в емкость для утилизации. Далее специалист устанавливает новую аккумуляторную батарею в ваш MacBook, производит ее тестирование. По желанию клиента производится полная цикличная зарядка-разрядка аккумулятора 24 часа для того, чтобы аккумуляторная батарея набрала свою максимальную емкость и держала заряд максимально долго. После прохождения отдела контроля качества, макбук выдается клиенту, клиент оплачивает аккумулятор и работу по установке.",
  },
  {
    id: 2,
    title: "Стоимость замены аккумулятора MacBook",
    text: "Цены на аккумуляторные батареи постоянно меняются в меньшую сторону, а также зависят от курса доллара. В данном случае мы просим Вас уточнить стоимость аккумуляторной батареи на вашу модель MacBook у наших менеджеров по телефону 8(495) 540-50-67. Наши менеджеры точно назовут стоимость батареи на момент вашего обращения. Стоимость работы по установке батареи 700 руб.",
  },
  {
    id: 3,
    title: <>Какую гарантию мы предоставляем на&nbsp;аккумулятор для MacBook</>,
    text: "Мак Профи предоставляет гарантию на аккумуляторные батареи от 3-х до 6-и месяцев. Срок гарантии зависит от модели самого аккумулятора и модели вашего MacBook. В гарантийный период, в случае если аккумуляторная батарея оказалась бракованной и не держит заряд, мы бесплатно обменяем аккумулятор на новый. Внимание! Мы рекомендуем заряжать аккумуляторную батарею только оригинальными зарядными устройствами, зарядка аккумулятора неоригинальным зарядным устройством приводит к потери емкости аккумулятора и выхода его из строя.",
  },
];

const RepairMacBookLinkBattery = () => {
  return (
    <Page title="Замена аккумулятора MacBook, Pro, Air, Retina">
      <main className="repair-mb__group-links-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-macbook/zamena_akkumuljatora_macbook"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Осуществляем замену и установку новых аккумуляторных батарей на MacBook всех моделей и годов выпуска. Предоставляем гарантию на батарею и установку батареи в ваш MacBook!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Замена аккумулятора MacBook, Pro, Air, Retina"
          text="Производим замену аккумуляторной батареи на MacBook. В каких случаях требуется замена аккумулятора MacBook:"
          alt="замена аккумулятора MacBook"
          titleImg="замена аккумулятора MacBook"
        />
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {listOfBattery.map((item) => {
              return (
                <RepairAppleLinksList
                  key={item.id}
                  number={item.number}
                  subtitle={item.subtitle}
                  textLi={item.textLi}
                />
              );
            })}
          </div>
        </Section>
        <Section className="section_group">
          <div className="repair-mb__group-list-container">
            {textOfBaterry.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairMacBookLinks />
      </main>
    </Page>
  );
};

export default RepairMacBookLinkBattery;

// toDo:
// импортировать банер из индекса
// перенести списки в константы
