import React from 'react';
import { FooterNav } from '../index';

const Footer = () => {
  let today = new Date();

  return (
    <footer className='footer'>
      <FooterNav />
      <div className='footer__content'>
        <p className='footer__copyright'>
          &copy; Oh!MyGadget! Технокампус восстановления. Бренд зарегистрирован, все права защищены. 2016 - {today.getFullYear()}
        </p>
        <p className='footer__copyright'>
          РА "РОССТ"
        </p>
      </div>
    </footer>
  )
};

export default Footer;
