import React from 'react';
import { NavLink } from 'react-router-dom';
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import Section from '../Section/Section';

const links = [
  {
    id: 1,
    href: '/remont-apple/remont-ipad/zamena-akkumuljatora',
    text: 'Замена аккумулятора iPad'
  },
  {
    id: 2,
    href: '/remont-apple/remont-ipad/zamena-stekla',
    text: 'Замена стекла iPad'
  },
  {
    id: 3,
    href: '/remont-apple/remont-ipad/zamena-kamery',
    text: 'Замена камеры iPad'
  },
  {
    id: 4,
    href: '/remont-apple/remont-ipad/zamena-korpusa',
    text: 'Замена корпуса iPad'
  },
  {
    id: 5,
    href: '/remont-apple/remont-ipad/ne-vidit-sim-karty',
    text: 'iPad не видит сим карты'
  },
  {
    id: 6,
    href: '/remont-apple/remont-ipad/ne-vkljuchaetsja',
    text: 'iPad не включается'
  },
  {
    id: 7,
    href: '/remont-apple/remont-ipad/zamena-dinamika',
    text: 'Замена динамика iPad'
  },
  {
    id: 8,
    href: '/remont-apple/remont-ipad/ne-lovit-set',
    text: 'iPad не ловит сеть'
  },
]

const RepairIpadServiceLinks = () => {
  return (
    <Section className='repair-iphone__services-links-container'>
      <div className='repair-iphone__services-links-wrapper'>
        <h2 className='repair-iphone__services-links-title'>Подробнее по предоставляемым услугам:</h2>
        <ul className='repair-iphone__services-links-grid'>
          {links.map((item) => {
            return (
              <li key={item.id} className="repair-iphone__services-links-item">
                <img src={IconCheckMark} alt="иконка галочки" className="repair-iphone__services-links--icon" />
                <NavLink className='repair-iphone__services-links' to={item.href}>{item.text}</NavLink>
              </li >
            )
          })}
        </ul>
      </div>
    </Section>
  )
};

export default RepairIpadServiceLinks;