import React from "react";
import Carousel from "react-elastic-carousel";
import NoteExpertReason from "./NoteExpertReason";
import styles from "./NoteExpertGeneralPageReasonsCarousel.module.css";

const breakPoints = [
  { width: 1160, itemsToShow: 1 },
  { width: 720, itemsToShow: 2 },
];

function NoteExpertGeneralPageReasonsCarousel({ elements }) {
  return (
    <ul className={styles.list}>
      <Carousel breakPoints={breakPoints}>
        {elements.map((item, i) => {
          return (
            <NoteExpertReason
              key={i}
              icon={item.icon}
              subtitle={item.subtitle}
              text1={item.text1}
              text2={item.text2}
              text3={item.text3}
            />
          );
        })}
      </Carousel>
    </ul>
  );
}

export default NoteExpertGeneralPageReasonsCarousel;
