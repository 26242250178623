import React from 'react';
import { Link } from 'react-router-dom';
import { CompanyCard, Section } from '../index';
import noteExpert from '../../images/logos/logo-notexpert-mobile.svg';
import macprofi from '../../images/logos/logo-macprofi-mobile.svg';
import centerMobile from '../../images/logos/logo-centermobile-mobile.svg';

const companies = [
  {
    src: noteExpert,
    text: 'Ремонт любых ноутбуков',
    link: '/note-expert',
    color: '#5E78E9'
  },
  {
    src: macprofi,
    text: 'Ремонт всей техники Apple',
    link: '/remont-apple',
    color: '#BA59F6'
  },
  {
    src: centerMobile,
    text: 'Ремонт планшетов и смартфонов',
    link: '/center-mobile',
    color: '#00E593'
  },
]

const OurCompaniesMobile = () => {
  return (
    <Section className='companies-mobile'>
      <div className='companies-mobile__container'>
        <h2 className='companies-mobile__title'>Ремонт телефонов, ноутбуков, планшетов, моноблоков</h2>
        <ul className='companies-mobile__list'>
          {companies.map((item, i) => {
            return (
              <li key={i} className='companies-mobile__item'>
                <div className='companies-mobile__logo'>
                  <img src={item.src} alt=''/>
                </div>
                <h4 className='companies-mobile__text'>{item.text}</h4>
                <Link className='companies-mobile__link' to={item.link} style={{backgroundColor: item.color}}>Перейти</Link>
              </li>
            )})}
        </ul>
      </div>
    </Section>
  )
}

export default OurCompaniesMobile;