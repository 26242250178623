import React from "react";
import styles from "./CenterMobileIRepairMobileAdvantagesTabletScreen.module.css";

function Advantage(props) {
  return (
    <div className={styles.box}>
      <div className={styles.image}>
        <img src={props.img} alt="иконка" />
      </div>
      <h4 className={styles.boxTitle}>{props.title}</h4>
      <p className={styles.boxText}>{props.text}</p>
    </div>
  );
}

export default Advantage;
