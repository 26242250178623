import React from "react";
import {
  MacProfiList,
  BreadCrumbs,
  Page,
  RepairMacMiniBanner,
  RepairMacMiniText,
  RepairMacMiniTitle,
} from "../index";
import { Helmet } from "react-helmet";

const RepairMacMiniPage = () => {
  return (
    <main className="repair-mac-mini__page">
      <Helmet>
        <link
          rel="canonical"
          href="https://ohmygadget.ru/remont-apple/remont_mac_mini"
        />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Производим качественный ремонт Apple Mac Mini в Москве. Полная информация по ремонту Mac Mini, гарантия, сроки ремонта, цены."
        />
      </Helmet>
      <Page title="Срочный ремонт Mac Mini в сервисном центре Oh!MyGadget!">
        <MacProfiList />
        <BreadCrumbs />
        <RepairMacMiniBanner />
        <RepairMacMiniTitle />
        <RepairMacMiniText />
      </Page>
    </main>
  );
};

export default RepairMacMiniPage;
