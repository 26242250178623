import React from "react";
import Section from "../../Section/Section";
import { Carousel } from "react-responsive-carousel";
import phoneWorked from "../../../images/phone-worked.svg";
import padWorked from "../../../images/pad-worked.svg";
import { Link } from "react-router-dom";
import styles from "./CenterMobileGeneralPageGadgetsMobile.module.css";

const mobileLinks = [
  {
    text: "SAMSUNG",
    to: "remont-smartfonov-samsung",
  },
  {
    text: "LENOVO",
    to: "remont-smartfonov-lenovo",
  },
  {
    text: "NOKIA",
    to: "remont-smartfonov-nokia",
  },
  {
    text: "PHILIPS",
    to: "remont-smartfonov-philips",
  },
  {
    text: "FLY",
    to: "remont-smartfonov-fly",
  },
  {
    text: "LG",
    to: "remont-smartfonov-lg",
  },
  {
    text: "HTC",
    to: "remont-smartfonov-htc",
  },
  {
    text: "SONY",
    to: "remont-smartfonov-sony",
  },
];

const padLinks = [
  {
    text: "SAMSUNG",
    to: "remont-planshetov-samsung",
  },
  {
    text: "ASUS",
    to: "remont-planshetov-asus",
  },
  {
    text: "PRESTIGIO",
    to: "remont-planshetov-prestigio",
  },
  {
    text: "LENOVO",
    to: "remont-planshetov-lenovo",
  },
  {
    text: "EXPLAY",
    to: "remont-planshetov-explay",
  },
  {
    text: "DIGMA",
    to: "remont-planshetov-digma",
  },
  {
    text: "ACER",
    to: "remont-planshetov-acer",
  },
  {
    text: "SONY",
    to: "remont-planshetov-sony",
  },
];

function CenterMobileGeneralPageGadgetsMobile() {
  return (
    <Section className={styles.gadgetSection}>
      <div className={styles.content}>
        <h2 className={styles.title}>Какое у васустройство?</h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className={styles.wrapperImage}>
            <p className={styles.gadget}>Смартфон</p>
            <img
              src={phoneWorked}
              alt="картинка телефона"
              style={{ width: 107, height: 218 }}
            />
          </div>

          <div>
            <ul className={styles.list}>
              {mobileLinks.map((link, i) => {
                return (
                  <li key={i} className={styles.item}>
                    <Link className={styles.link} to={link.to}>
                      {link.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className={styles.wrapperImagePad}>
            <p className={styles.gadget}>Планшет</p>
            <img
              src={padWorked}
              alt="картинка телефона"
              style={{ width: 176, height: 232 }}
            />
          </div>

          <div>
            <ul className={styles.list}>
              {padLinks.map((link, i) => {
                return (
                  <li key={i} className={styles.item}>
                    <Link className={styles.link} to={link.to}>
                      {link.text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
      </div>
    </Section>
  );
}

export default CenterMobileGeneralPageGadgetsMobile;
