import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AncorLinks, PageLinks } from '../index';

const NavList = () => {
  const location = useLocation();

  return (
    <nav className='nav'>
      <div className='nav__container'>
        <ul className='nav__items'>
          { location.pathname === '/' ?
          <>
          <AncorLinks />
          <PageLinks />
          </>
          :
          <>
          <li>
            <Link className='nav__item' to='/'>Главная</Link>
          </li>
          <PageLinks />
          </>
          }
        </ul>
      </div>
    </nav>
  )
};

export default NavList;
