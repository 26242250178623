import React from "react";

const Popup = ({ onClose, isOpenPopup, children }) => {
  return (
    <div className={`modal ${isOpenPopup ? "modal_is-opened" : ""}`}>
      <div className="modal__content">
        <button
          className="modal__close"
          type="button"
          aria-label="закрыть модальное окно"
          onClick={onClose}
        >
          <svg
            fill="#fff"
            width="54"
            height="54"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M28.286 36.659a1 1 0 0 1-.691-1.723A305.23 305.23 0 0 0 35 27.642a1 1 0 1 1 1.43 1.398 307.316 307.316 0 0 1-7.453 7.342.999.999 0 0 1-.691.277z" />
            <path d="M35.714 36.659a.997.997 0 0 1-.691-.277 309.15 309.15 0 0 1-7.453-7.342A1 1 0 1 1 29 27.642a305.23 305.23 0 0 0 7.405 7.294 1 1 0 0 1-.691 1.723z" />
            <path d="M40.835 47.818c-.582 0-1.97-.008-3.751-.018-4.4-.025-11.215-.065-14.206-.03h-.001c-3.324 0-7.83-2.253-7.842-6.482-.006-2.208.012-4.117.029-5.955.027-2.926.053-5.69-.024-9.208-.129-5.882 2.456-9.413 7.092-9.688 2.534-.151 7.256-.256 11.484-.256 4.166 0 9.432.094 10.863.544 2.574.808 4.038 3.591 4.308 5.835.31 2.564.142 11.354.052 16.077-.026 1.34-.045 2.346-.045 2.772 0 1.804-.802 3.443-2.259 4.618-1.454 1.172-3.426 1.791-5.7 1.791zm-14.744-2.06c3.355 0 7.813.025 11.004.043 1.776.01 3.159.018 3.74.018 1.787 0 3.366-.479 4.445-1.348.991-.798 1.514-1.856 1.514-3.059 0-.433.019-1.453.045-2.812.089-4.674.255-13.374-.038-15.8-.202-1.678-1.271-3.648-2.921-4.166-.696-.219-3.758-.452-10.265-.452-4.193 0-8.866.104-11.366.252-4.439.264-5.284 4.305-5.21 7.648.078 3.549.052 6.328.024 9.271-.017 1.831-.035 3.731-.029 5.93.008 2.89 3.416 4.489 5.768 4.489.885-.01 2.006-.014 3.289-.014z" />
          </svg>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
