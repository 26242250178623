import React from "react";
import { Link } from "react-router-dom";

const pageLinks = [
  {
    id: 1,
    href: "/klientam-strahovyh",
    text: "Клиентам страховых",
  },
  {
    id: 2,
    href: "/dostavka",
    text: "Доставка",
  },
  {
    id: 3,
    href: "/sotrudnichestvo",
    text: "Сотрудничество",
  },
  {
    id: 4,
    href: "/kontakty",
    text: "Контакты",
  },
];

const PageLinksMobile = ({ setActive }) => {
  return (
    <>
      {pageLinks.map((item) => {
        return (
          <li className="burger-menu__item" key={item.id}>
            <Link
              className="burger-menu__link"
              to={item.href}
              onClick={() => setActive(false)}
            >
              {item.text}
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default PageLinksMobile;
