import React, { useState } from "react";
import Section from "../Section/Section";
import Select, { components } from "react-select";
import vector from "../../images/icons/Vector.svg";

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    <img
      alt=""
      src={vector}
      className="repair-apple-table__price-padding-img"
    />
  </components.IndicatorsContainer>
);

const RepairAppleTableMobile = ({ options, serviceText }) => {
  const [value, setValue] = useState("");

  const handleSelectChange = (options) => {
    setValue(options);
  };

  return (
    <Section>
      <div className="repair-apple-table-mobile__content">
        <Select
          components={{ IndicatorsContainer }}
          options={options}
          onChange={handleSelectChange}
          placeholder="Выберите модель"
          styles={{
            container: (styles) => ({
              ...styles,
              width: 377,
              fontFamily: "Open Sans, sans-serif",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "10px",
              lineHeight: "22px",
              color: "#88898D;",
              boxShadow: "0px 0px 93.035px rgba(0, 0, 0, 0.05)",
              margin: "0 auto 8px",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 370,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 161,
                fontWeight: "400",
                fontSize: "10px",
              },
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingLeft: 32,
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                paddingLeft: 18,
              },
            }),
            control: (styles) => ({
              ...styles,
              borderRadius: 13,
              borderColor: null,
              border: "1px solid #D9D9D9",
              boxShadow: "0px 0px 93.035px rgba(0, 0, 0, 0.05)",
              "&:hover": null,
            }),
            option: (styles, state) => ({
              ...styles,
              fontFamily: "Open Sans, sans-serif",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "19px",
              color: "#88898D",
              borderRadius: 6,
              marginLeft: 7,
              marginRight: 7,
              width: 440,
              backgroundColor: state.isSelected
                ? "rgba(2, 143, 232, 0.06)"
                : styles.backgroundColor,
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 320,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 255,
              },
            }),
            menuList: (styles) => ({
              ...styles,
              width: 472,
              backgroundColor: "#fff",
              borderRadius: 20,
              maxHeight: 232,
              overflow: "auto",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 350,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 272,
              },
            }),
            menu: (styles) => ({
              ...styles,
              width: 472,
              maxHeight: 232,
              borderRadius: 6,
              boxShadow: "0px 14px 24px rgba(18, 68, 100, 0.2)",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              "@media only screen and (max-width: 720px)": {
                ...styles["@media only screen and (max-width: 720px)"],
                width: 350,
              },
              "@media only screen and (max-width: 500px)": {
                ...styles["@media only screen and (max-width: 500px)"],
                width: 270,
              },
            }),
          }}
        />
        <div className="repair-apple-table-mobile__titles">
          <h4 className="repair-apple-table-mobile__title repair-apple-table-mobile__title-name">
            {serviceText}
          </h4>
          <div className="repair-apple-table-mobile__titles-wrapper">
            <h4 className="repair-apple-table-mobile__title repair-apple-table-mobile__title-time">
              Сроки ремонта
            </h4>
            <h4 className="repair-apple-table-mobile__title repair-apple-table-mobile__title-price">
              Стоимость ремонта
            </h4>
          </div>
        </div>
        {value ? (
          <ul className="repair-apple-table-mobile__list">
            {value.list.map((item, i) => {
              return (
                <li key={i} className="repair-apple-table-mobile__item">
                  <p className="repair-apple-table-mobile__text-name">
                    {item.service}
                  </p>
                  <div className="repair-apple-table-mobile__titles-wrapper">
                    <p className="repair-apple-table-mobile__text-time">
                      {item.time}
                    </p>
                    <p className="repair-apple-table-mobile__price">
                      {item.price}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </Section>
  );
};

export default RepairAppleTableMobile;
