import { myFetch } from "./myFetch";

export const getUsers = (params = {}) => {
  const queryParams = new URLSearchParams(params);
  return myFetch(`users?${queryParams}`);
};

export const getUserById = (userId) => {
  return myFetch(`users/${userId}`);
};

export const changeUserData = (userId, data) => {
  return myFetch(`users/${userId}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
};

export const addNewUser = (data) => {
  return myFetch(`users`, {
    method: "POST",
    body: JSON.stringify({
      login: data.login,
      email: data.email,
      password: data.password,
      name: data.name,
      role: data.role,
    }),
  });
};

export const deleteUser = (userId) => {
  return myFetch(`users/${userId}`, { method: "DELETE" });
};

export const deleteUsers = (usersIds) => {
  return myFetch(`users`, {
    method: "DELETE",
    body: JSON.stringify({ users: usersIds }),
  });
};
