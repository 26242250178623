import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairIphoneModelLinksForServiceMobile,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/speaker6.jpeg";
import { listOfModelForSpeaker } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const RepairIphoneServiceLinkSpeakerModels6 = () => {
  return (
    <Page title="Цена на замену динамика iPhone 6 в Oh!MyGadget!">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-dinamika/6"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена динамика iPhone 6 позволит снова наслаждаться звуком. Мастерская Oh!MyGadget! гарантирует результат, выгодные цены, качество."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена динамика iPhone 6"
          titleImg="Замена динамика iPhone 6"
          banner={banner}
          title="Замена динамика iPhone 6"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Проблемы с динамиком Айфона диагностируются довольно легко. Это
                распространенная поломка, визит с которой в мастерскую вовсе не
                является редкостью. Однако, к счастью, она вполне легко
                устраняется, замена позволяет продолжить нормальную эксплуатацию
                устройства, способного прослужить еще долго. Как правило, о
                данной поломке свидетельствуют такие симптомы:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Невозможность прослушивания музыки без помощи наушников,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Проблемы со слышимостью собеседника при разговоре,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Полная «тишина» без использования вспомогательных устройств.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Все это, а также любые дефекты звука, к примеру, хрипы на низких
                частотах, могут говорить о том, что требуется замена динамика
                iPhone 6, и путем реализации такого ремонта действительно
                удается устранить неисправность. Однако почему же такие поломки
                происходят, и что можно сделать для их избежания? Ответить на
                эти вопросы тоже не сложно. Поломка может произойти при ударе
                или падении аппаратуры, из-за износа или попадания воды. Чтобы
                уберечь девайс, следует обращаться с ним аккуратно, использовать
                чехол, не включать звук на максимум и избегать попадания воды.
              </p>
              <p className="repair-iphone-display__paragraph">
                Однако если поломка уже произошла, необходимо принять
                соответствующие меры – отправиться в профессиональную
                мастерскую, и заказать услуги по замене, и не пытаться выполнить
                такие работы своими силами или с привлечением некомпетентных
                лиц. Стоит помнить, что Айфон – это высокотехнологичный и весьма
                чувствительный девайс, и непрофессиональный подход к его ремонту
                может привести к полному выходу из строя и необходимости
                приобретения нового. Ремонт обойдется куда дешевле.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          title="Срочная замена динамика iPhone 6 в Мак Профи"
          textUp="Замена слухового динамика"
          textDown="Замена полифонического динамика"
          priceUp="1200 P"
          priceDown="1200 P"
          paragraph={
            <>
              <p className="repair-iphone-display__paragraph">
                Если вы столкнулись с проблемами в работе динамика Айфона, не
                стоит переживать. Ремонт возможен, и неисправности динамика
                могут быть устранены быстро и легко, по невысокой цене. Наши
                специалисты успешно справляются с такими задачами не первый год
                – мастерская работает давно и имеет прекрасную репутацию, массу
                положительных отзывов. Мы успешно справляемся с самыми разными
                проблемами и всегда добиваемся необходимого результата.
                Сервисный центр работает официально, предоставляет гарантии и
                все документы, с полной ответственностью выполняет взятые
                обязательства.
              </p>
              <p className="repair-iphone-display__paragraph">
                Результативность работ обуславливается в первую очередь
                компетентным персоналом – у нас работают только специалисты со
                стажем, профильным образованием, которые отлично разбираются в
                устройстве высокотехнологичных новинок. Под рукой у них всегда
                есть необходимые запчасти высокого качества – мы используем
                только фирменные детали официального производства, и даем на них
                гарантию. Мастерская оснащена по последнему слову техники, у нас
                есть все необходимое оборудование для быстрого и успешного
                проведения сложнейших операций, и результат нашей работы
                оказывается качественным даже в расчете на долгий срок.
              </p>
              <p className="repair-iphone-display__paragraph repair-iphone-display__margin-bottom">
                Если вы столкнулись с поломкой, обращайтесь в нашу мастерскую
                Мак Профи. В первую очередь мы проведем экспресс-диагностику,
                которая осуществляется бесплатно и позволяет найти источник
                проблем. После проведения этого мероприятия, которое отнимет
                буквально несколько минут, вы сможете узнать, что именно
                случилось с Айфоном, и сколько будет стоить его ремонт. Мы
                придерживаемся политики разумных цен, и потому с переплатами вы
                не столкнетесь, а что касается скорости выполнения – она отнимет
                несколько часов, и выполнить ее можно как в обычном, так и в
                экспресс-режиме. Обращаясь к нам, вы доверите оборудование
                надежному исполнителю, и получите прекрасный результат, который
                позволит еще долго пользоваться устройством.
              </p>
            </>
          }
        />
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForSpeaker} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkSpeakerModels6;
