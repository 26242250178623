import React from "react";
import { Carousel } from "react-responsive-carousel";
import Section from "../Section/Section";

const firstSlide = [
  {
    id: 1,
    text: "Потоковую обработку большого количества как розничной, так и корпоративной техники",
  },
  {
    id: 2,
    text: "Быструю аппаратную диагностику",
  },
  {
    id: 3,
    text: "BGA пайку любого уровня сложности",
  },
  {
    id: 4,
    text: "Ремонт залитых аппаратов",
  },
];

const secondSlide = [
  {
    id: 1,
    text: "Ремонт после механического повреждения",
  },
  {
    id: 2,
    text: "Ремонт техники в состоянии “не включается",
  },
  {
    id: 3,
    text: "Наличие всех комплектующих для ремонта",
  },
  {
    id: 4,
    text: "Онлайн базу с полной информацией о статусе ремонта, четкой и подробной диагностикой вашей техники, полный список цен перед произведением ремонта",
  },
];

const thirdSlide = [
  {
    id: 1,
    text: "Отсрочку платежа",
  },
  {
    id: 2,
    text: "Персонального менеджера",
  },
  {
    id: 3,
    text: "Четко структурированную работу",
  },
  {
    id: 4,
    text: "Легкую коммуникацию через собственную онлайн базу",
  },
];

const CooperationOffersMobile = () => {
  return (
    <Section>
      <div className="cooperation-mobile__contant">
        <h2 className="cooperation-mobile__title">Мы можем предложить</h2>
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div className="cooperation-mobile__carousel-box">
            <ul className="cooperation-mobile__carousel-list">
              {firstSlide.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="cooperation-mobile__carousel-item"
                  >
                    <div className="cooperation-mobile__carousel-icon"></div>
                    <p className="cooperation-mobile__carousel-text">
                      {item.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="cooperation-mobile__carousel-box">
            <ul className="cooperation-mobile__carousel-list">
              {secondSlide.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="cooperation-mobile__carousel-item"
                  >
                    <div className="cooperation-mobile__carousel-icon"></div>
                    <p className="cooperation-mobile__carousel-text">
                      {item.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="cooperation-mobile__carousel-box">
            <ul className="cooperation-mobile__carousel-list">
              {thirdSlide.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="cooperation-mobile__carousel-item"
                  >
                    <div className="cooperation-mobile__carousel-icon"></div>
                    <p className="cooperation-mobile__carousel-text">
                      {item.text}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </Carousel>
        <p className="cooperation-mobile__text">
          Мы стараемся сделать нашу работу максимально комфортной для
          сотрудничества.
        </p>
        <p className="cooperation-mobile__text">
          Мы производим ремонт по четко оговоренным ценам, оптимальная цена на
          комплектующие, минимальные сроки ремонта.
        </p>
      </div>
    </Section>
  );
};

export default CooperationOffersMobile;
