import React from "react";

function NoteExpertVideoMobile(props) {
  return (
    <li>
      <iframe
        id="ytplayer"
        type="text/html"
        width="274"
        height="156"
        src={props.link}
        style={{ borderRadius: 8 }}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </li>
  );
}

export default NoteExpertVideoMobile;
