import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera7plus.jpeg";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCamera7Plus = [
  {
    id: 1,
    title: "Как проходит замена камеры iPhone 7 Plus?",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          При отсутствии дополнительных внутренних повреждений, процедура
          установки новой камеры занимает минимум времени. Мастер выполнит
          следующие работы:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Проведет диагностику;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Удалит защитные болты и вскроет устройство;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Бережно достанет защитные пластины, шлейфы и разъемы, которые
              мешают добраться до неисправной детали;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">04</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Вытащит поломанный модуль и поставит вместо него новую деталь;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">05</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Аккуратно соберет смартфон и предоставит владельцу возможность его
              протестировать.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Включайте смартфон, делайте снимки – и убедитесь, что ремонт был
          выполнен действительно качественно. И только проверив работу мастера,
          вы сможете расплатиться за предоставленные услуги – после проведения
          замены камеры iPhone 7 Plus владельцу будет выдан чек и предоставлен
          заполненный гарантийный талон. Если в течение указанного времени вновь
          возникнут неполадки в работе устройства, вы можете повторно обратиться
          к нам для решения проблемы.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Срочная замена камеры iPhone 7 Plus в сервисе Мак Профи",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Любая техника может сломаться, особенно если обладатель периодически
          нарушает условия её эксплуатации. Но это отнюдь не значит, что
          проблема должна существенно ограничивать функциональность устройства –
          большинство поломок легко устранимы, а ремонт не потребует
          значительных затрат. А обеспечить профессиональный ремонт нам
          помогает:
        </p>
        <ul className="repair-iphone-display__list">
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">01</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Качественные инструменты – мы используем только качественные
              средства, что обеспечивает точность действий;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">02</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Новейшее оборудование – предварительная диагностика дает
              возможность получить достоверную информацию о состоянии смартфона;
            </p>
          </li>
          <li className="repair-iphone-display__item">
            <p className="repair-iphone__models-links-number">03</p>
            <img
              src={IconCheckMark}
              alt="иконка галочки"
              className="repair-iphone__models-links-img"
            />
            <p className="repair-iphone-display__paragraph">
              Гарантии качества – вы получаете 100% уверенность в том, что
              установленный модуль порадует продолжительной работой.
            </p>
          </li>
        </ul>
        <p className="repair-iphone-display__paragraph-down">
          Если вы выбираете надежность и профессионализм, не желая доверять
          замену камеры iPhone 7 Plus частным мастерам, которые не способны
          гарантировать качество предоставляемых услуг, то обращайтесь в Мак
          Профи – и ваши снимки вновь будут яркими, красочными и удивительно
          четкими.
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels7Plus = () => {
  return (
    <Page title="Замена камеры iPhone 7 Plus в Москве по доступной цене">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/7-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Срочная замена камеры iPhone 7 Plus: низкие цены, минимальные сроки, бесплатная диагностика"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone 7 Plus"
          titleImg="Замена камеры iPhone 7 Plus"
          banner={banner}
          title="Замена камеры iPhone 7 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Замена камеры iPhone 7 Plus в Москве: полный комплекс услуг в
                сервисе Мак Профи. Качественный ремонт с гарантией,
                профессиональный подход, быстрая замена комплектующих.
              </p>
              <p className="repair-iphone-display__paragraph">
                Отличная камера – истинная гордость владельцев iPhone 7 Plus, а
                потому её поломка доставит немало неудобств. Для фронтального и
                основного устройства используются разные шлейфы, благодаря чему
                они невзаимосвязаны, но все равно равнозначной замены не
                получится – поэтому при выходе одной из камер, потребуется
                обратиться к квалифицированным мастерам. И чтобы получить полный
                комплекс услуг на выгодных условиях, посетите сервисный центр
                Мак Профи – всего 20 минут, и вы сможете отправиться домой с
                обновленным смартфоном.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="3700 P"
          priceDown="3000 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera7Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels7Plus;
