import React from "react";
import { Section } from "../index";

const RepairMacMiniTitle = () => {
  return (
    <Section>
      <div className="repair-mac-mini__title-container">
        <article>
          <h1 className="repair-mac-mini__title-title">Ремонт Mac Mini</h1>
          <div className="repair-mac-mini__title-border"></div>
          <p className="repair-mac-mini__title-paragraph">
            Сервисный центр Мак Профи профессионально подходит к вопросу ремонта
            Mac Mini. Мы производим ремонт как на аппаратном, так и на
            программном уровне. Даже при самых значительных поломках, наши
            специалисты в 95% случаев восстанавливают работоспособность Mac
            Mini, в том числе и при серьзных повреждениях материнской платы.
          </p>
        </article>
        <div className="repair-mac-mini__title-icon"></div>
      </div>
    </Section>
  );
};

export default RepairMacMiniTitle;
