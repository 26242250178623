import React from "react";
import { NavLink } from "react-router-dom";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import Section from "../Section/Section";

const links = [
  {
    to: "/remont-apple/remont-iphone/zamena-displeja",
    text: "iPhone замена дисплея",
  },
  {
    to: "/remont-apple/remont-iphone/ne-lovit-set",
    text: "iPhone не ловит сеть",
  },
  {
    to: "/remont-apple/remont-iphone/zamena-knopok",
    text: "iPhone замена кнопок",
  },
  {
    to: "/remont-apple/remont-iphone/zalil_iphone",
    text: "iPhone после залития, ремонт материнской платы iPhone",
  },
  {
    to: "/remont-apple/remont-iphone/zamena-akkumuljatora",
    text: "iPhone замена аккумулятора",
  },
  {
    to: "/remont-apple/remont-iphone/zamena-dinamika",
    text: "iPhone замена динамика",
  },
  {
    to: "/remont-apple/remont-iphone/ne-zarjazhaetsja",
    text: "iPhone не заряжается, не включается",
  },
  {
    to: "/remont-apple/remont-iphone/zamena-kamery",
    text: "iPhone замена камеры",
  },
  {
    to: "/remont-apple/remont-iphone/zamena-mikrofona",
    text: "iPhone замена микрофона",
  },
];

const RepairIphoneServicesLinks = () => {
  return (
    <Section className="repair-iphone__services-links-container">
      <div className="repair-iphone__services-links-wrapper">
        <h2 className="repair-iphone__services-links-title">
          Подробнее по Услугам
        </h2>
        <ul className="repair-iphone__services-links-grid">
          {links.map((link, i) => {
            return (
              <li key={i} className="repair-iphone__services-links-item">
                <img
                  src={IconCheckMark}
                  alt="иконка галочки"
                  className="repair-iphone__services-links--icon"
                />
                <NavLink className="repair-iphone__services-links" to={link.to}>
                  {link.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
};

export default RepairIphoneServicesLinks;
