import React from "react";
import { CompanyCard, Section } from "../index";
import {
  mono,
  laptop,
  phone,
  logoCenterMobile,
  logoMacProfi,
  logoNotEexpert,
} from "../../images/index";

const OurCompanies = () => {
  return (
    <Section className="companies">
      <div className="companies__container">
        <h2 className="companies__title">
          Ремонт телефонов, ноутбуков, планшетов, моноблоков
        </h2>
        <ul className="companies__cards">
          <li className="companies__card">
            <CompanyCard
              title="Ремонт любых ноутбуков"
              imageSrc={laptop}
              src={logoNotEexpert}
              to="/note-expert"
              alt="Ремонт любых ноутбуков"
            />
          </li>
          <li className="companies__card">
            <CompanyCard
              title="Ремонт всей техники Apple"
              imageSrc={mono}
              src={logoMacProfi}
              to="/remont-apple"
              className="card__link_color_purple"
              alt="Ремонт всей техники Apple"
            />
          </li>
          <li className="companies__card">
            <CompanyCard
              title="Ремонт планшетов и смартфонов"
              imageSrc={phone}
              src={logoCenterMobile}
              to="/center-mobile"
              className="card__link_color_green"
              alt="Ремонт планшетов и смартфонов"
            />
          </li>
        </ul>
      </div>
    </Section>
  );
};

export default OurCompanies;
