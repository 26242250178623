import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIpodModelLinks,
} from "../index";
import banner from "../../images/banners/ipodNano5.jpeg";
import { Helmet } from "react-helmet";

const listOfTrouble = [
  {
    id: 1,
    text: "Запас деталей в наличии – вам не придется ждать, пока нужный элемент появиться на складе, благодаря чему большинство распространенных поломок устраняется в течение получаса.",
    number: "01",
  },
  {
    id: 2,
    text: "Бесплатная диагностика – для нас важно понять, что же послужило истинной причиной неисправности, а потому мастер тщательно протестирует устройство и выявит даже скрытые повреждения.",
    number: "02",
  },
  {
    id: 3,
    text: "Профессиональный подход – мы предложим выгодные и рациональные решения возникшей проблемы, чтобы вы могли избежать дополнительных финансовых затрат.",
    number: "03",
  },
  {
    id: 4,
    text: "Современное оснащение сервиса – мы регулярно обновляем используемое оборудование, применяем только фирменные инструменты и восстанавливаем технику с помощью качественных расходных материалов.",
    number: "04",
  },
];

const textIpodNano5 = [
  {
    id: 1,
    title: "Особенности ремонта iPod Touch 5G",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Техника Apple высоко ценится не только за отменные эксплуатационные
          характеристики, но и красоту внешнего вида. И это очень важный момент,
          ведь создателям удалось добиться минимальных размеров устройство,
          дополнив его многочисленными полезными функциями.
        </p>
        <p className="repair-iphone-display__paragraph">
          Серия Touch представляет собой уменьшенную копию Айфона – её
          обладателю доступна возможность выходить в Интернет, снимать фото и
          видео, устанавливать различные приложения и, конечно же, наслаждаться
          любимой музыкой. Лишившись гаджета хотя бы на день, владелец
          испытывает значительные неудобства, поэтом и ремонт iPod Touch 5G
          должен быть быстрым и обязательно качественным, чтобы в ближайшее
          время проблема не повторилась вновь. В Мак Профи вам будут
          предоставлены выбранные услуги, при этом стоимость достаточно лояльна,
          а качество подтверждено соответствующей гарантией.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title:
      "Ремонт iPod Touch 5G в Москве: специальное предложение от Мак Профи",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Мы предлагаем стабильного высокий уровень сервиса – основная часть ремонтов выполняется в присутствии клиента. Именно поэтому большинство посетителей приходят к нам по рекомендации друзей и знакомых – за годы работы наш сервисный центр заслужил немало положительных откликов, ведь мы готовы предложить:"
          array={listOfTrouble}
          paragraphDown="Чтобы узнать подробнее об условиях ремонта iPod Touch 5G, вы можете связаться с нами по указанным на сайте контактным телефонам. Либо же сразу приезжайте в сервисный центр, чтобы провести диагностику и узнать точную сумму и сроки предоставления услуги."
        />
      </>
    ),
  },
];

const RepairIpodModelLinkNano5 = () => {
  return (
    <Page title="Ремонт iPod Touch 5G в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-ipod/5g"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ремонт iPod Touch 5G в Москве. Мы сможем починить Apple Ipod за 20 минут."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Ремонт iPod Touch 5G"
          titleImg="Ремонт iPod Touch 5G"
          banner={banner}
          title="Ремонт iPod Touch 5G"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Сервисный центр Мак Профи: ремонт iPod Touch 5G срочно. Низкие
                цены. Гарантия на работу и комплектующие.
              </p>
              <p className="repair-iphone-display__paragraph">
                Активное использование iPod Touch 5G рано или поздно приведет к
                поломке устройства. Может потребоваться замена дисплея или
                корпуса, либо же перестанут выполнять функционировать основные
                кнопки. К нам регулярно обращаются с подобными просьбами – и в
                Мак Профи каждый посетитель получает квалифицированную помощь.
              </p>
            </>
          }
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textIpodNano5.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIpodModelLinks />
      </main>
    </Page>
  );
};

export default RepairIpodModelLinkNano5;
