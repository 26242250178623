import React from "react";
import Section from "../../Section/Section";
import styles from "./CenterMobileIRepairMobileInfo.module.css";

function CenterMobileIRepairMobileInfo({ title, text }) {
  return (
    <Section>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.border}></div>
        <p className={styles.subtitle}>{text}</p>
      </div>
    </Section>
  );
}

export default CenterMobileIRepairMobileInfo;
