import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  Section,
  RepairAppleDoublePrice,
} from "../index";
import banner from "../../images/banners/camera8plus.png";
import { listOfModelForCamera } from "../../utils/iphoneModels";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Helmet } from "react-helmet";

const textOfCamera8Plus = [
  {
    id: 1,
    title: "Когда необходима замена камеры iPhone 8 Plus в Москве",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если качество изображения резко ухудшилось, фотоматериалы имеют битые
          пиксели и другие видимые дефекты – наверняка речь идет о проблемах с
          камерой. То же самое можно сказать при проблемах с автоматической
          фокусировкой или полным отказом опции фото и видео хотя бы на одну из
          камер. Также могут возникнуть проблемы со вспышкой, но в их
          возникновении чаще всего виновата матрица.
        </p>
        <p className="repair-iphone-display__paragraph">
          Как бы то ни было, но профессионалы в любом случае смогут обнаружить
          источник проблем и устранить его. Вам же перед обращением в сервис
          стоит попробовать протереть камеру и пересмотреть ее настройки,
          перезагрузить Айфон. В некоторых случаях эти манипуляции позволяют
          решить проблему. Но если ничего не изменилось, важно найти достойного
          исполнителя ремонтных работ, который сможет установить и устранить
          проблему.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Когда необходима замена камеры iPhone 8 Plus в Москве",
    text: (
      <>
        <p className="repair-iphone-display__paragraph">
          Если вы замечаете некорректную работу фотокамер устройства, или
          происходит их отказ по тем или иным причинам, крайне важно будет
          обратиться к профессиональному исполнителю, который действительно
          сможет выполнить точную диагностику и избавить технику от проблем
          наиболее эффективным и грамотным образом. Сервисный центр Мак Профи
          специализируется на работе с современной высокотехнологичной
          аппаратурой, наши специалисты имеют богатый опыт и справляются с
          проблемами любой сложности. В процессе выполнения ремонта в первую
          очередь проводится диагностика – для наших клиентов она осуществляется
          бесплатно. Экспресс-подход позволяет локализировать проблему за
          считанные минуты и назвать стоимость и условия ремонта, ответить на
          все вопросы клиента. При вашем согласии ремонт будет начат немедленно,
          он может осуществляться при вашем присутствии в сервисе. Замена будет
          выполнена за считанные часы, а в ряде случаев и куда быстрее. После
          этого техника будет проверена касаемо работоспособности и возвращена
          вам под гарантию на ремонт и запчасти.
        </p>
        <p className="repair-iphone-display__paragraph">
          Мы обеспечиваем нашим клиентам выгодную ценовую политику и оптимальные
          условия сотрудничества. У нас вы сможете получить долгосрочную
          гарантию и серьезнейшее отношение ко всем обязательствам. Сервис
          работает уже много лет, имеет прекрасную репутацию – мы ответственно
          относимся ко всем работам, а опыт позволяет нам оказывать помощь даже
          в самых сложных ситуациях. Работы выполняются быстро, без задержек,
          при необходимости – в срочном порядке. Обращаясь к нам, вы
          гарантируете не только успешный ремонт с оформлением всех документов,
          но и длительный срок беспроблемной работы техники. Обращайтесь, и мы
          избавим Айфон от поломок!
        </p>
      </>
    ),
  },
];

const RepairIphoneServiceLinkCameraModels8Plus = () => {
  return (
    <Page title="Надежная замена камеры на iPhone 8 Plus по низкой цене">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-kamery/8-plus"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Поломка, из-за которой требуется замена камеры iPhone 8 Plus, это вовсе не проблема. Мастерская Oh!MyGadget! выполнит для вас восстановление техники!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена камеры iPhone 8 Plus"
          titleImg="Замена камеры iPhone 8 Plus"
          banner={banner}
          title="Замена камеры iPhone 8 Plus"
          text={
            <>
              <p className="repair-iphone-display__paragraph">
                Айфоны славятся многими своими качествами, и в частности, они
                могут порадовать высоким качеством снимков и видео. Две камеры
                обеспечивают возможность выполнения селфи и обычных снимков, и
                качество в обоих случаях оказывается более чем достойным. Однако
                в ряде случаев камеры перестают давать видео и фото достойного
                качества, а иногда и вовсе отказываются работать и выполнять
                возложенные на них функции. В таких ситуациях речь идет о
                поломке данного узла и необходимости его ремонта, и также иногда
                проблемы могут возникать с матрицей. Существует множество
                причин, из-за которых техника перестает нормально работать, и в
                частности, замена камеры iPhone 8 Plus может потребоваться:
              </p>
              <ul className="repair-iphone-display__list">
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">01</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    {" "}
                    После падения, удара оборудования,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">02</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    При попадании на него воды, утоплении,
                  </p>
                </li>
                <li className="repair-iphone-display__item">
                  <p className="repair-iphone__models-links-number">03</p>
                  <img
                    src={IconCheckMark}
                    alt="иконка галочки"
                    className="repair-iphone__models-links-img"
                  />
                  <p className="repair-iphone-display__paragraph">
                    Из-за заводского брака или по иным причинам.
                  </p>
                </li>
              </ul>
              <p className="repair-iphone-display__paragraph-down">
                Иногда виновными в происходящих проблемах становятся и
                программные сбои. Но в целом же, о поломке камер можно судить в
                следующих ситуациях.
              </p>
            </>
          }
        />
        <RepairAppleDoublePrice
          textUp="Замена основной камеры"
          textDown="Замена передней камеры"
          priceUp="5900 P"
          priceDown="4900 P"
        />
        <Section className="section_group" style={{ paddingBottom: 65 }}>
          {textOfCamera8Plus.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIphoneModelLinksForServiceMobile links={listOfModelForCamera} />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkCameraModels8Plus;
