import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Dialog } from "@headlessui/react";
import ok from "../../images/icons/popup-ok.svg";
import popupCloseIcon from "../../images/icons/popup-close.svg";

const DeliveryFormApplication = ({
  isDeliveryFormApplication,
  setIsDeliveryFormApplication,
}) => {
  // const handleSubmitForm = (e) => {
  //   e.preventDefault();
  //   setIsDeliveryFormApplication(false);
  // };

  const [name, setName] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [nameError, setNameError] = useState("Поле не может быть пустым");

  const [isOpen, setIsOpen] = useState(false);

  const blurHandler = (e) => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
    }
  };

  const nameHandler = (e) => {
    let newState = e.target.value;
    setName(newState);
    if (newState.length < 2) {
      setNameError("Здесь должно быть минимум 2 символа");
      if (!e.target.value) {
        setNameError("Поле не может быть пустым");
      }
    } else {
      setNameError("");
    }
  };

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gdq4iwq",
        "template_jxff54g",
        e.target,
        "EZfZDetK08h7QxQFY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsDeliveryFormApplication(false);
          setIsOpen(true);
          setTimeout(() => {
            setIsOpen(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
    // setIsSubmitSuccessful(true);
    // setTimeout(() => {
    //   setIsSubmitSuccessful(false);
    //   setIsOpen(false);
    // }, 3000);
  }

  return (
    <>
      <form
        onSubmit={sendEmail}
        className={`form-application ${
          isDeliveryFormApplication ? "form-application_is-opened" : ""
        }`}
      >
        <ul className="form-application__content">
          <li className="form-application__inputs">
            <div className="form-application__input-box">
              <label className="form-application__lable">фио</label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => nameHandler(e)}
                onBlur={(e) => blurHandler(e)}
                className="form-application__input"
              ></input>
              {nameDirty && nameError ? (
                <em className="">{nameError}</em>
              ) : (
                <div className=""></div>
              )}
            </div>
            <div className="form-application__input-box">
              <label className="form-application__lable">телефон</label>
              <input name="phone" className="form-application__input"></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-box">
              <label className="form-application__lable">email</label>
              <input
                type="email"
                name="email"
                className="form-application__input"
              ></input>
            </div>
            <div className="form-application__input-box">
              <label className="form-application__lable">серийный номер</label>
              <input name="number" className="form-application__input"></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-big-box">
              <label className="form-application__lable">
                описание проблемы
              </label>
              <input
                type="text"
                name="problem"
                className="form-application__big-input"
              ></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-box">
              <label className="form-application__lable">метро</label>
              <input
                type="text"
                name="metro"
                className="form-application__input"
              ></input>
            </div>
            <div className="form-application__input-box">
              <label className="form-application__lable">улица</label>
              <input name="street" className="form-application__input"></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-box">
              <label className="form-application__lable">дом</label>
              <input
                type="text"
                name="house"
                className="form-application__input"
              ></input>
            </div>
            <div className="form-application__input-box">
              <label className="form-application__lable">корпус</label>
              <input name="build" className="form-application__input"></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-box">
              <label className="form-application__lable">подъезд</label>
              <input
                name="entrance"
                type="text"
                className="form-application__input"
              ></input>
            </div>
            <div className="form-application__input-box">
              <label className="form-application__lable">квартира</label>
              <input
                name="apartment"
                className="form-application__input"
              ></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-box">
              <label className="form-application__lable">этаж</label>
              <input
                name="floor"
                type="text"
                className="form-application__input"
              ></input>
            </div>
            <div className="form-application__input-box">
              <label className="form-application__lable">домофон</label>
              <input
                name="doorphone"
                className="form-application__input"
              ></input>
            </div>
          </li>
          <li className="form-application__inputs">
            <div className="form-application__input-big-box">
              <label className="form-application__lable">
                дополнительные пожелания
              </label>
              <input
                name="additional_requests"
                type="text"
                className="form-application__big-input"
              ></input>
            </div>
          </li>
        </ul>
        <button type="submit" className="form-application__button">
          Оформить заявку
        </button>
      </form>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="form-application__backdrop" aria-hidden="true"></div>
        <div className="form-application__container-popup">
          <div className="form-application__contentSuccess">
            <Dialog.Panel className="form-application__success">
              <img
                src={popupCloseIcon}
                alt="иконка закрытия окна"
                className="center-mobile-general-page__popup-success-close"
                onClick={() => setIsOpen(false)}
              />
              <img
                src={ok}
                className="center-mobile-general-page__popup-success-finger"
                alt="иконка успешной отправки"
              />
              <Dialog.Title className="center-mobile-general-page__popup-success-title">
                Мы вам перезвоним в течении 30 минут!
              </Dialog.Title>
              <p className="center-mobile-general-page__popup-success-subtitle">
                звонки осуществляются только в рабочее время
              </p>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DeliveryFormApplication;

// toDo:
// проверить верстку формы - скорее всего можно сделать смысловое объединение полей
