import React from "react";
import { RepairIphoneModelsLinksMobile, Section } from "../index";
import { listOfModel } from "../../utils/iphoneModels";
import { useMobileScreen } from "../../utils/useMedia";
import IconCheckMark from "../../images/icons/cooper-offers_icon.svg";
import { Link } from "react-router-dom";

function PageLinks() {
  const isMobile = useMobileScreen();
  if (isMobile) {
    return <RepairIphoneModelsLinksMobile />;
  }
  return (
    <Section className="repair-iphone__models-links-container">
      <div className="repair-iphone__models-links-wrapper">
        <h2 className="repair-iphone__models-links-title">
          Больше детальной информации по каждой модели:
        </h2>
        <ul
          className="repair-iphone__models-links-grid"
          style={{ gridTemplateAreas: "col-1 col-2" }}
        >
          {listOfModel.map((link, i) => {
            return (
              <li key={i} className="repair-iphone__models-links-item">
                <img
                  src={IconCheckMark}
                  alt="иконка галочки"
                  className="repair-iphone__models-links-img"
                />
                <Link
                  className="repair-iphone__models-links-link"
                  to={link.href}
                >
                  {link.model}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

const RepairIphoneModelsLinks = () => {
  return PageLinks();
};

export default RepairIphoneModelsLinks;
