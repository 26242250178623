import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "../NoteExpertReasonsLinks/NoteExpertInsidePage.module.css";
import { Helmet } from "react-helmet";

const callbackText = [
  "В процессе замены HDD опытные мастера московского сервисного центра Note Expert подберут для вашего ноутбука жесткий диск нужного производителя с идеально подходящими параметрами (объемом, габаритами, скоростью вращения), установят его в ноутбук после демонтажа старого диска, осуществят корректный перенос данных с вашего прежнего HDD на новый, отформатируют для проверки работоспособности",
  "Замена или установка дополнительного жесткого диска будет выполнена гарантированно качественно и без неприятных для вас последствий.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Внутренние накопители представляют собой функционально и конструктивно законченные устройства для хранения данных, ремонт которых абсолютно нецелесообразен, поэтому при выходе из строя HDD обычно производится его замена на новый накопитель. Для этого ноутбук должен быть подвергнут частичной или полной разборке (в зависимости от модели).",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "И поскольку замена винчестера ноутбука требует специальных знаний и технических навыков, делать это самостоятельно крайне не рекомендуется – вы можете потерять важную информацию, которая хранится на вашем HDD. Доверьте эту операцию экспертам, и вы с максимальной долей вероятности получите качественный результат.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "В сервисном центре Note Expert, предлагающем москвичам полный спектр квалифицированных услуг по ремонту ноутбуков, замена жестких дисков относится к самым востребованным услугам и производится на исключительно высоком профессиональном уровне.",
  },
  {
    icon: priceIcon2,
    text: "Стоимость услуг – самая адекватная, диагностика неисправностей производится бесплатно.",
  },
  {
    icon: priceIcon3,
    text: "Итоговая стоимость работ определяется по результатам диагностики, которая выполняется бесплатно.",
  },
];

const prices = [
  {
    text: "HDD 320 GB",
    price: "2500 руб.",
  },
  {
    text: "HDD 500 GB",
    price: "3500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "HDD 1 TB",
    price: "6500 P",
  },
  {
    text: "HDD 2 TB",
    price: "10800 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Необходимость в замене на ноутбуке жесткого диска может возникнуть как в связи с его поломкой, так и с потребностью пользователя в дополнительном объеме памяти.",
  "Оба случая приводят пользователей в сервисный центр Note Expert, где им предоставляется профессиональная замена жесткого диска на накопитель того же типа и модификации или более емкий и современный.",
  "Ассортимент запасных частей на собственном складе нашей компании включает все необходимые для ремонта ноутбуков компоненты, включая HDD разных моделей.",
];

function NoteExpertGeneralPageServiceHardDisk() {
  return (
    <Page title="Замена HDD ноутбука - от 2500 рублей">
      <main className={styles.main}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/note-expert/zamena-hdd"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Замена жесткого диска на ноутбуках в сервисном центре Oh!MyGadget! (Москва). Оригинальные запчасти для всех моделей ноутбуков. Специалисты высокой квалификации. Отличное качество по минимальным ценам."
          />
        </Helmet>
        <NoteExpertInsidePageBanner
          banner={banner}
          alt="Замена жесткого диска"
          title="Замена жесткого диска"
        />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Замена жесткого диска в Oh!MyGadget!"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Почему мы предлагаем замену, а не ремонт HDD"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Замена HDD на ноутбуке – стоимость"
          subtitle="Цены на жесткие диски"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Причины для замены жесткого диска на ноутбуке"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertGeneralPageServiceHardDisk;
