import React from "react";
import Section from "../Section/Section";
import iMacBanner from "../../images/banners/banner-imac.png";

const RepairIMacBanner = () => {
  return (
    <Section>
      <img
        className="repair-imac__banner"
        src={iMacBanner}
        alt="ремонт imac"
        title="ремонт imac"
      />
    </Section>
  );
};

export default RepairIMacBanner;
