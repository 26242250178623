import React from "react";
import Section from "../Section/Section";

const RepairMacBookInfoMobile = () => {
  return (
    <Section>
      <div className="repair-mb-mobile__info-content">
        <h1 className="repair-mb-mobile__info-title">Ремонт MacBook</h1>
        <div className="repair-mb-mobile__info-border"></div>
        <h4 className="repair-mb-mobile__info-subtitle">
          Сервисный центр Мак Профи производит ремонт MacBook в Москве.
        </h4>
        <p className="repair-mb-mobile__info-text">
          Обратите внимание, Вам не обязательно иметь при себе гарантийный
          талон, идентификация вашего MacBook производится по серийному номеру.
          При обращении в сервисный центр Вам достаточно указать серийный номер
          вашего MacBook.
        </p>
      </div>
    </Section>
  );
};

export default RepairMacBookInfoMobile;
