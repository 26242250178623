import Section from "../Section/Section";
import banner from "../../images/banners/about_banner.png";
import bannerMobile from "../../images/banners/about_banner_mobile.png";

const AboutCompanyBanner = () => {
  return (
    <Section>
      <picture>
        <source srcSet={bannerMobile} media="(max-width: 550px)" />
        <img
          src={banner}
          alt="Информация о компании О Май Гаджет"
          title="Информация о компании О Май Гаджет"
          className="about-comp__banner"
        />
      </picture>
    </Section>
  );
};

export default AboutCompanyBanner;
