import React from "react";
import { Link } from "react-router-dom";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  RepairIphoneModelLinksForServiceMobile,
  SelectForIphoneServiceLinks,
  Section,
} from "../index";
import banner from "../../images/banners/repair-iphone-microphone.jpeg";
import { listOfModelForMicrophone } from "../../utils/iphoneModels";
import { Helmet } from "react-helmet";

const textOfMicrophone = [
  {
    id: 1,
    title: "Когда требуется замена микрофона iPhone",
    text: (
      <>
        Существует целый ряд симптомов, наглядно свидетельствующих о том, что
        проблемы возникли именно с микрофоном вашего устройства. В первую
        очередь речь идет о ситуациях, когда абонент, с которым вы пытаетесь
        поговорить, совершенно вас не слышит. В этом случае стоит обратить
        внимание на настройки вашего Айфона, возможно, причина в том, что они
        случайно сбились. Если с ними все в порядке, попробуйте позвонить
        другому человеку, может быть, проблема в телефоне абонента. Если вас все
        равно никто не слышит, стоит обратиться в мастерскую, потому как в этой
        ситуации пострадал, скорее всего, именно микрофон. Также бывает, что вас
        перестали нормально слышать – голос и звук есть, однако звучание тихое и
        сопровождается шумами, посторонними звуками. В этом случае можно
        говорить не только о повреждении микрофона – бывает и так, что нужно
        просто почистить отверстия в корпусе при микрофоне, и звук вернется.
        Впрочем, чистку тоже лучше доверить профессионалам, и лучше будет просто
        отнести аппарат в мастерскую, где уточнят источник проблем и избавят вас
        от него. Но почему же эти проблемы возникают? Существует целый спектр
        ситуаций, когда микрофон может получить повреждения и выйти из строя, и
        среди таковых стоит отметить:<br></br>
        1. Падения, удары, механическое повреждение,<br></br>
        2. Окисление, попадание воды,<br></br>
        3. Износ и отказ в связи со сроком эксплуатации.<br></br>
        Ремонт оказывается возможным во всех этих случаях, и переживать не
        стоит. Однако на будущее стоит подумать о принятии некоторых мер,
        которые исключат возникновение тех же проблем в будущем. Так, стоит
        аккуратно и бережно относиться к устройству, чтобы исключить нелепые
        случайности. Имеет смысл пользоваться чехлом, выбрав наиболее практичный
        из них, чтобы дать Айфону достойную защиту. Не стоит допускать
        загрязнение, запыление приборчика. Эти простые советы помогут сохранить
        его работоспособность на долгий срок, исключат частое обращение в
        мастерские за ремонтом.<br></br>
        Но что же делать, если техника уже пострадала и работает некорректно,
        если звука нет, или вторая сторона на связи слышит вас с трудом? В таком
        случае стоит немедленно обратиться в хороший сервисный центр, избегая
        любого непрофессионального вмешательства. Помните, что даже просто
        разборка современного высокотехнологичного гаджета в домашних условиях
        чревата новыми его повреждениями, от такого подхода следует отказаться
        даже в том случае, если вы уже посмотрели видео инструкции по ремонту,
        нашли запчасти на замену, и так далее. Доверьте работу с чувствительной
        аппаратурой специалистам, ведь это буквально ювелирное дело, которое
        действительно требует навыков и знаний, особых условий и специального
        оснащения для ремонта.
      </>
    ),
  },
  {
    id: 2,
    title: "Замена микрофона iPhone в сервисном центре Мак Профи",
    text: (
      <>
        Если вы столкнулись с неисправностями Айфона, предполагаете проблемы с
        микрофоном, или же иные поломки, переживать не стоит. Сервисный центр
        Мак Профи уже много лет занимается устранением различных неисправностей
        и специализируется именно на таких высокотехнологичных девайсах. Наши
        мастера знают о них все, успешно добиваются их исправной работы даже в
        самых сложных случаях. Мастерская работает официально, имеет прекрасную
        репутацию, честно заслуженную за годы успешной работы. Современное
        оснащение рабочих мест в полном соответствии с сутью реализуемых задач
        становится важным фактором успеха и позволяет выполнять задачи любой
        сложности оперативно и качественно. К работам мы допускаем только
        компетентных специалистов с соответствующим образованием, опытом работы.
        Они прекрасно разбираются в таких устройствах и знают о них буквально
        все. Запчасти, и в частности, микрофоны на замену всегда есть в наличии,
        что существенно ускоряет процесс выполнения работ, ведь ожидать их
        появления не придется. На них, как и на сами работы, дается гарантия –
        мы используем элементы только заводского фирменного производства.
        Бесплатная диагностика и масса других возможностей всегда открыты для
        наших клиентов. Обращайтесь, чтобы получить ремонт в профессиональном
        исполнении, и еще долго пользоваться гаджетом!
      </>
    ),
  },
];

const optionsMicrophone = [
  { value: "iPhone 4", label: "iPhone 4", price: "1150" },
  { value: "iPhone 4s", label: "iPhone 4s", price: "5555" },
  { value: "iPhone 5", label: "iPhone 5", price: "2450" },
  { value: "iPhone 5c", label: "iPhone 5c", price: "2450" },
  { value: "iPhone 5s", label: "iPhone 5s", price: "5555" },
  { value: "iPhone 6", label: "iPhone 6", price: "2490" },
  { value: "iPhone 6s", label: "iPhone 6s", price: "3500" },
  { value: "iPhone 6 Plus", label: "iPhone 6 Plus", price: "3000" },
  { value: "iPhone 6s Plus", label: "iPhone 6s Plus", price: "3700" },
  { value: "iPhone 7", label: "iPhone 7", price: "9999" },
  { value: "iPhone 7 Plus", label: "iPhone 7 Plus", price: "1111" },
  { value: "iPhone 8", label: "iPhone 8", price: "2222" },
  { value: "iPhone 8 Plus", label: "iPhone 8 Plus", price: "3333" },
  { value: "iPhone X", label: "iPhone X", price: "4444" },
  { value: "iPhone XS", label: "iPhone XS", price: "5555" },
  { value: "iPhone XS Max", label: "iPhone XS Max", price: "6666" },
  { value: "iPhone 11", label: "iPhone 11", price: "7777" },
  { value: "iPhone 11 Pro", label: "iPhone 11 Pro", price: "8888" },
  { value: "iPhone 11 Pro Max", label: "iPhone 11 Pro Max", price: "9999" },
  { value: "iPhone SE 2020", label: "iPhone SE 2020", price: "0000" },
  { value: "iPhone 12", label: "iPhone 12", price: "10000" },
  { value: "iPhone 12 mini", label: "iPhone 12 mini", price: "11000" },
  { value: "iPhone 12 Pro", label: "iPhone 12 Pro", price: "12000" },
  { value: "iPhone 12 Pro Max", label: "iPhone 12 Pro Max", price: "13000" },
  { value: "iPhone 13", label: "iPhone 13", price: "14000" },
  { value: "iPhone 13 mini", label: "iPhone 13 mini", price: "15000" },
  { value: "iPhone 13 Pro", label: "iPhone 13 Pro", price: "16000" },
  { value: "iPhone 13 Pro Max", label: "iPhone 13 Pro Max", price: "17000" },
];

const RepairIphoneServiceLinkMicrophone = () => {
  return (
    <Page title="Замена микрофона iPhone в Москве">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-iphone/zamena-mikrofona"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Качественная замена микрофона iPhone – это частая необходимость, и если с вашим гаджетом возникли проблемы, сервис Oh!MyGadget! поможет."
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          alt="Замена микрофона iPhone"
          titleImg="Замена микрофона iPhone"
          banner={banner}
          title="Замена микрофона iPhone"
          text="Поломка любимого Айфона, дорогостоящего и многофункционального девайса – это весьма печальное событие, однако спешить впадать в уныние и считать средства на приобретение нового образца не стоит. Не спешите избавляться от сломанного устройства, и тем более, не пытайтесь ремонтировать его своими силами в ситуации, когда подозрения падают на микрофон. Это вполне устранимая проблема, которая решается довольно легко, если обратиться именно к профессиональным исполнителям.

Грамотный подход и замена микрофона iPhone позволят восстановить работоспособность устройства за считанные часы и без необходимости совершения больших вложений, что позволит вам продолжить эксплуатацию без каких-либо проблем."
        />
        <Section
          style={{
            backgroundColor: "rgba(196, 196, 196, 0.09)",
            width: "100%",
            marginTop: 56,
            padding: "75px 0 75px",
          }}
        >
          <div className="repair-mb__group-list-container">
            <SelectForIphoneServiceLinks
              options={optionsMicrophone}
              text="микрофона"
            />
          </div>
        </Section>
        <Section className="section_group" style={{ padding: "50px 0 65px" }}>
          <div className="repair-mb__group-list-container">
            {textOfMicrophone.map((item) => {
              return (
                <RepairAppleLinksText
                  key={item.id}
                  title={item.title}
                  text={item.text}
                />
              );
            })}
          </div>
        </Section>
        <RepairIphoneModelLinksForServiceMobile
          links={listOfModelForMicrophone}
        />
      </main>
    </Page>
  );
};

export default RepairIphoneServiceLinkMicrophone;
