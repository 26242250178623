import React, { useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useUser } from "../providers/UserProvider";
import { usersApi } from "../utils/api";
import {
  PopupAddUser,
  PopupConfirm,
  Preloader,
  PopupError,
} from "../components/index";
import { auth } from "../utils/api/index";
import Table from "./Table";
import usePopup from "../hooks/usePopup";
import useFetch from "../hooks/useFetch";
import { userAddIcon, trashTableIcon } from "./newIcons/index";

const columns = [
  {
    selector: (row) => row._id,
    id: true,
  },
  {
    name: "Login",
    selector: (row) => row.login,
    render: (row) => (
      <Link to={`/admin-page/users/${row._id}`} className="admin__data-link">
        {row.login}
      </Link>
    ),
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Имя",
    selector: (row) => row.name,
  },
  {
    name: "Должность",
    selector: (row) => row.role,
  },
];

const AdminUserList = () => {
  const { user } = useUser();
  const tableStateRef = useRef(null);

  const { isPopupOpen, togglePopup, closePopup } = usePopup();
  const {
    isPopupOpen: isRemovePopupOpen,
    togglePopup: toggleRemovePopup,
    closePopup: closeRemovePopup,
  } = usePopup();
  const {
    isPopupOpen: isErrorPopupOpen,
    togglePopup: toggleErrorPopup,
    closePopup: closeErrorPopup,
    setIsPopupOpen,
  } = usePopup();

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const {
    data: usersPage,
    loading,
    errorText,
    setApiFilters: setFilters,
    setData: setUsersPage,
    setLoading,
    setErrorText,
  } = useFetch(usersApi.getUsers);

  const hadleAddNewUser = async (data) => {
    setLoading(true);

    try {
      await auth.register(data);
    } catch (error) {
      setIsError(true);
      setIsPopupOpen(true);
      if (error.message === "Ошибка: 409") {
        setMessage("Пользователь с данным email уже зарегистрирован");
      } else if (error.message === "Ошибка: 500") {
        setMessage("Произошла ошибка на сервере. Повторите попытку позднее");
      } else if (error.message === "Ошибка: 400") {
        setMessage("Возникла ошибка при передаче данных");
      } else {
        setMessage("Произошла ошибка");
      }
    }

    const users = await usersApi.getUsers();
    setUsersPage(users);

    setLoading(false);
  };

  if (user.role !== "admin") {
    return <Navigate to="/admin-page" />;
  }

  const handleRemove = async (state) => {
    const { selectedRows } = state;
    const usersIds = selectedRows.map((selectedRow) => selectedRow._id);

    await usersApi.deleteUsers(usersIds);

    const users = await usersApi.getUsers();
    setUsersPage(users);

    tableStateRef.current = null;
  };

  const handlePageChange = (nextPage) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: nextPage }));
  };

  const handleSearchChange = (search) => {
    setFilters((prevFilters) => ({ ...prevFilters, search }));
  };

  const actions = [
    { name: "Добавить", onClick: togglePopup, icon: userAddIcon },
    {
      name: "Удалить",
      onClick: (state) => {
        tableStateRef.current = state;
        toggleRemovePopup();
      },
      icon: trashTableIcon,
      type: "danger",
      disabled: (state) => !state.isChecked,
    },
  ];

  let noFoundMessage = "";
  if (!loading && usersPage.results && usersPage.results.length === 0) {
    noFoundMessage = "Данные по запросу не найдены";
  }

  return (
    <>
      {loading && <Preloader overlay={true} />}
      <section className="info">
        <div className="info__container">
          <Table
            title="Таблица пользователей с доступом в систему"
            columns={columns}
            data={usersPage.results}
            actions={actions}
            totalDataCount={usersPage.count}
            onPageChange={handlePageChange}
            onSearchChange={handleSearchChange}
            errorMessage={noFoundMessage}
          />
        </div>
      </section>
      <PopupAddUser
        isOpenPopup={isPopupOpen}
        onClose={closePopup}
        hadleRegisterUser={hadleAddNewUser}
      />
      <PopupConfirm
        isOpenPopup={isRemovePopupOpen}
        onClose={closeRemovePopup}
        handleSubmit={() => handleRemove(tableStateRef.current)}
      />
      {isError && (
        <PopupError
          isOpenPopup={isErrorPopupOpen}
          message={message}
          onClose={closeErrorPopup}
          togglePopup={toggleErrorPopup}
        />
      )}
    </>
  );
};

export default AdminUserList;
