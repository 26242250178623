import React from "react";
import {
  RepairAppleLinksBanner,
  Page,
  BreadCrumbs,
  MacProfiList,
  RepairAppleLinksText,
  Section,
  RepairAppleTextWithCheckMark,
  RepairIMacLinks,
} from "../index";
import banner from "../../images/banners/imac-plata.jpeg";
import { Helmet } from "react-helmet";

const textBanner = [
  {
    id: 1,
    text: "Скачка напряжения (короткого замыкания).",
    number: "01",
  },
  {
    id: 2,
    text: "Изношенности, как самой материнской платы iMac, так и её отдельных модулей.",
    number: "02",
  },
  {
    id: 3,
    text: "От сырости или перегрева (перегрев чаще всего происходит из-за проблем с кулером, а также системой охлаждения iMac).",
    number: "03",
  },
  {
    id: 4,
    text: "Механические повреждения (трещины) от случайных ударов или падений iMac.",
    number: "04",
  },
  {
    id: 5,
    text: "Некорректная работа, вызванная присутствием внутри iMac грязи и пыли.",
    number: "05",
  },
  {
    id: 6,
    text: "Иногда в материнской плате iMac наблюдается брак производителя, который, в большинстве случаев приводит к замене материнской платы, либо к ее последующему ремонту.",
    number: "06",
  },
];

const text = [
  {
    id: 1,
    text: "Проводится пайка подгоревших контактов, восстанавливаются токопроводящие дорожки и дискретные элементы, по необходимости производится BGA пайка компонентов материнской платы iMac.",
    number: "01",
  },
  {
    id: 2,
    text: "Устранение последствий перегрева платы из-за загрязнённой системы охлаждения.",
    number: "02",
  },
  {
    id: 3,
    text: "При необходимости, производится полная замена материнской платы iMac на новую (при невозможности ремонта существующей платы). Замена платы требуется только в 5% случаев, 95% плат подлежат ремонту и восстановлению.",
    number: "03",
  },
];

const textWithCkeckMark = [
  {
    id: 1,
    title: "Как мы производим ремонт материнской платы iMac",
    text: (
      <RepairAppleTextWithCheckMark
        paragraphUp={
          <>
            Ремонт материнской платы iMac проводится высококвалифицированными
            специалистами с применением современного оборудования:
          </>
        }
        array={text}
      />
    ),
  },
];

const textWithoutChechMark = [
  {
    id: 1,
    title:
      "Какую гарантию мы предоставляем на ремонт и замену материнской платы iMac",
    text: "По завершению ремонта или замены платы общая и компонентная работоспособность техники проверяется на специальном стенде, после чего выдаётся клиенту с гарантией до 3-х месяцев. Гарантия на новую материнскую плату предоставляется в срок 6 месяцев.",
  },
];

const RepairIMacLinkMotherboard = () => {
  return (
    <Page title="Ремонт материнской платы iMac в Москве и всех регионах РФ">
      <main className="repair-iphone-group-content">
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/remont-apple/remont-imac/remont_materinskoj_platy_imac"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Специалисты Oh!MyGadget! производят ремонт и восстановление материнских плат на всех моделях iMac. Производим ремонт любой сложности с гарантией на все виды работ!"
          />
        </Helmet>
        <MacProfiList />
        <BreadCrumbs />
        <RepairAppleLinksBanner
          banner={banner}
          title="Ремонт материнской платы iMac"
          titleImg="Ремонт материнской платы iMac"
          alt="Ремонт материнской платы iMac"
          text={
            <RepairAppleTextWithCheckMark
              paragraphUp={
                <>
                  МасProfi - сервисный центр, специалисты которого
                  специализируются на ремонте и замене материнских плат iMac.
                  Ремонт материнской платы iMac может понадобиться в случаях:
                </>
              }
              array={textBanner}
            />
          }
        />
        <Section className="section_group">
          {textWithCkeckMark.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <Section className="section_group" style={{ paddingBottom: 18 }}>
          {textWithoutChechMark.map((item) => {
            return (
              <RepairAppleLinksText
                key={item.id}
                title={item.title}
                text={item.text}
              />
            );
          })}
        </Section>
        <RepairIMacLinks />
      </main>
    </Page>
  );
};

export default RepairIMacLinkMotherboard;
