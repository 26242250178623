import React from 'react';
import Section from '../Section/Section';
/* import { delivery } from '../../images/index'; */

const DeliveryBanner = () => {
  return (
    <Section className='delivery-banner'>
      <div className='delivery-banner__container'>
        <div className='delivery-banner__content'>
          <ul className='delivery-banner__list'>
            <li className='delivery-banner__item'>
              <p className='delivery-banner__text'>Доставка по всей России</p>
            </li>
            <li className='delivery-banner__item'>
              <p className='delivery-banner__text'>Экспресс доставка по Москве в течение часа</p>
            </li>
            <li className='delivery-banner__item'>
              <p className='delivery-banner__text'>Диагностика в течении 1 - 3 часов</p>
            </li>
            <li className='delivery-banner__item'>
              <p className='delivery-banner__text'>Оплата онлайн</p>
            </li>
            <li className='delivery-banner__item'>
              <p className='delivery-banner__text'>Гарантия на осущественный ремонт до 12 месяцев</p>
            </li>
            <li className='delivery-banner__item'>
              <p className='delivery-banner__text'>Ремонт любого уровня сложности</p>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  )
};

export default DeliveryBanner;

/*
<img src={delivery} alt='главный баннер страницы, с коробкой' className='delivery-banner__background' />
*/

// toDo:
// проверить, зачем закомменченный код??
