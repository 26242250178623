import React from "react";
import Page from "../../Page";
import NoteExpertInsidePageBanner from "../NoteExpertInsidePageBanner/NoteExpertInsidePageBanner";
import banner from "../../../images/banners/note-expert-banner.png";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import NoteExpertInsidePageCallback from "../NoteExpertInsidePageCallback/NoteExpertInsidePageCallback";
import NoteExpertGeneralPageFeedbacks from "../NoteExpertGeneralPageFeedbacks/NoteExpertGeneralPageFeedbacks";
import NoteExpertGeneralPageServiceLinks from "../NoteExpertGeneralPageServiceLinks/NoteExpertGeneralPageServiceLinks";
import CenterMobileIRepairMobileOrderForm from "../../centerMobile/CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import NoteExpertInsidePageInfo from "../NoteExpertInsidePageInfo/NoteExpertInsidePageInfo";
import NoteExpertInsidePageAdvantages from "../NoteExpertInsidePageAdvantages/NoteExpertInsidePageAdvantages";
import NoteExpertInsidePagePrice from "../NoteExpertInsidePagePrice/NoteExpertInsidePagePrice";
import advantagesIcon1 from "../../../images/icons/note-expert-advantage-icon1.svg";
import advantagesIcon2 from "../../../images/icons/note-expert-advantage-icon2.svg";
import priceIcon1 from "../../../images/icons/note-expert-price-icon1.svg";
import priceIcon2 from "../../../images/icons/note-expert-price-icon2.svg";
import priceIcon3 from "../../../images/icons/note-expert-price-icon3.svg";
import styles from "./NoteExpertInsidePage.module.css";
import { Helmet } from "react-helmet";

const callbackText = [
  "Ваш ноутбук самопроизвольно перезагружается при включении, во время online-игр или работы? Как минимум, это приводит к потере несохраненных результатов игры или рабочих файлов.",
  "Как максимум, грозит поломкой важных компонентов ноутбука и его выходом из строя.",
  "Чтобы не допустить этого, вам необходимо при первых проявлениях проблемы перезапуска ноутбука обратиться в сервисный центр для ее диагностики и устранения.",
];

const advantages = [
  {
    title: "ПРОФЕССИОНАЛИЗМ И КВАЛИФИЦИРОВАННОСТЬ НАШИХ СОТРУДНИКОВ",
    text: "Причины, по которым ноутбук сам перезагружается, можно подразделить на две группы: программные и аппаратные. К первой относятся проблемы с прошивкой BIOS, операционной системой (особенно при использовании нелицензионных версий), воздействием вредоносных программ. Ко второй – проблемы с оперативной памятью, материнской платой, видеокартой, жестким диском.",
    icon: advantagesIcon1,
  },
  {
    title: "ОПЫТ И СЕРТИФИЦИРОВАННАЯ ЛИЦЕНЗИОННАЯ ПРОДУКЦИЯ",
    text: "Эти компоненты могут давать сбои при несовместимости друг с другом, недостатке мощности или объема, наличии производственных дефектов, больших нагрузках и перегреве вследствие сильной внутренней запыленности или неисправности системы охлаждения.",
    icon: advantagesIcon2,
  },
];

const priceElements = [
  {
    icon: priceIcon1,
    text: "Ремонт ноутбуков в сервисном центре Note Expert включает весь спектр услуг по восстановлению работоспособности устройства с самопроизвольной перезагрузкой.",
  },
  {
    icon: priceIcon2,
    text: "Мы предлагаем заказчикам профессиональный сервис и высокое качество по самым разумным ценам.",
  },
  {
    icon: priceIcon3,
    text: "Диагностику неисправностей выполняем бесплатно!",
  },
];

const prices = [
  {
    text: "Чистка от пыли с заменой термоинтерфейсов",
    price: "2000 Р",
  },
  {
    text: "Ремонт цепи питания",
    price: "от 2500 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    text: "Перепрошивка BIOS",
    price: "от 2000 P",
  },
  {
    text: "Замена/реболл чипов",
    price: "от 3000 P",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const infoTexts = [
  "Если причиной постоянного перезапуска портативного ПК являются программные сбои, потребуется переустановка операционной системы и другого ПО, перепрошивка BIOS либо удаление вирусов.",
  "Делать это самостоятельно или обратиться к специалистам, зависит от уровня вашей компетентности в этих вопросах. Однако при проблемах с аппаратной частью вашему ноутбуку, скорее всего, потребуется профессиональная диагностика, для которой нужно специальное оборудование.",
  "Самостоятельно можно попытаться выполнить чистку ноутбука от пыли и замену термопасты, если симптомы неисправности свидетельствуют о перегреве.",
];

function NoteExpertNoteSettingRestart() {
  return (
    <Page title="Устранение поломки ноутбука, вызывающей перезагрузку">
      <main className={styles.main}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/note-expert/perezagruzhaetsya"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Ноутбук сам перезагружается во время игры или работы? Специалисты Oh!MyGadget! точно диагностируют и быстро устранят неисправность. Конкурентные цены, широкий спектр услуг. Ремонт ноутбуков всех марок и моделей."
          />
        </Helmet>
        <NoteExpertInsidePageBanner
          banner={banner}
          alt="Ноутбук перезагружается"
          title="Ноутбук перезагружается"
        />
        <BreadCrumbs />
        <NoteExpertInsidePageCallback
          title="Ноутбук перезагружается сам"
          callbackText={callbackText}
        />
        <NoteExpertInsidePageAdvantages
          title="Причины самопроизвольной перезагрузки ноутбука"
          advantages={advantages}
        />
        <NoteExpertInsidePagePrice
          title="Ремонт перезагружающегося ноутбука: цены на услуги"
          subtitle="Стоимость ремонта перезагружающегося ноутбука"
          priceElements={priceElements}
          prices={prices}
        />
        <NoteExpertInsidePageInfo
          title="Способы устранения неисправностей"
          infoTexts={infoTexts}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК СДЕЛАТЬ ЗАКАЗ ?"
          text={
            <>
              Если не включается телефон Samsung или с мобильным устройством
              случилась другая неприятность, оформить заказ можно через
              электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              или связавшись с нами по телефону +7 (495) 540-43-19 (для вызова
              курьера или мастера). А можно самостоятельно привезти гаджет
              Самсунг в наш сервис-центр по ремонту смартфонов. Ждем вас!
            </>
          }
        />
        <NoteExpertGeneralPageFeedbacks title="отзывы наших клиентов" />
        <NoteExpertGeneralPageServiceLinks />
      </main>
    </Page>
  );
}

export default NoteExpertNoteSettingRestart;
