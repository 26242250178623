import React from "react";
import {
  Section,
  RepairAppleLinksText,
  RepairAppleTextWithCheckMark,
} from "../index";

const repairStages = [
  {
    id: 1,
    text: "Мастер протестирует часы на специальном оборудовании",
    number: "01",
  },
  {
    id: 2,
    text: "Удалит болты и аккуратно отсоединит основные шлейфы и разъемы",
    number: "02",
  },
  {
    id: 3,
    text: "Устранит неполадку и соберет все части воедино.",
    number: "03",
  },
];

const reasons = [
  {
    id: 1,
    text: "Лояльные расценки на все виды услуг – ценовая политика честна и прозрачна, что подтверждает прайс, представленный на сайте;",
    number: "01",
  },
  {
    id: 2,
    text: "Отменное обслуживание – не задерживаем клиентов, оперативно предоставляем необходимые услуги;",
    number: "02",
  },
  {
    id: 3,
    text: "Квалифицированные сотрудники – только лучшие инженеры, у которых огромный опыт работы в этой сфере.",
    number: "03",
  },
];

const textForWatch = [
  {
    id: 1,
    title: "Основные этапы ремонта Apple Watch",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Важно, чтобы ремонтом занимались квалифицированные инженеры, которым хорошо знакомы основные тонкости выполнения подобной процедуры. Изначально мы выясним причину обращения в сервис, после чего клиенту будут оказаны следующие услуги:"
          array={repairStages}
          paragraphDown="Нередко требуется и дополнительный ремонт, ведь механические повреждения приводят к появлению не только видимых, но и внутренних дефектов. Благодаря предварительной диагностике удается в полной мере оценить реальное состояние устройства, предложив владельцу действительно необходимые ему услуги. Мы настоятельно рекомендуем не заниматься ремонтом Apple Watch самостоятельно, так как многолетняя практика доказывает – это приводит лишь к дополнительным поломкам. Несмотря на желание самостоятельно вернуть устройство к жизни, лучше все-таки доверить выполнение этой задачи тем, кто обладает достаточным опытом и квалификацией."
        />
      </>
    ),
  },
  {
    id: 2,
    title: "Почему стоит доверить ремонт Apple Watch мастерам Мак Профи?",
    text: (
      <>
        <RepairAppleTextWithCheckMark
          paragraphUp="Конструкция Apple Watch сложна, и чтобы добраться до неисправных частей, нужно преодолеть целый ряд «препятствий»: разъемы, шлейфы, болты. Не так уж просто бережно удалить все эти элементы, ничего не повредив в процессе. Мы готовы предложить вам профессиональный ремонт, ведь в нашем сервисе:"
          array={reasons}
          paragraphDown="Кроме того, вы получаете ряд дополнительных услуг, начиная от возможности воспользоваться бесплатной курьерской доставкой до комплексного выполнения работ, что позволит за одно посещение сервиса выполнить несколько видов ремонта."
        />
      </>
    ),
  },
];

const RepairIwatchText = () => {
  return (
    <Section className="section_group" style={{ paddingBottom: 65 }}>
      {textForWatch.map((item) => {
        return (
          <RepairAppleLinksText
            key={item.id}
            title={item.title}
            text={item.text}
          />
        );
      })}
    </Section>
  );
};

export default RepairIwatchText;
