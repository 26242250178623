import React from "react";
import Page from "../../Page";
import styles from "../CenterMobileIRepairMobile/CenterMobileIRepairMobile.module.css";
import CenterMobileIRepairMobileBanner from "../CenterMobileIRepairMobileBanner/CenterMobileIRepairMobileBanner";
import CenterMobileIRepairMobileCallback from "../CenterMobileIRepairMobileCallback/CenterMobileIRepairMobileCallback";
import CenterMobileIRepairMobileInfo from "../CenterMobileIRepairMobileInfo/CenterMobileIRepairMobileInfo";
import CenterMobileIRepairMobileAdvantages from "../CenterMobileIRepairMobileAdvantages/CenterMobileIRepairMobileAdvantages";
import CenterMobileIRepairMobileOrderForm from "../CenterMobileIRepairMobileOrderForm/CenterMobileIRepairMobileOrderForm";
import CenterMobileIRepairMobilePrice from "../CenterMobileIRepairMobilePrice/CenterMobileIRepairMobilePrice";
import CenterMobileIRepairMobileSelect from "../CenterMobileIRepairMobileSelect/CenterMobileIRepairMobileSelect";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import lgBanner from "../../../images/banners/mobile-lg-banner.png";
import icon1 from "../../../images/icons/center-mobile-advantages-icon.svg";
import icon2 from "../../../images/icons/center-mobile-advantages-icon-house.svg";
import icon3 from "../../../images/icons/center-mobile-advantages-icon-car.svg";
import icon4 from "../../../images/icons/center-mobile-advantages-icon-rocket.svg";
import {
  pricelists5mini,
  pricelist2Grand,
} from "../../../utils/CenterMobileSamsungPriceList";
import { Helmet } from "react-helmet";

const contentForAdvantages = [
  {
    img: icon1,
    title: "ГАРАНТИЕЙ",
    text: "На все виды проведенных работ «Центр Мобайл» предоставляет гарантию на определенный срок. Также гарантийные обязательства распространяются на установленные запчасти.",
  },
  {
    img: icon2,
    title: "БЕСПЛАТНОЙ ДОСТАВКОЙ",
    text: "«В рабочее время у нас действует зона бесплатной доставки (в пределах МКАД).",
  },
  {
    img: icon3,
    title: "ВОЗМОЖНОСТЬЮ ВЫЗОВА МАСТЕРА НА ДОМ",
    text: "При небольшой проблеме ремонт телефонов Лджи может выполняться в домашних условиях. Для этого достаточно связаться с нами по телефону +7 (495) 540-43-19.",
  },
  {
    img: icon4,
    title: "СРОЧНЫМ РЕМОНТОМ",
    text: "Мы понимаем, что если не работает телефон LG, вы хотите быстрее вернуть ему рабочее состояние. Поэтому предлагаем срочный ремонт, восстанавливая устройство в присутствии заказчика.",
  },
];

const mobiles = [
  { name: "W41", pricelist: pricelists5mini },
  { name: "W41 Pro", pricelist: pricelist2Grand },
  { name: "W41+", pricelist: pricelists5mini },
  { name: "LG G", pricelist: pricelist2Grand },
  { name: "LG L", pricelist: pricelists5mini },
  { name: "Nexus", pricelist: pricelist2Grand },
  { name: "Optimus", pricelist: pricelists5mini },
];

const samsungMobilePrice = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Замена микросхем телефона",
    price: "от 900 P",
    time: "2-3 часа",
  },
  {
    id: 3,
    text: "Замена батареи",
    price: "от 300 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Замена экрана",
    price: "от 900 P",
    time: "2 часа",
  },
  {
    id: 5,
    text: "Ремонт цепи питания",
    price: "990 P",
    time: "3-4 часа",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Замена стекла",
    price: "от 800 P",
    time: "1-2 часа",
  },
  {
    id: 7,
    text: "Замена динамика",
    price: "от 280 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 8,
    text: "Замена разъема зарядки",
    price: "700-900 P",
    time: "1 час",
  },
  {
    id: 9,
    text: "Ремонт кнопки",
    price: "от 440 Р",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 10,
    text: "Замена задней крышки",
    price: "от 390 P",
    time: "4-5 часов",
  },
  {
    id: 11,
    text: "Ремонт системной платы",
    price: "1900 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

const samsungMobilePrice2 = [
  {
    id: 1,
    text: "Диагностика",
    price: "Бесплатно",
    time: "30 минут",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 2,
    text: "Восстановление прошивки",
    price: "890 P",
    time: "1 час",
  },
  {
    id: 3,
    text: "Чистка от жидкости",
    price: "890 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 4,
    text: "Восстановление данных",
    price: "890 P",
    time: "2-3 часа",
  },
  {
    id: 5,
    text: "Мелкий ремонт",
    price: "от 1500 P",
    time: "4-5 часов",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
  {
    id: 6,
    text: "Чистка от пыли",
    price: "от 390 P",
    time: "1 час",
  },
  {
    id: 7,
    text: "Перепрошивка",
    price: "700 P",
    time: "1 час",
    color: "#FFFFFF",
    boxShadow: "0px 0px 134px rgba(0, 0, 0, 0.05)",
  },
];

function CenterMobileRepairMobileLG() {
  return (
    <Page title="Ремонт телефонов LG в Москве, сервисный ремонт смартфонов LG всех моделей">
      <main className={styles.content}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ohmygadget.ru/center-mobile/remont-smartfonov-lg"
          />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Полный спектр услуг по восстановлению смартфонов LG в Oh!MyGadget! (Москва). Качественный ремонт телефонов LG по конкурентоспособным ценам. Гарантии на все виды работ и комплектующие."
          />
        </Helmet>
        <CenterMobileIRepairMobileBanner
          image={lgBanner}
          alt="смартфон LG"
          title="смартфон LG"
        />
        <BreadCrumbs />
        <CenterMobileIRepairMobileCallback />
        <CenterMobileIRepairMobileInfo
          title="РЕМОНТ СМАРТФОНОВ LG"
          text="Больше не нужно искать, где отремонтировать в Москве телефон LG. Мы предлагаем не только качественный ремонт, но и высокий уровень сервиса, что позволяет клиентам пользоваться нашими услугами, даже не выходя из дома. Позвоните по нашему телефону, закажите ремонт смартфона, и вы лично убедитесь в профессионализме наших сотрудников и безупречном качестве ремонтных работ."
        />
        <CenterMobileIRepairMobileSelect
          title="Цены на ремонт LG"
          text="Для уточнения стоимости услуг по ремонту, выберите вашу модель
            LG:"
          mobiles={mobiles}
        />
        <CenterMobileIRepairMobileAdvantages
          title="ЧЕМ ОТЛИЧАЕТСЯ РЕМОНТ ТЕЛЕФОНОВ LG В «ЦЕНТР МОБАЙЛ»?"
          titleMobile="Наши преимущества"
          contentForAdvantages={contentForAdvantages}
        />
        <CenterMobileIRepairMobileOrderForm
          title="КАК ОТРЕМОНТИРОВАТЬ ТЕЛЕФОН В «ЦЕНТР МОБАЙЛ» ?"
          text={
            <>
              Если у вас не включается смартфон LG, позвоните нам по контактному
              телефону, напишите на электронную почту{" "}
              <a
                href="mailto:zakaz@centre-mobile.ru"
                className={styles.emaillink}
              >
                zakaz@centre-mobile.ru
              </a>{" "}
              для вызова мастера (курьера) или привезите свой телефон лично. В
              любом случае «Центр Мобайл» гарантирует оптимально короткие сроки
              ремонта и приятную стоимость услуг.
            </>
          }
        />
        <CenterMobileIRepairMobilePrice
          samsungMobilePrice={samsungMobilePrice}
          samsungMobilePrice2={samsungMobilePrice2}
        />
      </main>
    </Page>
  );
}

export default CenterMobileRepairMobileLG;
