import React from "react";
import { slide1, slide2, slide3, slide4 } from "../../images/index";
import slide1Mob from "../../images/banners/slideMobile.jpg";
import slide2Mob from "../../images/banners/slide2Mobile.jpg";
import slide3Mob from "../../images/banners/slide3Mobile.jpg";
import slide4Mob from "../../images/banners/slide4Mobile.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Section } from "../index";

const Banner = () => {
  return (
    <Section>
      <div className="banner__content">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showIndicators={true}
          stopOnHover={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          <div>
            <picture>
              <source srcSet={slide1Mob} media="(max-width: 550px)" />
              <img
                src={slide1}
                alt="ремонт телефонов"
                title="ремонт телефонов"
              />
            </picture>
          </div>
          <div>
            <picture>
              <source srcSet={slide2Mob} media="(max-width: 550px)" />
              <img
                src={slide2}
                alt="ремонт телефонов"
                title="ремонт телефонов"
              />
            </picture>
          </div>
          <div>
            <picture>
              <source srcSet={slide3Mob} media="(max-width: 550px)" />
              <img
                src={slide3}
                alt="ремонт моноблоков"
                title="ремонт моноблоков"
              />
            </picture>
          </div>
          <div>
            <picture>
              <source srcSet={slide4Mob} media="(max-width: 550px)" />
              <img
                src={slide4}
                alt="ремонт планшетов"
                title="ремонт планшетов"
              />
            </picture>
          </div>
        </Carousel>
      </div>
    </Section>
  );
};

export default Banner;
