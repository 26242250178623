import React from 'react';
import Section from '../Section/Section';

const RepairIphoneContacs = () => {
  return (
    <Section>
      <div className='repair-iphone__contacts-container'>
        <h2 className='repair-iphone__contacts-title'>Обращайтесь к нам, если&nbsp;нужен ремонт iPhone!</h2>
        <p className='repair-iphone__contacts-text'>Если вы столкнулись с поломкой Айфона, уронили или повредили его, или он просто перестал работать нормально без видимых причин, обращайтесь к нам, чтобы мы смогли оказать вам оперативную и качественную помощь! Помните, что услуги полупрофессиональных мастеров нередко наносят больше вреда, чем пользы, от исполнителей зависит очень многое. Мы же возьмем на себя полную ответственность за приборчик и починим его качественно и в срок!</p>
      </div>
    </Section>
  )
};

export default RepairIphoneContacs;